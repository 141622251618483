<div class="row mb-1 justify-content-center">
    <div class="col-lg-12 concept" align="center">
        
      <div>
        <h3>Material Management - Disposal  ({{userName}})</h3>
        <div class="col-lg-12" style="background-color: #045975; margin-bottom: 20px;"> <!-- Color band -->
            &nbsp; 
        </div>
      </div>
    </div>
    
<div class="row mb-1" *ngIf="!busy" >
    <div class="col-lg-4 col-xs-12">
       <b>Batch Number</b>
    </div>
    <div class="col-lg-2 col-xs-12">
       <b>Expiry Date</b>
    </div>
    
    <div class="col-lg-2 col-xs-12">
       <b>Original Inventory</b>
    </div>
    <div class="col-lg-2 col-xs-12">
        <b>Dispatch Date</b>
     </div>
  
</div>  

<div class="row mb-1" *ngFor="let aMaterial of allMaterials" >
    <div class="col-lg-4 col-xs-12">
        {{aMaterial.batchNumber}}
    </div>
    <div class="col-lg-2 col-xs-12">
        {{aMaterial.expiryDate|date:'d MMMM y'}}
    </div>
    
    <div class="col-lg-2 col-xs-12">
        {{aMaterial.numberOfItems}}
    </div>
    <div class="col-lg-2 col-xs-12">
        {{aMaterial.dateCreated|date:'d MMMM y'}}
    </div>
    
    <div class="col-lg-2 col-xs-12">
        <a  class="btn btn-lg btn-primary  me-4" routerLink="/pi/return/{{aMaterial.id}}" role="button">Return 
            &raquo;&nbsp; </a>
    </div>
</div>  
</div>