<!-- patient identification  == -->
<div>
    <form *ngIf="!busy" #f="ngForm" (ngSubmit)="onSubmit()">

        <div class="row mb-1 justify-content-center">
            <button type="button" class="btn btn-info" ngbTooltip="{{patientTooltip}}"
                (click)="isSearchPatient=!isSearchPatient">
                <!-- [attr.aria-expanded]="!isCollapsed" aria-controls="addIrb" -->
                <h3>Patients</h3>
            </button>
            <p></p>
        </div>

        <div *ngIf="isSearchPatient">
            <div class="row mb-1 justify-content-center">
                <h3> Search Patient</h3>
            </div>

            <!-- <div class="row mb-1"> -->

            <div class="row mb-1">
                <div class="col-lg-2 col-xs-12">
                    <div class="form-group">
                        <label for="patientNumber"><b>Patient Number</b></label>
                    </div>
                </div>

                <div class="col-lg-3 col-xs-12">
                    <div class="form-group">
                        <input type="text" id="patientNumber" name="patientNumber" [(ngModel)]="patientNumber"
                            class="form-control" required>
                    </div>
                </div>

                <div class="col-lg-2 col-xs-12">
                    <div class="form-group">
                        <label for="dateOfBirth"><b>Date of Birth</b></label>
                    </div>
                </div>

                <div class="col-lg-3 col-xs-12">
                    <div class="form-group">

                        <input type="date" id="dateOfBirth" name="dateOfBirth" [(ngModel)]="dateOfBirth"
                            class="form-control" required>
                    </div>
                </div>
                <div class="col-lg-2 col-xs-12">
                    <div class="form-group">
                        <button type="submit" class="btn btn-primary">
                            Search
                        </button>
                    </div>
                </div>
            </div>
            <!-- </div> -->
        </div>
        <div *ngIf="!isSearchPatient">
            <div class="row mb-1 justify-content-center">
                
            </div>
            <div class="row mb-1">
                <div class="col-lg-6"><h3>Add New Patient</h3> 
                </div>
           
            </div>


            <div class="row mb-1">
                <div class="col-lg-2 col-xs-12">
                    <div class="form-group">
                        <label for="patientNumber"><b>Patient Number</b></label>
                    </div>
                </div>

                <div class="col-lg-3 col-xs-12">
                    <div class="form-group">
                        <input type="text" id="patientNumber" name="patientNumber" [(ngModel)]="patient.patientNumber"
                            class="form-control" placeholder="New patient Number" required>
                    </div>
                </div>

                <div class="col-lg-2 col-xs-12">
                    <div class="form-group">
                        <label for="dateOfBirth"><b>Date of Birth</b></label>
                    </div>
                </div>

                <div class="col-lg-3 col-xs-12">
                    <div class="form-group">

                        <input type="date" id="dateOfBirth" name="dateOfBirth" [(ngModel)]="patient.dateOfBirth"
                            class="form-control" required>
                    </div>
                </div>
            </div>
            <div class="row mb-1">
                <div class="col-lg-2 col-xs-12">
                    <div class="form-group">
                        <label for="clinicalTreatmentCost"><b>Clinical Treatment Cost($)</b></label>
                    </div>
                </div>

                <div class="col-lg-3 col-xs-12">
                    <div class="form-group">
                        <input type="number" id="clinicalTreatmentCost" name="clinicalTreatmentCost" [(ngModel)]="patient.clinicalTreatmentCost"
                            class="form-control" placeholder="$" required min = '0'>
                    </div>
                </div>
                <div class="col-lg-2 col-xs-12">
                    <div class="form-group">
                        <label for="medicalAnalysisCost"><b>Medical Analysis Cost($)</b></label>
                    </div>
                </div>

                <div class="col-lg-3 col-xs-12">
                    <div class="form-group">
                        <input type="number" id="medicalAnalysisCost" name="medicalAnalysisCost" [(ngModel)]="patient.medicalAnalysisCost"
                            class="form-control" placeholder="$" required min = '0'>
                    </div>
                </div>
 

            </div>

            <div class="row mb-1">
                <div class="col-lg-2 col-sx-12">

                    <div class="form-group">
                        <label for="biologicalGender"><b>Biological Gender</b></label>
                    </div>
                </div>
                <div class="col-lg-3 col-xs-12">

                    <div class="form-group">
                        <!-- -->
                        <select class="form-control" id="biologicalGender" name="biologicalGender"
                            [(ngModel)]="patient.biologicalGender" required>
                            <option value="M">Male</option>
                            <option value="F">Female</option>
                        </select>
                    </div>
                </div>

                <div class="col-lg-2 col-xs-12">

                </div>
                <div class="col-lg-3 col-xs-12">
                    <div class="form-group">
                        <button type="submit" class="btn btn-primary form-control" [disabled]="!f.form.valid">
                            Add
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- </div> -->
    </form>

    <div *ngIf="!patientListBusy">

        <div class="row mb-1">
            <div class="col-lg-12 text-center">
                <div *ngIf="!busy" class="col-md-12">
                    <h3>List of Patients</h3>
             <table class="table">
                        <tr>
                            <div class="row mb-1">
                            <div class="col-lg-2 col-sx-12">

                                <div class="form-group">
                                    <label for="statusNumber">Filter by Patient Status</label>
                                </div>
                            </div>
                    <div class="col-lg-3 col-xs-12">

                        <div class="form-group">
                            <!-- -->
                            <select class="form-control" id="patientStatus" name="patientStatus"
                            [(ngModel)]="statusNumber"  (change)= "filterStatus()">
                                <option value="0">All Patients</option>
                                <option value="1">Needs Screening</option>
                                <option value="2">Waiting For Screening Results</option>
                                <option value="3">Selected After Screening - Provide Consent Form</option>
                                <option value="4"> Not selected for Study - Failed Screening</option>
                                <option value="5">Awaiting consent signature</option>
                                <option value="6">No consent</option>
                                <option value="7">Enrolled</option>

                            </select>
                        </div>
                    </div>
                <!--    <div class="row mb-1 ">  -->
                        <div class="col-lg-2 col-xs-12">
                            <div class="form-group">
                                <label for="patientAuditCount"># of Patients To Date (Filtered/Total) &#64; Site </label>
                            </div>
                        </div>
        
                        <div class="col-lg-3 col-xs-12">
                            <div class="form-group">
                               <!-- <input type="number" id="patientAuditCount" name="patientAuditCount" [(ngModel)]="patientAuditCount"
                            class="form-control" readonly>-->
                            <a  class="btn btn-primary form-control PATIENTSCOUNT "
                                     role="button" title={{patientAuditCount}} aria-disabled="true">
                                     {{patientListFilteredCount}} / {{patientAuditCount}}
                            </a>
                           
                            </div>
                        </div>
                  <!--  </div> -->
                </div>

               
                        </tr>
                        <tr>
                            <td>
                                <h4>Click on a patient "Status" below to proceed</h4>

                            </td>
                        </tr>
                        
                       <!-- </tr>-->
              </table>
                    <div>
                     <!--  <div *ngFor="let aPatient of patientListFiltered; let i = index" [attr.data-index]="i" >  -->
                       <table class="table">
                        
                        <thead>
            
                            <tr> 
                                 <th scope="col" ># </th>
                                 <th scope="col" >Enrollment Order </th>
                                 <th scope="col" >Screening Date</th>
                                 <th scope="col">Patient Num</th>
                                 <th scope="col">Dob</th>
                                 <th scope="col">Gender</th><!-- 
                            <div class="col-lg-1  font-weight-bold hidden-xs hidden-sm">Consent Date</div>
                            <div class="col-lg-1  font-weight-bold hidden-xs hidden-sm">First TRx</div> -->
                                <th scope="col">Status</th>
                            </tr>
                       </thead>
                        
                            <tbody>
                       
                    <tr *ngFor="let aPatient of patientListFiltered;  let i = index" [attr.data-index]="i" > 
                        
                                <td style="color: red;"><strong>{{patientAuditCount-i}}</strong></td>
                                <td>{{aPatient.id}}</td>
                                <td>{{aPatient.screeningDate|date:'d MMM y H:mm:ss'}}</td>
                                <td>{{aPatient.patientNumber}}</td>  
                                <td >{{aPatient.dateOfBirth|date:'d MMM y'}}</td>
                                <td >{{aPatient.biologicalGender}}</td>
                              
                                <a  class="btn btn-primary form-control {{nextStepClass(aPatient)}}"
                                    [routerLink]=nextStepLink(aPatient)  role="button" title={{patientStatus(aPatient)}} aria-disabled="true">
                                    {{patientStatus(aPatient)}}
                                </a>
                            
                    </tr>  
                     </tbody>

             </table>               
                 </div> 
                 <ngx-spinner type="pacman">
                    <p>Loading...</p>
                </ngx-spinner>
                        </div> 
                    </div>
                </div>

            </div>
        </div>
   <!-- </div>-->


  
