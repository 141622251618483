<div class="container">
    <div class="row mb-1">
        <div *ngIf="!busy" class="col-md-8">
          <h3>Super-secret user claims sent from the server!</h3>
            <table class="table small">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Type</th>
                    <th scope="col">Value</th>                
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let claim of claims; let i = index" [attr.data-index]="i">
                    <th scope="row">{{i+1}}</th>
                    <td>{{claim.type}}</td>
                    <td>{{claim.value}}</td>                   
                  </tr>                 
                </tbody>
              </table>            
        </div>
    </div>
    <ngx-spinner type="pacman">
    <p>Loading...</p></ngx-spinner>  
</div>
