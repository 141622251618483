import { Component, OnInit } from '@angular/core';
import { ICompany, Company } from 'src/app/shared/interfaces/company';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators'
import { AuthService } from '../../core/authentication/auth.service';
import { AdministrationService } from '../administration.service';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Component({
  selector: 'app-new-company',
  templateUrl: './new-company.component.html',
  styleUrls: ['./new-company.component.sass']
})
export class NewCompanyComponent implements OnInit {

  company:Company= new Company();
  success:boolean=false;
  busy:boolean=false;
  error:string;
  constructor(private authService: AuthService,private router:Router, private administrationService: AdministrationService, private spinner: NgxSpinnerService) { 
    this.company.companyName='';
    this.company.companyAddress='';
  }

  ngOnInit() {
  }

  onSubmit() {
    console.log('submit');
    this.busy=true;
    this.spinner.show();
    this.administrationService.addCompany(this.authService.authorizationHeaderValue,this.company)
    .pipe(finalize(() => {
      this.spinner.hide();
      this.busy = false;
    })).subscribe(
    result => {         
      //this.companies = result;
      this.router.navigate(['/company']);//view list
   },
   error => {
     this.error = error; 
   });
   
   }
}
