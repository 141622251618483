export class PositionMarker{
    public lat:number;
    public lng:number;
    

    
}
export interface IPositionMarker{
    lat:number;
    lng:number;
   
}