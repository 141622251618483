import { Component , OnInit} from '@angular/core';
import {Irb,IIrb} from 'src/app/shared/interfaces/irb';
import { Material, IMaterial} from 'src/app/shared/interfaces/material';
import {IConceptEvaluation} from 'src/app/shared/interfaces/conceptEvaluation';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators'
import { AuthService } from '../../core/authentication/auth.service';
import { IssAdministrationService } from '../iss-administration.service';
import { Router, CanActivate, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-material-management-add',
  templateUrl: './material-management-add.component.html',
  styleUrls: ['./material-management-add.component.sass']
})
export class MaterialManagementAddComponent {

  irbId: number;
  busy:boolean=false;
  material:Material;
  disableUpdate:boolean=false;
  constructor(private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private administrationService: IssAdministrationService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    debugger;
    this.material=new Material();
    this.irbId = +this.route.snapshot.paramMap.get('irbId');
    this.material.irbId=this.irbId;
    console.log('irb Id ='+ this.irbId.toString());
  }
  onSubmit= ():void=>{ 
    this.disableUpdate=true;
    this.administrationService.addMaterial(this.authService.authorizationHeaderValue, this.material)
      .pipe(finalize(() => {
        this.busy = false;
      })).subscribe(
        result => {
          console.log(result);
          this.router.navigate(['/drugs']);
        });
  }
}
