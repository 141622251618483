import { Component, NgZone } from '@angular/core';
import { AuthService } from '../../core/authentication/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';
import { FilterService } from 'primeng/api';
import { ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Table } from 'primeng/table';
//import * as countryData from 'country-data';

import { ISiteSetup} from 'src/app/shared/interfaces/siteSetup';
import { Irb, IIrb } from 'src/app/shared/interfaces/irb';
import { IssAdministrationService } from 'src/app/investigator-initiated-study/iss-administration.service';
import { PiAdministrationService } from 'src/app/principal-investigator/pi-administration.service'; 

import { MapInfoWindow, MapMarker, GoogleMap } from '@angular/google-maps';
import { MapGeocoder } from '@angular/google-maps';
import { Marker } from 'src/app/shared/interfaces/markers';
import { PositionMarker } from 'src/app/shared/interfaces/positionMarker';




@Component({
  selector: 'app-sponsor-irbs-all',
  templateUrl: './sponsor-irbs-all.component.html',
  styleUrls: ['./sponsor-irbs-all.component.sass']
})
export class SponsorIrbsAllComponent {
 @ViewChild('dt') dt: Table;
 
  siteSetups: ISiteSetup[] = [];
  siteSetup: ISiteSetup;
  siteId: number = 0;
  conceptId: number;

  currentIrbId: number;
  irbs: IIrb[] = [];
  irbCount: number = 0;
  irb: IIrb;
  irbsFiltered: IIrb[] = [];
  allIrbListing: any[] = [];
  
  busy:boolean = false;
  statusNumber:string = "0";
  statusString:string;
  IRBstatusString:string;

  selectedDiseaseAreas: any[];
  selectedSiteNames: any[];
  selectedStudyNames: any[];
  selectedPiNames: any[];
  selectedCountries: any[] ;
  distinctCountryList: string[] =[];
  distinctStudyNameList: string[] =[];
  distinctStudyNameListObject: any[] = [];
  distinctDiseaseAreaList: string[] = [];
  distinctDiseaseAreaListObject:any[] = [];
  irbDistinctSiteNameList: string[] = [];
  irbDistinctSiteNameListObject: any[] = [];
  irbDistinctPiNameList: string[] = [];
  irbDistinctPiNameListObject: any[] = [];
  irbDistinctCountryList: string[] = [];
  irbDistinctCountryListObject: any[] = [];
  irbListFilteredByCountry: any[] = [];
  irbListFilteredByDiseaseArea:any[] = [];
  irbListFilteredByPiName:any[] = [];
  irbListFilteredBySiteName:any[] = [];

  siteBusy: boolean=false;
  irbBusy: boolean =false;
  displayIrbMap: boolean = false;
  zoom: number = 2.0;
  center: google.maps.LatLngLiteral;
  markers:Marker[] = [];
  marker: Marker;
  
  summaryData: string[] = [];
  thisistheinfo: string = "The Info";
  display: any;
  currentSiteId: number;
  markerIndex: number = 0;
  
  IrbMarker: any = {
    path: google.maps.SymbolPath.CIRCLE,
    fillColor: '#0000FF',
    fillOpacity: 0.7,
    strokeColor: '#FFFFFF',
    strokeOpacity: 1.0,
    strokeWeight: 1,
    scale: 16,
  };
  IrbRequestedMarker: any = {
    path: google.maps.SymbolPath.CIRCLE,
    fillColor: '#3e3636',
    fillOpacity: 1.0,
    strokeColor: '#FFFFFF',
    strokeOpacity: 1.0,
    strokeWeight: 1,
    scale: 16,
  };
  IrbSubmittedMarker: any = {
    path: google.maps.SymbolPath.CIRCLE,
    fillColor: '#0000FF',
    fillOpacity: 0.9,
    strokeColor: '#FFFFFF',
    strokeOpacity: 1.0,
    strokeWeight: 1,
    scale: 16,
  };
  IrbRevisionPendingMarker: any = {
    path: google.maps.SymbolPath.CIRCLE,
    fillColor: '#ff7034',
    fillOpacity: 1.0,
    strokeColor: '#FFFFFF',
    strokeOpacity: 1.0,
    strokeWeight: 1,
    scale: 16,
  };
  IrbApprovedMarker: any = {
    path: google.maps.SymbolPath.CIRCLE,
    fillColor: '#097969',
    fillOpacity: 0.9,
    strokeColor: '#FFFFFF',
    strokeOpacity: 1.0,
    strokeWeight: 1,
    scale: 16,
  };
  
  markerInfoContent = '';  //AAA

  markerOptions: google.maps.MarkerOptions = {
    
    draggable: false,
    animation: google.maps.Animation.DROP,
    visible: true, 
    opacity: 1,
    optimized: true,
    
    label: {
       color: '#FFFFFF',
       text: 'H',
  }
  };
  
  constructor(
    private authService: AuthService,
    private administrationService: IssAdministrationService,
    private piAdministrationService: PiAdministrationService,
    private spinner: NgxSpinnerService,
    private filterService: FilterService,
    private router: Router,
    private zone: NgZone,
    private geocoder: MapGeocoder,
    
    
    ) { }

    @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow | undefined;

    ngOnInit(): void {

      this.center = {lat: 21.885, lng: 1.279};
    // get all markers
    this.markers = [];
   // this.markerClusters = [];
   

    this.zone.run(() => {
      // This code will be executed in response to a change detection event.
    });

      this.getAllIrbs();
     }

    showSpinner = (): void => {
    this.spinner.show();    
     }

    hideSpinner=():void =>{
     this.spinner.hide();
     }

       
    getAllIrbs = (): void => {
      this.administrationService.getAllIrbs(this.authService.authorizationHeaderValue)
        .pipe(finalize(() => {
          this.busy = false;
          this.hideSpinner();
        }))
        .subscribe(
          results => {
            this.irbs = results;
            this.irbCount = this.irbs.length;
           //console.log(this.irbs);

           let i: number = 0;
            
            for (i = 0; i < this.irbCount; i++) {
              if (this.irbs[i].longitude != null && this.irbs[i].latitude != null) {
                this.pushIrbMarker(i);
              }
              else {
                  console.log(i, this.irbs[i]);
                this.getLongLat(i);
              }
  
            } 
              this.irbListingTable();

          });
          
     }

     getAdverseEventListForMap (adverseEventArray:string[]): string  {
      var list="";
      adverseEventArray.forEach(element => {
        if(element!=null){
          if(list=="")
         {list+= element;}
         else{
          list+=", "+element
         }
        
        }
      });
      //console.log(list);
      if(list=="")
      {
        list+="None reported";
      }
      return list
    }

     irbListingTable = (): any =>{  
        
      for (let j = 0; j < this.irbs.length; j++) {

        const irbSite = this.irbs[j];
        const site = irbSite.site;
            
        let irbInfo ={
                          count: j+1,
                          studyName:site.studyName,
                          siteName: irbSite.siteName,
                          diseaseArea: site.diseaseArea,
                          piName: site.piName,
                          irbStatus: this.approvalStatusClass(irbSite), 
                          irbId: irbSite.id,
                          conceptId: site.id,
                          country: irbSite.country
                        };
    
            this.allIrbListing.push(irbInfo);
      }
      this.filterElement(this.allIrbListing);
      
   console.log(this.allIrbListing)
      return this.allIrbListing;
    }

    approvalStatusClass = (aIrb: Irb): string => {
      if (this.approvalStatus(aIrb) == "Approved") {
        return "Approved";
      }
      if (this.approvalStatus(aIrb) == "Pending Revision") {
        return "Pending";
      }
      if (this.approvalStatus(aIrb) == "Submitted") {
        return "Submitted";
      }
      if (this.approvalStatus(aIrb) == "Requested") {
        return "Requested";
      }
      return "Requested";
    }
  
    approvalStatus = (aIrb: Irb): string => {
      if (aIrb.reviewDate != undefined && aIrb.reviewDate != null) {
  
  
        if (aIrb.outcome == true) {
          return "Approved";
        }
        if (aIrb.outcome == false) {
          return "Pending Revision";
        }
      }
      if (aIrb.submissionDate != undefined && aIrb.submissionDate != null) {
        return "Submitted";
      }
      return "Requested"
    }
  
    clickHandler = (rowData:number):any =>{
      // Handle the click event here.
      console.log(rowData);
      return
  }

  filterElement=(allIrbListing:any[]): any => {


    let studyNameList = this.allIrbListing.map(item => item.studyName);
    let siteNameList = this.allIrbListing.map(item => item.siteName);
    let diseaseAreList = this.allIrbListing.map(item => item.diseaseArea);
    let piNameList = this.allIrbListing.map(item => item.piName);
    let countryList = this.allIrbListing.map(item => item.country);
    //console.log(countryList , siteNameList);

    this.distinctStudyNameList = Array.from(new Set(studyNameList));
    this.distinctStudyNameList.sort((a, b) => a.localeCompare(b));
    this.distinctStudyNameListObject = this.distinctStudyNameList.map((item, index) => {
      return { studyName: item };});

    this.irbDistinctSiteNameList = Array.from(new Set(siteNameList));
    this.irbDistinctSiteNameList.sort((a, b) => a.localeCompare(b));
    this.irbDistinctSiteNameListObject = this.irbDistinctSiteNameList.map((item, index) => {
      return { siteName: item };});

    this.distinctDiseaseAreaList = Array.from(new Set(diseaseAreList));
    this.distinctDiseaseAreaList.sort((a, b) => a.localeCompare(b));
    this.distinctDiseaseAreaListObject = this.distinctDiseaseAreaList.map((item, index) => {
      return { diseaseArea: item };});

    this.irbDistinctPiNameList = Array.from(new Set(piNameList));
    this.irbDistinctPiNameList.sort((a, b) => a.localeCompare(b));
    this.irbDistinctPiNameListObject = this.irbDistinctPiNameList.map((item, index) => {
      return { piName: item };});

    this.irbDistinctCountryList = Array.from(new Set(countryList));
    this.irbDistinctCountryList.sort((a, b) => a.localeCompare(b));
    this.irbDistinctCountryListObject = this.irbDistinctCountryList.map((item, index) => {
        const countryCode = this.getCountryCodeFromLibrary(item);
        return {
          country: item,
          countryCode: countryCode
        };
      }); 
      console.log(this.irbDistinctCountryListObject);

    return this.distinctStudyNameListObject, this.irbDistinctSiteNameListObject,
            this.distinctDiseaseAreaListObject, this.irbDistinctPiNameListObject,
            this.irbDistinctCountryListObject
  }

  
/*filter(value: any) {
  console.log('Received value:', value);
  this.dt.filter(value, 'studyName', 'in');
  this.dt.filter(value, 'siteName', 'in');
  this.dt.filter(value, 'diseaseArea', 'in');
  this.dt.filter(value, 'piName', 'in');
  this.dt.filter(value, 'country', 'in');
}*/

filter(event: any) {
  // Apply filtering logic using PrimeNG's filter API
  this.dt.filter(event.value, 'studyName', 'in');
}

/*applyFilters() {
  this.filterService.filter(this.dt.value, 'studyName', 'in', this.selectedStudyNames);
    this.filterService.filter(this.dt.value, 'siteName', 'in', this.selectedSiteNames);
    this.filterService.filter(this.dt.value, 'diseaseArea', 'in', this.selectedDiseaseAreas);
    this.filterService.filter(this.dt.value, 'piName', 'in', this.selectedPiNames);
    this.filterService.filter(this.dt.value, 'country', 'in', this.selectedCountries); 
}*/


clear(table: Table) {
  table.clear();
}

getLocalFlagUrl = (countryCode: string): string =>{
  return `/assets/flags/${countryCode.toLowerCase()}.png`;
}

getCountryCodeFromLibrary = (countryName:string):string  => {
  return this.countryCodes[countryName];
}

 countryCodes = {
  'United States': 'US',
  'France': 'FR', 'UK': 'GB', 'Germany': 'DE', 'Russia': 'RU',
  'Brazil': 'BR', 'Peru': 'PE',
  'Togo': 'TG','Mali': 'ML','Guinea': 'GN', 'Ghana': 'GH',
  'USA': 'US',
  'Israel': 'IL',
  'China': 'CN', 'India': 'IN',
  'Australia': 'AU',
  
  // add more countries here
};


   

      getIrbInfoview = (irbId: number, j: number): void => {
        this.currentIrbId = irbId;
        var thisIrb = this.findIrbById(irbId);
        console.log('find Irb by Id ' + thisIrb);
        var thisSiteId= thisIrb.site.id;
        //get site analytics
        this.administrationService.getAnalyticsDataSummary(this.authService.authorizationHeaderValue, thisSiteId)
        .subscribe(results => {
          var siteAnalyticsDataSummary=results;
        this.administrationService.getIrbAnalyticsDataSummary(this.authService.authorizationHeaderValue, irbId)
          .subscribe(results => {
            var analyticsDataSummary = results;
            // console.log (results);
            var theString = '<div><b>Study Name</b> = ' + siteAnalyticsDataSummary.studyName + '</div>'
              + '<div><b>Product Under Investigation</b> = ' + siteAnalyticsDataSummary.requestedProduct + '</div>'
              + '<div><b>Coordinating Site</b> = ' + siteAnalyticsDataSummary.siteName + '</div>'
              + '<div><b>PI Name (Coordinator)</b> = ' + siteAnalyticsDataSummary.piName + '</div>'
              + '<div><b>Study Type </b>= ' + siteAnalyticsDataSummary.studyTypeDescription + '</div>'
              + '<div><b>Disease Area </b>= ' + siteAnalyticsDataSummary.diseaseArea + '</div>'
              + '<div><b>Protocol Version </b>= ' + analyticsDataSummary.protocolVersion + '</div>'
              + '<div><b>Number of Patients in the Study</b> = ' + siteAnalyticsDataSummary.numberOfPatients.toString() + '/ <b>' + analyticsDataSummary.expectedNumberOfPatientsForTheStudy.toString() + '</b></div>'   // Done on 12/14/2023
              + '<div><b>Study Total Budget</b> = $' + siteAnalyticsDataSummary.siteTotalBudget + '</div>'
              + '<div><b>List of Adverse Events for all sites in the study</b> = ' + this.getAdverseEventListForMap(siteAnalyticsDataSummary.adverseEventsList) + '</div>'
    
              + '<div><b>  ---------------IRB Site Info---------------' + '</div>'
              + '<div><b>Irb Site </b>= <b>' + analyticsDataSummary.irbSiteName + '</b></div>'
              + '<div><b>Irb (Local PI) </b>= ' + analyticsDataSummary.irbPiName + '</div>'
              + '<div><b>Irb Local MSL </b>= ' + analyticsDataSummary.irbMsl + '</div>'
              + '<div><b>Number of Patients  @ this Site</b> = ' + analyticsDataSummary.numberOfPatients.toString() + '/ <b>' + analyticsDataSummary.expectedNumberOfPatients.toString() + '</b></div>'
              + '<div><b>Inventory </b>= ' + analyticsDataSummary.inventory + '</div>'
              + '<div><b>IRB Protocol Version </b>= ' + analyticsDataSummary.irbProtocolVersion + '</div>'
              + '<div><b>IRB Outcome </b>= ' + analyticsDataSummary.irbOutcome + '</div>'
              + '<div><b>FPI</b> = ' + analyticsDataSummary.firstPatientIn + '</div>'
              
              + '<div><b>  ---------------Treatments Adverse Events---------------' + '</b></div>'
              + '<div><b>List of Adverse Events @ This Site</b> : ' + this.getAdverseEventListForMap(analyticsDataSummary.adverseEventsList) + '</div>'   ;;
    
            this.summaryData[j] = theString;
    
          })} );
      }

      findIrbById =(irbId :number):IIrb =>{

        return this.irbs.filter(thisIrb => thisIrb.id==irbId)[0];
        }

       

      //push the marker for irbs[i]
  pushIrbMarker = (i: number): void => {
    
    this.marker = new Marker();
    this.marker.position = new PositionMarker();
    this.marker.position.lat = Number(this.irbs[i].latitude);
    this.marker.position.lng = Number(this.irbs[i].longitude);
    this.marker.title = this.irbs[i].geoLocation;  // i.toString();  //
    
    this.marker.options = this.IrbMarkerColor(this.irbs[i], this.IRBstatusString);  //introduced for the IRB Marker color selection
    
    this.markerOptions = {
                           icon: this.IrbMarker,
                           zIndex: Number(google.maps.Marker.MAX_ZINDEX) + 100*i
    };

    this.marker.label = { text:this.irbs[i].id.toString(),
                          color:'#FFFFFF',
                        };

    this.marker.id = this.irbs[i].id;
    this.marker.markerIndex = this.markerIndex;
     
    this.marker.markerType = "irb";

    this.markers.push(this.marker);

      
    this.getIrbInfoview(this.irbs[i].id,this.markerIndex)
    this.markerIndex++;
    
  }

  IrbMarkerColor(aIrb: Irb, IRBstatusString: string):any{

    if (aIrb.reviewDate != undefined && aIrb.reviewDate != null) {


      if (aIrb.outcome == true) {
        return this.IrbApprovedMarker;
      }
      if (aIrb.outcome == false) {
        return this.IrbRevisionPendingMarker;
      }
    }
    if (aIrb.submissionDate != undefined && aIrb.submissionDate != null) {
      return this.IrbSubmittedMarker;
    }
    return this.IrbRequestedMarker;

   };

  

  move(event: google.maps.MapMouseEvent) {
    if (event.latLng != null) this.display = event.latLng.toJSON();
  }

  updateLongitudeLatitude = (a: IIrb): void => {
    this.administrationService.updateLatitudeLongitudeIrb(this.authService.authorizationHeaderValue, a)
      .pipe(finalize(() => {

      })).subscribe(
        results => {
          console.log(a);
        });

  }
  openInfoWindow(marker: MapMarker,i:number) {
    if (this.markers[i].markerType == "irb"){
      this.currentIrbId = this.markers[i].id;
      
      this.getIrb(this.currentIrbId);
      
    }
    else{
      this.currentSiteId = this.markers[i].id;
      this.currentIrbId =  this.markers[i].id;
      
    }
    let identifier = this.markers[i].markerIndex;
    
    let description = this.markers[i].title;
    if (this.summaryData[identifier] != null)
    {
    this.thisistheinfo=this.summaryData[identifier];
    }
    else{
    this.thisistheinfo = description;
    }
      if (this.infoWindow != undefined) this.infoWindow.open(marker);
      
  }

  getSite  =(siteId:number):void =>{
    
    this.siteBusy=true;
    this.showSpinner();
    this.piAdministrationService.getIssSite(this.authService.authorizationHeaderValue, siteId)
    .pipe(finalize(() => {
      this.siteBusy=false;
      this.hideSpinner();
    })).subscribe(
      result => {
        this.siteSetup=result;
        //console.log("Site Details", this.siteSetup);
        
      });
  }
  
  getIrb =(irbId:number):void =>{
    this.irbBusy=true;
    this.showSpinner();
    this.piAdministrationService.getSelectedIrb(this.authService.authorizationHeaderValue, irbId)
    .pipe(finalize(() => {
      this.irbBusy=false;
      this.hideSpinner();
    })).subscribe(
      result => {
        this.irb=result;
        this.siteId = this.irb.issSiteId;
        
        this.getSite(this.irb.issSiteId);
       
      });
  }

   // get longitude and latitude // update database//push marker
   getLongLat = (i: number): void => {
    let a = this.irbs[i];
    
    this.administrationService.trialomsGeocode(a.geoLocation)
      .pipe(finalize(() => {

      })).subscribe(
        results => {
          debugger;
          try {
            this.irbs[i].longitude = results.results[0].geometry.location.lng.toString();
            this.irbs[i].latitude = results.results[0].geometry.location.lat.toString();
            //console.log(results);
            
            this.updateLongitudeLatitude(this.irbs[i]);
            
            this.pushIrbMarker(i);
          }
          catch (e) {
            console.log(results);
            console.log('i=' + i.toString());
            console.log(e.message);
          }

        });   

  }
  
 

}
