
<div>
  <div class="row mb-1 justify-content-center">

    <div class="col-lg-12 concept" align="center">
      <div>
        <h3>Material Management Add To Stock!</h3>
      </div>
    </div>

     <form *ngIf="!busy" ngNativeValidate  #f="ngForm" (ngSubmit)="onSubmit()">

      <div class="row mb-1">
        <div class="col-lg-2 col-xs-12">
          <div class="form-group">
            <label for="batchNumber">Batch Number</label>
          </div>
        </div>

        <div class="col-lg-4 col-xs-12">
          <div class="form-group">
            <input type="text" id="batchNumber" name="batchNumber" [(ngModel)]="material.batchNumber"
                   class="form-control" required>
          </div>
        </div>


 
        <div class="col-lg-2 col-xs-12">
          <div class="form-group">
            <label for="expiryDate">Expiry Date</label>
          </div>
        </div>

        <div class="col-lg-4 col-xs-12">

          <div class="form-group">
            <input type="date" id="expiryDate" name="expiryDate"
                   [(ngModel)]="material.expiryDate" class="form-control" required>
          </div>

        </div>
 

      </div>
      
      <div class="row mb-1">
        <div class="col-lg-2 col-xs-12">
          <div class="form-group">
            <label for="numberOfItems">Number of Items</label>
          </div>
        </div>

        <div class="col-lg-4 col-xs-12">
          <div class="form-group">
            <input type="number" id="numberOfItems" name="numberOfItems" [(ngModel)]="material.numberOfItems"
                   class="form-control" required>
          </div>
        </div>

        <div class="col-lg-2 col-xs-12">
          <div class="form-group">
            <label for="manufRealeaseDate">Manuf. Release Date</label>
          </div>
        </div>

        <div class="col-lg-4 col-xs-12">

          <div class="form-group">
            <input type="date" id="manufacturingReleaseDate" name="manufacturingReleaseDate"
                   [(ngModel)]="material.manufacturingReleaseDate" class="form-control" required>  
          </div>

        </div>
        
      </div>

      <div class="row mb-1">
        
        <div class="col-lg-2 col-xs-12">
          <div class="form-group">
            <label for="leadTime">Lead Time </label>
          </div>
        </div>
        <div class="col-lg-4 col-xs-12">
          <div class="form-group">
            <input type="number" id="leadTime" name="leadTime" [(ngModel)]="material.leadTime"
                   class="form-control" required>
          </div>
        </div>
        
        <div class="col-lg-2">
          <select class="form-control" id="leadTimeUnit" name="leadTimeUnit"
          [(ngModel)]="material.leadTimeUnit" required title="Select a lead time unit">
              
              <option value="d">Days</option>
              <option value="w">Weeks</option>
              <option value="m">Months</option>
              <option value="y">Years</option>
          </select>
      </div>
      </div>

<div class="row mb-1">

  <div class="col-lg-2 col-xs-12">
  </div>
  <div class="col-lg-4 col-xs-12">
      <div class="form-group">
          <a class="btn btn-lg btn-primary btn-block" routerLink="/drugs/" 
              role="button">Cancel</a>
      </div>
  </div>

  <div class="col-lg-2 col-xs-12">
  </div>
  <div class="col-lg-4 col-xs-12">
      <div class="form-group">
          <button class="btn btn-lg btn-primary btn-block" type="submit" 
          [disabled]="!f.form.valid || disableUpdate">
              Update
          </button>
      </div>
  </div>
</div>


      <div *ngIf="error" class="alert alert-danger mt-3" role="alert">
        <strong>Oops!</strong> {{error}}
      </div>
    </form> 


  </div>

</div>
