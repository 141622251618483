<div class="container">

    <div class="row" >
      <div *ngIf="!busy" class="col-md-10">
        <a class="btn btn-lg btn-primary" 
        [routerLink]="['/newSite']"
        role="button" > Add New Site &raquo;</a> 
      </div>
    </div>
        <div class="row mb-1">
            <div *ngIf="!busy" class="col-md-10">
              <h3>List of sites sent from the server!</h3>
                <table class="table small">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Site Name</th>
                        <th scope="col">Description</th>
                        <th scope="col">Geolocation</th>
                        <th scope="col">Created By</th>  
                        <th scope="col">Modified By</th>  
                        <th scope="col">Active</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let site of sites; let i = index" [attr.data-index]="i">
                        <th scope="row">{{i+1}}</th>
                        <td>{{site.siteName}}</td>
                        <td>{{site.siteDescription}}</td>
                        <td>{{site.geoLocation}}</td> 
                        <td>{{site.createdBy}}</td> 
                        <td>{{site.modifiedBy}}</td> 
                        <td>{{!site.deleted}}  </td> 
                        <td><a class="btn btn-lg btn-primary" 
                            [routerLink]="['/updateSite' , site.id]"
                            role="button">Modify</a> </td>                 
                      
                        </tr>                 
                    </tbody>
                  </table>            
            </div>
        </div>
        <ngx-spinner type="pacman">
    <p>Loading...</p></ngx-spinner>  
    </div>
    
