<div class="row justify-content-center">
    <div class="col-md-8 text-center">
      <form *ngIf="!success" #f="ngForm" (ngSubmit)="onSubmit()">
        <!-- <img class="mb-4" src="assets/images/angular_solidBlack.svg" alt="" width="125" height="125"> -->
        <h1 class="h3 mb-3 font-weight-normal">New Company</h1>
        <div class="form-group">
            <label for="companyName" class="sr-only">Company Name</label>
            <input type="text" id="companyName" name="companyName" [(ngModel)]="company.companyName" class="form-control" placeholder="Company Name" required autoFocus>
        </div>
        <div class="form-group">
            <label for="companyAddress" class="sr-only">Company Address</label>
            <input type="text" id="companyAddress" name="companyAddress"  [(ngModel)]="company.companyAddress" class="form-control" placeholder="Company Address" required>
        </div>
        
        <a class="btn btn-lg btn-primary btn-block"
        routerLink="/company"
        role="button" >Cancel</a>
        <button class="btn btn-lg btn-primary btn-block" type="submit" [disabled]="!f.form.valid">Add Company</button>
        <ngx-spinner type="pacman">
    <p>Loading...</p></ngx-spinner>   
        <div *ngIf="error" class="alert alert-danger mt-3" role="alert">
            <strong>Oops!</strong> {{error}}
        </div>     
      </form>      
      <div *ngIf="success" class="alert alert-success" role="alert">
          <h4 class="alert-heading">Well done!</h4>
          <p>Company was created successfully, <a routerLink="/company">Please click here to continue</a>.</p>        
      </div>  
    </div> 
  </div>
