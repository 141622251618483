import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router'; // CLI imports router
import { Shell } from './../shell/shell.service';
import { AuthGuard } from '../core/authentication/auth.guard';
import { HospitalsComponent } from './hospitals/hospitals.component';
import { ProtocolSubmissionComponent } from './protocol-submission/protocol-submission.component';
import { CvSubmissionComponent } from './cv-submission/cv-submission.component';
import { IrbSubmissionComponent } from './irb-submission/irb-submission.component';
import { IrbAuditComponent } from './irb-audit/irb-audit.component';
import { AdverseEffectsComponent } from './adverse-effects/adverse-effects.component';
import { PatientComponent } from './patient/patient.component';
import { PatientScreeningComponent } from './patient-screening/patient-screening.component';
import { PatientRecruitmentComponent } from './patient-recruitment/patient-recruitment.component';
import { ScreeningResultsComponent } from './screening-results/screening-results.component'
import { ConsentFormComponent } from './consent-form/consent-form.component';
import { ConsentFormSignedComponent } from './consent-form-signed/consent-form-signed.component';
import { TreatmentComponent } from './treatment/treatment.component';
import { PatientTreatmentComponent } from './patient-treatment/patient-treatment.component';
import { RegisterComponent} from '../account/register/register.component'
import { MaterialDisposalComponent } from './material-disposal/material-disposal.component';
import { MaterialManagementComponent } from './material-management/material-management.component';
import { LocalPiReturnMaterialComponent } from './local-pi-return-material/local-pi-return-material.component';
import { MaterialReturnComponent } from './material-return/material-return.component';
const routes: Routes = [
  Shell.childRoutes([
    { path: 'hospitals', component: HospitalsComponent, canActivate: [AuthGuard] },
    { path: 'piProtocolSubmission/:conceptId', component: ProtocolSubmissionComponent, canActivate: [AuthGuard] },
    { path: 'cvSubmission/:conceptId', component: CvSubmissionComponent, canActivate: [AuthGuard] },
    { path: 'irbSubmission/:conceptId', component: IrbSubmissionComponent, canActivate: [AuthGuard] },
    { path: 'displayirb/:irbId', component: IrbSubmissionComponent, canActivate: [AuthGuard] },
    { path: 'adverseEvent/:conceptId', component: AdverseEffectsComponent, canActivate: [AuthGuard] },
    { path: 'irbAudit/:conceptId/:irbId', component: IrbAuditComponent, canActivate: [AuthGuard] },
    { path: 'patient/:irbId', component: PatientComponent, canActivate: [AuthGuard] },
    { path: 'patientscreening/:patientId', component: PatientScreeningComponent, canActivate: [AuthGuard] },
    { path: 'screeningresult/:patientId', component: ScreeningResultsComponent, canActivate: [AuthGuard] },
    { path: 'consentform/:patientId', component: ConsentFormComponent, canActivate: [AuthGuard] },
    { path: 'giveordenyconsent/:patientId', component: ConsentFormSignedComponent, canActivate: [AuthGuard] },
    { path: 'material', component: MaterialManagementComponent, canActivate: [AuthGuard] },
    { path: 'materialDisposal/:irbId', component: MaterialDisposalComponent, canActivate: [AuthGuard] },
    { path: 'treatment/:patientId', component:TreatmentComponent, canActivate:[AuthGuard]},
    {path: 'localpi/material', component:MaterialReturnComponent , canActivate:[AuthGuard]},
    {path: 'localpi/return/:materialId' , component:LocalPiReturnMaterialComponent,canActivate:[AuthGuard]},
    {path: 'pi/return/:materialId' , component:LocalPiReturnMaterialComponent,canActivate:[AuthGuard]}

  ])

]; // sets up routes constant where you define your routes

// configures NgModule imports and exports
@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
  providers: []
})
export class PrincipalInvestigatorRoutingModule { }