<div  >
    <h2>IRBs for local PI : {{userName}}</h2>

   

    <div *ngIf="totalIrbs==0  && !busy  && !irbBusy">
        <h2>Sorry there is no irb to display</h2>
    </div>
<div *ngIf="!busy">
    <div class="row mb-1" >
        <div class="col-lg-2"><b>Study Site Name</b></div>
        <div class="col-lg-2"><b>Disease Area</b></div>
        <div class="col-lg-2"><b>Product Under Investigation</b></div>
              <div class="col-lg-2" *ngIf="localIrbs"><b>Local Site</b></div>
    </div>
    <div class="row mb-1" *ngFor="let aSite of piSites let i = index" >
        <div class="col-lg-2">
            {{aSite.siteName}}
        </div>
        <div class="col-lg-2">
            {{aSite.diseaseArea}}
        </div>
        <div class="col-lg-2">
            {{aSite.requestedProduct}}
        </div>
         <div class="col-lg-2" *ngIf="localIrbs">
            
        </div>
        <!--<div class="col-lg-2">
            
        </div> -->
        <div class="col-lg-2">
            <a  class="btn btn-lg btn-primary me-4" routerLink="/irbSubmission/{{aSite.id}}" role="button">IRB
                    &raquo;&nbsp; </a> 
        </div>
    </div>
    <div class="row mb-1" *ngFor="let aIrb of piIrbs let i = index" >
        <div class="col-lg-2">
            {{aIrb.site.siteName}}
        </div>
        <div class="col-lg-2">
            {{aIrb.site.diseaseArea}}
        </div>
        <div class="col-lg-2">
            {{aIrb.site.requestedProduct}}
        </div>
         <div class="col-lg-2">
            {{aIrb.siteName}}
        </div>
        <!--<div class="col-lg-2">
            
        </div> -->
        
        <div class="col-lg-2" *ngIf="!patientManagement || aIrb.firstApprovalDate==null">
            <a  class="btn btn-lg btn-primary me-4" routerLink="/displayirb/{{aIrb.id}}" role="button">View this IRB
                    &raquo;&nbsp; </a> 
        </div>
        
        <div class="col-lg-2" *ngIf="patientManagement && aIrb.firstApprovalDate!=null">
            <a  class="btn btn-lg btn-primary me-4" routerLink="/patient/{{aIrb.id}}" role="button">View patients
                    &raquo;&nbsp; </a> 
        </div>
    </div>
</div>

<ngx-spinner type="pacman">
    <h2>Loading...</h2></ngx-spinner>
</div>

