import { Component } from '@angular/core';

import {Irb,IIrb} from 'src/app/shared/interfaces/irb';
import { Material, IMaterial} from 'src/app/shared/interfaces/material';
import {IConceptEvaluation} from 'src/app/shared/interfaces/conceptEvaluation';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators'
import { AuthService } from '../../core/authentication/auth.service';
import { IssAdministrationService } from '../iss-administration.service';
import { Router, CanActivate, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-material-management-view',
  templateUrl: './material-management-view.component.html',
  styleUrls: ['./material-management-view.component.sass']
})
export class MaterialManagementViewComponent {
  irbId: number;
  busy:boolean;
  allMaterials: IMaterial[];

  remainingDays: number;
  batchStatus: any[];
  materialsStatus: any[];

  sortType: string = '';
  sortReverse: boolean = false;

  constructor(private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private administrationService: IssAdministrationService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    
    this.irbId = +this.route.snapshot.paramMap.get('irbId');
    console.log('irb Id ='+ this.irbId.toString());
    this.getDeliveries(this.irbId);
  }
  getDeliveries(irbId:number){
    this.busy=true;
    this.administrationService.getDeliveries(this.authService.authorizationHeaderValue, irbId)
    .pipe(finalize(() => {
    })).subscribe(
      result => {
        this.busy=false;
        debugger;
        console.log(result);
        this.allMaterials=result;
        this.ExpirationDateEvaluation(this.allMaterials)

      });
  }

  ExpirationDateEvaluation = (allMaterials:IMaterial[]): any => {
  
    this.materialsStatus = [];
    let localDate = new Date();
    let currentDate = new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000);  //convert local time to UTC 
    
    let i:number = 0;

    for (i = 0; i<this.allMaterials.length; i++) {

    let lastDate = new Date(allMaterials[i].expiryDate);
  
    // Calculate the difference in milliseconds and convert into days
    
    this.remainingDays = Math.round((lastDate.getTime() - currentDate.getTime())/(1000 * 60 * 60 * 24));
    console.log(this.remainingDays);

    if(this.remainingDays <= 0 )
    {
      this.batchStatus = [this.remainingDays, "Expired Batch"]
    }
    else
    {
      this.batchStatus = [this.remainingDays, "   Days To Expiration" ]
    }
    
    this.materialsStatus.push(this.batchStatus);
    
  }
    
    return this.materialsStatus
  }

  getButtonColor(remainingDays: number): string {
    if (remainingDays> 30) {
        return '#00cc66';
    } else if (remainingDays > 0 && remainingDays < 30) {
        return 'orange';
    } else {
        return '#ff5050';
    }
}


}
