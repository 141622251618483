<div>
    <div>
        <form *ngIf="!busy" #f="ngForm" (ngSubmit)="onSubmit()">



            <div>
                <div class="row mb-1 justify-content-center">
                    <h3>Register Principal Investigator</h3>
                </div>
                <div class="row mb-1">
                    <div class="col-lg-12" id='addtrialomsUser'>
                    </div>
                </div>

                <div  class="col-lg-12 " align="center" style="background-color: #034289; color: white; height: 30px;"></div>              
<!------------------------------------------------------------------------>

<div class="p-grid p-justify-between p-mb-3">
    <div class="p-col-12" id="addtrialomsUser"></div>
</div>

<div class="p-grid p-justify-between p-mb-3">
    <div class="flex flex-column gap-2">
        <label for="email">Email</label>
        <span class="p-input-icon-right">
            <i class="pi pi-inbox"></i>
            <input pInputText id="email" name="email" type="text" 
            class="p-inputtext-lg"
            placeholder="Enter Email" [(ngModel)]="trialomsUser.email" required />
        </span>
    </div>

    <div class="flex flex-column gap-2">
        <label for="password">Password</label>
        <p-password [(ngModel)]="trialomsUser.password" [toggleMask]="true" 
                    placeholder="Enter password" (ngModelChange)="checkPasswords()"
                    id="password"   name="password"
                    required>
            <ng-template pTemplate="header">
                <h6>Pick a password</h6>
            </ng-template>
            <ng-template pTemplate="footer">
                <p-divider></p-divider>
                <p class="mt-2">Suggestions</p>
                <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                    <li>At least one lowercase</li>
                    <li>At least one uppercase</li>
                    <li>At least one numeric</li>
                    <li>Minimum 8 characters</li>
                </ul>
            </ng-template>
        </p-password>
    </div>
</div>

<div class="p-grid p-justify-between p-mb-3"> 

                 <!--------------------------------------------->

    <div class="flex flex-column gap-2"> 

        <label for="password">Name</label> 

         <span class="p-input-icon-right"> 

                <i class="pi pi-user"></i> 

            <input pInputText type="text"  placeholder="Enter Name" 
            id="name" name="name"
            class="p-inputtext-lg"
            [(ngModel)]="trialomsUser.name"  

            (ngModelChange)="onInputChange('name')" required/> 

         </span> 

    </div> 

        <div class="p-col-5"> 

            <div class="p-col-12" *ngIf="showError"> 

                <span class="fontRed"> 

                    {{feedback}} 

                </span> 

            </div> 


            <label for="password">Password</label>
            <p-password [(ngModel)]="trialomsUser.confirmPassword" [toggleMask]="true" 
                        placeholder="Confirm password" 
                        id="confirmPassword" name="confirmPassword"
                        (ngModelChange)="checkPasswords()" required> 

                <ng-template pTemplate="header"> 

                    <h6>Confirm the password</h6> 

                </ng-template> 

                <ng-template pTemplate="footer"> 

                    <p-divider></p-divider> 

                    <p class="mt-2">Suggestions</p> 

                    <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5"> 
                        <li>At least one lowercase</li> 
                        <li>At least one uppercase</li> 
                        <li>At least one numeric</li> 
                        <li>Minimum 8 characters</li> 

                    </ul> 

                </ng-template> 

            </p-password>  

        </div> 

</div> 

<!----------------------------------------------------------------------------------------->

                <div class="row mb-1">
                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <a class="btn btn-lg form-control btn-primary btn-block" [routerLink]="['/']"
                                role="button" title="Start Again">Cancel</a>
                        </div>
                    </div>
                    <div class="col-lg-5 col-xs-12">
                        
                    </div>

                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            
                           <button type="submit" class="btn btn-lg  btn btn-primary form-control"
                                [disabled]="!f.form.valid || disabledSubmit || !passwordMatch ||!strongPassword">
                                Register
                            </button>

                        </div>
                    </div>
                </div>
            </div>

            
        </form>
    </div>

    <ngx-spinner type="pacman">
    <h2>Loading...</h2></ngx-spinner>
</div>

