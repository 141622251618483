import { Component } from '@angular/core';

import { AuthService } from '../../core/authentication/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';

import { Router } from '@angular/router';

import { IssAdministrationService } from 'src/app/investigator-initiated-study/iss-administration.service';
import { PiAdministrationService } from 'src/app/principal-investigator/pi-administration.service'; 

interface parameters { 
  count?: number;
  componentInternal?: string; 
  durationInternal?: number; 
  unitInternal?: string; 
  componentExternal?: string; 
  durationExternal?: number; 
  unitExternal?: string; 
} 

@Component({
  selector: 'app-sponsor-parameters-setup',
  templateUrl: './sponsor-parameters-setup.component.html',
  styleUrls: ['./sponsor-parameters-setup.component.sass']
})
export class SponsorParametersSetupComponent {
  
  operationalParameters: parameters[] = [];
  durationUnit: any[] = [];
  Days: any;
  Months:any;
  selectedUnit: string;

  conceptReviewDuration: number;
  conceptSubmissionDuration: number;
  protocolReviewDuration: number;
  protocolSubmissionDuration: number;
  contractReviewDuration: number;
  contractSubmissionDuration: number;
  DocumentReviewDuration: number;
  DocumentSubmissionDuration: number;
  timeToSiteActivation: number;
  timeToDataAnalysisSinceLPO: number;
  timeToPublicationSinceLPO: number;
  timeToLastBudgetReconciliation: number;
  timeToSiteClosureSinceDataAnalysis: number;


  constructor(
    private authService: AuthService,
    private administrationService: IssAdministrationService,
    private piAdministrationService: PiAdministrationService,
    private spinner: NgxSpinnerService,
    private router: Router,
    
    ) { }

    ngOnInit() { 

      this.settingDurationUnits() ;
      
  };

  settingDurationUnits=(): any =>{
    this.operationalParameters = [];

    this.durationUnit = [
      
      { label: '', value: null },
      { label: 'Days', value: this.Days },
      { label: 'Months', value: this.Months }
    ];
    //console.log(this.durationUnit)

     let tempRows= [ 
      
      { 
          count: 1,
          componentInternal: 'Concept Evaluation', 
          durationInternal: this.conceptReviewDuration, 
          unitInternal: this.durationUnit[0].label, 
          componentExternal: 'Concept Submission', 
          durationExternal: this.conceptSubmissionDuration, 
          unitExternal: this.durationUnit[0].label, 

      }, 
    
     { 
        count: 2,
        componentInternal: 'Protocol Evaluation', 
        durationInternal: this.protocolReviewDuration, 
        unitInternal: this.durationUnit[0].label, 
        componentExternal: 'Protocol Submission', 
        durationExternal: this.protocolSubmissionDuration, 
        unitExternal: this.durationUnit[0].label,  

    }, 

     { 
       count: 3,
       componentInternal: 'Contract Evaluation', 
       durationInternal: this.contractReviewDuration, 
       unitInternal: this.durationUnit[0].label, 
       componentExternal: 'Contract Submission', 
       durationExternal: this.contractSubmissionDuration, 
       unitExternal: this.durationUnit[0].label,  

     }, 

     { 
      count: 4,
      componentInternal: 'Documents Evaluation', 
      durationInternal: this.DocumentReviewDuration, 
      unitInternal: this.durationUnit[0].label, 
      componentExternal: 'Documents Submission', 
      durationExternal: this.DocumentSubmissionDuration, 
      unitExternal: this.durationUnit[0].label,  

    }, 

    { 
      count: 5,
      componentInternal: 'Time to Site Activation', 
      durationInternal: this.timeToSiteActivation, 
      unitInternal: this.durationUnit[0].label, 
      componentExternal: 'Time to Site Activation', 
      durationExternal: this.timeToSiteActivation, 
      unitExternal: this.durationUnit[0].label,  

    }, 

    { 
      count: 6,
      componentInternal: 'Time to Data Analysis after LPO', 
      durationInternal: this.timeToDataAnalysisSinceLPO, 
      unitInternal: this.durationUnit[0].label, 
      componentExternal: 'Time to Data Analysis after LPO', 
      durationExternal: this.timeToDataAnalysisSinceLPO, 
      unitExternal: this.durationUnit[0].label,  

    }, 

    { 
      count: 7,
      componentInternal: 'Time to Publication after LPO', 
      durationInternal: this.timeToPublicationSinceLPO, 
      unitInternal: this.durationUnit[0].label, 
      componentExternal: 'Time to Publication after LPO', 
      durationExternal: this.timeToPublicationSinceLPO, 
      unitExternal: this.durationUnit[0].label,  

    }, 

    { 
      count: 8,
      componentInternal: 'Time to Budget Reconciliation after LPO', 
      durationInternal: this.timeToLastBudgetReconciliation, 
      unitInternal: this.durationUnit[0].label, 
      componentExternal: 'Time to Budget Reconciliation after LPO', 
      durationExternal: this.timeToLastBudgetReconciliation, 
      unitExternal: this.durationUnit[0].label,  

    }, 

    { 
      count: 9,
      componentInternal: 'Time to Site Closure after Data Analysis', 
      durationInternal: this.timeToSiteClosureSinceDataAnalysis, 
      unitInternal: this.durationUnit[0].label, 
      componentExternal: 'Time to Site Closure after Data Analysis', 
      durationExternal: this.timeToSiteClosureSinceDataAnalysis, 
      unitExternal: this.durationUnit[0].label,  

    }, 

    ]
    /*for (let i=0; i<tempRows.length; i++)
   { this.operationalParameters.push(tempRows[i])}*/
   Array.prototype.push.apply(this.operationalParameters, tempRows);
   //console.log(this.operationalParameters,this.operationalParameters.length)

    return this.durationUnit, this.operationalParameters;
  }

  
}
