import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {  Trial } from 'src/app/shared/interfaces/trial';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators'
import { AuthService } from '../../core/authentication/auth.service';
import { AdministrationService } from '../administration.service';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Component({
  selector: 'app-update-trial',
  templateUrl: './update-trial.component.html',
  styleUrls: ['./update-trial.component.sass']
})
export class UpdateTrialComponent implements OnInit {
  trial:any;
  success:boolean=false;
  busy:boolean=false;
  trialId:number;
  companyId:number;
  error:string;
  constructor(private authService: AuthService,
    private route: ActivatedRoute, 
    private router:Router, 
    private administrationService: AdministrationService, 
    private spinner: NgxSpinnerService) { 
    /* this.trial.trialName='';
    this.trial.trialAddress=''; */
  }

  ngOnInit() {
    this.trialId = +this.route.snapshot.paramMap.get('trialId');
    this.companyId=+this.route.snapshot.paramMap.get('companyId');
    //retrieve the trial/gettrial
    
    this.busy = true;
    this.spinner.show();
    this.administrationService.getTrial(this.authService.authorizationHeaderValue,this.trialId)
    .pipe(finalize(() => {
      this.spinner.hide();
    })).subscribe(
    result => {   
      this.busy = false;      
      this.trial = result;

   });
    console.log(`trial Id=${this.trialId}`)
  }
  onSubmit() {
    console.log('submit');
    this.busy=true;
    this.spinner.show();
    this.administrationService.updateTrial(this.authService.authorizationHeaderValue,
                this.trialId,
                this.trial)
    .pipe(finalize(() => {
      this.spinner.hide();
      this.busy = false;
    })).subscribe(
    result => {         
      //this.companies = result;
      this.router.navigate([`/companyTrials/${this.companyId}`]);//view list
   },
   error => {
     this.error = error; 
   });
  }

}
