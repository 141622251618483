<div>
    <div class="row mb-1 justify-content-center">

        <div  class="col-lg-12 concept" align="center">
            <div>
                <h3>Concept Evaluation</h3>
            </div>
            


        </div>
        <div class="col-lg-12 text-center" *ngIf="!historyOnly">

            <form *ngIf="!busy" #f="ngForm" (ngSubmit)="onSubmit()">

                <div class="row mb-1">
                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <label for="siteName">Institution Name</label>
                        </div>
                    </div>

                    <div class="col-lg-4 col-xs-12">
                        <div class="form-group">
                            <input type="text" id="siteName" name="siteName" [(ngModel)]="site.siteName"
                                class="form-control" readonly>
                        </div>
                    </div>

                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <label for="studyName">Study Name</label>
                        </div>
                    </div>
                
                

                    <div class="col-lg-4 col-xs-12">
                        <div class="form-group">

                            <input type="text" id="studyName" name="studyName" [(ngModel)]="site.studyName"
                                class="form-control" readonly>
                        </div>
                    </div>
                </div>
                <div class="row mb-1">
                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <label for="geoLocation">Address</label>
                        </div>
                    </div>

                    <div class="col-lg-10 col-xs-12">
                        <div class="form-group">
                            <input type="text" id="geoLocation" name="geoLocation" [(ngModel)]="site.geoLocation"
                                class="form-control" readonly>
                        </div>
                    </div>
                </div>

                <div class="row mb-1">
                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <label for="piName">PI </label>
                        </div>
                    </div>

                    <div class="col-lg-4 col-xs-12">
                        <div class="form-group">
                            <input type="text" id="piName" name="piName" [(ngModel)]="site.piName"
                                class="form-control" placeholder="Name" readonly>
                        </div>
                    </div>

                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <label for="piEmail"> </label>
                        </div>
                    </div>

                    <div class="col-lg-4 col-xs-12">
                        <div class="form-group">
                            <input type="email" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                id="piEmail" name="piEmail" [(ngModel)]="site.piEmail" class="form-control"
                                placeholder="Email" readonly>
                        </div>
                    </div>
                </div>

                <div class="row mb-1">
                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <label for="siteResearchCoordinatorName">Site Ressearch Coordinator</label>
                        </div>
                    </div>

                    <div class="col-lg-4 col-xs-12">
                        <div class="form-group">
                            <input type="text" id="siteResearchCoordinatorName"
                                name="siteResearchCoordinatorName"
                                [(ngModel)]="site.siteResearchCoordinatorName" class="form-control"
                                placeholder="Name" readonly>
                        </div>
                    </div>
                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <label for="piEmail"></label>
                        </div>
                    </div>

                    <div class="col-lg-4 col-xs-12">
                        <div class="form-group">
                            <input type="email" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                id="siteResearchCoordinatorEmail" name="siteResearchCoordinatorEmail"
                                [(ngModel)]="site.siteResearchCoordinatorEmail" class="form-control"
                                placeholder="Email" readonly>
                        </div>
                    </div>

                </div>


                <div class="row mb-1">
                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <label for="msl">MSL</label>
                        </div>
                    </div>

                    <div class="col-lg-4 col-xs-12">
                        <div class="form-group">
                            <input type="text" id="msl" name="msl" [(ngModel)]="site.msl"
                                class="form-control" placeholder="Name" required>
                        </div>
                    </div>
                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <label for="mslEmail"></label>
                        </div>
                    </div>

                    <div class="col-lg-4 col-xs-12">
                        <div class="form-group">
                            <input type="email" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                id="mslEmail" name="mslEmail" [(ngModel)]="site.mslEmail"
                                class="form-control" placeholder="Email" required>
                        </div>
                    </div>

                </div>

                <!---->
                <div class="row mb-1">

                    <div class="col-lg-2 col-xs-12">

                        <div class="form-group">
                            <label for="diseaseArea">Disease Area</label>
                        </div>
                    </div>

                    <div class="col-lg-4 col-xs-12">

                        <div class="form-group">
                            <input type="text" id="diseaseArea" name="diseaseArea"
                                [(ngModel)]="site.diseaseArea" class="form-control" readonly>
                        </div>
                    </div>

                </div>

                <div class="row mb-1">


                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <label for="protocol">Concept Submited on</label>
                        </div>
                    </div>

                    <div class="col-lg-4 col-xs-12">
                        <div class="form-group">
                            {{site.conceptSubmissionDate|date:'d MMMM y'}}
                        </div>
                    </div>

                </div>
                <div class="row mb-1" *ngIf="LastEvaluationDate!=null">
                    <div class="col-lg-12 col-xs-12 fontRed">
                        <p ><b>This is a re-evaluation</b>, the concept has been evaluated on &nbsp;
                            <b> {{LastEvaluationDate|date:'d MMMM y'}}</b>&nbsp; and review team decision was
                            &nbsp;<b>{{LastStatus}}</b></p>
                    </div>
                </div>
                <div class="row mb-1">
                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <label for="protocol">Evaluation Date</label>
                        </div>
                    </div>

                    <div class="col-lg-4 col-xs-12">
                        <div class="form-group">
                            <input type="date" id="conceptEvaluationDate" name="conceptEvaluationDate"
                                [(ngModel)]="site.conceptEvaluationDate" class="form-control"
                                (change)="evaluationDate()" 
                                placeholder="Concept Evaluation Date" required>
                            <!-- <input type="text" ngbDatepicker [(ngModel)]="site.conceptEvaluationDate" /> -->
                        </div>
                    </div>


                    <div *ngIf="!validEvaluationDate && evaluationDateErrorMessage.length>5"
                        class="col-lg-12 alert alert-danger">
                        {{evaluationDateErrorMessage}}
                    </div>
                    
                    <div class="col-lg-2 col-xs-12">

                        <div class="form-group">
                            <label for="conceptEvaluationStatus">Status</label>
                        </div>
                    </div>

                    <div class="col-lg-4 col-xs-12">

                        <div class="form-group">
                            <!-- -->
                            <select class="form-control" id="conceptEvaluationStatus" name="conceptEvaluationStatus"
                                [(ngModel)]="site.conceptEvaluationStatus" required>
                                <option value="Approved">Approved</option>
                                <option value="Rejected">Rejected</option>
                                <option value="Pending additional information">Pending additional information</option>
                            </select>
                        </div>
                    </div>


                </div>
                <div class="row mb-1">

                    <div class="col-lg-2 col-xs-12">

                        <div class="form-group">
                            <label for="conceptEvaluationComment">Evaluation Comment</label>
                        </div>
                    </div>
                    <div class="col-lg-10 col-xs-12">

                        <div class="form-group">
                            <input type="text" id="conceptEvaluationComment" name="conceptEvaluationComment"
                                [(ngModel)]="site.conceptEvaluationComment" class="form-control"
                                placeholder="Evaluation Comment" required>
                        </div>
                    </div>
                </div>
                <div class="row mb-1">

                    <div class="col-lg-2 col-xs-12">
                    </div>
                    <div class="col-lg-4 col-xs-12">
                        <div class="form-group">
                            <a class="btn btn-lg btn-primary btn-block" [routerLink]="['/siteSetup']"
                                role="button">Cancel</a>
                        </div>
                    </div>
                    <!--  </div>
            <div class="row mb-1"> -->
                    <div class="col-lg-2 col-xs-12">
                    </div>
                    <div class="col-lg-4 col-xs-12">
                        <div class="form-group">
                            <button class="btn btn-lg btn-primary btn-block" type="submit" 
                            [disabled]="!f.form.valid || !validEvaluationDate">
                                Update
                            </button>
                        </div>
                    </div>
                </div>

                <!--  <div class="row mb-1">
                Rajouter table des concept approval successifs
                <div class="col-lg-2 col-xs-12">
                </div>
                <div class="col-lg-10 col-xs-12">
                  <div class="form-group">
                    <button class="btn btn-lg btn-primary btn-block" type="submit" [disabled]="!f.form.valid">
                      {{createProtocolButtonText}}
                    </button>
                  </div>
                </div>
              </div> -->

                <div *ngIf="error" class="alert alert-danger mt-3" role="alert">
                    <strong>Oops!</strong> {{error}}
                </div>
            </form>

        </div>
    </div>
    <div *ngIf="!busy && !auditBusy && conceptEvaluations!=undefined && conceptEvaluations!=null">
        <div class="row mb-1 justify-content-center"  >
            <div class="col-lg-12 text-center">
                <div class="col-md-12">
                    <h3>History</h3>
                    <table class="table small">
                        <thead>

                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Concept Evaluation Date</th>
                                <th scope="col">Status</th>
                                <th scope="col">Comment</th>
                                <th scope="col">Created By</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let conceptEvaluation of conceptEvaluations; let i = index"
                                [attr.data-index]="i">
                                <th scope="row">{{i+1}}</th>
                                <td>{{conceptEvaluation.conceptEvaluationDate|date:'d MMMM y'}}</td>
                                <td>{{conceptEvaluation.conceptEvaluationStatus}}</td>
                                <td>{{conceptEvaluation.conceptEvaluationComment}}</td>
                                <td>{{conceptEvaluation.createdBy}}</td>
                            </tr>
                        </tbody>
                        
                    </table>
                </div>
                <ngx-spinner type="pacman">
    <p>Loading...</p></ngx-spinner>
            </div>
        </div>
        <div class="row mb-1 justify-content-center">
            <div class="col-lg-4 offset-lg-8 col-xs-12">
                <a class="btn btn-lg btn-primary form-control" ngbTooltip="Return"
                [routerLink]="['/siteSetup']"     
                      role="button">Back
                    </a> 
            </div>
        </div>
    </div>
    <ngx-spinner type="pacman">
    <p>Loading...</p></ngx-spinner>
</div>