import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/internal/operators/finalize';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { ClaimsService } from 'src/app/home/claims.service';
import { PiAdministrationService } from '../pi-administration.service';
import { IMaterial } from 'src/app/shared/interfaces/material';

@Component({
  selector: 'app-material-disposal',
  templateUrl: './material-disposal.component.html',
  styleUrls: ['./material-disposal.component.sass']
})
export class MaterialDisposalComponent implements OnInit {

  allMaterials: IMaterial[];
  name:string="";
  userName:string="";
  study:number;
  claimList=null;
  busy:boolean=false;

  constructor(private authService:AuthService,
    private pIAdministrationService: PiAdministrationService,
    private router:Router, private claims:ClaimsService) { }


  ngOnInit(): void {
    this.getProfile();
  }
 getProfile():void{
  this.claims.fetchData(this.authService.authorizationHeaderValue)
  .pipe(finalize(() => {
/*     this.spinner.hide();
    this.busy = false;
*/   })).subscribe(
  result => {         
    this.claimList = result;
    this.getDetails();
    this.getMaterial();
 });
}
getDetails(){

  this.claimList.forEach(element => {
    if(element.type=="name"){
      this.name=element.value;
    }
    if(element.type=="userName"){
      this.userName=element.value;
    }
  }); 
}
getMaterial(){
  //getPiMaterialInventory

  this.pIAdministrationService.getPiMaterialInventory(this.authService.authorizationHeaderValue,this.userName)
  .pipe(finalize(() => {
      this.busy = false;
  })).subscribe(
    result => {
      this.allMaterials=result;
      debugger;
    });
}
}
