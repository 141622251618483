<h1>New trial site</h1>
<div class="row justify-content-center">
    <div class="col-lg-12 text-center">
      <form *ngIf="!success" #f="ngForm" (ngSubmit)="onSubmit()">
        
        <div class="row mb-1">
          <div class="col-lg-3 col-xs-12">

            <div class="form-group">
              <label for="Areas" >Area</label>
            </div>
          </div>
          
          <div class="col-lg-6 col-xs-12">
            
            <div class="form-group">
              <select class="form-control" id="Areas" name="Areas" [(ngModel)]="trialSite.areaId" (click)="onClick()" required>
                <option *ngFor="let area of areas" [value]="area.id">{{area.areaName}}</option>
              </select>
            </div>
          </div>
        </div>
        <!--Site -->
        <div class="row mb-1">
          <div class="col-lg-3 col-xs-12">
            <div class="form-group">
              <label for="sites" >Site</label>
            </div>
          </div>
          
          <div class="col-lg-6 col-xs-12">
            <div class="form-group">
              <select class="form-control" id="sites" name="sites" [(ngModel)]="trialSite.siteId" 
              autoFocus
              (click)="onClick()" required>
                <option *ngFor="let site of sites" [value]="site.id">{{site.siteName}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row mb-1">
          
          <div class="col-lg-3 col-xs-12">
          </div>
          <div class="col-lg-6 col-xs-12">
            <div class="form-group">
              <a class="btn btn-lg btn-primary btn-block"
              [routerLink]="['/trialSites' , trialId]"
              role="button" >Cancel</a>
            </div>
          </div>
        </div>
        
        <div class="row mb-1">
          <div class="col-lg-3 col-xs-12">
          </div>
          <div class="col-lg-6 col-xs-12">
            <div class="form-group">
              <button class="btn btn-lg btn-primary btn-block" type="submit" [disabled]="!f.form.valid">Add Trial Site</button>
            </div>
          </div>
        </div>

        <ngx-spinner type="pacman">
    <p>Loading...</p></ngx-spinner>   
        <div *ngIf="error" class="alert alert-danger mt-3" role="alert">
            <strong>Oops!</strong> {{error}}
        </div>     

       
      </form>
    </div>
</div>


