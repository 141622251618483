import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators'
import { AuthService } from '../../core/authentication/auth.service';
import { AdministrationService } from '../administration.service';
import { Router, CanActivate, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Protocol } from 'src/app/shared/interfaces/protocol';
@Component({
  selector: 'app-trial-protocol',
  templateUrl: './trial-protocol.component.html',
  styleUrls: ['./trial-protocol.component.sass']
})
export class TrialProtocolComponent implements OnInit {
  companyId:number;
  protocol: Protocol;
  protocols:any;
  error:boolean;
  trial:any;
  success:boolean=false;
  trialId:number;
  busy:boolean=true;
  protocolBusy:boolean=true;//loading trials
  noProtocolExists:boolean=true;
  viewFormAddProtocol:boolean=false;
  viewButtonAddProtocol:boolean=false;
  createProtocolButtonText:string="Create Protocol";
  constructor(private route: ActivatedRoute,
    private authService: AuthService,
    private router:Router, 
    private administrationService: AdministrationService, 
    private spinner: NgxSpinnerService ) { 
    this.protocol=new Protocol();
    }

  ngOnInit(): void {
    this.busy=true;
    this.protocolBusy=true;
    this.spinner.show();
    this.trialId= +this.route.snapshot.paramMap.get('trialId');
    this.protocol=new Protocol();
    this.protocol.trialId=+this.trialId;
    //get trial
    this.busy=true;
    this.administrationService.getTrial(this.authService.authorizationHeaderValue,this.trialId)
    .pipe(finalize(() => {
      this.busy=false;
      this.getProtocols();
    })).subscribe(
    result => {        
      this.trial = result;
    this.companyId=this.trial.company.companyId;
    console.log(this.trial);
    });

    this.companyId = +this.route.snapshot.paramMap.get('companyId');
    //get protocols
    
  }
  getProtocols()
  {
    
    this.protocolBusy=true;
    this.administrationService.getProtocolbyTrialId(this.authService.authorizationHeaderValue,this.trialId)
    .pipe(finalize(() => {
      this.protocolBusy=false;
      if (!this.busy)
      {
        this.spinner.hide();
      }
    })).subscribe(
    result => {        
      this.protocols = result;
      if (this.protocols.length==0)
      {
        this.noProtocolExists=true;
        this.viewFormAddProtocol=true;
      }
      else
      {
        this.noProtocolExists=false;
        this.viewFormAddProtocol=false;
        this.createProtocolButtonText="Amend Protocol";
      }
    });
    
  }
  showFormAddProtocol(){
    this.viewFormAddProtocol=true;
  }
  onSubmit():void {
    console.log('submit');
    
    this.busy=true;
    this.spinner.show();
    this.administrationService.addProtocol(this.authService.authorizationHeaderValue,this.protocol)
    .pipe(finalize(() => {
      //+get all protocols
      
      this.busy = false;
      this.getProtocols();
      this.spinner.hide();
    })).subscribe(
    result => {         
      
      this.router.navigate([`/companyTrials/${this.companyId}`]);//view list
   },
   error => {
     this.error = error; 
   });
   
  }
}
