<div class="container">
  <div class="jumbotron mt-3" *ngIf="!isBusy">
    <h1>TrialOMS Home Page</h1>
    <p></p>
    <h2>Greetings {{name}}</h2>
    <p></p>
    <h2>{{userName}}</h2>

    <div  *ngIf="admin">

      <a class="btn btn-lg btn-primary me-4" routerLink="/siteSetup" role="button">Study&nbsp;Setup &raquo;</a>

      <a *ngIf="isAuthenticated" class="btn btn-lg btn-primary me-4" routerLink="/irbSubmission/1" role="button">IRB
        &raquo;</a>
      <a *ngIf="isAuthenticated" class="btn btn-lg btn-primary me-4" routerLink="/adverseEvent/1" role="button">Adverse
        Events&raquo;</a>
      <a *ngIf="isAuthenticated" class="btn btn-lg btn-primary me-4" routerLink="/patient/3" role="button">Treatments
        &raquo;</a>
    </div>
    <div  *ngIf="sponsor">
      <h2>You are the sponsor </h2>
      <a class="btn btn-lg btn-primary m-4" routerLink="/sponsor/dashboard/" role="button">My Dashboard &raquo;</a>

    </div>
    <div  *ngIf="principalInvestigator">

      <h2>You are the PI </h2>
      <a class="btn btn-lg btn-primary m-4" routerLink="/pi/dashboard" role="button">My Dashboard &raquo;</a>
      <a class="btn btn-lg btn-primary m-4" title="Click to create a new study or to manage existing studies" routerLink="/hospitals" role="button">My Studies &nbsp;&raquo;</a>
    </div>
    <div *ngIf="localpi">
      <h2>You are a local PI</h2>
      <a class="btn btn-lg btn-primary m-4" routerLink="/localpi/dashboard" role="button">My Dashboard &raquo;</a>

    </div>
<div>
  <p></p>
</div>

<!-- 
    <a *ngIf="isAuthenticated" class="btn btn-lg btn-primary ml-4" routerLink="/irbSubmission/1" role="button">IRB
      &raquo;</a>
    <a *ngIf="isAuthenticated" class="btn btn-lg btn-primary ml-4" routerLink="/adverseEvent/1" role="button">Adverse
      Events&raquo;</a>
    <a *ngIf="isAuthenticated" class="btn btn-lg btn-primary ml-4" routerLink="/patient/3" role="button">Traitements
      &raquo;</a>

 -->  
</div>
</div>