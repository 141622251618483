import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { SharedModule }   from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';

import { GoogleMapsModule } from '@angular/google-maps';
import { InvestigatorInitiatedStudyRoutingModule} from './investigator-initiated-study.routing-module'
import { IssAdministrationService} from './iss-administration.service';
import { PiAdministrationService } from 'src/app/principal-investigator/pi-administration.service';
import { SiteSetupComponent } from './site-setup/site-setup.component';
import { ConceptApprovalComponent } from './concept-approval/concept-approval.component';
import { ProtocolSubmissionComponent } from './protocol-submission/protocol-submission.component';
import { ProtocolApprovalComponent } from './protocol-approval/protocol-approval.component';
import { NegotiationComponent } from './negotiation/negotiation.component';
import { DocumentComponent } from './document/document.component';
import { AuditComponent } from './audit/audit.component';
import { PatientRecruitmentComponent } from './patient-recruitment/patient-recruitment.component';
import { SponsorAggregateComponent } from './sponsor-aggregate/sponsor-aggregate.component';

import { DashboardComponent } from './dashboard/dashboard.component';
import { PiDashboardComponent } from './pi-dashboard/pi-dashboard.component';
import { DiseaseAreaComponent } from './disease-area/disease-area.component';
import { PrincipalInvestigatorComponent } from './principal-investigator/principal-investigator.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from  '@angular/material/input';
import { MatSelectModule} from '@angular/material/select'
import { MatIconModule} from '@angular/material/icon';
import { MatButtonModule} from '@angular/material/button';
import { HttpClient } from '@angular/common/http';
import { MaterialManagementComponent } from './material-management/material-management.component';
import { MaterialManagementViewComponent } from './material-management-view/material-management-view.component';
import { MaterialManagementAddComponent } from './material-management-add/material-management-add.component'; 
import { MatCardModule } from '@angular/material/card';

import { CardModule } from 'primeng/card';
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { KnobModule } from 'primeng/knob';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { ChartModule } from 'primeng/chart';
import { TabViewModule } from 'primeng/tabview';
import { RegisterPiComponent } from './register-pi/register-pi.component';
import { RegisterLocalPiComponent } from './register-local-pi/register-local-pi.component';
import { RegisterSponsorComponent } from './register-sponsor/register-sponsor.component';
import { PasswordModule } from 'primeng/password';
import { SponsorIrbsAllComponent } from './sponsor-irbs-all/sponsor-irbs-all.component';
import { SponsorIrbDetailComponent } from './sponsor-irb-detail/sponsor-irb-detail.component';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { TooltipModule } from 'primeng/tooltip';
import { HttpClientModule } from '@angular/common/http';
import { InputNumberModule } from 'primeng/inputnumber';
import { SponsorParametersSetupComponent } from './sponsor-parameters-setup/sponsor-parameters-setup.component';
import { CreateParameterComponent } from './create-parameter/create-parameter.component';
import { ViewParametersComponent } from './view-parameters/view-parameters.component';
import { UpdateParameterComponent } from './update-parameter/update-parameter.component';
import { ParameterAuditComponent } from './parameter-audit/parameter-audit.component';

@NgModule({
  declarations: [SiteSetupComponent, ConceptApprovalComponent, ProtocolSubmissionComponent, 
                ProtocolApprovalComponent, NegotiationComponent, DocumentComponent, AuditComponent, 
                PatientRecruitmentComponent, DashboardComponent, PiDashboardComponent, DiseaseAreaComponent, 
                PrincipalInvestigatorComponent, AnalyticsComponent, MaterialManagementComponent, 
                MaterialManagementViewComponent, MaterialManagementAddComponent, RegisterPiComponent, 
                RegisterLocalPiComponent, RegisterSponsorComponent, SponsorIrbsAllComponent, 
                SponsorIrbDetailComponent, SponsorAggregateComponent, SponsorParametersSetupComponent, CreateParameterComponent, ViewParametersComponent, UpdateParameterComponent, ParameterAuditComponent],

  providers: [IssAdministrationService, PiAdministrationService],

  imports: [
    CommonModule,
    SharedModule,
    FormsModule, 
    ReactiveFormsModule,
    NgbModule,
    InvestigatorInitiatedStudyRoutingModule,
    GoogleMapsModule,
    MatTabsModule,  //AAA down
    BrowserModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatCardModule,

    CardModule, TableModule, KnobModule,
    FlexLayoutModule, ButtonModule,DropdownModule,
    ChartModule, TabViewModule, PasswordModule,
    DragDropModule, TooltipModule, MultiSelectModule,
    HttpClientModule, InputNumberModule
    

  ]
})
export class InvestigatorInitiatedStudyModule { 
  file: File;

  constructor(private http: HttpClient) {}
  

  uploadFile() {
    this.http.post('api/upload', this.file)
      .subscribe(
        (response) => {
          console.log('File uploaded successfully');
        },
        (error) => {
          console.log('Error uploading file');
        }
      );
  }

}
