<div>
    <div>
        <form *ngIf="!busy && !treatmentBusy" #f="ngForm" (ngSubmit)="onSubmit()">
            <div>
                <div class="row mb-1 justify-content-center">
                    <h3>Treatment</h3>
                </div>
                <div class="row mb-1">
                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <label for="patientNumber"><b>Patient Number</b></label>
                        </div>
                    </div>

                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <span>{{patient.patientNumber}}</span>
                        </div>
                    </div>

                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <label for="dateOfBirth"><b>Date of Birth</b></label>
                        </div>
                    </div>

                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <span>{{patient.dateOfBirth|date:'d MMMM y'}}</span>
                        </div>
                    </div>

                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group ">
                            <label for="consentSignedDate"><b>Patient Enrolled On</b></label>
                        </div>
                    </div>

                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <span>{{patient.consentSignedDate|date:'d MMMM y'}}</span>
                        </div>
                    </div>

                </div>
<!------------------------------------------------------------------------------------------->
                <div class="row mb-1">
                    <div class="col-lg-2 col-sx-12">

                        <div class="form-group">
                            <label for="treamentType"><b>Treatment Type</b></label>
                        </div>
                    </div>
                    <div class="col-lg-3 col-xs-12">

                        <div class="form-group">
                            <!-- -->
                            <select class="form-control" id="treamentType" name="treamentType"
                                [(ngModel)]="treatment.treatmentType" required>
                                <option value="0">According to Protocol</option>
                                <option value="1">Adverse Events</option> 
                                <option value="2">Follow up Visit</option>

                            </select>
                        </div>
                    </div>


                    <div class="col-lg-2 col-xs-12" *ngIf="treatment.treatmentType=='0'">
                        <div class="form-group">
                            <label for="vialId"><b>Vial/Batch Number</b></label>
                        </div>
                    </div>

                   <!-- <div class="col-lg-3 col-xs-12" *ngIf="treatment.treatmentType=='0'">
                        <div class="form-group">
                            <input type="text" id="vialId" name="vialId" [(ngModel)]="treatment.vialId"
                                class="form-control" placeholder="Batch Number" required>
                        </div>
                    </div>-->

                    <div class="col-lg-3 col-xs-12" *ngIf = "treatment.treatmentType == '0'">
                       
                            <select class = "form-control" 
                                    id = "vialId" name = "vialId" 
                                    [(ngModel)] = "treatment.vialId"
                                    placeholder = "Active Batches Selection" 
                                    required
                                    (change) = "batchSelection(treatment.vialId)">  

                                <option >None</option>
                                <option *ngFor = "let vialId of batchNumbers" [value] = "vialId"> {{vialId}} </option>

                             </select>
                    </div>

                   

                    <!-----------------Batch information------------------------->
           
            <div *ngIf="treatment.treatmentType=='0' && treatment.vialId != null && treatment.vialId != 'None' " 
                  class="card flex justify-content-center sliderbox " 
                  [style]="{ height: '100px', width: '550px'}">
               
                <button type="button" 
                    [ngStyle] = "{'background-color': getButtonColor(remainingTime), 'color': 'black'}"><b>{{remainingTime}}</b> Day(s) to Batch Expiration 
                </button>
                
               <mat-slider [min] = "sliderMin" [max] = "sliderMax">
                           <input [value] ="matSliderStartThumbValue" matSliderStartThumb>
                           <input [value] = "matSliderEndThumbValue" matSliderEndThumb>

              </mat-slider>

              
            </div>

            <!--------------------End of Batch Information--------------------------->
            
                    <!--............................AE detailling/reporting Section........................-->


                    <div  *ngIf="treatment.treatmentType=='1'">
                        <div class="row mb-1">
                    <div class="col-lg-2 col-sx-12">   
                        <div class="form-group fontRed">
                            <label for="aeSymptomsSelection"><b>AE Symptoms</b></label>
                       </div>
                    </div>

                    <div class="col-lg-3 col-xs-12">
                        <div class="form-group">
                            <select id="aeSymptomsSelection" name="aeSymptomsSelection" [(ngModel)]="treatment.aeSymptomsSelection"
                                class="form-control" placeholder="Select AE Symptoms" required  (change)="onSelectionChange($event)"> 
                                
                               <option *ngFor="let adverseEvent of adverseEventList" [value]="adverseEvent.adverseEventName">{{adverseEvent.adverseEventName}}</option>
                               
                               <option value="Other">Other</option> 

                            </select>

                            <input *ngIf="isOtherSelected" type="text" [(ngModel)]="treatment.otherAESymptomsSelection" class="form-control" placeholder="Enter NOT Listed  AE Symptoms" required>

                        <!--<select id="aESymptomsSelection" name="aESymptomsSelection" [(ngModel)]="aESymptomsSelection"
                            class="form-control" placeholder="Select AE Symptoms" multiple required > 
                            
                           
                           <option *ngFor="let adverseEvent of adverseEventListForDropDownMenu" [value]="adverseEvent">{{adverseEvent}}</option>
                            <option value="Other">Other</option>
                        </select>-->

                        </div>
                    
                    </div>

                    <div class="col-lg-3 col-sx-12">   
                        <div class="form-group fontRed"
                        title="Enter first date the patient noticed symptoms">
                            <label for="aeOnsetDate"><b>AE's Onset Date</b></label>
                       </div>
                    </div>

                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <input type="date" id="aeOnsetDate" name="aeOnsetDate" [(ngModel)]="treatment.aeOnsetDate"
                            class="form-control" placeholder="First AE Symptoms' Date" required >
                        </div>
                    </div>

                   
                </div>
                
                <div class="row mb-1">
                    <div class="col-lg-2 col-sx-12">   
                        <div class="form-group fontRed" 
                        Title="Click to access the reference document for CTCAE V5.0">
                        
                            <label for="aeSeverity"> <a href="https://ctep.cancer.gov/protocoldevelopment/electronic_applications/docs/ctcae_v5_quick_reference_5x7.pdf"
                                                        target="blank" rel="noopener noreferrer"> <b>Severity</b> </a> </label>
                       </div>
                    </div>

                        <div class="col-lg-3 col-xs-12" >
                        <div class="form-group" >
                            
                                <select class="form-control" id="aeSeverity" name="aeSeverity"
                                [(ngModel)]="treatment.aeSeverity" placeholder="Select severity grade" required>
                                <option value="0"><b>Grade 1</b></option>
                                <option value="1"><b>Grade 2</b></option> 
                                <option value="2"><b>Grade 3</b></option>
                                <option value="3"><b>Grade 4</b></option> 
                                <option value="4"><b>Grade 5</b></option> 

                            </select>
                        </div>
                    
                    </div>
                   <!--------------------------->
                   <div class="col-lg-3 col-sx-12">  
                    <div class="form-group fontRed"
                    title="Select if Product-related AE">
                        <label for="aeRelatedCausality"><b>Causality</b></label>
                   </div>
               </div>

                <div class="col-lg-2 col-xs-12">

                   
                        <input type="checkbox" id="causality" 
                        name="causality" [(ngModel)]="treatment.aeRelatedCausality"
                        class="redBox" > <span *ngIf="treatment.aeRelatedCausality">YES - Product-related</span>

                   
                </div>
                <!--  <div class="col-lg-2 col-xs-12">

                   <div class="form-group ">
                        <input type="checkbox" id="causality" 
                        name="causality" [(ngModel)]="treatment.aERelatedCausality"
                        class="form-control redBox" >YES - Product-related AE

                    </div>
                </div> -->

                    </div>

                    <!------------------------------------>

                    
                <div class="row mb-1">
                    <div class="col-lg-2 col-sx-12">   
                        <div class="form-group fontRed">
                        
                            <label for="aeOoutcome"> <b>AE Outcome</b> </label>
                       </div>
                    </div>

                        <div class="col-lg-3 col-xs-12" >
                        <div class="form-group" >
                            
                                <select class="form-control" id="aeOutcome" name="aeOutcome"
                                [(ngModel)]="treatment.aeOutcome" placeholder="Select outcome " required>
                                <option value="0"><b>Recovered/Resolved</b></option>
                                <option value="1"><b>Recovered/Not Resolved</b></option> 
                                <option value="2"><b>Recovered/Resolved with sequalae</b></option>
                                
                                                                 
                            </select>
                        </div>
                    
                    </div>
                   <!--------------------------->
                   <div class="col-lg-3 col-sx-12">  
                    <div class="form-group fontRed"
                    title="Enter date of successful AE treatment outcome">
                        <label for="aeEndDate"><b>AE's End Date</b></label>
                    </div>
                 </div>

                 <div class="col-lg-2 col-xs-12">
                     <div class="form-group">
                         <input type="date" id="aeEndDate" name="aeEndDate" [(ngModel)]="treatment.aeEndDate"
                         class="form-control" placeholder="End AE Symptoms' Date" required >
                     </div>
                 </div>


                    </div>

                    
                <div class="row mb-1">
                    <div class="col-lg-2 col-sx-12">   
                        <div class="form-group fontRed">
                        
                            <label for="aeInducedAction"> <b>Actions taken due to AE</b> </label>
                       </div>
                    </div>

                        <div class="col-lg-3 col-xs-12" >
                        <div class="form-group" >
                            
                                <select class="form-control" id="aeInducedAction" name="aeInducedAction"
                                [(ngModel)]="treatment.aeInducedAction" placeholder="Select Action Taken due to AE" required>
                                <option value="0"><b>Dose unchanged - Continue as specify in the protocol</b></option>
                                <option value="1"><b>Dose reduced</b></option>
                                <option value="2"><b>Dose withheld</b></option> 
                                <option value="3"><b>Discontinued from treatment - Stop administration of study product</b></option> 
                                
                                 

                            </select>
                        </div>
                    
                    </div>

                     <!--------------------------->
                   <div class="col-lg-3 col-sx-12">  
                    <div class="form-group fontRed"
                    title="AE treatment cost">
                        <label for="adverseEventTreatmentCost"><b>AE's Treatment Cost</b></label>
                    </div>
                 </div>

                 <div class="col-lg-2 col-xs-12">
                     <div class="form-group">
                         <input type="number" id="adverseEventTreatmentCost" name="adverseEventTreatmentCost" [(ngModel)]="treatment.adverseEventTreatmentCost"
                         class="form-control" placeholder="$" required >
                     </div>
                 </div>

                    </div>
                             
                    
                 </div>
                    
                </div> 
                
<!----------------------------------------End of AE detailling/reporting section------------------------------------------->
                
                <div class="row mb-1">

                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <label for="treatmentDate"><b>Date of Treatment</b></label>
                        </div>
                    </div>

                    <div class="col-lg-3 col-xs-12">
                        <div class="form-group">

                            <input type="date" id="treatmentDate" name="treatmentDate"
                                [(ngModel)]="treatment.treatmentDate" class="form-control" (change)="checkDate()" required>
                        </div>
                    </div>
                    <div class="col-lg-5 col-xs-12 alert alert-danger" role="alert" *ngIf="error">
                        {{errorMessage}}
                    </div>

                    <div class="col-lg-2 col-sx-12" *ngIf="treatment.treatmentType=='0'">   
                        <div class="form-group">
                            <label for="doseSelection"><b>Dose Selection</b></label>
                       </div>
                    </div>

                    <div class="col-lg-3 col-xs-12" *ngIf="treatment.treatmentType=='0'">
                        <div class="form-group"  >
                            
                                <select class="form-control" id="doseSelection" name="doseSelection"
                                [(ngModel)]="treatment.doseSelection" placeholder="Select dosing option (Full vs. adjusted dose)" required>
                                <option value="0"><b>1 - Full dose as indicated in protocol</b></option>
                                <option value="1"><b>2 - Adjusted dose - Provide justifications</b></option> 
                                
                                </select>
                        </div>
                    
                    </div>

                </div>
               <div class="row mb-1">
                <div class="col-lg-2 col-xs-12">

                    <div class="form-group">
                        <label for="miscellaneousPaymentsToPatient"><b>Miscellaneous Payments to Patient ($)</b></label>
                    </div>
                </div>
                <div class="col-lg-3 col-xs-12">
                    <div class="form-group">

                        <input type="number" id="miscellaneousPaymentsToPatient" name="miscellaneousPaymentsToPatient" 
                            [(ngModel)]="treatment.miscellaneousPaymentsToPatient"
                            placeholder="$"
                            class="form-control" required 
                            min = '0'>
                
                    </div>
                </div>
               </div>
                <div class="row mb-1" >
                    <div class="col-lg-2 col-xs-12">

                        <div class="form-group">
                            <label for="comments"><b>Treatment Comments</b></label>
                        </div>
                    </div>

                    <div class="col-lg-10 col-xs-12">
                        <div class="form-group">

                            <textarea id="comments" name="comments" [(ngModel)]="treatment.comments"
                                class="form-control" required>
                    </textarea>
                        </div>
                    </div>
                </div>

                <div class="row mb-1">

                    <div class="col-lg-2 col-xs-12">

                    </div>
                    <div class="col-lg-3 col-xs-12">
                        <div class="form-group">
                            <button type="submit" class="btn btn-primary form-control" [disabled]="!f.form.valid || error">
                                Add
                            </button>
                        </div>
                    </div>

                </div>

            </div>
        </form>
    </div>
    <div *ngIf="!busy && !treatmentBusy">
        <div class="row mb-1 justify-content-center">
            <div class="col-lg-12 text-center">
                <div *ngIf="!busy" class="col-md-12">
                    <h3>Patient Treatment History</h3>

  <!--Filter for Treatment Type-->                 
  <div class="row mb-1">
       <div class="col-lg-2 col-xs-12">
    
      <div class="form-group">

          <label for="Type"><b>Filter by Treatment Type</b></label>
          
      </div>
      </div>
<div class="col-lg-3 col-sx-12">

    <div class="form-group">
        
        <select class="form-control" id="treatmentType" name="treatmentType"
        [(ngModel)]="statusNumber"  (change)= "filterStatus()">
            <option value="9">All Treatments</option>
            <option value="0">According to Protocol</option>
            <option value="1">Adverse Events</option>
            <option value="2">Follow Up Visits</option>
           
        </select>
    </div>
</div>

<!--<div class="row mb-1 justify-content-md-center">  -->
    <div class="col-lg-4 col-xs-12">
        <div class="form-group">
            <label for="patientAuditCount"><b># of Treatments for this Patient (Filtered/Total)</b></label>
        </div>
    </div>

    <div class="col-lg-3 col-xs-12 font-weight-bold">
        <div class="form-group">
           <!-- <input type="number" id="treatmentAuditCount" name="treatmentAuditCount" [(ngModel)]="treatmentAuditCount"
        class="form-control" readonly>-->
        <a  class="btn btn-primary form-control TREATMENTSCOUNT"
        role="button" title={{treatmentAuditCount}} aria-disabled="true">
       {{treatmentListFilteredCount}} / {{treatmentAuditCount}}
        </a>
        </div>
    </div>
<!--</div>-->

</div>
  <!---End of filter-->

  <div class="row mb-10"></div>
  <div class="row mb-10"></div>
  
                    <div>
                        <div class="row mb-1">

                            <div class="col-lg-1  font-weight-bold"> #</div>
                            <div class="col-lg-3  font-weight-bold">Treatment Date</div>
                            <div class="col-lg-3  font-weight-bold">Type</div>
                            <div class="col-lg-2  font-weight-bold">Protocol Version</div>
                            <div class="col-lg-3  font-weight-bold">Vial/Batch# </div>

                        </div>
                 <div *ngFor="let aTreatment of treatmentListFiltered; let i = index" [attr.data-index]="i" >


                            <div class="row mb-1" title="Treatment number {{aTreatment.id}} According to Protocol Approved by IRB on ????">
                                
                               <!-- <div class="col-lg-1 fontRed font-weight-bold" >{{treatmentAuditCount-i}}</div> -->
                                

                                <div class="col-lg-1 fontRed font-weight-bold" *ngIf="statusNumber=='9'"   for>{{treatmentAuditCount-i}}</div>
                                <div class="col-lg-1 fontRed font-weight-bold" *ngIf="statusNumber!=='9'"  for>{{treatmentListFilteredCount-i}}</div>

                                <div class="col-lg-3 ">{{aTreatment.treatmentDate|date:'d MMM y H:mm:ss'}}</div>

                                <div class="col-lg-3" *ngIf="aTreatment.treatmentType=='0'" >Protocol</div>
                                <div class="col-lg-3" *ngIf="aTreatment.treatmentType=='1'" >Adverse Event</div>
                                <div class="col-lg-3" *ngIf="aTreatment.treatmentType=='2'" >Follow up Visit</div>
                                <div class="col-lg-2 ">{{aTreatment.protocolVersion}}</div>
                                <div class="col-lg-3" *ngIf="aTreatment.treatmentType=='0'" >{{aTreatment.vialId}}</div>
                                <div class="col-lg-3" *ngIf="aTreatment.treatmentType!='0'" >N/A</div>
                               
                                
                     
                
                    <div class="col-lg-10 col-xs-10" *ngIf="aTreatment.comments!=null">
                        <div class="form-group ">
 
                             <textarea type="text" id="aTreatment.comments" name="aTreatment.comments" [(ngModel)]=  "aTreatment.comments"
                            class="form-control TREATMENTSCOMMENTBOX" readonly> </textarea>
                        </div>
                    </div>

             
                              
                     </div>
            </div>
                    </div>
                </div>
               <!-- </div>-->
                

                <!---->

            </div>
        </div>
    </div>
    <ngx-spinner type="pacman">
        <p>Loading...</p>
    </ngx-spinner>
</div>