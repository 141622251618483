import { IPatient  } from "./patient";

export class Treatment
{
    public id: number;
    public patientId: number;
    public treatmentDate: Date;
    public vialId: string;
    public treatmentType: number;
    public protocolVersion: string;
    public comments: string;
    public createdBy: string;
    public dateCreated: Date;
    public modifiedBy: string;
    public dateModified: Date;
    public deleted: boolean;
    public aeSymptomsSelection: string;
    public otherAeSymptomsSelection: string;   //To handle the selection of not listed AE
    public aeSeverity: string;
    public aeOnsetDate: Date;
    public aeEndDate: Date;
    public aeRelatedCausality: boolean;
    public aeOutcome: number;
    public doseSelection: number;
    public aeInducedAction: number;
    public aeTreatmentCost: number;
    public adverseEventTreatmentCost:number;
    public miscellaneousPaymentsToPatient:number;
}
export interface ITreatment
{
    id:number;
    patientId:number;
    treatmentDate:Date;
    vialId:string;
    treatmentType:number;
    protocolVersion:string;
    comments:string;
    createdBy:string;
    dateCreated:Date;
    modifiedBy: string;
    dateModified: Date;
    deleted:boolean;
    aeSymptomsSelection: string;
    aeSeverity: string;
    aeOnsetDate: Date;
    aeEndDate: Date;
    aeRelatedCausality: boolean;
    aeOutcome: number;
    aeInducedAction: number;
    doseSelection: number;
    adverseEventTreatmentCost:number;
    miscellaneousPaymentsToPatient:number;
    patient:IPatient;
}
export interface ITreatmentAudits
{
    id:number;
    treatmentId:number;
    patientId:number;
    treatmentDate:Date;
    vialId:string;
    treatmentType:number;
    protocolVersion:string;
    comments:string;
    createdBy:string;
    dateCreated:Date;
    modifiedBy: string;
    dateModified: Date;
    deleted:boolean;
    aeSymptomsSelection: string;
    aeSeverity: string;
    aeOnsetDate: Date;
    aeEndDate: Date;
    aeRelatedCausality: boolean;
    aeOutcome: number;
    aeInducedAction: number;
    doseSelection: number;
    adverseEventTreatmentCost:number;
    miscellaneousPaymentsToPatient:number;
}