import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators'
import { AuthService } from '../../core/authentication/auth.service';
import { PiAdministrationService } from '../pi-administration.service';
import { IssAdministrationService } from '../../investigator-initiated-study/iss-administration.service';
import { Router, CanActivate, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Patient, IPatient } from 'src/app/shared/interfaces/patient';
import { ISiteSetup, SiteSetup } from 'src/app/shared/interfaces/siteSetup';
import { Treatment, ITreatment } from 'src/app/shared/interfaces/treatment';
import { IIrb } from 'src/app/shared/interfaces/irb';
import { IAdverseEvents } from 'src/app/shared/interfaces/adverseEvent';
import { IMaterial } from 'src/app/shared/interfaces/material';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { Injectable } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';


@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-treatment',
  templateUrl: './treatment.component.html',
  styleUrls: ['./treatment.component.sass']
})
export class TreatmentComponent implements OnInit {
irbId:number=0;
irb:IIrb;
conceptId:number=0;
  patientId:number=0;
  busy: boolean = true;
  irbBusy:boolean=true;
  error:boolean=false;
  errorMessage:string;
  siteId:number=0;
  siteSetup:ISiteSetup;
  siteBusy:boolean=false;
  protocolVersion: string="0";
  treatmentBusy:boolean=false;
  treatmentList:ITreatment[];
  treatmentListFiltered:ITreatment[];
  statusNumber:string="9";
  statusString:string;
  treatmentAuditCount:number;  // Added by Armel to count the number of treatment
  treatmentListFilteredCount:number; // To count the filtering result
  treatment:Treatment=new Treatment();
  biologicalGender:string;
  patient:Patient=new Patient();
  patientList:IPatient[];
  
  adverseEventList:IAdverseEvents[];
  adverseEventListForDropDownMenu: Array<string>;
  patientListBusy:boolean=true;//busy retrieving patient List
  patientTooltip:string="Click to toggle between Add and Search"
  patientNumber:string="";
  dateOfBirth:Date; //=new  Date('Jan 1, 2021');

  form: FormGroup;

  material: IMaterial;
  materialInventoryList: IMaterial[] = [];
  //materialInventoryListFiltered: IMaterial[] = [];
  materialInventoryListCount: number;
  matSliderStartThumbValue: number = 0;
  matSliderEndThumbValue: number = 100;
  batchInfo: any[] = [];
  batchNumbers: string[] = [];
  availableBatchNumbersAtSite: string = null;
 
  manufDate:Date;
  sliderMin: number = 0;
  sliderMax: number = 0;
  remainingTime: number;
  
  
constructor(private route: ActivatedRoute,
  private formBuilder: FormBuilder,
  private authService: AuthService,
  private router: Router,
  private pIAdministrationService: PiAdministrationService,
  private administrationService: IssAdministrationService,
  private spinner: NgxSpinnerService,
  private primeNGConfig: PrimeNGConfig) 
  { this.form = new FormGroup({
    vialId: new FormControl(''),
  });}

  ngOnInit(): void {
    this.busy=true;
    this.patientId = +this.route.snapshot.paramMap.get('patientId');
    this.treatment.patientId=this.patientId;
    this.patient=new Patient();
    this.error=false;
    this.getPatient(this.patientId);

    this.form = this.formBuilder.group({
      change: new FormControl(''),
    });

    this.form.get('change').valueChanges.subscribe((value) => {
      this.batchSelection(value);
    });
    
  }

  checkDate():void{
    this.error=false;
    if (this.treatmentList.length>0){

      var lastTreatmentDate=this.treatmentList[this.treatmentList.length-1].treatmentDate;
      if(this.treatment.treatmentDate<lastTreatmentDate ){

        this.error=true;
        this.errorMessage="Treatment date must be after last treatment";
      }
    }
    if (new Date(this.treatment.treatmentDate)>new Date()){
      this.error=true;
      this.errorMessage="Treatment Date cannot be in the future";
    }
    //verify that date is <=today and date > date of last treatment
  }
  getPatient=(patientId:number):void => {
    this.busy=true;
    this.showSpinner();
    this.pIAdministrationService.getSelectedPatient(this.authService.authorizationHeaderValue, this.patientId)
      .pipe(finalize(() => {
        this.busy=false;
        this.hideSpinner();
      })).subscribe(
        result => {
          this.patient = result;
          this.irbId=this.patient.irbId;
          console.log("IRB Id = ",this.irbId);
          this.getIrb(this.irbId);
          
          console.log("Patient info",result);
          this.getTreatmentList(this.patient.id);
          this.getMaterialInventoryList(this.irbId)
          
        });

  }
  getIrb =(irbId:number):void =>{
    this.irbBusy=true;
    this.showSpinner();
    this.pIAdministrationService.getSelectedIrb(this.authService.authorizationHeaderValue, irbId)
    .pipe(finalize(() => {
      this.irbBusy=false;
      this.hideSpinner();
    })).subscribe(
      result => {
        this.irb=result;
        this.siteId = this.irb.issSiteId;
        //get site
        console.log("irb");
        console.log("This IRB details",result);
        debugger;
        this.getSite(this.irb.issSiteId);
        this.getAdverseEventList();
      });
  }
  //
  getSite  =(siteId:number):void =>{
    
    this.siteBusy=true;
    this.showSpinner();
    this.administrationService.getIssSite(this.authService.authorizationHeaderValue, siteId)
    .pipe(finalize(() => {
      this.siteBusy=false;
      this.hideSpinner();
    })).subscribe(
      result => {
        this.siteSetup=result;
        console.log("Site Details", result);
        //console.log(result);
        if(this.siteSetup.protocolVersion!=null)
        {
          this.protocolVersion = this.siteSetup.protocolVersion;
        }
        else {
          
        this.protocolVersion = "0";
        }
        console.log(`Protocol Version ${this.protocolVersion}`);
        //get site
        
        //this.getTreatmentList(this.patient.id);
      });
  }
  getAdverseEventList=(): void=>{
    this.conceptId=this.irb.issSiteId;
    console.log(this.conceptId);
    this.spinner.show();
    this.busy = true;
    this.pIAdministrationService.getAdverseEvents(this.authService.authorizationHeaderValue, this.conceptId)
      .pipe(finalize(() => {
        this.busy = false;
        this.hideSpinner();
      })).subscribe(
        result => {
          this.adverseEventList = result;
          this.adverseEventListForDropDownMenu = this.adverseEventList.map(obj => obj.adverseEventName);
          console.log(this.adverseEventList);
          console.log(this.adverseEventListForDropDownMenu);
        });
  }
  addTreatment = (): void =>{ 
    this.treatmentBusy=true;
    this.showSpinner();
    this.treatment.patientId=this.patientId;//make sure that this is populated before sending to server
    this.pIAdministrationService.addTreatment(this.authService.authorizationHeaderValue, this.treatment)
      .pipe(finalize(() => {
        this.treatmentBusy= false;
        this.hideSpinner();
      })).subscribe(
        result => { 
          console.log(result);
          this.getTreatmentList(this.patientId);
          this.treatment=new Treatment();
        });
  }

  getMaterialInventoryList = (irbId: number): void => {
    
    this.pIAdministrationService.getAllMaterialInventory(this.authService.authorizationHeaderValue, irbId)//this.irbId will be provided here
      .pipe(finalize(() => {
        //this.patientListBusy=false;
        this.hideSpinner();
      })).subscribe(
        result => {
          this.materialInventoryList = result;
console.log("Inventory List",this.materialInventoryList);
          //this.materialInventoryListFiltered = result;
          this.materialInventoryListCount = this.materialInventoryList.length; //To count the result of filtering
console.log("IRB site # ",this.irbId,", # of Batches =",this.materialInventoryListCount);

this.batchSelection(this.availableBatchNumbersAtSite);

           });  
      
  }

  batchSelection = (vialId:string) : any =>{
      
     
     //Initialiazation
    this.sliderMin = 0;
    this.sliderMax = 0;
    this.matSliderStartThumbValue = 0;
    this.matSliderEndThumbValue = 0;
     
    console.log(`Selected batch: ${vialId}`);
 
   let rowIndex:number = null;
 
 rowIndex = this.materialInventoryList.findIndex(row => row.batchNumber === vialId);  //to find the row's index associated with "selected batch:  vialId"
 
 this.materialSummaryTable(this.irbId);  // to construct the dropdown menu for the batch selection
 //console.log(this.materialInventoryList[rowIndex].manufacturingReleaseDate,this.materialInventoryList[rowIndex].expiryDate);
 this.calculateDaysDiff(this.materialInventoryList[rowIndex].manufacturingReleaseDate, this.materialInventoryList[rowIndex].expiryDate); //will  update the manufacturing date once it is available in the database


 console.log("all batches Info", this.materialInventoryList);
 console.log("selected batch number", vialId );
 console.log("Row Index", rowIndex);
 console.log("Selected batch info", this.materialInventoryList[rowIndex]);
 
return [this.batchNumbers, this.sliderMin,this.matSliderStartThumbValue,this.matSliderEndThumbValue,  this.sliderMax]

  } 
  
  materialSummaryTable = (irbId:number): any =>{
    this.batchInfo = [];
    this.batchNumbers = [];

      let i:number =0;

            
      for (i = 0; i<this.materialInventoryListCount; i++) 
      {
          let localDate = new Date();
          let currentDate = new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000);  //convert local time to UTC 
          let lastDate = new Date(this.materialInventoryList[i].expiryDate);

      if (currentDate < lastDate) 
      {
         this.batchNumbers.push(this.materialInventoryList[i].batchNumber);  // For the dropdown batch menu at treatment site, eliminating expired batches
      }
      this.batchInfo.push([this.materialInventoryList[i].batchNumber, 
                            this.materialInventoryList[i].numberOfItems, 
                            this.materialInventoryList[i].expiryDate])
       
      }
    
      //console.log("Active batches listing at Treatment site", this.batchNumbers);
      //console.log("to be used for Table....Include expired batches",this.batchInfo);
      

      return this.batchNumbers   //to obtain the batch numbers at this site
      
    }

 
  calculateDaysDiff = (manufDate: Date, expiryDate:Date): any => {

    //console.log("manufDate and Exp Date of Selected batch",manufDate, expiryDate);
    //Initialiazation
    //this.sliderMin = 0;
    //this.sliderMax = 0;
    this.matSliderStartThumbValue = 0;
    this.matSliderEndThumbValue = 0;
    this.remainingTime = 0;
    
    
   
    
    let localDate = new Date();
    let currentDate = new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000);  //convert local time to UTC 
    //let firstDate = new Date(manufDate);
    let lastDate = new Date(expiryDate);
    
    //Calculation
    
  {
    //this.sliderMin = Math.round((firstDate.getTime())/(1000 * 60 * 60 * 24)); 
    //this.sliderMax = Math.round((lastDate.getTime())/(1000 * 60 * 60 * 24));
    this.updateSliderValues(manufDate, expiryDate);
   
    this.matSliderStartThumbValue = Math.round((currentDate.getTime())/(1000 * 60 * 60 * 24));
    this.matSliderEndThumbValue = Math.round((lastDate.getTime())/(1000 * 60 * 60 * 24));

    this.remainingTime = Math.round((lastDate.getTime() - currentDate.getTime())/(1000 * 60 * 60 * 24));

  }
    
    //console.log(this.sliderMax, expiryDate);
    //console.log(firstDate, lastDate);
    //Calculate the difference in milliseconds then Convert to days and return
    
    console.log("matSliderStartThumbValue",this.matSliderStartThumbValue);
    console.log("matSliderEndThumbValue",this.matSliderEndThumbValue);
    console.log("Extremites" ,this.sliderMin, this.sliderMax);
   
    console.log("# days before expiration", this.remainingTime);
   
   // return [this.sliderMin, this.matSliderStartThumbValue, this.matSliderEndThumbValue, this.sliderMax, this.remainingTime]

    return [this.matSliderStartThumbValue, this.matSliderEndThumbValue,  this.remainingTime]

  }

  updateSliderValues(manufDate: Date, expiryDate:Date) {

    this.sliderMin = 0;
    this.sliderMax = 0;
    let firstDate = new Date(manufDate);
    let lastDate = new Date(expiryDate);
    
    //Calculation
    
  {
    this.sliderMin = Math.round((firstDate.getTime())/(1000 * 60 * 60 * 24)); 
    this.sliderMax = Math.round((lastDate.getTime())/(1000 * 60 * 60 * 24));

  }

}

  getButtonColor(remainingTime: number): string {
    if (remainingTime > 30) {
        return '#00cc66';
    } else if (remainingTime > 0 && remainingTime <= 30) {
        return '#FEA51D';
    } else {
        return '#ff5050';
    }
}

  getTreatmentList=(patientId:number):void => {
    this.treatmentBusy=true;
    this.showSpinner();
    this.pIAdministrationService.getPatientTreatments(this.authService.authorizationHeaderValue, patientId)
      .pipe(finalize(() => {
        this.treatmentBusy=false;
        this.hideSpinner();
      })).subscribe(
        result => {
          this.treatmentList=result;
          this.treatmentListFiltered=result;
          this.treatmentAuditCount=this.treatmentList.length; // counting the number of treatments/visits in the list 
          this.treatmentListFilteredCount=this.treatmentListFiltered.length; //Counting fitering results
          debugger;
          this.treatmentList.forEach(x =>{

            if(x.protocolVersion==null || x.protocolVersion==undefined || x.protocolVersion=="")
            { x.protocolVersion="V0"}
            
          }
          );
          debugger;
          console.log("All treatments", result);
          //console.log(result);
        });

  }
  showSpinner = (): void => {
    this.spinner.show();

  }
  hideSpinner = (): void => {
    if (this.busy || this.treatmentBusy || this.irbBusy) {
      console.log ( this.busy); 
      console.log(this.treatmentBusy);
      return;
    }
    this.spinner.hide();
  }


//Filter Implementation

filterStatus= ():void=>{
    var myStatusString=["", "All Treatments","According to Protocol", "Adverse Events",
    "Follow Up Visits"]

  let index=Number(this.statusNumber);
  this.statusString=myStatusString[index];
  
  console.log(`${this.statusString} number= ${index}`);
  debugger;
  if(index > 3){
    this.treatmentListFiltered=this.treatmentList
    this.treatmentListFilteredCount=this.treatmentListFiltered.length; //Counting fitering results
  }
  else{

    this.treatmentListFiltered=this.treatmentList.filter(aTreatment=>
      aTreatment.treatmentType == index);
    this.treatmentListFilteredCount=this.treatmentListFiltered.length; //Counting fitering results
  }
}

  treatmentType = (aTreatment:Treatment): string => {
    if (aTreatment.treatmentType==0 )
      {
        return "Protocol";
      }
     
    if (aTreatment.treatmentType==1)
    {
        return "Adverse Events";
    }
    
    if (aTreatment.treatmentType==2)
    {
        return "Follow Up Visits" ;
    }
     
}

isOtherSelected = false;

onSelectionChange(event: any) {
  this.isOtherSelected = event.target.value === 'Other';
}

onFormSubmit() {
  if (this.isOtherSelected) {
    this.treatment.aeSymptomsSelection = this.treatment.otherAeSymptomsSelection;
  }

  // Add your form submission logic here
}

//nextStepLink=(aTreatment:Treatment):[string,number]=> {}

//nextStepClass=(aTreatment:Treatment): string => {}

nextStepTitle=(aTreatment:Treatment):string=>{
  return "";
}

  // End of Filter

  onSubmit = (): void => {
    this.addTreatment();
  }
}   
