<div>
    <div class="row mb-1 justify-content-center ">

        <div class="col-lg-12 protocol" align="center">
            <h3>Protocol Submission</h3>


        </div>
        <div class="col-lg-12 text-center" *ngIf="!historyOnly">

            <form *ngIf="!busy" #f="ngForm" (ngSubmit)="onSubmit()">

                <div class="row mb-1">
                    <div class="col-lg-2 col-xs-12 ">
                        <div class="form-group">
                            <label for="siteName">PI Institution Name</label>
                        </div>
                    </div>

                    <div class="col-lg-4 col-xs-12 ">
                        <div class="form-group">
                            <input type="text" id="siteName" name="siteName" [(ngModel)]="site.siteName"
                                class="form-control" readonly>
                        </div>
                    </div>

                    <div class="col-lg-2 col-xs-12 ">
                        <div class="form-group">
                            <label for="studyName">Study Name</label>
                        </div>
                    </div>

                    <div class="col-lg-4 col-xs-12 ">
                        <div class="form-group">

                            <input type="text" id="studyName" name="studyName" [(ngModel)]="site.studyName"
                                class="form-control" readonly>
                        </div>
                    </div>

                    <div class="col-lg-2 col-xs-12 ">
                        <div class="form-group">
                            <label for="geoLocation">PI Address</label>
                        </div>
                    </div>

                    <div class="col-lg-10 col-xs-12 ">
                        <div class="form-group">
                            <input type="text" id="geoLocation" name="geoLocation" [(ngModel)]="site.geoLocation"
                                class="form-control" readonly>
                        </div>
                    </div>
                </div>
                <!---->

                <div class="alert alert-primary" *ngIf="isCollapsed" (click)="isCollapsed = !isCollapsed"
                    aria-controls="siteInfo">Click here for more info on this site

                </div>
                <div class="alert alert-secondary" *ngIf="!isCollapsed" (click)="isCollapsed = !isCollapsed"
                    aria-controls="siteInfo">Click here for less info on this site

                </div>
                <div class="col-lg-12 text-center collapse" id='siteInfo' [ngbCollapse]="isCollapsed">

                    <div class="card">
                        <div class="card-body">
                            <!---->
                            <div class="row mb-1">
                                <div class="col-lg-2 col-xs-12 ">
                                    <div class="form-group">
                                        <label for="piName">PI </label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xs-12 ">
                                    <div class="form-group">
                                        <input type="text" id="piName" name="piName" [(ngModel)]="site.piName"
                                            class="form-control" placeholder="Name" readonly>
                                    </div>
                                </div>

                                <div class="col-lg-2 col-xs-12 ">
                                    <div class="form-group">
                                        <label for="piEmail"> </label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xs-12 ">
                                    <div class="form-group">
                                        <input type="email" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                            id="piEmail" name="piEmail" [(ngModel)]="site.piEmail" class="form-control"
                                            placeholder="Email" readonly>
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-1">
                                <div class="col-lg-2 col-xs-12 ">
                                    <div class="form-group">
                                        <label for="siteResearchCoordinatorName">Site Ressearch Coordinator</label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xs-12 ">
                                    <div class="form-group">
                                        <input type="text" id="siteResearchCoordinatorName"
                                            name="siteResearchCoordinatorName"
                                            [(ngModel)]="site.siteResearchCoordinatorName" class="form-control"
                                            placeholder="Name" readonly>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-xs-12 ">
                                    <div class="form-group">
                                        <label for="piEmail"></label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xs-12 ">
                                    <div class="form-group">
                                        <input type="email" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                            id="siteResearchCoordinatorEmail" name="siteResearchCoordinatorEmail"
                                            [(ngModel)]="site.siteResearchCoordinatorEmail" class="form-control"
                                            placeholder="Email" readonly>
                                    </div>
                                </div>

                            </div>


                            <div class="row mb-1">
                                <div class="col-lg-2 col-xs-12 ">
                                    <div class="form-group">
                                        <label for="msl">MSL</label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xs-12 ">
                                    <div class="form-group">
                                        <input type="text" id="msl" name="msl" [(ngModel)]="site.msl"
                                            class="form-control" placeholder="Name" readonly>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-xs-12 ">
                                    <div class="form-group">
                                        <label for="mslEmail"></label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xs-12 ">
                                    <div class="form-group">
                                        <input type="email" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                            id="mslEmail" name="mslEmail" [(ngModel)]="site.mslEmail"
                                            class="form-control" placeholder="Email" readonly>
                                    </div>
                                </div>

                            </div>

                            <!---->
                            <div class="row mb-1">

                                <div class="col-lg-2 col-xs-12 ">

                                    <div class="form-group">
                                        <label for="diseaseArea">Disease Area</label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xs-12 ">

                                    <div class="form-group">
                                        <input type="text" id="diseaseArea" name="diseaseArea"
                                            [(ngModel)]="site.diseaseArea" class="form-control" readonly>
                                    </div>
                                </div>

                            </div>

                            <div class="row mb-1">


                                <div class="col-lg-2 col-xs-12 mb-1">
                                    <div class="form-group">
                                        <label for="protocol">Concept Submited on</label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xs-12">
                                    <div class="form-group">
                                        {{site.conceptSubmissionDate|date:'d MMMM y'}}
                                    </div>
                                </div>

                            </div>

                            <div class="row mb-1">

                                <div class="col-lg-2 col-xs-12 ">
                                    <div class="form-group">
                                        <label for="concept">Concept Evaluated on</label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xs-12 ">
                                    <div class="form-group">
                                        {{site.conceptEvaluationDate|date:'d MMMM y'}}
                                    </div>
                                </div>
                                <div class="col-lg-2 col-xs-12 ">

                                    <div class="form-group">
                                        <label for="conceptEvaluationStatus">Status</label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xs-12 ">

                                    <div class="form-group">
                                        <!-- -->
                                        <input type="text" class="form-control" id="conceptEvaluationStatus"
                                            name="conceptEvaluationStatus" [(ngModel)]="site.conceptEvaluationStatus"
                                            readonly>
                                    </div>
                                </div>


                            </div>
                            <div class="row mb-1">

                                <div class="col-lg-2 col-xs-12 ">

                                    <div class="form-group">
                                        <label for="conceptEvaluationComment">Evaluation Comment</label>
                                    </div>
                                </div>
                                <div class="col-lg-10 col-xs-12">

                                    <div class="form-group">
                                        <input type="text" id="conceptEvaluationComment" name="conceptEvaluationComment"
                                            [(ngModel)]="site.conceptEvaluationComment" class="form-control"
                                            placeholder="Evaluation Comment" readonly>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="row mb-1" *ngIf="LastEvaluationDate!=null">
                    <div class="col-lg-12 col-xs-12 fontRed">
                        <p><b>This is a Submission for a re-evaluation</b>, the protocol has been last evaluated on&nbsp;
                            <b> {{site.protocolEvaluationDate|date:'d MMMM y'}}</b>&nbsp; and review team decision was
                            &nbsp;<b>{{LastStatus}}</b></p>
                    </div>
                </div>
             <!--Protocol Evaluation section-->

                <div class="row mb-1">
                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <label for="concept">Protocol Submission Date</label>
                        </div>
                    </div>
                    <div class="col-lg-4 col-xs-12">
                        <div class="form-group">

                                <input type="date" id="protocolSubmissionDate" name="protocolSubmissionDate"
                                [(ngModel)]="site.protocolSubmissionDate" class="form-control"
                                (change)="SubmissionDate()" placeholder="Protocol Submission Date" required autofocus>
                                
                        </div>
                        
                    </div>

                
                        <!-- Submission Date Evaluation error Display-->

                    <div *ngIf="!validSubmissionDate && submissionDateErrorMessage.length>5"
                        class="col-lg-12 alert alert-danger">
                        {{submissionDateErrorMessage}}
                       
                    </div>
                        
                </div>

                

                <!--Addition if TRIAl in PROGRESS-->

         
                <div class="row mb-1">
                    <div class="col-lg-2 col-xs-12 fontRed ">
                        <label
                            for="trialStarted" style="font-weight: bold;"> Trial In Progress (Protocol Amendment)</label>
                    </div>
                    <div class="col-lg-2 col-xs-12">
                        <div *ngIf="trialStarted ==true">
                        
                       <input type="checkbox" id="trialStarted"  name="trialStarted" [(ngModel)]="trialStarted" readonly>
                    </div>

                    <div *ngIf="trialStarted == false">
                        
                        <input type="checkbox" id="trialStarted"  name="trialStarted" [(ngModel)]="trialStarted">
                     </div>

                    </div>

                    <div class="col-lg-2 col-xs-12 "></div>
                    <div class="col-lg-2 col-xs-12 fontRed ">
                        <div class="form-group" >
                            <label for="protocolVersion">Protocol Version</label>
                        </div>
                    </div>

                    <div class="col-lg-4 col-xs-12 ">
                        <div class="form-group" *ngIf="trialStarted==true">
                            <input type="text" id="protocolVersion" name="protocolVersion"
                                [(ngModel)]="site.protocolVersion" class="form-control" readonly>
                        </div>

                        <div class="form-group" *ngIf="trialStarted==false">
                            <input type="text" id="protocolVersion" name="protocolVersion"
                                [(ngModel)]="site.protocolVersion" class="form-control" >
                        </div>
                    </div>
                </div>
         <!-- -------------  -->
          
          
          <div *ngIf="trialStarted">
                <div class="row mb-1">

                    <div class="col-lg-2 col-xs-12 ">

                        <div class="form-group">
                            <label for="changeSummary">Change Summary</label>
                        </div>
                    </div>
                    <div class="col-lg-10 col-xs-12 ">

                        <div class="form-group">
                            <textarea id="changeSummary" name="changeSummary" class="form-control"
                                placeholder="Change Summary" [(ngModel)]="site.changeSummary" ></textarea>
                        </div>
                    </div>
                </div>

                <div class="row mb-1 flex-container">
                    <div class="col-lg-2 col-xs-12 fontRed">
            
                        <div class="form-group">
                            <label for="hightLights" style="font-weight: bold;">Changes Highlight</label>
                        </div>
                    </div>
                    
                
                    <div class="col-lg-3 col-xs-12 fontRed">
                        <div class="form-group">
                            <label for="trialGoalChanged"> Trial Goals Changed</label>
                            <input type="checkbox" id="trialGoalChanged" name="trialGoalChanged" [(ngModel)]="site.trialGoalChanged" >
                        </div>
                    </div>
            
                    <div class="col-lg-3 col-xs-12 fontRed">
                        <div class="form-group">
                            <label for="trialDesignChanged"> Trial Design Changed</label>
                            <input type="checkbox" id="trialDesignChanged" name="trialDesignChanged" [(ngModel)]="site.trialDesignChanged" >
                        </div>
                    </div>
            
                    <div class="col-lg-3 col-xs-12 fontRed">
                        <div class="form-group">
                            <label for="statisticalAnalysisImpacted"> Statistical Analysis Impacted</label>
                            <input type="checkbox" id="statisticalAnalysisImpacted" name="statisticalAnalysisImpacted" [(ngModel)]="site.statisticalAnalysisImpacted" >
                        </div>
                    </div>
                  
                </div>

            
                <div class="row mb-10 "></div>
                <div class="row mb-1 flex-container">
            
                    <div class="col-lg-1 col-xs-12 fontRed"> </div>
            
                    <div class="col-lg-3 col-xs-12 fontRed">
                        <label for="stopTrial">Stop Trial Pending Protocol Revision </label>
                    </div>
                    <div class="col-lg-1 col-xs-12 fontRed">
                      <input type="checkbox" id="stopTrial" name="stopTrial" [(ngModel)]="site.stopTrial" class="redBox">
                    </div>
            
                    <div class="col-lg-3 col-xs-12 fontRed">
                        <label for="notification">Regulatory Agencies Notifications are Required</label>
                    </div>
                    <div class="col-lg-1 col-xs-12 fontRed">
                       <input type="checkbox" id="notificationRequired" name="notificationRequired" [(ngModel)] ="site.notificationRequired" class="redBox">
                    </div> 
            
                    <div class="col-lg-2 col-xs-12 fontRed">
                        <label for="notification">Require IRB Approval </label>
                    </div>
                    <div class="col-lg-1 col-xs-12 fontRed">
                       <input type="checkbox" id="irbApprovalRequired" name="irbApprovalRequired" [(ngModel)] ="site.irbApprovalRequired" class="redBox">
                   </div>

                </div>
            </div>
       

            <!--End of TRIAL in PROGRESS scenario-->

                <div class="row mb-1">

                    <div class="col-lg-2 col-xs-12 ">
                    </div>
                    <div class="col-lg-4 col-xs-12 ">
                        <div class="form-group">
                            <a *ngIf="!principalInvestigator" class="btn btn-lg btn-primary btn-block"
                                [routerLink]="['/siteSetup']" role="button">Cancel</a>

                            <a *ngIf="principalInvestigator" class="btn btn-lg btn-primary btn-block"
                                [routerLink]="['/hospitals']" role="button">Cancel</a>
                        </div>
                    </div>
                    <div class="col-lg-2 col-xs-12 ">
                    </div>
                    <div class="col-lg-4 col-xs-12 ">
                        <div class="form-group">
                            <button class="btn btn-lg btn-primary btn-block" type="submit" 
                                    [disabled]="!f.form.valid || !validSubmissionDate"> 
                                Update
                            </button>
                        </div>
                    </div>
                </div>

                <div *ngIf="error" class="alert alert-danger mt-3" role="alert">
                    <strong>Oops!</strong> {{error}}
                </div>
            </form>

        </div>
    </div>

    <ngx-spinner type="pacman">
    <p>Loading...</p></ngx-spinner>
</div>