export class TrialomsLogin
{
   public email:string;
   public password:string;
   public confirmPassword:string;
   public userName:string;
   public name:string;

}
export interface ITrialomsLogin
{
    email:string;
    password:string;
    confirmPassword:string;
    userName:string;
    name:string;
    errorMessage:string;
}