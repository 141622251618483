import { Component, OnInit } from '@angular/core';
import { TrialSite, ITrialSite } from 'src/app/shared/interfaces/trialSite';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators'
import { AuthService } from '../../core/authentication/auth.service';
import { AdministrationService } from '../administration.service';
import { Router, CanActivate,ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Component({
  selector: 'app-trial-site',
  templateUrl: './trial-site.component.html',
  styleUrls: ['./trial-site.component.sass']
})
export class TrialSiteComponent implements OnInit {
  trialSites:any;
  trial:any;
  success:boolean=false;
  trialId:number;
  busy:boolean=false;
  trialBusy:boolean=false;//loading trials
  error:string;
  constructor(private route: ActivatedRoute,
    private authService: AuthService,
    private router:Router, 
    private administrationService: AdministrationService, 
    private spinner: NgxSpinnerService ) {
     
  }
  ngOnInit() {
      this.trialId = +this.route.snapshot.paramMap.get('trialId');
      // Get trial
      this.trialBusy=true;
this.administrationService.getTrial(this.authService.authorizationHeaderValue,this.trialId)
.pipe(finalize(() => {
  this.trialBusy=false;
})).subscribe(
result => {        
  this.trial = result;
});
//
this.spinner.show();
this.busy=true;
this.administrationService.fetchTrialSitesData(this.authService.authorizationHeaderValue, this.trialId)
.pipe(finalize(() => {
  this.spinner.hide();
  this.busy = false;
})).subscribe(
result => {         
  this.trialSites = result;
});
  }

}
