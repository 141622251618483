import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators'
import { AuthService } from '../../core/authentication/auth.service';
import { AdministrationService } from '../administration.service';
import { ICompany } from 'src/app/shared/interfaces/company';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.sass']
})
export class CompanyComponent implements OnInit {
  companies=null;
  busy: boolean;
  addCompany:boolean;
  viewList:boolean;
  newCompany:ICompany;
  constructor(private authService: AuthService,private router:Router, private administrationService: AdministrationService, private spinner: NgxSpinnerService) {
  }

  ngOnInit() {  
    console.log("starting .... listing companies"); 
    this.addCompany=false;
    this.viewList=true; 
    this.busy = true;
    this.spinner.show();
    this.administrationService.fetchCompanyData(this.authService.authorizationHeaderValue)
    .pipe(finalize(() => {
      this.spinner.hide();
      this.busy = false;
    })).subscribe(
    result => {         
      this.companies = result;
   });
  }
  wantToAdd(){
    this.addCompany=true;
    this.viewList=false;
    this.router.navigate(['/newCompany']);
  }
  cancel(){
    this.addCompany=false;
    this.viewList=true;
  }

}
