<div>
    <div>
        <form *ngIf="!busy" #f="ngForm" (ngSubmit)="onSubmit()">



            <div>
                <div class="row mb-1 justify-content-center">
                    <h3>Give Consent Form To Patient</h3>
                </div>
                <div class="row mb-1">
                    <div class="col-lg-12" id='addPatient'>
                    </div>
                </div>


                <div class="row mb-1">
                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <label for="patientNumber"><b>Patient Number</b></label>
                        </div>
                    </div>

                    <div class="col-lg-3 col-xs-12">
                        <div class="form-group">
                            <span>{{patient.patientNumber}}</span>
                        </div>
                    </div>

                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <label for="dateOfBirth"><b>Date of Birth</b></label>
                        </div>
                    </div>

                    <div class="col-lg-3 col-xs-12">
                        <div class="form-group">
                            <span>{{patient.dateOfBirth|date:'d MMMM y'}}</span>
                        </div>
                    </div>
                </div>
                <div class="row mb-1">
                    <div class="col-lg-2 col-sx-12">

                        <div class="form-group">
                            <label for="biologicalGender"><b>Biological Gender</b></label>
                        </div>
                    </div>
                    <div class="col-lg-3 col-xs-12">

                        <div class="form-group">
                            <!-- -->
                            <span>{{patient.biologicalGender}}</span>
                            <!-- <select class="form-control" id="biologicalGender" name="biologicalGender"
                            [(ngModel)]="patient.biologicalGender" readonly>
                            <option value="M">Male</option>
                            <option value="F">Female</option>
                        </select> -->
                        </div>
                    </div>
                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <label for="screeningDate"><b>Screening Date</b></label>
                        </div>
                    </div>

                    <div class="col-lg-3 col-xs-12">
                        <div class="form-group">
                            <span>{{patient.screeningDate|date:'d MMMM y'}}</span>
                        </div>
                    </div>
                </div>
                <div class="row mb-1">
                    
                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <label for="screeningResultsDate" title="Sreeneng Results Date"><b>Result Date</b></label>
                        </div>
                    </div>

                    <div class="col-lg-3 col-xs-12">
                        <div class="form-group">
                            <span>{{patient.screeningResultsDate|date:'d MMMM y'}}</span>
                        </div>
                    </div>
                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <label for="selected" title="Patient Selected?">Selected ?</label>
                        </div>
                    </div>
                    
                    <div class="col-lg-3 col-xs-12">
                        
                            <input type="checkbox"   id="selected" name="selected"
                                [(ngModel)]="patient.selected" disabled >
                        
                    </div>
                </div>
                <div class="row mb-1">
                    
                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <label for="screeningResultsDate" title="Screeneng Results Date"><b>Consent Form Review Date</b></label>
                        </div>
                    </div>

                    <div class="col-lg-3 col-xs-12">
                        <div class="form-group">
                            <input type="date" id="consentGivenDate" name="consentGivenDate" 
                                [(ngModel)]="patient.consentGivenDate" class="form-control" required>
                        </div>
                    </div>
                </div>
                <div class="row mb-1">
                    <div class="col-lg-4 col-xs-12">

                    </div>
                    <div class="col-lg-3 col-xs-12">
                        <div class="form-group">
                            <a class="btn btn-lg form-control btn-primary btn-block" [routerLink]="['/patient', irbId]"
                                role="button" title="View Patients">Cancel</a>
                        </div>
                    </div>
                    <div class="col-lg-3 col-xs-12">
                        <div class="form-group">
                            <button type="submit" class="btn btn-lg  btn btn-primary form-control"
                                [disabled]="!f.form.valid ">
                                Update
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- </div> -->
        </form>
    </div>

    <ngx-spinner type="pacman">
    <p>Loading...</p></ngx-spinner>
</div>
