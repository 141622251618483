export class InclusionCriteria{
    public id:number;
    public protocolId:number;
    public criteriaDescription: string;
    public inExclusion:boolean;
    public createdBy:string;
    public dateCreated:Date;
    public modifiedBy: string;
    public dateModified: Date;
    public deleted:boolean;
}
export interface IInclusionCriteria{
    id:number;
    protocolId:number;
    criteriaDescription: string;
    inExclusion:boolean;
    createdBy:string;
    dateCreated:Date;
    modifiedBy: string;
    dateModified: Date;
    deleted:boolean;
}