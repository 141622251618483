import { Component, NgZone,  ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';
import { Subject} from 'rxjs';
import { Observable, of } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { PrimeNGConfig } from 'primeng/api';
import { ISiteSetup} from 'src/app/shared/interfaces/siteSetup';
import { IIrb } from 'src/app/shared/interfaces/irb';
import { Patient, IPatient } from 'src/app/shared/interfaces/patient';
import { ITreatment } from 'src/app/shared/interfaces/treatment';
import { GraphValue } from 'src/app/shared/interfaces/graphValues';
import { AuthService } from '../../core/authentication/auth.service';
import { IssAdministrationService } from 'src/app/investigator-initiated-study/iss-administration.service';
import { PiAdministrationService } from 'src/app/principal-investigator/pi-administration.service'; 

import { Chart, registerables } from 'chart.js';
import 'chartjs-adapter-moment';
import 'chartjs-plugin-datalabels';
import zoomPlugin from 'chartjs-plugin-zoom';
Chart.register(zoomPlugin);
Chart.register(...registerables);



@Component({
  selector: 'app-sponsor-aggregate',
  templateUrl: './sponsor-aggregate.component.html',
  styleUrls: ['./sponsor-aggregate.component.sass']
})

export class SponsorAggregateComponent {
  
 
  displayEnrollmentSummary: boolean = false;
  displayCards: boolean = false;
  displayAggregates:boolean = false;
  busy:boolean = false;
  irbBusy: boolean =false;
  patientListBusy: boolean = false;
  treatmentBusy: boolean = false;
  isLoading:boolean = false;
  showColumn:boolean = true;
  showAggregatesChart:boolean = false;
  showBudgetDetailsChart:boolean = false;
  showAggregateColumn: boolean = true;
  showEnrollmentRateChart: boolean = false;


  siteSetups: ISiteSetup[] = [];
  siteId: number = 0;
  conceptId: number;

  irb: IIrb;
  currentIrbId: number;
  irbs: IIrb[] = [];
  approvedIrbSites: IIrb[] = [];
  approvedIrbCount: number = 0;
  //irbs$ = new Subject< IIrb[]>();
  siteNameAndIdForApprovedIrb: any[] =[];
  irbIdAndPatientId: any[] = [];
  irbIdAndPatientIdArray: any[] = [];

  patient: IPatient;
  patientList: IPatient[] = []; 
  numberOfScreenedPatients: number = 0;
  numberOfScreenedPatientsArray:Array<number> = [];
  enrolledPatientListAtSite: any[] = [];
  listOfAllEnrollePatientsIdAtAllSites: any[] = [];
  patientIdToTrackTreatmentCost: number = 0;

  enrolledPatientIdArray: Array<number> = [];
  numberOfEnrolledPatientsAtSite: any[] = [];
  enrolledPatientsAtAllApprovedSite: any[] =[];
  enrollmentDates:any[] = [];
  FPI: Date = new Date(); 
  FPO: Date = new Date();
  LPI: Date = new Date();
  LPO: Date = new Date();
  studyFPI: Date = new Date(); 
  studyFPO: Date = new Date();
  studyLPI: Date = new Date();
  studyLPO: Date = new Date();
  FPIArray: Array<Date> = [];
  FPOArray: Array<Date> = [];
  LPIArray: Array<Date> = [];
  LPOArray: Array<Date> = [];
  FPOorLPOArray: Array<Date> = [];

  treatmentList: ITreatment[] = [];
  treatmentsWithProtocolAtSitesArray: any[] = [];
  treatmentsForAeAtSitesArray: any[] = [];
  treatmentsWithProtocol: any[] = [];
  treatmentsForAE: any[] = [];
  treatmentsAtAllApprovedSite: any[] = [];
  treatmentsWithProtocolCostPerPatient: number = 0;
  totalCostForTreatmentsByProtocolAtSite: number = 0;
  treatmentsForAeCostPerPatient: number = 0;
  totalCostForAeTreatmentsAtSite: number = 0;
  treatmentWithProtocolDetailsAndCostArray: any[] = [];
  treatmentForAeDetailsAndCostArray: any[] = [];
  allProtocolTreatmentDetailsAndCosts: any[] = [];
  allAeTreatmentDetailsAndCosts: any[] = [];
  irbIdAndCostByProtocolAtSite: any[] = [];
  irbIdAndCostForAeAtSite: any[] = [];

  budgetSummaryInfo:any[] = [];
  budgetAggregate:any[] = [];
  budgetAggregateGraphData: any;
  budgetAggregateBarGraphData: any;
  sunkAndVariableCostGraphData: any;
  sunkCostDetailsGraphData: any;
  variableCostDetailsGraphData: any;
 
 
  enrollemtInfoPerSite:any[] = [];
  overAllEnrollmentInfo: any[] = [];
  enrollmentAggregate: any[] = [];
  enrollmentDetailsGraphData: any;
  enrollmentRateGraphData: any;
  timetoFpiGraphData: any;
  costPerPatientGraphData: any;

  options1: any;
  options2: any;
  options3: any;
  options4: any;
  options5: any;
  chartHolder: any;
  budgetGraphValues: GraphValue[] = [];
  budgetGraphValue: GraphValue;
 

  enrollmentRateArray: any[] = [];
  studyDurationSinceIrbApproval: number =0;
  irbsOperationData2: any[] = [];
  timeToFpiArray: any[] = [];

   
  
  
  constructor(

    private zone: NgZone,
    private authService: AuthService,
    private administrationService: IssAdministrationService,
    private piAdministrationService: PiAdministrationService,
    private spinner: NgxSpinnerService,
    private primeNGConfig: PrimeNGConfig,
    private datePipe: DatePipe,) { }

    ngOnInit(): void {
     
      this.getAllPoints();
  
     }

    showSpinner = (): void => {
    this.spinner.show();    
     }

    hideSpinner=():void =>{
     this.spinner.hide();
     }

     toggleColumn() {
      this.showColumn = !this.showColumn;
    }

    displayAggregatesChart() {
      this.showAggregatesChart = !this.showAggregatesChart;
    }

    displayBudgetDetailsChart() {
      this.showBudgetDetailsChart = !this.showBudgetDetailsChart;
    }

    displayEnrollmentRateChart() {
      this.showEnrollmentRateChart = !this.showEnrollmentRateChart;
    }

    toggleAggregateColumn() {
      this.showAggregateColumn = !this.showAggregateColumn;
    }
////////////////////////////////////////////////////////////

    getAllPoints():void {
      this.getSiteSetups();
      this.getAllIrbs();
     
     }

    getSiteSetups():void{
      this.spinner.show();
      this.piAdministrationService.getPiSites(this.authService.authorizationHeaderValue)
      .pipe(finalize(() => { 
          this.busy = false;
          this.hideSpinner();
      }))
      .subscribe(
        result => {
          this.siteSetups = result;
          
           });
  
     }

    getAllIrbs = (): void => {
      this.administrationService.getAllIrbs(this.authService.authorizationHeaderValue)
        .pipe(finalize(() => {
          this.busy = false;
          this.hideSpinner();
        }))
        .subscribe(
          results => {
            this.irbs = results;
           console.log(this.irbs);

          this.sitesWithApprovedIrb();
          //this.irbsOperationalAnalytics(this.approvedIrbSites, this.numberOfEnrolledPatientsAtSite, this.LPIArray)
          this.PatientsAtAllSites(this.siteNameAndIdForApprovedIrb);
          
          });
          
     }


     getPatientList = (irbId: number): Observable<IPatient[]> => {
      this.patientListBusy = true;
      this.showSpinner();
    
      return this.piAdministrationService.getAllPatient(this.authService.authorizationHeaderValue, irbId)
        .pipe(
          finalize(() => {
            this.patientListBusy = false;
            this.hideSpinner();
          }),
          tap((result: IPatient[]) => {
            this.patientList = result;
           // console.log(this.patientList);
          
          })
        );
    };
    

    getTreatmentListPerPatientId = (patientId:number): Observable<ITreatment[]> => {
    this.treatmentBusy = true;
    this.showSpinner();

   return  this.piAdministrationService.getPatientTreatments(this.authService.authorizationHeaderValue, patientId) 
      .pipe(finalize(() => {
        this.treatmentBusy=false;
        this.hideSpinner();
      }),
      tap((result: ITreatment[]) => {
        this.treatmentList = result;
        //console.log(this.treatmentList);
      })
      
      );
    
     }

 
    budgetSummaryTable = (): any =>{  
        
        for (let j = 0; j < this.siteNameAndIdForApprovedIrb.length; j++) {
              const approvedIrbSite = this.approvedIrbSites[j];
              const site = approvedIrbSite.site;
              const irbIdAndCostByProtocolAtSite = this.irbIdAndCostByProtocolAtSite.find(subArray => subArray[0] === approvedIrbSite.id) || [];
              const irbIdAndCostForAeAtSite = this.irbIdAndCostForAeAtSite.find(subArray => subArray[0] === approvedIrbSite.id) || [];
      
          let budgetInfo ={
                            count: j+1,
                            studyName:site.studyName,
                            siteName: approvedIrbSite.siteName,
                            irbId: approvedIrbSite.id,
                            irbCost: approvedIrbSite.irbCost,
                            screeningCost: site.costPerPatient * this.numberOfScreenedPatientsArray[j],
                            numberOfScreened: this.numberOfScreenedPatientsArray[j],
                            numberOfEnrolled: this.numberOfEnrolledPatientsAtSite[j],
                            recruitmentCosts: approvedIrbSite.recruitmentCosts * this.numberOfScreenedPatientsArray[j],
                            treatmentCostPerPatient: (irbIdAndCostByProtocolAtSite[1] || 0),
                            aeTreatmentCost: (irbIdAndCostForAeAtSite[1] || 0),
                            materialCost: site.materialCost,
                            equipmentCost: site.equipmentCost,
                            setUpCost: site.setUpCost,
                            siteActivationCost: site.siteActivationCost,
                            publicationCost: site.publicationCost,
                            totalCost: this.calculateEachRowTotalCost(approvedIrbSite,this.numberOfScreenedPatientsArray[j], this.numberOfEnrolledPatientsAtSite[j]),
                            percentOfTotalBudget: this.calculateEachRowTotalCost(approvedIrbSite, this.numberOfScreenedPatientsArray[j], this.numberOfEnrolledPatientsAtSite[j]) / site.totalBudget,
                            costPerPatientAtEachSite: this.calculateEachSIteCostPerPatient(approvedIrbSite, this.numberOfEnrolledPatientsAtSite[j], approvedIrbSite.expectedNumberOfPatients),
                            sunkCost: this.calculateEachRowSunkCost(approvedIrbSite),
                            variableCost: this.calculateEachRowVariableCost(approvedIrbSite,this.numberOfScreenedPatientsArray[j], this.numberOfEnrolledPatientsAtSite[j])
                          };
      
              this.budgetSummaryInfo.push(budgetInfo);
        }
     
        return this.budgetSummaryInfo;
      }
      

    sitesWithApprovedIrb =(): any =>{

        this.siteNameAndIdForApprovedIrb = [];
        this.approvedIrbCount = 0; 
        

        for (let j = 0; j < this.irbs.length; j++) {
          if(this.irbs[j].reviewDate != null && this.irbs[j].outcome == true)
          {
            this.siteNameAndIdForApprovedIrb.push([this.irbs[j].siteName, this.irbs[j].id])
            this.approvedIrbSites.push(this.irbs[j]);

          }
          this.approvedIrbCount = this.approvedIrbSites.length;
          
        }
        console.log('Approved IRB Sites List',this.approvedIrbSites, this.siteNameAndIdForApprovedIrb);
        

      return this.siteNameAndIdForApprovedIrb, this.approvedIrbSites, this.approvedIrbCount
      }

    calculateSum(field: string): number {
      
        return this.budgetSummaryInfo.reduce((sum, item) => sum + item[field], 0);
      }


      calculateEachRowTotalCost(rowData:any,numberOfScreened:number, numberOfEnrolled:number) {
        
        let sum = 0;
        sum += rowData.irbCost || 0;
        sum += (rowData.site.costPerPatient * numberOfScreened) || 0;
        sum += ((rowData.recruitmentCosts * numberOfScreened) || 0);
        
        let irbIdAndCostByProtocolAtSite = this.irbIdAndCostByProtocolAtSite.find(subArray => subArray[0] === rowData.id) || [];
        let irbIdAndCostForAeAtSite = this.irbIdAndCostForAeAtSite.find(subArray => subArray[0] === rowData.id) || [];
        
        sum += irbIdAndCostByProtocolAtSite[1] || 0;
        sum += irbIdAndCostForAeAtSite[1] || 0;
        sum += rowData.site.materialCost || 0;
        sum += rowData.site.equipmentCost || 0;
        sum += rowData.site.setUpCost || 0;
        sum += rowData.site.siteActivationCost || 0;
        sum += rowData.site.publicationCost || 0;
        
        return sum;
    }

    calculateEachRowSunkCost(rowData:any) {
        
      let sum = 0;
      sum += rowData.irbCost || 0;
      sum += rowData.site.materialCost || 0;
      sum += rowData.site.equipmentCost || 0;
      sum += rowData.site.setUpCost || 0;
      sum += rowData.site.siteActivationCost || 0;
      sum += rowData.site.publicationCost || 0;
      
      return sum;
  }

  calculateEachRowVariableCost(rowData:any,numberOfScreened:number, numberOfEnrolled:number) {
        
    let sum = 0;
   
    sum += (rowData.site.costPerPatient * numberOfScreened) || 0;
    sum += ((rowData.recruitmentCosts * numberOfScreened) || 0);
    
    let irbIdAndCostByProtocolAtSite = this.irbIdAndCostByProtocolAtSite.find(subArray => subArray[0] === rowData.id) || [];
    let irbIdAndCostForAeAtSite = this.irbIdAndCostForAeAtSite.find(subArray => subArray[0] === rowData.id) || [];
    
    sum += irbIdAndCostByProtocolAtSite[1] || 0;
    sum += irbIdAndCostForAeAtSite[1] || 0;
    
    return sum;
}

    calculateEachSIteCostPerPatient(rowData:any, numberOfEnrolled:number, numberOfExpected:number ) {
      
      let sum1 = 0; 
      if (numberOfEnrolled !=0){
      
      
      let numberOfEnrolledAsDenominator = 1/numberOfEnrolled;
      let numberOfExpectedAsDenominator = 1/numberOfExpected;
      
      sum1 += (rowData.irbCost || 0) * (numberOfExpectedAsDenominator);
      sum1 += (rowData.site.costPerPatient) || 0;
      sum1 += (rowData.recruitmentCosts) || 0;
      
      let irbIdAndCostByProtocolAtSite = this.irbIdAndCostByProtocolAtSite.find(subArray => subArray[0] === rowData.id) || [];
      let irbIdAndCostForAeAtSite = this.irbIdAndCostForAeAtSite.find(subArray => subArray[0] === rowData.id) || [];
      

      sum1 += (irbIdAndCostByProtocolAtSite[1] || 0) * (numberOfEnrolledAsDenominator );
      sum1 += (irbIdAndCostForAeAtSite[1] || 0) * (numberOfEnrolledAsDenominator );
      sum1 += (rowData.site.materialCost || 0) * (numberOfExpectedAsDenominator );
      sum1 += (rowData.site.equipmentCost || 0) * (numberOfExpectedAsDenominator );
      sum1 += (rowData.site.setUpCost || 0) * (numberOfExpectedAsDenominator );
      sum1 += (rowData.site.siteActivationCost || 0) * (numberOfExpectedAsDenominator );
      }
      else 
      {
        sum1 = 0
      }
      ;
      console.log(sum1)
      return sum1;
  }
    

    aggregateBudgetData(): void {
        this.budgetAggregate = [{
          irbCost: this.calculateSum('irbCost'),
          numberOfScreened: this.calculateSum('numberOfScreened'),
          screeningCost: this.calculateSum('screeningCost'),
          numberOfEnrolled: this.calculateSum('numberOfEnrolled'),
          recruitmentCosts: this.calculateSum('recruitmentCosts'),
          treatmentCostPerPatient: this.calculateSum('treatmentCostPerPatient'),
          aeTreatmentCost: this.calculateSum('aeTreatmentCost'),
          materialCost: this.calculateSum('materialCost'),
          equipmentCost: this.calculateSum('equipmentCost'),
          setUpCost: this.calculateSum('setUpCost'),
          siteActivationCost: this.calculateSum('siteActivationCost'),
          publicationCost: this.calculateSum('publicationCost'),
          totalCost: this.calculateSum('totalCost'),
          percentOfTotalBudget: this.calculateSum('percentOfTotalBudget'),
          sunkCost: this.calculateSum('sunkCost'),
          variableCost: this.calculateSum('variableCost'),

        }];
      }

      aggregateBudgetDataGraph =(): any => {

        this.budgetGraphValues = [];
        this.budgetGraphValue  = new GraphValue();

        this.budgetGraphValue.graphValueY = [  
                                            this.calculateSum('irbCost'),
                                            this.calculateSum('screeningCost'),
                                            this.calculateSum('recruitmentCosts'),
                                            this.calculateSum('treatmentCostPerPatient'),
                                            this.calculateSum('aeTreatmentCost'),
                                            this.calculateSum('materialCost'),
                                            this.calculateSum('equipmentCost'),
                                            this.calculateSum('setUpCost'),
                                            this.calculateSum('siteActivationCost'),
                                            this.calculateSum('publicationCost')  
                                            ];
        this.budgetGraphValues.push(this.budgetGraphValue);
       
return this.budgetGraphValues
      }

 

    aggregateEnrollmentKPI(): void {
        this.enrollmentAggregate = [{
                                      //numberOfExpected:,  to be enter as the study total expected patients
                                      numberOfScreened: this.calculateSum('numberOfScreened'),
                                      numberOfEnrolled: this.calculateSum('numberOfEnrolled'),
                                      studyFPI: this.studyFPI,   //Done + correct
                                      expectedFPO: this.studyFPO,         
                                      ActualFPO: 1-1-2023+6, //to be revised
                                      siteEnrollemtRate: 0.04,  // To be revised 
                                      studyLPI:this.studyLPI,  // to be revised once the AE cost is available
                                      expectedLPO: this.studyLPO,
                                      actualLPO:20-11-2023+6,
                                      expectedAnalysisDate: 20-11-2023+12,
                                      expectedPublicationDate: 20-11-2023+18,  //to be revised
                                      actualPublicationDate: 20-11-2023+18,
                                    }];
      }

    enrollemtSummaryTable = (): any =>{  
         
        for (let j = 0; j < this.siteNameAndIdForApprovedIrb.length; j++) {
            const approvedIrbSite = this.approvedIrbSites[j];
  
           let enrollmentInfo ={
                               count: j+1,
                               studyName:approvedIrbSite.site.studyName,
                               siteName:approvedIrbSite.siteName, 
                               numberOfExpected: approvedIrbSite.expectedNumberOfPatients,
                               numberOfScreened: this.numberOfScreenedPatientsArray[j],
                               numberOfEnrolled: this.numberOfEnrolledPatientsAtSite[j], 
                               FPI: this.FPIArray[j],
                               expectedFPO: this.FPOArray[j],         
                               ActualFPO: 1-1-2023+6,  //to be revised
                               siteEnrollemtRate: this.enrollmentRateArray[j][1],  
                               LPI:this.LPIArray[j],  
                               expectedLPO: this.LPOArray[j],
                               actualLPO:20-11-2023+6,
                               expectedAnalysisDate: 20-11-2023+12,   //to be revised
                               expectedPublicationDate: 20-11-2023+18,  //to be revised
                               actualPublicationDate: 20-11-2023+18,
                                         
                              };
           
            this.enrollemtInfoPerSite.push(enrollmentInfo);
           
            }
               
          console.log("To be used for the Enrollment Summary Table....",this.enrollemtInfoPerSite, this.siteNameAndIdForApprovedIrb);
      
          return this.enrollemtInfoPerSite 
          
          }

  

    patientStatus = (aPatient: Patient): string => {
        if (aPatient.consentSignedDate!=undefined && aPatient.consentSignedDate!=null){
          if(aPatient.consent!=undefined && aPatient.consent!=null && aPatient.consent==true){
            return "Enrolled";
          }
          else{
            return "No consent";
          }
        }
        if (aPatient.consentGivenDate!=undefined && aPatient.consentGivenDate!=null){
          return "Awaiting consent signature";
        }
        if (aPatient.screeningResultsDate!=undefined && aPatient.screeningResultsDate!=null){
          if(aPatient.selected!=undefined && aPatient.selected!=null){
            return aPatient.selected?"Selected After Screening - Provide Consent Form":" Not selected for Study - Failed Screening" ;
          }
        }
        if (aPatient.screeningDate!=undefined && aPatient.screeningDate!=null){
          return "Waiting For Screening Results"
        }
        return "Needs Screening";
      }

    scrennedPatientsAtSite = (patientList:IPatient[]):number =>{
            this.numberOfScreenedPatients = 0;
            let screenCount =0;
            
                    for (let i=0; i<patientList.length; i++){
                      
                      if (patientList[i].screeningDate !=null){
                        screenCount = screenCount +1;
                      }

                      this.numberOfScreenedPatients = screenCount;
                     
                    }

            return this.numberOfScreenedPatients;

            }

    listingEnrolledPatientsAtOneSite =  (patientList:any[]):any =>{
                    this.enrolledPatientIdArray = []; 
           //this.enrollmentGraphValue.graphValueY = 0;
                    this.enrolledPatientListAtSite = [];
                    this.irbIdAndPatientId = [];
                    this.enrollmentDates = [];
                    this.FPI = null; 
                    this.LPI = null;
                    this.treatmentsWithProtocol = [];
                    this.treatmentsForAE = [];
                    

                    { //Selection of All CONSENTED Patients
                      this.enrolledPatientListAtSite = this.patientList.filter(aPatient => this.patientStatus(aPatient) == "Enrolled");
                      this.enrolledPatientIdArray = this.enrolledPatientListAtSite.map(item =>item.id);
     
                      //this.enrollmentDates = this.patientList.map(item =>item.consentSignedDate);
                    //  console.log('Step1- enrolled Patient List',this.enrolledPatientListAtSite);

                      for (let i = 0; i< this.enrolledPatientListAtSite.length; i++)
                      { 
                        
                        if(this.enrolledPatientListAtSite[i].consent != null && this.enrolledPatientListAtSite[i].consent == true)    
                        
                        {
                          let date = new Date(this.enrolledPatientListAtSite[i].consentSignedDate);
                          let truncatedDate = date.toISOString().split('T')[0];
              
                          this.enrollmentDates.push(truncatedDate);  //Array of consent form returned dates for enrolled patients (e.g consent form signature date)
                          this.FPI = this.enrollmentDates.reduce((prev, curr) => { return prev < curr ? prev : curr; });  //Find the earliest date (the FPI)

                          if(this.enrolledPatientListAtSite.length >= this.enrolledPatientListAtSite[i].irb.expectedNumberOfPatients)
                          {
                          //this.LPI = this.enrollmentDates[this.enrolledPatientIdArray.length-1];
                          this.LPI = this.enrollmentDates.reduce((prev, curr) => { return prev > curr ? prev : curr; });
                          }
                          else 
                          {this.LPI = null;}
                        } 
                      } 
                      
                    } 
                    //this.TreatmentsAtEachSite(this.enrolledPatientIdArray);  // for Treatment List at each approved site
                    
                      //console.log("Array Enrolled PatientId",this.enrolledPatientIdArray, this.enrolledPatientIdArray.length);
                      //console.log("Array of Enrolled Patient List",this.enrolledPatientListAtSite);
                      //console.log([this.enrolledPatientIdArray, this.enrollmentDates, this.FPI, this.LPI]);  
        
                      return this.enrolledPatientListAtSite, this.enrolledPatientIdArray, this.FPI, this.irbIdAndPatientId, this.LPI;

             }     

    processPatientList = async (patientListObservable: Observable<any>) => {
     
              await new Promise((resolve, reject) => {
                patientListObservable.subscribe({
                  next: (patientList) => { 
                  // console.log(patientList);
                    this.scrennedPatientsAtSite(patientList);
                    this.listingEnrolledPatientsAtOneSite(patientList);  //1st step for Treatment - List enrolled patients at site
    
                    this.allProtocolTreatmentDetailsAndCosts.push(this.treatmentWithProtocolDetailsAndCostArray) ;     
                    this.allAeTreatmentDetailsAndCosts.push(this.treatmentForAeDetailsAndCostArray); 
        
                   // console.log(this.enrolledPatientListAtSite, this.enrolledPatientIdArray, this.enrolledPatientIdArray.length);

                    if(this.enrolledPatientIdArray.length !=0 ){
                        this.listOfAllEnrollePatientsIdAtAllSites.push(this.enrolledPatientIdArray);  //All enrolled Patients Ids
                    }

                    this.numberOfScreenedPatientsArray.push(this.numberOfScreenedPatients);

                    this.numberOfEnrolledPatientsAtSite.push(this.enrolledPatientIdArray.length);
   

                    this.irbIdAndPatientIdArray.push(this.irbIdAndPatientId);
                    
                    this.FPIArray.push(this.FPI);
                    
                    this.LPIArray.push(this.LPI);
                    

                    this.enrolledPatientsAtAllApprovedSite = [this.siteNameAndIdForApprovedIrb.map((item) => item[0]),
                                                              this.siteNameAndIdForApprovedIrb.map((item) => item[1]), 
                                                              this.numberOfScreenedPatientsArray,
                                                              this.numberOfEnrolledPatientsAtSite,
                                                              this.FPIArray,
                                                              this.LPIArray];

  
                 // console.log('FPIs', this.FPIArray);
                 // console.log('LPIs', this.LPIArray);
                 // console.log(this.irbIdAndPatientIdArray);

                    resolve(null);
                  },
                  
                  error: (err) => {
                    console.error(err);
                    reject(err);
                  }
                  
                });
                
              });
              
            };
        
    fpoOrLpoCalculation = (FPIorLPIArray:Array<Date>): any =>{

      this.FPOorLPOArray = [];

      for (let i = 0; i < this.approvedIrbSites.length; i++) {
             
                  //Initialiazation
        
          let initialDate = new Date(FPIorLPIArray[i]);
          let resultingDate;


          if (this.approvedIrbSites[i].studyDurationUnit == 'd' )  // Duartiom in Days
          {

            //Calculation
                  if (FPIorLPIArray[i] == null) {resultingDate = null} 

                  else{
                      let timestamp = initialDate.setDate(initialDate.getDate() + this.approvedIrbSites[i].studyDuration);
                      resultingDate = new Date(timestamp).toISOString().split('T')[0]; // Convert to string;
                     }
                  this.FPOorLPOArray.push(resultingDate);
                  
                /*  console.log(this.approvedIrbSites[i].id, 
                              this.FPIArray[i], 
                              this.approvedIrbSites[i].studyDuration, 
                              this.approvedIrbSites[i].studyDurationUnit, 
                                  resultingDate); */
    
          }

          if (this.approvedIrbSites[i].studyDurationUnit == 'w' )  // Duration in Weeks
          {

            //Calculation
                  if (FPIorLPIArray[i] == null){ resultingDate = null} 

                  else{
                      let timestamp = initialDate.setDate(initialDate.getDate() + this.approvedIrbSites[i].studyDuration * 7);
                      resultingDate = new Date(timestamp).toISOString().split('T')[0]; // Convert to string;;
                     }
                  this.FPOorLPOArray.push(resultingDate);
                  
                 /* console.log(this.approvedIrbSites[i].id, 
                              this.FPIArray[i], 
                              this.approvedIrbSites[i].studyDuration, 
                              this.approvedIrbSites[i].studyDurationUnit, 
                                  resultingDate); */
    
          }

          if (this.approvedIrbSites[i].studyDurationUnit == 'm' ) //Duration in Months
          {

           //Calculation
           if (FPIorLPIArray[i] == null){ resultingDate = null} 

           else{
                  let timestamp = initialDate.setMonth(initialDate.getMonth() + this.approvedIrbSites[i].studyDuration);
                  resultingDate = new Date(timestamp).toISOString().split('T')[0]; // Convert to string;;
              }  
                  this.FPOorLPOArray.push(resultingDate);
                
                /* console.log(this.approvedIrbSites[i].id, 
                              this.FPIArray[i], 
                              this.approvedIrbSites[i].studyDuration, 
                              this.approvedIrbSites[i].studyDurationUnit, 
                                  resultingDate);*/
    
          }

          if (this.approvedIrbSites[i].studyDurationUnit == 'y' )// Duration in Years
          {

          //Calculation
          if (FPIorLPIArray[i] == null){ resultingDate = null} 

          else{
                  let timestamp = initialDate.setFullYear(initialDate.getFullYear() + this.approvedIrbSites[i].studyDuration);
                  resultingDate = new Date(timestamp).toISOString().split('T')[0]; // Convert to string;;
              }
                  this.FPOorLPOArray.push(resultingDate);

                 /* console.log(this.approvedIrbSites[i].id, 
                              this.FPIArray[i], 
                              this.approvedIrbSites[i].studyDuration, 
                              this.approvedIrbSites[i].studyDurationUnit, 
                                  resultingDate);*/
        
        }
      
        }

       // console.log(this.FPOorLPOArray);
        
        return this.FPOorLPOArray
      }

    PatientsAtAllSites = async (siteNameAndIdForApprovedIrb: any[]): Promise<any> => {

        this.numberOfScreenedPatients = 0;
        this.numberOfEnrolledPatientsAtSite = [];
        this.enrolledPatientsAtAllApprovedSite = [];
        this.FPIArray = [];
        this.timeToFpiArray = [];
        this.LPIArray = [];
        this.FPOArray = [];
        this.studyFPI = new Date();
        this.studyFPO = new Date();
        this.studyLPI = new Date();
        this.studyLPO = new Date();
        this.numberOfScreenedPatientsArray = [];
        this.allProtocolTreatmentDetailsAndCosts = [];
        this.allAeTreatmentDetailsAndCosts = [];
        this.listOfAllEnrollePatientsIdAtAllSites = [];
  
        //console.log('1st step for getting patientID', siteNameAndIdForApprovedIrb);
 
        for (let z = 0; z < siteNameAndIdForApprovedIrb.length; z++) {
          await this.processPatientList(this.getPatientList(siteNameAndIdForApprovedIrb[z][1]));
        }

        this.irbsOperationalAnalytics(this.approvedIrbSites, this.numberOfEnrolledPatientsAtSite, this.LPIArray);
        this.timeToFirstPatient (this.approvedIrbSites, this.numberOfEnrolledPatientsAtSite);
        this.treatmentsAtAllSites(this.listOfAllEnrollePatientsIdAtAllSites);

    this.enrollmentRateCalculation( this.numberOfEnrolledPatientsAtSite ,this.irbsOperationData2);


        //console.log(this.numberOfEnrolledPatientsAtSite);
        //console.log(this.siteNameAndIdForApprovedIrb.map((item) => item[0]), this.siteNameAndIdForApprovedIrb.map((item) => item[1]), this.numberOfScreenedPatientsArray, this.numberOfEnrolledPatientsAtSite);
        //console.log('SiteName - IrbId - #of screeened - #of Enrolled - FPI',this.enrolledPatientsAtAllApprovedSite);
        //console.log('Number of enrolled patients at site, #treatment by protocol, # treated for AE', this.treatmentsAtAllApprovedSite);
        //console.log(this.irbIdAndCostByProtocolAtSite);
        //console.log('All - Enrolled Patients IDs',this.listOfAllEnrollePatientsIdAtAllSites)
       
    
        this.studyFPI = this.FPIArray.filter(value => value !== null).reduce((prev, curr) => { return prev < curr ? prev : curr; });  //Find the earliest date (the FPI)
        this.studyLPI = this.LPIArray.filter(value => value !== null && value !== undefined).reduce((prev, curr) => { return prev > curr ? prev : curr; });  //Find the last date for the last patient enrollment (the LPI)
        //console.log('StudyLPI:',this.studyLPI);

        this.FPOArray = this.fpoOrLpoCalculation(this.FPIArray);        
        this.LPOArray = this.fpoOrLpoCalculation(this.LPIArray);
        this.studyFPO = this.FPOArray.filter(value => value !== null).reduce((prev, curr) => { return prev < curr ? prev : curr; });  //Find the earliest date for the first patient exit (the FPO)
        this.studyLPO = this.LPOArray.filter(value => value !== null).reduce((prev, curr) => { return prev > curr ? prev : curr; });  //Find the last date for the last patient exit (the LPO)
        console.log('StudyFPO:',this.studyFPO,'StudyLPO:',this.studyLPO);
        
        this.enrollemtSummaryTable();  
        this.enrollmentDetailsVectorGenerator();
        this.enrollmentRateVectorGenerator();
        this.timeToFpiVectorGenerator();
        //this.studyFPO = this.FPOArray.filter(value => value !== null).reduce((prev, curr) => { return prev < curr ? prev : curr; });  //Find the earliest date for the first patient exit (the FPO)
  
        //console.log('StudyFPO:',this.studyFPO);

        return this.numberOfEnrolledPatientsAtSite, 
                this.timeToFpiArray,
                this.enrolledPatientsAtAllApprovedSite, 
                this.studyFPI, 
                this.studyFPO,
                this.FPOArray,
                this.studyLPI,
                this.studyLPO,
                this.LPOArray,
                this.listOfAllEnrollePatientsIdAtAllSites,
                this.allProtocolTreatmentDetailsAndCosts,
                this.allAeTreatmentDetailsAndCosts
  
      };  

    treatmentWithProtocolCostsCalculation = (treatmentsWithProtocol:ITreatment[]): any =>{
      // console.log(this.treatmentsWithProtocol);

      this.treatmentsWithProtocolCostPerPatient = 0;
      this.treatmentWithProtocolDetailsAndCostArray = [];
      this.patientIdToTrackTreatmentCost = 0;
      
      for (let j = 0; j < this.treatmentsWithProtocol.length; j++){

        const treatment = this.treatmentsWithProtocol[j];
        const patientj = treatment.patient;
        const irb = patientj.irb;

      const temp = treatment.miscellaneousPaymentsToPatient
                 + patientj.medicalAnalysisCost
                 + patientj.clinicalTreatmentCost
                 + irb.hospitalCosts
                 + irb.paymentToPatient;

    this.treatmentsWithProtocolCostPerPatient += temp;   

    this.treatmentWithProtocolDetailsAndCostArray = [irb.id, patientj.id, this.treatmentsWithProtocolCostPerPatient];

    this.patientIdToTrackTreatmentCost = patientj.id;
  } 
      
      if (this.treatmentWithProtocolDetailsAndCostArray.length !=0){

      this.allProtocolTreatmentDetailsAndCosts.push(this.treatmentWithProtocolDetailsAndCostArray); 

      }
       
     // console.log('Patient ID',this.patientIdToTrackTreatmentCost,'Total cost for treatment with protocol for patientId',this.treatmentsWithProtocolCostPerPatient);
      //console.log('IrbID - PatientId - Treatment Costs For Treatments With Protocol',this.treatmentWithProtocolDetailsAndCostArray);
 
     // console.log('All - IrbId - PatientId - Protocol Treatment Costs',this.allProtocolTreatmentDetailsAndCosts)
      

      return this.treatmentWithProtocolDetailsAndCostArray, 
             this.allProtocolTreatmentDetailsAndCosts, 
             this.treatmentsWithProtocolCostPerPatient
             
     }

     treatmentForAeCostsCalculation = (treatmentsForAE:any[]): any =>{
      //console.log(this.treatmentsForAE);

      this.treatmentsForAeCostPerPatient = 0;
      this.treatmentForAeDetailsAndCostArray = [];
      this.patientIdToTrackTreatmentCost = 0;

      for (let j = 0; j < treatmentsForAE.length; j++){

        const treatmentForAE = treatmentsForAE[j];
        const treatmentWithProtocol = this.treatmentsWithProtocol[j];
        const patient = treatmentWithProtocol.patient;
        const irb = patient.irb;
    
        const temp = treatmentForAE.adverseEventTreatmentCost;
    
        this.treatmentsForAeCostPerPatient += temp;
    
        this.treatmentForAeDetailsAndCostArray = [irb.id, patient.id, this.treatmentsForAeCostPerPatient];
    
        this.patientIdToTrackTreatmentCost = patient.id;
      }

      if (this.treatmentForAeDetailsAndCostArray.length != 0){

      this.allAeTreatmentDetailsAndCosts.push(this.treatmentForAeDetailsAndCostArray); 

      }

       // console.log('Patient ID',this.patientIdToTrackTreatmentCost,'Total treatment for AE cost for patientId',this.treatmentsForAeCostPerPatient);
       // console.log('IrbID - PatientId - Treatment Costs For AE',this.treatmentForAeDetailsAndCostArray);
       // console.log('All - IrbId - PatientId - AEs Treatment Costs',this.allAeTreatmentDetailsAndCosts);

      return  this.treatmentForAeDetailsAndCostArray, 
              this.allAeTreatmentDetailsAndCosts, 
              this.treatmentsForAeCostPerPatient
              
     }


    TreatmentsAtEachSiteXX = async (enrolledPatientIdArrayPerSite: any[]): Promise<any> => {
      this.treatmentsWithProtocol = [];
      this.treatmentsForAE = [];
    
      const tasks = enrolledPatientIdArrayPerSite.map(async (patientId) => {
        try {
          await this.processTreatmentList(this.getTreatmentListPerPatientId(patientId));
          await this.treatmentWithProtocolCostsCalculation(this.treatmentsWithProtocol);
          await this.treatmentForAeCostsCalculation(this.treatmentsForAE);
        } catch (err) {
          console.error(err);
        }
      });
    
      await Promise.all(tasks);
    
       console.log('Number of enrolled patients at site, #treatment by protocol, # treated for AE', this.treatmentsAtAllApprovedSite);
    
      return this.treatmentsWithProtocol, this.treatmentsForAE, 
             this.treatmentsAtAllApprovedSite, 
             this.treatmentWithProtocolDetailsAndCostArray,
             this.treatmentForAeDetailsAndCostArray,
             this.irbIdAndCostByProtocolAtSite,
             this.irbIdAndCostForAeAtSite
    };

    processTreatmentList = async (treatmentListObservable: Observable<any>) => {
      await new Promise((resolve, reject) => {
        treatmentListObservable.subscribe({
          next: (treatmentList) => {
           //console.log(treatmentList);
           
            this.parsingTreatmentList(treatmentList)
                     
          //add a push for the treatment
          
          this.treatmentsWithProtocolAtSitesArray.push(this.treatmentsWithProtocol.length);
          this.treatmentsForAeAtSitesArray.push(this.treatmentsForAE.length);  
            
          this.treatmentsAtAllApprovedSite = [this.numberOfEnrolledPatientsAtSite,this.treatmentsWithProtocolAtSitesArray, 
                                              this.treatmentsForAeAtSitesArray];


            resolve(null);
          },
          
          error: (err) => {
            console.error(err);
            reject(err);
          }
          
        });
         
      });
      
    };

    parsingTreatmentList = (treatmentList:ITreatment[]):any =>{
        
      //2- 2nd Step for finding the treatments with PROTOCOL and AEs for a specific patient Id   
      
     // console.log(this.treatmentList);
                 
          this.treatmentsWithProtocol = [];
          this.treatmentsForAE = [];

               if(this.treatmentList.length !=0){
                   this.treatmentsWithProtocol = this.treatmentList.filter(aTreatment=>
                     aTreatment.treatmentType == 0 || aTreatment.treatmentType == 2);   //Filter treatment with protocol
         
                   this.treatmentsForAE =  this.treatmentList.filter(aTreatment=>
                       aTreatment.treatmentType == 1);   //Filter treatment with for AEs
                   }
                   else
                   {this.treatmentsWithProtocol = [];
                     this.treatmentsForAE = [];
                   }
                   
          // console.log('List of Treatments With Protocol Including Follow-up Visits', this.treatmentsWithProtocol);
          // console.log('List of Treatments for AEs', this.treatmentsForAE);
            
           return this.treatmentsWithProtocol, this.treatmentsForAE  
                      
             } 

    treatmentsAtAllSites = async (listOfAllEnrollePatientsIdAtAllSites: any[]): Promise<any> => { 

      //console.log(this.listOfAllEnrollePatientsIdAtAllSites);
      this.allProtocolTreatmentDetailsAndCosts =[];
      this.allAeTreatmentDetailsAndCosts = [];

      // console.log(this.listOfAllEnrollePatientsIdAtAllSites);

      try {
        await Promise.all(listOfAllEnrollePatientsIdAtAllSites.map(patientId => this.TreatmentsAtEachSiteXX(patientId)));
      } catch (err) {
        console.error(err);
      }             
              {let protocolTreatmentCostResults = this.allProtocolTreatmentDetailsAndCosts.reduce((acc, val) => {
                let key = Number(val[0]);
                let value = val[2];
                acc[key] = (acc[key] || 0) + value;
                return acc;
              }, {});

              this.irbIdAndCostByProtocolAtSite = Object.entries(protocolTreatmentCostResults).map(([key, value]) => [Number(key), value]);
              }
              
               {let AeTreatmentCostResults = this.allAeTreatmentDetailsAndCosts.reduce((acc, val) => {
                let key = Number(val[0]);
                let value = val[2];
                acc[key] = (acc[key] || 0) + value;
                return acc;
              }, {});
              
              this.irbIdAndCostForAeAtSite = Object.entries(AeTreatmentCostResults).map(([key, value]) => [Number(key), value]);
              }
              this.budgetSummaryTable(); 
              this.aggregateBudgetData()
              
              //this.enrollmentRateCalculation( this.numberOfEnrolledPatientsAtSite ,this.irbsOperationData2);
              this.aggregateEnrollmentKPI()
              this.BudgetAggregatVectorGenerator();
              this.sunkAndVariableCostVectorGenerator();
              this.sunkCostDetailsVectorGenerator();
              this.variableCostDetailsVectorGenerator();
              this.CostPerPatientVectorGenerator();

             // console.log('All - IrbId - PatientId - Protocol Treatment Costs',this.allProtocolTreatmentDetailsAndCosts);
             // console.log('All - IrbId - PatientId - AEs Treatment Costs',this.allAeTreatmentDetailsAndCosts);
              console.log('IrbId - Total Protocol Treatment Costs',this.irbIdAndCostByProtocolAtSite);
              console.log('IrbId - Total AEs Treatment Costs',this.irbIdAndCostForAeAtSite);

               }       

     BudgetAggregatVectorGenerator = ():any =>{

                this.aggregateBudgetDataGraph();
      
                          this.budgetAggregateGraphData = {
                          labels: ['Irb Cost','Patients Screening Cost', 'Recruitment Costs', 'Treatment Cost', 'AE Treatment Cost', 'Material/Product Cost', 'Equipment Cost', 'Setup Cost', 'Site Activation Cost', 'Publication Cost'],
                          datasets: [{
                              
                                label: 'Budget Categories',
      
                                data: this.budgetGraphValue.graphValueY,
                            
                                  backgroundColor:  ['#666666', '#FFFF33', '999999', '#669900', '#FF3333', '#AA2205', '#CCFFCC', '#FEA51D', '#0CAF47','#0A48B5' ],
                                  hoverBackgroundColor: ['#E8153B'],
                                  borderWidth: 1

                              }
                          ]
                      };
                     
                      this.options1 = {
                        plugins: {
                            legend: {
                                labels: {
                                    usePointStyle: true,
                                    
                                }
                            }
                            
                        },
                        animation: {
                          duration: 1000,
                          easing: 'easeInCirc', 
                         
                        }
                    };

                    this.budgetAggregateBarGraphData = {
                      labels: ['Irb Cost','Patients Screening Cost', 'Recruitment Costs', 'Treatment Cost', 'AE Treatment Cost', 'Material/Product Cost', 'Equipment Cost', 'Setup Cost', 'Site Activation Cost', 'Publication Cost'],
                      datasets: [
                          {
                            label: 'Budget Categories',
  
                            data: this.budgetGraphValue.graphValueY,
  
                              backgroundColor:  '#666666',
                              hoverBackgroundColor: '#038939',
                              borderWidth: 1

                          }
                      ]
                  };

                    this.options2 = {
                      plugins: {
                          legend: {
                             labels: {
                                  color: '#000000'
                                  
                              }
                          }
                      },
                      animation: {
                        duration: 1000,
                        easing: 'linear', 
                       
                      },
  
                      scales: {
                        y: {
                          min: 0,
                        //  max: 242000,
                          title: {
                            display: true,
                            text: '$',
                            color: '#000000',
                            font: {
                              weight: 800
                            }
                          },
                            beginAtZero: true,
                            ticks: {
                                color: '#000000',
                                
                            },

                           
                        },
                        
                    },
                    interaction: {
                      mode: 'nearest',
                      axis: 'x',
                      intersect: false
                  },
                };
                  }

     enrollmentDetailsVectorGenerator = ():any =>{

                    this.enrollmentDetailsGraphData = {
                    labels: this.enrolledPatientsAtAllApprovedSite[0] ,
                    datasets: [
                        {
                          label: 'Number of Screened Patients',

                          data: this.numberOfScreenedPatientsArray, //this.screenedGraphValue.graphValueY,

                            backgroundColor: '#0A48B5',
                            hoverBackgroundColor: '#E8153B',
                            borderWidth: 1

                        },

                        {
                          label: 'Number of Enrolled Patients',

                          data: this.numberOfEnrolledPatientsAtSite, //this.screenedGraphValue.graphValueY,

                            backgroundColor: '#6A994F',
                            hoverBackgroundColor: '#E8153B',
                            borderWidth: 1

                        }

                    ]

                    
                };

              this.options3 = {
                plugins: {
                    legend: {
                       labels: {
                            color: '#000000'
                            
                        }
                    }
                },
                animation: {
                  duration: 1000,
                  easing: 'linear', 
                 
                },

                scales: {
                  y: {
                    min: 0,
                  
                    title: {
                      display: true,
                      text: 'Number of Patients',
                      color: '#000000',
                      font: {
                        weight: 800
                      }
                    },
                      beginAtZero: true,
                      ticks: {
                          color: '#000000',
                          
                      },
                     
                  },
                  
              },
              interaction: {
                mode: 'nearest',
                axis: 'x',
                intersect: false
            },
          };
        }           

     CostPerPatientVectorGenerator = ():any =>{

                   // this.aggregateBudgetDataGraph();
          
                              this.costPerPatientGraphData = {
                              labels: this.enrolledPatientsAtAllApprovedSite[0],
                              datasets: [
                                  {
                                    label: 'Cost Per Patient',
          
                                    data: this.budgetSummaryInfo.map(row => row.costPerPatientAtEachSite),
          
                                      backgroundColor: ['#C22402' ],
                                      hoverBackgroundColor: ['#666666'],
                                      borderWidth: 1
    
                                  }
                              ]
                          };
                         
                          
    
                        this.options2 = {
                          plugins: {
                              legend: {
                                 labels: {
                                      color: '#000000'
                                      
                                  }
                              }
                          },
                          animation: {
                            duration: 1000,
                            easing: 'linear', 
                           
                          },
      
                          scales: {
                            y: {
                              min: 0,
                            //  max: 242000,
                              title: {
                                display: true,
                                text: '$',
                                color: '#000000',
                                font: {
                                  weight: 800
                                }
                              },
                                beginAtZero: true,
                                ticks: {
                                    color: '#000000',
                                    
                                },
    
                               
                            },
                            
                        },
                        interaction: {
                          mode: 'nearest',
                          axis: 'x',
                          intersect: false
                      },
                    };
                     }             
                 
     sunkAndVariableCostVectorGenerator = ():any =>{

                              this.sunkAndVariableCostGraphData = {
                              labels: this.enrolledPatientsAtAllApprovedSite[0] ,
                              datasets: [

                                  {
                                  label: 'Total Cost',
        
                                  data: this.budgetSummaryInfo.map(row => row.totalCost),
        
                                    backgroundColor: '#e69832',
                                    hoverBackgroundColor: '#E8153B',
                                    borderWidth: 1
  
                                  },
                                 
                                  {
                                    label: 'Sunk Cost',
          
                                    data: this.budgetSummaryInfo.map(row => row.sunkCost),
          
                                      backgroundColor: '#0A48B5',
                                      hoverBackgroundColor: '#E8153B',
                                      borderWidth: 1
    
                                  },

                                  {
                                    label: 'Variable Cost',
          
                                    data: this.budgetSummaryInfo.map(row => row.variableCost),
          
                                      backgroundColor: '#6A994F',
                                      hoverBackgroundColor: '#E8153B',
                                      borderWidth: 1
    
                                  },

                                  

                              ]

                              
                          };
    
                        this.options3 = {
                          plugins: {
                              legend: {
                                 labels: {
                                      color: '#000000'
                                      
                                  }
                              }
                          },
                          animation: {
                            duration: 1000,
                            easing: 'linear', 
                           
                          },
      
                          scales: {
                            y: {
                              min: 0,
                            
                              title: {
                                display: true,
                                text: '$',
                                color: '#000000',
                                font: {
                                  weight: 800
                                }
                              },
                                beginAtZero: true,
                                ticks: {
                                    color: '#000000',
                                    
                                },
                               
                            },
                            
                        },
                        interaction: {
                          mode: 'nearest',
                          axis: 'x',
                          intersect: false
                      },
                    };
        }   

     sunkCostDetailsVectorGenerator = ():any =>{

                    this.sunkCostDetailsGraphData = {
                    labels: this.enrolledPatientsAtAllApprovedSite[0] ,
                    datasets: [

                        {
                        label: 'Irb Review Cost',

                        data: this.budgetSummaryInfo.map(row => row.irbCost),

                          backgroundColor: '#a80c36',
                          hoverBackgroundColor: '#E8153B',
                          borderWidth: 1

                        },
                       
                        {
                          label: 'Material Cost',

                          data: this.budgetSummaryInfo.map(row => row.materialCost),

                            backgroundColor: '#0ca823',
                            hoverBackgroundColor: '#E8153B',
                            borderWidth: 1

                        },

                        {
                          label: 'Equipment Cost',

                          data: this.budgetSummaryInfo.map(row => row.equipmentCost),

                            backgroundColor: '#5b5e5d',
                            hoverBackgroundColor: '#E8153B',
                            borderWidth: 1

                        },

                        {
                          label: 'Set-up Cost',

                          data: this.budgetSummaryInfo.map(row => row.setUpCost),

                            backgroundColor: '#4b6cbd',
                            hoverBackgroundColor: '#E8153B',
                            borderWidth: 1

                        },

                        {
                          label: 'Site Activation Cost',

                          data: this.budgetSummaryInfo.map(row => row.siteActivationCost),

                            backgroundColor: '#6A994F',
                            hoverBackgroundColor: '#E8153B',
                            borderWidth: 1

                        },

                        {
                          label: 'Publication Cost',

                          data: this.budgetSummaryInfo.map(row => row.publicationCost),

                            backgroundColor: '#fab14b',
                            hoverBackgroundColor: '#E8153B',
                            borderWidth: 1

                        },

                        

                    ]

                    
                };

              this.options3 = {
                plugins: {
                    legend: {
                       labels: {
                            color: '#000000'
                            
                        }
                    }
                },
                animation: {
                  duration: 1000,
                  easing: 'linear', 
                 
                },

                scales: {
                  y: {
                    min: 0,
                  
                    title: {
                      display: true,
                      text: '$',
                      color: '#000000',
                      font: {
                        weight: 800
                      }
                    },
                      beginAtZero: true,
                      ticks: {
                          color: '#000000',
                          
                      },
                     
                  },
                  
              },
              interaction: {
                mode: 'nearest',
                axis: 'x',
                intersect: false
            },
          };
        } 
               
     variableCostDetailsVectorGenerator = ():any =>{

          this.variableCostDetailsGraphData = {
          labels: this.enrolledPatientsAtAllApprovedSite[0] ,
          datasets: [

              {
              label: 'Screening Cost',

              data: this.budgetSummaryInfo.map(row => row.screeningCost),

                backgroundColor: '#a80c36',
                hoverBackgroundColor: '#0f0200',
                borderWidth: 1

              },
             
              {
                label: 'Recruitment Cost',

                data: this.budgetSummaryInfo.map(row => row.recruitmentCosts),

                  backgroundColor: '#0ca823',
                  hoverBackgroundColor: '#E8153B',
                  borderWidth: 1

              },

              {
                label: 'Treatment Cost',

                data: this.budgetSummaryInfo.map(row => row.treatmentCostPerPatient),

                  backgroundColor: '#5b5e5d',
                  hoverBackgroundColor: '#E8153B',
                  borderWidth: 1

              },

              {
                label: 'AEs Treatment Cost',

                data: this.budgetSummaryInfo.map(row => row.aeTreatmentCost),

                  backgroundColor: '#d92916',
                  hoverBackgroundColor: '#0f0200',
                  borderWidth: 1

              },

          ]

          
      };

    this.options3 = {
      plugins: {
          legend: {
             labels: {
                  color: '#000000'
                  
              }
          }
      },
      animation: {
        duration: 1000,
        easing: 'linear', 
       
      },

      scales: {
        y: {
          min: 0,
        
          title: {
            display: true,
            text: '$',
            color: '#000000',
            font: {
              weight: 800
            }
          },
            beginAtZero: true,
            ticks: {
                color: '#000000',
                
            },
           
        },
        
    },
    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false
  },
};
        }   
         
        
    enrollmentRateVectorGenerator = ():any => {
          
      
          this.enrollmentRateGraphData = {
              datasets: [
                  {
                      type: 'bar',
                      label: 'Enrollment Rate at Approved Sites ',
                      data: this.enrollmentRateArray.map(point => ({y: point[1],  x: point[0]})), //Enrollment Rate Array,
                      backgroundColor: Array(this.enrollmentRateArray.length).fill('#2A9D8F'), // Set initial color for all bars
                      hoverBackgroundColor: '#E8153B',
                      borderWidth: 1
                  },
              ]
          };
      
          this.options4 = {
            plugins: {
                legend: {
                    labels: {
                        color: '#000000'
                    }
                },
            },
            animation: {
                duration: 1000,
                easing: 'linear', 
            },
            scales: {
                y: {
                    min: 0,
                   // max: 1,
                    title: {
                        display: true,
                        text: 'Enrollment Rate',
                        color: '#000000',
                        font: {
                            weight: 800
                        }
                    },
                    beginAtZero: true,
                    ticks: {
                        color: '#000000',
                    },
                },
            },
            interaction: {
                mode: 'nearest',
                axis: 'x',
                intersect: false
            },
            zoom: {
                pan: {
                    enabled: true,
                    mode: 'xy'
                },
                zoom: {
                    wheel: {
                        enabled: true,  // Enable zooming via mouse wheel
                        speed: 0.1  // Speed of zoom via mouse wheel
                    },
                    pinch: {
                        enabled: true  // Enable pinch-to-zoom
                    },
                    mode: 'xy'
                }
            }
        };
        
        
      
          
      }
      
      timeToFpiVectorGenerator = ():any => {
          
      
        this.timetoFpiGraphData = {
            datasets: [
                {
                    type: 'bar',
                    label: 'Time to FPI at Approved Sites ',
                    data: this.timeToFpiArray.map(point => ({y: point[1],  x: point[0]})), //time to FPI,
                    backgroundColor: Array(this.timeToFpiArray.length).fill('#344feb'), // Set initial color for all bars
                    hoverBackgroundColor: '#E8153B',
                    borderWidth: 1
                },
            ]
        };
    
        this.options5 = {
          plugins: {
              legend: {
                  labels: {
                      color: '#000000'
                  }
              },
          },
          animation: {
              duration: 1000,
              easing: 'linear', 
          },
          scales: {
              y: {
                  min: 0,
                 // max: 1,
                  title: {
                      display: true,
                      text: 'Time to FPI (in Days)',
                      color: '#000000',
                      font: {
                          weight: 800
                      }
                  },
                  beginAtZero: true,
                  ticks: {
                      color: '#000000',
                  },
              },
          },
          interaction: {
              mode: 'nearest',
              axis: 'x',
              intersect: false
          },
          zoom: {
              pan: {
                  enabled: true,
                  mode: 'xy'
              },
              zoom: {
                  wheel: {
                      enabled: true,  // Enable zooming via mouse wheel
                      speed: 0.1  // Speed of zoom via mouse wheel
                  },
                  pinch: {
                      enabled: true  // Enable pinch-to-zoom
                  },
                  mode: 'xy'
              }
          }
      };
      
      
    
        
       }

   enrollmentRateCalculation = ( numberOfEnrolledPatientsAtSite:Array<number> ,irbsOperationData2:any[]): any =>{
                  
                   for (let i=0; i<this.numberOfEnrolledPatientsAtSite.length; i++)
                   {
                   let temp = (this.numberOfEnrolledPatientsAtSite[i]/(this.irbsOperationData2[i][1]))*30;
                   let roundedtemp = Math.round(temp*1000)/1000;

                   //console.log(i, this.numberOfEnrolledPatientsAtSite[i], this.irbsOperationData2[i][1]);

                   this.enrollmentRateArray.push([this.irbsOperationData2[i][0],roundedtemp]);

                   }

                    console.log('Enrollment rate Array',this.enrollmentRateArray);

                    return this.enrollmentRateArray
      }

      irbsOperationalAnalytics = (approvedIrbSites:IIrb[], numberOfEnrolledPatientsAtSite:number[], LPIArray: Array<Date>):any =>{
        console.log(this.numberOfEnrolledPatientsAtSite)
          this.irbsOperationData2 = [];

          for (let i = 0; i < this.approvedIrbCount; i++) {

                //Initialiazation
            let evaluationDate = 0;
            let timeSinceIrbApproval = 0;
            
            let today = 0;
            
            let LPIDay;
                  
            let localDate = new Date();
            let currentDate = new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000);  //convert local time to UTC 
            let lastIrbReviewDate;  // Declare last review Date here
           
          if (this.numberOfEnrolledPatientsAtSite[i] == this.approvedIrbSites[i].expectedNumberOfPatients)
            {
               lastIrbReviewDate = new Date(this.approvedIrbSites[i].reviewDate); 
               LPIDay = new Date(this.LPIArray[i]);
               
               {
                //Calculation
          
              evaluationDate = Math.round((lastIrbReviewDate.getTime())/(1000 * 60 * 60 * 24));
              today = Math.round((currentDate.getTime())/(1000 *60 * 60 *24));
              let LPItemp = Math.round((LPIDay.getTime())/(1000 *60 * 60 *24));
             
              timeSinceIrbApproval = Math.round((LPItemp - evaluationDate));
              
              this.irbsOperationData2.push([this.approvedIrbSites[i].siteName, timeSinceIrbApproval]);
              
                  }  
            }
            else{
             lastIrbReviewDate = new Date(this.approvedIrbSites[i].reviewDate);
             
             {
              //Calculation
        
            evaluationDate = Math.round((lastIrbReviewDate.getTime())/(1000 * 60 * 60 * 24));
            today = Math.round((currentDate.getTime())/(1000 *60 * 60 *24));
            
            timeSinceIrbApproval = Math.round((today - evaluationDate));
            
            this.irbsOperationData2.push([this.approvedIrbSites[i].siteName, timeSinceIrbApproval]);
            
                }  
                }
                  
                  }
                 
                  console.log('IRB, time since approval',this.irbsOperationData2);
                  console.log('IRB Operational Analytics Data', [this.irbsOperationData2]);

          return  this.irbsOperationData2

        }                 

      timeToFirstPatient = (approvedIrbSites:IIrb[], numberOfEnrolledPatientsAtSite:number[]):any =>{
  //console.log(this.numberOfEnrolledPatientsAtSite)
    this.timeToFpiArray = [];

    for (let i = 0; i < this.approvedIrbCount; i++) {

          //Initialiazation
      let evaluationDate = 0;
      let timeToFPI = 0;
      let FPIDay;
          
      let lastIrbReviewDate;  // Declare last review Date here
     
    if (this.FPIArray[i] != null)
      {
         lastIrbReviewDate = new Date(this.approvedIrbSites[i].reviewDate); 
         
         FPIDay = new Date(this.FPIArray[i])

         {
          //Calculation
    
        evaluationDate = Math.round((lastIrbReviewDate.getTime())/(1000 * 60 * 60 * 24));
        
        let FPIDayTemp = Math.round((FPIDay.getTime())/(1000 *60 * 60 *24));
        timeToFPI = Math.round((FPIDayTemp - evaluationDate));
        this.timeToFpiArray.push([this.approvedIrbSites[i].siteName,  timeToFPI]);
        
            }  
      }
      else{
      
      timeToFPI = 0;
      this.timeToFpiArray.push([this.approvedIrbSites[i].siteName,  timeToFPI]);
      
          }  
          }
            
            console.log('IRB, Time to FPI',this.timeToFpiArray);
            console.log('IRB Time to FPI ', [this.timeToFpiArray]);

    return  this.timeToFpiArray;

        }                 

                

}
