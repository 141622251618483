import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { BaseService } from "../shared/base.service";
import { ConfigService } from '../shared/config.service';

@Injectable({
  providedIn: 'root'
})


export class AdministrationService extends BaseService  {
  constructor(private http: HttpClient, private configService: ConfigService) {    
    super();      
  }

  fetchCompanyData(token: string) {   
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })
    };

    return this.http.get(this.configService.resourceApiURI + '/companies', httpOptions).pipe(catchError(this.handleError));
  }
  addCompany(token: string, company:any) {   
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })
    };

    return this.http.post(this.configService.resourceApiURI + '/companies',company, httpOptions).pipe(catchError(this.handleError));
  }
  getCompany(token: string, companyId:number) {   
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })
    };
    return this.http.get(this.configService.resourceApiURI + `/companies/${companyId.toString()}`, httpOptions).pipe(catchError(this.handleError));
  }
  updateCompany(token: string, companyId:number, company:any) {   
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })
    };

    return this.http.put(this.configService.resourceApiURI + `/companies/${companyId.toString()}`,company, httpOptions).pipe(catchError(this.handleError));
  }
  fetchTrialsData(token: string, companyId:number) {   
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })
    };

    return this.http.get(this.configService.resourceApiURI + `/trials/company/${companyId.toString()}`, httpOptions).pipe(catchError(this.handleError));
  }
  
  addTrial(token: string, trial:any) {   
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })
    };

    return this.http.post(this.configService.resourceApiURI + '/trials',trial, httpOptions).pipe(catchError(this.handleError));
  }
  getTrial(token: string, trialId:number) {   
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })
    };
    return this.http.get(this.configService.resourceApiURI + `/trials/${trialId.toString()}`, httpOptions).pipe(catchError(this.handleError));
  }
  updateTrial(token: string, trialId:number, trial:any) {   
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })
    };

    return this.http.put(this.configService.resourceApiURI + `/trials/${trialId.toString()}`,trial, httpOptions).pipe(catchError(this.handleError));
  }
  fetchAreasData(token: string, companyId:number) {   
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })
    };

    return this.http.get(this.configService.resourceApiURI + `/areas/company/${companyId.toString()}`, httpOptions).pipe(catchError(this.handleError));
  }
  fetchTrialSitesData(token: string, trialId:number) {   
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })
    };

    return this.http.get(this.configService.resourceApiURI + `/trialSites/trial/${trialId.toString()}`, httpOptions).pipe(catchError(this.handleError));
  }

  addArea(token: string, area:any) {   
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })
    };

    return this.http.post(this.configService.resourceApiURI + '/areas',area, httpOptions).pipe(catchError(this.handleError));
  }
  addATrialSite(token: string, trialSite:any) {   
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })
    };

    return this.http.post(this.configService.resourceApiURI + '/trialSites',trialSite, httpOptions).pipe(catchError(this.handleError));
  }

  getArea(token: string, areaId:number) {   
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })
    };
    return this.http.get(this.configService.resourceApiURI + `/areas/${areaId.toString()}`, httpOptions).pipe(catchError(this.handleError));
  }
  getTrialSite(token: string, trialSiteId:number) {   
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })
    };
    return this.http.get(this.configService.resourceApiURI + `/trialSites/${trialSiteId.toString()}`, httpOptions).pipe(catchError(this.handleError));
  }

  updateArea(token: string, areaId:number, area:any) {   
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })
    };

    return this.http.put(this.configService.resourceApiURI + `/areas/${areaId.toString()}`,area, httpOptions).pipe(catchError(this.handleError));
  }  
  fetchSiteData(token: string) {   
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })
    };

    return this.http.get(this.configService.resourceApiURI + '/sites', httpOptions).pipe(catchError(this.handleError));
  }
  addSite(token: string, site:any) {   
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })
    };

    return this.http.post(this.configService.resourceApiURI + '/sites',site, httpOptions).pipe(catchError(this.handleError));
  }
  getSite(token: string, siteId:number) {   
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })
    };
    return this.http.get(this.configService.resourceApiURI + `/sites/${siteId.toString()}`, httpOptions).pipe(catchError(this.handleError));
  }
  updateSite(token: string, siteId:number, site:any) {   
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })
    };

    return this.http.put(this.configService.resourceApiURI + `/sites/${siteId.toString()}`,site, httpOptions).pipe(catchError(this.handleError));
  }
  fetchTrialAreaData(token: string, trialId:number) {   
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })
    };

    return this.http.get(this.configService.resourceApiURI + `/areas/trial/${trialId.toString()}`, httpOptions).pipe(catchError(this.handleError));
  }
  addTrialSite(token: string, trialSite:any) {   
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })
    };
    return this.http.post(this.configService.resourceApiURI + '/trialSites',trialSite, httpOptions).pipe(catchError(this.handleError));
  }
  addProtocol(token: string, protocol:any) {   
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })
    };

    return this.http.post(this.configService.resourceApiURI + '/protocols',protocol, httpOptions).pipe(catchError(this.handleError));
  }
  addAreaProtocol(token: string, protocol:any) {   
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })
    };

    return this.http.post(this.configService.resourceApiURI + '/areaProtocols',protocol, httpOptions).pipe(catchError(this.handleError));
  }
  getProtocolbyId(token: string, protocolId:number) {   
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })
    };
    return this.http.get(this.configService.resourceApiURI + `/protocols/${protocolId.toString()}`, httpOptions).pipe(catchError(this.handleError));
  }
  getAreaProtocolbyId(token: string, protocolId:number) {   
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })
    };
    return this.http.get(this.configService.resourceApiURI + `/areaProtocols/${protocolId.toString()}`, httpOptions).pipe(catchError(this.handleError));
  }
  getProtocolbyTrialId(token: string, trialId:number) {   
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })
    };
    return this.http.get(this.configService.resourceApiURI + `/protocols/trial/${trialId.toString()}`, httpOptions).pipe(catchError(this.handleError));
  }
  getAreaProtocolbyTrialId(token: string, trialId:number, areaId:number) {   
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })
    };
    return this.http.get(this.configService.resourceApiURI + 
      `/areaProtocols/trial/${trialId.toString()}/${areaId.toString()}`, httpOptions).pipe(catchError(this.handleError));
  }
  fetchInclusionCriteriaData(token: string,protocolId:number) {   
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })
    };

    return this.http.get(this.configService.resourceApiURI + `/inclusionCriteria/protocol/${protocolId.toString()}`, httpOptions).pipe(catchError(this.handleError));
  }
  
  fetchAreaInclusionCriteriaData(token: string,protocolId:number) {   
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })
    };

    return this.http.get(this.configService.resourceApiURI + `/areaInclusionCriterias/protocol/${protocolId.toString()}`, httpOptions).pipe(catchError(this.handleError));
  }
  addInclusionCriteria(token: string, inclusionCriteria:any) {   
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })
    };

    return this.http.post(this.configService.resourceApiURI + '/inclusionCriteria',inclusionCriteria, httpOptions).pipe(catchError(this.handleError));
  }
  addAreaInclusionCriteria(token: string, inclusionCriteria:any) {   
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })
    };

    return this.http.post(this.configService.resourceApiURI + '/areainclusionCriterias',inclusionCriteria, httpOptions).pipe(catchError(this.handleError));
  }
}
