import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators'
import { AuthService } from '../../core/authentication/auth.service';
import { AdministrationService } from '../administration.service';
import { Router, CanActivate, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AreaProtocol } from 'src/app/shared/interfaces/areaProtocol';

@Component({
  selector: 'app-area-protocol',
  templateUrl: './area-protocol.component.html',
  styleUrls: ['./area-protocol.component.sass']
})
export class AreaProtocolComponent implements OnInit {

  companyId: number;
  protocol: AreaProtocol;
  protocols: any;
  area: any;
  error: boolean;
  trial: any;
  areas: any;
  success: boolean = false;
  trialId: number;
  areaId: number;
  busy: boolean = true;
  areaBusy: boolean;//retrieving areas
  fetchAreaBusy: boolean;
  protocolBusy: boolean = true;//loading trials
  noProtocolExists: boolean = true;
  viewFormAddProtocol: boolean = false;
  viewButtonAddProtocol: boolean = false;
  createProtocolButtonText: string = "Create Protocol";
  constructor(private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private administrationService: AdministrationService,
    private spinner: NgxSpinnerService) {
    this.protocol = new AreaProtocol();
  }

  ngOnInit(): void {
    this.busy = true;
    this.protocolBusy = true;
    this.spinner.show();
    this.trialId = +this.route.snapshot.paramMap.get('trialId');
    this.protocol = new AreaProtocol();
    this.protocol.areaId=0;
    this.protocol.trialId = +this.trialId;
    //get trial
    this.busy = true;
    this.administrationService.getTrial(this.authService.authorizationHeaderValue, this.trialId)
      .pipe(finalize(() => {
        this.busy = false;
      })).subscribe(
        result => {
          this.trial = result;
          this.companyId = this.trial.company.companyId;
          this.getAreas(); // will hide spinner
          console.log(this.trial);
        });


  }
  getAreas() {
    this.spinner.show();
    this.areaBusy = true;
    this.administrationService.fetchTrialAreaData(this.authService.authorizationHeaderValue, this.trialId)
      .pipe(finalize(() => {
        this.areaBusy = false;
        this.hideSpinner();
      })).subscribe(
        result => {
          this.areas = result;
          console.log(result);
        });
  }
  getArea(id: number) {
    this.spinner.show();
    this.fetchAreaBusy = true;
    this.administrationService.getArea(this.authService.authorizationHeaderValue, id)
      .pipe(finalize(() => {
        this.fetchAreaBusy = false;
        this.hideSpinner();
      })).subscribe(
        result => {
          this.area = result;
          console.log(result);
        });
  }
  getProtocols() {

    this.protocolBusy = true;
    this.administrationService.getAreaProtocolbyTrialId(
      this.authService.authorizationHeaderValue, this.trialId, this.areaId)
      .pipe(finalize(() => {
        this.protocolBusy = false;
        this.hideSpinner();
      })).subscribe(
        result => {
          this.protocols = result;
          if (this.protocols.length == 0) {
            this.noProtocolExists = true;
            this.viewFormAddProtocol = true;
            this.createProtocolButtonText = "Create Protocol";
          }
          else {
            this.noProtocolExists = false;
            this.viewFormAddProtocol = false;
            this.createProtocolButtonText = "Amend Protocol";
          }
        });

  }
  showFormAddProtocol() {
    this.viewFormAddProtocol = true;
  }
  onClick() {
    console.log(`AreaId: ${this.protocol.areaId}`);
    this.areaId = this.protocol.areaId;
    this.getArea(this.areaId);
    this.getProtocols();
    console.log(this.protocol);
    //get protocols for this area
  }
  onSubmit(): void {
    console.log('submit');

    this.busy = true;
    this.spinner.show();
    debugger;
    this.administrationService.addAreaProtocol(this.authService.authorizationHeaderValue, this.protocol)
      .pipe(finalize(() => {
        //+get all protocols

        this.busy = false;
        this.getProtocols();
        this.hideSpinner();
      })).subscribe(
        result => {

          //this.router.navigate([`/companyTrials/${this.companyId}`]);//view list
        },
        error => {
          this.error = error;
        });

  }
  hideSpinner()
  {
    if(!this.busy && !this.areaBusy && !this.fetchAreaBusy)
    this.spinner.hide();
  }
}
