export class PrincipalInvestigator
 {
    public id: number;  // PI Id
    public siteName: string;
    public address: string;
    public piName: string;
    public piEmail: string;
    public msl: string;
    public mslEmail: string;
}
export interface IPrincipalInvestigator
 {
    id: number;  // PI Id
    siteName: string;
    address: string;
    piName: string;
    piEmail: string;
    msl: string;
    mslEmail: string;
}