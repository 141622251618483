import { IIrb } from './irb';

export class Patient
{
    public id:number;
    public irbId:number; // links the patient to the local site=>local PI
    public patientNumber:string;
    public dateOfBirth:Date;//yyyymmdd
    public biologicalGender:string;
    public screeningDate:Date;//bar code creation
    public screeningResultsDate:Date;
    public selected:boolean; // undefined/yes/No
    public consentGivenDate:Date; 
    public consentSignedDate:Date;//date signed consent is returned
    public consent:boolean; // undefined/yes/no undefined => can chase
    public screeningComment: string;
    public clinicalTreatmentCost:number;
    public medicalAnalysisCost:number;
    public createdBy:string;
    public dateCreated:Date;
    public modifiedBy: string;
    public dateModified: Date;
    public deleted:boolean;
}
export interface IPatient
{
    id:number;
    irbId:number; 
    irb:IIrb;
    patientNumber:string;//Identifier from the hospital or local investigation site
    dateOfBirth:Date;//yyyymmdd
    biologicalGender:string;
    screeningDate:Date;//bar code creation
    screeningResultsDate:Date;
    selected:boolean; // undefined/yes/No
    consentGivenDate:Date; 
    consentSignedDate:Date;//date signed consent is returned
    consent:boolean;
    screeningComment: string;
    clinicalTreatmentCost:number;
    medicalAnalysisCost:number;
    createdBy:string;
    dateCreated:Date;
    modifiedBy: string;
    dateModified: Date;
    deleted:boolean;
}
export interface IPatientAudits
{
    id:number;
    patientId:number; //the patient's id 
    patientNumber:string;
    dateOfBirth:Date;//yyyymmdd
    biologicalGender:string;
    screeningDate:Date;//bar code creation
    screeningResultsDate:Date;
    selected:boolean; // undefined/yes/No
    consentGivenDate:Date; 
    consentSignedDate:Date;//date signed consent is returned
    consent:boolean;
    screeningComment: string;
    clinicalTreatmentCost:number;
    medicalAnalysisCost:number;
    modifiedBy: string;
    dateModified: Date;
}