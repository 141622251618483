import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-patient-engagement',
  templateUrl: './patient-engagement.component.html',
  styleUrls: ['./patient-engagement.component.sass']
})
export class PatientEngagementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
