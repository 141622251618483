<div>
    <div class="row mb-1" >
        <div class="col-lg-12 text-center">
            <h1>IRB AUDIT</h1>
            <p *ngIf="siteReady"> Study Name: {{site.studyName}}</p>
            
            <!-- <p    *ngIf="irbAuditReady">IRB Site {{aIrbAudit.siteName}}</p> -->

        </div>
    </div>
    <div>
        <div *ngFor="let aIrbAudit of irbAuditList; let i = index" [attr.data-index]="i">
            
            <table class="table small">
                <thead>
    
                    <tr>
                        <th scope="col">Audit Trail</th>
                        <th scope="col">Status</th>
                        <th scope="col">Institution Name</th>
                        <th scope="col">Address</th>
                        <th scope="col">Expected Number of Patients</th>
                        
                    </tr>

                </thead>
            </table> 

                            <!---->
       <!--  <tbody>
             
                 <div class="col-lg-1 text-center fontRed font-weight-bold "> 
                    <th for>{{irbAuditCount-i}}</th>

                </div>
                

                    
                    <td>{{approvalStatus(aIrbAudit)}}, {{approvalStatusClass(aIrbAudit)}}</td>
                    <td>{{aIrbAudit.siteName}}</td>
                    <td>{{aIrbAudit.geoLocation}}</td>
                    <td>{{aIrbAudit.expectedNumberOfPatients}}</td> 
                    <a  class="btn btn-primary form-control {{approvalStatusClass(aIrbAudit)}}"
                         role="button" title={{approvalStatus(aIrbAudit)}} aria-disabled="true">
                         {{approvalStatus(aIrbAudit)}}
                    </a>
                
        </tbody> -->
                        <!---->
      
       <div class="row mb-1">
            <div class="col-lg-1 text-center fontRed font-weight-bold">
                <label for>{{irbAuditCount-i}}</label>

            </div>

            <div class="col-lg-2  text-center {{approvalStatusClass(aIrbAudit)}}">
                <label for> {{approvalStatus(aIrbAudit)}}</label>

            </div>

            <div class="col-lg-2 text-center" title="Site Name">
                <label for>{{aIrbAudit.siteName}}</label>
            </div>
            <div class="col-lg-3  text-center" title="Local Site Address">
                <label for>{{aIrbAudit.geoLocation}}</label>
            </div>

            <div class="col-lg-2  text-center" title="Expected Number Of Patients">
                <label for>{{aIrbAudit.expectedNumberOfPatients}}</label>
            </div>
        </div>  
                            <!---->

            <table class="table small">
                <thead>
    
                    <tr>
                        <th scope="col">-.-.-.-.-</th>
                        <th scope="col">PI Name</th>
                        <th scope="col">PI Email</th>
                        <th scope="col">MSL Name</th>
                        <th scope="col">MSL Email</th>
                    </tr>
                </thead>
            
        <tbody>
           <!-- <div class="row mb-1"> -->
                <div class="col-lg-1">

                </div>  
                
                    <td>{{aIrbAudit.piName}}</td>
                    <td>{{aIrbAudit.piEmail}}</td>
                    <td>{{aIrbAudit.msl}}</td>
                    <td>{{aIrbAudit.mslEmail}}</td>
                
            </tbody>
            </table>
           
            <table class="table small">
                <thead>
    
                    <tr>
                        <th scope="col">-.-.-.-.-</th>
                        <th scope="col">Submission Date</th>
                       <!-- <th scope="col">Expected Review Date</th>-->
                        <th scope="col">Actual Review Date</th>
                        <th scope="col">Modified By</th>
                        <th scope="col">Modification Date</th>
                    </tr>
                </thead>
           

            <tbody>
                <div class="col-lg-1">  </div>

                
                    <td>{{aIrbAudit.submissionDate|date:'d MMM yyyy H:mm:ss'}}</td>
                  <!--  <td>{{aIrbAudit.expectedReviewDate|date:'d MMM yyyy'}}</td>-->
                    <td>{{aIrbAudit.reviewDate|date:'d MMM yyyy H:mm:ss'}}</td>
                    <td>{{aIrbAudit.modifiedBy}}</td>
                    <td>{{aIrbAudit.dateModified|date:'d MMM yyyy H:mm:ss'}}</td>
                
            </tbody>
            
        </table>

            <div class="row mb-1" *ngIf="approvalStatus(aIrbAudit)=='Pending Revision'">
                <div class="col-lg-12">
                    <textarea id="comment" name="comment" [(ngModel)]="aIrbAudit.comment" class="form-control COMMENTBOX"
                        readonly></textarea>

                </div>
            </div>
            <div class="row mb-1">
                <div class="col-lg-12">
                    <hr />
                </div>
            </div>


        </div>
        
        <div class="row mb-1">

            <div class="col-lg-8 col-xs-12">
            </div>
            <div class="col-lg-4 col-xs-12">
                <div class="form-group">
                    <a class="btn btn-lg btn-primary btn-block" [routerLink]="['/irbSubmission', conceptId]"
                        role="button">Back</a>


                </div>
            </div>
            <div class="col-lg-2 col-xs-12">
            </div>

        </div>
   </div> 
   
    <ngx-spinner type="pacman">
    <p>Loading...</p></ngx-spinner>
</div>