import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../../core/authentication/auth.service';
import { finalize } from 'rxjs/operators'
import { Subscription } from 'rxjs';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ClaimsService } from '../claims.service';
@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit, OnDestroy  {
  isAuthenticated:boolean;
  isBusy:boolean=true;
  subscription:Subscription;
  admin:boolean=false;
  sponsor:boolean=false;
  principalInvestigator:boolean=false;
  studies= new Array();
  localpi:boolean=false;
  localSite=new Array();
  treatmentCoordinator:boolean=false;
  localtreatmentSite=new Array();
  claimList=null;
  name:string="";
  userName:string="";
  study:number;

  constructor(private authService:AuthService,private router:Router, private claims:ClaimsService) { }

  ngOnInit() {
    this.subscription = this.authService.authNavStatus$.subscribe(status => this.isAuthenticated = status);
  
    if (this.authService.isAuthenticated()) { 
      sessionStorage.clear();
      
      this.getProfile();
      return true; }
      
      console.log("it is not authenticated");
      this.authService.login();
    // this.router.navigate(['/login']);
    //return false;


  }
  getDetails(){

    this.claimList.forEach(element => {
      if(element.type=="name"){
        this.name=element.value;
        sessionStorage.setItem('name', this.name)
      }
      if(element.type=="userName"){
        this.userName=element.value;
        sessionStorage.setItem('userName', this.userName)
      }
      
      if(element.type=="admin"){
        this.admin=true;
      }
      //"Sponsor"
      if(element.type=="Sponsor"){
        this.sponsor=true;
      }
      //  localpi
      
      if(element.type=="Local PI"){
        this.localpi=true;
        
        if (Number.isInteger(element.value)){
          this.localSite.push(Number(element.value))
        }
      }
      //"Treatment Coordinator"
      if(element.type=="Treatment Coordinator"){
        this.treatmentCoordinator=true;
        
        if (Number.isInteger(element.value)){
          this.localtreatmentSite.push(Number(element.value))
        }
      }
    });
  }
  getProfile()
  {
    this.claims.fetchData(this.authService.authorizationHeaderValue)
    .pipe(finalize(() => {
  /*     this.spinner.hide();
      this.busy = false;
  */   })).subscribe(
    result => {         
      this.claimList = result;
      this.getDetails();
      console.log(this.claimList);
      this.claimList.forEach(element => {
        if(element.type=="Principal Investigator"){
          this.principalInvestigator=true;
          this.study=Number(element.value);
          if (Number.isInteger(this.study))
          {
            this.studies.push(this.study)
          }
          console.log(`Principal investigator - Hospital=${element.value}`);
        }
      });
      
      if (this.principalInvestigator)
      {
        sessionStorage.setItem('studies', JSON.stringify(this.studies));
        sessionStorage.setItem('principalInvestigator',JSON.stringify(this.principalInvestigator)) ;
        //send to hospitals
        console.log(`Principal investigator - Hospital silence`);
       // this.router.navigate(['/hospitals']);
        
      }
      else{
        sessionStorage.setItem('principalInvestigator',JSON.stringify(this.principalInvestigator) );
      }
      this.isBusy=false;
   });
  }
  ngOnDestroy() {
    // prevent memory leak when component is destroyed
    this.subscription.unsubscribe();
  }
}
