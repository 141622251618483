import { asNativeElements, Component, OnInit } from '@angular/core';
import {SiteSetup, ISiteSetup} from 'src/app/shared/interfaces/siteSetup';
import { IStudyType } from 'src/app/shared/interfaces/studyTypes';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators'
import { AuthService } from '../../core/authentication/auth.service';
import { IssAdministrationService } from '../iss-administration.service';
import { Router, CanActivate, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { IConceptEvaluation } from 'src/app/shared/interfaces/conceptEvaluation';
import { IContractNegociation } from 'src/app/shared/interfaces/contractNegociation';
import { IProtocolEvaluation } from 'src/app/shared/interfaces/protocolEvaluation';
//import { error } from 'protractor';
@Component({
  selector: 'app-site-setup',
  templateUrl: './site-setup.component.html',
  styleUrls: ['../styles.sass','./site-setup.component.sass']
})
export class SiteSetupComponent implements OnInit {
site:SiteSetup=new SiteSetup();
buttonBaseClass:string="btn btn-lg form-control ";

studyTypeId:number;
studyTypeDescription:string;
error:boolean=false;
busy:boolean=false;
isCollapsed:boolean=true;
setUpTooltip:string="Click to set up a new site";
studyTypes:IStudyType[]; 
studyType: IStudyType;
studyTypeFiltered:IStudyType; //AAA

siteSetups:ISiteSetup[];
SiteSetup:ISiteSetup[];
statusString:string;
typeString:string;
studyStatus:string="0";
statusNumber:string="0";
typeNumber:string="0";
siteSetupsCount:number;

siteSetupsFilteredByStatusCount:number;
siteSetupsFilteredByStatus:ISiteSetup[];
siteSetupsFilteredCount:number;
siteSetupsFiltered:ISiteSetup[];

conceptEvaluationStatus:IConceptEvaluation[];
protocolEvaluationStatus:IProtocolEvaluation[];
contractEvaluationStatus:IContractNegociation[];

conceptEvaluationDate:Date;
protocolEvaluationDate:Date;
contratcEvaluationDate:Date;
  constructor(private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private administrationService: IssAdministrationService,
    private spinner: NgxSpinnerService) { 
    this.busy = true;
    this.spinner.show();
    this.administrationService.getStudyTypes(this.authService.authorizationHeaderValue)
      .pipe(finalize(() => {
          this.busy = false;
          this.hideSpinner();
      })).subscribe(
        result => {
          console.log("All Studies");
          this.studyTypes = result;
          //this.siteSetupsFiltered=result;
          console.log(this.studyTypes);
        });

    }

  ngOnInit(): void { 
    this.getSiteSetups();
  }
  getSiteSetups():void{
    this.spinner.show();
    this.administrationService.getIssSites(this.authService.authorizationHeaderValue)
    .pipe(finalize(() => {
        this.busy = false;
        this.hideSpinner();
    })).subscribe(
      result => {
        this.siteSetups=result;
        this.siteSetupsFiltered=result;
        this.siteSetupsCount=this.siteSetups.length; // counting the number of sites in the list
        this.siteSetupsFilteredCount=this.siteSetupsFiltered.length; // Counting filtering result
        console.log("All Studies");
        
        console.log(this.siteSetups);
        setTimeout(() => {
          this.analyseSites();
        }, 1);
      });
  }

  analyseSites():void{
    let i:number=0;
    for(i=0;i<this.siteSetups.length;i++)
     {
      let a=this.siteSetups[i];
      
        if (a.latitude==null || a.longitude==null || a.country==null ){  //
          this.getLongitudeLatitude(a , i);
         }
        
     };
    }
    getLongitudeLatitude(a:ISiteSetup, i:number): void{
      this.administrationService.trialomsGeocode(a.geoLocation)
      .pipe(finalize(() => {
          
      })).subscribe(
        results => {
          console.log (results);
          try{ 
          if(results.status=="OK"){
            var formattedAddress=results.results[0].formatted_address;

            this.siteSetups[i].country=this.getCountry(formattedAddress).trim();

            this.siteSetups[i].longitude=results.results[0].geometry.location.lng.toString();
          this.siteSetups[i].latitude= results.results[0].geometry.location.lat.toString();
          this.updateLongitudeLatitude(this.siteSetups[i]);
        }

        }
        catch (e){
          console.log (results);
          console.log('i='+i.toString());
          console.log(e.message);
        }
          // console.log('id=' + a.id.toString());
          // console.log ('latitude='+results.results[0].geometry.location.lat.toString());
          // console.log ('longitude='+ results.results[0].geometry.location.lng.toString());
          
        });
  
    }
    getCountry(formattedAddress:string): string{
      var positionOfCountry=formattedAddress.lastIndexOf(",");
      var country=formattedAddress.substring(positionOfCountry);
      country=country.substring(1,country.length)
      return country;
    }
   updateLongitudeLatitude(a:ISiteSetup): void{
      this.administrationService.updateLatitudeLongitude(this.authService.authorizationHeaderValue,a)
      .pipe(finalize(() => {
          
      })).subscribe(
        results => {
          console.log(a);
        });
   }

   

  hideSpinner=():void =>{
    this.spinner.hide();
  }
  onSubmit= ():void=>{
      this.administrationService.siteSetup(this.authService.authorizationHeaderValue, this.site)
      .pipe(finalize(() => {
          this.busy = false;
          this.hideSpinner();
      })).subscribe(
        result => {
        this.getSiteSetups();

          console.log(result);
          this.site=new SiteSetup();
        });
  } 
//Filter Implementatiuon (place holder)
filterSites =(): void =>{
this.filterByStatus();
this.filterByType();

};

filterByStatus= ():void=>{
  var myStatusString=[" ", "Concept Evaluation", "Concept Re-evaluation", "Rejected (Concept)", "Protocol Submission", "Protocol Evaluation", 
  "Protocol Re-evaluation", "Rejected (Protocol)", "Contract Negotiation", "Documents Check"]
  
let index=Number(this.statusNumber);
this.statusString=myStatusString[index];
console.log(`${this.statusString} number= ${index}`);

if(index==0){
  this.siteSetupsFilteredByStatus=this.siteSetups
  this.siteSetupsFilteredByStatusCount=this.siteSetupsFilteredByStatus.length; // Counting filtering result
}
else{

  this.siteSetupsFilteredByStatus=this.siteSetups.filter(aSite=>
  this.EvaluationStatus(aSite) == this.statusString ); 
  
  this.siteSetupsFilteredByStatusCount=this.siteSetupsFilteredByStatus.length; // Counting filtering result
}
}

// STARTING FILTERING

EvaluationStatus = (aSite:ISiteSetup): string => {
    if (aSite.contractEvaluationStatus== 'Approved'  ){
      
        return 'Documents Check';
      }
     
      if (aSite.contractEvaluationStatus== 'Conditional approval pending revisions'  ){
      
        return 'Contract Negotiation';
     }

     if (aSite.protocolEvaluationStatus=='Approved' &&  aSite.contractEvaluationStatus!= 'NULL'){
     return 'Contract Negotiation';
     }

      if (aSite.protocolEvaluationStatus=='Conditional approval pending revisions'){
        return 'Protocol Re-evaluation';
      }

     if (aSite.protocolEvaluationStatus!=null && aSite.protocolEvaluationDate==null){
        return 'Protocol Submission';
      }
     
      if (aSite.conceptEvaluationStatus=='Approved' && aSite.protocolEvaluationStatus=='Pending Revision'){
        return 'Protocol Evaluation';
      }

      if (aSite.protocolSubmissionDate!=null && aSite.protocolEvaluationStatus==null){
        return 'Protocol Evaluation';
      }  

      if (aSite.conceptEvaluationStatus== 'Approved' && aSite.protocolSubmissionDate==null){
        return 'Protocol Submission';
        }

     if (aSite.conceptEvaluationStatus== 'Pending additional information'){
        return 'Concept Re-evaluation';
      }

     if (aSite.conceptEvaluationStatus==null){
        return 'Concept Evaluation';
      }

    if (aSite.protocolEvaluationStatus=='Rejected'){
        return 'Rejected (Protocol)'; 
       }
       
      if (aSite.conceptEvaluationStatus=='Rejected'){
        return 'Rejected (Concept)';
       }

        return '@#!';
         }
        

  // " EvaluationStatus" lines were inserted for filetring

  nextStepTitle=(aSite:SiteSetup):string =>{
    if (aSite.conceptEvaluationStatus==null)
    {
      return 'Concept Evaluation ';
    }
    
    if (aSite.conceptEvaluationStatus=='Rejected')
    {
      return 'Concept Rejected';
    }
    
    if (aSite.conceptEvaluationStatus=='Pending additional information')
    {
      return 'Concept Re-evaluation';
    }
    if (aSite.conceptEvaluationStatus=='Approved' && aSite.protocolSubmissionDate==null)
    {
      return 'Protocol Submission';
    }
 
    if (aSite.protocolSubmissionDate!=null && aSite.protocolEvaluationStatus==null){
      return 'Protocol Evaluation';
    }
    
    if (aSite.protocolSubmissionDate==null && aSite.protocolEvaluationStatus=='Conditional approval pending revisions'){
      return 'Protocol Re-submission';
      }

    if (aSite.protocolSubmissionDate!=null && aSite.protocolEvaluationStatus=='Conditional approval pending revisions'){
      return 'Protocol Re-evaluation';
   }

   
    //if (aSite.protocolEvaluationStatus!='Approved' && aSite.protocolEvaluationStatus!='Rejected'){
      // pending => need to submit modifications
     // return 'Protocol Evaluation'; 
   // }
    if ( aSite.protocolEvaluationStatus=='Rejected'){
      return 'Protocol Rejected'; 
    }
    if ( aSite.protocolEvaluationStatus=='Approved' && aSite.contractEvaluationStatus!='Approved'){
      return 'Contract Negotiation';
    }    
    if ( aSite.contractEvaluationStatus!='Approved'){
      return 'Contract Negotiation';
    }    
    if ( aSite.contractEvaluationStatus=='Approved'){
      return 'Documents Check';
    }

    return "";
  }
  nextStepLink=(aSite:SiteSetup):[string,number]=>{
    if (aSite.conceptEvaluationStatus==null)
    {
      return ['/conceptApproval' , aSite.id];
    }
    
    if (aSite.conceptEvaluationStatus=='Pending additional information')
    {
      return ['/conceptApproval' , aSite.id];
    }

    if (aSite.conceptEvaluationStatus=='Rejected')
    {
      return ['/',0];
    }
    
    if (aSite.conceptEvaluationStatus=='Approved' && aSite.protocolSubmissionDate==null)
    {
      return ['/protocolSubmission' , aSite.id];
    }

    if (aSite.protocolSubmissionDate!=null && aSite.protocolEvaluationStatus==null){
      return ['/protocolApproval' , aSite.id];
    }

    if (aSite.protocolSubmissionDate==null && aSite.protocolEvaluationStatus=='Conditional approval pending revisions'){
      return ['/protocolSubmission' , aSite.id]; 
      //In case of a re-evaluation
    }

    if (aSite.protocolSubmissionDate!=null && aSite.protocolEvaluationStatus=='Conditional approval pending revisions'){
      return ['/protocolApproval' , aSite.id];
    } 
    
    
    //if ( aSite.protocolEvaluationStatus!='Rejected' && aSite.protocolEvaluationStatus!='Approved'){
      //return ['/protocolSubmission' , aSite.id];
   // }
    if ( aSite.protocolEvaluationStatus=='Rejected'){
      return ['/',0];
    }
    if ( aSite.protocolEvaluationStatus=='Approved' && aSite.contractEvaluationStatus!='Approved'){
      return ['/negotiation' , aSite.id];
    }
    
    if ( aSite.contractEvaluationStatus!='Approved'){
      return ['/negotiation' , aSite.id];
    }    
    if ( aSite.contractEvaluationStatus=='Approved'){
      return ['/document' , aSite.id];
    }
    return ['/conceptApproval' , aSite.id];
    // return ['/conceptApproval' , aStudy.id];
  }
  
  //btn btn-lg btn-primary form-control
  nextStepClass=(aSite:SiteSetup):string=>{
    if (aSite.conceptEvaluationStatus==null)
    {
      return this.buttonBaseClass+'concept';
    }
    
    if (aSite.conceptEvaluationStatus=='Rejected')
    {
      return this.buttonBaseClass+'rejected';
    }
    
    if (aSite.conceptEvaluationStatus=='Pending additional information')
    {
      return this.buttonBaseClass+'concept';
    }
    if (aSite.conceptEvaluationStatus=='Approved' && aSite.protocolSubmissionDate==null)
    {
      return this.buttonBaseClass+'protocol';
    }
 
    if (aSite.protocolSubmissionDate!=null && aSite.protocolEvaluationStatus==null){
      return this.buttonBaseClass+'protocol';
    }
    if ( aSite.protocolEvaluationStatus!='Approved' && aSite.protocolEvaluationStatus!='Rejected'){
      // pending => need to submit modifications
      return this.buttonBaseClass+'protocol';
    }
    if ( aSite.protocolEvaluationStatus=='Rejected')
    {
      return this.buttonBaseClass+'rejected';
    }
    if ( aSite.protocolEvaluationStatus=='Approved' && aSite.contractEvaluationStatus!='Approved')
    {
      return this.buttonBaseClass+'negotiation';
    }    
    if ( aSite.contractEvaluationStatus!='Approved')
    {
      return this.buttonBaseClass+'negotiation';
    }    
    if ( aSite.contractEvaluationStatus=='Approved')
    {
      return this.buttonBaseClass+'document';
    }
    return '@#!';
  }



  // Filter by Type of Study

  filterByType= ():void=>{
    var myTypeString=["", "Clinical", "Pre-Clinical", "Observational" ]
    
    let index=Number(this.typeNumber);
    this.typeString=myTypeString[index];
  
    console.log(`${this.typeString} number= ${this.typeNumber}`);
  
  if(index == 0){
    this.siteSetupsFiltered=this.siteSetupsFilteredByStatus;
    this.siteSetupsFilteredCount=this.siteSetupsFilteredByStatus.length; // Counting filtering result
  }
  else{
  
    this.siteSetupsFiltered=this.siteSetupsFilteredByStatus.filter(aSite=>
      aSite.studyType.id == Number( this.typeNumber));
      this.siteSetupsFilteredCount=this.siteSetupsFiltered.length; // Counting filtering result
  }
  console.log(this.siteSetupsFiltered);
  }
  
  studyTypeSelection = (aSite:SiteSetup): string =>{
    if (aSite.studyTypeId == 1){
      return '1'
    }
  
    if (aSite.studyTypeId == 2){
      return '2'
    }
  
    if (aSite.studyTypeId == 3){
      return '3'
    }
  
  }  


  //End Filter by Type of Study

  mouseOverSetUp=():void=>{
    if (this.isCollapsed){
      this.setUpTooltip="Click to set up a new site";
    }
    else{
      this.setUpTooltip="Click to hide fields and only view the list of sites";
    }
  }
}


