import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-protocol-submission',
  templateUrl: './protocol-submission.component.html',
  styleUrls: ['./protocol-submission.component.sass']
})
export class ProtocolSubmissionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
