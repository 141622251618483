<div class="row justify-content-center">
    <div class="col-md-8 text-center">
      <form *ngIf="!success" #f="ngForm" (ngSubmit)="onSubmit()">
        <!-- <img class="mb-4" src="assets/images/angular_solidBlack.svg" alt="" width="125" height="125"> -->
        <h1 class="h3 mb-3 font-weight-normal">New Site</h1>
        <div class="form-group">
            <label for="siteName" class="sr-only">Site Name</label>
            <input type="text" id="siteName" name="siteName" [(ngModel)]="site.siteName" 
            class="form-control" placeholder="Site Name" required autoFocus>
        </div>
        <div class="form-group">
            <label for="siteDescription" class="sr-only">Site Description</label>
            <input type="text" id="siteDescription" name="siteDescription"  [(ngModel)]="site.siteDescription"
             class="form-control" placeholder="Site Description" required>
        </div>
        
        <div class="form-group">
            <label for="geoLocation" class="sr-only">Geolocation</label>
            <input type="text" id="geoLocation" name="geoLocation"  [(ngModel)]="site.geoLocation" class="form-control" 
            placeholder="GeoLocation" >
        </div>
        <a class="btn btn-lg btn-primary btn-block"
        routerLink="/site"
        role="button" >Cancel</a>
        <button class="btn btn-lg btn-primary btn-block" type="submit" [disabled]="!f.form.valid">Add Site</button>
        <ngx-spinner type="pacman">
    <p>Loading...</p></ngx-spinner>   
        <div *ngIf="error" class="alert alert-danger mt-3" role="alert">
            <strong>Oops!</strong> {{error}}
        </div>     
      </form>      
      <div *ngIf="success" class="alert alert-success" role="alert">
          <h4 class="alert-heading">Well done!</h4>
          <p>Site was created successfully, <a routerLink="/site">Please click here to continue</a>.</p>        
      </div>  
    </div> 
  </div>
