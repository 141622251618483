import { Irb } from "./irb";

export class Material
{
    public id:number;
    public irbId:number;
    public batchNumber:string;
    public manufacturingReleaseDate:Date;
    public expiryDate:Date;
    public numberOfItems: number;
    public leadTime:number;
    public leadTimeUnit:string;
   
}
export interface IMaterial{
    id:number;
    irb:Irb;
    batchNumber:string;
    manufacturingReleaseDate:Date;
    expiryDate:Date;
    numberOfItems: number;
    leadTime: number;
    leadTimeUnit:string;
    dateCreated:Date;
    dateModified:Date;
    createdBy:string;
    modifiedBy:string
   
}