import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router'; // CLI imports router
import { Shell } from './../shell/shell.service';
import { AuthGuard } from '../core/authentication/auth.guard';
import { from } from 'rxjs';
import {SiteSetupComponent} from './site-setup/site-setup.component';
import {ConceptApprovalComponent} from './concept-approval/concept-approval.component';
import {ProtocolSubmissionComponent} from './protocol-submission/protocol-submission.component';
import {ProtocolApprovalComponent} from './protocol-approval/protocol-approval.component';
import {NegotiationComponent} from './negotiation/negotiation.component';
import {DocumentComponent} from './document/document.component';
import {AuditComponent} from './audit/audit.component';
import {PatientRecruitmentComponent} from './patient-recruitment/patient-recruitment.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { PiDashboardComponent } from './pi-dashboard/pi-dashboard.component'; 
import { DiseaseAreaComponent } from './disease-area/disease-area.component';
import {PrincipalInvestigatorComponent} from './principal-investigator/principal-investigator.component'
import { PiAnalyticsComponent } from '../principal-investigator/pi-analytics/pi-analytics.component';
import {MaterialManagementComponent} from './material-management/material-management.component';
import {MaterialManagementViewComponent} from './material-management-view/material-management-view.component';
import {MaterialManagementAddComponent} from './material-management-add/material-management-add.component';
import { PiAggregateComponent } from '../principal-investigator/pi-aggregate/pi-aggregate.component';
import { PiPatientsComponent } from '../principal-investigator/pi-patients/pi-patients.component';
import { MaterialDisposalComponent } from '../principal-investigator/material-disposal/material-disposal.component';
import { LocalPiDashboardComponent } from '../principal-investigator/local-pi-dashboard/local-pi-dashboard.component';
import { MaterialReturnComponent } from '../principal-investigator/material-return/material-return.component';
import { RegisterPiComponent } from './register-pi/register-pi.component';
import { RegisterLocalPiComponent } from './register-local-pi/register-local-pi.component';
import { PiIrbComponent } from '../principal-investigator/pi-irb/pi-irb.component';
import { SponsorIrbsAllComponent } from './sponsor-irbs-all/sponsor-irbs-all.component';
import { SponsorIrbDetailComponent } from './sponsor-irb-detail/sponsor-irb-detail.component';
import { LocalPiIrbComponent } from '../principal-investigator/local-pi-irb/local-pi-irb.component';
import { SponsorAggregateComponent } from './sponsor-aggregate/sponsor-aggregate.component';
import { SponsorParametersSetupComponent } from './sponsor-parameters-setup/sponsor-parameters-setup.component';
import { ViewParametersComponent } from './view-parameters/view-parameters.component';
import { CreateParameterComponent } from './create-parameter/create-parameter.component';
import { UpdateParameterComponent } from './update-parameter/update-parameter.component';
import { ParameterAuditComponent } from './parameter-audit/parameter-audit.component';

const routes: Routes = [
    Shell.childRoutes([
    
      { path: 'siteSetup', component: SiteSetupComponent, canActivate: [AuthGuard] } ,
      { path: 'PISetup', component: PrincipalInvestigatorComponent, canActivate: [AuthGuard] } ,
      
    { path: 'conceptApproval/:conceptId', component:ConceptApprovalComponent,canActivate: [AuthGuard] }, 
    { path: 'conceptEvaluationAudit/:conceptId', component:ConceptApprovalComponent,canActivate: [AuthGuard] }, //
    { path: 'protocolSubmission/:conceptId', component:ProtocolSubmissionComponent,canActivate: [AuthGuard] }, //displayirb
    { path: 'protocolApproval/:conceptId', component:ProtocolApprovalComponent,canActivate: [AuthGuard] }, 
    { path: 'negotiation/:conceptId', component:NegotiationComponent,canActivate: [AuthGuard] }, 
    { path: 'document/:conceptId', component:DocumentComponent,canActivate: [AuthGuard] }, 
    { path: 'audit/:conceptId', component:AuditComponent,canActivate: [AuthGuard] },
    { path: 'sponsor/dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
    { path: 'sponsor/diseasearea', component: DiseaseAreaComponent, canActivate: [AuthGuard] },  
    { path: 'sponsor/allirbs', component: SponsorIrbsAllComponent, canActivate: [AuthGuard] }, 
    { path: 'sponsor/irbdetail/:irbId', component: SponsorIrbDetailComponent, canActivate: [AuthGuard] }, 
    { path: 'sponsor/analytics', component: AnalyticsComponent, canActivate:[AuthGuard]},
    { path: 'pi/dashboard', component: PiDashboardComponent, canActivate: [AuthGuard] },
    { path: 'pi/analytics', component: PiAnalyticsComponent, canActivate: [AuthGuard] },//PiIrbComponent
    { path: 'pi/patients/:userName', component: PiPatientsComponent, canActivate: [AuthGuard] },
    { path: 'pi/irb/:userName', component: PiIrbComponent, canActivate: [AuthGuard] },
    { path: 'pi/aggregate', component: PiAggregateComponent, canActivate:[AuthGuard]},
    { path: 'sponsor/aggregate' , component: SponsorAggregateComponent, canActivate:[AuthGuard] },
    { path: 'sponsor/parameterssetup' , component: SponsorParametersSetupComponent, canActivate:[AuthGuard] },  //ParametersSetUpComponent
    { path: 'drugs', component: MaterialManagementComponent, canActivate: [AuthGuard] }, //MaterialManagementViewComponent
    
    { path: 'registerPi', component: RegisterPiComponent, canActivate: [AuthGuard] }, //RegisterLocalPiComponent
    { path: 'registerLocalPi', component: RegisterLocalPiComponent, canActivate: [AuthGuard] }, 
    { path: 'viewStocks/:irbId', component: MaterialManagementViewComponent, canActivate: [AuthGuard] }, 
  {path: 'pi/material', component:MaterialDisposalComponent , canActivate:[AuthGuard]}, //RegisterPiComponent
  {path: 'localpi/dashboard', component:LocalPiDashboardComponent, canActivate:[AuthGuard]}, 
  {path: 'localpi/material', component:MaterialReturnComponent , canActivate:[AuthGuard]},
  {path: 'localpi/irb', component:LocalPiIrbComponent, canActivate:[AuthGuard]},
  { path: 'localpi/patientManagement', component: LocalPiIrbComponent, canActivate: [AuthGuard] },
    { path: 'addtoStocks/:irbId', component: MaterialManagementAddComponent, canActivate: [AuthGuard] }, //
   // sponsor/dashboard/
   
  { path: 'parameters/view', component: ViewParametersComponent, canActivate: [AuthGuard] },
  { path: 'parameters/create', component: CreateParameterComponent, canActivate: [AuthGuard] },
  { path: 'parameters/update/:parameterid', component: UpdateParameterComponent, canActivate: [AuthGuard] },
  { path: 'parameters/audit/:parameterid', component: ParameterAuditComponent, canActivate: [AuthGuard] },

    { path: 'recruitment/:conceptId', component:PatientRecruitmentComponent,canActivate: [AuthGuard] } 
      ])

]; // sets up routes constant where you define your routes

// configures NgModule imports and exports
@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
  providers: []
})
export class InvestigatorInitiatedStudyRoutingModule { }