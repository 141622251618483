import { Component, OnInit } from '@angular/core';
import { SiteSetup, ISiteSetup } from 'src/app/shared/interfaces/siteSetup';
import { Irb, IIrb, IIrbAudits } from 'src/app/shared/interfaces/irb';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators'
import { AuthService } from '../../core/authentication/auth.service';
import { PiAdministrationService } from '../pi-administration.service';
import { IssAdministrationService } from '../../investigator-initiated-study/iss-administration.service';
import { Router, CanActivate, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
//import { ThrowStmt } from '@angular/compiler';
@Component({
  selector: 'app-irb-audit',
  templateUrl: './irb-audit.component.html',
  styleUrls: ['../styles.sass','./irb-audit.component.sass']
})
export class IrbAuditComponent implements OnInit {
  conceptId: number;
  busy:boolean=true;
  irbAuditReady:boolean=false;
  siteReady:boolean=false;
  site: ISiteSetup;
  irbId:number;
  irbAuditList: IIrbAudits[];
  irbAuditCount:number;
  constructor(private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private pIAdministrationService: PiAdministrationService,
    private administrationService: IssAdministrationService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.conceptId = +this.route.snapshot.paramMap.get('conceptId');
    this.irbId = +this.route.snapshot.paramMap.get('irbId');
    debugger;
    this.irbAuditReady=false;
    
    this.administrationService.getIssSite(this.authService.authorizationHeaderValue, this.conceptId)
      .pipe(finalize(() => {
        this.busy = false;
        this.hideSpinner();
      })).subscribe(
        result => {
          this.site = result;
          this.siteReady=true;
          this.getIrbAuditList();
        });
  }
  getIrbAuditList = (): void => {

    console.log(this.conceptId);
    this.spinner.show();
    this.busy = true;
    this.pIAdministrationService.getIrbAudits(this.authService.authorizationHeaderValue, this.irbId)
      .pipe(finalize(() => {
        this.busy = false;
        this.irbAuditReady=true;
        this.hideSpinner();
      })).subscribe(
        result => {
          this.irbAuditList = result;
          this.irbAuditCount=this.irbAuditList.length;
          console.log(this.irbAuditList);
        });
  }
  showSpinner = (): void => {
    this.spinner.show();

  }
  hideSpinner = (): void => {
    if (this.busy ) {
      return;
    }
    this.spinner.hide();
  }
  approvalStatusClass = (aIrb: IIrbAudits): string => {
    if (this.approvalStatus(aIrb)=="Approved"){
        return "clsApproved";
    }
    if (this.approvalStatus(aIrb)=="Pending Revision"){
      return "clsPending";
    }
    if (this.approvalStatus(aIrb)=="Submitted"){
      return "clsSubmitted";
    }
    if (this.approvalStatus(aIrb)=="Requested"){
      return "clsRequested";
    }
    return "clsRequested";
  }
  approvalStatus = (aIrb: IIrbAudits): string => {
    debugger;
    if (aIrb.reviewDate!=undefined &&  aIrb.reviewDate!=null){

    
    if (aIrb.outcome == true) {
      return "Approved";
    }
    if (aIrb.outcome == false) {
      return "Pending Revision";
    }
  }
    if (aIrb.submissionDate != undefined &&  aIrb.submissionDate != null) {
      return "Submitted";
    }
    return "Requested"
  }
}
