import { Component, OnInit } from '@angular/core';

import { Trial } from 'src/app/shared/interfaces/trial';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators'
import { AuthService } from '../../core/authentication/auth.service';
import { AdministrationService } from '../administration.service';
import { Router, CanActivate,ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Component({
  selector: 'app-new-trial',
  templateUrl: './new-trial.component.html',
  styleUrls: ['./new-trial.component.sass']
})
export class NewTrialComponent implements OnInit {
  trial:Trial= new Trial();
  success:boolean=false;
  companyId:number;
  busy:boolean=false;
  error:string;
  constructor(private route: ActivatedRoute,
    private authService: AuthService,
    private router:Router, 
    private administrationService: AdministrationService, private spinner: NgxSpinnerService) { 
      
    this.trial.trialName='';
    this.trial.trialDescription='';
    this.trial.companyId=
    +this.route.snapshot.paramMap.get('companyId');
  }

  ngOnInit() {
    this.companyId = +this.route.snapshot.paramMap.get('companyId');
    console.log(`Company Id=${this.companyId}`)
    console.log("starting .... listing trials"); 
  }
  onSubmit() {
    this.busy=true;
    this.spinner.show();
    this.administrationService.addTrial(this.authService.authorizationHeaderValue,this.trial)
    .pipe(finalize(() => {
      this.spinner.hide();
      this.busy = false;
    })).subscribe(
    result => {         
      
      this.router.navigate([`/companyTrials/${this.companyId}`]);//view list
   },
   error => {
     this.error = error; 
   });
   
   }
}
