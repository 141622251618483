<h2>Principal Investigator Dashboard</h2>
<div> 
    <a  class="btn btn-lg btn-primary  me-4" routerLink="/pi/aggregate/" role="button">Aggregate Analytics
    &raquo; &nbsp;</a>
    <a  class="btn btn-lg btn-primary  me-4" routerLink="/pi/analytics/" role="button">Site Analytics
        &raquo;&nbsp; </a>
        
    <a  class="btn btn-lg btn-primary  me-4" routerLink="/pi/material/" role="button">Material
        &raquo;&nbsp; </a>
        <a  class="btn btn-lg btn-primary me-4" routerLink="/pi/irb/{{userName}}" role="button">IRBs
                &raquo;&nbsp; </a>  
    <a  class="btn btn-lg btn-primary me-4" routerLink="/pi/patients/{{userName}}" role="button">Patients Management
            &raquo;&nbsp; </a>  
            <a  class="btn btn-lg btn-primary me-4" routerLink="/registerLocalPi/" role="button">Register Local PI
                    &raquo;&nbsp; </a> 
            
</div>