import { IStudyType } from './studyTypes';

export class SiteSetup {
    public id: number;
    public description:string;   //AAA Added
    public siteName: string;
    public studyType: string;
    public geoLocation: string;
    public longitude:string;
    public latitude: string;
    public country:string;
    public studyName: string;
    public piName: string;
    public piEmail: string;
    public siteResearchCoordinatorName: string;
    public siteResearchCoordinatorEmail: string;
    public studyTypeId: number;
    public combinationStudies: string;
    public diseaseArea: string;
    public numberOfPatients: number;
    public msl: string;
    public mslEmail: string;
    public conceptSubmissionDate: Date;
    public conceptEvaluationDate: Date;
    public conceptEvaluationStatus: string;
    public conceptEvaluationComment: string;
    
    
    public trialInProgress:boolean;
    public protocolVersion:string;
    //if trial is in progress then these are visible
    //summary of changes to the protocol
    public changeSummary:string;
    public stopTrial:boolean;//trial needs to be stopped until irb is approved
    //notification to authorities required - FDA EMA etc
    public notificationRequired:boolean;
    public trialGoalChanged:boolean;
    public trialDesignChanged:boolean;
    public statisticalAnalysisImpacted:boolean;
    public protocolSubmissionDate: Date;
    public protocolEvaluationDate: Date;
    public protocolEvaluationStatus: string;
    public protocolEvaluationComment: string; 
    public requestedProduct:string;  //AAA Addition
    public productMarketingStatus:string; //AAA Addition
    public pharmacoVigilance:string;
    public pharmacoVigilanceComment:string;
    public setUpCost: number;
    public totalBudget: number;
    public costPerPatient: number;
    public treatmentCostPerPatient:number;
    public publicationCost:number;
    public materialCost: number;
    public equipmentCost: number;
    public siteActivationCost: number;
    public contractEvaluationDate: Date;
    public contractEvaluationStatus: string;
    public contractEvaluationComment: string;
    public piCV: boolean;
    public piCVLink: string;
    public studyProtocolAndBudget: boolean;
    public studyProtocolAndBudgetLink: string;
    public informedConsent: boolean;
    public informedConsentLink: string;
    public irbSubmissionDate: Date;
    public irbApprovalDate: Date;
    public suppliesAndEquipmentDate: Date;
    public siteActivationDate: Date;
    public recruitmentAdvertDate: Date;
    public createdBy: string;
    public dateCreated: Date;
    public modifiedBy: string;
    public dateModified: Date;
    public deleted: boolean;
}
export interface ISiteSetup {
    id: number;
    description: string;   //AAA Added
    siteName: string;
    geoLocation: string;
    longitude:string;
    latitude: string;
    country:string;
    studyName: string;
    piName: string;
    piEmail: string;
    siteResearchCoordinatorName: string;
    siteResearchCoordinatorEmail: string;
    studyType:IStudyType;
    studyTypeId: number;
    combinationStudies: string;
    diseaseArea: string;
    numberOfPatients: number;
    msl: string;
    mslEmail: string;
    conceptSubmissionDate: Date;
    conceptEvaluationDate: Date;
    conceptEvaluationStatus: string;
    conceptEvaluationComment: string;
    protocolSubmissionDate: Date;
    trialInProgress:boolean;
    protocolVersion:string;
    changeSummary:string;
    stopTrial:boolean;//trial needs to be stopped until irb is approved
    //notification to authorities required - FDA EMA etc
    notificationRequired:boolean;
    trialGoalChanged:boolean;
    trialDesignChanged:boolean;
    statisticalAnalysisImpacted:boolean;
    protocolEvaluationDate: Date;
    protocolEvaluationStatus: string;
    protocolEvaluationComment: string;
    requestedProduct:string;  //AAA Addition
    productMarketingStatus:string; //AAA Addition
    pharmacoVigilance:string;
    pharmacoVigilanceComment:string;
    contractEvaluationDate: Date;
    contractEvaluationStatus: string;
    contractEvaluationComment: string;
    setUpCost: number;
    totalBudget: number;
    costPerPatient: number;//screening cost
    treatmentCostPerPatient:number;
    publicationCost:number;
    materialCost: number;
    equipmentCost: number;
    siteActivationCost: number;
    piCV: boolean;
    piCVLink: string;
    formW9: boolean;
    formW9Link: string;
    studyProtocolAndBudget: boolean;
    studyProtocolAndBudgetLink: string;
    informedConsent: boolean;
    informedConsentLink: string;
    irbSubmissionDate: Date;
    irbApprovalDate: Date;
    suppliesAndEquipmentDate: Date;
    siteActivationDate: Date;
    recruitmentAdvertDate: Date;
    createdBy: string;
    dateCreated: Date;
    modifiedBy: string;
    dateModified: Date;
    deleted: boolean;
}