import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { IParameter, Parameter } from 'src/app/shared/interfaces/parameter';
import { IssAdministrationService } from '../iss-administration.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-view-parameters',
  templateUrl: './view-parameters.component.html',
  styleUrls: ['./view-parameters.component.sass']
})

export class ViewParametersComponent {
  busy:boolean=false;
  setupParameter:Parameter=new Parameter();
  allParameters:IParameter[];
  parametersForDisplay: any[] = [];

constructor(private route: ActivatedRoute,
  private authService: AuthService,
  private router: Router,
  private administrationService: IssAdministrationService,
  private spinner: NgxSpinnerService) { }


  ngOnInit(): void {
    this.busy=true;
    this.spinner.show();
    this.administrationService.getAllSetupParameters(this.authService.authorizationHeaderValue)
    .pipe(finalize(() => {
      this.busy = false;
      this.spinner.hide();
  })).subscribe(
    result => {
      console.log(result);
      this.allParameters=result;
     for (let i=0; i<this.allParameters.length; i++){
      if (this.allParameters[i].unitInternal=="d"){
        this.allParameters[i].unitInternal="Days";
      }
      if (this.allParameters[i].unitInternal=="m"){
        this.allParameters[i].unitInternal="Months";
      }
     }
     this.parametersSummaryTable(this.allParameters)
    });

  }
  parametersSummaryTable = (allParameters:any): any =>{  
        
    for (let j = 0; j < this.allParameters.length; j++) {
          const para = this.allParameters[j];
          
  
      let parametersListing ={
                        id: para.id,
                        count: j+1,
                        componentInternal:para.componentInternal,
                        durationInternal: para.durationInternal,
                        unitInternal: para.unitInternal,
                        componentExternal:para.componentExternal,
                        durationExternal: para.durationExternal,
                        unitExternal: para.unitExternal,
                        
                       
                      };
  
          this.parametersForDisplay.push(parametersListing);
    }
 
    return this.parametersForDisplay;
  }
}
