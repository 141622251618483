import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { IssAdministrationService } from '../iss-administration.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { PrincipalInvestigator, IPrincipalInvestigator } from 'src/app/shared/interfaces/principalInvestigator';
import { RegisterUser } from 'src/app/shared/interfaces/registerUser';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-principal-investigator',
  templateUrl: './principal-investigator.component.html',
  styleUrls: ['../styles.sass','./principal-investigator.component.sass']
})
export class PrincipalInvestigatorComponent implements OnInit {
 pi : PrincipalInvestigator=new PrincipalInvestigator();
 piList: IPrincipalInvestigator[];
  busy:boolean=false;
  piListBusy:boolean=false;
  emailChecked:boolean=true;
  isCollapsed:boolean=false;
  errorMessage:string="";
  registerUser:RegisterUser=new RegisterUser();
  constructor(private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private administrationService: IssAdministrationService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.pi=new PrincipalInvestigator();
    this.getPiList();
  }
  getPiList(): void{
    this.piListBusy=true;
    this.showSpinner();
    this.administrationService.listPI(this.authService.authorizationHeaderValue)
    .pipe(finalize(() => {
      this.piListBusy = false;
      this.hideSpinner();
    })).subscribe(
      result => { 
        this.piList=result;
        console.log(result);
      });

  }
  checkEmail():void{
    debugger;
    this.emailChecked=false;
    if (this.piList==undefined || this.piList==null){
      this.errorMessage="";
      this.emailChecked=true;
      return;
    }
    this.emailChecked=true;
    this.piList.forEach(this.checkDuplicate,this)
  }
  checkDuplicate(currentPi : PrincipalInvestigator)
  {
    if (currentPi.piEmail == this.pi.piEmail)
    {
      this.errorMessage="This PI is already in the database";
      this.emailChecked=false;
      console.log(this.errorMessage);
    }
  }
  mouseOverSetUp=():void=>{}
  onSubmit = (): void => {

    this.busy=true;
    
    this.showSpinner();
    this.administrationService.addPI(this.authService.authorizationHeaderValue, this.pi)
      .pipe(finalize(() => {
        this.busy = false;
        this.hideSpinner();
      })).subscribe(
        result => { 
          debugger;
          this.registerPI();
          console.log(result);
          this.pi=new PrincipalInvestigator();
          this.getPiList();
        });
  }
  registerPI=():void=>{
    this.busy=true;
    this.registerUser=new RegisterUser();
    this.registerUser.email=this.pi.piEmail;
    this.registerUser.name=this.pi.piEmail;
    this.registerUser.permissions=3;//pi=> 3
    this.registerUser.password="Le5tetons";
    this.showSpinner();
    this.administrationService.registerPI(this.authService.authorizationHeaderValue, this.registerUser)
      .pipe(finalize(() => {
        this.busy = false;
        this.hideSpinner();
      })).subscribe(
        result => { 
          console.log(result);
          this.pi=new PrincipalInvestigator();
          this.getPiList();
        });
  }
  showSpinner = (): void => {
    this.spinner.show();

  }

  hideSpinner = (): void => {
    if (this.busy||this.piListBusy) {
      return;
    }
    this.spinner.hide();
  }
}
