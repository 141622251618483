<div class="container">

<div class="row" *ngIf="viewList">
  <div *ngIf="!busy" class="col-md-10">
    <a class="btn btn-lg btn-primary" 
    [routerLink]="['/newCompany']"
    role="button" > Add New Company &raquo;</a> 
  </div>
</div>
    <div class="row mb-1">
        <div *ngIf="!busy && viewList" class="col-md-10">
          <h3>List of companies sent from the server!</h3>
            <table class="table small">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Company Name</th>
                    <th scope="col">Address</th>
                    <th scope="col">Created By</th>  
                    <th scope="col">Modified By</th>  
                    <th scope="col">Active</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let company of companies; let i = index" [attr.data-index]="i">
                    <th scope="row">{{i+1}}</th>
                    <td>{{company.companyName}}</td>
                    <td>{{company.companyAddress}}</td> 
                    <td>{{company.createdBy}}</td> 
                    <td>{{company.modifiedBy}}</td> 
                    <td>{{!company.deleted}}  </td> 
                    <td><a class="btn btn-lg btn-primary" 
                      [routerLink]="['/companyTrials' , company.companyId]" role="button">Trials </a></td> 
                      <td><a class="btn btn-lg btn-primary" 
                        [routerLink]="['/areas' , company.companyId]" role="button">Geo&nbsp;Areas </a></td> 
                      <td><a class="btn btn-lg btn-primary" 
                        [routerLink]="['/updateCompany' , company.companyId]"
                        role="button">Modify</a> </td>                 
                  
                    </tr>                 
                </tbody>
              </table>            
        </div>
    </div>
    <ngx-spinner type="pacman">
    <p>Loading...</p></ngx-spinner>  
</div>
