<div class="container">
    <div class="row" >
      <div *ngIf="!busy" class="col-md-10">
        <a class="btn btn-lg btn-primary" 
        [routerLink]="['/newTrialSite' , trialId]" 
        role="button" > Add New Trial Site &raquo;</a> 
      </div>
    </div>
        <div class="row mb-1">
            <div *ngIf="!busy && !trialBusy" class="col-md-10">
              <h3>List of Trial Sites sent from the server for trial: {{trial.trialName}}!</h3>
                <table class="table small">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Area Name</th>
                        <th scope="col">Site Name</th>
                        <th scope="col">Created By</th>  
                        <th scope="col">Modified By</th>  
                        <th scope="col">Active</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let trialSite of trialSites; let i = index" [attr.data-index]="i">
                        <th scope="row">{{i+1}}</th>
                        <td>{{trialSite.area}}</td>
                        <td>{{trialSite.site}}</td> 
                        <td>{{trialSite.createdBy}}</td> 
                        <td>{{trialSite.modifiedBy}}</td> 
                        <td>{{!trialSite.deleted}}  </td> 
                        <td><td><a class="btn btn-lg btn-primary" 
                          [routerLink]="['/subjectss' , trialSite.id]"
                          role="button">Subjects</a> </td>
                        </tr>                 
                    </tbody>
                  </table>            
            </div>
        </div>
        <ngx-spinner type="pacman">
    <p>Loading...</p></ngx-spinner>  
    </div>


