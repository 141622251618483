import { Component, OnInit } from '@angular/core';

import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators'
import { AuthService } from '../../core/authentication/auth.service';
import { PiAdministrationService } from '../pi-administration.service';
import { IssAdministrationService } from '../../investigator-initiated-study/iss-administration.service';
import { Router, CanActivate, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Patient, IPatient } from 'src/app/shared/interfaces/patient';

//import { ThrowStmt } from '@angular/compiler';
@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.sass']
})
export class PatientComponent implements OnInit {
  irbId:number=0;
  id:number;
  isSearchPatient: boolean = false;
  busy: boolean = true;
  biologicalGender:string;
  patient:Patient=new Patient();
  patientList:IPatient[];
  patientListFiltered:IPatient[];
  statusNumber:string="0";
  statusString:string;
  patientListBusy:boolean=true;//busy retrieving patient List
  patientTooltip:string="Click to toggle between Add and Search"
  patientNumber:string="";
  patientAuditCount:number;  // Added by Armel to reverse the listing order 
  patientListFilteredCount:number; // To count the filtering result

  dateOfBirth:Date; //=new  Date('Jan 1, 2021');
  screeningDate:Date;

constructor(private route: ActivatedRoute,
  private authService: AuthService,
  private router: Router,
  private pIAdministrationService: PiAdministrationService,
  private administrationService: IssAdministrationService,
  private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    
    this.irbId = +this.route.snapshot.paramMap.get('irbId');
    this.patient=new Patient();
    this.busy=false;
    this.patient.irbId=this.irbId;
      this.getPatientList(this.irbId);
  }
  getPatientList=(irbId:number):void => {
    this.patientListFilteredCount =0;
    this.patientListBusy=true;
    this.showSpinner();
    this.pIAdministrationService.getAllPatient(this.authService.authorizationHeaderValue, this.irbId)
      .pipe(finalize(() => {
        this.patientListBusy=false;
        this.hideSpinner();
      })).subscribe(
        result => {
          this.patientList=result;
          this.patientAuditCount=this.patientList.length; // counting the number of patients in the list 
          this.patientListFiltered=result;
          this.patientListFilteredCount=this.patientListFiltered.length; //To count the result of filtering
          console.log("All patients");
          console.log(result);
          console.log(this.patientList);
        });

  }

  onSubmit = (): void => {
    if (this.isSearchPatient){
      //call search patient
    }
    else{
      //Add patient
      this.addPatient();
    }
  }
  addPatient = (): void =>{ 
    this.patientListBusy=true;
    this.showSpinner();
    this.pIAdministrationService.addPatient(this.authService.authorizationHeaderValue, this.patient)
      .pipe(finalize(() => {
        this.busy = false;
        this.hideSpinner();
      })).subscribe(
        result => { 
          console.log(result);
          this.getPatientList(this.irbId);
          this.patient=new Patient();
        });
  }
  searchPatient = ():void=>{

  }
  selectPatient = (): void =>{

  }
  showSpinner = (): void => {
    this.spinner.show();

  }
  hideSpinner = (): void => {
    if (this.busy || this.patientListBusy) {
      return;
    }
    this.spinner.hide();
  }
  // Filter implementation
  
        filterStatus= ():void=>{
          var myStatusString=["","Needs Screening", "Waiting For Screening Results",
          "Selected After Screening - Provide Consent Form"," Not selected for Study - Failed Screening",
          "Awaiting consent signature","No consent","Enrolled"]
        let index=Number(this.statusNumber);
        this.statusString=myStatusString[index];
        console.log(`${this.statusString} number= ${index}`);

        if(index==0){
          this.patientListFiltered=this.patientList
          this.patientListFilteredCount=this.patientListFiltered.length; //To count the result of filtering
        }
        else{

          this.patientListFiltered=this.patientList.filter( aPatient=>
          this.patientStatus(aPatient) == this.statusString);
          this.patientListFilteredCount=this.patientListFiltered.length; //To count the result of filtering
        }
        }
        patientStatus = (aPatient: Patient): string => {
          if (aPatient.consentSignedDate!=undefined && aPatient.consentSignedDate!=null){
            if(aPatient.consent!=undefined && aPatient.consent!=null && aPatient.consent){
              return "Enrolled";
            }
            else{
              return "No consent";
            }
          }
          if (aPatient.consentGivenDate!=undefined && aPatient.consentGivenDate!=null){
            return "Awaiting consent signature";
          }
          if (aPatient.screeningResultsDate!=undefined && aPatient.screeningResultsDate!=null){
            if(aPatient.selected!=undefined && aPatient.selected!=null){
              return aPatient.selected?"Selected After Screening - Provide Consent Form":" Not selected for Study - Failed Screening" ;
            }
          }
          if (aPatient.screeningDate!=undefined && aPatient.screeningDate!=null){
            return "Waiting For Screening Results"
          }
          return "Needs Screening";
        }
        nextStepLink=(aPatient:Patient):[string,number]=>{
          if (aPatient.consentSignedDate!=undefined && aPatient.consentSignedDate!=null){
            if(aPatient.consent!=undefined && aPatient.consent!=null && aPatient.consent){
            return ['/treatment', aPatient.id]; //"Consented";
            }
            else{
              return ['/patient', this.irbId]; // "No consent"; cannot go further
            }
          }
          if (aPatient.consentGivenDate!=undefined && aPatient.consentGivenDate!=null){
            return ['/giveordenyconsent', aPatient.id]; // "Need to sign consent";
          }
          if (aPatient.screeningResultsDate!=undefined && aPatient.screeningResultsDate!=null){
            if(aPatient.selected!=undefined && aPatient.selected!=null ){
              if(aPatient.selected){
                return ['/consentform', aPatient.id];
              }
              else
              {
                return ['/patient', this.irbId];  //not selected
              }
              // aPatient.selected?"Selected After Screening - Provide Consent Form":"Not selected";
            }
          }
          if (aPatient.screeningDate!=undefined && aPatient.screeningDate!=null){
            return ['/screeningresult', aPatient.id]; // "Waiting For Screening Results" /  
          }
          return ['/patientscreening', aPatient.id];
        }
        nextStepClass=(aPatient: Patient): string => {
          if (aPatient.consentSignedDate!=undefined && aPatient.consentSignedDate!=null){
            if(aPatient.consent!=undefined && aPatient.consent!=null && aPatient.consent){
              return "consented";
            }
            else{
              return "noConsent";
            }
          }
          if (aPatient.consentGivenDate!=undefined && aPatient.consentGivenDate!=null){
            return "needToSignConsent";
          }
          if (aPatient.screeningResultsDate!=undefined && aPatient.screeningResultsDate!=null){
            if(aPatient.selected!=undefined && aPatient.selected!=null){
              return aPatient.selected?"selectedGiveConsentForm":"notselected";
            }
          }
          if (aPatient.screeningDate!=undefined && aPatient.screeningDate!=null){
            return "waitingForScreeningResults"
          }
          return "needScreening";
        }
        nextStepTitle=(aPatient:Patient):string=>{
          return "";
        }
      }
