<div *ngIf="!busy">
<h2>Parameter Audit</h2>
<div class="row mb-1">

  <div class="col-lg-1">
    <b>Count</b>
    </div>
    
    <div class="col-lg-2">
      <b>component Internal</b>
    </div>
    <div class="col-lg-1">
      <b>Duration</b>
    </div>
    <div class="col-lg-2">
      <b>component External</b>
    </div>
    <div class="col-lg-1">
      <b>Duration</b>
    </div>
    <div class="col-lg-2">
      <b>Modified by</b>
    </div>
    <div class="col-lg-2">
      <b>Date</b>
    </div>
    
</div>

<div class="row mb-1" *ngFor="let aParameter of setupParameterAudit; let i = index">
<div class="col-lg-1">
{{aParameter.count}}
</div>

<div class="col-lg-2">
  {{aParameter.componentInternal}}
</div>
<div class="col-lg-1" >
  {{aParameter.durationInternal}}&nbsp;{{aParameter.unitInternal}}
</div>

<div class="col-lg-2 m-1">
  {{aParameter.componentExternal}}
</div>
<div class="col-lg-1 mb-1">
  {{aParameter.durationExternal}}&nbsp;{{aParameter.unitInternal}}
</div>

<div class="col-lg-2">
    <b>{{aParameter.modifiedBy}}</b>
  </div>
  <div class="col-lg-2">
    <b>{{aParameter.dateModified |date:'d MMMM y h:mm:s'}}</b>
  </div>
</div>

<div>
    <a class="btn btn-lg btn-primary m-4" routerLink="/parameters/view" role="button">View Parameters&raquo;</a>
  
</div>

<ngx-spinner type="pacman">
  <h2>Loading...</h2></ngx-spinner>
</div>
