import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { BaseService } from "../shared/base.service";
import { ConfigService } from '../shared/config.service';
import { Observable, throwError, observable } from 'rxjs';
import { IStudyType } from 'src/app/shared/interfaces/studyTypes';
import { IConceptEvaluation } from 'src/app/shared/interfaces/conceptEvaluation';
import { SiteSetup } from 'src/app/shared/interfaces/siteSetup';
import { ISiteSetup } from 'src/app/shared/interfaces/siteSetup';
import { IAdverseEvents, IAdverseEventAudits } from 'src/app/shared/interfaces/adverseEvent';
import { AdverseEvent } from 'src/app/shared/interfaces/adverseEvent';
import { Irb, IIrb } from 'src/app/shared/interfaces/irb';
import { IIrbAudits } from 'src/app/shared/interfaces/irb';
import { Patient, IPatient } from 'src/app/shared/interfaces/patient';
import { IPatientAudits } from 'src/app/shared/interfaces/patient';
import { IProtocolEvaluation } from '../shared/interfaces/protocolEvaluation';
import { IContractNegociation } from '../shared/interfaces/contractNegociation';
import { Treatment,ITreatment , ITreatmentAudits} from '../shared/interfaces/treatment';
import { IMaterial } from '../shared/interfaces/material';

@Injectable({
  providedIn: 'root'
})
export class PiAdministrationService extends BaseService {
  constructor(private http: HttpClient, private configService: ConfigService) {
    super();
  }
  getPiSites = (token: string): Observable<ISiteSetup[]> => {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<ISiteSetup[]>(this.configService.resourceApiURI + '/issSiteSetups/principalInvestigator', httpOptions).pipe(catchError(this.handleError));

  }

  getIssSite=(token: string, conceptId:number) :Observable<ISiteSetup> =>{   
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })
    };
    return this.http.get<ISiteSetup>(this.configService.resourceApiURI + `/issSiteSetups/${conceptId.toString()}`, httpOptions).pipe(catchError(this.handleError));
  }

  siteSetup(token: string,siteData:SiteSetup ){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })
    };
    debugger;
    return this.http.post(this.configService.resourceApiURI + '/issSiteSetups',siteData, httpOptions).pipe(catchError(this.handleError));
  }
  getAdverseEvents = (token: string, conceptId: number): Observable<IAdverseEvents[]> => {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<IAdverseEvents[]>(this.configService.resourceApiURI + `/adverseEvents/site/${conceptId.toString()}`, httpOptions).pipe(catchError(this.handleError));

  }

  getAdverseEventAudit = (token: string, eventId: number): Observable<IAdverseEventAudits[]> => {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<IAdverseEventAudits[]>(this.configService.resourceApiURI + `/adverseEvents/audit/${eventId.toString()}`, httpOptions).pipe(catchError(this.handleError));

  }
  addAdverseEvent(token: string, adverseEvent: AdverseEvent) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.post(this.configService.resourceApiURI + '/adverseEvents', adverseEvent, httpOptions).pipe(catchError(this.handleError));
  }
  updateAdverseEvent(token: string, adverseEvent: AdverseEvent) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.put(this.configService.resourceApiURI + `/adverseEvents/${adverseEvent.id}`, adverseEvent, httpOptions).pipe(catchError(this.handleError));
  }
  getIrbs = (token: string, conceptId: number): Observable<IIrb[]> => {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<IIrb[]>(this.configService.resourceApiURI + `/irbs/site/${conceptId.toString()}`, httpOptions).pipe(catchError(this.handleError));

  }
  addIrb(token: string, irb: Irb) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.post(this.configService.resourceApiURI + '/irbs', irb, httpOptions).pipe(catchError(this.handleError));
  }
  updateIrb(token: string, irb: Irb) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.put(this.configService.resourceApiURI + `/irbs/${irb.id.toString()}`, irb, httpOptions).pipe(catchError(this.handleError));
  }
  getSelectedIrb = (token: string, irbId: number): Observable<IIrb> => {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<IIrb>(this.configService.resourceApiURI + `/irbs/${irbId.toString()}`, httpOptions).pipe(catchError(this.handleError));
  }
  getIrbAudits = (token: string, irbId: number): Observable<IIrbAudits[]> => {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<IIrbAudits[]>(this.configService.resourceApiURI + `/irbs/audit/${irbId.toString()}`, httpOptions).pipe(catchError(this.handleError));
  }

  addPatient(token: string, patient: Patient) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.post(this.configService.resourceApiURI + '/patients', patient, httpOptions).pipe(catchError(this.handleError));
  }
  updatePatient(token: string, patient: Patient) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.put(this.configService.resourceApiURI + `/patients/${patient.id.toString()}`, patient, httpOptions).pipe(catchError(this.handleError));
  }
  getSelectedPatient = (token: string, patientId: number): Observable<IPatient> => {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<IPatient>(this.configService.resourceApiURI + `/patients/${patientId.toString()}`, httpOptions).pipe(catchError(this.handleError));
  }
  getAllPatient = (token: string, irbId: number): Observable<IPatient[]> => {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<IPatient[]>(this.configService.resourceApiURI + `/patients/irb/${irbId.toString()}`, httpOptions).pipe(catchError(this.handleError));
  }
  getPatients = (token: string): Observable<IPatient[]> => {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<IPatient[]>(this.configService.resourceApiURI + `/patients/all`, httpOptions).pipe(catchError(this.handleError));
  }
  getPatientAudits = (token: string, patientId: number): Observable<IPatientAudits[]> => {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<IPatientAudits[]>(this.configService.resourceApiURI + `/patients/audit/${patientId.toString()}`, httpOptions).pipe(catchError(this.handleError));
  }
  
  addTreatment(token: string, treatment: Treatment) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.post(this.configService.resourceApiURI + '/treatments', treatment, httpOptions).pipe(catchError(this.handleError));
  }
  updateTreatment(token: string, treatment: Treatment) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.put(this.configService.resourceApiURI + `/treatments/${treatment.id.toString()}`, treatment, httpOptions).pipe(catchError(this.handleError));
  }
  getSelectedTreatment = (token: string, treatmentId: number): Observable<ITreatment> => {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<ITreatment>(this.configService.resourceApiURI + `/treatments/${treatmentId.toString()}`, httpOptions).pipe(catchError(this.handleError));
  }
  getPatientTreatments = (token: string, patientId: number): Observable<ITreatment[]> => {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<ITreatment[]>(this.configService.resourceApiURI + `/treatments/patient/${patientId.toString()}`, httpOptions).pipe(catchError(this.handleError));
  }
  getTreatmentAudits = (token: string, treatmentId: number): Observable<ITreatmentAudits[]> => {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<ITreatmentAudits[]>(this.configService.resourceApiURI + `/treatments/audit/${treatmentId.toString()}`, httpOptions).pipe(catchError(this.handleError));
  }
  
  getAllTreatments = (token: string): Observable<ITreatment[]> => {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<ITreatment[]>(this.configService.resourceApiURI + '/treatments/', httpOptions).pipe(catchError(this.handleError));

  }

  getAllMaterialInventory = (token: string, irbId: number): Observable<IMaterial[]> => {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<IMaterial[]>(this.configService.resourceApiURI + `/materials/irb/${irbId.toString()}`, httpOptions).pipe(catchError(this.handleError));
  }
  getPiMaterialInventory = (token: string, piEmail: string): Observable<IMaterial[]> => {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<IMaterial[]>(this.configService.resourceApiURI + `/materials/pi/${piEmail}`, httpOptions).pipe(catchError(this.handleError));
  }
  getLocalPiMaterialInventory = (token: string, localPiEmail: string): Observable<IMaterial[]> => {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<IMaterial[]>(this.configService.resourceApiURI + `/materials/localpi/${localPiEmail}`, httpOptions).pipe(catchError(this.handleError));
  }
}
