import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule }   from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';

import { GoogleMapsModule } from '@angular/google-maps';

import { PrincipalInvestigatorRoutingModule} from './principal-investigator.routing-module';
import { HospitalsComponent } from './hospitals/hospitals.component';
import { ProtocolSubmissionComponent } from './protocol-submission/protocol-submission.component';
import { CvSubmissionComponent } from './cv-submission/cv-submission.component';
import { IrbSubmissionComponent } from './irb-submission/irb-submission.component';
import { IrbApprovalComponent } from './irb-approval/irb-approval.component';
import { PatientRecruitmentComponent } from './patient-recruitment/patient-recruitment.component';
import { PatientTreatmentComponent } from './patient-treatment/patient-treatment.component';
import { AdverseEffectsComponent } from './adverse-effects/adverse-effects.component';
import { PatientEngagementComponent } from './patient-engagement/patient-engagement.component';
import { PatientConsentComponent } from './patient-consent/patient-consent.component';
import { PatientScreeningComponent } from './patient-screening/patient-screening.component';
import { InclusionCriteriaComponent } from './inclusion-criteria/inclusion-criteria.component';
import { PatientWelcomeComponent } from './patient-welcome/patient-welcome.component';
import { MaterialDisposalComponent } from './material-disposal/material-disposal.component';
import { MaterialReturnComponent } from './material-return/material-return.component';
import { IrbAuditComponent } from './irb-audit/irb-audit.component';
import { PatientComponent } from './patient/patient.component';
import { ScreeningResultsComponent } from './screening-results/screening-results.component';
import { ConsentFormComponent } from './consent-form/consent-form.component';
import { ConsentFormSignedComponent } from './consent-form-signed/consent-form-signed.component';
import { TreatmentComponent } from './treatment/treatment.component';
import { PiAnalyticsComponent } from './pi-analytics/pi-analytics.component';
import { PiAdministrationService } from './pi-administration.service';
import { MatInputModule } from  '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideAnimations } from '@angular/platform-browser/animations';

import { MatTableModule } from '@angular/material/table';
import { CdkTableModule} from '@angular/cdk/table';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserModule } from '@angular/platform-browser';

import { MatCardModule } from '@angular/material/card';
import { MatSliderModule} from '@angular/material/slider';
import { HttpClient } from '@angular/common/http';
import { MaterialManagementComponent } from './material-management/material-management.component';



import { CardModule } from 'primeng/card';
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { KnobModule } from 'primeng/knob';
import { SliderModule } from 'primeng/slider';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ButtonModule } from 'primeng/button';
import { InputTextModule} from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { PiAggregateComponent } from './pi-aggregate/pi-aggregate.component';
import { PiPatientsComponent } from './pi-patients/pi-patients.component';
import { LocalPiDashboardComponent } from './local-pi-dashboard/local-pi-dashboard.component';
import { LocalPiReturnMaterialComponent } from './local-pi-return-material/local-pi-return-material.component';
import { ChartModule } from 'primeng/chart';

import { TabViewModule } from 'primeng/tabview';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TooltipModule } from 'primeng/tooltip';
import { InputNumberModule } from 'primeng/inputnumber';
import { HttpClientModule } from '@angular/common/http';
import { PiIrbComponent } from './pi-irb/pi-irb.component';
import { LocalPiIrbComponent } from './local-pi-irb/local-pi-irb.component';




@NgModule({
  declarations: [HospitalsComponent, ProtocolSubmissionComponent, CvSubmissionComponent, IrbSubmissionComponent, 
    IrbApprovalComponent, PatientRecruitmentComponent, PatientTreatmentComponent, AdverseEffectsComponent, 
    PatientEngagementComponent, PatientConsentComponent, PatientScreeningComponent, InclusionCriteriaComponent, 
    PatientWelcomeComponent, MaterialDisposalComponent, MaterialReturnComponent, IrbAuditComponent, 
    PatientComponent, ScreeningResultsComponent, ConsentFormComponent, ConsentFormSignedComponent, 
    TreatmentComponent, PiAnalyticsComponent, MaterialManagementComponent, PiAggregateComponent, PiPatientsComponent, 
    LocalPiDashboardComponent, LocalPiReturnMaterialComponent, PiIrbComponent, LocalPiIrbComponent],
    
    providers: [PiAdministrationService, 
    provideAnimations(),],
  
    imports: [
    CommonModule,
    SharedModule,
    FormsModule, 
    ReactiveFormsModule, 
    NgbModule,
    PrincipalInvestigatorRoutingModule,
    MatTabsModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatFormFieldModule,
    BrowserAnimationsModule,
    BrowserModule,
    MatTableModule,
    CdkTableModule,
    GoogleMapsModule,
    
    MatCardModule,
    MatSliderModule,
    
    CardModule, TableModule, KnobModule, SliderModule,
    FlexLayoutModule, ButtonModule, InputTextModule, DropdownModule,
    ChartModule, TabViewModule, 
    DragDropModule, TooltipModule, MultiSelectModule, HttpClientModule,
    InputNumberModule
    
  ]
})
export class PrincipalInvestigatorModule {  
  file: File;

  constructor(private http: HttpClient) {}
  

  uploadFile() {
    this.http.post('api/upload', this.file)
      .subscribe(
        (response) => {
          console.log('File uploaded successfully');
        },
        (error) => {
          console.log('Error uploading file');
        }
      );
  }
} 
