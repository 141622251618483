import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators'
import { AuthService } from '../../core/authentication/auth.service';
import { AdministrationService } from '../administration.service';
import { Router, CanActivate, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { TrialSite } from 'src/app/shared/interfaces/trialSite';

@Component({
  selector: 'app-new-trial-site',
  templateUrl: './new-trial-site.component.html',
  styleUrls: ['./new-trial-site.component.sass']
})
export class NewTrialSiteComponent implements OnInit {
  trialId: number;
  areas: any;
  sites: any;
  siteId: number;
  areaId:number;
  busy: boolean;
  success: boolean;
  areaBusy: boolean;//retrieving areas
  siteBusy: boolean;//retrieving sites
  trialSite:TrialSite=new TrialSite();
  error:string;
  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private administrationService: AdministrationService, private spinner: NgxSpinnerService) {
  }

  onClick() {
    console.log(`SiteId: ${this.trialSite.siteId}`);
    console.log(`AreaId: ${this.trialSite.areaId}`);
    console.log(this.trialSite);
  }
  ngOnInit() {
    this.trialId = +this.route.snapshot.paramMap.get('trialId');
    //get sites
    this.trialSite.areaId=null;
    this.trialSite.siteId=null;
    this.trialSite.trialId=this.trialId;
    this.trialSite.id=0;
    this.getSites();
    this.getAreas();

  }

  getSites() {
    this.spinner.show();
    this.busy = true;
    this.administrationService.fetchSiteData(this.authService.authorizationHeaderValue)
      .pipe(finalize(() => {

        this.busy = false;
        if (!this.areaBusy) {
          this.spinner.hide();
        }

      })).subscribe(
        result => {
          this.sites = result;
        });
  }
  getAreas() {
    this.spinner.show();
    this.areaBusy = true;
    this.administrationService.fetchTrialAreaData(this.authService.authorizationHeaderValue, this.trialId)
      .pipe(finalize(() => {
        this.areaBusy = false;
        if (!this.busy) {
          this.spinner.hide();
        }
      })).subscribe(
        result => {
          this.areas = result;
          console.log(result);
        });
  }
  onSubmit() {
    console.log('submit');
    this.busy=true;
    this.spinner.show();
    debugger;
    this.administrationService.addTrialSite(this.authService.authorizationHeaderValue,this.trialSite)
    .pipe(finalize(() => {
      this.spinner.hide();
      this.busy = false;
    })).subscribe(
    result => {   
      this.router.navigate([`/trialSites/${this.trialId}`]);//view list
   },
   error => {
     this.error = error; 
   });
  }
}
