<div > 
    <a  class="btn btn-lg btn-primary  me-4" routerLink="/pi/analytics/" role="button">Site Analytics
        &raquo;&nbsp; </a>
        
    <a  class="btn btn-lg btn-primary  me-4" routerLink="/localpi/material" role="button">Material
        &raquo;&nbsp; </a>
        <a  class="btn btn-lg btn-primary me-4" routerLink="/localpi/irb" role="button">IRB
                &raquo;&nbsp; </a>
    <a  class="btn btn-lg btn-primary me-4" routerLink="/localpi/patientManagement/" role="button">Patients Management
            &raquo;&nbsp; </a>
            
</div>
