export class TrialSite{
        public  id:number;
        //trial Name
        public trial:string; 
        public trialId:number;
        // Area Name
        public area:string;
        public areaId:number;
        //Site Name
        public site:string;
        public siteId:number;
        public createdBy:string;
        public dateCreated:Date;
        public modifiedBy: string;
        public dateModified: Date;
        public deleted:boolean;
}
export interface ITrialSite{
     id:number;
    //trial Name
     trial:string; 
    trialId:number;
    // Area Name
    area:string;
    areaId:number;
    //Site Name
    site:string;
    siteId:number;
    createdBy:string;
    dateCreated:Date;
    modifiedBy: string;
    dateModified: Date;
    deleted:boolean;
}