<h2 *ngIf="!busy">Modify Parameter</h2>

<div  class="col-lg-10 text-center" style="background-color: #034289; color: white; height: 50px;"></div>

<div class="col-lg-12 text-center" id='updateParameter' >

    <div class="card" style="width: 83%;">
      <div class="card-body">
        <form *ngIf="!busy" #f="ngForm" (ngSubmit)="onSubmit()">

          <div class="row mb-20">
            <div class="col-lg-1 col-xs-12">
              <div class="form-group">
                <label for="count">Count</label>
              </div>
            </div>

            <div class="col-lg-2 col-xs-12">
              <div class="form-group">
                <input type="number" id="count" name="count"
                  [(ngModel)]="setupParameter.count" class="form-control" placeholder="Position of parameter"
                  min="1" required autoFocus>
              </div>
            </div>


            <div class="col-lg-2 col-xs-12">
              <div class="form-group">
                <label for="componentInternal">Component Internal</label>
              </div>
            </div>

            <div class="col-lg-3 col-xs-12">
              <div class="form-group">
                <input type="text" id="componentInternal" name="componentInternal"
                  [(ngModel)]="setupParameter.componentInternal" class="form-control"
                  placeholder="Component Internal" required>
              </div>
            </div>

            <div class="col-lg-1 col-xs-12">
                <div class="form-group">
                  <label for="durationInternal">Duration</label>
                </div>
              </div>
  
              <div class="col-lg-2 col-xs-12">
                <div class="form-group">
                  <input type="number" id="durationInternal" name="durationInternal"
                    [(ngModel)]="setupParameter.durationInternal" class="form-control" placeholder="Duration"
                    min="0" required >
                </div>
              </div>
          </div>
          <div class="row mb-20">
            <div class="col-lg-1 col-xs-12">
                <div class="form-group">
                  <label for="unitInternal">Unit</label>
                </div>
              </div>
              <div class="col-lg-2 col-xs-12">
                <div class="form-group">
                    <select class="form-control" id="unitInternal" name="unitInternal"
                    [(ngModel)]="setupParameter.unitInternal" placeholder="Select..." required>
                    <option value="d"><b>Days</b></option>
                    <option value="m"><b>Months</b></option> 
                </select>
                </div>
              </div>


            <div class="col-lg-2 col-xs-12">
              <div class="form-group">
                <label for="componentExternal">Component External</label>
              </div>
            </div>

            <div class="col-lg-3 col-xs-12">
              <div class="form-group">
                <input type="text" id="componentExternal" name="componentExternal"
                  [(ngModel)]="setupParameter.componentExternal" class="form-control"
                  placeholder="Component External" required>
              </div>
            </div>

            <div class="col-lg-1 col-xs-12">
                <div class="form-group">
                  <label for="durationExternal">Duration</label>
                </div>
              </div>
  
              <div class="col-lg-2 col-xs-12">
                <div class="form-group">
                  <input type="number" id="durationExternal" name="durationExternal"
                    [(ngModel)]="setupParameter.durationExternal" class="form-control" placeholder="Duration Fexternal"
                    min="0" required >
                </div>
              </div>


          </div>

          <div  class="col-lg-12 text-center" style="background-color: white; color: white; height: 50px;"></div>

          <div class="row mb-1">
            <div class="col-lg-1 col-xs-12">
            </div>
            <div class="col-lg-2 col-xs-12">
              <div class="form-group">
                <a class="btn btn-lg btn-primary btn-block" routerLink="/parameters/view" role="button">Cancel</a>
              </div>
            </div>
            <div class="col-lg-4 col-xs-12">
            </div>
            <div class="col-lg-5 col-xs-12">
              <div class="form-group">
                <button class="btn btn-lg btn-primary btn-block" type="submit" [disabled]="!f.form.valid">
                  {{submitText}}
                </button>
              </div>
            </div>
          </div>
          <div *ngIf="error" class="alert alert-danger mt-3" role="alert">
            <strong>Oops!</strong> {{error}}
          </div>
        </form>
      </div>
    </div>
    <ngx-spinner type="pacman">
      <h2>Loading...</h2></ngx-spinner>
  </div>
