<div>
    <div class="row mb-1 justify-content-center">

        <div class="col-lg-12 negotiation" align="center">
            <h3>Contract Negociation</h3>
        </div>
        
        <div class="col-lg-12 text-center" *ngIf="!historyOnly">

            <form *ngIf="!busy" #f="ngForm" (ngSubmit)="onSubmit()">

                <div class="row mb-1">
                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <label for="siteName">Institution Name</label>
                        </div>
                    </div>

                    <div class="col-lg-4 col-xs-12">
                        <div class="form-group">
                            <input type="text" id="siteName" name="siteName" [(ngModel)]="site.siteName"
                                class="form-control" readonly>
                        </div>
                    </div>

                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <label for="studyName">Study Name</label>
                        </div>
                    </div>

                    <div class="col-lg-4 col-xs-12">
                        <div class="form-group">

                            <input type="text" id="studyName" name="studyName" [(ngModel)]="site.studyName"
                                class="form-control" readonly>
                        </div>
                    </div>
                </div>

                    <div class="row mb-1">
                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <label for="geoLocation">Address</label>
                        </div>
                    </div>

                    <div class="col-lg-10 col-xs-12">
                        <div class="form-group">
                            <input type="text" id="geoLocation" name="geoLocation" [(ngModel)]="site.geoLocation"
                                class="form-control" readonly>
                        </div>
                    </div>
                    </div>
                <!--</div>-->
                <!-- -->
                <div class="alert alert-primary" *ngIf="isCollapsed" (click)="isCollapsed = !isCollapsed"
                    aria-controls="siteInfo">Click here for more info on this site

                </div>
                <div class="alert alert-secondary" *ngIf="!isCollapsed" (click)="isCollapsed = !isCollapsed"
                    aria-controls="siteInfo">Click here for less info on this site

                </div>
                <div class="col-lg-12 text-center collapse" id='siteInfo' [ngbCollapse]="isCollapsed">

                    <div class="card">
                        <div class="card-body">
                            <!---->
                            <div class="row mb-1">
                                <div class="col-lg-2 col-xs-12">
                                    <div class="form-group">
                                        <label for="piName">PI </label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xs-12">
                                    <div class="form-group">
                                        <input type="text" id="piName" name="piName" [(ngModel)]="site.piName"
                                            class="form-control" placeholder="Name" readonly>
                                    </div>
                                </div>

                                <div class="col-lg-2 col-xs-12">
                                    <div class="form-group">
                                        <label for="piEmail"> </label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xs-12">
                                    <div class="form-group">
                                        <input type="email" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                            id="piEmail" name="piEmail" [(ngModel)]="site.piEmail" class="form-control"
                                            placeholder="Email" readonly>
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-1">
                                <div class="col-lg-2 col-xs-12">
                                    <div class="form-group">
                                        <label for="siteResearchCoordinatorName">Site Ressearch Coordinator</label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xs-12">
                                    <div class="form-group">
                                        <input type="text" id="siteResearchCoordinatorName"
                                            name="siteResearchCoordinatorName"
                                            [(ngModel)]="site.siteResearchCoordinatorName" class="form-control"
                                            placeholder="Name" readonly>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-xs-12">
                                    <div class="form-group">
                                        <label for="piEmail"></label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xs-12">
                                    <div class="form-group">
                                        <input type="email" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                            id="siteResearchCoordinatorEmail" name="siteResearchCoordinatorEmail"
                                            [(ngModel)]="site.siteResearchCoordinatorEmail" class="form-control"
                                            placeholder="Email" readonly>
                                    </div>
                                </div>

                            </div>


                            <div class="row mb-1">
                                <div class="col-lg-2 col-xs-12">
                                    <div class="form-group">
                                        <label for="msl">MSL</label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xs-12">
                                    <div class="form-group">
                                        <input type="text" id="msl" name="msl" [(ngModel)]="site.msl"
                                            class="form-control" placeholder="Name" readonly>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-xs-12">
                                    <div class="form-group">
                                        <label for="mslEmail"></label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xs-12">
                                    <div class="form-group">
                                        <input type="email" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                            id="mslEmail" name="mslEmail" [(ngModel)]="site.mslEmail"
                                            class="form-control" placeholder="Email" readonly>
                                    </div>
                                </div>

                            </div>

                            <!---->
                            <div class="row mb-1">

                                <div class="col-lg-2 col-xs-12">

                                    <div class="form-group">
                                        <label for="diseaseArea">Disease Area</label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xs-12">

                                    <div class="form-group">
                                        <input type="text" id="diseaseArea" name="diseaseArea"
                                            [(ngModel)]="site.diseaseArea" class="form-control" readonly>
                                    </div>
                                </div>

                                <div class="col-lg-2 col-xs-12">

                                    <div class="form-group">
                                        <label for="numberOfPatients">Number of Patients (N)</label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xs-12">

                                    <div class="form-group">
                                        <input type="text" id="numberOfPatients" name="numberOfPatients"
                                            [(ngModel)]="site.numberOfPatients" class="form-control" readonly>
                                    </div>
                                </div>  

                            </div>

                            <!--New Addition-->
                            <div class="row mb-1">

                                <div class="col-lg-2 col-xs-12">

                                    <div class="form-group">
                                        <label for="requestedProduct">Requested Product</label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xs-12">

                                    <div class="form-group">
                                        <input type="text" id="requestedProduct" name="requestedProduct"
                                            [(ngModel)]="site.requestedProduct" class="form-control" readonly>
                                    </div>
                                </div>

                                <div class="col-lg-2 col-xs-12">

                                    <div class="form-group">
                                        <label for="productMarketingStatus">Product Marketing Status</label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xs-12">

                                    <div class="form-group">
                                        <input type="text" id="productMarketingStatus" name="productMarketingStatus"
                                            [(ngModel)]="site.productMarketingStatus" class="form-control" readonly>
                                    </div>
                                </div>

                            </div>
 <!--End New Addition-->
                            <div class="row mb-1">


                                <div class="col-lg-2 col-xs-12">
                                    <div class="form-group">
                                        <label for="protocol">Concept Submited on</label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xs-12">
                                    <div class="form-group">
                                        {{site.conceptSubmissionDate|date:'d MMMM y'}}
                                    </div>
                                </div>

                            </div>

                            <div class="row mb-1">

                                <div class="col-lg-2 col-xs-12">
                                    <div class="form-group">
                                        <label for="concept">Concept Evaluated on</label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xs-12">
                                    <div class="form-group">
                                        {{site.conceptEvaluationDate|date:'d MMMM y'}}
                                    </div>
                                </div>
                                <div class="col-lg-2 col-xs-12">

                                    <div class="form-group">
                                        <label for="conceptEvaluationStatus">Status</label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xs-12">

                                    <div class="form-group">
                                        <!-- -->
                                        <input type="text" class="form-control" id="conceptEvaluationStatus"
                                            name="conceptEvaluationStatus" [(ngModel)]="site.conceptEvaluationStatus"
                                            readonly>
                                    </div>
                                </div>


                            </div>
                            <div class="row mb-1">

                                <div class="col-lg-2 col-xs-12">

                                    <div class="form-group">
                                        <label for="conceptEvaluationComment">Evaluation Comment</label>
                                    </div>
                                </div>
                                <div class="col-lg-10 col-xs-12">

                                    <div class="form-group">
                                        <input type="text" id="conceptEvaluationComment" name="conceptEvaluationComment"
                                            [(ngModel)]="site.conceptEvaluationComment" class="form-control"
                                            placeholder="Evaluation Comment" readonly>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-1">
                                <div class="col-lg-2 col-xs-12">
                                    <div class="form-group">
                                        <label for="concept">Protocol Submited on</label>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-xs-12">
                                    <div class="form-group">
                                        {{site.protocolSubmissionDate|date:'d MMMM y'}}
                                    </div>
                                </div>
                                <div class="col-lg-2 col-xs-12">
                                    <div class="form-group">
                                        <label for="concept">Protocol file link</label>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-xs-12">
                                    <div class="form-group alert alert-primary">
                                        link to approved protocol here
                                    </div>
                                </div>
                            </div>



                            <div class="row mb-1">
                                <div class="col-lg-2 col-xs-12">
                                    <div class="form-group">
                                        <label for="concept">Protocol Evaluated on </label>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-xs-12">
                                    {{site.protocolEvaluationDate|date:'d MMMM y'}}

                                </div>

                                <div class="col-lg-2 col-xs-12">

                                    <div class="form-group">
                                        <label for="protocolEvaluationStatus">Status</label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xs-12">

                                    <div class="form-group">
                                        <input type="text" class="form-control" id="protocolEvaluationStatus"
                                            name="protocolEvaluationStatus" [(ngModel)]="site.protocolEvaluationStatus"
                                            readonly>

                                    </div>
                                </div>

                              

                            </div>
                            <div class="row mb-1">

                                <div class="col-lg-2 col-xs-12">

                                    <div class="form-group">
                                        <label for="protocolEvaluationComment">Evaluation Comment</label>
                                    </div>
                                </div>
                                <div class="col-lg-10 col-xs-12">

                                    <div class="form-group">
                                        <input type="text" id="protocolEvaluationComment"
                                            name="protocolEvaluationComment"
                                            [(ngModel)]="site.protocolEvaluationComment" class="form-control"
                                            placeholder="Protocol Evaluation Comment" readonly>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>





                <div class="row mb-1">
                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <label for="setUpCost">Set Up Costs</label>
                        </div>
                    </div>

                    <div class="col-lg-4 col-xs-12">
                        <div class="form-group">
                            <input type="number" id="setUpCost" name="setUpCost" [(ngModel)]="site.setUpCost"
                                placeholder="Set Up Costs" class="form-control" required autofocus>
                        </div>
                    </div>
                    
  
                    <div class="col-lg-2 col-xs-12">

                        <div class="form-group">
                            <label for="totalBudget">Total Budget</label>
                        </div>
                    </div>

                    <div class="col-lg-4 col-xs-12">

                        <div class="form-group">
                            <input type="number" id="totalBudget" name="totalBudget" [(ngModel)]="site.totalBudget"
                                placeholder="Total Budget" class="form-control" required>
                        </div>
                    </div>
                </div>

                <div class="row mb-1">
                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <label for="sietActivationCost">Site Activation Cost</label>
                        </div>
                    </div>

                    <div class="col-lg-4 col-xs-12">
                        <div class="form-group">
                            <input type="number" id="siteActivationCost" name="siteActivationCost" [(ngModel)]="site.siteActivationCost"
                                placeholder="Site Activation Cost" class="form-control" required >
                        </div>
                    </div>
                    
  
                    <div class="col-lg-2 col-xs-12">

                        <div class="form-group">
                            <label for="equipmentCost">Equipment Cost</label>
                        </div>
                    </div>

                    <div class="col-lg-4 col-xs-12">

                        <div class="form-group">
                            <input type="number" id="equipmentCost" name="equipmentCost" [(ngModel)]="site.equipmentCost"
                                placeholder="Equipment Cost" class="form-control" required>
                        </div>
                    </div>
                </div>

                <div class="row mb-1">
                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <label for="costPerPatient">Screening Cost per Patient</label>
                        </div>
                    </div>

                    <div class="col-lg-4 col-xs-12">
                        <div class="form-group">
                            <input type="number" id="costPerPatient" name="costPerPatient"
                                placeholder="Cost Per Patient" [(ngModel)]="site.costPerPatient" class="form-control"
                                required>
                        </div>
                    </div>
                    <!--  </div>
  SCREENING COST TREATMENT COST
            <div class="row mb-1"> -->
                    <div class="col-lg-2 col-xs-12">

                        <div class="form-group">
                            <label for="materialCost">Material Cost</label>
                        </div>
                    </div>

                    <div class="col-lg-4 col-xs-12">

                        <div class="form-group">
                            <input type="number" id="materialCost" name="materialCost" [(ngModel)]="site.materialCost"
                                class="form-control" placeholder="Material Cost" required>
                        </div>
                    </div>
                </div>

                <div class="row mb-1">  
                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <label for="treatmentCostPerPatient">Treatment Cost per Patient</label>
                        </div>
                    </div>
                    <div class="col-lg-4 col-xs-12">
                        <div class="form-group">
                            <input type="number" id="treatmentCostPerPatient" name="treatmentCostPerPatient"
                                placeholder="Cost Per Patient" [(ngModel)]="site.treatmentCostPerPatient"
                                class="form-control" required>
                        </div>
                    </div>
                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <label for="publicationCost">Publication Cost</label>
                        </div>
                    </div>

                    <div class="col-lg-4 col-xs-12">
                        <div class="form-group">
                            <input type="number" id="publicationCost" name="publicationCost"
                                placeholder="Publication Cost" [(ngModel)]="site.publicationCost"
                                class="form-control" required>
                        </div>
                    </div>
                </div>
                
               
                <div class="row mb-1" *ngIf="LastEvaluationDate!=null">
                    <div class="col-lg-12 col-xs-12 fontRed">
                        <p><b>This is a re-evaluation</b>, this contract has been previously evaluated on &nbsp;
                            <b>{{LastEvaluationDate|date:'d MMMM y'}}</b>&nbsp; and the review team decision was
                            &nbsp;<b>{{LastStatus}}</b>
                        </p>
                    </div>
                </div>
                <div class="row mb-1">
                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <label for="contractEvaluationDate">Contract Evaluation Date</label>
                        </div>
                    </div>
                    <div class="col-lg-4 col-xs-12">
                        <div class="form-group">
                            <input type="date" id="contractEvaluationDate" name="contractEvaluationDate"
                                [(ngModel)]="site.contractEvaluationDate" class="form-control"
                                (change)="evaluationDate()" placeholder="Contract Evaluation Date" required>
                        </div>
                    </div>

                    <div class="col-lg-2 col-xs-12">

                        <div class="form-group">
                            <label for="contractEvaluationStatus">Status</label>
                        </div>
                    </div>

                    <div class="col-lg-4 col-xs-12">

                        <div class="form-group">
                            <!-- -->
                            <select class="form-control" id="contractEvaluationStatus" name="contractEvaluationStatus"
                                placeholder="Status" [(ngModel)]="site.contractEvaluationStatus" required>

                                <option value="Approved">Approved</option>
                                <option value="Conditional approval pending revisions">Pending Revision</option>
                            </select>
                        </div>
                    </div>

                    <div *ngIf="!validEvaluationDate && evaluationDateErrorMessage.length>5"
                        class="col-lg-12 alert alert-danger">
                        {{evaluationDateErrorMessage}}
                    </div>

                </div>
                <div class="row mb-1">

                    <div class="col-lg-2 col-xs-12">

                        <div class="form-group">
                            <label for="contractEvaluationComment">Evaluation Comment</label>
                        </div>
                    </div>
                    <div class="col-lg-10 col-xs-12">

                        <div class="form-group">
                            <input type="text" id="contractEvaluationComment" name="contractEvaluationComment"
                                [(ngModel)]="site.contractEvaluationComment" class="form-control"
                                placeholder="Contract Evaluation Comment" required>
                        </div>
                    </div>
                </div>
                <div class="row mb-1">
                    <div class="col-lg-2 col-xs-12">
                    </div>
                    <div class="col-lg-4 col-xs-12">
                        <div class="form-group">
                            <a class="btn btn-lg btn-primary btn-block" [routerLink]="['/siteSetup']"
                                role="button">Cancel</a>
                        </div>
                    </div>
                    <div class="col-lg-2 col-xs-12">
                    </div>
                    <div class="col-lg-4 col-xs-12">
                        <div class="form-group">
                            <button class="btn btn-lg btn-primary btn-block" type="submit"
                                [disabled]="!f.form.valid || !validEvaluationDate">
                                Update
                            </button>
                        </div>
                    </div>
                </div>

                <div *ngIf="error" class="alert alert-danger mt-3" role="alert">
                    <strong>Oops!</strong> {{error}}
                </div>
            </form>

        </div>
    </div>

    <ngx-spinner type="pacman">
    <p>Loading...</p></ngx-spinner>
</div>