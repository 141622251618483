import { Component, OnInit } from '@angular/core';

import {SiteSetup, ISiteSetup} from 'src/app/shared/interfaces/siteSetup';
import { IStudyType } from 'src/app/shared/interfaces/studyTypes';
import {IProtocolEvaluation} from 'src/app/shared/interfaces/protocolEvaluation';
import { IProtocolSubmission } from 'src/app/shared/interfaces/protocolSubmission';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators'
import { AuthService } from '../../core/authentication/auth.service';
import { IssAdministrationService } from '../iss-administration.service';
import { Router, CanActivate, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-protocol-approval',
  templateUrl: './protocol-approval.component.html',
  styleUrls: ['../styles.sass','./protocol-approval.component.sass']
})
export class ProtocolApprovalComponent implements OnInit {

  conceptId:number;
  site:ISiteSetup ;
  pipe:DatePipe;
  isCollapsed:boolean=true;
  validEvaluationDate:boolean=false;
  evaluationDateErrorMessage:string='';
  protocolEvaluations:IProtocolEvaluation[];
  error:boolean=false;
  busy:boolean=false;
  auditBusy:boolean=false;
  historyOnly:boolean=false;
  trialStarted:boolean=false;
  LasttrialStartedChecked:boolean;
  LastEvaluationDate:Date;
  LastStatus:string;
  requestedProduct:string;
  productinUse:string;
  productMarketingStatus:string;
  currentMarketingStatus:string;
  
  constructor(private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private administrationService: IssAdministrationService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    
    this.conceptId = +this.route.snapshot.paramMap.get('conceptId');
    console.log(this.conceptId);
    debugger;
   if(this.route.snapshot.url[0].path=='conceptEvaluationAudit')
   {
     this.historyOnly=true;
   }
    console.log(`url= ${this.route.snapshot.url}`);
    // get concept
    //get site for this concept
    // this.getConceptEvaluationAudit();
    this.spinner.show();
    this.busy = true;
    this.administrationService.getIssSite(this.authService.authorizationHeaderValue, this.conceptId)
      .pipe(finalize(() => {
        this.busy = false;
        this.hideSpinner();
      })).subscribe(
        result => {
          this.site = result;
          debugger;
          this.LastEvaluationDate=this.site.protocolEvaluationDate;
          this.LastStatus=this.site.protocolEvaluationStatus;
          this.trialStarted=this.site.trialInProgress;  // AAA addition
          this.productinUse=this.site.requestedProduct; // AAA addition
          this.currentMarketingStatus=this.site.productMarketingStatus;  // AAA addition
          console.log(this.site);
        });
  
  }

  hideSpinner=():void =>{
    if(this.busy || this.auditBusy)
    {
      return; 
    }
    this.spinner.hide();
  }

  evaluationDate= ():void =>{
    this.pipe = new DatePipe('en-US');
    console.log(`Evaluation Date ${this.site.protocolEvaluationDate} Submission Date:${this.site.protocolSubmissionDate}`);
    if (this.site.protocolEvaluationDate< this.site.protocolSubmissionDate)
    {
      this.validEvaluationDate=false;
      this.evaluationDateErrorMessage=`Invalid Evaluation Date. Must be after Submission Date which is ${ this.pipe.transform(this.site.protocolSubmissionDate, 'd MMMM y')}`;
    }
    else{
      this.validEvaluationDate=true;
      this.evaluationDateErrorMessage='';
    }
  }

  onSubmit= ():void=>{
    debugger;
    
    this.administrationService.evaluateProtocol(this.authService.authorizationHeaderValue, this.site)
    .pipe(finalize(() => {
        this.busy = false;
        this.hideSpinner();
    })).subscribe(
      result => {
        console.log(result);
        this.router.navigate(['/siteSetup']);
      });
} 
}
