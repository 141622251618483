import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators'
import { AuthService } from '../../core/authentication/auth.service';
import { AdministrationService } from '../administration.service';
import { ICompany } from 'src/app/shared/interfaces/company';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Component({
  selector: 'app-site',
  templateUrl: './site.component.html',
  styleUrls: ['./site.component.sass']
})
export class SiteComponent implements OnInit {
  sites:any;
  busy: boolean;
  constructor(private authService: AuthService,private router:Router, private administrationService: AdministrationService, private spinner: NgxSpinnerService) {
  }
  ngOnInit() { 
    console.log("starting .... listing sites"); 
    this.busy = true;
    this.spinner.show();
    this.administrationService.fetchSiteData(this.authService.authorizationHeaderValue)
    .pipe(finalize(() => {
      this.spinner.hide();
      this.busy = false;
    })).subscribe(
    result => {         
      this.sites = result;
   });
  }

}
