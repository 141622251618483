<div class="row mb-1 justify-content-center "  *ngIf="!busy">
    <div class="col-lg-12 concept" align="center">
      <div>
        <h3>Material Management - Disposal local PI  {{userName}}</h3>
      </div>
    </div>
    
<div class="row mb-1" *ngIf="!busy" >
    <div class="col-lg-3 col-xs-12" style="display: flex; justify-content: center; align-items: center;">
       <b>Batch Number</b>
    </div>
    <div class="col-lg-2 col-xs-12" style="display: flex; justify-content: center; align-items: center;">
       <b>Expiry Date</b>
    </div>
    
    <div class="col-lg-2 col-xs-12" style="display: flex; justify-content: center; align-items: center;">
       <b>Original Inventory</b>
    </div>
    <div class="col-lg-2 col-xs-12" style="display: flex; justify-content: center; align-items: center;">
        <b>Dispatch Date</b>
     </div>
  
</div>  

<div class="row mb-1" *ngFor="let aMaterial of allMaterials" >
    <div class="col-lg-3 col-xs-12" style="display: flex; justify-content: center; align-items: center;">
        {{aMaterial.batchNumber}}
    </div>
    <div class="col-lg-2 col-xs-12" style="display: flex; justify-content: center; align-items: center;">
        {{aMaterial.expiryDate|date:'d MMMM y'}}
    </div>
    
    <div class="col-lg-2 col-xs-12" style="display: flex; justify-content: center; align-items: center;">
        {{aMaterial.numberOfItems}}
    </div>
    <div class="col-lg-2 col-xs-12" style="display: flex; justify-content: center; align-items: center;">
        {{aMaterial.dateCreated|date:'d MMMM y'}}
    </div>
    <div class="col-lg-2 col-xs-12">
        <a  class="btn btn-lg btn-primary  me-4" routerLink="/localpi/return/{{aMaterial.id}}" role="button">Return 
            &raquo;&nbsp; </a>
    </div>
</div>  

<ngx-spinner type="pacman">
    <h2>Loading...</h2></ngx-spinner>
</div>
