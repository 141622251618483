import { Component, OnInit } from '@angular/core';
import { Area } from 'src/app/shared/interfaces/area';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators'
import { AuthService } from '../../core/authentication/auth.service';
import { AdministrationService } from '../administration.service';
import { Router, CanActivate,ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Component({
  selector: 'app-new-area',
  templateUrl: './new-area.component.html',
  styleUrls: ['./new-area.component.sass']
})
export class NewAreaComponent implements OnInit {
  area:Area= new Area();
  success:boolean=false;
  companyId:number;
  busy:boolean=false;
  error:string;
  constructor(private route: ActivatedRoute,
    private authService: AuthService,
    private router:Router, 
    private administrationService: AdministrationService, private spinner: NgxSpinnerService) { 
      
    this.area.areaName='';
    this.area.areaDescription='';
    this.area.companyId=
    +this.route.snapshot.paramMap.get('companyId');}

  ngOnInit() {
    this.companyId = +this.route.snapshot.paramMap.get('companyId');
    console.log(`Company Id=${this.companyId}`)
    console.log("starting .... listing trials"); 
  }
  onSubmit() {
    this.busy=true;
    this.spinner.show();
    this.administrationService.addArea(this.authService.authorizationHeaderValue,this.area)
    .pipe(finalize(() => {
      this.spinner.hide();
      this.busy = false;
    })).subscribe(
    result => {         
      
      this.router.navigate([`/areas/${this.companyId}`]);//view list
   },
   error => {
     this.error = error; 
   });
   
   }

}
