
<router-outlet></router-outlet>
<!--<mat-tab></mat-tab>
<mat-tab-group></mat-tab-group>-->
<!--<p>
    Implementation of mat-tab-group for 
    center style and for default theme
 </p>
   
 <mat-tab-group mat-align-tabs="center">
   <mat-tab label="Home">Home Content</mat-tab>
   <mat-tab label="Overview">Overview Content</mat-tab>
 </mat-tab-group>
 <br>
   
   
 <p>
   Implementation of mat-tab-group for center 
   style and for primary theme
 </p>
   
 <mat-tab-group mat-align-tabs="center" backgroundColor="primary">
   <mat-tab label="Home">Home Content</mat-tab>
   <mat-tab label="Overview">Overview Content</mat-tab>
 </mat-tab-group>
 <br>
   
   
 <p>
   Implementation of mat-tab-group for center 
   style and for accent theme
 </p>
   
 <mat-tab-group mat-align-tabs="center" backgroundColor="accent">
   <mat-tab label="Home">Home Content</mat-tab>
   <mat-tab label="Overview">Overview Content</mat-tab>
 </mat-tab-group>-->