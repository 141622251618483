import { Component } from '@angular/core';

@Component({
  selector: 'app-local-pi-dashboard',
  templateUrl: './local-pi-dashboard.component.html',
  styleUrls: ['./local-pi-dashboard.component.sass']
})
export class LocalPiDashboardComponent {

}
