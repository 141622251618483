<div class="row justify-content-center">
    <div class="col-md-8 text-center">
      <form *ngIf="!success" #f="ngForm" (ngSubmit)="onSubmit()">
        <!-- <img class="mb-4" src="assets/images/angular_solidBlack.svg" alt="" width="125" height="125"> -->
        <h1 class="h3 mb-3 font-weight-normal">New Geo&nbsp;Area</h1>
        <div class="form-group">
            <label for="areaName" class="sr-only">Area Name</label>
            <input type="text" id="areaName" name="areaName" 
            [(ngModel)]="area.areaName" class="form-control" placeholder="area Name" required autoFocus>
        </div>
        <div class="form-group">
            <label for="areaDescription" class="sr-only">Area Description</label>
            <input type="text" id="areaDescription" name="areaDescription"  
            [(ngModel)]="area.areaDescription" class="form-control" placeholder="area Description" required>
        </div>
        
        <a class="btn btn-lg btn-primary btn-block"
        [routerLink]="['/areas' , companyId]"
        role="button" >Cancel</a>
        <button class="btn btn-lg btn-primary btn-block" type="submit" 
        [disabled]="!f.form.valid">Create</button>
        <ngx-spinner type="pacman">
    <p>Loading...</p></ngx-spinner>   
        <div *ngIf="error" class="alert alert-danger mt-3" role="alert">
            <strong>Oops!</strong> {{error}}
        </div>     
      </form>      
      <div *ngIf="success" class="alert alert-success" role="alert">
          <h4 class="alert-heading">Well done!</h4>
      </div>  
    </div> 
  </div>

