import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-disease-area',
  templateUrl: './disease-area.component.html',
  styleUrls: ['./disease-area.component.sass']
})
export class DiseaseAreaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
