<!--<p>pi-analytics works!</p>-->


<div class="row mb-1">
    <div class="col-lg-2 col-sx-12">
      
        <div class="form-group">
            <label for="EvaluationStatus"><b>Filter by Study Status</b></label>
            
        </div>
    </div>
  
    
              <div class="col-lg-2 col-xs-12">
  
                  <div class="form-group">
                      
                      <select class="form-control" id="EvaluationStatus" name="EvaluationStatus"
                      [(ngModel)]="statusNumber"  (change)= "filterSites()" title="Select Study Status & CLICK on the Search Button">
                          <option value="0">All Studies</option>
                          <option value="1">Concept Evaluation</option>
                          <option value="2">Concept Re-evaluation</option>
                          <option value="3">Rejected (Concept)</option>
                          <option value="4">Protocol Submission</option>
                          <option value="5">Protocol Evaluation</option>
                          <option value="6">Protocol Re-evaluation</option>
                          <option value="7">Rejected (Protocol)</option>
                          <option value="8">Contract Negotiation</option>
                          <option value="9">Documents Check</option>
                          <option value="10">All IRBs</option>
                         
                     
                      </select>
                  
                  </div>
  
              </div>
  
  <!-- /////End Filter by Study status-->  
  
 <div class="col-lg-2 col-xs-12">
    <div class="form-group">
        <label for="countryName"><b>Country Name</b></label>
    </div>
  </div>
  
  <div class="col-lg-2 col-xs-12">
    <div class="form-group">
  
      <input type="text" id="enterCountryName" name="enterCountryName" placeholder="Enter COUNTRY and Click" [(ngModel)]="enterCountryName"
      class="form-control" required>
</div>
</div>

<div class="col-lg-2 col-xs-12">
<div class="form-group">
  <button (click)="filterByCountry(enterCountryName)" class="btn btn-primary">
    Search - Sites/Country
  </button>
  
    </div>
  </div>
   </div>

    <!-----------------------------------Filter by IRBs Evaluation Status------------------------> 

  <div class="row mb-1">
    <div class="col-lg-2 col-sx-12">
      
        <div class="form-group">
            <label for="EvaluationStatus"><b>Filter by IRBs Status</b></label>
            
        </div>
    </div>
  
  
    <div class="col-lg-2 col-xs-12">

        <div class="form-group">
      
            <select class="form-control" id="IrbEvaluationByStatus" name="IrbEvaluationByStatus"
              [(ngModel)]="IRBstatusNumber"  (change)= "filterIRBs()" title="Select IRB Status & CLICK on the Search Button">
          
               <option value="0">IRB - All</option>
               <option value="1">IRB - Requested</option>
               <option value="2">IRB - Submitted</option>
               <option value="3">IRB - Pending Revision</option>
               <option value="4">IRB - Approved</option>
                                
            </select>

        </div>

    </div>

<!-- /////End Filter by IRBs status-->  
              
        <!--/// Begining of # of Sites Display-->
 <!--<div class="row mb-1 justify-content-center"> -->
      <div class="col-lg-2 col-xs-12">

          <div class="form-group">
              <label for="siteSetupsCount"><b># of Sites (Filtered/Total)</b></label>
          </div>

        </div>

 <div class="col-lg-2 col-xs-12">
  <div class="form-group" *ngIf="statusNumber != '10'">
      
       <a  class="btn btn-primary form-control SITESCOUNT"
           role="button" title={{siteSetupsCount}} aria-disabled="true" >
           {{siteSetupsFilteredByStatusCount}} / {{siteSetupsCount}}
       </a>

  </div>
    <div class="form-group" *ngIf="statusNumber =='10'">

        <a  class="btn btn-primary form-control SITESCOUNT"
            role="button" title={{siteSetupsCount}} aria-disabled="true" >
            {{irbsFilteredByStatusCount}} / {{irbCount}}
        </a>
    </div>


 </div>

 

  <div class="col-lg-2 col-xs-12">
      <div class="form-group">
            <button (click)="filterIrbByCountry(enterCountryName)" class="btn btn-primary">
              Search - IRBs /Country
            </button>

      </div>
  </div>

   </div>
       
    <!-----------------------for dropdown list of Countries------------------------------->
    
<div class="row mb-10">

                         
                          <div class = "col-lg-2">

                            <p-dropdown [options]="irbDistinctCountryList" [(ngModel)]="enterCountryName" 
                                        placeholder="Select a Country"  [editable]="true" [showClear]="true">
                            </p-dropdown>
                                 
                            
                          </div>
                          <div class = "col-lg-6">
                          <div style="display: flex; justify-content: space-between;"> 

                            <p-button label = "AE Map" 
                                      icon = "pi pi-map" 
                                      iconPos = "right"
                                      (onClick) = "displayAeMap = !displayAeMap">
                            </p-button>
                          
                          </div>
  </div>
                         

   </div>


<!--------------------------------------end of Country Dropdown --------------------------------------------->      
     
 
 
  <div>
  <div>
     
    <div class = "map">
    <div style = "width: 90%; height: 400px;"> 
  <google-map 

      width = "85%" 
      height = "100%"
  
      [zoom] = "zoom"
      [center] = "center"
  
      (mapMousemove) = "move($event)">
                     
    <map-marker #marker = "mapMarker"
     
     *ngFor = "let markerP of markers;  let i = index" 
        [position] = "markerP.position"
        [options]  = "markerOptions"    
        (mapClick) = "openInfoWindow(marker, i)"
        [title]    = "markerP.title"
        [label]    = "markerP.label"
        [icon]     = "markerP.options"
              
     >
   
             <map-info-window>
                <div [innerHtml] = "thisistheinfo"></div>
             </map-info-window>
   
    </map-marker>
      
  </google-map>
      </div>
  </div>
  
  </div>
  
  
  <div>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              
  <ngx-spinner type="pacman">
    <p>Loading...</p></ngx-spinner>
  </div>
  </div>
  
 <!--::::::::::::::::::::::::::::::::::::::::::::AE Map Card Below::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::-->

 <p-card *ngIf="displayAeMap"
 class="floating-AeMapCard"
 cdkDrag  
 [style] = "{ width: '600px' , height: '500px'}"
 pTooltip="Drag & Position The AE Map Around The Screen - Best to Enlarge the map"
 tooltipPosition="top">
 
 <p-header class="centered-bold" style="text-align: center;">Adverse Event Map - {{sitesCountForAeMap}} Site(s) With Recorded AEs</p-header>
        
 <div>
  <div>
     
    <div class = "map">
    <div style = "width: 100%; height: 400px;"> 
  <google-map 

      width = "98%" 
      height = "100%"
  
      [zoom] = "1.5"
      [center] = "center"
  
      (mapMousemove) = "move($event)">
                     
    <map-marker #marker = "mapMarker"
     
     *ngFor = "let AEmarker of AEmarkers;  let j = index" 
        [position] = "AEmarker.position"
        [options]  = "AEmarkerOptions"
        (mapClick) = "openInfoWindow(marker, j)"
        [title]    = "AEmarker.title"
        [label]    = "AEmarker.label"
        [icon]     = "AEmarker.options"
              
     >
   
             <map-info-window>
                <div [innerHtml] = "thisistheinfo"></div>
             </map-info-window>
   
    </map-marker> 
    
    
    
  </google-map>
      </div>
  </div>
  
  </div>
  
  
  <!--<div>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              
  <ngx-spinner type="pacman">
    <p>Loading...</p></ngx-spinner>
  </div>-->
  </div>
 
 </p-card>

 <p-card *ngIf="displayAeMap"
 class="floating-bubbleChart"
 cdkDrag  
 [style] = "{ width: '600px' , height: '500px'}"
 pTooltip="Drag & Position The AE Map Around The Screen "
 tooltipPosition="top">

 <p-header class="centered-bold" style="text-align: center;">Adverse Event Count Per Site - Chart</p-header>
 
 <p-chart type="bar" 
              [data]="aEDistributionBubleGraphData" 
              [options]="options9"
               width="90%" height="360px">
        </p-chart>
 
 </p-card>

<!--:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::-->
<div class="row mb-1"></div>
<div class="row mb-1"></div>

<div fxLayout="row" fxLayoutAlign="start center">
 
  <p-card>
    <p-table  [value]="summaryEnrollmentData" 
              [tableStyle]="{ 'min-width': '50rem' }" > 
     
      <ng-template pTemplate="header">
          <tr>
              <th class="tbody"># Enrolled Patients</th>
              <th class="tbody"># Expected Patients (N)</th>
              <th class="tbody">FPI &#64; site</th>
              <th class="tbody"># of Patient(s) with AE</th>
              <th class="tbody"># Months Since IRB Approval</th>
             
          </tr>
      </ng-template>

      <ng-template pTemplate="body"  let-rowData>
          <tr>
            <td class="tbody">{{ value1 }}</td>
            <td class="tbody">{{ knobEnrollmentMax }}</td>
            <td class="tbody">{{ FPI |date:'d MMMM y' }}</td>
            <td class="tbody">{{ patientsWithAECounts }}</td>
            <td class="tbody"><b>{{studyDurationSinceIrbApproval}}</b></td>
             
          </tr>
      </ng-template>
     
    </p-table>
  
  </p-card>
</div>

<!--::::::::::::::::::::::::::::::::::::::BUTTONS::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::-->
<div class = "row mb-10"></div>
<div class = "row mb-10"></div>

<div style="display: flex; justify-content: space-between;"> 

  <p-button label = "Display/Hide - Site/IRB Analytics" 
            icon = "pi pi-images" 
            iconPos = "right"
            (onClick) = "displayCards = !displayCards">
  </p-button>

  <p-button label = "Display/Hide - IRB Site Specific Charts" 
            icon = "pi pi-chart-bar" 
            iconPos = "right"
            (onClick) = "displayCharts = !displayCharts">
  </p-button>

  <p-button label = "Display/Hide - Operational Charts" 
            icon = "pi pi-chart-bar" 
            iconPos = "right"
            (onClick) = "displayOperationalCharts = !displayOperationalCharts">
  </p-button>

</div>

<!--:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::-->

<div class = "row mb-10"></div>
<div class = "row mb-10"></div>

<div fxLayout="row" fxLayoutAlign="start center">
  
  <p-card *ngIf="displayCards"
         class = "card-style" 
         header = "Patients Status" 
         subheader = "Click on a button above for details" 
         [style] = "{ width: '350px' }" >

    <ng-template pTemplate="header">

      <div class="centered-card">
          
        <h5># Enrolled Patients.......</h5>   

        <p-knob [(ngModel)] = "value1"  
                [readonly] = "true" 
                [min] = "0" [max] = "knobEnrollmentMax"
                valueTemplate="{value}/N"
                valueColor = "#008013"  >
        </p-knob>
               
      </div>

      <div class = "centered-card">
          
        <h5># Patients in Treatment</h5>   

        <p-knob [(ngModel)] = "value3"  
                [readonly] = "true" 
                [min] = "0" [max] = "value1" >
        </p-knob>
               
      </div>
                   
    </ng-template>
  </p-card>

  
  <p-card *ngIf="displayCards"
          class = "card-style"
          header = ""  
          [style] = "{ width: '350px' }" 
          subheader = "Click on 'Actions...' button" >
    
    <ng-template pTemplate="header">

      <div class="centered-card">
        
        <h5># To be Consented....... </h5>

        <p-knob [(ngModel)] = "value2" 
                [readonly] = "true" 
                [min] = "0" [max] = "patientListFilteredCount"
                valueColor = "#FEA51D" >
        </p-knob>
        
      </div>

      <div class="centered-card">
        
        <h5># Awaiting Treatment...</h5>

        <p-knob  [(ngModel)] = "value4"   
                 [readonly] = "true"  
                 [min] = "0" [max] = "value1"
                 valueColor = "#FEA51D" >
        </p-knob>
        
      </div>


      <!--  Action Button-->
      <div style="display: flex; justify-content: center; align-items: center;">

            <p-button 

                  *ngIf = "(value2 != 0 || value4 != 0 || (value2 == 0 && value4 == 0))"  
                  [disabled]="!(value2 != 0 || value4 != 0)"
                  label="Actions..."
                  [raised]="true"
                  severity="warning"
                  icon = "pi pi-list" 
                  
                  (onClick) = "displayActionTable = !displayActionTable" >

            </p-button>

      </div> 
      
    <!---->
    
    </ng-template>
  </p-card>


  <p-card *ngIf="displayCards"
              header = "Operational Data" 
              subheader = "Since IRB Approval" 
              [style] = "{ width: '360px' }"
              class = "card-style">
            
              <ng-template pTemplate="header">

                  <p-table  [value]="enrollmentRate" 
                              [tableStyle]="{ 'min-width': '20rem' }" > 
                    
                        <ng-template pTemplate="header">
                              <tr>
                                  <th class="tbody">Enrollment Rate (per Month)</th>
                                  <th class="tbody">Time Since IRB Approval (Months)</th>
                              </tr>
                        </ng-template>

                        <ng-template pTemplate="body"  let-rowData>
                              <tr>
                                <td class="tbody">{{ enrollmentRate }}</td>
                                <td class="tbody">{{ studyDurationSinceIrbApproval }}</td>
                              </tr>
                        </ng-template>
                    
                  </p-table>

            </ng-template>
  </p-card>

</div>

<!------------------------------Action Table-------------------------------------------->

<div class="row mb-1"></div>
<div class="row mb-1"></div>

<div fxLayout="row" fxLayoutAlign="start center">

  <p-card  
          *ngIf="displayActionTable"
          class = "card-style"
          [style] = "{ width: '350px' }"
           >

    <p-table  [value]="patientNumberAwaitingConsentSignature" 
              [tableStyle]="{ 'min-width': '18rem' }" > 
    
      <ng-template pTemplate="header">
          <tr>

              <th class="tbody">Awaiting Consent Signature (Patient Number)</th>
                     
          </tr>
      </ng-template>

      <ng-template pTemplate="body"  let-rowData>
          <tr>

            <td class="tbody" style="color: red;">{{ rowData }}</td>
            
          </tr>
      </ng-template>
     
    </p-table>
  
  </p-card>

  <p-card  
            *ngIf="displayActionTable"
            class = "card-style"
            [style] = "{ width: '350px' }"
           >

    <p-table  [value]="patientsAwaitingFirstTreatmentID" 
              [tableStyle]="{ 'min-width': '18rem' }" > 
     
      <ng-template pTemplate="header">
          <tr>
              
              <th class="tbody">Awaiting Treatment Initiation (Patient Number) </th>
             
          </tr>
      </ng-template>

      <ng-template pTemplate="body"  let-rowData>
          <tr>
            
            <td class="tbody" style="color: red;">{{ rowData }}</td>
             
          </tr>
      </ng-template>
     
    </p-table>
  
  </p-card>

</div>


<!--::::::::::::::::::::::New Set of Graphs Using Chart.JS::::::::::::::::::-->

  <p-card *ngIf="displayCharts"
          class = "card-style"
          [style] = "{ width: '1100px' }">

    
  <p-tabView> 

    
    <p-tabPanel header="Patient Enrollment Status">

        <p-chart type="pie" 
              [data]="irbPatientEnrollmentGraphData" 
              [options]="options3"
               width="65%" height="360px">
        </p-chart>

    </p-tabPanel>

      <p-tabPanel header="Patient Treatment Status">

          <p-chart type="pie" 
                [data]="irbPatientTreatmentGraphData" 
                [options]="options4"
                width="65%" height="360px">
          </p-chart>

      </p-tabPanel>

      <p-tabPanel header="Enrollement Over Time">

          <p-chart type="line" 
                [data]="patientEnrollmentOverTimeGraphData" 
                [options]="options5"
                width="1000px" height="500px">
          </p-chart>

      </p-tabPanel>

      <p-tabPanel header="AE Distribution @ Site">

        <p-chart *ngIf="aeRecordedAtSiteAndCounts && aeRecordedAtSiteAndCounts.length > 0" 
            type="bar" 
            [data]="aeRecordedAtSiteGraphData" 
            [options]="options8"
            width="1000px" height="500px">
        </p-chart>

        <div *ngIf="!aeRecordedAtSiteAndCounts || aeRecordedAtSiteAndCounts.length === 0"
              style="color: red; font-size: 50px;"> No  AEs reported at this site
        </div>

      </p-tabPanel>

  </p-tabView>

  
</p-card>


<!-------------------------------------------------------------->

<div class = "row mb-10"></div>
<div class = "row mb-10"></div>

<p-card *ngIf="displayOperationalCharts"
          class = "card-style"
          [style] = "{ width: '1100px',  height: 'auto'}">


<p-tabView> 

      <p-tabPanel header="Studies Operational Data">

              <p-chart type="bar" 
              [data]="studyOperationalStatusGraphData" 
              [options]="options6"
              width="1000px" >
              </p-chart> 
      
      </p-tabPanel>

      <p-tabPanel header="Study Evaluation Status Counts">

          <p-chart type="bar" 
              [data]="studyEvaluationStatusGraphData" 
              [options]="options1"
              width="1000px" height="360px">
          </p-chart>   

      </p-tabPanel>

      <p-tabPanel header="IRBs Operational Data">

          <p-chart type="bar" 
              [data]="irbOperationalStatusGraphData" 
              [options]="options7"
              width="1000px" 
              height="auto">
          </p-chart> 

      </p-tabPanel>

      <p-tabPanel header="IRB Review Status Counts">
        
          <p-chart type="pie" 
              [data]="irbReviewStatusGraphData" 
              [options]="options2"
              width="65%" height="360px">
          </p-chart>

      </p-tabPanel>

      <p-tabPanel header="AE Distribution @ ALL Sites">

        <p-chart type="bar" 
            [data]="aEDistributionGraphData" 
            [options]="options8"
            width="1000px" height="400px">
        </p-chart>

      </p-tabPanel>


</p-tabView>


</p-card>
