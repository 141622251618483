<div  >
    <h2>IRBs Associated With Principal Investigator (PI) {{userName}}</h2>

    <div  class="col-lg-12 text-center" style="background-color: #034289; color: white; height: 30px; margin-bottom: 20px;"></div>

    <div *ngIf="totalIrbs==0  && !busy">
        <h2>Sorry there is no irb to display</h2>
    </div>
<div *ngIf="!busy">
    <div class="row mb-1" >
        <div class="col-lg-2"><b>Study Name</b></div>
        <div class="col-lg-2"><b>Study Site Name</b></div>
        <div class="col-lg-2"><b>Disease Area</b></div>
        <div class="col-lg-2"><b>Product Under Investigation</b></div>
              <div class="col-lg-2" *ngIf="localIrbs"><b>Local Site</b></div>
    </div>
    <div class="row mb-1" *ngFor="let aSite of piSites let i = index" >
        <div class="col-lg-2" style="font-weight: bold; color: blue;">
            {{aSite.studyName}}
        </div>
        <div class="col-lg-2">
            {{aSite.siteName}}
        </div>
        <div class="col-lg-2">
            {{aSite.diseaseArea}}
        </div>
        <div class="col-lg-2">
            {{aSite.requestedProduct}}
        </div>
         <div class="col-lg-2" *ngIf="localIrbs">
            
        </div>
        
        <div class="col-lg-2">
            <a  class="btn btn-lg btn-primary me-4" routerLink="/irbSubmission/{{aSite.id}}" role="button" style="width: 200px;">Go to IRB List
                    &raquo;&nbsp; </a> <!---->
        </div>
    </div>
    <div class="row mb-1" *ngFor="let aIrb of piIrbs let i = index" >
        <div class="col-lg-2" style="font-weight: bold; color: blue;">
            {{aSite.studyName}}
        </div>
        <div class="col-lg-2">
            {{aIrb.site.siteName}}
        </div>
        <div class="col-lg-2">
            {{aIrb.site.diseaseArea}}
        </div>
        <div class="col-lg-2">
            {{aIrb.site.requestedProduct}}
        </div>
         <div class="col-lg-2">
            {{aIrb.siteName}}
        </div>
        <!--<div class="col-lg-2">
            
        </div> -->
        <div class="col-lg-2" >
            
            <a  class="btn btn-lg btn-primary me-4" routerLink="/irbSubmission/{{aIrb.site.id}}" role="button">IRB
                    &raquo;&nbsp; </a> 
                



        </div>
    </div>
</div>

<ngx-spinner type="pacman">
    <h2>Loading...</h2></ngx-spinner>
</div>
