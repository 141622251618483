export class MaterialReturn
{
    public id:number;
    public  materialId:number;
    public numberOfItems: number;
}
export interface IMaterialReturn
{
    id:number;
    materialId:number;
    numberOfItems: number;
}