export interface ICompany {
    companyId: number;
    companyName: string;
    companyAddress: string;
    createdBy: string;
    modifiedBy: string;
    deleted:boolean;
    dateCreated: any;
    dateModified: Date;
}
export class Company{
    
  public  companyId: number;
  public  companyName: string;
  public  companyAddress: string;
  public  createdBy: string;
  public  modifiedBy: string;
  public  deleted:boolean;
  public  dateCreated: any;
  public  dateModified: Date;
}
