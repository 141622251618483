export class GraphValue{
  
  public graphValueX: any;
  public graphValueY: any;
  public Index:number;
 
}

export interface IGraphValue {

  graphValueX: any;
  graphValueY: any;
  Index: number;
  
}
