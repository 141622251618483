import { Component, OnInit, ViewChild, ViewChildren, QueryList, NgZone } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MapGeocoder, MapMarkerClusterer } from '@angular/google-maps';  //For converting adresses into geographic coordinates (Lat, Lng)//
import { MapInfoWindow, MapMarker, GoogleMap } from '@angular/google-maps';

import { SiteSetup, ISiteSetup } from 'src/app/shared/interfaces/siteSetup';
import { Irb, IIrb } from 'src/app/shared/interfaces/irb';
import { Patient, IPatient } from 'src/app/shared/interfaces/patient';
import { Treatment, ITreatment } from 'src/app/shared/interfaces/treatment';
import { IMaterial } from 'src/app/shared/interfaces/material';
import { IAdverseEvents } from 'src/app/shared/interfaces/adverseEvent';
import { AuthService } from '../../core/authentication/auth.service';
import { PiAdministrationService } from '../pi-administration.service'; 
import { IssAdministrationService } from 'src/app/investigator-initiated-study/iss-administration.service';
import { Router, CanActivate, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { IAnalyticsDataSummary } from 'src/app/shared/interfaces/analyticsSummaryData';
import { Marker } from 'src/app/shared/interfaces/markers';
import { PositionMarker } from 'src/app/shared/interfaces/positionMarker';
import { GraphValue } from 'src/app/shared/interfaces/graphValues';
import { FormControl } from '@angular/forms';

import { Chart, ChartDataset, ChartOptions } from 'chart.js';
import 'chartjs-adapter-moment';
import 'chartjs-plugin-datalabels';
import zoomPlugin from 'chartjs-plugin-zoom';
Chart.register(zoomPlugin);

import { Injectable } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { Card } from 'primeng/card';
import { TrialInclusionCriteriaComponent } from 'src/app/omnipotent/trial-inclusion-criteria/trial-inclusion-criteria.component';


@Injectable({
  providedIn: 'root'
})


@Component({
  selector: 'app-pi-analytics',
  templateUrl: './pi-analytics.component.html',
  styleUrls: ['./pi-analytics.component.sass']
  
})
export class PiAnalyticsComponent {

  userName:string=sessionStorage.getItem("userName");  //  to be revisited for selecting IRBs specific to a PI

  thisistheinfo: string = "The Info";
  summaryData: string[] = [];
  site: ISiteSetup ;
  siteId: number = 0;
  siteSetups: ISiteSetup[] = [];
  siteSetup: ISiteSetup;
  conceptId: number;
  siteSetupsFilteredByStatus: ISiteSetup[] = [];
  siteSetupsFilteredByCountry: ISiteSetup[] = [];
  siteSetupsFilteredByStatusCount: number;
  siteSetupsFilteredByCountryCount:  number = 0;
  siteSetupsFiltered: ISiteSetup[]; //AAA
  SiteSetup: ISiteSetup[];
  siteBusy: boolean=false;
  siteSetupsCount: number;  //AAA
  siteSetupsFilteredCount: number;  //AAA
  siteSetupsFilteredCountforGraph: Number=0;  //AAA
  currentSiteId: number;
  
  irb: IIrb;
  irbs: IIrb[] = [];
  irbListFiltered: IIrb[];
  irbsFilteredByStatus: IIrb[] = [];  // For IRB filteriing---08/30/2023
  irbsFilteredByCountry: IIrb[] = [];
  irbsFiltered: IIrb[] = [];  // For IRB filteriing
  irbCount: number;
  irbsFilteredCount: number;  //AAA
  irbsFilteredByStatusCount: number = 0;  //AAA...08/30/2023
  irbsFilteredByCountryCount: number = 0;
  irbId: number=0;
  irbBusy: boolean =false;
  irbGraphValues: GraphValue[] = [];
  irbGraphvalue: GraphValue;
  irbListFilteredCountforGraph: number = 0;
  currentIrbId:number;
  
  IRBstatusString: string;
  IRBstatusNumber: string;  
  irbCountryList: string[] =[];
  irbDistinctCountryList: string[] =[];
  irbSiteNameList: string[] = [];
  irbDistinctSiteNameList: string[] = [];
  irbPiNameList: string[] = [];
  irbDistinctPiNameList: string[] = [];
 
  material: IMaterial;
  materialInventoryList: IMaterial[] = [];
  //materialInventoryListFiltered: IMaterial[] = [];
  materialInventoryListCount: number;

  adverseEventList:IAdverseEvents[];
  adverseEventListForDropDownMenu: Array<string>;

  patientListBusy:boolean = false;
  patient:IPatient;
  patientList:IPatient[] = [];
  patientListFiltered: IPatient[];
  patientListFilteredCount: number; // To count the filtering result
  patientStatusGraphValues: GraphValue[] = [];
  patientStatusGraphValue: GraphValue;
  patientListFilteredCountforGraph: number = 0;
  patientEnrollmentGraphValues: GraphValue[] = [];
  patientEnrollmentGraphValue: GraphValue;
  patientTreatmentGraphValues: GraphValue[] = [];
  patientTreatmentGraphValue: GraphValue;
  NofPatientsInTreatment: number;
  NofPatientsAwaitingTreatment: number;
  orderedDates: Array<any>=[];
  FPI: Date = new Date();  
  

  treatmentBusy:boolean=false;
  treatments:ITreatment[] = [];
  treatmentsWithPi:ITreatment[] = [];
  treatmentList: ITreatment[];
  allTreatmentsListatSite: any[];
  treatmentList$ = new Subject<any[]>();  //////
  treatmentListFiltered:ITreatment[];
  aeTreatmentListAtSite: any[] = [];
  aeTreatmentList: ITreatment[]=[];
  treatmentAuditCount:number;  
 
  treatmentsCount: number;
  treatmentsWithPiCount: number;
  treatmentListFilteredCount:number; 
  enrolledPatientIdArray: Array<number> = [];
  aeRecordedAtSite: any[] = [];
  aeRecordedAtSiteAndCounts: any[] = [];
  sitesCountForAeMap: number = 0;
  
  patientNumberAwaitingConsentSignature: string[] = [];
  treatmentDatesArray:Date[] = [];
  firstTreatmentDatesArray: Date[] = [];
  firstTreatmentDate: Date = new Date();
  patientsAwaitingFirstTreatmentID :Array<string>;
  patientsInTreatmentID : Array<number>;
  summaryEnrollmentData: any[] = [];
  enrollmentRate: any[] = [];
    knobEnrollmentMax: number;
    value1: number ;
    value2: number ;
    value3: number ;
    value4: number ;
    AECount: number;
    AECounts: number;
    AECountsForPlot: any[] = [];
    PatientWithAE: number;
    patientsWithAECounts: number;
    patientsOnSelectedBatchCount: number;
    availableBatchNumbersAtSite:string;
    
   
  statusString: string;
  typeString: string;
  studyStatus: string = "0";
  statusNumber: string;  ;
  typeNumber:string = "0";
  enterCountryName: string = '';
  CountryList: string[] =[];
  siteCountryList: string[] = [];
 
  distinctCountryList: string[] =[];
  diseaseAreaList: string[] = [];
  distinctDiseaseAreaList: string[] = [];
  distinctAEListAtSite: string[] = [];
  studyNameList: string[] = [];
  distinctStudyNameList: string[] = [];
  piNameList: string[] = [];
  distinctPiNameList: string[] = [];
  siteNameList: string[] = [];
  distinctSiteNameList: string[] = [];

  conceptOperationData: any[] = [];
  protocolOperationData: any[] = [];
  contractOperationData: any[] = [];
  documentCheckOperationData: any[] = [];
  irbsOperationData1: any[] = [];
  irbsOperationData2: any[] = [];
  averageTimeIrbSubmissionToApproval: number = 0;
  studyDurationSinceIrbApproval: number =0;
  
  documentCheckData: any[] = [];  // to be deleted
  

  markers:Marker[] = [];
  IRBmarkersFilteredByStatusCount: number = 0; // AAA 08/23/2023
  markersPositionFiltered: Array<number>;
  markersFiltered: Marker[] = [];
  IRBmarkersFilteredByStatus: Marker[] = [];
  marker: Marker;
  AEmarker: Marker;
  AEmarkers: Marker[] = [];
  markerFiltered: Marker;
  minLatitudeForZooming: number = 0;
  maxLongitudeForZooming: number = 0;
  markerIndex: number = 0;
  markerFilteredIndex: number= 0;
  markerFilteredIrbIndex: number= 0;
  markerAEIrbIndex: number= 0;
  markersCount: number;  //AAA
  markersFilteredCount: number;   //AAA
  //markerClusters: MapMarkerClusterer[] = [];

  graphValues: GraphValue[] = [];
  graphValue: GraphValue;
  Index: number = 0;

  studyEvaluationStatusGraphData: any;
  irbReviewStatusGraphData: any;
  irbPatientEnrollmentGraphData:any;
  irbPatientTreatmentGraphData: any;
  patientEnrollmentOverTimeGraphData: any;
  enrollmentPlotTimeSerie: any[] = [];
  studyOperationalStatusGraphData: any;
  irbOperationalStatusGraphData: any;
  aEDistributionGraphData: any;
  aeRecordedAtSiteGraphData: any;
  AETreatmentListforMap: any[] = [];
  distinctAETreatmentSitesForMap: any[] = [];
  AETreatmentListforMapForBublePlot: any[] = [];
  aEDistributionBubleGraphData: any;
  options1: any;
  options2: any;  
  options3: any;
  options4: any;
  options5: any;
  options6: any;
  options7: any;
  options8: any;
  options9: any;
  
  visible: boolean = false; 
  busy:boolean = false;
  displayCards: boolean = false;
  displayAeMap: boolean = false;
  displayActionTable: boolean = false;
  displayCharts: boolean = false;
  displayOperationalCharts: boolean = false;
  address:string;
  display: any;
  description: string;
  zoom:number = 2.0;
  center: google.maps.LatLngLiteral;
  


  conceptIconMarkerURL:    string   = 'http://maps.google.com/mapfiles/ms/icons/blue.png';
  protocolIconMarkerURL:   string   = 'http://maps.google.com/mapfiles/ms/icons/green.png';
  contractIconMarkerURL:   string   = 'http://maps.google.com/mapfiles/ms/icons/orange.png';
  docRequestIconMarkerURL: string   = 'http://maps.google.com/mapfiles/ms/icons/purple.png';
  rejectedIconMarkerURL:   string   = 'http://maps.google.com/mapfiles/ms/icons/red-dot.png';
  missingIconMarkerURL:    string   = '';
  IrbMarker: any = {
    path: google.maps.SymbolPath.CIRCLE,
    fillColor: '#0000FF',
    fillOpacity: 0.7,
    strokeColor: '#FFFFFF',
    strokeOpacity: 1.0,
    strokeWeight: 1,
    scale: 16,
  };
  IrbRequestedMarker: any = {
    path: google.maps.SymbolPath.CIRCLE,
    fillColor: '#756d6d',
    fillOpacity: 1.0,
    strokeColor: '#FFFFFF',
    strokeOpacity: 1.0,
    strokeWeight: 1,
    scale: 16,
  };
  IrbSubmittedMarker: any = {
    path: google.maps.SymbolPath.CIRCLE,
    fillColor: '#0000FF',
    fillOpacity: 0.9,
    strokeColor: '#FFFFFF',
    strokeOpacity: 1.0,
    strokeWeight: 1,
    scale: 16,
  };
  IrbRevisionPendingMarker: any = {
    path: google.maps.SymbolPath.CIRCLE,
    fillColor: '#ff7034',
    fillOpacity: 1.0,
    strokeColor: '#FFFFFF',
    strokeOpacity: 1.0,
    strokeWeight: 1,
    scale: 16,
  };
  IrbApprovedMarker: any = {
    path: google.maps.SymbolPath.CIRCLE,
    fillColor: '#097969',
    fillOpacity: 0.9,
    strokeColor: '#FFFFFF',
    strokeOpacity: 1.0,
    strokeWeight: 1,
    scale: 16,
  };

  //conceptRejectedIconMarkerURL:   string ='';
  //protocolRejectedIconMarkerURL:   string = '';
  
  markerInfoContent = '';  //AAA
  
  
  markerOptions: google.maps.MarkerOptions = {
    
    draggable: false,
    animation: google.maps.Animation.DROP,
    visible: true, 
    opacity: 1,
    optimized: true,
    
    label: {
       color: '#FFFFFF',
       text: 'H',
  }
  };

 

  AEmarkerOptions: google.maps.MarkerOptions = {
    
    icon: {
      path: google.maps.SymbolPath.CIRCLE,
      fillColor: '#8B0000',
      fillOpacity: 1.0,
      strokeColor: '#FFFFFF',
      strokeOpacity: 1.0,
      strokeWeight: 1,
      scale: 16,
    },
    draggable: false,
    animation: google.maps.Animation.DROP,
    visible: true, 
    opacity: 1.0,
    optimized: true,
    

  };

  constructor(private route: ActivatedRoute,

    private zone: NgZone,
    private geocoder: MapGeocoder,
    private authService: AuthService,
    private router: Router,
    private administrationService: IssAdministrationService,
    private piAdministrationService: PiAdministrationService,
    private spinner: NgxSpinnerService, 
    private primeNGConfig: PrimeNGConfig,
    private datePipe: DatePipe) { }
    
    
    @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow | undefined;
    
   
  ngOnInit(): void {
    this.center = {lat: 21.885, lng: 1.279};
    // get all markers
    this.markers = [];
    this.AEmarkers =[];
   // this.markerClusters = [];
   

    this.zone.run(() => {
      // This code will be executed in response to a change detection event.
    });

    this.getAllPoints();
       
  }

  getAllPoints():void {
    this.getSiteSetups();
    this.getAllIrbs();
    this.getAllTreatments();
    //this.getAdverseEventList();
    
  }

  getPatientList = (irbId:number):void => {
   this.patientList = [];
   this.patientListBusy=true;
    this.showSpinner();
    this.piAdministrationService.getAllPatient(this.authService.authorizationHeaderValue, irbId)//this.irbId will be provided here
      .pipe(finalize(() => {
      this.patientListBusy=false;
        this.hideSpinner();
      })).subscribe(
        result => {
          this.patientList = result;
           
          this.patientListFiltered = result;
          this.patientListFilteredCount = this.patientListFiltered.length; //To count the result of filtering
          
         
          console.log('Site #',irbId,'All patients',this.patientList);
          console.log(this.irbsOperationData2);
          
          this.PatientVectorGenerator (this.patientList);
          this.PatientEnrollmentVectorGenerator(this.patientList);
          this.selectIrbOperationalAnalytics(this.currentIrbId, this.enrolledPatientIdArray ,this.irbsOperationData2);
         
        });

  }

  getIrb =(irbId:number):void =>{
    this.irbBusy=true;
    this.showSpinner();
    this.piAdministrationService.getSelectedIrb(this.authService.authorizationHeaderValue, irbId)
    .pipe(finalize(() => {
      this.irbBusy=false;
      this.hideSpinner();
    })).subscribe(
      result => {
        this.irb=result;
        this.siteId = this.irb.issSiteId;
        //get site
       // console.log("irb");
       // console.log("This IRB details",result);

        // Keep the order below.....
        this.getAdverseEventList();
        this.getSite(this.irb.issSiteId);
       
      });
  }

  /*getTreatmentListForGraph = (patientId:number): void => {
    this.treatmentBusy = true;
    this.treatmentList = [];
    this.treatmentAuditCount = 0;
  
    this.treatmentList$ = new Subject<ITreatment[]>;
    this.showSpinner();
    this.piAdministrationService.getPatientTreatments(this.authService.authorizationHeaderValue, patientId) 
      .pipe(finalize(() => {
        this.treatmentBusy=false;
        this.hideSpinner()
      })) .subscribe(
        result => {
          this.treatmentList=result;
          this.treatmentAuditCount=this.treatmentList.length; // counting the number of treatments/visits in the list 
          
         // Emit the treatmentList
          this.treatmentList$.next(this.treatmentList);
       
          this.PatientTreatmentVectorGenerator(patientId);
        
        });
 
       }*/

       async getTreatmentListForGraph(patientId: number): Promise<void> {
        try {
            this.treatmentBusy = true;
            this.treatmentList = [];
            this.treatmentAuditCount = 0;
    
            this.treatmentList$ = new Subject<ITreatment[]>();
            this.showSpinner();
    
            const result = await this.piAdministrationService
                .getPatientTreatments(this.authService.authorizationHeaderValue, patientId)
                .toPromise();
    
            this.treatmentList = result;
            this.treatmentAuditCount = this.treatmentList.length;
    
            // Emit the treatmentList
            this.treatmentList$.next(this.treatmentList);
    
            this.PatientTreatmentVectorGenerator(patientId);
          } 
        catch (error) {
            console.error('Error fetching patient treatments:', error);
        } 
        finally {
            this.treatmentBusy = false;
            this.hideSpinner();
        }
    }
    
    

 /// copy this function
 getAdverseEventListForMap (adverseEventArray:string[]): string  {
  var list="";
  adverseEventArray.forEach(element => {
    if(element!=null){
      if(list=="")
     {list+= element;}
     else{
      list+=", "+element
     }
    
    }
  });
  //console.log(list);
  if(list=="")
  {
    list+="None reported";
  }
  return list
}
  


    getAdverseEventList = async (): Promise<void> => {
      try {
        this.adverseEventList = [];
        this.adverseEventListForDropDownMenu = [];
        this.conceptId = this.irb.issSiteId;
    //console.log(this.conceptId);

        this.spinner.show();
        this.busy = true;
    
        const result = await this.piAdministrationService.getAdverseEvents(this.authService.authorizationHeaderValue, this.conceptId).toPromise();
    
        this.adverseEventList = result;
        this.adverseEventListForDropDownMenu = this.adverseEventList.map(obj => obj.adverseEventName);
    
        this.individualAEOccurence(this.adverseEventListForDropDownMenu);

      } 
      catch (error) {
        console.error('Error fetching adverse events:', error);
      } 
      finally {
        this.busy = false;
        this.hideSpinner();
      }
    };
    

  

       getSite  =(siteId:number):void =>{
    
        this.siteBusy=true;
        this.showSpinner();
        this.piAdministrationService.getIssSite(this.authService.authorizationHeaderValue, siteId)
        .pipe(finalize(() => {
          this.siteBusy=false;
          this.hideSpinner();
        })).subscribe(
          result => {
            this.siteSetup=result;
            //console.log("Site Details", this.siteSetup);
            
          });
      }


     
      getAllTreatments = async (): Promise<any> => {
        try {
            this.busy = true;
            this.treatments = [];
            this.treatmentsWithPi = [];
            const result = await this.piAdministrationService.getAllTreatments(this.authService.authorizationHeaderValue).toPromise();
           console.log(result);
           this.treatments = result;
           this.treatmentsCount = this.treatments.length;

           let allTreatmentAssociatedWithPI = result.filter(aTreatment => aTreatment.patient.irb.piEmail == this.userName);
           this.treatmentsWithPi = allTreatmentAssociatedWithPI;
           this.treatmentsWithPiCount = this.treatmentsWithPi.length;
            
           console.log('Listing of All Treatments regardless of Patients ID', this.treatmentsWithPi);
            this.AEMapping(this.treatmentsWithPi);

        } catch (error) {
            console.error(error);
        } finally {
            this.busy = false;
        }
       
        return  this.treatments, this.treatmentsWithPi, this.treatmentsCount, this.treatmentsWithPiCount;
    }
    

 showSpinner = (): void => {
   this.spinner.show();    

  }

  getMaterialInventoryList = (irbId: number): void => {
    
    this.piAdministrationService.getAllMaterialInventory(this.authService.authorizationHeaderValue, irbId)//this.irbId will be provided here
      .pipe(finalize(() => {
        
        this.hideSpinner();
      })).subscribe(
        result => {
          this.materialInventoryList = result;
console.log("Inventory List",this.materialInventoryList);
          
          this.materialInventoryListCount = this.materialInventoryList.length; //To count the result of filtering
console.log("IRB site # ",this.currentIrbId,", # of Batches =",this.materialInventoryListCount);
          
        });

  }

  getSiteSetups():void{
    this.spinner.show();
    this.piAdministrationService.getPiSites(this.authService.authorizationHeaderValue)
    .pipe(finalize(() => {
        this.busy = false;
        this.hideSpinner();
    }))
    .subscribe(
      result => {
        this.siteSetups=result;
        this.siteSetupsFilteredByStatus = result;
        this.siteSetupsFilteredByCountry = result;
        this.siteSetupsCount = this.siteSetups.length; // AAA counting the number of sites in the list
        this.siteSetupsFilteredByStatusCount = this.siteSetupsFilteredByStatus.length; // Counting filtering result        

        this.findDistinctSiteVariableList(this.siteSetupsCount);
        
        
    //console.log('List of All Study Sites',this.siteSetups);

        let i:number=0;
        
        for (i=0; i<this.siteSetupsCount;i++)
        {
          
            //this.markerCluster = new MarkerClusterer();
            this.marker = new Marker();   
            this.marker.position = new PositionMarker();
            this.marker.position.lat = Number(this.siteSetups[i].latitude);
            this.marker.position.lng = Number(this.siteSetups[i].longitude);
            this.marker.id = this.siteSetups[i].id;
            this.marker.markerIndex = this.markerIndex;
            this.markerOptions.title = this.siteSetups[i].geoLocation;
            this.marker.title = "Study - "+  this.markerOptions.title; // so it can be used it in the HTML component

            this.marker.options = String(this.MarkerURL(this.siteSetups[i], i));  //introduced for the Marker URL selection
            this.markerOptions.icon = this.marker.options;  
            this.markerOptions.zIndex = Number(google.maps.Marker.MAX_ZINDEX) + 10*i;
            this.marker.zIndex = this.markerOptions.zIndex;
             
            this.markers.push(this.marker);
            this.markersCount = this.markers.length;
            this.markersFilteredCount = this.markersCount;
        
            this.getInfoview(this.siteSetups[i].id,this.markerIndex);
            this.markerIndex++;
         
        }
        this.conceptOperationalAnalytics(this.siteSetups);
        this.protocolOperationalAnalytics(this.siteSetups);
        this.contractOperationalAnalytics(this.siteSetups);
        this.SitesVectorGenerator(this.siteSetups); 
       
      });

  }

          conceptOperationalAnalytics = (aSite:ISiteSetup[]):any =>{

    this.conceptOperationData = [];

  for (let i = 0; i < this.siteSetupsCount; i++) {

    //Initialiazation
let submissionDate = 0;
let evaluationDate = 0;
let timeDifference = 0;


let localDate = new Date();
let currentDate = new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000);  //convert local time to UTC 
let firstDate = new Date(this.siteSetups[i].conceptSubmissionDate );
let lastDate = new Date(this.siteSetups[i].conceptEvaluationDate);

    if (this.siteSetups[i].conceptEvaluationDate != null && 
      this.siteSetups[i].conceptEvaluationStatus !='Pending additional information' && 
      this.siteSetups[i].conceptEvaluationStatus != null)
        {


//Calculation

    submissionDate = Math.round((firstDate.getTime())/(1000 * 60 * 60 * 24)); 
    evaluationDate = Math.round((lastDate.getTime())/(1000 * 60 * 60 * 24));

    timeDifference = Math.round((evaluationDate - submissionDate)); // /(1000 * 60 * 60 * 24));

//console.log([this.siteSetups[i].siteName,timeDifference]);

    this.conceptOperationData.push([this.siteSetups[i].siteName, timeDifference]);

       }

  else
  {
    submissionDate = Math.round((firstDate.getTime())/(1000 * 60 * 60 * 24)); 
    evaluationDate = Math.round((currentDate.getTime())/(1000 * 60 * 60 * 24));

    timeDifference = Math.round((evaluationDate - submissionDate)); // /(1000 * 60 * 60 * 24));

//console.log([this.siteSetups[i].siteName,timeDifference]);

    this.conceptOperationData.push([this.siteSetups[i].siteName, timeDifference]);
  }
  }

  console.log('Concept Evaluation Duration',this.conceptOperationData);
  

  return this.conceptOperationData
          }


          protocolOperationalAnalytics = (aSite:ISiteSetup[]):any =>{

          this.protocolOperationData = [];

          for (let i = 0; i < this.siteSetupsCount; i++) {

            //Initialiazation
            let submissionDate = 0;
            let evaluationDate = 0;
            let timeDifference = 0;


            let localDate = new Date();
            let currentDate = new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000);  //convert local time to UTC 
            let firstDate = new Date(this.siteSetups[i].protocolSubmissionDate );
            let lastDate = new Date(this.siteSetups[i].protocolEvaluationDate);

            if (this.siteSetups[i].protocolEvaluationDate != null && this.siteSetups[i].protocolSubmissionDate !=null &&
              this.siteSetups[i].protocolEvaluationStatus !='Conditional approval pending revisions' && 
              this.siteSetups[i].protocolEvaluationStatus != null)
                {


        //Calculation

            submissionDate = Math.round((firstDate.getTime())/(1000 * 60 * 60 * 24)); 
            evaluationDate = Math.round((lastDate.getTime())/(1000 * 60 * 60 * 24));

            timeDifference = Math.round((evaluationDate - submissionDate)); // /(1000 * 60 * 60 * 24));

        //console.log([this.siteSetups[i].siteName,timeDifference]);

            this.protocolOperationData.push([this.siteSetups[i].siteName, timeDifference]);

              }

          else
          {

            if (this.siteSetups[i].protocolSubmissionDate ==null) {submissionDate = 0,  evaluationDate = 0}
            
            else{

            submissionDate = Math.round((firstDate.getTime())/(1000 * 60 * 60 * 24)); 
            evaluationDate = Math.round((currentDate.getTime())/(1000 * 60 * 60 * 24));
            }
          
            timeDifference = Math.round((evaluationDate - submissionDate)); // /(1000 * 60 * 60 * 24));

        //console.log([this.siteSetups[i].siteName,timeDifference]);

            this.protocolOperationData.push([this.siteSetups[i].siteName, timeDifference]);
          }
          }

          //console.log('Protocol Evaluation Duration',this.protocolOperationData);


          return this.protocolOperationData
           }

          contractOperationalAnalytics = (aSite:ISiteSetup[]):any =>{

            this.contractOperationData = [];

            for (let i = 0; i < this.siteSetupsCount; i++) {

              //Initialiazation
          let submissionDate = 0;
          let evaluationDate = 0;
          let timeDifference = 0;


          let localDate = new Date();
          let currentDate = new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000);  //convert local time to UTC 
          let firstDate = new Date(this.siteSetups[i].protocolEvaluationDate );
          let lastDate = new Date(this.siteSetups[i].contractEvaluationDate);

              if (this.siteSetups[i].protocolEvaluationDate !=null && this.siteSetups[i].protocolEvaluationStatus =='Approved' && 
                  this.siteSetups[i].contractEvaluationDate != null && this.siteSetups[i].contractEvaluationStatus == 'Approved' )
                  {


          //Calculation

              submissionDate = Math.round((firstDate.getTime())/(1000 * 60 * 60 * 24)); 
              evaluationDate = Math.round((lastDate.getTime())/(1000 * 60 * 60 * 24));

              timeDifference = Math.round((evaluationDate - submissionDate)); // /(1000 * 60 * 60 * 24));

          //console.log([this.siteSetups[i].siteName,timeDifference]);

              this.contractOperationData.push([this.siteSetups[i].siteName, timeDifference]);

                }

            else 
            {
          if(this.siteSetups[i].protocolEvaluationDate !=null && this.siteSetups[i].protocolEvaluationStatus =='Rejected' ||
          this.siteSetups[i].protocolEvaluationDate != null && this.siteSetups[i].protocolEvaluationStatus =='Conditional approval pending revisions' ||
          this.siteSetups[i].protocolEvaluationDate ==null)
          { 
            submissionDate = 0;
            evaluationDate = 0;
            timeDifference = 0;
            this.contractOperationData.push([this.siteSetups[i].siteName, timeDifference]);
          }
          else{



              submissionDate = Math.round((firstDate.getTime())/(1000 * 60 * 60 * 24)); 
              evaluationDate = Math.round((currentDate.getTime())/(1000 * 60 * 60 * 24));
              
            
            timeDifference = Math.round((evaluationDate - submissionDate)); // /(1000 * 60 * 60 * 24));

              //console.log([this.siteSetups[i].siteName,timeDifference]);

              this.contractOperationData.push([this.siteSetups[i].siteName, timeDifference]);
            }
            }
            }

              //console.log('Contract Negotiation Duration',this.contractOperationData);


                return this.contractOperationData
            }

//========================================To be constructed once data available in the database===================//

              documentCheckOperationalAnalytics = (aSite:ISiteSetup[], i:number):any =>{
                this.documentCheckOperationData = [];

                for (i = 0; i < this.siteSetupsCount; i++) {

                  this.documentCheckData.push([this.siteSetups[i].siteName,
                                                    this.siteSetups[i].protocolEvaluationDate, 
                                                    this.siteSetups[i].protocolEvaluationStatus,
                                                    this.siteSetups[i].contractEvaluationDate, 
                                                    this.siteSetups[i].contractEvaluationStatus])  //to be updated once the document-checks data are in the database

                }

                console.log('List of All Contract Negociation Data',this.documentCheckData);

                return
              }

 ////////////////////////////////////////////////////////////////////////////////////////////////

        irbsOperationalAnalytics = (aIrb:IIrb[], i:number):any =>{
          this.irbsOperationData1 = [];
          this.irbsOperationData2 = [];
          this.averageTimeIrbSubmissionToApproval = 0;
          

          for (i = 0; i < this.irbCount; i++) {

            //Initialiazation
            let creationDate = 0;
            let submitDate = 0;
            let timeIrbRequestToSubmission = 0;

              
            let localDate = new Date();
            let currentDate = new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000);  //convert local time to UTC 
            let firstDate = new Date(this.irbs[i].dateCreated);
            let halfwayDate = new Date(this.irbs[i].submissionDate);


            if (this.irbs[i].submissionDate != null )
                {

              //Calculation
            
            creationDate = Math.round((firstDate.getTime())/(1000 * 60 * 60 * 24)); 
            submitDate = Math.round((halfwayDate.getTime())/(1000 * 60 * 60 * 24));

            timeIrbRequestToSubmission = Math.round((submitDate - creationDate)); 
            this.irbsOperationData1.push([this.irbs[i].siteName, timeIrbRequestToSubmission]);

                }  
                else
                {

            creationDate = Math.round((firstDate.getTime())/(1000 * 60 * 60 * 24)); 
            submitDate = Math.round((currentDate.getTime())/(1000 * 60 * 60 * 24));

            timeIrbRequestToSubmission = Math.round((submitDate  - creationDate)); // /(1000 * 60 * 60 * 24));
            this.irbsOperationData1.push([this.irbs[i].siteName, timeIrbRequestToSubmission]);

                }
                
              }
            // console.log('IRB, Request-to-Submission Duration',this.irbsOperationData1);


              for (i = 0; i < this.irbCount; i++) {

                //Initialiazation
            let submitDate = 0;
            let evaluationDate = 0;
            let timeIrbSubmissionToReview = 0;
            let timeSinceIrbApproval = 0;
            let today = 0;
          
                  
            let localDate = new Date();
            let currentDate = new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000);  //convert local time to UTC 
            let halfwayDate = new Date(this.irbs[i].submissionDate);
            let lastDate = new Date(this.irbs[i].reviewDate);
            
                if (this.irbs[i].submissionDate != null && this.irbs[i].outcome == true)
                    {
            
                  //Calculation
                
                submitDate = Math.round((halfwayDate.getTime())/(1000 * 60 * 60 * 24)); 
                evaluationDate = Math.round((lastDate.getTime())/(1000 * 60 * 60 * 24));
                today = Math.round((currentDate.getTime())/(1000 *60 * 60 *24));
            
                timeIrbSubmissionToReview = Math.round((evaluationDate - submitDate)); 
                timeSinceIrbApproval = Math.round((today - evaluationDate));
                this.irbsOperationData2.push([this.irbs[i].siteName, timeIrbSubmissionToReview, timeSinceIrbApproval]);
                
          
                    }  
                    else
                    {
                      if (this.irbs[i].submissionDate !=null && this.irbs[i].outcome == false)
                      {
                submitDate = Math.round((halfwayDate.getTime())/(1000 * 60 * 60 * 24)); 
                evaluationDate = Math.round((currentDate.getTime())/(1000 * 60 * 60 * 24));
            
                timeIrbSubmissionToReview = Math.round((evaluationDate  - submitDate)); 
                this.irbsOperationData2.push([this.irbs[i].siteName, timeIrbSubmissionToReview, timeSinceIrbApproval]);
                      }
                      else 
                      { 
                        if (this.irbs[i].submissionDate = null)

                timeIrbSubmissionToReview = 0;
                timeSinceIrbApproval = 0;
                this.irbsOperationData2.push([this.irbs[i].siteName, timeIrbSubmissionToReview, timeSinceIrbApproval])

                  }
                    }
                    
                  }
                  this.averageTimeIrbSubmissionToApproval = (this.irbsOperationData2.reduce((total, row) => total + row[1], 0)) / (this.irbsOperationData2.length);
                  
                  

                  let mean = this.averageTimeIrbSubmissionToApproval;
                  let variance = this.irbsOperationData2.map(row => Math.pow(row[1] - mean, 2)).reduce((total, value) => total + value) / this.irbsOperationData2.length;
                  let standardDeviation = Math.sqrt(variance);

                 /* console.log('Average (Days) = ',Math.round(this.averageTimeIrbSubmissionToApproval));
                  console.log('Variance = ', Math.round(variance));
                  console.log('Standard Deviation (Days) = ', Math.round(standardDeviation));
                  console.log('IRB, Submission-to -Evaluation Duration',this.irbsOperationData2);

                  console.log('IRB Operational Analytics Data', [this.irbsOperationData1, this.irbsOperationData2]); */

          return this.irbsOperationData1, this.irbsOperationData2

        }

        selectIrbOperationalAnalytics = (currenIrbId:number, enrolledPatientIdArray:Array<number> ,irbsOperationData2:any[]): any =>{

          let rowIndex: number = null;
          let rowIndex1: number = null;
          this.enrollmentRate = [];
          this.studyDurationSinceIrbApproval = 0;
          

          if(this.enrolledPatientIdArray.length != 0) 
       
        {

              rowIndex1 = this.irbs.findIndex(row => row.id === this.currentIrbId);
              rowIndex = this.irbsOperationData2.findIndex(row => row[0] === this.irbs[rowIndex1].siteName);  //to find the row's index associated with siteName
             
              this.studyDurationSinceIrbApproval = Math.round(((irbsOperationData2[rowIndex][2])/30)*100)/100;   //Time since IRB approval in months
              this.enrollmentRate = [Math.round(((this.enrolledPatientIdArray.length)/((irbsOperationData2[rowIndex][2])/30))*1000)/1000];  //rounded to 2 decimal points
console.log(this.irbs[rowIndex1].siteName, this.enrolledPatientIdArray.length, irbsOperationData2[rowIndex][2] );
              console.log(this.currentIrbId, this.irbs[rowIndex1].siteName);
              console.log(this.currentIrbId, this.enrollmentRate, this.studyDurationSinceIrbApproval);

        }
        else
        {
          this.enrollmentRate = ['N/A - IRB Not Yet Approved']; 
          this.studyDurationSinceIrbApproval = 0; //Time since IRB approval in months
        }

          return this.studyDurationSinceIrbApproval, this.enrollmentRate
        }

        individualAEOccurence = (adverseEventListForDropDownMenu:Array<string>): any =>{
               
          this.AECountsForPlot = [];
          let AEtreatmentList = [];
         
         console.log('List of all Treatments accross all sites and patients',this.treatments, 'Listed or Expected AEs',adverseEventListForDropDownMenu);

          {
            AEtreatmentList = this.treatments.filter(aTreatment => aTreatment.treatmentType == 1);
          }
          
          let dataset = AEtreatmentList.map(obj => obj.aeSymptomsSelection);

          console.log("AE treatments list",AEtreatmentList);
          console.log('Observed/Recorded AEs', dataset);

          let i:number = 0;
          
          for (i = 0; i < this.adverseEventListForDropDownMenu.length; i++) {

          let count:number = 0;

          count = dataset.filter(word => word === this.adverseEventListForDropDownMenu[i]).length;

          this.AECountsForPlot.push([this.adverseEventListForDropDownMenu[i], count])
          }
          
         console.log('Array for Plot - AEs Occurence',this.AECountsForPlot);

         this.AEOccurenceVectorGenerator([this.AECountsForPlot]);

         return [this.AECountsForPlot]
        }

//==================================================================================================//
       //To select the unique Countries, Disease Area, Study NAme, Pi Name and Site Name from the studies List
        findDistinctSiteVariableList=(irbCountOrSiteCount: number): any =>{
          let countryTemp: string[] = [];
          let diseaseAreaTemp: string[] = [];
          let studyNameTemp: string[] = [];
          let piNameTemp: string[] = [];
          let siteNameTemp: string[] = [];

          for (let i = 0; i < irbCountOrSiteCount; i++) {
            countryTemp.push(this.siteSetups[i].country);   //Site Countries
            diseaseAreaTemp.push(this.siteSetups[i].diseaseArea); //Disease Areas
            studyNameTemp.push(this.siteSetups[i].studyName); //Study Names
            piNameTemp.push(this.siteSetups[i].piName); //Pi Names
            siteNameTemp.push(this.siteSetups[i].siteName); //Pi Names
            
        }
        this.siteCountryList = Array.from(new Set(countryTemp));
        this.siteCountryList.sort((a, b) => a.localeCompare(b));

        this.distinctDiseaseAreaList = Array.from(new Set(diseaseAreaTemp));
        this.distinctDiseaseAreaList.sort((a, b) => a.localeCompare(b));

        this.distinctStudyNameList = Array.from(new Set(studyNameTemp));
        this.distinctStudyNameList.sort((a, b) => a.localeCompare(b));

        this.distinctPiNameList = Array.from(new Set(piNameTemp));
        this.distinctPiNameList.sort((a, b) => a.localeCompare(b));

        this.distinctSiteNameList = Array.from(new Set(siteNameTemp));
        this.distinctSiteNameList.sort((a, b) => a.localeCompare(b));

        console.log('Disease Areas', this.distinctDiseaseAreaList);
        console.log('Study Names', this.distinctStudyNameList);
        console.log('Pi Names', this.distinctPiNameList);
        console.log('Site Names', this.distinctSiteNameList);
        console.log('Distinct Countries (for Studies)', this.siteCountryList);

        return this.siteCountryList, this.distinctDiseaseAreaList, this.distinctStudyNameList, this.distinctPiNameList
        }


          distinctIrbVariables= (): any =>{
    
            let tempIrbCountryList:string[] = [];
            let tempIrbSiteNameList: string[] = [];
            let tempIrbPiNameList: string[] = [];

            for (let i = 0; i < this.irbCount; i++) {
              if (this.irbs[i].longitude != null && this.irbs[i].latitude != null)
            {
            this.irbCountryList.push(this.irbs[i].country);   //IRB Countries
            }
              this.irbSiteNameList.push(this.irbs[i].siteName);
              this.irbPiNameList.push(this.irbs[i].piName);
            }
          
           tempIrbCountryList = this.siteCountryList.concat(this.irbCountryList);  //Assembling the country list
           this.irbDistinctCountryList = Array.from(new Set(tempIrbCountryList));  //Select distinct country from list
           this.irbDistinctCountryList.sort((a, b) => a.localeCompare(b));          // order distinct countries Array in alphabetic order

           tempIrbSiteNameList = this.siteNameList.concat(this.irbSiteNameList)
           this.irbDistinctSiteNameList = Array.from(new Set(tempIrbSiteNameList));
           this.irbDistinctSiteNameList.sort((a, b) => a.localeCompare(b));
           
           tempIrbPiNameList = this.distinctPiNameList.concat(this.irbPiNameList)
           this.irbDistinctPiNameList = Array.from(new Set(tempIrbPiNameList));
           this.irbDistinctPiNameList.sort((a, b) => a.localeCompare(b));
           
        
           //console.log('All countries from 2 sets',this.siteCountryList,this.irbCountryList,tempIrbCountryList);
           // console.log('Distinct IRB Pis',this.irbDistinctPiNameList);
           // console.log('Distinct IRB Site Names',this.irbDistinctSiteNameList);
           // console.log('Distinct Countries',this.irbDistinctCountryList);
          
            return [this.irbDistinctCountryList, this.irbDistinctSiteNameList, this.irbDistinctPiNameList]
           
          } 

            //ALL 4 Filters Implementatiuon 

        filterSites = (): void =>{
          this.filterByStatus();
        
          this.filterByCountry(this.enterCountryName);
        
          };
          
          filterIRBs = (): void =>{
            
          this.filterByIrbStatus();
            
          this.filterIrbByCountry(this.enterCountryName)
            };
  
   //.............................................................................................//
  //..'MarkerURL' and 'MarkerURLFiltered' are used to select the colored icon associated with the status of the study. The colors are same one you will see in the site-Setup module ..//

  MarkerURL(a:ISiteSetup, i:number):string{
   
    {
      let a = this.siteSetups[i];
      
  
              if ((a.conceptSubmissionDate != null && a.conceptEvaluationStatus == null) || 
              (a.conceptSubmissionDate != null && a.conceptEvaluationStatus == 'Pending additional information') )
                  return this.conceptIconMarkerURL;    //Concept       
   
             
              if ((a.conceptEvaluationStatus == 'Approved' && a.protocolSubmissionDate == null) ||
              (a.protocolSubmissionDate != null && a.protocolEvaluationStatus == null) || 
              (a.protocolEvaluationStatus != 'Approved' && a.protocolEvaluationStatus != 'Rejected'))
                  return this.protocolIconMarkerURL;  //Protocol
  
              if (( a.protocolEvaluationStatus == 'Approved' && a.contractEvaluationStatus != 'Approved') )
                  return this.contractIconMarkerURL;   //Negotiation
    
    
             if ((a.contractEvaluationStatus == 'Approved'))
                  return this.docRequestIconMarkerURL;    //Document 

             if ((a.conceptEvaluationStatus == 'Rejected') )
                  return this.rejectedIconMarkerURL;   //Rejected

             if ( (a.protocolEvaluationStatus == 'Rejected'))
                  return this.rejectedIconMarkerURL;   //Rejected
             
  
    }         
   }

   MarkerURLFiltered(a:ISiteSetup, b:ISiteSetup, i:number, enterCountryName:string):string{
   
    if (this.enterCountryName == ''){
      let a = this.siteSetupsFilteredByStatus[i];
      
      
  
              if ((a.conceptSubmissionDate != null && a.conceptEvaluationStatus == null) || 
              (a.conceptSubmissionDate != null && a.conceptEvaluationStatus == 'Pending additional information') )
                  return this.conceptIconMarkerURL;    //Concept       
   
             
              if ((a.conceptEvaluationStatus == 'Approved' && a.protocolSubmissionDate == null) ||
              (a.protocolSubmissionDate != null && a.protocolEvaluationStatus == null) || 
              (a.protocolEvaluationStatus != 'Approved' && a.protocolEvaluationStatus != 'Rejected'))
                  return this.protocolIconMarkerURL;  //Protocol
  
              if (( a.protocolEvaluationStatus == 'Approved' && a.contractEvaluationStatus != 'Approved') )
                  return this.contractIconMarkerURL;   //Negotiation
    
    
             if ((a.contractEvaluationStatus == 'Approved'))
                  return this.docRequestIconMarkerURL;    //Document 

             if ((a.conceptEvaluationStatus == 'Rejected') )
                  return this.rejectedIconMarkerURL;   //Rejected

             if ( (a.protocolEvaluationStatus == 'Rejected'))
                  return this.rejectedIconMarkerURL;   //Rejected
  
    }   
    
    else 

    {
      let b=this.siteSetupsFilteredByCountry[i];
  
              if ((b.conceptSubmissionDate != null && b.conceptEvaluationStatus == null) || 
              (b.conceptSubmissionDate != null && b.conceptEvaluationStatus == 'Pending additional information') )
                  return this.conceptIconMarkerURL;    //Concept       
   
             
              if ((b.conceptEvaluationStatus == 'Approved' && b.protocolSubmissionDate == null) ||
              (b.protocolSubmissionDate != null && b.protocolEvaluationStatus == null) || 
              (b.protocolEvaluationStatus != 'Approved' && b.protocolEvaluationStatus != 'Rejected'))
                  return this.protocolIconMarkerURL;  //Protocol
  
              if (( b.protocolEvaluationStatus == 'Approved' && b.contractEvaluationStatus != 'Approved') )
                  return this.contractIconMarkerURL;   //Negotiation
    
    
             if ((b.contractEvaluationStatus == 'Approved'))
                  return this.docRequestIconMarkerURL;    //Document 

             if ((b.conceptEvaluationStatus == 'Rejected') )
                  return this.rejectedIconMarkerURL;   //Rejected

             if ( (b.protocolEvaluationStatus == 'Rejected'))
                  return this.rejectedIconMarkerURL;   //Rejected
  
    }   

   }

   irbMarkerColorFiltered(a:IIrb[], b:IIrb[], i:number, enterCountryName:string):string{
   
    if (this.enterCountryName == ''){
      let a = this.irbsFilteredByStatus[i];
  
      if (a.reviewDate != undefined && a.reviewDate != null) {

        if (a.outcome == true) {
          return this.IrbApprovedMarker;
        }
        if (a.outcome == false) {
          return this.IrbRevisionPendingMarker;
        }
      }
      if (a.submissionDate != undefined && a.submissionDate != null) {
        return this.IrbSubmittedMarker;
      }
      return this.IrbRequestedMarker;
  
    }   
    
    else 

    {
      let b = this.irbsFilteredByCountry[i];
  
      if (b.reviewDate != undefined && b.reviewDate != null) {


        if (b.outcome == true) {
          return this.IrbApprovedMarker;
        }
        if (b.outcome == false) {
          return this.IrbRevisionPendingMarker;
        }
      }
      if (b.submissionDate != undefined && b.submissionDate != null) {
        return this.IrbSubmittedMarker;
      }
      return this.IrbRequestedMarker;
  
    }   

   }
 
//the Clearmarkers function is designed to wipe out all markers from the map at the beginning of activating a Filter//
  ClearMarkers(a:Marker[], i:number):void{
    {
     
      for (i = 0; i<this.siteSetupsCount;i++){
        this.markers[i] == null;
        
      }
      this.markers.length = 0;

    }
  }

  FilteredMarkers(aSite:ISiteSetup[],siteSetupsFilteredByCountry:ISiteSetup[], i:number, enterCountryName:string):any{  
        this.markerIndex = 0;
        this.markerFilteredIndex = 0;
        
   
    if(this.enterCountryName == ''){
 
    //console.log(aSite);
    //console.log(this.siteSetupsFilteredByStatus);
    //console.log(this.siteSetupsFilteredByStatusCount);
    //console.log(this.siteSetupsFilteredByCountry);

 
           this.ClearMarkers(this.markersFiltered, this.siteSetupsCount);
          
         for (i=0; i<this.siteSetupsFilteredByStatusCount;i++)   
         
         {
             this.markerFiltered = new Marker();
             this.markerFiltered.position = new PositionMarker();
             this.markerFiltered.position.lat = Number(this.siteSetupsFilteredByStatus[i].latitude);
             this.markerFiltered.position.lng = Number(this.siteSetupsFilteredByStatus[i].longitude);
             this.markerFiltered.id = this.siteSetupsFilteredByStatus[i].id;
             this.markerFiltered.markerIndex = this.markerIndex;  //AAA
             
             this.markerOptions.title = "Study - "+ this.siteSetupsFilteredByStatus[i].geoLocation;
             this.markerFiltered.title = this.markerOptions.title; /// So it can be used it in the HTML component
             
             
             this.markerFiltered.options = String(this.MarkerURLFiltered(this.siteSetupsFilteredByStatus[i], this.siteSetupsFilteredByCountry[i], i, this.enterCountryName));  //introduced for the Marker URL selection
             this.markerOptions.icon = this.markerFiltered.options;
 
             this.markerOptions.zIndex = Number(google.maps.Marker.MAX_ZINDEX) + 10*i
             this.markerFiltered.zIndex = this.markerOptions.zIndex
            
             this.markersFiltered.push(this.markerFiltered);
             this.markersFilteredCount = this.markersFiltered.length;
 
             this.getInfoview(this.siteSetupsFilteredByStatus[i].id,this.markerIndex)
             this.markerIndex++;
            
         }         
             
         
         }
 
 else {
   
  // console.log(aSite);
   //console.log(this.siteSetupsFilteredByCountry);
   //console.log(this.siteSetupsFilteredByCountryCount);
   //console.log(this.siteSetupsFilteredByCountry);
 
   this.ClearMarkers(this.markersFiltered, this.siteSetupsCount);
          
          let i:number=0;
        for (i = 0; i<this.siteSetupsFilteredByCountryCount;i++)   
        
        {
            this.markerFiltered = new Marker();
            this.markerFiltered.position = new PositionMarker();
            this.markerFiltered.position.lat = Number(this.siteSetupsFilteredByCountry[i].latitude);
            this.markerFiltered.position.lng = Number(this.siteSetupsFilteredByCountry[i].longitude);
            this.markerFiltered.id = this.siteSetupsFilteredByCountry[i].id;
            this.markerFiltered.markerIndex = this.markerFilteredIndex;  //AAA
            
            this.markerOptions.title = "Study - "+ this.siteSetupsFilteredByCountry[i].geoLocation;
            this.markerFiltered.title = this.markerOptions.title; /// So it can be used it in the HTML component
            
            
            this.markerFiltered.options = String(this.MarkerURLFiltered(this.siteSetupsFilteredByStatus[i], this.siteSetupsFilteredByCountry[i], i, this.enterCountryName));  //introduced for the Marker URL selection
            this.markerOptions.icon = this.markerFiltered.options;
 
            this.markerOptions.zIndex = Number(google.maps.Marker.MAX_ZINDEX) + 10*i;
            this.markerFiltered.zIndex = this.markerOptions.zIndex;
           
            this.markersFiltered.push(this.markerFiltered);
            this.markersFilteredCount = this.markersFiltered.length;
  
            this.getInfoview(this.siteSetupsFilteredByCountry[i].id,this.markerFilteredIndex)
            this.markerFilteredIndex++;
           
        } 
      } 

            return this.markersFiltered
            
   }

   IrbMarkerColor(aIrb: Irb, IRBstatusString: string):any{

    if (aIrb.reviewDate != undefined && aIrb.reviewDate != null) {


      if (aIrb.outcome == true) {
        return this.IrbApprovedMarker;
      }
      if (aIrb.outcome == false) {
        return this.IrbRevisionPendingMarker;
      }
    }
    if (aIrb.submissionDate != undefined && aIrb.submissionDate != null) {
      return this.IrbSubmittedMarker;
    }
    return this.IrbRequestedMarker;

   };

   FilteredIrbMarkers(aIrb:IIrb[], i:number, enterCountryName:string, IRBstatusString: string): any {  
          
    this.markerIndex = 0;
    this.markerFilteredIrbIndex = 0;
    
    this.ClearMarkers(this.markersFiltered, this.siteSetupsCount);
    
  for (i = 0; i<this.irbsFilteredByStatusCount; i++) {
    if(this.irbsFilteredByStatus[i].latitude!=null &&
      this.irbsFilteredByStatus[i].longitude!=null &&
      this.irbsFilteredByStatus[i].country!=null)
      {

  if(this.enterCountryName == '') {
  
    
    this.markerFiltered = new Marker();
    this.markerFiltered.position = new PositionMarker();
    this.markerFiltered.position.lat = Number(this.irbsFilteredByStatus[i].latitude);
    this.markerFiltered.position.lng = Number(this.irbsFilteredByStatus[i].longitude);
    
    this.markerOptions.title = this.irbsFilteredByStatus[i].geoLocation;
    this.markerFiltered.title = "Irb - " +this.markerOptions.title; /// So it can be used it in the HTML component
    
    
    this.markerFiltered.options = this.IrbMarkerColor(this.irbs[i], this.IRBstatusString);  //introduced for the IRB Marker color selection selection
    
    this.markerOptions = {
                        icon: this.IrbMarker,
                        zIndex: Number(google.maps.Marker.MAX_ZINDEX) + 100*i,
                          };
    
    this.markerFiltered.label = {text: this.irbsFilteredByStatus[i].id.toString(),
                                  color:'#FFFFFF',
                                };

    this.markerFiltered.id = this.irbsFilteredByStatus[i].id;
    this.markerFiltered.markerIndex = this.markerIndex;

    this.markersFiltered.push(this.markerFiltered);
    this.markersFilteredCount = this.markersFiltered.length;

    this.getIrbInfoview(this.irbsFilteredByStatus[i].id,this.markerIndex)
    this.markerIndex++;
    

   }

   if(this.enterCountryName == '' && this.IRBstatusString != '" "') {
    this.markerFiltered = new Marker();
    this.markerFiltered.position = new PositionMarker();
    this.markerFiltered.position.lat = Number(this.irbsFilteredByStatus[i].latitude);
    this.markerFiltered.position.lng = Number(this.irbsFilteredByStatus[i].longitude);
    
    this.markerOptions.title = this.irbsFilteredByStatus[i].geoLocation;
    this.markerFiltered.title = "Irb - " +this.markerOptions.title; /// So it can be used it in the HTML component
    
    this.markerFiltered.options = (this.irbMarkerColorFiltered(this.irbsFilteredByStatus, this.irbsFilteredByCountry, i, enterCountryName));  //introduced for the Marker URL selection
   

    this.markerOptions = {
                            icon: this.IrbMarker,
                            zIndex: Number(google.maps.Marker.MAX_ZINDEX) + 100*i,
                         };

    this.markerFiltered.label = {text: this.irbsFilteredByStatus[i].id.toString(),
                                 color:'#FFFFFF',
                                };

    this.markerFiltered.id = this.irbsFilteredByStatus[i].id;
    this.markerFiltered.markerIndex = this.markerFilteredIrbIndex;

    this.markersFiltered.push(this.markerFiltered);
    this.markersFilteredCount = this.markersFiltered.length;

    this.getIrbInfoview(this.irbsFilteredByStatus[i].id,this.markerFilteredIrbIndex)
    this.markerFilteredIrbIndex++;
   
    //console.log([this.markerFiltered.id, this.markerFiltered.title, this.markerFiltered.position.lat , this.markerFiltered.position.lng]);

    
  } 
  
  else {
        this.markerFiltered = new Marker();
        this.markerFiltered.position = new PositionMarker();
        this.markerFiltered.position.lat = Number(this.irbsFilteredByCountry[i].latitude);
        this.markerFiltered.position.lng = Number(this.irbsFilteredByCountry[i].longitude);
        
        this.markerOptions.title = this.irbsFilteredByCountry[i].geoLocation;
        this.markerFiltered.title = "Irb - " +this.markerOptions.title; /// So it can be used it in the HTML component
        
        this.markerFiltered.options = (this.irbMarkerColorFiltered(this.irbsFilteredByStatus, this.irbsFilteredByCountry, i, enterCountryName));  //introduced for the Marker URL selection
       

        this.markerOptions = {
                                icon: this.IrbMarker,
                                zIndex: Number(google.maps.Marker.MAX_ZINDEX) + 100*i,
                             };

        this.markerFiltered.label = {text: this.irbsFilteredByCountry[i].id.toString(),
                                     color:'#FFFFFF',
                                    };

        this.markerFiltered.id = this.irbsFilteredByCountry[i].id;
        this.markerFiltered.markerIndex = this.markerFilteredIrbIndex;

        this.markersFiltered.push(this.markerFiltered);
        this.markersFilteredCount = this.markersFiltered.length;

        this.getIrbInfoview(this.irbsFilteredByCountry[i].id,this.markerFilteredIrbIndex)
        this.markerFilteredIrbIndex++;
       
        //console.log([this.markerFiltered.id, this.markerFiltered.title, this.markerFiltered.position.lat , this.markerFiltered.position.lng]);

        
      } 
      }
  
}

return this.markersFiltered
}



      filterByStatus = (): any => {
        var myStatusString = [" ", "Concept Evaluation", "Concept Re-evaluation", "Rejected (Concept)", "Protocol Submission", "Protocol Evaluation",
          "Protocol Re-evaluation", "Rejected (Protocol)", "Contract Negotiation", "Documents Check",
          "All IRBs"]


        let index = Number(this.statusNumber);
        this.statusString = myStatusString[index];

        {
          if (index == 0) {
            this.ClearMarkers(this.markersFiltered, this.siteSetupsCount);

            this.siteSetupsFilteredByStatus = this.siteSetups;
            this.siteSetupsFilteredByStatusCount = this.siteSetupsFilteredByStatus.length; // Counting filtering result

            this.markersFiltered = this.markers;
            this.markersFiltered = this.FilteredMarkers(this.siteSetupsFilteredByStatus, this.siteSetupsFilteredByCountry, this.siteSetupsFilteredByStatusCount, this.enterCountryName);
            this.markersFilteredCount = this.markersFiltered.length; // Counting filtering result

          }

          else {

            this.ClearMarkers(this.markersFiltered, this.siteSetupsCount);
            this.siteSetupsFilteredByStatus = [];

            this.siteSetupsFilteredByStatus = this.siteSetups.filter((aSite, i) =>
            this.EvaluationStatus(aSite, i) == this.statusString);

            this.siteSetupsFilteredByStatusCount = this.siteSetupsFilteredByStatus.length; // Counting filtering result
            
            this.markersFiltered = this.markers;
            this.markersFiltered = this.FilteredMarkers(this.siteSetupsFilteredByStatus, this.siteSetupsFilteredByCountry, this.siteSetupsFilteredByStatusCount, this.enterCountryName);   //  AAA
            this.markersFilteredCount = this.markersFiltered.length; // Counting filtering result

            //console.log(this.siteSetupsFilteredByStatusCount)
            //console.log(this.siteSetupsFilteredByStatus)

          }



          if (index == 10) {
            this.ClearMarkers(this.markersFiltered, this.siteSetupsCount);

            this.irbsFilteredByStatus = this.irbs
            this.irbCount = this.irbs.length;  //Counting original number of IRB

            this.irbsFilteredByStatusCount = this.irbsFilteredByStatus.length; // Counting IRB filtering result

            this.markersFiltered = this.markers;
            this.markersFiltered = this.FilteredIrbMarkers(this.irbsFilteredByStatus, this.irbsFilteredByStatusCount, this.enterCountryName, this.IRBstatusString);
            this.markersFilteredCount = this.markersFiltered.length; // Counting filtering result


          // console.log(this.irbsFilteredByStatus)
            //console.log(this.markersFiltered)
            //console.log(this.markersFilteredCount)

          }
        }
        return [this.siteSetupsFilteredByStatus, this.markerFiltered, this.markers]
      }

  
  // STARTING FILTERING
  
  EvaluationStatus = (aSite:ISiteSetup, i:number): string => {
    {
      let a = this.siteSetups[i];
    
      if (a.contractEvaluationStatus == 'Approved'  ){
          return 'Documents Check';   //Document
        }
       
        if (a.contractEvaluationStatus == 'Conditional approval pending revisions'  ){
          return 'Contract Negotiation';   //Negotiation
       }
  
       if (a.protocolEvaluationStatus == 'Approved' &&  a.contractEvaluationStatus!= 'NULL'){
          return 'Contract Negotiation';   //Negotiation
       }
  
        if (a.protocolEvaluationStatus == 'Conditional approval pending revisions'){
          return 'Protocol Re-evaluation';  //Protocol
        }
  
       if (a.protocolEvaluationStatus != null && a.protocolEvaluationDate==null){
        return 'Protocol Submission';  //Protocol
        }
       
        if (a.conceptEvaluationStatus == 'Approved' && a.protocolEvaluationStatus=='Pending Revision'){
          return 'Protocol Evaluation';  //Protocol
        }
  
        if (a.protocolSubmissionDate != null && a.protocolEvaluationStatus==null){
          return 'Protocol Evaluation';  //Protocol
        }  
  
        if (aSite.conceptEvaluationStatus == 'Approved' && a.protocolSubmissionDate==null){
          return 'Protocol Submission';  //Protocol
          }
  
       if (a.conceptEvaluationStatus == 'Pending additional information'){
          return 'Concept Re-evaluation';    //Concept
        }
  
       if (a.conceptEvaluationStatus == null){
       return 'Concept Evaluation';    //Concept
        }
  
       if (a.protocolEvaluationStatus == 'Rejected'){
        return 'Rejected (Protocol)';   //Rejected
         }
         
        if (a.conceptEvaluationStatus == 'Rejected'){
          return 'Rejected (Concept)';   //Rejected
         } 
      
       } 
        }

         //----------------------IRB Filter--------------------------------------------------------------------//

          filterByIrbStatus = (): any=> {
            var myIrbStatusString = [" ", "Requested", "Submitted", "Pending Revision", "Approved"]

          let index=Number(this.IRBstatusNumber);
          this.IRBstatusString = myIrbStatusString[index];
                    
          {            
          if (index == 0) {

          this.ClearMarkers(this.markersFiltered, this.siteSetupsCount);
          this.irbsFilteredByStatus = [];

          this.irbsFilteredByStatus = this.irbs;
          this.irbsFilteredByStatusCount = this.irbsFilteredByStatus.length; // Counting filtering result

          this.IRBmarkersFilteredByStatus = this.markers;
          this.IRBmarkersFilteredByStatus = this.FilteredIrbMarkers(this.irbsFilteredByStatus, this.irbsFilteredByStatusCount, this.enterCountryName, this.IRBstatusString);
          this.IRBmarkersFilteredByStatusCount = this.IRBmarkersFilteredByStatus.length; // Counting filtering result
                      
          //console.log(this.irbsFilteredByStatusCount)
          //console.log(this.IRBmarkersFilteredByStatus);
          }

          else

          {
          //console.log(this.irbs)
          this.ClearMarkers(this.markersFiltered, this.siteSetupsCount);
          this.irbsFilteredByStatus = [];
          
          this.irbsFilteredByStatus = this.irbs.filter((aIrb)=>
          this.irbApprovalStatus(aIrb) == this.IRBstatusString); 
          this.irbsFilteredByStatusCount = this.irbsFilteredByStatus.length; // Counting filtering result

            this.IRBmarkersFilteredByStatus = this.markers;
            this.IRBmarkersFilteredByStatus = this.FilteredIrbMarkers(this.irbsFilteredByStatus, this.irbsFilteredByStatusCount, this.enterCountryName, this.IRBstatusString);   //  AAA
            this.IRBmarkersFilteredByStatusCount = this.IRBmarkersFilteredByStatus.length; // Counting filtering result

          }

          }
          this.zoomingOnMarker(this.IRBmarkersFilteredByStatus, this.IRBmarkersFilteredByStatusCount, this.enterCountryName);
          return [this.irbsFilteredByStatus, this.irbsFilteredByStatusCount, this.IRBmarkersFilteredByStatus]
                            
          }

 //---------------------------------------------------------------------------------------------------//
       // Search by Country//

       searchCountryName = (aSite:ISiteSetup, i:number, enterCountryName:string): any => {
        
        if (this.enterCountryName==''){
         let a = this.siteSetups[i];
         
        if (a.country == this.enterCountryName){

         return this.enterCountryName;    //country Name, e.g. India
      } 
       
      }
      
      else 
      {let a = this.siteSetupsFilteredByStatus[i];
        
      if (a.country == this.enterCountryName) {

      return this.enterCountryName;    //country Name, e.g. India
     } 
     }
     }

     filterByCountry = (enterCountryName: string): void => {

      
     let i: number = 0;
     this.siteSetupsFilteredByCountry = []; 

      for (i = 0; i < this.siteSetupsCount; i++) {
  
        if (this.enterCountryName == '') {

         
          this.ClearMarkers(this.markersFiltered, this.siteSetupsCount);
   
          this.siteSetupsFilteredByCountry = this.siteSetupsFilteredByStatus;   
          this.siteSetupsFilteredByCountryCount = this.siteSetupsFilteredByCountry.length;    // Counting filtering result
          this.siteSetupsFilteredByStatusCount = this.siteSetupsFilteredByCountryCount;
   
          this.markersFiltered = this.markers;
          this.markersFiltered = this.FilteredMarkers(this.siteSetupsFilteredByStatus, this.siteSetupsFilteredByCountry, this.siteSetupsFilteredByCountryCount, this.enterCountryName);   //  AAA
          this.markersFilteredCount = this.markersFiltered.length; // Counting filtering result
        }
      
        else {
          
          this.ClearMarkers(this.markersFiltered, this.siteSetupsCount);
   
          this.siteSetupsFilteredByCountry = this.siteSetupsFilteredByStatus.filter((aSite, i) =>   
          this.searchCountryName(aSite, i, this.enterCountryName) == this.enterCountryName);   
          this.siteSetupsFilteredByCountryCount = this.siteSetupsFilteredByCountry.length; // Counting filtering result
          this.siteSetupsFilteredByStatusCount = this.siteSetupsFilteredByCountryCount;
  
          this.markersFiltered = this.markers;
          this.markersFiltered = this.FilteredMarkers(this.siteSetupsFilteredByStatus, this.siteSetupsFilteredByCountry, this.siteSetupsFilteredByStatusCount, this.enterCountryName);   //  AAA
          this.markersFilteredCount = this.markersFiltered.length; // Counting filtering result
           
        }
      }
    
      //console.log(this.siteSetupsFilteredByCountryCount)
      //console.log("filter by country", this.siteSetupsFilteredByCountry)
     
      this.zoomingOnMarker(this.markersFiltered, this.markersFilteredCount, this.enterCountryName);
      
      return;
    }

     // ----------------------------SEARCH IRB's By COUNTRY-----------------------------------//

     searchIrbByCountryName = (aIrb:IIrb, i:number, enterCountryName:string): any => {

       if (this.enterCountryName == ''){

         let b = this.irbs[i];
         
        if (b.country == this.enterCountryName ){

         return this.enterCountryName;    //country Name, e.g. India
      } 
       
      }
      
      else 
      {
       let b = this.irbsFilteredByStatus[i];
         
      if (b.country == this.enterCountryName  ){

      return this.enterCountryName;    //country Name, e.g. India
     } 
     }
     }

     filterIrbByCountry=(enterCountryName:string):void => {

       let i: number = 0;
       this.irbsFilteredByCountry = [];
       
       for (i=0; i<this.irbCount;i++) {

       if (this.enterCountryName == '') {
         
         this.ClearMarkers(this.markersFiltered, this.irbCount);

         this.irbsFilteredByCountry = this.irbsFilteredByStatus;
         this.irbsFilteredByCountryCount = this.irbsFilteredByCountry.length; // Counting filtering result
         this.irbsFilteredByStatusCount = this.irbsFilteredByCountryCount;

           this.markersFiltered = this.markers;
           this.markersFiltered = this.FilteredIrbMarkers(this.irbsFilteredByCountry,this.irbsFilteredByCountryCount,this.enterCountryName, this.IRBstatusString);   //  AAA
           this.markersFilteredCount = this.markersFiltered.length; // Counting filtering result
       }

       else

       {
         this.ClearMarkers(this.markersFiltered, this.siteSetupsCount);

         this.irbsFilteredByCountry = this.irbsFilteredByStatus.filter((aIrb,i)=>
         this.searchIrbByCountryName(aIrb, i, this.enterCountryName) == this.enterCountryName); 
         this.irbsFilteredByCountryCount = this.irbsFilteredByCountry.length; // Counting filtering result
         this.irbsFilteredByStatusCount = this.irbsFilteredByCountryCount;

         
          this.markersFiltered = this.markers;
          this.markersFiltered = this.FilteredIrbMarkers(this.irbsFilteredByCountry,this.irbsFilteredByCountryCount,this.enterCountryName, this.IRBstatusString);   //  AAA
          this.markersFilteredCount = this.markersFiltered.length; // Counting filtering result
          
          
       }

     }
       //console.log("filter by country", this.irbsFilteredByCountry)
      // console.log("latitude, longitude", this.markersPositionFiltered)
       
       this.zoomingOnMarker(this.markersFiltered, this.markersFilteredCount, this.enterCountryName);
       
       return ;
     }


//------------------End of Country Filtering-------------------------------------------------------------------//
//--------------------------------------------------------------------------------------------------------------//

        zoomingOnMarker = (markersFiltered: Marker[], markersFilteredCount: number, enterCountryName: string): void => {

          if (this.enterCountryName != '' && this.markersFilteredCount != 0) {
            let bullsEye=this.centerMarkers(markersFiltered);

            this.center = { lat: bullsEye.position.lat, lng: bullsEye.position.lng };
            this.zoom = 9;
          }
          else {
            this.minLatitudeForZooming = 10;
            this.maxLongitudeForZooming = -50;

            this.center = { lat: this.minLatitudeForZooming, lng: this.maxLongitudeForZooming };
            this.zoom = 2.7;
          }


        }
        centerMarkers = (filteredMarkers: Marker[]): Marker => {
          var mybullsEye = new Marker;
          mybullsEye.position = new PositionMarker;
          if (filteredMarkers.length>0)
          {
            mybullsEye.position.lat =
            (this.minLatitude(filteredMarkers).position.lat 
            +this.maxLatitude(filteredMarkers).position.lat)/2;

            mybullsEye.position.lng=(this.minLongitude(filteredMarkers).position.lng 
            +this.maxLongitude(filteredMarkers).position.lng)/2;

          }
          else{}

          return mybullsEye;

        }

        minLatitude = (filteredMarkers: Marker[]): Marker => {
          var minLatMarker: Marker;
          if (filteredMarkers.length != 0) {
            minLatMarker = filteredMarkers[0];
          }
          let i:number=0;
          for(i = 0; i< filteredMarkers.length;i++){
            if (filteredMarkers[i].position.lat<minLatMarker.position.lat)
            {minLatMarker=filteredMarkers[i]}
          }
          return minLatMarker;
        }

        maxLatitude = (filteredMarkers: Marker[]): Marker => {
          var maxLatMarker: Marker;
          if (filteredMarkers.length != 0) {
            maxLatMarker = filteredMarkers[0];
          }
          let i:number=0;
          for(i = 0; i< filteredMarkers.length;i++){
            if (filteredMarkers[i].position.lat>maxLatMarker.position.lat)
            {maxLatMarker=filteredMarkers[i]}
          }
          return maxLatMarker;
        }

        minLongitude = (filteredMarkers: Marker[]): Marker => {
          var minLngMarker: Marker;
          if (filteredMarkers.length != 0) {
            minLngMarker = filteredMarkers[0];
          }
          let i:number=0;
          for(i = 0; i< filteredMarkers.length;i++){
            if (filteredMarkers[i].position.lng<minLngMarker.position.lng)
            {minLngMarker = filteredMarkers[i]}
          }
          return minLngMarker;
        }

        maxLongitude = (filteredMarkers: Marker[]): Marker => {
          var maxLngMarker: Marker;
          if (filteredMarkers.length != 0) {
            maxLngMarker = filteredMarkers[0];
          }
          let i:number=0;
          for(i = 0; i< filteredMarkers.length;i++){
            if (filteredMarkers[i].position.lng>maxLngMarker.position.lng)
            {maxLngMarker = filteredMarkers[i]}
          }
          return maxLngMarker;
        }
  //---------------------------------------------------------------------------------------------------//
  
  //.................................Functions for Plotting Graphs....................................//

        SitesVectorGenerator = (aSite:ISiteSetup[]):any =>{

          this.SitesDataGraph(); 

                    this.studyEvaluationStatusGraphData = {
                    labels: ['Concept Evaluation', 'Concept Re-evaluation', 'Rejected (Concept)', 'Protocol Submission', 'Protocol Evaluation', 'Protocol Re-evaluation', 'Rejected (Protocol)', 'Contract Negociation', 'Document Check'],
                    datasets: [
                        {
                          label: 'Evaluation Status',

                          data: [
                          this.graphValues[0].graphValueY,
                          this.graphValues[1].graphValueY,
                          this.graphValues[2].graphValueY,
                          this.graphValues[3].graphValueY,
                          this.graphValues[4].graphValueY,
                          this.graphValues[5].graphValueY,
                          this.graphValues[6].graphValueY,
                          this.graphValues[7].graphValueY,
                          this.graphValues[8].graphValueY],

                            backgroundColor: ['#1922FB', '#1922FB', '#C7B8BA', '#008013', '#008013', '#008013', '#969594', '#FEA51D', '#801780' ],
                            hoverBackgroundColor: ['#1922FB', '#1922FB', '#C7B8BA', '#008013', '#008013', '#008013', '#969594', '#FEA51D', '#801780'],
                            borderWidth: 1

                        }
                    ]
                };
               
                this.options1 = {
                    plugins: {
                        legend: {
                           labels: {
                                color: '#000000'
                                
                            }
                        }
                    },
                    animation: {
                      duration: 1000,
                      easing: 'linear', 
                     
                    },

                    scales: {
                      y: {
                        title: {
                          display: true,
                          text: 'COUNT',
                          color: '#000000',
                          font: {
                            weight: 800
                          }
                        },
                          beginAtZero: true,
                          ticks: {
                              color: '#000000'
                          },
                         
                      },
                      x: {
                        title: {
                          display: true,
                          text: 'Evaluation Status',
                          color: '#000000',
                          font: {
                            weight: 800
                          }
                        },
                          
                         
                      },
                      
                  },
                  interaction: {
                    mode: 'nearest',
                    axis: 'x',
                    intersect: false
                },
              };

     //==================================================================//
                    
    this.studyOperationalStatusGraphData = {

      
      datasets: [
        {
          type: 'bar',
          label: 'Concept Evaluation Duration in Days',
          data: this.conceptOperationData.map(point => ({y: point[0],  x: point[1]})),      
          
          fill: false,
          borderColor: '#1922FB',
          backgroundColor: '#1922FB',
          hoverBackgroundColor: '#1922FB',
          
        },
        {
          type: 'bar',
          label: 'Protocol Evaluation Duration in Days',
          data: this.protocolOperationData.map(point => ({y: point[0],  x: point[1]})),      
          
          fill: false,
          borderColor: '#008013',
          backgroundColor: '#008013',
          hoverBackgroundColor: '#008013',
          
        },

        {
          type: 'bar',
          label: 'Contract Negociation Duration in Days',
          data: this.contractOperationData.map(point => ({y: point[0],  x: point[1]})),      
          
          fill: false,
          borderColor: '#FEA51D',
          backgroundColor: '#FEA51D',
          hoverBackgroundColor: '#FEA51D',
          
        }

    ]
    };

    this.options6 = {
      indexAxis: 'y',
      maintainAspectRatio: false,
                aspectRatio: 0.8,
                plugins: {
                    tooltips: {
                        mode: 'index',
                        intersect: false
                    },
                    legend: {
                        labels: {
                            color: '#000000'
                        }
                    }
      },
      scales: {
          x: {
            title: {
              display: true,
              text: 'Number of DAYS',
              color: '#000000',
              font: {
                weight: 800
              }
            },
            stacked: true,
              ticks: {
                  color: '#000000',
                  font: {
                      weight: 500
                  }
              },
              grid: {
                  color: '#000000',
                  drawBorder: false
              },
              barThickness: 10, // Set your desired bar thickness (in pixels)
          },
          y: {
            title: {
              display: true,
              text: 'Study Site Name',
              color: '#000000',
              font: {
                weight: 800
              }
            },
            stacked: true,
              ticks: {
                  color: '#000000'
              },
              grid: {
                  color: '#000000',
                  drawBorder: false
              }
          }
        },
        interaction: {
          mode: 'nearest',
          axis: 'y',
          intersect: false
      },
      };
            
                
    }

  
//=======================================================================================//

        IRBVectorGenerator = (aIrb:IIrb[]):any =>{
                  
               
          this.irbDataGraph();
          

                                  
                 this.irbReviewStatusGraphData = {
                  labels: ['Requested', 'Submitted', 'Pending Revision', 'Approved'],
                  datasets: [
                      {
                        data: [
                        this.irbGraphValues[0].graphValueY, 
                        this.irbGraphValues[1].graphValueY, 
                        this.irbGraphValues[2].graphValueY, 
                        this.irbGraphValues[3].graphValueY],

                          backgroundColor: ['#FF0000', '#1922FB', '#FFBF00', '#008013' ],
                          hoverBackgroundColor: ['#666666']
                      }
                      ]
              };
             
              this.options2 = {
                  plugins: {
                      legend: {
                          labels: {
                              usePointStyle: true,
                              
                          }
                      }
                  },
                  animation: {
                    duration: 1000,
                    easing: 'easeInCirc', 
                   
                  }
              };
          
              //////////////////////////////////////////////////////////

              this.irbOperationalStatusGraphData = {

  
                datasets: [
                  {
                    type: 'bar',
                    label: 'IRB - Request-to-Submission Duration',
                    data: this.irbsOperationData1.map(point => ({y: point[0],  x: point[1]})),      
                    
                    fill: false,
                    borderColor: '#FB8706',
                    backgroundColor: '#FB8706',
                    hoverBackgroundColor: '#E8153B'
                    
                  },
                  {
                    type: 'bar',
                    label: 'IRB - Submission-to-Evaluation Duration',
                    data: this.irbsOperationData2.map(point => ({y: point[0],  x: point[1]})),      
                    
                    fill: false,
                    borderColor: '#049E08',
                    backgroundColor: '#049E08',
                    hoverBackgroundColor: '#E8153B',
                    
                  },

                  {
                    type: 'bar',
                    label: 'Time Since IRB approval',
                    data: this.irbsOperationData2.map(point => ({y: point[0],  x: point[2]})),      
                    
                    fill: false,
                    borderColor: '#0A1EF0',
                    backgroundColor: '#0A1EF0',
                    hoverBackgroundColor: '#E8153B',
                    
                  }
                                
              ]
              };
              
              this.options7 = {
                indexAxis: 'y',
                responsive: true,
                maintainAspectRatio: false,
                          aspectRatio: 0.8,
                          plugins: {
                              tooltips: {
                                  mode: 'index',
                                  intersect: false
                              },
                              legend: {
                                  labels: {
                                      color: '#000000'
                                  }
                              }
                },
                scales: {
                    x: {
                      title: {
                        display: true,
                        text: 'Number of DAYS',
                        color: '#000000',
                        font: {
                          weight: 800
                        }
                      },
                     

                      stacked: true,
                        ticks: {
                            color: '#000000',
                            font: {
                                weight: 500
                            }
                        },
                        grid: {
                            color: '#000000',
                            drawBorder: false
                        },
                        barThickness: 10, // Set the desired bar thickness (in pixels)
                    },
                    y: {
                      title: {
                        display: true,
                        text: 'IRB Site Name',
                        color: '#000000',
                        font: {
                          weight: 800
                        }
                      },
                      stacked: true,
                        ticks: {
                            color: '#000000'
                        },
                        grid: {
                            color: '#000000',
                            drawBorder: false
                        }
                    }
                  },
                  interaction: {
                    mode: 'nearest',
                    axis: 'y',
                    intersect: false
                },
                };


                
                return;
                     
                }

                 //////////////////////////////////////////////////////////
          AEOccurenceVectorGenerator = (AECountsForPlot:any[]):any =>{

              this.aEDistributionGraphData = {

  
                datasets: [
                  {
                    type: 'bar',
                    label: 'Total Counts per AE',
                    data: this.AECountsForPlot.map(point => ({y: point[0],  x: point[1]})),      
                    
                    fill: false,
                    borderColor: '#8B0000',
                    backgroundColor: '#8B0000',
                    hoverBackgroundColor: '#000000'
                    
                  }
                                 
              ]
              };

              this.options8 = {

                plugins: {
                  datalabels: {
                    anchor: 'top',
                    align: 'center',
                    display: true,
                    font: {
                      size:12,
                      weight: 'bold'
                    }
                  }
                },

                indexAxis: 'y',
                responsive: true,
                maintainAspectRatio: false,
                          aspectRatio: 0.8,
                         
                scales: {
                    x: {
                      beginAtZero: true,
                      title: {
                        display: true,
                        text: 'AE Counts',
                        color: '#000000',
                        font: {
                          weight: 800
                        }
                      },
                      barPercentage: 0.2,   // to control the bar (in the bar graph) width
                        ticks: {
                            color: '#000000',
                            font: {
                                weight: 500
                            },
                            stepSize: 20,
                        },
                        
                        grid: {
                            color: '#000000',
                            drawBorder: false
                        },
                        
                    },
                    y: {
                      title: {
                        display: true,
                        text: 'AE Listing @ Site',
                        color: '#000000',
                        font: {
                          weight: 800
                        }
                      },
                      //stacked: true,
                        ticks: {
                            color: '#000000'
                        },
                        grid: {
                            color: '#000000',
                            drawBorder: false
                        }
                    }
                  }
                };


              
                  
          return;
                     
                }

          AESatSiteVectorGenerator = (aeRecordedAtSiteAndCounts:any[]):any =>{

                     
                  this.aeRecordedAtSiteGraphData = {
    
      
                    datasets: [
                      {
                        type: 'bar',
                        label: 'Total Counts per AE @ Site#',
                        data: this.aeRecordedAtSiteAndCounts.map(point => ({y: point[0],  x: point[1]})),      
                        
                        fill: false,
                        borderColor: '#8B0000',
                        backgroundColor: '#8B0000',
                        hoverBackgroundColor: '#000000'
                        
                      }
                                     
                  ]
                  };
                  
                  this.options8 = {
                    indexAxis: 'y',
                    responsive: true,
                    maintainAspectRatio: false,
                              aspectRatio: 0.8,
                              plugins: {
                                  tooltips: {
                                      mode: 'index',
                                      intersect: false
                                  },
                                  legend: {
                                      labels: {
                                          color: '#000000'
                                      }
                                  }
                    },
                    scales: {
                        x: {
                          beginAtZero: true,
                          title: {
                            display: true,
                            text: 'AE Counts',
                            color: '#000000',
                            font: {
                              weight: 800
                            }
                          },
                          //stacked: true,
                            ticks: {
                                color: '#000000',
                                font: {
                                    weight: 500
                                },
                                stepSize: 20,
                            },
                            
                            grid: {
                                color: '#000000',
                                drawBorder: false
                            },
                            
                        },
                        y: {
                          title: {
                            display: true,
                            text: 'AE Listing @ Site',
                            color: '#000000',
                            font: {
                              weight: 800
                            }
                          },
                          //stacked: true,
                            ticks: {
                                color: '#000000'
                            },
                            grid: {
                                color: '#000000',
                                drawBorder: false
                            }
                        }
                      },
                      interaction: {
                        mode: 'nearest',
                        axis: 'y',
                        intersect: false
                    },
                      
                    }; 
    
                    
                  
                      
              return;
                         
                    }

          AEOccurenceBubbleVectorGenerator = (AETreatmentListforMapForBublePlot:any[]):any =>{

                      this.aEDistributionBubleGraphData = {
        
          
                        datasets: [
                          {
                            type: 'bar',
                            label: 'Number Of AEs At This Site',
                            data: this.AETreatmentListforMapForBublePlot.map(point => ({x: point[0], y: point[1]})),      
                            
                            fill: false,
                            borderColor: '#8B0000',
                            backgroundColor: '#8B0000',
                            hoverBackgroundColor: '#000000'
                            
                          }
                                       
                      ]
                      };
        
                      this.options9 = {
        
                        plugins: {
                          datalabels: {
                            anchor: 'top',
                            align: 'center',
                            display: true,
                            font: {
                              size:12,
                              weight: 'bold'
                            }
                          }
                        },
        
                        indexAxis: 'x',
                        responsive: true,
                        maintainAspectRatio: false,
                                  aspectRatio: 0.8,
                                 /* plugins: {
                                      tooltips: {
                                          mode: 'index',
                                          intersect: false
                                      },
                                      legend: {
                                          labels: {
                                              color: '#000000'
                                          }
                                      }
                        },*/
                        scales: {
                            x: {
                              beginAtZero: true,
                              title: {
                                display: true,
                                text: 'Site Name',
                                color: '#000000',
                                font: {
                                  weight: 800
                                }
                              },
                              
                                ticks: {
                                    color: '#000000',
                                    font: {
                                        weight: 500
                                    },
                                    stepSize: 20,
                                },
                                
                                grid: {
                                    color: '#000000',
                                    drawBorder: false
                                }
                            },
                            y: {
                              title: {
                                display: true,
                                text: 'AE Listing ',
                                color: '#000000',
                                font: {
                                  weight: 800
                                }
                              },
                             
                                ticks: {
                                    color: '#000000'
                                },
                                grid: {
                                    color: '#000000',
                                    drawBorder: false
                                }
                            }
                          },
                          interaction: {
                            mode: 'nearest',
                            axis: 'x',
                            intersect: false
                        },
                        };
        
        
                      
                          
                  return;
                             
                        }

     //=================================================================================================//

          PatientVectorGenerator = (aPatient:IPatient[]):void =>{
                  
          this.patientStatusDataGraph();
          
           this.irbPatientEnrollmentGraphData = {
                    labels: ['Need Screening', 'Waiting for Screening Results', 'Selected After Screening - Provide Consent Form', 'Not Selected After Screening Results Review','Awaiting Consent Signature', 'No Consent', 'Enrolled'],
                    datasets: [
                        {
                          data: [
                          this.patientStatusGraphValues[0].graphValueY, 
                          this.patientStatusGraphValues[1].graphValueY, 
                          this.patientStatusGraphValues[2].graphValueY, 
                          this.patientStatusGraphValues[3].graphValueY,
                          this.patientStatusGraphValues[4].graphValueY,
                          this.patientStatusGraphValues[5].graphValueY,
                          this.patientStatusGraphValues[6].graphValueY],

                          backgroundColor: ['#FFFF00', '#1922FB', '#cce6ff', '#D0CECE', '#FFBF00', '#041b50', '#008013' ],
                          hoverBackgroundColor: ['#FFFF00', '#1922FB', '#cce6ff', '#D0CECE', '#FFBF00', '#041b50', '#008013']
                        }
                    ]
                };
               
                this.options3 = {
                    plugins: {
                        legend: {
                            labels: {
                                usePointStyle: true,
                                
                            }
                        }
                    },
                    animation: {
                      duration: 1000,
                      easing: 'easeInCirc', 
                     
                    }
                };
                                               
                 }

         //================================================================================//        

           PatientTreatmentVectorGenerator = (patientId:number):void =>{

                  this.patientTreatmentGraphValues = [];
                  this.value3 = 0;
                  this.value4 = 0;
                  
                  if(this.treatmentAuditCount !=0){

                    this.patientTreatmentGraphValue  = new GraphValue();
                    console.log("siteID = ",this.currentIrbId,"  All treatments for PatientId#", patientId, this.treatmentList);
                   
                                    
                   
                   console.log("First Treatment Info",[this.treatmentList.reduce((r, o) => o.treatmentDate < r.treatmentDate ? o : r)]);  //provide all info about the 1st treatment
                   this.firstTreatmentDate = (this.treatmentList.reduce((r, o) => o.treatmentDate < r.treatmentDate ? o : r)).treatmentDate;// provide the date
                 
                   console.log("First Treatment Date: ", this.firstTreatmentDate, "for Patient ID =", patientId);
                   
                  this.firstTreatmentDatesArray.push(this.firstTreatmentDate);   
                  this.patientsInTreatmentID.push(patientId);
                  this.value3 = this.patientsInTreatmentID.length;
                  
                  //Count number of AE  treatments  //////////////
                    
                  this.AESelection(this.treatmentList);
                  this.AECountAtSite(this.allTreatmentsListatSite);

                    //console.log("AE Count temp = ",this.AECount);
                    if (this.AECount !=0) {this.patientsWithAECounts = this.patientsWithAECounts +1} //Counting the number of patients showing singns of AEs
                    this.AECounts = this.AECounts + this.AECount; //Counting the number of AE treatment visits
                    
                   // console.log("AE Treatment Visits",this.AECounts);
                   // console.log("Patients With AE Counts",this.patientsWithAECounts);

                    /////////////////END///////////////////////

                    /////////////////Count of Number of Patient on a Specific Batch of Material  //////
                    //  this.batchSelection(this.treatmentList, this.availableBatchNumbersAtSite)

                   ///////////////////END///////////////////////
                 
                this.patientTreatmentGraphValue[0]= this.firstTreatmentDatesArray.length; 
                this.patientTreatmentGraphValue[1] = (this.enrolledPatientIdArray.length - this.firstTreatmentDatesArray.length)

                   }

               else{

                    this.patientTreatmentGraphValue  = new GraphValue();
                    this.firstTreatmentDate = new Date();
      //console.log("NO listed Treatments for ", patientId);
                    let rowIndex:number = 0;
      //console.log(this.patientList);
                  rowIndex = this.patientList.findIndex(row => row.id === patientId);  //to find the row's index associated with "this.currenIrbId"

                  this.patientsAwaitingFirstTreatmentID.push(this.patientList[rowIndex].patientNumber);
                                                                      
                   }

                this.patientTreatmentGraphValues = [this.patientTreatmentGraphValue[0], this.patientTreatmentGraphValue[1]];                            
                           
                this.value3 = this.firstTreatmentDatesArray.length;
                this.value4 = this.patientsAwaitingFirstTreatmentID.length;       
                                                    
                console.log( "Number of Patients In Treatment IDs", this.patientsInTreatmentID, "First Treatment Dates", this.firstTreatmentDatesArray); 
                          
                     
                  //console.log("Array of Enrolled Patients Ids", this.enrolledPatientIdArray);                                               
                                                      
                  //console.log("Total Number of Enrolled Patients At This Selected Site =", this.enrolledPatientIdArray.length);
                  //console.log("Total Number of Patients in Treatment At This Selected Site =", this.firstTreatmentDatesArray.length);
                  //console.log( "Number of Patients Awaiting First Treatment", this.patientTreatmentGraphValues[1]); 
                  //console.log( "Patients Awaiting First Treatment IDs", this.patientsAwaitingFirstTreatmentID); 
                  // console.log(this.patientTreatmentGraphValues);  
               
                //////////////////Data for chart.JS plot//////////////

                this.irbPatientTreatmentGraphData = {
                  labels: ['Number of Enrolled Patients & Receiving Treatments', 'Number of Enrolled Patient & Awaiting 1st Treatment'],
                  datasets: [
                      {
                        data: [
                          this.patientTreatmentGraphValue[0], 
                          this.patientTreatmentGraphValue[1]],

                        backgroundColor: ['#008013', '#FEA51D' ],
                        hoverBackgroundColor: ['#008013', '#FEA51D']
                      }
                  ]
              };
             
              this.options4 = {
                  plugins: {
                      legend: {
                          labels: {
                              usePointStyle: true,
                              
                          }
                      }
                  },
                  animation: {
                    duration: 1000,
                    easing: 'easeInCirc', 
                   
                  }
              };
                
          return;
                                               
                 }

      //==================================================================================================//
            
                
                 PatientEnrollmentVectorGenerator = (aPatient:IPatient[]):void =>{
                
                  
                 this.enrollmentPlotTimeSerie = [];
                 this.summaryEnrollmentData = [];
                 this.knobEnrollmentMax = 0;
                 this.value2 = 0; 
                 
                 //this.lineChartOptions.title.text= 'Patients Enrollment Curve @ /IRB Site #'+ this.currentIrbId.toString();           
                  
                 this.patientEnrollmentDataGraph();
                 this.patientTreatmentData();

                                
                let j:number = 0;
                
                for (j = 0; j < this.patientEnrollmentGraphValues.length; j++)
                {
                             
                this.enrollmentPlotTimeSerie.push([this.patientEnrollmentGraphValues[j].graphValueX, this.patientEnrollmentGraphValues[j].graphValueY]);
                
               }
               console.log(this.enrollmentPlotTimeSerie);

              //////////////////////////////////////////Chart.JS///////////////////////////////////////////////


                   this. patientEnrollmentOverTimeGraphData= {
                    
                    datasets: [
                        {
                          label: 'Patient Enrollment Number',
                          data: this.enrollmentPlotTimeSerie.map(point => ({x: point[0],  y: point[1]})),      
                          
                          fill: false,
                          borderColor: '#008013',
                          hoverBackgroundColor: '#008013',
                          tension: 0.0
                        }
                    ]
                };
               
                this.options5 = {
                  scales: {
                    x: {
                      type: 'time',
                      display: true,
                      title: {
                        display: true,
                        text: 'Enrollment Dates',
                        font: {
                          weight: 800
                        }
                      }
                    },
                    y: {
                      display: true,
                      title: {
                        display: true,
                        text: 'Total Enrollment - n of N',
                        font: {
                          weight: 800
                        }
                      }
                    }
                  }
                };
                                                       
          return ;
                                               
                 }
          
          //::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::://
          
        SitesDataGraph = ():any=>{
              var myStatusString=["Concept Evaluation", "Concept Re-evaluation", "Rejected (Concept)", 
                                 "Protocol Submission", "Protocol Evaluation", "Protocol Re-evaluation", 
                                 "Rejected (Protocol)", "Contract Negotiation", "Documents Check"]   
                    
                  let index = Number(this.statusNumber);
                  this.statusString = myStatusString[index];
          
                  this.graphValues = [];
          
                  for (index=0; index < 9;index++) 
                 
                  {
                  this.graphValue = new GraphValue();
                     
                  this.statusString = myStatusString[index];
                  this.graphValue.Index = index;
                  this.graphValue.graphValueX = this.statusString;
                  
                  this.siteSetupsFiltered = this.siteSetups.filter((aSite,i)=>
                  this.EvaluationStatus(aSite,i) == this.statusString ); // Think of adding another condition here
                  this.siteSetupsFilteredCountforGraph = this.siteSetupsFiltered.length; // Counting filtering result   
                    
                  this.graphValue.graphValueY = this.siteSetupsFilteredCountforGraph;
                      
                  this.graphValues.push(this.graphValue);
                  }
                   //console.log(this.graphValues);
                        
                  return [this.graphValues]
                  
                  }  

//........................................................................................................................//
// For Patients status data filtering

patientStatusDataGraph= ():any=>{
  var myStatusString=["Needs Screening", "Waiting For Screening Results",
                      "Selected After Screening - Provide Consent Form"," Not selected for Study - Failed Screening",
                     "Awaiting consent signature","No consent","Enrolled"]


                  let index = Number(this.statusNumber);
                  this.statusString = myStatusString[index];
          
                  this.patientStatusGraphValues = [];
          
                  for (index=0; index < 7; index++) 
                 
                  {
                  this.patientStatusGraphValue  = new GraphValue();
                     
                  this.statusString=myStatusString[index];
                  this.patientStatusGraphValue.Index = index;
                  this.patientStatusGraphValue.graphValueX = this.statusString;
                  
                  this.patientListFiltered = this.patientList.filter( aPatient=>
                    this.patientStatus(aPatient) == this.statusString);
                    this.patientListFilteredCountforGraph = this.patientListFiltered.length; //To count the result of filtering  
                    
                  this.patientStatusGraphValue.graphValueY=this.patientListFilteredCountforGraph;
                      
                  this.patientStatusGraphValues.push(this.patientStatusGraphValue);
                  }
                  // console.log(this.patientStatusGraphValues);
                        
                  return [this.patientStatusGraphValues]
                  
                  }

        patientStatus = (aPatient: Patient): string => {
          if (aPatient.consentSignedDate!=undefined && aPatient.consentSignedDate!=null){
            if(aPatient.consent!=undefined && aPatient.consent!=null && aPatient.consent==true){
              return "Enrolled";
            }
            else{
              return "No consent";
            }
          }
          if (aPatient.consentGivenDate!=undefined && aPatient.consentGivenDate!=null){
            return "Awaiting consent signature";
          }
          if (aPatient.screeningResultsDate!=undefined && aPatient.screeningResultsDate!=null){
            if(aPatient.selected!=undefined && aPatient.selected!=null){
              return aPatient.selected?"Selected After Screening - Provide Consent Form":" Not selected for Study - Failed Screening" ;
            }
          }
          if (aPatient.screeningDate!=undefined && aPatient.screeningDate!=null){
            return "Waiting For Screening Results"
          }
          return "Needs Screening";
        }
          
//................................Patient Enrollment Graph....................................................................//

patientEnrollmentDataGraph = ():any=>{
  var myStatusString=["Enrolled", "Awaiting consent signature"]

  //STEP #1 - Filtering out the patient in treatment
                    
                     let index=Number(this.statusNumber);
                     //this.statusString=myStatusString[index];
                     this.patientEnrollmentGraphValues = [];
                     this.patientNumberAwaitingConsentSignature = [];
                     this.orderedDates = [];
                     this.enrolledPatientIdArray = [];
                     this.knobEnrollmentMax = 0;
                     this.value1 = 0;
                     this.value2 = 0; 
                     this.FPI = new Date();
                     this.summaryEnrollmentData = [];
                     
                     for (index = 0; index < 2; index++) 
         {
                    
                     this.statusString = myStatusString[index];

                     this.patientListFiltered = this.patientList.filter(aPatient => this.patientStatus(aPatient) == this.statusString);
                   
             console.log(this.statusString, this.patientListFiltered);
                    
            // -----------------------------End STEP #1  --------------------------------------//

            //STEP #2 - Enrollment date (date of signing the consent form) extraction
              if (this.statusString == "Enrolled" && this.patientListFiltered.length !=0 )
              {
                                               
                     for (let i = 0; i< this.patientListFiltered.length; i++)
                    {
                      
                      if(this.patientListFiltered[i].consent != null && this.patientListFiltered[i].consent == true)    
                      {
                        let date = new Date(this.patientListFiltered[i].consentSignedDate);
                        let truncatedDate = date.toISOString().split('T')[0]; 

                        this.orderedDates.push(truncatedDate);  //Array of consent form returned dates for enrolled patients (e.g consent form signature date)
                        this.enrolledPatientIdArray.push(this.patientListFiltered[i].id);  // Array of enrolled patients' Ids

                        //console.log(truncatedDate, this.patientListFiltered[i].consentSignedDate);
                        //console.log('PAtient ID and Consent signed Date',[this.patientListFiltered[i].id, this.patientListFiltered[i].consentSignedDate])
                       
                      } 
                      
                    } 
                     
                     this.value1= this.enrolledPatientIdArray.length; //Number of consented patients
                      

                        console.log("Patients IDs and Related Enrollment Dates", this.enrolledPatientIdArray, this.orderedDates);
              
                        this.orderedDates.sort((a, b) => {return a.localeCompare(b);}); //To sort out the array from the earliest to the latest
                        
                        this.FPI = this.orderedDates.reduce((prev, curr) => { return prev < curr ? prev : curr; });  //Find the earliest date (the FPI)

                        
                        //console.log('FPI =', this.FPI);

                    for (let i = 0; i< this.patientListFiltered.length; i++)

                    {
                     this.patientEnrollmentGraphValue = new GraphValue();
        
                     this.patientEnrollmentGraphValue.graphValueX = this.orderedDates[i];
                     this.patientEnrollmentGraphValue.graphValueY = i+1; 
                     this.patientEnrollmentGraphValue.Index = i;

                     this.patientEnrollmentGraphValues.push(this.patientEnrollmentGraphValue);

                      //console.log(this.patientEnrollmentGraphValues)
                  
                    } 
              }
                     
            //for later decisison tool to provide recommentations to PI Still part of STEP #2
                    
                    if (this.statusString == "Awaiting consent signature" && this.patientListFiltered.length !=0)
                    {
                           
                        for (let i=0; i< this.patientListFiltered.length; i++)
                        
                        {
                          this.patientNumberAwaitingConsentSignature.push(this.patientListFiltered[i].patientNumber);
                        }
                        
                    }
                  
        }
            {
                  let rowIndex:number;

                  if(this.marker.markerType = "irb")
              {

                 rowIndex = this.irbs.findIndex(row => row.id === this.currentIrbId);  //to find the row's index associated with "this.currenIrbId"
                 this.knobEnrollmentMax = this.irbs[rowIndex].expectedNumberOfPatients;
                 //console.log("Expected Patients at site#",this.currentIrbId, "=", this.knobEnrollmentMax);
              }
              else{
                this.value1 = 0;
                this.value2 = 0;
              }
              
            }

                 this.value2 = this.patientNumberAwaitingConsentSignature.length;
                 this.summaryEnrollmentData = [this.value1];  // For the summary table

                // console.log("Plot Coordonnees",this.patientEnrollmentGraphValues);
                // console.log("Array of Patient Numbers to follow-up (Not Yet Enrolled)",this.patientNumberAwaitingConsentSignature);
                // console.log(this.summaryEnrollmentData);

          return this.patientEnrollmentGraphValues, this.patientNumberAwaitingConsentSignature, 
                 this.enrolledPatientIdArray, this.summaryEnrollmentData, this.value2 
                                      
  }

//----------------------------------------------------------//

       patientTreatmentData =():any =>{
                   
          this.firstTreatmentDatesArray = [];
          this.allTreatmentsListatSite = [];
          this.aeTreatmentListAtSite = [];
          this.patientsInTreatmentID = [];
          this.patientsAwaitingFirstTreatmentID = [];
          this.value3 = 0;
          this.value4 = 0;  
          this.AECount = 0;
          this.AECounts = 0;
          this.patientsWithAECounts = 0;
          //this.allTreatmentsForAE = [];
          this.aeRecordedAtSiteAndCounts = [];
        
            for (let i = 0; i < this.enrolledPatientIdArray.length; i++) {

             this.getTreatmentListForGraph(this.enrolledPatientIdArray[i])

            }
            
             this.treatmentList$.subscribe(treatmentList => {
  
                  // console.log('TreatmentList', this.treatmentList);
                  if(treatmentList.length !=0){

                    this.allTreatmentsListatSite.push(...this.treatmentList); 
                    
                    }
                  });
                       
          console.log('All Treatments at Site',this.allTreatmentsListatSite);
          
          return this.allTreatmentsListatSite
        }
                                                                                     
         AESelection = (treatment: ITreatment[]): any =>{
               
          console.log('All treatments',treatment);
                this.treatmentListFiltered = [];
                this.AECount = 0;

                {
                  this.treatmentListFiltered = this.treatmentList.filter(aTreatment=>
                    aTreatment.treatmentType == 1);
                }
                
                this.AECount = this.treatmentListFiltered.length; //Counting filtered results

                console.log('AE Count',this.AECount);

                return this.AECount
              }

              AECountAtSite = (allTreatmentsListatSite: ITreatment[]): any =>{
               
                      //console.log('All treatments',allTreatmentsListatSite);
                      this.aeTreatmentList = [];
                      this.aeRecordedAtSite = [];
                      this.distinctAEListAtSite = [];
                      this.aeRecordedAtSiteAndCounts = [];
                      
      
                      {
                        this.aeTreatmentList = this.allTreatmentsListatSite.filter(aTreatment=>
                          aTreatment.treatmentType == 1);
                      }
                      
                         
                      let aeRecordedAtSiteAndCountsSet = new Set();
                      let i:number = 0;
      
                      for(i = 0; i < this.aeTreatmentList.length; i++)
                      {
                        this.aeRecordedAtSite.push(this.aeTreatmentList[i].aeSymptomsSelection)
                      }
      
                      this.distinctAEListAtSite = Array.from(new Set(this.aeRecordedAtSite));  // removal of duplicate listing of AEs at site
      
                      
                      for(i = 0; i < this.distinctAEListAtSite.length; i++){

                        let count:number = 0;
                        if(this.distinctAEListAtSite[i] != null){
      
                        count = (this.aeRecordedAtSite.filter(word => word === this.distinctAEListAtSite[i])).length;
              
                        aeRecordedAtSiteAndCountsSet.add([this.distinctAEListAtSite[i], count]);
      
                        }
      
                      }

                      this.aeRecordedAtSiteAndCounts = Array.from(aeRecordedAtSiteAndCountsSet);
      
                     /* console.log("AEs Filtered list",this.aeTreatmentList);
                      console.log('Number of AE Treatments',this.aeTreatmentList.length);
                      console.log('Disclosed AEs List at Site',this.aeRecordedAtSite);
                      console.log('Disclosed List of Distinct AEs at Site',this.distinctAEListAtSite);
                      console.log('Disclosed AEs List and their Counts at Site',this.aeRecordedAtSiteAndCounts);*/

                      this.AESatSiteVectorGenerator([this.aeRecordedAtSiteAndCounts])
      
                      return  this.aeRecordedAtSite, [this.aeRecordedAtSiteAndCounts]
                    }

                   

//----------------------------------------------------------------------------------------------------------------------------//

            /*  listingAllTreatmentsForAE = (treatmentsForAE: ITreatment[]): any =>{
       
   console.log(this.treatmentsForAE);
    

                if (this.treatmentsForAE.length !=0){
              
                  
                  this.allTreatmentsForAE.push(this.treatmentsForAE);
             
               }
                
          console.log("All Treatments for AE", this.currentIrbId, this.allTreatmentsForAE);
           
        
               return this.allTreatmentsForAE
          }*/

       

       /*batchSelection = (treatment: ITreatment[], selectedbatch:string): number =>{
               
                this.patientsOnSelectedBatchCount = 0;
                
                {
                  this.treatmentListFiltered = this.treatmentList.filter(aTreatment=>
                    aTreatment.vialId == 'selectedbatch');
                }
                console.log("Number of Patients Using BATCH:'",this.availableBatchNumbersAtSite,"'",this.treatmentListFiltered);
                this.patientsOnSelectedBatchCount = this.treatmentListFiltered.length; //Counting filtering results

               return this.patientsOnSelectedBatchCount
               
              }*/

//----------------------------------------------------------------------------------------------------//
irbDataGraph= ():any=>{
  var myStatusString=["Requested", "Submitted", "Pending Revision", "Approved"]

  

                  let index=Number(this.statusNumber);
                  this.statusString=myStatusString[index];
                  this.irbGraphValues = [];
          
                 for (index = 0; index < 4; index++) 
                 
                  {
                  this.irbGraphvalue  = new GraphValue();
                     
                  this.statusString=myStatusString[index];
                  this.irbGraphvalue.Index = index;
                  this.irbGraphvalue.graphValueX = this.statusString;
                  
                  this.irbListFiltered=this.irbs.filter( aIrb =>
                  this.irbApprovalStatus(aIrb) == this.statusString);
                  this.irbListFilteredCountforGraph = this.irbListFiltered.length; //To count the result of filtering  
                    
                  this.irbGraphvalue.graphValueY = this.irbListFilteredCountforGraph;
                      
                  this.irbGraphValues.push(this.irbGraphvalue);
            
                  }
                   //console.log(this.irbGraphValues);
                        
                  return [this.irbGraphValues]
                  
                }

irbApprovalStatus = (aIrb: Irb): string => {
  if (aIrb.reviewDate != undefined && aIrb.reviewDate != null) {


    if (aIrb.outcome == true) {
      return "Approved";
    }
    if (aIrb.outcome == false) {
      return "Pending Revision";
    }
  }
  if (aIrb.submissionDate != undefined && aIrb.submissionDate != null) {
    return "Submitted";
  }
  return "Requested"

}

//-------------------------------------------------------------------------------------------------------------------//
      

//------------------End of IRBDataGraph and Related-------------------------------------------------------------------//
      

  getInfoview = (siteId: number, j: number): void => {
    this.administrationService.getAnalyticsDataSummary(this.authService.authorizationHeaderValue, siteId)
      .subscribe(results => {
        var analyticsDataSummary = results;
        var theString = '<div><b>Study Name</b> = '  + analyticsDataSummary.studyName + '</div>' 
              + '<div><b>Product Under Investigation</b> = ' + analyticsDataSummary.requestedProduct + '</div>' 
              + '<div><b>Coordinating Site</b> = <b>' + analyticsDataSummary.siteName + '</b></div>' 
              + '<div><b>PI Name (Coordinator)</b> = <b>' + analyticsDataSummary.piName + '</b></div>'
              +'<div><b>Study Type</b> = ' + analyticsDataSummary.studyTypeDescription + '</div>'
              + '<div><b>Disease Area</b> = ' + analyticsDataSummary.diseaseArea +'</div>'
              + '<div><b>Protocol Version</b> = ' + analyticsDataSummary.protocolVersion  + '</div>'
              + '<div><b> MSL (Associated with the Site) </b>= '+analyticsDataSummary.msl+'</div>'
              + '<div><b>Number of Patients in the Study</b> = ' + analyticsDataSummary.numberOfPatients.toString() + '/<b>' +  analyticsDataSummary.expectedNumberOfPatients.toString() +'</b></div>'   
              + '<div><b>FPI </b> = ' + analyticsDataSummary.firstPatientIn +'</div>';
       
              this.summaryData[j] = theString;
      })
      }
  

      findIrbById =(irbId :number):IIrb =>{
//console.log(this.irbs)
//console.log(irbId, this.irbs.filter(thisIrb => thisIrb.id==irbId)[0], this.irbs.filter(thisIrb => thisIrb.id==irbId))

        return this.irbs.filter(thisIrb => thisIrb.id==irbId)[0];
        
        }

     getIrbInfoview = (irbId: number, j: number): void => {
      this.currentIrbId = irbId;
      var thisIrb = this.findIrbById(irbId);
      console.log('find Irb by Id ' + thisIrb, j);
      var thisSiteId= thisIrb.site.id;
      //get site analytics
      this.administrationService.getAnalyticsDataSummary(this.authService.authorizationHeaderValue, thisSiteId)
      .subscribe(results => {
        var siteAnalyticsDataSummary=results;
      this.administrationService.getIrbAnalyticsDataSummary(this.authService.authorizationHeaderValue, irbId)
        .subscribe(results => {
          var analyticsDataSummary = results;
          // console.log (results);
          var theString = '<div><b>Study Name</b> = ' + siteAnalyticsDataSummary.studyName + '</div>'
            + '<div><b>Product Under Investigation</b> = ' + siteAnalyticsDataSummary.requestedProduct + '</div>'
            + '<div><b>Coordinating Site</b> = ' + siteAnalyticsDataSummary.siteName + '</div>'
            + '<div><b>PI Name (Coordinator)</b> = ' + siteAnalyticsDataSummary.piName + '</div>'
            + '<div><b>Study Type </b>= ' + siteAnalyticsDataSummary.studyTypeDescription + '</div>'
            + '<div><b>Disease Area </b>= ' + siteAnalyticsDataSummary.diseaseArea + '</div>'
            + '<div><b>Protocol Version </b>= ' + analyticsDataSummary.protocolVersion + '</div>'
            + '<div><b>Number of Patients in the Study</b> = ' + siteAnalyticsDataSummary.numberOfPatients.toString() + '/ <b>' + analyticsDataSummary.expectedNumberOfPatientsForTheStudy.toString() + '</b></div>'   // Done on 12/14/2023
            + '<div><b>Study Total Budget</b> = $' + siteAnalyticsDataSummary.siteTotalBudget + '</div>'
            + '<div><b>List of Adverse Events for all sites in the study</b> = ' + this.getAdverseEventListForMap(siteAnalyticsDataSummary.adverseEventsList) + '</div>'
  
            + '<div><b>  ---------------IRB Site Info---------------' + '</div>'
            + '<div><b>Irb Site </b>= <b>' + analyticsDataSummary.irbSiteName + '</b></div>'
            + '<div><b>Irb (Local PI) </b>= ' + analyticsDataSummary.irbPiName + '</div>'
            + '<div><b>Irb Local MSL </b>= ' + analyticsDataSummary.irbMsl + '</div>'
            + '<div><b>Number of Patients  @ this Site</b> = ' + analyticsDataSummary.numberOfPatients.toString() + '/ <b>' + analyticsDataSummary.expectedNumberOfPatients.toString() + '</b></div>'
            + '<div><b>Inventory </b>= ' + analyticsDataSummary.inventory + '</div>'
            + '<div><b>IRB Protocol Version </b>= ' + analyticsDataSummary.irbProtocolVersion + '</div>'
            + '<div><b>IRB Outcome </b>= ' + analyticsDataSummary.irbOutcome + '</div>'
            + '<div><b>FPI</b> = ' + analyticsDataSummary.firstPatientIn + '</div>'
            
            + '<div><b>  ---------------Treatments Adverse Events---------------' + '</b></div>'
            + '<div><b>List of Adverse Events @ This Site</b> : ' + this.getAdverseEventListForMap(analyticsDataSummary.adverseEventsList) + '</div>'   ;;
  
          this.summaryData[j] = theString;
  
        })} );
    }

    

   /*getAllIrbs = (): void => {
    this.administrationService.getAllIrbs(this.authService.authorizationHeaderValue)
      .pipe(finalize(() => {
        this.busy = false;
        this.hideSpinner();
      }))
      .subscribe(
        results => {
         // this.irbs = results;
          
          this.irbs= results.filter(aIrb => aIrb.piEmail==this.userName || aIrb.site.piEmail== this.userName );//  to be revisited for selecting IRBs specific to a PI
          this.irbCount = this.irbs.length;

          this.distinctIrbVariables(); 

          let i: number = 0;
          
          for (i = 0; i < this.irbCount; i++) {
            if (this.irbs[i].longitude != null && this.irbs[i].latitude != null) {
              this.pushIrbMarker(i);
            }
            else {
                console.log(i, this.irbs[i]);
              this.getLongLat(i);
            }

          } 
          
        this.irbsOperationalAnalytics(this.irbs, this.irbCount );
        this.IRBVectorGenerator (this.irbs);
        
         
        });

  }*/

  async getAllIrbs(): Promise<any> {
    try {
      const results = await this.administrationService.getAllIrbs(this.authService.authorizationHeaderValue).toPromise();
     // this.irbs = results;
      this.irbs = results.filter((aIrb) => aIrb.piEmail === this.userName || aIrb.site.piEmail === this.userName);
      this.irbCount = this.irbs.length;
  
      this.distinctIrbVariables();
      
      for (let i = 0; i < this.irbCount; i++) {
        if (this.irbs[i].longitude !== null && this.irbs[i].latitude !== null) {
          this.pushIrbMarker(i);
        } else {
          console.log(i, this.irbs[i]);
          await this.getLongLat(i);
        }
      }
  
      this.irbsOperationalAnalytics(this.irbs, this.irbCount);
      this.IRBVectorGenerator(this.irbs);
  
      return this.irbs; // Moved the return statement here
    } catch (error) {
      console.error('Error fetching IRBs:', error);
      // Handle the error appropriately (e.g., show an error message to the user)
      throw error; // Optional: Rethrow the error to propagate it further
    } finally {
      this.busy = false;
      this.hideSpinner();
    }
  }
  


  //==============================================================================================================

  //push the marker for irbs[i]
  pushIrbMarker = (i: number): void => {
    
    this.marker = new Marker();
    this.marker.position = new PositionMarker();
    this.marker.position.lat = Number(this.irbs[i].latitude);
    this.marker.position.lng = Number(this.irbs[i].longitude);
    this.marker.title = this.irbs[i].geoLocation;// i.toString();  //
    
    this.marker.options = this.IrbMarkerColor(this.irbs[i], this.IRBstatusString);  //introduced for the IRB Marker color selection
    
    this.markerOptions = {
                           icon: this.IrbMarker,
                           zIndex: Number(google.maps.Marker.MAX_ZINDEX) + 100*i
    };

    this.marker.label = { text:this.irbs[i].id.toString(),
                          color:'#FFFFFF',
                        };

    this.marker.id = this.irbs[i].id;
    this.marker.markerIndex = this.markerIndex;
     
    this.marker.markerType = "irb";

    this.markers.push(this.marker);

      
    this.getIrbInfoview(this.irbs[i].id,this.markerIndex)
    this.markerIndex++;
    
  }
  // get longitude and latitude // update database//push marker
  getLongLat = (i: number): void => {
    let a = this.irbs[i];
    //this.markers = [];
    this.administrationService.trialomsGeocode(a.geoLocation)
      .pipe(finalize(() => {

      })).subscribe(
        results => {
          debugger;
          try {
            this.irbs[i].longitude = results.results[0].geometry.location.lng.toString();
            this.irbs[i].latitude = results.results[0].geometry.location.lat.toString();
            //console.log(results);
            
            this.updateLongitudeLatitude(this.irbs[i]);
            
            this.pushIrbMarker(i);
          }
          catch (e) {
            console.log(results);
            console.log('i=' + i.toString());
            console.log(e.message);
          }
          // console.log('id=' + a.id.toString());
          // console.log ('latitude='+results.results[0].geometry.location.lat.toString());
          // console.log ('longitude='+ results.results[0].geometry.location.lng.toString());

        });

        

  }
 
//....................................................................................................//

hideSpinner=():void =>{
    this.spinner.hide();
  }

  move(event: google.maps.MapMouseEvent) {
    if (event.latLng != null) this.display = event.latLng.toJSON();
  }
  updateLongitudeLatitude = (a: IIrb): void => {
    this.administrationService.updateLatitudeLongitudeIrb(this.authService.authorizationHeaderValue, a)
      .pipe(finalize(() => {

      })).subscribe(
        results => {
          console.log(a);
        });

  }
  openInfoWindow(marker: MapMarker,i:number) {
    if (this.markers[i].markerType == "irb"){
      this.currentIrbId = this.markers[i].id;
      this.getPatientList(this.currentIrbId);  // CRUCIAL for the display of the KNOB
      this.getMaterialInventoryList(this.currentIrbId);
      this.getIrb(this.currentIrbId);
      
    }
    else{
      this.currentSiteId = this.markers[i].id;
      this.currentIrbId =  this.markers[i].id;
      this.getPatientList(this.currentIrbId); // CRUCIAL for the display of the KNOB
    
      //this.getMaterialInventoryList(this.currentIrbId);
      
    }
    let identifier = this.markers[i].markerIndex;
    
    let description = this.markers[i].title;
    if (this.summaryData[identifier] != null)
    {
    this.thisistheinfo=this.summaryData[identifier];
    }
    else{
    this.thisistheinfo=description;
    }
      if (this.infoWindow != undefined) this.infoWindow.open(marker);
      
  } 


AEMapping =  (treatmentsWithPi:ITreatment[]): any => {
  
   
  this.AETreatmentListforMap = [];
  this.AETreatmentListforMapForBublePlot = [];
  this.sitesCountForAeMap = 0;
  this.AEmarkers = [];
  this.markerAEIrbIndex = 0; 
  let AESymptomsListforMap = [];
  let i:number = 0;
  let AEMapIdentifyer;
  //let AEType;


  this.AETreatmentListforMap = this.treatmentsWithPi.filter(aTreatment => aTreatment.treatmentType == 1);
  this.AETreatmentListforMap.sort((a, b) => a.patient.irb.id - b.patient.irb.id);  //sorting by increasing irbId
  this.distinctAETreatmentSitesForMap = this.AETreatmentListforMap.filter((value, index, self) => 
  self.map(x => x.patient.irb.siteName).indexOf(value.patient.irb.siteName) === index);  //selecting unique site with AE
  this.sitesCountForAeMap = this.distinctAETreatmentSitesForMap.length;

  
// Listing of each AE including 
  AESymptomsListforMap = [...new Set(this.AETreatmentListforMap.map(item => item.aeSymptomsSelection))];

  //console.log(AESymptomsListforMap);

  let countAEOccurence = this.AETreatmentListforMap.reduce((acc, cur) => {
    acc[cur.patient.irb.siteName] = (acc[cur.patient.irb.siteName] || 0) + 1;  // counting the number of occurence of an AE site
    return acc;
  }, {});

  let counts = Object.values(countAEOccurence); // obtaining the count as an array (AEOccurence)
  console.log(counts);

let orderedSiteName =(this.distinctAETreatmentSitesForMap.map(item => item.patient.irb.siteName)).sort((a, b) => a - b);



//this.AETreatmentListforMapForBublePlot = [orderedSiteName, AESymptomsListforMap.map(item => item.aeSymptomsSelection), counts]
for (let i=0; i<counts.length; i++)
{this.AETreatmentListforMapForBublePlot.push([orderedSiteName[i], counts[i]])};
  
  console.log(this.AETreatmentListforMapForBublePlot);

  //console.log(this.sitesCountForAeMap);

    for (let i=0; i < this.sitesCountForAeMap; i++) {

      AEMapIdentifyer = this.AETreatmentListforMap[i].patient.irb;
      
      this.AEmarker = new Marker();
      this.AEmarker.position = new PositionMarker();
      this.AEmarker.position.lat = Number(AEMapIdentifyer.latitude);
      this.AEmarker.position.lng = Number(AEMapIdentifyer.longitude) ;

      this.AEmarker.title = AEMapIdentifyer.siteName;
      this.AEmarkerOptions.zIndex = Number(google.maps.Marker.MAX_ZINDEX) + 10000*i;
      this.AEmarker.label = {
                            text: AEMapIdentifyer.id.toString(),
                            color:'#FFFFFF',};

              
      this.AEmarker.markerIndex = this.markerAEIrbIndex;
      this.AEmarker.markerType = "irb";
      
      this.AEmarkers.push(this.AEmarker);
      console.log(this.AEmarkers)
      this.getIrbInfoview(AEMapIdentifyer.id,this.AEmarker.markerIndex);
      this.markerAEIrbIndex++;
      console.log(this.markerAEIrbIndex )
  }  

  console.log(this.AEmarkers )

  this.AEOccurenceBubbleVectorGenerator(this.AETreatmentListforMapForBublePlot);
  return this.sitesCountForAeMap, this.distinctAETreatmentSitesForMap, this.AETreatmentListforMap;
}


}
