import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
 
@Injectable()
export class ConfigService {    

    constructor() {}

    get authApiURI() {
        if (environment.production)
        {
          return 'https://trialomsidserver3.azurewebsites.net/api';
        }
        else
        {
          return 'https://trialomsidserver3.azurewebsites.net/api';//'https://trialomsidserver3.azurewebsites.net/api';//

        }
    }    
    get googleMapApi(){
        return 'https://maps.googleapis.com/maps/api/geocode/json?address=';
     }
     get googleMapKey(){
        return 'AIzaSyCkviDi0WgxiYHc5I3UH8lHiLRg9vZxV6o';
     }
    get resourceApiURI() {
        if (environment.production)
        {
          return 'https://trialomstestapi.azurewebsites.net/api';
        }
            else{
          return  'https://trialomstestapi.azurewebsites.net/api';  //'https://localhost:6001/api' ;// ;

            }
    }  
}
