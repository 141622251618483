import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { IssAdministrationService } from 'src/app/investigator-initiated-study/iss-administration.service';
import { IIrb } from 'src/app/shared/interfaces/irb';
import { ISiteSetup } from 'src/app/shared/interfaces/siteSetup';
import { PiAdministrationService } from '../pi-administration.service';

@Component({
  selector: 'app-local-pi-irb',
  templateUrl: './local-pi-irb.component.html',
  styleUrls: ['./local-pi-irb.component.sass']
})
export class LocalPiIrbComponent {

  userName:string=sessionStorage.getItem("userName");
  name:string=sessionStorage.getItem("name");
  busy:boolean=false;
  irbBusy:boolean=false;
  siteSetups:ISiteSetup[] = [];
  piSites:ISiteSetup[] = [];
  irb:IIrb;
  irbs: IIrb[] = [];
  piIrbs: IIrb[] = [];
  localIrbs:boolean=false;
  totalIrbs:number=0;
  patientManagement:boolean=false;
// this.irbId = +this.route.snapshot.paramMap.get('irbId');
constructor(private route: ActivatedRoute,

  private authService: AuthService,
  private router: Router,
  private administrationService: IssAdministrationService,
  private piAdministrationService: PiAdministrationService,
  private spinner: NgxSpinnerService) { }
ngOnInit(): void {
  
  var aWhere = this.route.snapshot.url[1].path;
  debugger;
  if(aWhere=='patientManagement'){
    this.patientManagement=true;
  }
//getAllSites
  this.getAllIrbs();

}
hideSpinner = (): void => {
  if(!this.busy && !this.irbBusy)
  { 
    this.spinner.hide();
  }
  

}
getSiteSetups(): void {
  //this.spinner.show();
  this.busy=true;
  this.administrationService.getIssSites(this.authService.authorizationHeaderValue)
    .pipe(finalize(() => {
      this.busy = false;
      this.hideSpinner();
    }))
    .subscribe(
      result => {
        this.siteSetups = result;
        
        //get all sites where status of contract evaluation=Approved
        this.piSites=this.siteSetups.filter(aSite => aSite.piEmail==this.userName 
        && aSite.contractEvaluationStatus== 'Approved');
        console.log('piSites');
        console.log(this.piSites);
        this.totalIrbs+=this.piSites.length;
      })
    }
    getAllIrbs = (): void => {
      this.irbBusy = true;
      this.administrationService.getAllIrbs(this.authService.authorizationHeaderValue)
        .pipe(finalize(() => {
          this.busy = false;
          this.hideSpinner();
        }))
        .subscribe(
          results => {
            this.irbs = results;
            console.log('piIrbs');
            console.log(results);
            debugger;
            this.piIrbs= results.filter(aIrb => aIrb.piEmail==this.userName || aIrb.site.piEmail== this.userName );
            console.log(this.piIrbs);
            
            if(this.piIrbs.length==0)
            {
              this.localIrbs=false;
            }
            else{
              
              this.localIrbs=true;
            }
            this.totalIrbs+=this.piIrbs.length;
          }
        )
      }
}
