<div>
    <div>
        <form *ngIf="!busy" #f="ngForm" (ngSubmit)="onSubmit()">

            <div class="row mb-1 justify-content-center">
                <button type="button" class="btn btn-info" ngbTooltip="{{patientTooltip}}"
                    (click)="isSearchPatient=!isSearchPatient">
                    <!-- [attr.aria-expanded]="!isCollapsed" aria-controls="addIrb" -->
                    <h3>Patients</h3>
                </button>
                <p></p>
            </div>


            <div>
                <div class="row mb-1 justify-content-center">
                    <h3>Patient Screening</h3>
                </div>
                <div class="row mb-1">
                    <div class="col-lg-12" id='addPatient'>
                    </div>
                </div>


                <div class="row mb-1">
                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <label for="patientNumber"><b>Patient Number</b></label>
                        </div>
                    </div>

                    <div class="col-lg-3 col-xs-12">
                        <div class="form-group">
                            <span>{{patient.patientNumber}}</span>
                        </div>
                    </div>

                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <label for="dateOfBirth"><b>Date of Birth</b></label>
                        </div>
                    </div>

                    <div class="col-lg-3 col-xs-12">
                        <div class="form-group">
                            <span>{{patient.dateOfBirth|date:'d MMMM y'}}</span>
                        </div>
                    </div>
                </div>
                <div class="row mb-1">
                    <div class="col-lg-2 col-sx-12">

                        <div class="form-group">
                            <label for="biologicalGender"><b>Biological Gender</b></label>
                        </div>
                    </div>
                    <div class="col-lg-3 col-xs-12">

                        <div class="form-group">
                            <!-- -->
                            <span>{{patient.biologicalGender}}</span>
                            <!-- <select class="form-control" id="biologicalGender" name="biologicalGender"
                            [(ngModel)]="patient.biologicalGender" readonly>
                            <option value="M">Male</option>
                            <option value="F">Female</option>
                        </select> -->
                        </div>
                    </div>
                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <label for="patientNumber"><b>Screening Date</b></label>
                        </div>
                    </div>
                    <!-- min="{{irb.firstApprovalDate|date:'yyyy-mm-dd'}}"   -->
                    <div class="col-lg-3 col-xs-12">
                        <div class="form-group">
                            <input type="date" id="screeningDate" name="screeningDate"
                                min="{{irb.firstApprovalDate}}"  
                                (change)="checkScreeningDate()"
                                [(ngModel)]="patient.screeningDate" class="form-control" required >  <!--min="irb.reviewDate|date:'MM dd yyyy' "??-->
                        </div>
                    </div>
                </div>
                <div class="row mb-1">
                    <div class="col-lg-5">
                        <span *ngIf="!validScreeningDate" class="fontRed">Screening date cannot be before Irb approval date</span>
                    </div>
                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <label for="minScreeningDate" style="color: red;"><b>Min Screening Date</b></label>
                        </div>
                    </div>

                    <div class="col-lg-3 col-xs-12">
                        <div class="form-group">
                            
                            <span id="minScreeningDate" name="minScreeningDate">{{irb.firstApprovalDate|date:'d MMM yyyy'}}</span>
                                 
                        </div>
                    </div>
                </div>
                <div class="row mb-1">
                    <div class="col-lg-4 col-xs-12">

                    </div>
                    <div class="col-lg-3 col-xs-12">
                        <div class="form-group">
                            <a class="btn btn-lg form-control btn-primary btn-block" [routerLink]="['/patient', irbId]"
                                role="button" title="View Patients">Cancel</a>
                        </div>
                    </div>
                    <div class="col-lg-3 col-xs-12">
                        <div class="form-group">
                            <button type="submit" class="btn btn-lg  btn btn-primary form-control"
                                [disabled]="!f.form.valid || !validScreeningDate">
                                Update
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- </div> -->
        </form>
    </div>

    <ngx-spinner type="pacman">
        <p>Loading...</p>
    </ngx-spinner>
</div>