<header> 
  <div class="navbar navbar-dark bg-dark box-shadow">
    <div class="container d-flex justify-content-between">
      <a routerLink="/" class="navbar-brand d-flex align-items-center">
        <strong>Trials Operation Management System</strong>
      </a>
      <nav *ngIf="!isAuthenticated" class="my-2 my-md-0 mr-md-3">
         <!-- <a class="p-2 text-light" routerLink="/register">Signup</a>  -->
         <a class="p-2 text-light" routerLink="/login">Login</a>     
      </nav>
      <nav *ngIf="isAuthenticated" class="my-2 my-md-0 mr-md-3">
         <a class="p-2 text-light" routerLink="/topsecret">Your Security Data</a>  
         <a class="p-2 text-light" (click)="signout()" href="javascript:void(0)">Signout {{name}}</a>               
      </nav>
    </div>    
  </div>
</header>
