<div>
    <div class="row mb-1 justify-content-center">
  
      <div class="col-lg-12 concept" align="center">
        <div>
          <h3>Material Management - Return  Material</h3>
        </div>
      </div>
  
       <form *ngIf="!busy"  #f="ngForm" (ngSubmit)="onSubmit()">
  
        <div class="row mb-1">
          <div class="col-lg-2 col-xs-12">
            <div class="form-group">
              <label for="batchNumber">Batch Number</label>
            </div>
          </div>
  
          <div class="col-lg-4 col-xs-12">
            <div class="form-group">
              <input type="text" id="batchNumber" name="batchNumber" [(ngModel)]="material.batchNumber"
                     class="form-control" readonly>
            </div>
          </div>
  
  
   
          <div class="col-lg-2 col-xs-12">
            <div class="form-group">
              <label for="expiryDate">Expiry Date:   </label>
            </div>
          </div>
  
           <div class="col-lg-4 col-xs-12">
  
            <div class="form-group">
                <span>{{material.expiryDate|date:"d MMM yyyy"}}</span>
            </div>
  
          </div> 
   
  
        </div>
        
        <div class="row mb-1">
          <div class="col-lg-2 col-xs-12">
            <div class="form-group">
              <label for="numberOfItems">Number of Returned Items</label>
            </div>
          </div>
  
          <div class="col-lg-4 col-xs-12">
            <div class="form-group">
              <input type="number" id="numberOfItems" name="numberOfItems" max="{{maxReturned}}" [(ngModel)]="materialReturn.numberOfItems"
                     class="form-control" required (change)="itemUptated()" placeholder="{{maxReturned}}"> 
            </div>
            <div *ngIf="!numberOfItemsUpdated">{{errorMessage}} </div>
          </div>
  
          <div class="col-lg-2 col-xs-12">
            <div class="form-group">
              <label for="manufRealeaseDate">Manuf. Release Date:</label>
            </div>
          </div>
  
          <div class="col-lg-4 col-xs-12">
  
            <div class="form-group">
                <span>{{material.manufacturingReleaseDate|date:"d MMM yyyy"}}</span>
            </div>
  
          </div>
          
        </div>
  
        <div class="row mb-1">
          
          <div class="col-lg-2 col-xs-12">
            <div class="form-group">
              <label for="leadTime">Lead Time</label>
            </div>
          </div>
          <div class="col-lg-4 col-xs-12">
            <div class="form-group">
              <input type="text" id="leadTime" name="leadTime" [(ngModel)]="leadTime"
                     class="form-control" readonly>
            </div>
          </div>
        </div>
  
  <div class="row mb-1">
  
    <div class="col-lg-2 col-xs-12">
    </div>
    <div class="col-lg-4 col-xs-12">
        <div class="form-group">
            <a class="btn btn-lg btn-primary btn-block" routerLink="/{{originator}}/material" 
                role="button">Cancel</a>
        </div>
    </div>
  
    <div class="col-lg-2 col-xs-12">
    </div>
    <div class="col-lg-4 col-xs-12">
        <div class="form-group">
            <button class="btn btn-lg btn-primary btn-block" type="submit" 
            [disabled]="!f.form.valid || disableUpdate  || !numberOfItemsUpdated">
                Update
            </button>
        </div>
    </div>
  </div>
  
  
        <div *ngIf="error" class="alert alert-danger mt-3" role="alert">
          <strong>Oops!</strong> {{error}}
        </div>
      </form> 
  
  
    </div>
    <ngx-spinner type="pacman">
        <h2>Loading...</h2></ngx-spinner>
  </div>
  