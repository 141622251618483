import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { IssAdministrationService } from 'src/app/investigator-initiated-study/iss-administration.service';
import { PiAdministrationService } from '../pi-administration.service';
import { IMaterial, Material } from 'src/app/shared/interfaces/material';
import { finalize } from 'rxjs';
import { MaterialReturn } from 'src/app/shared/interfaces/materialReturn';

@Component({
  selector: 'app-local-pi-return-material',
  templateUrl: './local-pi-return-material.component.html',
  styleUrls: ['./local-pi-return-material.component.sass']
})
export class LocalPiReturnMaterialComponent {
// +this.route.snapshot.paramMap.get('conceptId');
materialId:number;
material:IMaterial;
maxReturned:number;
materialReturn:MaterialReturn
busy:boolean=false;
disableUpdate:boolean=false;
numberOfItemsUpdated:boolean=false;
errorMessageNumberofItems:string="Please Update Number of Returned Items";
errorMessage:string;
leadTime:string;
originator:string;
constructor(private route: ActivatedRoute,
  private authService: AuthService,
  private router: Router,
  private pIAdministrationService: PiAdministrationService,
  private administrationService: IssAdministrationService,
  private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.materialId=+this.route.snapshot.paramMap.get('materialId');
    this.originator=this.route.snapshot.url[0].path;
    this.materialReturn=new MaterialReturn();
    this.errorMessage= this.errorMessageNumberofItems;
    this.getMaterial()
  }
  getMaterial(){
    this.busy=true;
    this.spinner.show();
    this.administrationService.getMaterialById(this.authService.authorizationHeaderValue, this.materialId)
    .pipe(finalize(() => {
      this.busy = false;
      this.hideSpinner();
    })).subscribe(
      result => {
        this.maxReturned=result.numberOfItems;
        this.material = result;
        debugger;
        switch(this.material.leadTimeUnit) { 
          case 'd': { 
             this.leadTime=this.material.leadTime.toString() + ' '+'days'; 
             break; 
          } 
          case 'w': { 
            this.leadTime=this.material.leadTime.toString() + ' '+'weeks'; 
             break; 
          } 
          case 'm': { 
            this.leadTime=this.material.leadTime.toString() + ' '+'months'; 
             break; 
          } 
          case 'y': { 
            this.leadTime=this.material.leadTime.toString() + ' '+'years'; 
             break; 
          } 
          default: { 
             //statements; 
             break; 
          } 
       } 
  });
} 
hideSpinner = (): void => {
  if (this.busy) {
    return;
  }
  this.spinner.hide();
}
itemUptated(){

 if (this.materialReturn.numberOfItems>this.maxReturned){
  this.materialReturn.numberOfItems=0;
  this.numberOfItemsUpdated=false;
  this.errorMessage="The number of retuned items must be less or equal to the original shipment size";
 } 
 else{
  this.numberOfItemsUpdated=true;
 }
}
onSubmit= ():void=>{ 
if (!this.numberOfItemsUpdated)
{ //this.errorMessage= this.errorMessageNumberofItems;
return;
}

  this.disableUpdate=true;
  
  this.materialReturn.materialId=this.material.id;
  //this.materialReturn.numberOfItems=this.material.numberOfItems;
  this.administrationService.returnMaterial(this.authService.authorizationHeaderValue, this.materialReturn)
    .pipe(finalize(() => {
      this.busy = false;
    })).subscribe(
      result => {
        debugger;
        console.log(result);
        this.router.navigate(['/'+this.originator+'/material']);
      });
}
}
