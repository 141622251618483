<div class="container">

    <div class="row" >
      <div *ngIf="!busy" class="col-md-10">
        <a class="btn btn-lg btn-primary" 
        [routerLink]="['/newTrial' , companyId]" 
        role="button" > Add New trial &raquo;</a> 
        
      </div>
    </div>
        <div class="row mb-1">
            <div *ngIf="!busy" class="col-md-12">
              <h3>List of Trials sent from the server for company: {{company.companyName}}!</h3>
                <table class="table small">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Trial Name</th>
                        <th scope="col">Description</th>
                        <th scope="col">Created By</th>  
                        <th scope="col">Modified By</th>  
                        <th scope="col">Active</th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let trial of trials; let i = index" [attr.data-index]="i">
                        <th scope="row">{{i+1}}</th>
                        <td>{{trial.trialName}}</td>
                        <td>{{trial.trialDescription}}</td> 
                        <td>{{trial.createdBy}}</td> 
                        <td>{{trial.modifiedBy}}</td> 
                        <td>{{!trial.deleted}}  </td> 
                          <td><a class="btn btn-lg btn-primary" 
                            [routerLink]="['/updateTrial' , trial.trialId, companyId]"
                            role="button">Modify</a> </td>
                            <td><a class="btn btn-lg btn-primary" 
                              [routerLink]="['/trialProtocol' ,companyId, trial.trialId]"
                              role="button">Protocol</a>
                            </td>  
                            <td><a class="btn btn-lg btn-primary" 
                              [routerLink]="['/trialSites' , trial.trialId]"
                              role="button">Trial&nbsp;Sites</a> </td> 
                        <td><a class="btn btn-lg btn-primary" 
                          [routerLink]="['/company']" 
                          role="button" > Back </a> </td>                
                            
                        </tr>                 
                    </tbody>
                  </table>            
            </div>
        </div>
        <ngx-spinner type="pacman">
    <p>Loading...</p></ngx-spinner>  
    </div>
