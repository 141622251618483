import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cv-submission',
  templateUrl: './cv-submission.component.html',
  styleUrls: ['./cv-submission.component.sass']
})
export class CvSubmissionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
