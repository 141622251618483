<div class="row mb-1 justify-content-center">
  <div class="mx-auto" style="width: 800px;">
    <button type="button" class="btn btn-info" ngbTooltip="{{irbTooltip}}" (mouseover)="mouseOverSetUp()"
            (click)="isCollapsed=!isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="addIrb">
      <h3>{{irbTitle}}</h3>  <!--Comment:  The Title is in the ts section-->
    </button>
    <p></p>
  </div>
  <div class="=row">
    <p></p>
  </div>
  <div class="col-lg-12 text-center" id='addIrb' [ngbCollapse]="isCollapsed">

        <div class="card">
            <div class="card-body">
                <form *ngIf="!busy" #f="ngForm" (ngSubmit)="onSubmit()">

          <div class="row mb-1">
            <div class="col-lg-2 col-xs-12">
              <div class="form-group">
                <label for="localSiteName">Local PI Institution Name</label>
              </div>
            </div>

            <div class="col-lg-4 col-xs-12">
              <div class="form-group">
                <input type="text" id="siteName" name="siteName" [(ngModel)]="irb.siteName"
                       class="form-control" required>
              </div>
            </div>

            <div class="col-lg-2 col-xs-12">
              <div class="form-group">
                <label for="studyName">Study Name</label>
              </div>
            </div>

            <div class="col-lg-4 col-xs-12">
              <div class="form-group">

                <input type="text" id="studyName" name="studyName" [(ngModel)]="site.studyName"
                       class="form-control" readonly>
              </div>
            </div>
          </div>
          <div class="row mb-1">

            <div class="col-lg-2 col-xs-12">
              <div class="form-group">
                <label for="geoLocation">Local PI Address</label>
              </div>
            </div>

            <div class="col-lg-10 col-xs-12">
              <div class="form-group">
                <input type="text" id="geoLocation" name="geoLocation" [(ngModel)]="irb.geoLocation"
                (blur)="getLongLat()"
                       class="form-control" required>
              </div>
            </div>
          </div>
          
                    <!---->
     
                    <!---->

          <div class="col-lg-12 text-center " id='siteInfo'>

            <div class="card">
              <div class="card-body">
                <!---->
                <div class="row mb-1">
                  <div class="col-lg-2 col-xs-12">
                    <div class="form-group">
                      <label for="piName">Local PI </label>
                    </div>
                  </div>

                  <div class="col-lg-4 col-xs-12">
                    <div class="form-group">
                      <input type="text" id="piName" name="piName" [(ngModel)]="irb.piName"
                             class="form-control" placeholder="Name" required>
                    </div>
                  </div>

                  <div class="col-lg-2 col-xs-12">
                    <div class="form-group">
                      <label for="piEmail"> </label>
                    </div>
                  </div>

                  <div class="col-lg-4 col-xs-12">
                    <div class="form-group">
                      <input type="email" 
                             id="piEmail" name="piEmail" [(ngModel)]="irb.piEmail"
                             class="form-control" placeholder="Email" required>
                    </div>
                  </div>
                </div>

                <div class="row mb-1" *ngIf="!submitNew">
                  <div class="col-lg-2 col-xs-12">
                    <div class="form-group">
                      <label for="siteResearchCoordinatorName">Site Ressearch Coordinator</label>
                    </div>
                  </div>

                  <div class="col-lg-4 col-xs-12">
                    <div class="form-group">
                      <input type="text" id="siteResearchCoordinatorName"
                             name="siteResearchCoordinatorName"
                             [(ngModel)]="irb.siteResearchCoordinatorName" class="form-control"
                             placeholder="Name">
                    </div>
                  </div>
                  <div class="col-lg-2 col-xs-12">
                    <div class="form-group">
                      <label for="siteResearchCoordinatorEmail"></label>
                    </div>
                  </div>

                  <div class="col-lg-4 col-xs-12">
                    <div class="form-group">
                      <input type="email" 
                             id="siteResearchCoordinatorEmail" name="siteResearchCoordinatorEmail"
                             [(ngModel)]="irb.siteResearchCoordinatorEmail" class="form-control"
                             placeholder="Email">
                    </div>
                  </div>

                </div>


                <div class="row mb-1" *ngIf="!submitNew">
                  <div class="col-lg-2 col-xs-12">
                    <div class="form-group">
                      <label for="msl">MSL</label>
                    </div>
                  </div>

                  <div class="col-lg-4 col-xs-12">
                    <div class="form-group">
                      <input type="text" id="msl" name="msl" [(ngModel)]="irb.msl"
                             class="form-control" placeholder="Name">
                    </div>
                  </div>
                  <div class="col-lg-2 col-xs-12">
                    <div class="form-group">
                      <label for="mslEmail"></label>
                    </div>
                  </div>

                  <div class="col-lg-4 col-xs-12">
                    <div class="form-group">
                      <input type="email" 
                             id="mslEmail" name="mslEmail" [(ngModel)]="irb.mslEmail"
                             class="form-control" placeholder="Email">
                    </div>
                  </div>

                </div>

                <!---->
                <div class="row mb-1">

                  <div class="col-lg-2 col-xs-12">

                    <div class="form-group">
                      <label for="diseaseArea">Disease Area</label>
                    </div>
                  </div>

                  <div class="col-lg-4 col-xs-12">

                    <div class="form-group">
                      <input type="text" id="diseaseArea" name="diseaseArea"
                             [(ngModel)]="site.diseaseArea" class="form-control" readonly>
                    </div>
                  </div>

                  <div class="col-lg-2 col-xs-12">

                    <div class="form-group">
                      <label for="requestedProduct"> Product under Investigation</label>
                    </div>
                  </div>

                  <div class="col-lg-4 col-xs-12">

                    <div class="form-group">
                      <input type="text" id="requestedProduct" name="requestedProduct"
                             [(ngModel)]="site.requestedProduct" class="form-control" placeholder="Name of Product under Investigation" readonly>
                    </div>
                  </div>

                </div>

                <div class="row mb-1">



                </div>

                <div class="row mb-1" *ngIf="!submitNew">
                  <!-- expectedReviewDate -->


                  <div class="col-lg-2 col-xs-12">
                    <div class="form-group">
                      <label for="protocolSubmissionDate">IRB-Protocol Submission Date</label>
                    </div>
                  </div>
                  <div class="col-lg-4 col-xs-12" *ngIf="!submitNew">
                    <div class="form-group" align="left" *ngIf="approveIrb">
                      {{irb.submissionDate|date:'d MMMM y'}}
                      <!-- <input type="text" id="protocolSubmissionDate" name="protocolSubmissionDate"
                          [(ngModel)]="site.protocolSubmissionDate" class="form-control"
                          readonly> -->
                    </div>
                    <div class="form-group" *ngIf="submitIrb">
                      <input type="date" id="submissionDate" name="submissionDate"
                             [(ngModel)]="irb.submissionDate" class="form-control"
                             placeholder="For IRB - Protocol Submission Date" 
                             [min]="irb.dateCreated"  
                             title="The IRB Review Date must be greater than {{irb.dateCreated | date: 'mediumDate'}}, the Review Requested Date"
                             required> <!--AAA Come back to review the [min]="" section-->
                    </div>
                  </div>
                  <div class="col-lg-2 col-xs-12">

                    <div class="form-group">
                      <label for="expectedReviewDate">Expected Review Date</label>
                    </div>
                  </div>

                  <div class="col-lg-4 col-xs-12">
                    <div class="form-group" align="left" *ngIf="approveIrb">
                      {{irb.expectedReviewDate|date:'d MMMM y'}}
                    </div>

                    <div class="form-group" *ngIf="submitIrb">
                      <!-- -->
                      <input type="date" id="expectedReviewDate" name="expectedReviewDate"
                             [(ngModel)]="irb.expectedReviewDate" class="form-control"
                             placeholder="Expected Review" required>  
                    </div>
                  </div>

                </div>
                <div class="row mb-1" *ngIf="isPending">

                  <div class="col-lg-2 col-xs-12">

                    <div class="form-group">
                      <label for="latestReviewDate">Latest Review Date</label>
                    </div>
                  </div>

                  <div class="col-lg-4 col-xs-12">
                    <div class="text-left">
                      {{lastestReviewDate|date:'d MMMM y'}}
                      <!--  <input type="text" id="lastestReviewDate" name="lastestReviewDate"
                           [(ngModel)]="lastestReviewDate" class="form-control" readonly> -->
                    </div>
                  </div>
                  <div class="col-lg-2 col-xs-12">

                    <div class="form-group">
                      <label for="lastComment">Latest Comment</label>
                    </div>
                  </div>

                  <div class="col-lg-4 col-xs-12">
                    <div class="form-group">

                      <textarea id="comment" name="comment" [(ngModel)]="lastComment"
                                class="form-control COMMENTBOX" readonly>
                                        </textarea>
                    </div>
                  </div>
                </div>
                <div class="row mb-1">
                  <div class="col-lg-2 col-xs-12">

                    <div class="form-group">
                      <label for="protocolVersion">Protocol Version</label>
                    </div>
                  </div>

                  <div class="col-lg-4 col-xs-12">

                    <div class="form-group">
                      <!-- -->
                      <input type="text" class="form-control" id="protocolVersion"
                             name="protocolVersion" [(ngModel)]="irb.protocolVersion"
                             placeholder="Protocol version under review">
                    </div>
                  </div>

                  <div class="col-lg-2 col-xs-12">

                    <div class="form-group">
                      <label for="expectedNumberOfPatients">Expected Number of Patients at Site</label>
                    </div>
                  </div>

                  <div class="col-lg-4 col-xs-12">

                                        <div class="form-group">
                                            <input type="number" id="expectedNumberOfPatients"
                                                name="expectedNumberOfPatients"
                                                [(ngModel)]="irb.expectedNumberOfPatients" class="form-control"
                                                required min="1">
                                        </div>
                                    </div>
                                    <div class="row mb-1">
                                        <div class="col-lg-2">
                                            <div class="form-group">
                                                <label for="studyDuration">Study duration</label>
                                            </div>
                                        </div>
                                           
                                        <div class="col-lg-2 BetweenBoxes">
                                            <div class="form-group">
                                            <input type="number" id="studyDuration" name="studyDuration" [(ngModel)]="irb.studyDuration"
                                                class="form-control" required min="1">
                                        </div>
                                    </div>

                                    
                                        <div class="col-lg-2">
                                            <select class="form-control" id="studyDurationUnit" name="studyDurationUnit"
                                            [(ngModel)]="irb.studyDurationUnit" required >
                                                
                                                <option value="d">Days</option>
                                                <option value="w">Weeks</option>
                                                <option value="m">Months</option>
                                                <option value="y">Years</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-xs-12" *ngIf="isApproved">

                    <div class="form-group">
                      <label for="latestReviewDate">Approval Date</label>
                    </div>
                  </div>

                  <div class="col-lg-4 col-xs-12" *ngIf="isApproved">
                    <div class="text-left">
                      {{irb.reviewDate|date:'d MMMM y'}}
                      <!--  <input type="text" id="lastestReviewDate" name="lastestReviewDate"
                           [(ngModel)]="lastestReviewDate" class="form-control" readonly> -->
                    </div>
                  </div>
                </div>


                <div *ngIf="approveIrb && !isApproved">
                  <div class="row mb-1">

                    <div class="col-lg-2 col-xs-12">

                      <div class="form-group">
                        <label for="reviewDate">Actual Review Date</label>
                      </div>
                    </div>
                    <div class="col-lg-4 col-xs-12">

                      <div class="form-group">
                        <!-- -->
                        <input type="date" id="reviewDate" name="reviewDate"
                               [(ngModel)]="irb.reviewDate" class="form-control"
                               min="irb.submissionDate" required>
                      </div>
                    </div>
                    <!--decision date-->

                    <div class="col-lg-2 col-xs-12">

                      <div class="form-group">
                        <label for="outcome">Outcome</label>
                      </div>
                    </div>
                    <div class="col-lg-4 col-xs-12">

                      <div class="form-group">
                        <!-- -->
                        <select class="form-control" id="outcome" name="outcome"
                                (change)="updateOutcome()" [(ngModel)]="outcomeString" required>
                          <option value="true">Approved</option>
                          <option value="false">Pending Revision</option>
                        </select>
                      </div>
                    </div>

                  </div>

                  <div class="row mb-1" *ngIf="approveIrb && commentVisible">

                    <!--decision  comment-->
                    <div class="col-lg-2 col-xs-12">

                      <div class="form-group">
                        <label for="comment">Review Comment</label>
                      </div>
                    </div>

                    <div class="col-lg-10 col-xs-12">

                      <div class="form-group">
                        <textarea id="comment" name="comment" [(ngModel)]="irb.comment"
                                  class="form-control" required
                                  placeholder="Required comments - What needs to be done to approve this protocol"></textarea>

                      </div>
                    </div>
                  </div>

                </div>

                <div class="row mb-1" *ngIf="!submitNew ">
                  <div class="col-lg-2 col-xs-12">

                    <div class="form-group">
                      <label for="irbCost">IRB Review Cost ($)</label>
                    </div>
                  </div>

                  <div class="col-lg-4 col-xs-12">

                    <div class="form-group">

                      <input type="number" class="form-control" id="irbCost"
                             name="irbCost" [(ngModel)]="irb.irbCost"
                             placeholder="$ "  min="0" required>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-1">
            <p></p>
          </div>
          <div class="row mb-1">

            <div class="col-lg-2 col-xs-12">
            </div>
            <div class="col-lg-4 col-xs-12">
              <div class="form-group">
                <a *ngIf="!principalInvestigator" class="btn btn-lg btn-primary btn-block"
                   [routerLink]="['/siteSetup']" role="button">Cancel</a>

                <a *ngIf="principalInvestigator" class="btn btn-lg btn-primary btn-block"
                   [routerLink]="['/hospitals']" role="button">Cancel</a>
              </div>
            </div>
            <div class="col-lg-2 col-xs-12">
            </div>
            <div class="col-lg-4 col-xs-12" *ngIf="!isApproved " title="Check the Submission Date AND Submit">
              <div class="form-group">
                <button class="btn btn-lg btn-primary btn-block" type="submit"
                        [disabled]="!f.form.valid || irb.submissionDate < irb.dateCreated">
                  {{submitText}}
                </button>
              </div>
            </div>
            <div class="col-lg-4 col-xs-12" *ngIf="isApproved">
              <a class="btn btn-lg btn-primary btn-block"
                 [routerLink]="['/patient', irb.id]" role="button" title="View or Add Patients">Patients</a>


            </div>
          </div>
          <div *ngIf="isApproved">
            <h2 class="fontRed">This information cannot be submited again, the protocol is already <br /> APPROVED by the IRB (Click on the Patients button to proceed)</h2>
          </div>
          <div *ngIf="error" class="alert alert-danger mt-3" role="alert">
            <strong>Oops!</strong> {{error}}
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div class="row mb-1 justify-content-center">
  <div class="col-lg-12 text-center">
    <div *ngIf="!busy && !auditVisible && !localPIiIrb" class="col-md-12">
      <h3>List of IRB Submissions</h3>

      <!--/// Begining of # of Sites Display-->

      <div class="row mb-1">

        <div class="col-lg-2 col-sx-12">

          <div class="form-group">
            <label for="statusNumber">Filter by IRB Status</label>
          </div>
        </div>

        <div class="col-lg-4 col-xs-12">

          <div class="form-group">
            <!-- -->
            <select class="form-control" id="approvalStatus" name="approvalStatus"
                    [(ngModel)]="statusNumber" (change)="irbApprovalStatus()">
              <option value="0">All IRBs</option>
              <option value="1">Requested</option>
              <option value="2">Submitted</option>
              <option value="3">Pending Revision</option>
              <option value="4">Approved</option>

            </select>
          </div>
        </div>

        <div class="col-lg-2 col-xs-12">
          <div class="form-group">
            <label for="irbRequestCount"># of IRB (Filtered/Total) </label>
          </div>
        </div>


        <div class="col-lg-4 col-xs-12 font-weight-bold ">


          <div class="form-group">

            <a class="btn btn-primary form-control IRBREQUEST "
               role="button" title={{irbRequestCount}} aria-disabled="true">
              {{irbListFilteredCount}}/{{irbRequestCount}}
            </a>

           
          </div>
        </div>
      </div>

      <!--////End # of Sites Display-->

      <table class="table small">
        <tr>
          <td>
            <p>Click an event below to modify its properties</p>
          </td>

        </tr>
      </table>



      <div>
        <div class="row mb-1">
          <div class="col-lg-1  font-weight-bold"> <b>#</b></div>
          <div class="col-lg-3  font-weight-bold"><b>Site Name</b></div>
          <div class="col-lg-2  font-weight-bold"><b>Local PI</b></div>
          <div class="col-lg-2  font-weight-bold hidden-xs hidden-sm"><b>Local MSL</b></div>
          <div class="col-lg-1  font-weight-bold hidden-xs hidden-sm"><b>Protocol Version</b></div>
          <div class="col-lg-1  font-weight-bold hidden-xs hidden-sm"><b> Target #Patients</b></div>
          <div class="col-lg-2  font-weight-bold"><b>Approval Status</b></div>

        </div>
        <div *ngFor="let aIrb of irbListFiltered; let i = index" [attr.data-index]="i">
          <div class="row mb-1">
            <div class="col-lg-1" title="Click here for audit trial">
              <a [routerLink]="['/irbAudit',conceptId,aIrb.id ]">{{i+1}}</a>
            </div>
            <div class="col-lg-3" (click)="select(aIrb.id)">{{aIrb.siteName}}</div>
            <div class="col-lg-2" (click)="select(aIrb.id)">{{aIrb.piName}}</div>
            <div class="col-lg-2 hidden-xs hidden-sm" (click)="select(aIrb.id)">{{aIrb.msl}}</div>
            <div class="col-lg-1 hidden-xs hidden-sm" (click)="select(aIrb.id)">{{aIrb.protocolVersion}}</div>
            <div class="col-lg-1 hidden-xs hidden-sm" title="Expected number of patients at site" (click)="select(aIrb.id)">{{aIrb.expectedNumberOfPatients}}</div>
            <div class="col-lg-2 {{approvalStatusClass(aIrb)}}" (click)="select(aIrb.id)">
              {{approvalStatus(aIrb)}}
            </div>

          </div>
          <!--  <div  *ngIf="approvalStatus(aIrb)=='Pending Revision'"><b>{{aIrb.comment}}</b></div>-->
          <div class="row mb-1" *ngIf="approvalStatus(aIrb)=='Pending Revision'">
            <div class="col-lg-10 fontRed">
              <textarea id="comment" name="comment" [(ngModel)]="aIrb.comment" class="form-control COMMENTBOX"
                        readonly></textarea>

            </div>
          </div>
        </div>
        <!-- <table class="multirow" width="100%">

            <tbody>
                <tr *ngFor="let aIrb of irbList; let i = index" [attr.data-index]="i" (click)="select(aIrb.id)">
                    <td style="cursor: pointer;width:10%" title="Click here for audit trial">
                        {{i+1}}

                    </td>
                    <td>{{aIrb.siteName}}</td>
                    <td>{{aIrb.piName}}</td>
                    <td>{{aIrb.msl}}</td>
                    <td>{{aIrb.protocolVersion}}</td>
                    <td>{{aIrb.expectedNumberOfPatients}}</td>
                    <td class="{{approvalStatusClass(aIrb)}}">{{approvalStatus(aIrb)}}</td>
                    <td *ngIf="approvalStatus(aIrb)=='Pending Revision'">{{aIrb.comment}}</td>
                </tr>
            </tbody>
        </table> -->
      </div>
    </div>
    <div *ngIf="auditVisible" class="col-md-12">

      <h3>Audit</h3>
      <table class="table small">
        <thead>

          <tr>
            <th>num</th>
            <th scope="col"> Name</th>
            <th scope="col">Priority</th>
            <th scope="col">Severity</th>
            <th scope="col">Amended By</th>
            <th scope="col">Date Modified</th>
            <th>Reason</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let aAudit of irbAuditList; let j = index" [attr.data-index]="j">
            <th scope="row">

              {{j+1}}
            </th>

            <td>{{aAudit.siteName}}</td>
            <td>{{aAudit.piName}}</td>
            <td>{{aAudit.msl}}</td>
            <td>{{aAudit.createdBy}}</td>
            <td>{{aAudit.dateCreated|date:"d MMM yyyy H:mm:ss"}}</td>
            <td>{{aAudit.reason}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <!---->
    <ngx-spinner type="pacman">
      <p>Loading...</p>
    </ngx-spinner>
  </div>
</div>
