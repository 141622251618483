import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { Parameter } from 'src/app/shared/interfaces/parameter';
import { IssAdministrationService } from '../iss-administration.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-create-parameter',
  templateUrl: './create-parameter.component.html',
  styleUrls: ['./create-parameter.component.sass']
})
export class CreateParameterComponent {
busy:boolean=false;
setupParameter:Parameter=new Parameter();
submitText:string="Create";

constructor(private route: ActivatedRoute,
  private authService: AuthService,
  private router: Router,
  private administrationService: IssAdministrationService,
  private spinner: NgxSpinnerService) { }


  ngOnInit(): void {}
  
  onSubmit= ():void=>{
    this.busy=true;
    this.spinner.show();
    this.administrationService.createParameter(this.authService.authorizationHeaderValue,this.setupParameter)
    .pipe(finalize(() => {
      this.busy = false;
      this.spinner.hide();
  })).subscribe(
    result => {
      console.log(result);
      //this.getConceptEvaluationAudit();
      
      this.router.navigate(['/parameters/view']);
    });
  }

}
