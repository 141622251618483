<div  >
      <h1>PI's Results Aggregates</h1>
  </div>

<div  class="col-lg-12 text-center" style="background-color: #038939; color: white; height: 30px;"> </div>

<!--<div  class="col-lg-12 " align="center" style="background-color: #038939; color: white;">
      <h3>PI's Results Aggregates</h3>
  </div>-->
  
  <div class = "row mb-20"></div>

<div style="display: flex; justify-content: space-between;"> 

    <p-button label = "Sites Budget Summary" 
              icon = "pi pi-dollar" 
              iconPos = "right"
              (onClick) = "displayCards = !displayCards">
    </p-button>
  
    <p-button label = "Sites Enrollment Summary" 
              icon = "pi pi-calendar-times" 
              iconPos = "right"
              (onClick) = "displayEnrollmentSummary = !displayEnrollmentSummary">
    </p-button>
  
    <p-button label = "Study Data Aggregates" 
              icon = "pi pi-chart-bar" 
              iconPos = "right"
              (onClick) = "displayAggregates = !displayAggregates">
    </p-button>
  
</div>

  <div class = "row mb-20"></div>

  <div fxLayout="row" fxLayoutAlign="start center">

            <p-card *ngIf="displayCards"
                        header = "Sites - Costs Data Aggeragte" 
                        subheader = "" 
                        [style] = "{ width: '100%' }"
                        class = "card-style">
                  <div style="display: flex; align-items: center;">
                        <button (click)="toggleColumn()">Hide # of Screened & Enrolled Patients' Columns</button>
                        <div style="flex: 1;"></div>
                        <button (click)="displayAggregatesChart()">Display Budget Aggregates - Charts</button>
                        <div style="flex: 1;"></div>
                        <button (click)="displayBudgetDetailsChart()">Display Budget Details At Site</button>
                  </div>     
                        <ng-template pTemplate="header">

                              <p-table  [value]="budgetSummaryInfo"
                                        styleClass = "p-datatable-striped" 
                                        [tableStyle]="{ 'min-width': '20rem' }" 
                                        [scrollable]="true"
                                        scrollHeight="350px"> 

                                    <ng-template pTemplate="header">
                                          <tr>
                                                <th pSortableColumn = "count">#<p-sortIcon field="count"></p-sortIcon></th>
                                                <th pSortableColumn = "studyName">Study Name<p-sortIcon field="studyName"></p-sortIcon></th>
                                                <th pSortableColumn = "siteName">IRB Site Name<p-sortIcon field="siteName"></p-sortIcon></th>
                                                <th pSortableColumn = "irbId">IRB id<p-sortIcon field="irbId"></p-sortIcon></th>
                                                <th pSortableColumn = "irbCost">IRB Review Cost ($)<p-sortIcon field="irbCost"></p-sortIcon></th>
                                                <th pSortableColumn = "materialCost">Material Cost ($)<p-sortIcon field="materialCost"></p-sortIcon></th>
                                                <th pSortableColumn = "equipmentCost">Equipment Cost ($)<p-sortIcon field="equipmentCost"></p-sortIcon></th>
                                                <th pSortableColumn = "setUpCost">Setup Cost ($)<p-sortIcon field="setUpCost"></p-sortIcon></th>
                                                <th pSortableColumn = "siteActivationCost">Site Activation Cost ($)<p-sortIcon field="siteActivationCost"></p-sortIcon></th>
                                                <th pSortableColumn = "publicationCost">Publication Cost ($)<p-sortIcon field="publicationCost"></p-sortIcon></th>
                                                <th pSortableColumn = "sunkCost">Sunk Cost ($)<p-sortIcon field="sunkCost"></p-sortIcon></th>

                                                <th pSortableColumn = "numberOfScreened" *ngIf="showColumn"># of Screened Patients (s)<p-sortIcon field="numberOfScreened"></p-sortIcon></th>
                                                <th pSortableColumn = "numberOfEnrolled" *ngIf="showColumn"># of Enrolled Patients (n)<p-sortIcon field="numberOfEnrolled"></p-sortIcon></th>
                                                <th pSortableColumn = "screeningCost">Patients Screening Cost(*ns)($)<p-sortIcon field="screeningCost"></p-sortIcon></th>
                                                <th pSortableColumn = "recruitmentCosts">Total Recruitment Costs(*s)($)<p-sortIcon field="recruitmentCosts"></p-sortIcon></th>
                                                <th pSortableColumn = "treatmentCostPerPatient">Total Treatment Cost(*n)($)<p-sortIcon field="treatmentCostPerPatient"></p-sortIcon></th>                      
                                                <th pSortableColumn = "aeTreatmentCost">Total AE Treatment Cost ($)<p-sortIcon field="aeTreatmentCost"></p-sortIcon></th>
                                                <th pSortableColumn = "variableCost">Variable Cost ($)<p-sortIcon field="variableCost"></p-sortIcon></th>
                                                
                                                <th pSortableColumn = "totalCost">Total Cost ($)<p-sortIcon field="totalCost"></p-sortIcon></th>
                                                <th pSortableColumn = "percentOfTotalBudget">% of Requested Budget<p-sortIcon field="percentOfTotalBudget"></p-sortIcon></th>  
                                          </tr>
                                          
                                    </ng-template>

                                    <ng-template pTemplate="body"  let-rowData>
                                          <tr>
                                                <td style="color: #3398FF" class="tbody"><b>{{ rowData.count }}</b></td>
                                                <td class="tbody"><b>{{ rowData.studyName }}</b></td>
                                                <td class="tbody">{{ rowData.siteName }}</td>
                                                <td style="border-right: 2px solid black;" class="tbody">{{ rowData.irbId }}</td>
                                                <td class="tbody">${{ rowData.irbCost }}</td>
                                                <td class="tbody">${{ rowData.materialCost }}</td>
                                                <td class="tbody">${{ rowData.equipmentCost }}</td>
                                                <td class="tbody">${{ rowData.setUpCost }}</td>
                                                <td class="tbody">${{ rowData.siteActivationCost }}</td>
                                                <td class="tbody">${{ rowData.publicationCost }}</td>
                                                <td style="border-right: 2px solid black; color: #1d67b1" class="tbody">${{ rowData.sunkCost }}</td>

                                                <td class="tbody" *ngIf="showColumn">{{ rowData.numberOfScreened }}</td>
                                                <td class="tbody" *ngIf="showColumn">{{ rowData.numberOfEnrolled }}</td>
                                                <td class="tbody">${{ rowData.screeningCost }}</td>
                                                <td class="tbody">${{ rowData.recruitmentCosts }}</td>
                                                <td class="tbody">${{ rowData.treatmentCostPerPatient }}</td>
                                                <td class="tbody">${{ rowData.aeTreatmentCost }}</td>
                                                <td style="border-right: 2px solid black; color: #1d67b1" class="tbody">${{ rowData.variableCost }}</td>
                                               
                                                <td style="color: #1d67b1" class="tbody"><b>${{ rowData.totalCost }}</b></td>
                                                <td style="color: #1d67b1" class="tbody">{{ rowData.percentOfTotalBudget *100 | number:'1.2-2' }}%</td>

                                          </tr>
                                    </ng-template>

                                    <!-- Footer template -->
                                    <ng-template pTemplate="footer">
                                          <tr>
                                                <td class="tbody"></td>
                                                <td class="tbody">---</td>
                                                <td class="tbody">Total</td>
                                                <td class="tbody">---</td>
                                                <td class="tbody">${{ calculateSum('irbCost') }}</td>
                                                <td class="tbody">${{ calculateSum('materialCost') }}</td>
                                                <td class="tbody">${{ calculateSum('equipmentCost') }}</td>
                                                <td class="tbody">${{ calculateSum('setUpCost') }}</td>
                                                <td class="tbody">${{ calculateSum('siteActivationCost') }}</td>
                                                <td class="tbody">${{ calculateSum('publicationCost') }}</td>
                                                <td style="color: #1d67b1" class="tbody">${{ calculateSum('sunkCost') }}</td>
                                                
                                                <td class="tbody" *ngIf="showColumn">{{ calculateSum('numberOfScreened') }}</td>
                                                <td class="tbody" *ngIf="showColumn">{{ calculateSum('numberOfEnrolled') }}</td>
                                                <td class="tbody">${{ calculateSum('screeningCost') }}</td>
                                                <td class="tbody">${{ calculateSum('recruitmentCosts') }}</td>
                                                <td class="tbody">${{ calculateSum('treatmentCostPerPatient') }}</td>
                                                <td class="tbody">${{ calculateSum('aeTreatmentCost') }}</td>
                                                <td style="color: #1d67b1" class="tbody">${{ calculateSum('variableCost') }}</td>
                                               
                                                <td style="color: #1d67b1" class="tbody">${{ calculateSum('totalCost') }}</td>
                                                <td style="color: #1d67b1" class="tbody">{{ (calculateSum('percentOfTotalBudget') * 100) | number:'1.2-2' }}%</td>

                                          </tr>
                                          
                                                                                  
                                     </ng-template>
  
                                     
                              </p-table>

                        </ng-template>
            </p-card>


  </div>

  <div class = "row mb-20"></div>

  <div fxLayout="row" fxLayoutAlign="start center">

            <p-card *ngIf="displayEnrollmentSummary"
                        header = "Study KPI Summary Data" 
                        subheader = "" 
                        [style] = "{ width: '100%' }"
                        class = "card-style">

                        <div style="display: flex; align-items: center;">  
                              <div style="flex: 1;"></div>
                              <button (click)="displayEnrollmentRateChart()">Display Enrollment Rate - Charts</button>
                        </div>  
                        
                        <ng-template pTemplate="header">

                              <p-table  [value]="enrollemtInfoPerSite" 
                                        [tableStyle]="{ 'min-width': '20rem' }"
                                        [scrollable]="true"
                                        scrollHeight="350px"> > 
                              
                                    <ng-template pTemplate="header">
                                          <tr>
                                          <th class="tbody">#</th>
                                          <th class="tbody">Study Name</th>
                                          <th class="tbody">IRB Site Name</th>
                                          <th pSortableColumn = "numberOfExpected"># of Expected Patients<p-sortIcon field="numberOfExpected"></p-sortIcon></th>
                                          <th pSortableColumn = "numberOfScreened"># of Screened Patients<p-sortIcon field="numberOfScreened"></p-sortIcon></th>
                                          <th pSortableColumn = "numberOfEnrolled"># of Enrolled Patients<p-sortIcon field="numberOfEnrolled"></p-sortIcon></th>
                                          <th pSortableColumn = "FPI">FPI<p-sortIcon field="FPI"></p-sortIcon></th>
                                          <th pSortableColumn = "expectedFPO">Expected FPO<p-sortIcon field="expectedFPO"></p-sortIcon></th>
                                          <th pSortableColumn = "actualFPO">Actual FPO<p-sortIcon field="actualFPO"></p-sortIcon></th>
                                          <th class="tbody">----</th>
                                          <th pSortableColumn = "siteEnrollemtRate">Site Enrollment Rate(per mth)<p-sortIcon field="siteEnrollemtRate"></p-sortIcon></th>
                                          <th pSortableColumn = "LPI">LPI<p-sortIcon field="LPI"></p-sortIcon></th>
                                          <th pSortableColumn = "expectedLPO"> Expected LPO<p-sortIcon field="expectedLPO"></p-sortIcon></th>
                                          <th pSortableColumn = "actualLPO">Actual LPO<p-sortIcon field="actualLPO"></p-sortIcon></th>
                                          <th class="tbody">----</th>
                                          <th class="tbody">Expected Data Analysis Results</th>
                                          <th class="tbody">Expected Publication Date </th>
                                          <th class="tbody">Actual Publication Date</th>
                                          </tr>
                                    </ng-template>

                                    <ng-template pTemplate="body"  let-rowData>
                                          <tr>
                                          <td style="color: #3398FF" class="tbody"><b>{{ rowData.count }}</b></td>
                                          <td class="tbody"><b>{{ rowData.studyName }}</b></td>
                                          <td class="tbody">{{ rowData.siteName }}</td>
                                          <td style="color: #074786"class="tbody"><b>{{ rowData.numberOfExpected }}</b></td>
                                          <td class="tbody">{{ rowData.numberOfScreened }}</td>
                                          <td class="tbody">{{ rowData.numberOfEnrolled }}</td>
                                          <td class="tbody">{{ rowData.FPI |date:'d MMMM y' }}</td>
                                          <td class="tbody">{{ rowData.expectedFPO |date:'d MMMM y' }}</td>
                                          <td class="tbody">{{ rowData.actualFPO |date:'d MMMM y' }}</td>
                                          <td class="tbody"></td>
                                          <td style="color: #0d7ceb" class="tbody"><b>{{ rowData.siteEnrollemtRate }}</b></td>  
                                          <td class="tbody">{{ rowData.LPI |date:'d MMMM y' }}</td>
                                          <td class="tbody">{{ rowData.expectedLPO |date:'d MMMM y' }}</td>
                                          </tr>
                                    </ng-template>
                              
                              </p-table>

                        </ng-template>
            </p-card>

  </div>

  <div class = "row mb-20"></div>

  <div fxLayout="row" fxLayoutAlign="start center">

      <p-card *ngIf="displayAggregates"
                  header = "Aggregate Budget Data" 
                  subheader = "" 
                  [style] = "{ width: '100%' }"
                  class = "card-style">
                  
                  <div style="display: flex; align-items: center;">
                        <button (click)="toggleAggregateColumn()">Hide # of Screened & Enrolled Patients' Columns</button>
                        <div style="flex: 1;"></div>
                        <button (click)="displayAggregatesChart()">Display Budget Aggregates - Charts</button>
                        <div style="flex: 1;"></div>
                        <button (click)="displayBudgetDetailsChart()">Display Budget Details at site</button>
                  </div>   
                  <ng-template pTemplate="header">

                        <p-table  [value]="budgetAggregate"
                                  styleClass = "p-datatable-striped" 
                                  [tableStyle]="{ 'min-width': '20rem' }" > 
                        
                              <ng-template pTemplate="header">
                                    <tr>
                                          <th class="tbody">IRB Review Cost($)</th>
                                          <th class="tbody">Material/Product Cost($)</th>
                                          <th class="tbody">Equipment Cost($)</th>
                                          <th class="tbody">Setup Cost($)</th>
                                          <th class="tbody">Site Activation Cost($)</th>
                                          <th class="tbody">publication Cost($)</th>
                                          <th class="tbody">Sunk Cost($)</th>

                                          <th class="tbody" *ngIf="showAggregateColumn"># of Screened Patients (s)</th>
                                          <th class="tbody" *ngIf="showAggregateColumn"># of Enrolled Patients (n)</th>
                                          <th class="tbody">Patients Screening Cost(*n)($)</th>
                                          <th class="tbody">Recruitment Costs(*s)($)</th>
                                          <th class="tbody">Treatment Cost(*n)($)</th>
                                          <th class="tbody">AE Treatment Cost($)</th>
                                          <th class="tbody">Variable Cost($)</th>
                                         
                                          <th class="tbody">Total Cost($)</th>
                                          <th class="tbody">% of Requested Budget</th>
                                    </tr>
                              </ng-template>

                              <ng-template pTemplate="body" let-rowData>
                                    <tr>
                                          <td class="tbody" style="color: red; font-weight: bold;">${{ rowData.irbCost }}</td>
                                          <td class="tbody" style="color: red; font-weight: bold;">${{ rowData.materialCost }}</td>
                                          <td class="tbody" style="color: red; font-weight: bold;">${{ rowData.equipmentCost }}</td>
                                          <td class="tbody" style="color: red; font-weight: bold;">${{ rowData.setUpCost }}</td>
                                          <td class="tbody" style="color: red; font-weight: bold;">${{ rowData.siteActivationCost }}</td>
                                          <td class="tbody" style="color: red; font-weight: bold;">${{ rowData.publicationCost }}</td>
                                          <td class="tbody" style="color: red; font-weight: bold;">${{ rowData.sunkCost }}</td>

                                          <td class="tbody" style="color: red; font-weight: bold;" *ngIf="showAggregateColumn">{{ rowData.numberOfScreened }}</td>
                                          <td class="tbody" style="color: red; font-weight: bold;" *ngIf="showAggregateColumn">{{ rowData.numberOfEnrolled }}</td>
                                          <td class="tbody" style="color: red; font-weight: bold;">${{ rowData.screeningCost }}</td>
                                          <td class="tbody" style="color: red; font-weight: bold;">${{ rowData.recruitmentCosts }}</td>
                                          <td class="tbody" style="color: red; font-weight: bold;">${{ rowData.treatmentCostPerPatient }}</td>
                                          <td class="tbody" style="color: red; font-weight: bold;">${{ rowData.aeTreatmentCost }}</td>
                                          <td class="tbody" style="color: red; font-weight: bold;">${{ rowData.variableCost }}</td>
                                         
                                          <td class="tbody" style="color: red; font-weight: bold;">${{ rowData.totalCost }}</td>
                                          <td class="tbody" style="color: red; font-weight: bold;">{{ (rowData.percentOfTotalBudget * 100) | number:'1.2-2' }}%</td>
                                    </tr>

                              </ng-template>

                             <!-- <ngx-spinner *ngIf="isLoading" type="pacman">
                                    <p>Loading...</p>
                              </ngx-spinner> -->
                              
                        </p-table>

                  </ng-template>
      </p-card>

      
 </div>

<div class = "row mb-20"></div>

<div fxLayout="row" fxLayoutAlign="start center">

      <p-card *ngIf="displayAggregates"
                  header = "Study KPI Summary Data" 
                  subheader = "" 
                  [style] = "{ width: '100%' }"
                  class = "card-style">
                  
                  <div style="display: flex; align-items: center;">  
                        <div style="flex: 1;"></div>
                        <button (click)="displayEnrollmentRateChart()">Display/Hide Enrollment Analytics - Charts</button>
                  </div>    
                  <ng-template pTemplate="header">

                        <p-table  [value]="enrollmentAggregate" 
                                  [tableStyle]="{ 'min-width': '20rem' }" > 
                        
                              <ng-template pTemplate="header">
                                    <tr>
                                   <!-- <th class="tbody">Study - # of Expected Patients </th> -->
                                    <th class="tbody">Total # of Screened Patients</th>  
                                    <th class="tbody">Total # of Enrolled Patients</th>  
                                    <th class="tbody">Study FPI</th>
                                    <th class="tbody">Expected FPO</th>
                                    <th class="tbody">Actual FPO</th>
                                    <th class="tbody">------</th>
                                    
                                    <th class="tbody">Study LPI</th>
                                    <th class="tbody">Expected LPO</th>
                                    <th class="tbody">Actual LPO</th>
                                    <th class="tbody">------</th>
                                    <th class="tbody">Expected Data Analysis Results</th>
                                    <th class="tbody">Expected Publication Date </th>
                                    <th class="tbody">Actual Publication Date</th>
                                    </tr>
                              </ng-template>

                              <ng-template pTemplate="body"  let-rowData>
                                    <tr>
                                   <!-- <td class="tbody" style="color: #3398FF; font-weight: bold;">{{ rowData.numberOfExpected }}</td> To be revised at a later date-->
                                    <td class="tbody" style="color: red; font-weight: bold;">{{ rowData.numberOfScreened }}</td>
                                    <td class="tbody" style="color: red; font-weight: bold;">{{ rowData.numberOfEnrolled }}</td>
                                    <td class="tbody" style="color: red; font-weight: bold;">{{ rowData.studyFPI |date:'d MMMM y' }}</td>
                                    <td class="tbody" style="color: red; font-weight: bold;">{{ rowData.expectedFPO |date:'d MMMM y' }}</td>
                                    <td class="tbody" style="color: red; font-weight: bold;">{{ rowData.studyActualFPO |date:'d MMMM y' }}</td>
                                    <td class="tbody"></td>
                                    <td class="tbody" style="color: red; font-weight: bold;">{{ rowData.studyLPI |date:'d MMMM y' }}</td>
                                    <td class="tbody" style="color: red; font-weight: bold;">{{ rowData.expectedLPO |date:'d MMMM y' }}</td>
                                    <td class="tbody" style="color: red; font-weight: bold;">{{ rowData.studyActualLPO |date:'d MMMM y' }}</td>
                                    
                                    
                                    </tr>
                              </ng-template>
                        
                        </p-table>

                  </ng-template>
      </p-card>

</div>


<p-card *ngIf="showAggregatesChart"
class="floating-budgetCard"
cdkDrag  
[style] = "{ width: '500px' , height: '500px'}"
pTooltip="Drag & Position Around The Screen"
tooltipPosition="top">

<p-header class="centered-bold">Budget Aggregate Pie/bar Chart</p-header>
       
<p-tabView> 

<!--    <p-tabPanel header="Sunk & Variable Costs per Site" leftIcon="pi pi-chart-bar"> 

      <p-chart type="bar" 
            [data]="sunkAndVariableCostGraphData" 
            [options]="options3"
            width="400px" height="300px">
      </p-chart>

  </p-tabPanel> 

  <p-tabPanel header="Sunk Costs Details Per Site" leftIcon="pi pi-chart-bar"> 

      <p-chart type="bar" 
            [data]="sunkCostDetailsGraphData" 
            [options]="options3"
            width="400px" height="300px">
      </p-chart>

  </p-tabPanel> 

  <p-tabPanel header="Variable Costs Details Per Site" leftIcon="pi pi-chart-bar"> 

      <p-chart type="bar" 
            [data]="variableCostDetailsGraphData" 
            [options]="options3"
            width="400px" height="300px">
      </p-chart>

  </p-tabPanel> -->

<p-tabPanel header="Budget Aggregate Distribution" leftIcon="pi pi-chart-pie"> 

<p-chart type="pie" 
      [data]="budgetAggregateGraphData" 
      [options]="options1"
      width="400px" height="300px">
</p-chart>

</p-tabPanel>


<p-tabPanel header="Budget Aggregate Distribution" leftIcon="pi pi-chart-bar"> 
      
<p-chart type="bar" 
      [data]="budgetAggregateBarGraphData" 
      [options]="options2"
      width="400px" height="300px">
</p-chart>

</p-tabPanel>

<!--   <p-tabPanel header="Cost per Patient at Site" leftIcon="pi pi-chart-bar"> 
      
      <p-chart type="bar" 
            [data]="costPerPatientGraphData" 
            [options]="options2"
            width="400px" height="300px">
      </p-chart>

      </p-tabPanel>-->

</p-tabView>

</p-card>

<p-card *ngIf="showBudgetDetailsChart"
class="floating-budgetDetailsCard"
cdkDrag  
[style] = "{ width: '600px' , height: '500px'}"
pTooltip="Drag & Position Around The Screen"
tooltipPosition="top">

<p-header class="centered-bold">Budget Details at Sites</p-header>
       
<p-tabView> 

<p-tabPanel header="Sunk & Variable Costs per Site" leftIcon="pi pi-chart-bar"> 

      <p-chart type="bar" 
            [data]="sunkAndVariableCostGraphData" 
            [options]="options3"
            width="500px" height="300px">
      </p-chart>

  </p-tabPanel> 

  <p-tabPanel header="Sunk Costs Details Per Site" leftIcon="pi pi-chart-bar"> 

      <p-chart type="bar" 
            [data]="sunkCostDetailsGraphData" 
            [options]="options3"
            width="500px" height="300px">
      </p-chart>

  </p-tabPanel> 

  <p-tabPanel header="Variable Costs Details Per Site" leftIcon="pi pi-chart-bar"> 

      <p-chart type="bar" 
            [data]="variableCostDetailsGraphData" 
            [options]="options3"
            width="500px" height="300px">
      </p-chart>

  </p-tabPanel> 

<p-tabPanel header="Average Cost per Patient at Site" leftIcon="pi pi-chart-bar"> 
      
      <p-chart type="bar" 
            [data]="costPerPatientGraphData" 
            [options]="options2"
            width="500px" height="300px">
      </p-chart>

      </p-tabPanel>

</p-tabView>

</p-card>

<p-card *ngIf="showEnrollmentRateChart"
class="floating-enrollmentCard"
cdkDrag  
[style] = "{ width: '600px' , height: '500px'}"
pTooltip="Drag & Position Around The Screen"
tooltipPosition="top">

<p-header class="centered-bold">Enrollment Charts</p-header>
       
<p-tabView> 

<p-tabPanel header="Screened & Enrolled Number of Patients" leftIcon="pi pi-chart-bar"> 

<p-chart type="bar" 
      [data]="enrollmentDetailsGraphData" 
      [options]="options3"
      width="550px" height="300px">
</p-chart>

</p-tabPanel> 


<p-tabPanel header="Enrollment Rates (per Months)" leftIcon="pi pi-chart-bar"> 
      
<p-chart type="bar" 
      [data]="enrollmentRateGraphData" 
      [options]="options4"
      width="550px" height="300px">
</p-chart>

</p-tabPanel>


<p-tabPanel header="Time to FPI (Days)" leftIcon="pi pi-chart-bar"> 
      
<p-chart type="bar" 
      [data]="timetoFpiGraphData" 
      [options]="options5"
      width="550px" height="300px">
</p-chart>

</p-tabPanel>

</p-tabView>

</p-card>



<ngx-spinner type="pacman">
      <p>Loading...</p>
  </ngx-spinner>

 

