<div>
    <div class="row mb-1 justify-content-center">

        <div  class="col-lg-12 protocol" align="center">
            <h3>Protocol Evaluation</h3>


        </div>
        <div class="col-lg-12 text-center" *ngIf="!historyOnly">

            <form *ngIf="!busy" #f="ngForm" (ngSubmit)="onSubmit()">

                <div class="row mb-1">
                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <label for="siteName">Institution Name</label>
                        </div>
                    </div>

                    <div class="col-lg-4 col-xs-12">
                        <div class="form-group">
                            <input type="text" id="siteName" name="siteName" [(ngModel)]="site.siteName"
                                class="form-control" readonly>
                        </div>
                    </div>

                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <label for="studyName">Study Name</label>
                        </div>
                    </div>

                    <div class="col-lg-4 col-xs-12">
                        <div class="form-group">

                            <input type="text" id="studyName" name="studyName" [(ngModel)]="site.studyName"
                                class="form-control" readonly>
                        </div>
                    </div>
                </div>
                <div class="row mb-1">
                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <label for="geoLocation">Address</label>
                        </div>
                    </div>

                    <div class="col-lg-10 col-xs-12">
                        <div class="form-group">
                            <input type="text" id="geoLocation" name="geoLocation" [(ngModel)]="site.geoLocation"
                                class="form-control" readonly>
                        </div>
                    </div>
                </div>


                <div class="alert alert-primary" *ngIf="isCollapsed" (click)="isCollapsed = !isCollapsed"
                    aria-controls="siteInfo">Click here for more info on this site

                </div>
                <div class="alert alert-secondary" *ngIf="!isCollapsed" (click)="isCollapsed = !isCollapsed"
                    aria-controls="siteInfo">Click here for less info on this site

                </div>
                <div class="col-lg-12 text-center collapse" id='siteInfo' [ngbCollapse]="isCollapsed">

                    <div class="card">
                        <div class="card-body">
                            <!---->
                            <div class="row mb-1">
                                <div class="col-lg-2 col-xs-12">
                                    <div class="form-group">
                                        <label for="piName">PI </label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xs-12">
                                    <div class="form-group">
                                        <input type="text" id="piName" name="piName" [(ngModel)]="site.piName"
                                            class="form-control" placeholder="Name" readonly>
                                    </div>
                                </div>

                                <div class="col-lg-2 col-xs-12">
                                    <div class="form-group">
                                        <label for="piEmail"> </label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xs-12">
                                    <div class="form-group">
                                        <input type="email" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                            id="piEmail" name="piEmail" [(ngModel)]="site.piEmail" class="form-control"
                                            placeholder="Email" readonly>
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-1">
                                <div class="col-lg-2 col-xs-12">
                                    <div class="form-group">
                                        <label for="siteResearchCoordinatorName">Site Ressearch Coordinator</label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xs-12">
                                    <div class="form-group">
                                        <input type="text" id="siteResearchCoordinatorName"
                                            name="siteResearchCoordinatorName"
                                            [(ngModel)]="site.siteResearchCoordinatorName" class="form-control"
                                            placeholder="Name" readonly>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-xs-12">
                                    <div class="form-group">
                                        <label for="piEmail"></label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xs-12">
                                    <div class="form-group">
                                        <input type="email" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                            id="siteResearchCoordinatorEmail" name="siteResearchCoordinatorEmail"
                                            [(ngModel)]="site.siteResearchCoordinatorEmail" class="form-control"
                                            placeholder="Email" readonly>
                                    </div>
                                </div>

                            </div>

                            
                            <div class="row mb-1">
                                <div class="col-lg-2 col-xs-12">
                                    <div class="form-group">
                                        <label for="msl">MSL</label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xs-12">
                                    <div class="form-group">
                                     
                                        <input type="text" id="msl" name="msl" [(ngModel)]="site.msl"
                                            class="form-control" placeholder="Name" readonly>
                                      
                                    </div>
                                </div>
                                <div class="col-lg-2 col-xs-12">
                                    <div class="form-group">
                                        <label for="mslEmail"></label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xs-12">
                                    <div class="form-group"> 
                                        
                                        <input type="email" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                            id="mslEmail" name="mslEmail" [(ngModel)]="site.mslEmail"
                                            class="form-control" placeholder="Email" readonly>
                                      
                                    </div>
                                </div>

                            </div>

                            <!---->
                            <div class="row mb-1">

                                <div class="col-lg-2 col-xs-12">

                                    <div class="form-group">
                                        <label for="diseaseArea">Disease Area</label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xs-12">

                                    <div class="form-group">
                                        <input type="text" id="diseaseArea" name="diseaseArea"
                                            [(ngModel)]="site.diseaseArea" class="form-control" readonly>
                                    </div>
                                </div>

                                <div class="col-lg-2 col-xs-12">

                                    <div class="form-group">
                                        <label for="numberOfPatients">Proposed Number of Patients (N)</label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xs-12">

                                    <div class="form-group">
                                        <input type="text" id="numberOfPatients" name="numberOfPatients"
                                            [(ngModel)]="site.numberOfPatients" class="form-control" readonly>
                                    </div>
                                </div>  

                            </div>

                            <div class="row mb-1">


                                <div class="col-lg-2 col-xs-12">
                                    <div class="form-group">
                                        <label for="protocol">Concept Submited on</label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xs-12">
                                    <div class="form-group">
                                        {{site.conceptSubmissionDate|date:'d MMMM y'}}
                                    </div>
                                </div>

                            </div>

                            <div class="row mb-1">

                                <div class="col-lg-2 col-xs-12">
                                    <div class="form-group">
                                        <label for="concept">Concept Evaluated on</label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xs-12">
                                    <div class="form-group">
                                        {{site.conceptEvaluationDate|date:'d MMMM y'}}
                                    </div>
                                </div>
                                <div class="col-lg-2 col-xs-12">

                                    <div class="form-group">
                                        <label for="conceptEvaluationStatus">Status</label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xs-12">

                                    <div class="form-group">
                                        <!-- -->
                                        <input type="text" class="form-control" id="conceptEvaluationStatus"
                                            name="conceptEvaluationStatus" [(ngModel)]="site.conceptEvaluationStatus"
                                            readonly>
                                    </div>
                                </div>


                            </div>
                            <div class="row mb-1">

                                <div class="col-lg-2 col-xs-12">

                                    <div class="form-group">
                                        <label for="conceptEvaluationComment">Evaluation Comment</label>
                                    </div>
                                </div>
                                <div class="col-lg-10 col-xs-12">

                                    <div class="form-group">
                                        <input type="text" id="conceptEvaluationComment" name="conceptEvaluationComment"
                                            [(ngModel)]="site.conceptEvaluationComment" class="form-control"
                                            placeholder="Evaluation Comment" readonly>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-1">
                                <div class="col-lg-2 col-xs-12">
                                    <div class="form-group">
                                        <label for="concept">Protocol Submited on</label>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-xs-12">
                                    <div class="form-group">
                                        {{site.protocolSubmissionDate|date:'d MMMM y'}}
                                    </div>
                                </div>
                                <div class="col-lg-2 col-xs-12">
                                    <div class="form-group">
                                        <label for="concept">Protocol file link</label>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-xs-12">
                                    <div class="form-group alert alert-primary">
                                        link to protocol file here
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mb-1" *ngIf="LastEvaluationDate!=null">
                    <div class="col-lg-12 col-xs-12 fontRed">
                        <p><b>This is a re-evaluation</b>, the protocol has been last evaluated on&nbsp;
                            <b> {{LastEvaluationDate|date:'d MMMM y'}}</b>&nbsp; and review team decision was
                            &nbsp;<b>{{LastStatus}}</b></p>
                    </div>
                </div>

   <!-- Addition if TRIAl in PROGRESS  -->
 <div *ngIf="trialStarted">
    <div class="row mb-1">
        <div class="col-lg-2 col-xs-12 fontRed">
            <label
                for="trialStarted" style="font-weight: bold;"> Trial In Progress (Protocol Amendment)</label>
        </div>
        <div class="col-lg-2 col-xs-12">
            

            <input type="checkbox" id="trialStarted"  name="trialStarted" [(ngModel)]="trialStarted"readonly>

        </div>

        <div class="col-lg-2 col-xs-12"></div>
        <div class="col-lg-2 col-xs-12 fontRed">
            <div class="form-group" *ngIf="trialStarted">
                <label for="protocolVersion">Protocol Version</label>
            </div>
        </div>

        <div class="col-lg-2 col-xs-12">
            <div class="form-group" *ngIf="trialStarted">
                <input type="text" id="protocolVersion" name="protocolVersion"
                    [(ngModel)]="site.protocolVersion" class="form-control" readonly>
            </div>
        </div>
    </div>
    <div *ngIf="trialStarted">
    <div class="row mb-1">

        <div class="col-lg-2 col-xs-12 fontRed">

            <div class="form-group">
                <label for="changeSummary">Change Summary</label>
            </div>
        </div>
        <div class="col-lg-10 col-xs-12">

            <div class="form-group">
                <textarea id="changeSummary" name="changeSummary" class="form-control"
                    placeholder="Change Summary" [(ngModel)]="site.changeSummary" readonly></textarea>
            </div>
        </div>
    </div>
    <div class="row mb-1 flex-container">
        <div class="col-lg-2 col-xs-12 fontRed">

            <div class="form-group">
                <label for="hightLights" style="font-weight: bold;">Changes Highlight</label>
            </div>
        </div>
        
    
        <div class="col-lg-3 col-xs-12 fontRed">
            <div class="form-group">
                <label for="trialGoalChanged"> Trial Goals Changed</label>
                <input type="checkbox" id="trialGoalChanged" name="trialGoalChanged" [(ngModel)]="site.trialGoalChanged" readonly>
            </div>
        </div>

        <div class="col-lg-3 col-xs-12 fontRed">
            <div class="form-group">
                <label for="trialDesignChanged"> Trial Design Changed</label>
                <input type="checkbox" id="trialDesignChanged" name="trialDesignChanged" [(ngModel)]="site.trialDesignChanged" readonly>
            </div>
        </div>

        <div class="col-lg-3 col-xs-12 fontRed">
            <div class="form-group">
                <label for="statisticalAnalysisImpacted"> Statistical Analysis Impacted</label>
                <input type="checkbox" id="statisticalAnalysisImpacted" name="statisticalAnalysisImpacted" [(ngModel)]="site.statisticalAnalysisImpacted" readonly>
            </div>
        </div>
      
    </div>
    
    <div class="row mb-10 "></div>
    <div class="row mb-1 flex-container">

        <div class="col-lg-1 col-xs-12 fontRed"> </div>

        <div class="col-lg-3 col-xs-12 fontRed">
            <label for="stopTrial">Stop Trial Pending Protocol Revision </label>
        </div>
        <div class="col-lg-1 col-xs-12 fontRed">
          <input type="checkbox" id="stopTrial" name="stopTrial" [(ngModel)]="site.stopTrial" class="redBox" readonly>
        </div>

        <div class="col-lg-3 col-xs-12 fontRed">
            <label for="notification">Regulatory Agencies Notifications are Required</label>
        </div>
        <div class="col-lg-1 col-xs-12 fontRed">
          <input type="checkbox" id="notificationRequired" name="notificationRequired" [(ngModel)] ="site.notificationRequired" class="redBox" readonly>
        </div> 

        <div class="col-lg-2 col-xs-12 fontRed">
            <label for="notification">Require IRB Approval </label>
        </div>
        <div class="col-lg-1 col-xs-12 fontRed">
          <input type="checkbox" id="irbApprovalRequired" name="irbApprovalRequired" [(ngModel)] ="site.irbApprovalRequired" class="redBox" readonly>
       </div>

    </div>

</div>
</div>
<!--End of TRIAL in PROGRESS scenario-->

<!--Products requested and product marketing status-->
<div class="row mb-10 "></div>
<div class="row mb-10 "></div>

        <div class="row mb-1">
                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <label for="concept">Requested Product</label>
                           
                        </div>
                    </div>
                    <div class="col-lg-4 col-xs-12">
                        <div class="form-group">
                         <div *ngIf="LastEvaluationDate==null">
                            <input type="string" id="site.requestedProduct" name="site.requestedProduct"
                                [(ngModel)]="site.requestedProduct" class="form-control"
                                 placeholder="Input Requested Product for this Study" required>
                         </div>

                         <div *ngIf="LastEvaluationDate!=null">
                        
                            <input type="string" id="site.requestedProduct" name="site.requestedProduct"
                                [(ngModel)]="site.requestedProduct" class="form-control" readonly>
                         </div>

                        </div>
                    </div>

                    <div class="col-lg-2 col-xs-12">

                        <div class="form-group">
                            <label for="productMarketingStatus">Product Marketing Status</label>
                        </div>
                    </div>

                    <div class="col-lg-4 col-xs-12">

                        <div class="form-group">
                            <div *ngIf="LastEvaluationDate==null">  
                            <select class="form-control" id="productMarketingStatus" name="productMarketingStatus"
                                [(ngModel)]="site.productMarketingStatus" placeholder="Select the Product Marketing Status" required>
                                <option value="Marketed Product">Marketed Product</option>
                                <option value="Product in Development">Product in Development</option>
                                <option value="NA">NA</option> 
                          </select>
                            </div>

                            <div *ngIf="LastEvaluationDate!=null">  
                                <input class="form-control" id="productMarketingStatus" name="productMarketingStatus"
                                    [(ngModel)]="currentMarketingStatus" readonly>
                                   <!-- <option value="Marketed Product">Marketed Product</option>
                                    <option value="Product in Development(awaiting commercialization authorization)">Product in Development</option>
                                    <option value="NA">NA</option>-->
                                <!--</select>-->
                            </div>

                        </div>
                    </div>


        </div>

        <!--END of Products requested and product marketing status-->

                <div class="row mb-1">
                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <label for="concept">Protocol Evaluation Date</label>
                        </div>
                    </div>
                    <div class="col-lg-4 col-xs-12">
                        <div class="form-group">
                            <input type="date" id="protocolEvaluationDate" name="protocolEvaluationDate"
                                [(ngModel)]="site.protocolEvaluationDate" class="form-control"
                                (change)="evaluationDate()" placeholder="Protocol Evaluation Date" required autofocus>
                        </div>
                    </div>
                    

                    <div class="col-lg-2 col-xs-12">

                        <div class="form-group">
                            <label for="protocolEvaluationStatus">Evaluation Decision</label>
                        </div>
                    </div>

                    <div class="col-lg-4 col-xs-12">

                        <div class="form-group">
                            
                            <select class="form-control" id="protocolEvaluationStatus" name="protocolEvaluationStatus"
                                [(ngModel)]="site.protocolEvaluationStatus" required>
                                <option value="Approved">Approved</option>
                                <option value="Rejected">Rejected</option>
                                <option value="Conditional approval pending revisions">Pending Revision</option>
                            </select>
                        </div>
                    </div>
                    
                    <div *ngIf="!validEvaluationDate && evaluationDateErrorMessage.length>5"
                        class="col-lg-12 alert alert-danger">
                        {{evaluationDateErrorMessage}}
                    </div>

                </div>
                
                <!--......................................................-->
                <div class="row mb-1">
                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <label for="Pharmacovigilance">Pharmacovigilance Review</label>
                        </div>
                    </div>
                    <div class="col-lg-4 col-xs-12">
                        <div class="form-group">
                            <!-- Radio button-->
                            <!--select class="form-control" id="Pharmacovigilance" name="Pharmacovigilance"
                                required>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                                <option value="NA">Not Applicable</option>
                            </select-->
                            
  <input type="radio" id="Yes" name="Pharmacovigilance" [(ngModel)]="site.pharmacoVigilance" value="Yes">
  <label for="Yes"> &nbsp;Yes</label><br>
  <input type="radio" id="No" name="Pharmacovigilance" [(ngModel)]="site.pharmacoVigilance" value="No">
  <label for="No">&nbsp; No</label><br>
  <input type="radio" id="NA" name="Pharmacovigilance" [(ngModel)]="site.pharmacoVigilance" value="NA">
  <label for="NA"> &nbsp;NA</label><br>
                        </div>
                    </div>

                    <div class="col-lg-2 col-xs-12">

                        <div class="form-group">
                            <label for="pharmacovigilancecomment">Pharmacovigilance Comments </label>
                        </div>
                    </div>

                    <div class="col-lg-4 col-xs-12">

                        <div class="form-group">
                            <!--multi line text ox -->
                             <textarea id="pharmacoVigilanceComment"    
                            name="pharmacoVigilanceComment"
                            [(ngModel)]="site.pharmacoVigilanceComment" 
                                class="form-control"
                                placeholder="Pharmacovigilance comments if applicable" ></textarea>
                        </div>
                    </div>

                <!--    <div *ngIf="!validEvaluationDate && evaluationDateErrorMessage.length>5"
                        class="col-lg-12 alert alert-danger">
                        {{evaluationDateErrorMessage}}
                    </div>  -->

                </div>
                <div class="row mb-1">

                    <div class="col-lg-2 col-xs-12">

                        <div class="form-group">
                            <label for="protocolEvaluationComment">Evaluation Comments</label>
                        </div>
                    </div>
                    <div class="col-lg-10 col-xs-12">

                        <div class="form-group">
                            <input type="text" id="protocolEvaluationComment" name="protocolEvaluationComment"
                                [(ngModel)]="site.protocolEvaluationComment" class="form-control"
                                placeholder="Protocol Evaluation Comment" required>
                        </div>
                    </div>
                </div>
                
                <div class="row mb-1">
                    <div class="col-lg-2 col-xs-12">
                    </div>
                    <div class="col-lg-4 col-xs-12">
                        <div class="form-group">
                            <a class="btn btn-lg btn-primary btn-block" [routerLink]="['/siteSetup']"
                                role="button">Cancel</a>
                        </div>
                    </div>
                    <div class="col-lg-2 col-xs-12">
                    </div>
                    <div class="col-lg-4 col-xs-12">
                        <div class="form-group">
                            <button class="btn btn-lg btn-primary btn-block" type="submit"
                                [disabled]="!f.form.valid || !validEvaluationDate">
                                Update
                            </button>
                        </div>
                    </div>
                </div>

                <div *ngIf="error" class="alert alert-danger mt-3" role="alert">
                    <strong>Oops!</strong> {{error}}
                </div>
            </form>

        </div>
    </div>

    <ngx-spinner type="pacman">
    <p>Loading...</p></ngx-spinner>
</div>