import { Component, OnInit, ViewChild, ViewChildren, QueryList, NgZone } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { distinct, finalize } from 'rxjs/operators';
import { MapGeocoder } from '@angular/google-maps';  //For converting adresses into geographic coordinates (Lat, Lng)//
import { MapInfoWindow, MapMarker, GoogleMap, MapMarkerClusterer } from '@angular/google-maps';
import { SiteSetup, ISiteSetup } from 'src/app/shared/interfaces/siteSetup';
import { Irb, IIrb } from 'src/app/shared/interfaces/irb';
import { Patient, IPatient } from 'src/app/shared/interfaces/patient';
import { Treatment, ITreatment } from 'src/app/shared/interfaces/treatment';
import { AuthService } from '../../core/authentication/auth.service';
import { IssAdministrationService } from '../iss-administration.service';
import { PiAdministrationService } from 'src/app/principal-investigator/pi-administration.service';
import { Router, CanActivate, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Marker } from 'src/app/shared/interfaces/markers';
import { PositionMarker } from 'src/app/shared/interfaces/positionMarker';
import { GraphValue } from 'src/app/shared/interfaces/graphValues';
import { IMaterial, Material } from 'src/app/shared/interfaces/material';
import { NumberSymbol } from '@angular/common';
import { BatchDisplay } from 'src/app/shared/interfaces/batchDisplay';



@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.sass']

})

export class AnalyticsComponent implements OnInit {

  thisistheinfo: string = "The Info";
  summaryData: string[] = [];
 
  site:ISiteSetup;
  siteSetups:ISiteSetup[] = [];
  SiteSetup:ISiteSetup[] = [];
  siteSetupsFilteredByStatus:ISiteSetup[] = [];
  siteSetupsFilteredByCountry:ISiteSetup[] = [];
  siteSetupsFilteredByStatusCount:number;
  siteSetupsFilteredByCountryCount:number = 0;
  siteSetupsCount:number = 0;  //AAA
  siteSetupsFilteredCount: number;  //AAA
  siteSetupsFilteredByStatusCountforGraph:Number = 0;  //AAA
  currentSiteId:number;
  siteCountryList:string[] = [];

  irb:IIrb;
  irbs: IIrb[] = [];
  irbsFilteredByStatus:IIrb[] = [];  // For IRB filteriing---08/30/2023
  irbsFilteredByCountry:IIrb[] = [];
  irbCount: number = 0;
  irbsFilteredCount: number = 0;  //AAA
  irbsFilteredByStatusCount: number = 0;  //AAA...08/30/2023
  irbsFilteredByCountryCount: number = 0;
  irbId:number = 0;
  irbGraphValues:GraphValue[] = [];
  irbGraphvalue:GraphValue;
  irbFilteredByStatusCountforGraph: number = 0;
  currentIrbId: number;

  IRBstatusString: string;
  IRBstatusNumber: string;  //="0"
  irbCountryList: string[] =[];
  irbDistinctCountryList: string[] =[];
  irbDistinctCountryListCount: number;
  irbSiteNameList: string[] = [];
  irbDistinctSiteNameList: string[] = [];
  irbDistinctSiteNameListCount: number;
  irbPiNameList: string[] = [];
  irbDistinctPiNameList: string[] = [];
  distinctAEListAtSite: string[] = [];
  irbDistinctPiNameListCount: number;

  patientListBusy:boolean = false;
  patient: IPatient;
  patientList: IPatient[] = []; 
  patientListFiltered: IPatient[] = [];
  enrolledPatientListAtSite: IPatient[] = [];
  patientListFilteredCount: number; // To count the filtering result
  patientStatusGraphValues: GraphValue[] = [];
  patientStatusGraphValue: GraphValue;
  patientEnrollmentGraphValues: GraphValue[] = [];
  patientEnrollmentGraphValue: GraphValue;
  patientTreatmentGraphValues: GraphValue[] = [];
  patientTreatmentGraphValue: GraphValue;
  NofPatientsInTreatment: number;
  NofPatientsAwaitingTreatment: number;
  //patientsOnSelectedBatchCount: number;
  orderedDates: Array<any>=[];
  patientListFilteredCountforGraph:number = 0;
  patientId: number;
  FPI: Date = new Date();  

  
  treatmentList:ITreatment[];
  treatmentListFiltered:ITreatment[];
  treatmentAuditCount:number; 
  treatmentsWithProtocol: ITreatment[] = [];
  treatmentsForAE: ITreatment[] = [];
  allTreatmentsForAE: any[] = [];
  treatmentsWithProtocolAndBatchList: any[] = []; 
  treatmentsWithBatch: ITreatment[] = [];
  batchAndTreatmentNumberArrayForSite: any[] = [];
  allTreatmentWithBatchAtSite: Array<number> = []; 
  numberOfTreatmentForThisBatch: number =0;
  //availableBatchNumbersAtSite:string;
  enrolledPatientIdArray: Array<number> = [];
  treatmentDatesArray:Date[] = [];
  firstTreatmentDatesArray: Date[] = [];
  firstTreatmentDate: Date = new Date();
  patientNumberAwaitingConsentSignature: string[] = [];
  treatmentBusy: boolean = false;

  patientsAwaitingFirstTreatmentID :Array<string>;
  patientsInTreatmentID : Array<number>;
  summaryEnrollmentData: any[] = [];
  enrollmentRate: any[] = [];
  knobEnrollmentMax: number;
  
  value1: number ;
  value2: number ;
  value3: number ;
  value4: number ;
  AECount: number;
  AECounts: number;
  PatientWithAE: number;
  patientsWithAECounts: number;

  statusString: string;
  typeString: string;
  studyStatus: string = '0';
  statusNumber: string;  //="0";
  typeNumber: string = "0";
  enterCountryName: string = '';
  selectedDiseaseArea: string ='';
  selectedSiteName: string = '';
  selectedStudyName: string = '';
  selectedPiName: string = '';
  CountryList: string[] =[];
  diseaseAreaList: string[] = [];
  distinctCountryList: string[] =[];
  //distinctCountryListCount: number;
  distinctDiseaseAreaList: string[] = [];
  
  
  studyNameList: string[] = [];
  distinctStudyNameList: string[] = [];
  piNameList: string[] = [];
  distinctPiNameList: string[] = [];
  siteNameList: string[] = [];
  distinctSiteNameList: string[] = [];

  conceptOperationData: any[] = [];
  protocolOperationData: any[] = [];
  contractOperationData: any[] = [];
  irbsOperationData1: any[] = [];
  irbsOperationData2: any[] = [];
  patientEnrollmentOverTimeGraphData: any;
  enrollmentPlotTimeSerie: any[] = [];
  studyOperationalStatusGraphData: any;
  studyEvaluationStatusGraphData: any;
  irbPatientEnrollmentGraphData: any;
  irbPatientTreatmentGraphData: any;
  irbReviewStatusGraphData: any;
  irbOperationalStatusGraphData: any;
  aEDistributionGraphData: any;
  averageTimeIrbSubmissionToApproval: number = 0;
  studyDurationSinceIrbApproval: number = 0;

  options1: any;
  options2: any;
  options3: any;
  options4: any;
  options5: any;
  options6: any;
  options7: any;

  material: IMaterial;
  materialInventoryList: IMaterial[] = [];
  //materialInventoryListFiltered: IMaterial[] = [];
  materialInventoryListCount: number;
  batchInfo: any[] = [];
  //updatedBatchInfo: any[] = [];
  updatedBatchInfo: BatchDisplay[] = [];   // for testing
  remainingDays: number;
  batchStatus: any[];

  markerIndex: number = 0;
 
  markerFilteredIndex: number = 0;
  markerFilteredIrbIndex: number = 0;
  markersCount: number;  //AAA
  markersFilteredCount: number;   //AAA
  IRBmarkersFilteredByStatusCount: number = 0; // AAA 08/23/2023
  markers: Marker[] = [];
  markersFiltered: Marker[] = [];
  IRBmarkersFilteredByStatus: Marker[] = [];
 
  markersPositionFiltered: Array<number>;
  marker:Marker;
  markerFiltered: Marker;
  //markerClusters: MapMarkerClusterer[] = [];
  //markerClusterer!: MapMarkerClusterer;
  minLatitudeForZooming: number = 0;
  maxLongitudeForZooming: number = 0;
  latitude: string;
  longitude: string;
 
  graphValues: GraphValue[] = [];
  graphValue: GraphValue;
  Index: number = 0; 
 
  displayCards: boolean = false;
  displayCharts: boolean = false;
  displayOperationalCharts: boolean = false;
  displayActionTable: boolean = false;

  busy: boolean = false;
  address: string;
  display: any;
  description: string;
  zoom: number = 2.0;
  center: google.maps.LatLngLiteral;



  conceptIconMarkerURL: string =  'http://maps.google.com/mapfiles/ms/icons/blue.png';
  protocolIconMarkerURL: string = 'http://maps.google.com/mapfiles/ms/icons/green.png';
  contractIconMarkerURL: string = 'http://maps.google.com/mapfiles/ms/icons/orange.png';
  docRequestIconMarkerURL: string = 'http://maps.google.com/mapfiles/ms/icons/purple.png';
  rejectedIconMarkerURL: string = 'http://maps.google.com/mapfiles/ms/icons/red-dot.png';
  missingIconMarkerURL: string = '';

  IrbMarker: any = {
    path: google.maps.SymbolPath.CIRCLE,
    fillColor: '#0000FF',
    fillOpacity: 0.7,
    strokeColor: '#FFFFFF',
    strokeOpacity: 1.0,
    strokeWeight: 1,
    scale: 16,
  };
  IrbRequestedMarker: any = {
    path: google.maps.SymbolPath.CIRCLE,
    fillColor: '#756d6d',
    fillOpacity: 1.0,
    strokeColor: '#FFFFFF',
    strokeOpacity: 1.0,
    strokeWeight: 1,
    scale: 16,
  };
  IrbSubmittedMarker: any = {
    path: google.maps.SymbolPath.CIRCLE,
    fillColor: '#0000FF',
    fillOpacity: 0.9,
    strokeColor: '#FFFFFF',
    strokeOpacity: 1.0,
    strokeWeight: 1,
    scale: 16,
  };
  IrbRevisionPendingMarker: any = {
    path: google.maps.SymbolPath.CIRCLE,
    fillColor: '#ff7034',
    fillOpacity: 1.0,
    strokeColor: '#FFFFFF',
    strokeOpacity: 1.0,
    strokeWeight: 1,
    scale: 16,
  };
  IrbApprovedMarker: any = {
    path: google.maps.SymbolPath.CIRCLE,
    fillColor: '#097969',
    fillOpacity: 0.9,
    strokeColor: '#FFFFFF',
    strokeOpacity: 1.0,
    strokeWeight: 1,
    scale: 16,
  };
  //conceptRejectedIconMarkerURL:   string ='';
  //protocolRejectedIconMarkerURL:   string = '';

  markerInfoContent = '';  //AAA


  markerOptions: google.maps.MarkerOptions = {

    draggable: false,
    animation: google.maps.Animation.DROP,
    visible: true,
    opacity: 1,
    optimized: true,
    //zIndex:Number(google.maps.Marker.MAX_ZINDEX) + 20,

    label: {
      color: '#FFFFFF',
      text: 'H',
    }

  };


  constructor(private route: ActivatedRoute,

    private zone: NgZone,
    private geocoder: MapGeocoder,
    private authService: AuthService,
    private router: Router,
    private administrationService: IssAdministrationService,
    private piAdministrationService: PiAdministrationService,
    private spinner: NgxSpinnerService) { }
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow | undefined;


  // @VMapMarkerClustereriewChildren(MapInfoWindow) infoWindowsView: QueryList<MapInfoWindow>;
  ngOnInit(): void {
    this.center = { lat: 21.885, lng: 1.279 };
    // get all markers
    this.markers = [];
    //this.markerClusters = [];
    //this.markerClusters = new MapMarkerClusterer(this.map, this.zone);
    
    this.zone.run(() => {
      // This code will be executed in response to a change detection event.
    });

    this.getAllPoints();

  }
  searchCountry = (): void => {

  }

  getAllPoints(): void {
    this.getSiteSetups();

    this.getAllIrbs();

  }

  getPatientList = (irbId: number): void => {
     this.patientListBusy=true;
    this.showSpinner();

    this.piAdministrationService.getAllPatient(this.authService.authorizationHeaderValue, irbId)
      .pipe(finalize(() => {
        this.patientListBusy=false;
        this.hideSpinner();
      })).subscribe(
        result => {
          this.patientList = result;

          this.patientListFiltered = result;
          this.patientListFilteredCount = this.patientListFiltered.length; //To count the result of filtering

         // console.log("All Patients @ IRB", this.currentIrbId, this.patientList);

          this.PatientVectorGenerator(this.patientList);
          this.PatientEnrollmentVectorGenerator(this.patientList);
          this.selectIrbOperationalAnalytics(this.currentIrbId, this.enrolledPatientIdArray ,this.irbsOperationData2);
        
        });

       }

  getTreatmentListForTable = (patientId:number): any => {
    this.treatmentBusy = true;
    this.treatmentList = [];
    this.treatmentAuditCount = 0;
    this.treatmentsWithProtocol = [];
    this.treatmentsForAE = [];
    
    this.piAdministrationService.getPatientTreatments(this.authService.authorizationHeaderValue, patientId) 
      .pipe(finalize(() => {
        this.treatmentBusy=false;
        this.hideSpinner()
      })).subscribe(
        result => {
         
         this.treatmentList = result;
         this.treatmentAuditCount=this.treatmentList.length; // counting the number of treatments/visits in the list 

           //Step #3 - Fltering for Treatment "According to Protocol"

          this.treatmentsWithProtocol = this.treatmentList.filter(aTreatment=>
               aTreatment.treatmentType == 0);   //Filter treatment with protocol

          this.treatmentsForAE =  this.treatmentList.filter(aTreatment=>
            aTreatment.treatmentType == 1);   //Filter treatment with for AEs    
     
             console.log("Step #2-3 - Results of individual treatment filtering  ", this.treatmentList, this.treatmentsWithProtocol, this.treatmentsForAE);

          this.listingTreatmentsWithProtocol();
          this.PatientTreatmentVectorGenerator(patientId);
         
        }); 
        return  this.treatmentsWithProtocol, this.treatmentsForAE
       }



  getMaterialInventoryList = (irbId: number): void => {
    
    this.piAdministrationService.getAllMaterialInventory(this.authService.authorizationHeaderValue, irbId)//this.irbId will be provided here, currently "3"
      .pipe(finalize(() => {
        //this.patientListBusy=false;
        this.hideSpinner();
      })).subscribe(
        result => {
          this.materialInventoryList = result;
          //this.materialInventoryListFiltered = result;
          this.materialInventoryListCount = this.materialInventoryList.length; //To count the result of filtering

    console.log("Inventory List",this.materialInventoryList);
    //console.log(this.materialInventoryList);
    //console.log("IRB site # ",this.currentIrbId,", # of Batches =",this.materialInventoryListCount);
          
          this.materialSummaryTable(this.currentIrbId);
          this.UpdatedMaterialSummaryTable(this.currentIrbId, this.numberOfTreatmentForThisBatch);
          
        });

  }

  getSiteSetups(): void {
    this.spinner.show();
    this.administrationService.getIssSites(this.authService.authorizationHeaderValue)
      .pipe(finalize(() => {
        this.busy = false;
        this.hideSpinner();
      }))
      .subscribe(
        result => {
          this.siteSetups = result;
          this.siteSetupsFilteredByStatus = result;
          this.siteSetupsFilteredByCountry = result;
          this.siteSetupsCount = this.siteSetups.length; // AAA counting the number of sites in the list
          this.siteSetupsFilteredByStatusCount = this.siteSetupsFilteredByStatus.length; // Counting filtering result     

          this.findDistinctSiteVariableList(this.siteSetupsCount);
       

        for (let i=0; i<this.siteSetupsCount;i++)
        
        
          {
            this.marker = new Marker();
            this.marker.position = new PositionMarker();
            this.marker.position.lat = Number(this.siteSetups[i].latitude);
            this.marker.position.lng = Number(this.siteSetups[i].longitude);
            this.marker.id = this.siteSetups[i].id;
            this.marker.markerIndex = this.markerIndex;
            this.markerOptions.title = this.siteSetups[i].geoLocation;
            this.marker.title = "Study - "+  this.markerOptions.title;/// so it can be used it in the HTML component

              this.marker.options = String(this.MarkerURL(this.siteSetups[i], i));  //introduced for the Marker URL selection
              this.markerOptions.icon = this.marker.options;

            this.markerOptions.zIndex= Number(google.maps.Marker.MAX_ZINDEX) + 10*i;
            this.marker.zIndex = this.markerOptions.zIndex;
            
           
            this.markers.push(this.marker);
            this.markersCount = this.markers.length;
            this.markersFilteredCount = this.markersCount;
          
            this.getInfoview(this.siteSetups[i].id,this.markerIndex);
            this.markerIndex++;
          }

          this.conceptOperationalAnalytics(this.siteSetups, this.siteSetupsCount);
          this.protocolOperationalAnalytics(this.siteSetups, this.siteSetupsCount);
          this.contractOperationalAnalytics(this.siteSetups, this.siteSetupsCount);
         
          this.SitesVectorGenerator(this.siteSetups);  
        
      });
      
  }


 //To select the unique Countries, Disease Area, Study NAme, Pi Name and Site Name from the studies List
        findDistinctSiteVariableList=(irbCountOrSiteCount: number): any =>{
          let countryTemp: string[] = [];
          let diseaseAreaTemp: string[] = [];
          let studyNameTemp: string[] = [];
          let piNameTemp: string[] = [];
          let siteNameTemp: string[] = [];

          for (let i = 0; i < irbCountOrSiteCount; i++) {
            countryTemp.push(this.siteSetups[i].country);   //Site Countries
            diseaseAreaTemp.push(this.siteSetups[i].diseaseArea); //Disease Areas
            studyNameTemp.push(this.siteSetups[i].studyName); //Study Names
            piNameTemp.push(this.siteSetups[i].piName); //Pi Names
            siteNameTemp.push(this.siteSetups[i].siteName); //Pi Names
            
        }
        this.siteCountryList = Array.from(new Set(countryTemp));
        this.siteCountryList.sort((a, b) => a.localeCompare(b));

        this.distinctDiseaseAreaList = Array.from(new Set(diseaseAreaTemp));
        this.distinctDiseaseAreaList.sort((a, b) => a.localeCompare(b));

        this.distinctStudyNameList = Array.from(new Set(studyNameTemp));
        this.distinctStudyNameList.sort((a, b) => a.localeCompare(b));

        this.distinctPiNameList = Array.from(new Set(piNameTemp));
        this.distinctPiNameList.sort((a, b) => a.localeCompare(b));

        this.distinctSiteNameList = Array.from(new Set(siteNameTemp));
        this.distinctSiteNameList.sort((a, b) => a.localeCompare(b));

        console.log('Disease Areas', this.distinctDiseaseAreaList);
        console.log('Study Names', this.distinctStudyNameList);
        console.log('Pi Names', this.distinctPiNameList);
        console.log('Site Names', this.distinctSiteNameList);
        console.log('Distinct Countries (for Studies)', this.siteCountryList);

        return this.siteCountryList, this.distinctDiseaseAreaList, this.distinctStudyNameList, this.distinctPiNameList
        }



  distinctIrbVariables= (): any =>{
    
    let tempIrbCountryList:string[] = [];
    let tempIrbSiteNameList: string[] = [];
    let tempIrbPiNameList: string[] = [];

    for (let i = 0; i < this.irbCount; i++) {
      if (this.irbs[i].longitude != null && this.irbs[i].latitude != null)
    {
    this.irbCountryList.push(this.irbs[i].country);   //IRB Countries
    }
      this.irbSiteNameList.push(this.irbs[i].siteName);
      this.irbPiNameList.push(this.irbs[i].piName);
    }
  
   tempIrbCountryList = this.siteCountryList.concat(this.irbCountryList);  //Assembling the country list
   this.irbDistinctCountryList = Array.from(new Set(tempIrbCountryList));  //Select distinct country from list
   this.irbDistinctCountryList.sort((a, b) => a.localeCompare(b));          // order distinct countries Array in alphabetic order

   tempIrbSiteNameList = this.siteNameList.concat(this.irbSiteNameList)
   this.irbDistinctSiteNameList = Array.from(new Set(tempIrbSiteNameList));
   this.irbDistinctSiteNameList.sort((a, b) => a.localeCompare(b));
   
   tempIrbPiNameList = this.distinctPiNameList.concat(this.irbPiNameList)
   this.irbDistinctPiNameList = Array.from(new Set(tempIrbPiNameList));
   this.irbDistinctPiNameList.sort((a, b) => a.localeCompare(b));
   

   //console.log('All countries from 2 sets',this.siteCountryList,this.irbCountryList,tempIrbCountryList);
    console.log('Distinct IRB Pis',this.irbDistinctPiNameList);
    console.log('Distinct IRB Site Names',this.irbDistinctSiteNameList);
    console.log('Distinct Countries',this.irbDistinctCountryList);
  
    return this.irbDistinctCountryList, this.irbDistinctSiteNameList, this.irbDistinctPiNameList
   
  } 
  conceptOperationalAnalytics = (aSite:ISiteSetup[], i:number):any =>{

    this.conceptOperationData = [];

  for (i = 0; i < this.siteSetupsCount; i++) {

    //Initialiazation
let submissionDate = 0;
let evaluationDate = 0;
let timeDifference = 0;


let localDate = new Date();
let currentDate = new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000);  //convert local time to UTC 
let firstDate = new Date(this.siteSetups[i].conceptSubmissionDate );
let lastDate = new Date(this.siteSetups[i].conceptEvaluationDate);

    if (this.siteSetups[i].conceptEvaluationDate != null && 
      this.siteSetups[i].conceptEvaluationStatus !='Pending additional information' && 
      this.siteSetups[i].conceptEvaluationStatus != null)
        {


//Calculation

    submissionDate = Math.round((firstDate.getTime())/(1000 * 60 * 60 * 24)); 
    evaluationDate = Math.round((lastDate.getTime())/(1000 * 60 * 60 * 24));

    timeDifference = Math.round((evaluationDate - submissionDate)); // /(1000 * 60 * 60 * 24));

//console.log([this.siteSetups[i].siteName,timeDifference]);

    this.conceptOperationData.push([this.siteSetups[i].siteName, timeDifference]);

       }

  else
  {
    submissionDate = Math.round((firstDate.getTime())/(1000 * 60 * 60 * 24)); 
    evaluationDate = Math.round((currentDate.getTime())/(1000 * 60 * 60 * 24));

    timeDifference = Math.round((evaluationDate - submissionDate)); // /(1000 * 60 * 60 * 24));

//console.log([this.siteSetups[i].siteName,timeDifference]);

    this.conceptOperationData.push([this.siteSetups[i].siteName, timeDifference]);
  }
}

  console.log('Concept Evaluation Duration',this.conceptOperationData);
  

  return this.conceptOperationData
}


  protocolOperationalAnalytics = (aSite:ISiteSetup[], i:number):any =>{

  this.protocolOperationData = [];

  for (i = 0; i < this.siteSetupsCount; i++) {

    //Initialiazation
    let submissionDate = 0;
    let evaluationDate = 0;
    let timeDifference = 0;


    let localDate = new Date();
    let currentDate = new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000);  //convert local time to UTC 
    let firstDate = new Date(this.siteSetups[i].protocolSubmissionDate );
    let lastDate = new Date(this.siteSetups[i].protocolEvaluationDate);

    if (this.siteSetups[i].protocolEvaluationDate != null && this.siteSetups[i].protocolSubmissionDate !=null &&
      this.siteSetups[i].protocolEvaluationStatus !='Conditional approval pending revisions' && 
      this.siteSetups[i].protocolEvaluationStatus != null)
        {


//Calculation

    submissionDate = Math.round((firstDate.getTime())/(1000 * 60 * 60 * 24)); 
    evaluationDate = Math.round((lastDate.getTime())/(1000 * 60 * 60 * 24));

    timeDifference = Math.round((evaluationDate - submissionDate)); // /(1000 * 60 * 60 * 24));

//console.log([this.siteSetups[i].siteName,timeDifference]);

    this.protocolOperationData.push([this.siteSetups[i].siteName, timeDifference]);

       }

  else
  {

    if (this.siteSetups[i].protocolSubmissionDate ==null) {submissionDate = 0,  evaluationDate = 0}
    
    else{

    submissionDate = Math.round((firstDate.getTime())/(1000 * 60 * 60 * 24)); 
    evaluationDate = Math.round((currentDate.getTime())/(1000 * 60 * 60 * 24));
    }
   
    timeDifference = Math.round((evaluationDate - submissionDate)); // /(1000 * 60 * 60 * 24));

//console.log([this.siteSetups[i].siteName,timeDifference]);

    this.protocolOperationData.push([this.siteSetups[i].siteName, timeDifference]);
  }
  }

  //console.log('Protocol Evaluation Duration',this.protocolOperationData);


  return this.protocolOperationData
}

contractOperationalAnalytics = (aSite:ISiteSetup[], i:number):any =>{

  this.contractOperationData = [];

  for (i = 0; i < this.siteSetupsCount; i++) {

    //Initialiazation
let submissionDate = 0;
let evaluationDate = 0;
let timeDifference = 0;


let localDate = new Date();
let currentDate = new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000);  //convert local time to UTC 
let firstDate = new Date(this.siteSetups[i].protocolEvaluationDate );
let lastDate = new Date(this.siteSetups[i].contractEvaluationDate);

    if (this.siteSetups[i].protocolEvaluationDate !=null && this.siteSetups[i].protocolEvaluationStatus =='Approved' && 
        this.siteSetups[i].contractEvaluationDate != null && this.siteSetups[i].contractEvaluationStatus == 'Approved' )
        {


//Calculation

    submissionDate = Math.round((firstDate.getTime())/(1000 * 60 * 60 * 24)); 
    evaluationDate = Math.round((lastDate.getTime())/(1000 * 60 * 60 * 24));

    timeDifference = Math.round((evaluationDate - submissionDate)); // /(1000 * 60 * 60 * 24));

//console.log([this.siteSetups[i].siteName,timeDifference]);

    this.contractOperationData.push([this.siteSetups[i].siteName, timeDifference]);

       }

  else 
  {
if(this.siteSetups[i].protocolEvaluationDate !=null && this.siteSetups[i].protocolEvaluationStatus =='Rejected' ||
this.siteSetups[i].protocolEvaluationDate != null && this.siteSetups[i].protocolEvaluationStatus =='Conditional approval pending revisions' ||
this.siteSetups[i].protocolEvaluationDate ==null)
{ 
  submissionDate = 0;
  evaluationDate = 0;
  timeDifference = 0;
  this.contractOperationData.push([this.siteSetups[i].siteName, timeDifference]);
}
else{



    submissionDate = Math.round((firstDate.getTime())/(1000 * 60 * 60 * 24)); 
    evaluationDate = Math.round((currentDate.getTime())/(1000 * 60 * 60 * 24));
    
   
   timeDifference = Math.round((evaluationDate - submissionDate)); // /(1000 * 60 * 60 * 24));

    //console.log([this.siteSetups[i].siteName,timeDifference]);

    this.contractOperationData.push([this.siteSetups[i].siteName, timeDifference]);
  }
  }
  }

    //console.log('Contract Negotiation Duration',this.contractOperationData);


      return this.contractOperationData
  }

   ////////////////////////////////////////////////////////////////////////////////////////////////

 irbsOperationalAnalytics = (aIrb:IIrb[], i:number):any =>{
  this.irbsOperationData1 = [];
  this.irbsOperationData2 = [];
  this.averageTimeIrbSubmissionToApproval = 0;


  for (i = 0; i < this.irbCount; i++) {

    //Initialiazation
    let creationDate = 0;
    let submitDate = 0;
    let timeIrbRequestToSubmission = 0;

      
    let localDate = new Date();
    let currentDate = new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000);  //convert local time to UTC 
    let firstDate = new Date(this.irbs[i].dateCreated);
    let halfwayDate = new Date(this.irbs[i].submissionDate);


    if (this.irbs[i].submissionDate != null )
        {

      //Calculation
    
    creationDate = Math.round((firstDate.getTime())/(1000 * 60 * 60 * 24)); 
    submitDate = Math.round((halfwayDate.getTime())/(1000 * 60 * 60 * 24));

    timeIrbRequestToSubmission = Math.round((submitDate - creationDate)); 
    this.irbsOperationData1.push([this.irbs[i].siteName, timeIrbRequestToSubmission]);

        }  
        else
        {

    creationDate = Math.round((firstDate.getTime())/(1000 * 60 * 60 * 24)); 
    submitDate = Math.round((currentDate.getTime())/(1000 * 60 * 60 * 24));

    timeIrbRequestToSubmission = Math.round((submitDate  - creationDate)); 
    this.irbsOperationData1.push([this.irbs[i].siteName, timeIrbRequestToSubmission]);

        }
        
      }
      
     console.log('IRB, Request-to-Submission Duration', this.irbsOperationData1);


      for (i = 0; i < this.irbCount; i++) {

                //Initialiazation
            let submitDate = 0;
            let evaluationDate = 0;
            let timeIrbSubmissionToReview = 0;
            let timeSinceIrbApproval = 0;
            let today = 0;
            
          
                  
            let localDate = new Date();
            let currentDate = new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000);  //convert local time to UTC 
            let halfwayDate = new Date(this.irbs[i].submissionDate);
            let lastDate = new Date(this.irbs[i].reviewDate);
    
        if (this.irbs[i].submissionDate != null && this.irbs[i].outcome == true)
            {
    
        //Calculation
        
            submitDate = Math.round((halfwayDate.getTime())/(1000 * 60 * 60 * 24)); 
            evaluationDate = Math.round((lastDate.getTime())/(1000 * 60 * 60 * 24));
            today = Math.round((currentDate.getTime())/(1000 *60 * 60 *24));
          
            timeIrbSubmissionToReview = Math.round((evaluationDate - submitDate)); 
            timeSinceIrbApproval = Math.round((today - evaluationDate));
            
            this.irbsOperationData2.push([this.irbs[i].siteName, timeIrbSubmissionToReview, timeSinceIrbApproval]);
        
  
            }  
            else
            {
              if (this.irbs[i].submissionDate !=null && this.irbs[i].outcome == false)
              {
                    submitDate = Math.round((halfwayDate.getTime())/(1000 * 60 * 60 * 24)); 
                    evaluationDate = Math.round((currentDate.getTime())/(1000 * 60 * 60 * 24));
                
                    timeIrbSubmissionToReview = Math.round((evaluationDate  - submitDate)); 
                    
                    this.irbsOperationData2.push([this.irbs[i].siteName, timeIrbSubmissionToReview, timeSinceIrbApproval]);
              }
              else 
              { 
                if (this.irbs[i].submissionDate = null)

                    timeIrbSubmissionToReview = 0;
                    timeSinceIrbApproval = 0;
                    
                    this.irbsOperationData2.push([this.irbs[i].siteName, timeIrbSubmissionToReview, timeSinceIrbApproval])

               }
           }
             
          }
          this.averageTimeIrbSubmissionToApproval = (this.irbsOperationData2.reduce((total, row) => total + row[1], 0)) / (this.irbsOperationData2.length);
          
          let mean = this.averageTimeIrbSubmissionToApproval;
          let secondColumn1 = this.irbsOperationData1.map(row => row[1]);
          let secondColumn2 = this.irbsOperationData2.map(row => row[1]);
          //console.log(secondColumn1, secondColumn2);
          let median1 = this.findingMedian(secondColumn1);
          let median2 = this.findingMedian(secondColumn2);
          
          let variance = this.irbsOperationData2.map(row => Math.pow(row[1] - mean, 2)).reduce((total, value) => total + value) / this.irbsOperationData2.length;
          let standardDeviation = Math.sqrt(variance);

          console.log('Average (Days) = ', Math.round(this.averageTimeIrbSubmissionToApproval));
          console.log('Median1 = ', median1,'  Median2 = ', median2);
          console.log('Variance = ', Math.round(variance));
          console.log('Standard Deviation (Days) = ', Math.round(standardDeviation));
          

          //console.log('IRB Operational Analytics Data', [this.irbsOperationData1, this.irbsOperationData2]);

  return this.irbsOperationData1, this.irbsOperationData2

}

selectIrbOperationalAnalytics = (currenIrbId:number, enrolledPatientIdArray:Array<number> ,irbsOperationData2:any[]): any =>{

  let rowIndex:number = null;
  let rowIndex1: number = null;
  this.enrollmentRate = [];
  this.studyDurationSinceIrbApproval = 0;

  if(this.enrolledPatientIdArray.length != 0) 
  {

  rowIndex1 = this.irbs.findIndex(row => row.id === this.currentIrbId);
  rowIndex = this.irbsOperationData2.findIndex(row => row[0] === this.irbs[rowIndex1].siteName);  //to find the row's index associated with siteName
  
  this.studyDurationSinceIrbApproval = Math.round(((irbsOperationData2[rowIndex][2])/30)*100)/100;   //Time since IRB approval in months
  this.enrollmentRate = [Math.round(((this.enrolledPatientIdArray.length)/((irbsOperationData2[rowIndex][2])/30))*1000)/1000]; //rounded to 2 decimal points
  
  console.log(this.currentIrbId, this.irbs[rowIndex1].siteName);
  console.log(this.currentIrbId, this.studyDurationSinceIrbApproval, this.enrollmentRate);

  }
  else
  {
    this.enrollmentRate = ['N/A - IRB Not Yet Approved']; //rounded to 2 decimal points
    this.studyDurationSinceIrbApproval = 0;  //Time since IRB approval in months
  }

  return this.studyDurationSinceIrbApproval, this.enrollmentRate
}

 findingMedian = (values:Array<number>): number => {
  
  if (values.length === 0) return 0;

      values.sort(function(a,b){
        return a-b;
      });

  var half = Math.floor(values.length / 2);

  if (values.length % 2)
    return values[half];
  else
    return (values[half - 1] + values[half]) / 2.0;
}

 

  //ALL 4 Filters Implementatiuon 

filterSites =(): void =>{
  this.filterByStatus();
 
  this.filterByCountry(this.enterCountryName);
 
  };
   
  filterIRBs =(): void =>{
    
   this.filterByIrbStatus();
    
   this.filterIrbByCountry(this.enterCountryName)
    };

  //.............................................................................................//
  //..'MarkerURL' and 'MarkerURLFiltered' are used to select the colored icon associated with the status of the study. The colors are same one you will see in the site-Setup module ..//

  MarkerURL(a: ISiteSetup, i: number): string {

    {
      let a = this.siteSetups[i];


      if ((a.conceptSubmissionDate != null && a.conceptEvaluationStatus == null) ||
        (a.conceptSubmissionDate != null && a.conceptEvaluationStatus == 'Pending additional information'))
        return this.conceptIconMarkerURL;    //Concept       


      if ((a.conceptEvaluationStatus == 'Approved' && a.protocolSubmissionDate == null) ||
        (a.protocolSubmissionDate != null && a.protocolEvaluationStatus == null) ||
        (a.protocolEvaluationStatus != 'Approved' && a.protocolEvaluationStatus != 'Rejected'))
        return this.protocolIconMarkerURL;  //Protocol

      if ((a.protocolEvaluationStatus == 'Approved' && a.contractEvaluationStatus != 'Approved'))
        return this.contractIconMarkerURL;   //Negotiation


      if ((a.contractEvaluationStatus == 'Approved'))
        return this.docRequestIconMarkerURL;    //Document 

      if ((a.conceptEvaluationStatus == 'Rejected'))
        return this.rejectedIconMarkerURL;   //Rejected

      if ((a.protocolEvaluationStatus == 'Rejected'))
        return this.rejectedIconMarkerURL;   //Rejected


    }
  }

  MarkerURLFiltered(a: ISiteSetup, b: ISiteSetup, i: number, enterCountryName: string): string {

    if (this.enterCountryName == '') {
      let a = this.siteSetupsFilteredByStatus[i];



      if ((a.conceptSubmissionDate != null && a.conceptEvaluationStatus == null) ||
        (a.conceptSubmissionDate != null && a.conceptEvaluationStatus == 'Pending additional information'))
        return this.conceptIconMarkerURL;    //Concept       


      if ((a.conceptEvaluationStatus == 'Approved' && a.protocolSubmissionDate == null) ||
        (a.protocolSubmissionDate != null && a.protocolEvaluationStatus == null) ||
        (a.protocolEvaluationStatus != 'Approved' && a.protocolEvaluationStatus != 'Rejected'))
        return this.protocolIconMarkerURL;  //Protocol

      if ((a.protocolEvaluationStatus == 'Approved' && a.contractEvaluationStatus != 'Approved'))
        return this.contractIconMarkerURL;   //Negotiation


      if ((a.contractEvaluationStatus == 'Approved'))
        return this.docRequestIconMarkerURL;    //Document 

      if ((a.conceptEvaluationStatus == 'Rejected'))
        return this.rejectedIconMarkerURL;   //Rejected

      if ((a.protocolEvaluationStatus == 'Rejected'))
        return this.rejectedIconMarkerURL;   //Rejected

    }

    else {
      let b = this.siteSetupsFilteredByCountry[i];

      if ((b.conceptSubmissionDate != null && b.conceptEvaluationStatus == null) ||
        (b.conceptSubmissionDate != null && b.conceptEvaluationStatus == 'Pending additional information'))
        return this.conceptIconMarkerURL;    //Concept       


      if ((b.conceptEvaluationStatus == 'Approved' && b.protocolSubmissionDate == null) ||
        (b.protocolSubmissionDate != null && b.protocolEvaluationStatus == null) ||
        (b.protocolEvaluationStatus != 'Approved' && b.protocolEvaluationStatus != 'Rejected'))
        return this.protocolIconMarkerURL;  //Protocol

      if ((b.protocolEvaluationStatus == 'Approved' && b.contractEvaluationStatus != 'Approved'))
        return this.contractIconMarkerURL;   //Negotiation


      if ((b.contractEvaluationStatus == 'Approved'))
        return this.docRequestIconMarkerURL;    //Document 

      if ((b.conceptEvaluationStatus == 'Rejected'))
        return this.rejectedIconMarkerURL;   //Rejected

      if ((b.protocolEvaluationStatus == 'Rejected'))
        return this.rejectedIconMarkerURL;   //Rejected

    }

  }

  irbMarkerColorFiltered(a:IIrb[], b:IIrb[], i:number, enterCountryName:string):string{
   
    if (this.enterCountryName == ''){
      let a = this.irbsFilteredByStatus[i];
  
      if (a.reviewDate != undefined && a.reviewDate != null) {

        if (a.outcome == true) {
          return this.IrbApprovedMarker;
        }
        if (a.outcome == false) {
          return this.IrbRevisionPendingMarker;
        }
      }
      if (a.submissionDate != undefined && a.submissionDate != null) {
        return this.IrbSubmittedMarker;
      }
      return this.IrbRequestedMarker;
  
    }   
    
    else 

    {
      let b = this.irbsFilteredByCountry[i];
  
      if (b.reviewDate != undefined && b.reviewDate != null) {


        if (b.outcome == true) {
          return this.IrbApprovedMarker;
        }
        if (b.outcome == false) {
          return this.IrbRevisionPendingMarker;
        }
      }
      if (b.submissionDate != undefined && b.submissionDate != null) {
        return this.IrbSubmittedMarker;
      }
      return this.IrbRequestedMarker;
  
    }   

   }

  //the Clearmarkers function is designed to wipe out all marker from the map at the begening of the activating a Filter//
  ClearMarkers(a: Marker[], i: number): void {
    {

      for (i = 0; i < this.siteSetupsCount; i++) {
        this.markers[i] == null;

      }
      this.markers.length = 0;


    }
  }

  FilteredMarkers(aSite: ISiteSetup[], siteSetupsFilteredByCountry: ISiteSetup[], i: number, enterCountryName: string): any {

      this.markerIndex = 0;
      this.markerFilteredIndex = 0;

    if (this.enterCountryName == '') {

      //console.log(aSite);
      //console.log(this.siteSetupsFilteredByStatus)
      //console.log(this.siteSetupsFilteredByStatusCount);

      this.ClearMarkers(this.markersFiltered, this.siteSetupsCount);
      
      // let i:number=0;
      for (i = 0; i < this.siteSetupsFilteredByStatusCount; i++) {
        this.markerFiltered = new Marker();
        this.markerFiltered.position = new PositionMarker();
        this.markerFiltered.position.lat = Number(this.siteSetupsFilteredByStatus[i].latitude);
        this.markerFiltered.position.lng = Number(this.siteSetupsFilteredByStatus[i].longitude);
        this.markerFiltered.id = this.siteSetupsFilteredByStatus[i].id;
        this.markerFiltered.markerIndex = this.markerIndex;  //AAA

        this.markerOptions.title = "Study - " + this.siteSetupsFilteredByStatus[i].geoLocation;
        this.markerFiltered.title = this.markerOptions.title; /// So it can be used it in the HTML component


        this.markerFiltered.options = String(this.MarkerURLFiltered(this.siteSetupsFilteredByStatus[i], this.siteSetupsFilteredByCountry[i], i, this.enterCountryName));  //introduced for the Marker URL selection
        this.markerOptions.icon = this.markerFiltered.options;

        this.markerOptions.zIndex = Number(google.maps.Marker.MAX_ZINDEX) + 10 * i
        this.markerFiltered.zIndex = this.markerOptions.zIndex

        this.markersFiltered.push(this.markerFiltered);
        this.markersFilteredCount = this.markersFiltered.length;

        this.getInfoview(this.siteSetupsFilteredByStatus[i].id, this.markerIndex)
        this.markerIndex++;

      }


    }

    else {

      console.log(aSite);
      console.log(this.siteSetupsFilteredByCountry)
      console.log(this.siteSetupsFilteredByCountryCount);

      this.ClearMarkers(this.markersFiltered, this.siteSetupsCount);

      //let i: number = 0;
      for (i = 0; i < this.siteSetupsFilteredByCountryCount; i++) {
        this.markerFiltered = new Marker();
        this.markerFiltered.position = new PositionMarker();
        this.markerFiltered.position.lat = Number(this.siteSetupsFilteredByCountry[i].latitude);
        this.markerFiltered.position.lng = Number(this.siteSetupsFilteredByCountry[i].longitude);
        this.markerFiltered.id = this.siteSetupsFilteredByCountry[i].id;
        this.markerFiltered.markerIndex = this.markerFilteredIndex;  //AAA

        this.markerOptions.title = "Study - " + this.siteSetupsFilteredByCountry[i].geoLocation;
        this.markerFiltered.title = this.markerOptions.title; /// So it can be used it in the HTML component


        this.markerFiltered.options = String(this.MarkerURLFiltered(this.siteSetupsFilteredByStatus[i], this.siteSetupsFilteredByCountry[i], i, this.enterCountryName));  //introduced for the Marker URL selection
        this.markerOptions.icon = this.markerFiltered.options;

        this.markerOptions.zIndex = Number(google.maps.Marker.MAX_ZINDEX) + 10 * i
        this.markerFiltered.zIndex = this.markerOptions.zIndex

        this.markersFiltered.push(this.markerFiltered);
        this.markersFilteredCount = this.markersFiltered.length;

        this.getInfoview(this.siteSetupsFilteredByCountry[i].id, this.markerFilteredIndex)
        this.markerFilteredIndex++;

      }
    }
    
      return this.markersFiltered
    

  }

  IrbMarkerColor(aIrb: Irb, IRBstatusString: string):any{

    if (aIrb.reviewDate != undefined && aIrb.reviewDate != null) {


      if (aIrb.outcome == true) {
        return this.IrbApprovedMarker;
      }
      if (aIrb.outcome == false) {
        return this.IrbRevisionPendingMarker;
      }
    }
    if (aIrb.submissionDate != undefined && aIrb.submissionDate != null) {
      return this.IrbSubmittedMarker;
    }
    return this.IrbRequestedMarker;

   };

   FilteredIrbMarkers(aIrb:IIrb[], i:number, enterCountryName:string, IRBstatusString: string): any {  
          
    this.markerIndex = 0;
    this.markerFilteredIrbIndex = 0;
    
    this.ClearMarkers(this.markersFiltered, this.siteSetupsCount);
    
  for (i = 0; i<this.irbsFilteredByStatusCount; i++) {
    if(this.irbsFilteredByStatus[i].latitude!=null &&
      this.irbsFilteredByStatus[i].longitude!=null &&
      this.irbsFilteredByStatus[i].country!=null)
      {

  if(this.enterCountryName == '') {
  
    
    this.markerFiltered = new Marker();
    this.markerFiltered.position = new PositionMarker();
    this.markerFiltered.position.lat = Number(this.irbsFilteredByStatus[i].latitude);
    this.markerFiltered.position.lng = Number(this.irbsFilteredByStatus[i].longitude);
    
    this.markerOptions.title = this.irbsFilteredByStatus[i].geoLocation;
    this.markerFiltered.title = "Irb - " +this.markerOptions.title; /// So it can be used it in the HTML component
    
    
    this.markerFiltered.options = this.IrbMarkerColor(this.irbs[i], this.IRBstatusString);  //introduced for the IRB Marker color selection selection
    
    this.markerOptions = {
                        icon: this.IrbMarker,
                        zIndex: Number(google.maps.Marker.MAX_ZINDEX) + 100*i,
                          };
    
    this.markerFiltered.label = {text: this.irbsFilteredByStatus[i].id.toString(),
                                  color:'#FFFFFF',
                                };

    this.markerFiltered.id = this.irbsFilteredByStatus[i].id;
    this.markerFiltered.markerIndex = this.markerIndex;

    this.markersFiltered.push(this.markerFiltered);
    this.markersFilteredCount = this.markersFiltered.length;

    this.getIrbInfoview(this.irbsFilteredByStatus[i].id,this.markerIndex)
    this.markerIndex++;
    

   }

   if(this.enterCountryName == '' && this.IRBstatusString != '" "') {
    this.markerFiltered = new Marker();
    this.markerFiltered.position = new PositionMarker();
    this.markerFiltered.position.lat = Number(this.irbsFilteredByStatus[i].latitude);
    this.markerFiltered.position.lng = Number(this.irbsFilteredByStatus[i].longitude);
    
    this.markerOptions.title = this.irbsFilteredByStatus[i].geoLocation;
    this.markerFiltered.title = "Irb - " +this.markerOptions.title; /// So it can be used it in the HTML component
    
    this.markerFiltered.options = (this.irbMarkerColorFiltered(this.irbsFilteredByStatus, this.irbsFilteredByCountry, i, enterCountryName));  //introduced for the Marker URL selection
   

    this.markerOptions = {
                            icon: this.IrbMarker,
                            zIndex: Number(google.maps.Marker.MAX_ZINDEX) + 100*i,
                         };

    this.markerFiltered.label = {text: this.irbsFilteredByStatus[i].id.toString(),
                                 color:'#FFFFFF',
                                };

    this.markerFiltered.id = this.irbsFilteredByStatus[i].id;
    this.markerFiltered.markerIndex = this.markerFilteredIrbIndex;

    this.markersFiltered.push(this.markerFiltered);
    this.markersFilteredCount = this.markersFiltered.length;

    this.getIrbInfoview(this.irbsFilteredByStatus[i].id,this.markerFilteredIrbIndex)
    this.markerFilteredIrbIndex++;
   
    //console.log([this.markerFiltered.id, this.markerFiltered.title, this.markerFiltered.position.lat , this.markerFiltered.position.lng]);

    
  } 
  
  else {
        this.markerFiltered = new Marker();
        this.markerFiltered.position = new PositionMarker();
        this.markerFiltered.position.lat = Number(this.irbsFilteredByCountry[i].latitude);
        this.markerFiltered.position.lng = Number(this.irbsFilteredByCountry[i].longitude);
        
        this.markerOptions.title = this.irbsFilteredByCountry[i].geoLocation;
        this.markerFiltered.title = "Irb - " +this.markerOptions.title; /// So it can be used it in the HTML component
        
        this.markerFiltered.options = (this.irbMarkerColorFiltered(this.irbsFilteredByStatus, this.irbsFilteredByCountry, i, enterCountryName));  //introduced for the Marker URL selection
       

        this.markerOptions = {
                                icon: this.IrbMarker,
                                zIndex: Number(google.maps.Marker.MAX_ZINDEX) + 100*i,
                             };

        this.markerFiltered.label = {text: this.irbsFilteredByCountry[i].id.toString(),
                                     color:'#FFFFFF',
                                    };

        this.markerFiltered.id = this.irbsFilteredByCountry[i].id;
        this.markerFiltered.markerIndex = this.markerFilteredIrbIndex;

        this.markersFiltered.push(this.markerFiltered);
        this.markersFilteredCount = this.markersFiltered.length;

        this.getIrbInfoview(this.irbsFilteredByCountry[i].id,this.markerFilteredIrbIndex)
        this.markerFilteredIrbIndex++;
       
        //console.log([this.markerFiltered.id, this.markerFiltered.title, this.markerFiltered.position.lat , this.markerFiltered.position.lng]);

        
      } 
      }
  
}

return this.markersFiltered
}


  filterByStatus = (): any => {
    var myStatusString = [" ", "Concept Evaluation", "Concept Re-evaluation", "Rejected (Concept)", "Protocol Submission", "Protocol Evaluation",
      "Protocol Re-evaluation", "Rejected (Protocol)", "Contract Negotiation", "Documents Check",
      "All IRBs"]


    let index = Number(this.statusNumber);
    this.statusString = myStatusString[index];

    {
      if (index == 0) {
        this.ClearMarkers(this.markersFiltered, this.siteSetupsCount);

        this.siteSetupsFilteredByStatus = this.siteSetups;
        this.siteSetupsFilteredByStatusCount = this.siteSetupsFilteredByStatus.length; // Counting filtering result

        this.markersFiltered = this.markers;
        this.markersFiltered = this.FilteredMarkers(this.siteSetupsFilteredByStatus, this.siteSetupsFilteredByCountry, this.siteSetupsFilteredByStatusCount, this.enterCountryName);
        this.markersFilteredCount = this.markersFiltered.length; // Counting filtering result

      }

      else {

        this.ClearMarkers(this.markersFiltered, this.siteSetupsCount);
        this.siteSetupsFilteredByStatus = [];

        this.siteSetupsFilteredByStatus = this.siteSetups.filter((aSite, i) =>
          this.EvaluationStatus(aSite, i) == this.statusString);
        this.siteSetupsFilteredByStatusCount = this.siteSetupsFilteredByStatus.length; // Counting filtering result

        this.markersFiltered = this.markers;
        this.markersFiltered = this.FilteredMarkers(this.siteSetupsFilteredByStatus, this.siteSetupsFilteredByCountry, this.siteSetupsFilteredByStatusCount, this.enterCountryName);   //  AAA
        this.markersFilteredCount = this.markersFiltered.length; // Counting filtering result

      }



      if (index == 10) {
        this.ClearMarkers(this.markersFiltered, this.siteSetupsCount);

        this.irbsFilteredByStatus = this.irbs
        this.irbCount = this.irbs.length;  //Counting original number of IRB


        this.irbsFilteredByStatusCount = this.irbsFilteredByStatus.length; // Counting IRB filtering result

        this.markersFiltered = this.markers;
        this.markersFiltered = this.FilteredIrbMarkers(this.irbsFilteredByStatus, this.irbsFilteredByStatusCount, this.enterCountryName, this.IRBstatusString);
        this.markersFilteredCount = this.markersFiltered.length; // Counting filtering result


       // console.log(this.irbsFilteredByStatus)
        //console.log(this.markersFiltered)
        //console.log(this.markersFilteredCount)

      }
    }
    return [this.siteSetupsFilteredByStatus, this.markerFiltered, this.markers]
  }

  // STARTING FILTERING

  EvaluationStatus = (aSite: ISiteSetup, i: number): string => {
    {
      let a = this.siteSetups[i];

      if (a.contractEvaluationStatus == 'Approved') {
        return 'Documents Check';   //Document
      }

      if (a.contractEvaluationStatus == 'Conditional approval pending revisions') {
        return 'Contract Negotiation';   //Negotiation
      }

      if (a.protocolEvaluationStatus == 'Approved' && a.contractEvaluationStatus != 'NULL') {
        return 'Contract Negotiation';   //Negotiation
      }

      if (a.protocolEvaluationStatus == 'Conditional approval pending revisions') {
        return 'Protocol Re-evaluation';  //Protocol
      }

      if (a.protocolEvaluationStatus != null && a.protocolEvaluationDate == null) {
        return 'Protocol Submission';  //Protocol
      }

      if (a.conceptEvaluationStatus == 'Approved' && a.protocolEvaluationStatus == 'Pending Revision') {
        return 'Protocol Evaluation';  //Protocol
      }

      if (a.protocolSubmissionDate != null && a.protocolEvaluationStatus == null) {
        return 'Protocol Evaluation';  //Protocol
      }

      if (aSite.conceptEvaluationStatus == 'Approved' && a.protocolSubmissionDate == null) {
        return 'Protocol Submission';  //Protocol
      }

      if (a.conceptEvaluationStatus == 'Pending additional information') {
        return 'Concept Re-evaluation';    //Concept
      }

      if (a.conceptEvaluationStatus == null) {
        return 'Concept Evaluation';    //Concept
      }

      if (a.protocolEvaluationStatus == 'Rejected') {
        return 'Rejected (Protocol)';   //Rejected
      }

      if (a.conceptEvaluationStatus == 'Rejected') {
        return 'Rejected (Concept)';   //Rejected
      }

    }
  }

  //----------------------IRB Filter--------------------------------------------------------------------//

 filterByIrbStatus= ():any=>{
          var myIrbStatusString=[" ", "Requested", "Submitted", "Pending Revision", "Approved"]
        
          
        
     let index=Number(this.IRBstatusNumber);
     this.IRBstatusString=myIrbStatusString[index];
                  
    {            
    if (index == 0) 
                         
      {
       // console.log(this.irbs)
        this.ClearMarkers(this.markersFiltered, this.siteSetupsCount);

        this.irbsFilteredByStatus = this.irbs;
        this.irbsFilteredByStatusCount = this.irbsFilteredByStatus.length; // Counting filtering result

        this.IRBmarkersFilteredByStatus = this.markers;
        this.IRBmarkersFilteredByStatus = this.FilteredIrbMarkers(this.irbsFilteredByStatus, this.irbsFilteredByStatusCount, this.enterCountryName, this.IRBstatusString);
        this.IRBmarkersFilteredByStatusCount = this.IRBmarkersFilteredByStatus.length; // Counting filtering result


      //  console.log(this.IRBmarkersFilteredByStatus);
      }

      else {
        console.log(this.irbs)
        this.ClearMarkers(this.markersFiltered, this.siteSetupsCount);
        this.irbsFilteredByStatus = []
        
        debugger;
        this.irbsFilteredByStatus = this.irbs.filter((aIrb)=>
        this.irbApprovalStatus(aIrb) == this.IRBstatusString); 
        this.irbsFilteredByStatusCount=this.irbsFilteredByStatus.length; // Counting filtering result

        console.log(this.irbsFilteredByStatus)

        this.IRBmarkersFilteredByStatus = this.markers;
        this.IRBmarkersFilteredByStatus = this.FilteredIrbMarkers(this.irbsFilteredByStatus, this.irbsFilteredByStatusCount, this.enterCountryName, this.IRBstatusString);   //  AAA
        this.IRBmarkersFilteredByStatusCount = this.IRBmarkersFilteredByStatus.length; // Counting filtering result


        console.log(this.IRBmarkersFilteredByStatus);
      }
    }
    return [this.irbsFilteredByStatus, this.IRBmarkersFilteredByStatus]

  }

  //---------------------------------------------------------------------------------------------------//
  // Search by Country//

  searchCountryName = (aSite: ISiteSetup, i: number, enterCountryName: string): any => {

    if (this.enterCountryName == '') {
      let a = this.siteSetups[i];

      if (a.country == this.enterCountryName) {

        return this.enterCountryName;    //country Name, e.g. India
      }

    }

    else {
      let a = this.siteSetupsFilteredByStatus[i];

      if (a.country == this.enterCountryName) {

        return this.enterCountryName;    //country Name, e.g. India
      }
    }
  }

  filterByCountry = (enterCountryName: string): void => {

    let i: number = 0;
    this.siteSetupsFilteredByCountry = [];
    for (i = 0; i < this.siteSetupsCount; i++) {

      if (this.enterCountryName == '') {

        this.ClearMarkers(this.markersFiltered, this.siteSetupsCount);

        this.siteSetupsFilteredByCountry = this.siteSetupsFilteredByStatus;
        this.siteSetupsFilteredByCountryCount = this.siteSetupsFilteredByCountry.length; // Counting filtering result
        this.siteSetupsFilteredByStatusCount = this.siteSetupsFilteredByCountryCount;


        this.markersFiltered = this.markers;
        this.markersFiltered = this.FilteredMarkers(this.siteSetupsFilteredByStatus, this.siteSetupsFilteredByCountry, this.siteSetupsFilteredByCountryCount, this.enterCountryName);   //  AAA
        this.markersFilteredCount = this.markersFiltered.length; // Counting filtering result
      }

      else {
        
        this.ClearMarkers(this.markersFiltered, this.siteSetupsCount);

        this.siteSetupsFilteredByCountry = this.siteSetupsFilteredByStatus.filter((aSite, i) =>
          this.searchCountryName(aSite, i, this.enterCountryName) == this.enterCountryName);
        this.siteSetupsFilteredByCountryCount = this.siteSetupsFilteredByCountry.length; // Counting filtering result
        this.siteSetupsFilteredByStatusCount = this.siteSetupsFilteredByCountryCount;

        this.markersFiltered = this.markers;
        this.markersFiltered = this.FilteredMarkers(this.siteSetupsFilteredByStatus, this.siteSetupsFilteredByCountry, this.siteSetupsFilteredByStatusCount, this.enterCountryName);   //  AAA
        this.markersFilteredCount = this.markersFiltered.length; // Counting filtering result

       
      }
    }
    console.log("filter by country", this.siteSetupsFilteredByCountry)
   // console.log("latitude, longitude", this.markersPositionFiltered)

    this.zoomingOnMarker(this.markersFiltered, this.markersFilteredCount, this.enterCountryName);
    //return [this.siteSetupsFilteredByCountry, this.markersPositionFiltered]
    return;
  }

  // ----------------------------SEARCH IRB's By COUNTRY-----------------------------------//

  searchIrbByCountryName = (aIrb: IIrb, i: number, enterCountryName: string): any => {

    if (this.enterCountryName == '') {

      let b = this.irbs[i];

      if (b.country == this.enterCountryName) {

        return this.enterCountryName;    //country Name, e.g. India
      }

    }

    else {
      let b = this.irbsFilteredByStatus[i];

      if (b.country == this.enterCountryName) {

        return this.enterCountryName;    //country Name, e.g. India
      }
    }
  }

  filterIrbByCountry = (enterCountryName: string): void => {

    let i: number = 0;
    this.irbsFilteredByCountry = [];

    for (i = 0; i < this.irbCount; i++) {
      
      if (this.enterCountryName == '') {

        this.ClearMarkers(this.markersFiltered, this.irbCount);

        this.irbsFilteredByCountry = this.irbsFilteredByStatus;
        this.irbsFilteredByCountryCount = this.irbsFilteredByCountry.length; // Counting filtering result
        this.irbsFilteredByStatusCount = this.irbsFilteredByCountryCount;

        this.markersFiltered = this.markers;
        this.markersFiltered = this.FilteredIrbMarkers(this.irbsFilteredByCountry, this.irbsFilteredByCountryCount, this.enterCountryName, this.IRBstatusString);   //  AAA
        this.markersFilteredCount = this.markersFiltered.length; // Counting filtering result
        
      }

      else {
        this.ClearMarkers(this.markersFiltered, this.siteSetupsCount);

        this.irbsFilteredByCountry = this.irbsFilteredByStatus.filter((aIrb, i) =>
          this.searchIrbByCountryName(aIrb, i, this.enterCountryName) == this.enterCountryName);
        this.irbsFilteredByCountryCount = this.irbsFilteredByCountry.length; // Counting filtering result
        this.irbsFilteredByStatusCount = this.irbsFilteredByCountryCount;


        this.markersFiltered = this.markers;
        this.markersFiltered = this.FilteredIrbMarkers(this.irbsFilteredByCountry, this.irbsFilteredByCountryCount, this.enterCountryName, this.IRBstatusString);   //  AAA
        this.markersFilteredCount = this.markersFiltered.length; // Counting
        
      }

    }
    //console.log("filter by country", this.irbsFilteredByCountry);
    //console.log("latitude, longitude", this.markersPositionFiltered);

    this.zoomingOnMarker(this.markersFiltered, this.markersFilteredCount, this.enterCountryName);
    //return [this.irbsFilteredByCountry, this.markersPositionFiltered]
    return;
  }


  //------------------End of Country Filtering-------------------------------------------------------------------//
  //--------------------------------------------------------------------------------------------------------------//

  zoomingOnMarker = (markersFiltered: Marker[], markersFilteredCount: number, enterCountryName: string): void => {

    if (this.enterCountryName != '' && this.markersFilteredCount != 0) {
      let bullsEye=this.centerMarkers(markersFiltered);

      this.center = { lat: bullsEye.position.lat, lng: bullsEye.position.lng };
      this.zoom = 8;
    }
    else {
      this.minLatitudeForZooming = 10;
      this.maxLongitudeForZooming = -50;

      this.center = { lat: this.minLatitudeForZooming, lng: this.maxLongitudeForZooming };
      this.zoom = 2.0;
    }


  }
  centerMarkers = (filteredMarkers: Marker[]): Marker => {
    var mybullsEye = new Marker;
    mybullsEye.position=new PositionMarker;
    if (filteredMarkers.length>0)
    {
      mybullsEye.position.lat = (this.minLatitude(filteredMarkers).position.lat 
      +this.maxLatitude(filteredMarkers).position.lat)/2;

      mybullsEye.position.lng = (this.minLongitude(filteredMarkers).position.lng 
      +this.maxLongitude(filteredMarkers).position.lng)/2;
    }
    else{}



    return mybullsEye;

  }
  minLatitude = (filteredMarkers: Marker[]): Marker => {
    var minLatMarker: Marker;
    if (filteredMarkers.length != 0) {
      minLatMarker = filteredMarkers[0];
    }
    let i:number = 0;
    for(i = 0; i< filteredMarkers.length;i++){
      if (filteredMarkers[i].position.lat<minLatMarker.position.lat)
      {minLatMarker = filteredMarkers[i]}
    }
    return minLatMarker;
  }
  maxLatitude = (filteredMarkers: Marker[]): Marker => {
    var maxLatMarker: Marker;
    if (filteredMarkers.length != 0) {
      maxLatMarker = filteredMarkers[0];
    }
    let i:number = 0;
    for(i=0; i< filteredMarkers.length;i++){
      if (filteredMarkers[i].position.lat>maxLatMarker.position.lat)
      {maxLatMarker = filteredMarkers[i]}
    }
    return maxLatMarker;
  }
  minLongitude = (filteredMarkers: Marker[]): Marker => {
    var minLngMarker: Marker;
    if (filteredMarkers.length != 0) {
      minLngMarker = filteredMarkers[0];
    }
    let i:number = 0;
    for(i = 0; i< filteredMarkers.length;i++){
      if (filteredMarkers[i].position.lng<minLngMarker.position.lng)
      {minLngMarker=filteredMarkers[i]}
    }
    return minLngMarker;
  }
  maxLongitude = (filteredMarkers: Marker[]): Marker => {
    var maxLngMarker: Marker;
    if (filteredMarkers.length != 0) {
      maxLngMarker = filteredMarkers[0];
    }
    let i:number = 0;
    for(i = 0; i< filteredMarkers.length;i++){
      if (filteredMarkers[i].position.lng>maxLngMarker.position.lng)
      {maxLngMarker=filteredMarkers[i]}
    }
    return maxLngMarker;
  }
  //.................................Function for Plotting Graph...........................................................//

  SitesVectorGenerator = (aSite: ISiteSetup[]): any => {

    this.SitesDataGraph()

            this.studyEvaluationStatusGraphData = {
              labels: ['Concept Evaluation', 'Concept Re-evaluation', 'Rejected (Concept)', 'Protocol Submission', 'Protocol Evaluation', 'Protocol Re-evaluation', 'Rejected (Protocol)', 'Contract Negociation', 'Document Check'],
              datasets: [
                  {
                    label: 'Evaluation Status',

                    data: [
                    this.graphValues[0].graphValueY,
                    this.graphValues[1].graphValueY,
                    this.graphValues[2].graphValueY,
                    this.graphValues[3].graphValueY,
                    this.graphValues[4].graphValueY,
                    this.graphValues[5].graphValueY,
                    this.graphValues[6].graphValueY,
                    this.graphValues[7].graphValueY,
                    this.graphValues[8].graphValueY],

                      backgroundColor: ['#1922FB', '#1922FB', '#C7B8BA', '#008013', '#008013', '#008013', '#969594', '#FEA51D', '#801780' ],
                      hoverBackgroundColor: ['#1922FB', '#1922FB', '#C7B8BA', '#008013', '#008013', '#008013', '#969594', '#FEA51D', '#801780'],
                      borderWidth: 1

                  }
              ]
          };
        
          this.options1 = {
              plugins: {
                  legend: {
                    labels: {
                          color: '#000000'
                          
                      }
                  }
              },
              animation: {
                duration: 1000,
                easing: 'easeInCirc', 
              
              },

              scales: {
                y: {
                  title: {
                    display: true,
                    text: 'COUNT',
                    color: '#000000',
                    font: {
                      weight: 800
                    }
                  },
                    beginAtZero: true,
                    ticks: {
                        color: '#000000'
                    },
                  
                },
                
            }
        };

        //==================================================================//
              
        this.studyOperationalStatusGraphData = {


        datasets: [
        {
        type: 'bar',
        label: 'Concept Evaluation Duration in Days',
        data: this.conceptOperationData.map(point => ({y: point[0],  x: point[1]})),      

        fill: false,
        borderColor: '#1922FB',
        backgroundColor: '#1922FB',
        hoverBackgroundColor: '#1922FB',

        },
        {
        type: 'bar',
        label: 'Protocol Evaluation Duration in Days',
        data: this.protocolOperationData.map(point => ({y: point[0],  x: point[1]})),      

        fill: false,
        borderColor: '#008013',
        backgroundColor: '#008013',
        hoverBackgroundColor: '#008013',

        },

        {
        type: 'bar',
        label: 'Contract Negociation Duration in Days',
        data: this.contractOperationData.map(point => ({y: point[0],  x: point[1]})),      

        fill: false,
        borderColor: '#FEA51D',
        backgroundColor: '#FEA51D',
        hoverBackgroundColor: '#FEA51D',

        }

        ]
        };

        this.options6 = {
        indexAxis: 'y',
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 0.8,
        plugins: {
          tooltips: {
              mode: 'index',
              intersect: false
          },
          legend: {
              labels: {
                  color: '#000000'
              }
          }
        },
        scales: {
        x: {
        title: {
        display: true,
        text: 'Number of DAYS',
        color: '#000000',
        font: {
        weight: 800
        }
        },
        stacked: true,
        ticks: {
        color: '#000000',
        font: {
            weight: 500
        }
        },
        grid: {
        color: '#000000',
        drawBorder: false
        }
        },
        y: {
        title: {
        display: true,
        text: 'IRB Site Name',
        color: '#000000',
        font: {
        weight: 800
        }
        },
        stacked: true,
        ticks: {
        color: '#000000'
        },
        grid: {
        color: '#000000',
        drawBorder: false
        }
        }
        },
        interaction: {
          mode: 'nearest',
          axis: 'y',
          intersect: false
      },
        };

  }

  IRBVectorGenerator = (aIrb: IIrb[]): any => {
            
    this.irbDataGraph();

                                  
    this.irbReviewStatusGraphData = {
     labels: ['Requested', 'Submitted', 'Pending Revision', 'Approved'],
     datasets: [
         {
           data: [
           this.irbGraphValues[0].graphValueY, 
           this.irbGraphValues[1].graphValueY, 
           this.irbGraphValues[2].graphValueY, 
           this.irbGraphValues[3].graphValueY],

             backgroundColor: ['#FF0000', '#1922FB', '#FFBF00', '#008013' ],
             hoverBackgroundColor: ['#FF0000', '#1922FB', '#FFBF00', '#008013']
         }
         ]
 };

 this.options2 = {
     plugins: {
         legend: {
             labels: {
                 usePointStyle: true,
                 
             }
         }
     },
     animation: {
       duration: 1000,
       easing: 'easeInCirc', 
      
     }
 };

 //////////////////////////////////////////////////////////

 this.irbOperationalStatusGraphData = {


   datasets: [
     {
       type: 'bar',
       label: 'IRB - Request-to-Submission Duration',
       data: this.irbsOperationData1.map(point => ({y: point[0],  x: point[1]})),      
       
       fill: false,
       borderColor: '#FB8706',
       backgroundColor: '#FB8706',
       hoverBackgroundColor: '#FB8706'
       
     },
     {
       type: 'bar',
       label: 'IRB - Submission-to-Evaluation Duration',
       data: this.irbsOperationData2.map(point => ({y: point[0],  x: point[1]})),      
       
       fill: false,
       borderColor: '#049E08',
       backgroundColor: '#049E08',
       hoverBackgroundColor: '#049E08',
       
     },

     {
       type: 'bar',
       label: 'Time Since IRB approval',
       data: this.irbsOperationData2.map(point => ({y: point[0],  x: point[2]})),      
       
       fill: false,
       borderColor: '#0A1EF0',
       backgroundColor: '#0A1EF0',
       hoverBackgroundColor: '#0A1EF0',
       
     }

 
                   
 ]
 };
 
 this.options7 = {
   indexAxis: 'y',
   responsive: true,
   maintainAspectRatio: false,
             aspectRatio: 0.8,
             plugins: {
                 tooltips: {
                     mode: 'index',
                     intersect: false
                 },
                 legend: {
                     labels: {
                         color: '#000000'
                     }
                 }
   },
   scales: {
       x: {
         title: {
           display: true,
           text: 'Number of DAYS',
           color: '#000000',
           font: {
             weight: 800
           }
         },
         stacked: true,
           ticks: {
               color: '#000000',
               font: {
                   weight: 500
               }
           },
           grid: {
               color: '#000000',
               drawBorder: false
           }
       },
       y: {
         title: {
           display: true,
           text: 'IRB Site Name',
           color: '#000000',
           font: {
             weight: 800
           }
         },
         stacked: true,
           ticks: {
               color: '#000000'
           },
           grid: {
               color: '#000000',
               drawBorder: false
           }
       }
     },
     interaction: {
      mode: 'nearest',
      axis: 'y',
      intersect: false
  },
   };

    return;

  }
 

  PatientVectorGenerator = (aPatient: IPatient[]): void => {

    this.patientStatusDataGraph();
          
           this.irbPatientEnrollmentGraphData = {
                    labels: ['Need Screening', 'Waiting for Screening Results', 'Selected After Screening - Provide Consent Form', 'Not Selected After Screening Results Review','Awaiting Consent Signature', 'No Consent', 'Enrolled'],
                    datasets: [
                        {
                          data: [
                          this.patientStatusGraphValues[0].graphValueY, 
                          this.patientStatusGraphValues[1].graphValueY, 
                          this.patientStatusGraphValues[2].graphValueY, 
                          this.patientStatusGraphValues[3].graphValueY,
                          this.patientStatusGraphValues[4].graphValueY,
                          this.patientStatusGraphValues[5].graphValueY,
                          this.patientStatusGraphValues[6].graphValueY],

                          backgroundColor: ['#FFFF00', '#1922FB', '#cce6ff', '#D0CECE', '#FFBF00', '#041b50', '#008013' ],
                          hoverBackgroundColor: ['#FFFF00', '#1922FB', '#cce6ff', '#D0CECE', '#FFBF00', '#041b50', '#008013']
                        }
                    ]
                };
               
                this.options3 = {
                    plugins: {
                        legend: {
                            labels: {
                                usePointStyle: true,
                                
                            }
                        }
                    },
                    animation: {
                      duration: 1000,
                      easing: 'easeInCirc', 
                     
                    }
                };
                  

  }


         //================================================================================//        

         PatientTreatmentVectorGenerator = (patientId:number):void =>{

          this.patientTreatmentGraphValues = [];
          this.value3 = 0;
          this.value4 = 0;
          
                           
          if(this.treatmentAuditCount !=0){

            this.patientTreatmentGraphValue  = new GraphValue();
            console.log("siteID = ",this.currentIrbId,"  All treatments for PatientId#", patientId, this.treatmentList);
           
                            
           //this.treatmentList.reduce((r, o) => o.treatmentDate < r.treatmentDate ? o : r);  // Extraction of the First Treatment Date
           console.log("First Treatment Info",[this.treatmentList.reduce((r, o) => o.treatmentDate < r.treatmentDate ? o : r)]);  //provide all info about the 1st treatment
           this.firstTreatmentDate = (this.treatmentList.reduce((r, o) => o.treatmentDate < r.treatmentDate ? o : r)).treatmentDate;// provide the date
         
           console.log("First Treatment Date: ", this.firstTreatmentDate, "for Patient ID =", patientId);
           
          this.firstTreatmentDatesArray.push(this.firstTreatmentDate);   
          this.patientsInTreatmentID.push(patientId);
          this.value3 = this.patientsInTreatmentID.length;
          
          //Count number of AE  treatments  //////////////
          
             this.AESelection(this.treatmentList);
            //console.log("AE Count temp = ",this.AECount);
            if (this.AECount !=0) {this.patientsWithAECounts = this.patientsWithAECounts +1} //Counting the number of patients showing singns of AEs
            this.AECounts = this.AECounts + this.AECount; //Counting the number of AE treatment visits
            
            console.log("AE Treatment Visits",this.AECounts);
            console.log("Patients With AE Counts",this.patientsWithAECounts);

            /////////////////END///////////////////////

            /////////////////Count of Number of Patient on a Specific Batch of Material  //////
             // this.batchSelection(this.treatmentList, this.availableBatchNumbersAtSite)

           ///////////////////END///////////////////////
         
        this.patientTreatmentGraphValue[0]= this.firstTreatmentDatesArray.length; 
        this.patientTreatmentGraphValue[1] = (this.enrolledPatientIdArray.length - this.firstTreatmentDatesArray.length)

           }

       else{

            this.patientTreatmentGraphValue  = new GraphValue();
            this.firstTreatmentDate = null;
//console.log("NO listed Treatments for ", patientId);
            let rowIndex:number = 0;
//console.log(this.patientList);
          rowIndex = this.patientList.findIndex(row => row.id === patientId);  //to find the row's index associated with "this.currenIrbId"

          this.patientsAwaitingFirstTreatmentID.push(this.patientList[rowIndex].patientNumber);
                                                              
           }

        this.patientTreatmentGraphValues = [this.patientTreatmentGraphValue[0], this.patientTreatmentGraphValue[1]];                            
                   
        this.value3 = this.firstTreatmentDatesArray.length;
        this.value4 = this.patientsAwaitingFirstTreatmentID.length;       
                                            
        console.log( "Number of Patients In Treatment IDs", this.patientsInTreatmentID, "First Treatment Dates", this.firstTreatmentDatesArray); 
                  
             
          //console.log("Array of Enrolled Patients Ids", this.enrolledPatientIdArray);                                               
                                              
          //console.log("Total Number of Enrolled Patients At This Selected Site =", this.enrolledPatientIdArray.length);
          //console.log("Total Number of Patients in Treatment At This Selected Site =", this.firstTreatmentDatesArray.length);
          //console.log( "Number of Patients Awaiting First Treatment", this.patientTreatmentGraphValues[1]); 
          console.log( "Patients Awaiting First Treatment IDs", this.patientsAwaitingFirstTreatmentID); 
          // console.log(this.patientTreatmentGraphValues);  
       
        //////////////////Data for chart.JS plot//////////////

        this.irbPatientTreatmentGraphData = {
          labels: ['Number of Enrolled Patients & Receiving Treatments', 'Number of Enrolled Patient & Awaiting 1st Treatment'],
          datasets: [
              {
                data: [
                  this.patientTreatmentGraphValue[0], 
                  this.patientTreatmentGraphValue[1]],

                backgroundColor: ['#008013', '#FEA51D' ],
                hoverBackgroundColor: ['#008013', '#FEA51D']
              }
          ]
      };
     
      this.options4 = {
          plugins: {
              legend: {
                  labels: {
                      usePointStyle: true,
                      
                  }
              }
          },
          animation: {
            duration: 1000,
            easing: 'easeInCirc', 
           
          }
      };
        
  return;
                                       
         }

//==================================================================================================//           
        
         PatientEnrollmentVectorGenerator = (aPatient:IPatient[]):void =>{
        
          
         this.enrollmentPlotTimeSerie = [];
         
         //this.lineChartOptions.title.text= 'Patients Enrollment Curve @ /IRB Site #'+ this.currentIrbId.toString();           
          
         this.patientEnrollmentDataGraph();
         this.patientTreatmentData();

                        
        let j:number = 0;
        
        for (j = 0; j < this.patientEnrollmentGraphValues.length; j++)
        {
                     
        this.enrollmentPlotTimeSerie.push([this.patientEnrollmentGraphValues[j].graphValueX, this.patientEnrollmentGraphValues[j].graphValueY]);
        
       }
      // console.log(this.enrollmentPlotTimeSerie);

      //////////////////////////////////////////Chart.JS///////////////////////////////////////////////


           this. patientEnrollmentOverTimeGraphData= {
            
            datasets: [
                {
                  label: 'Patient Enrollment Number',
                  data: this.enrollmentPlotTimeSerie.map(point => ({x: point[0],  y: point[1]})),      
                  
                  fill: false,
                  borderColor: '#008013',
                  hoverBackgroundColor: '#008013',
                  tension: 0.0
                }
            ]
        };
       
        this.options5 = {
          scales: {
            x: {
              type: 'time',
              display: true,
              title: {
                display: true,
                text: 'Enrollment Dates',
                font: {
                  weight: 800
                }
              }
            },
            y: {
              display: true,
              title: {
                display: true,
                text: 'Total Enrollment - n of N',
                font: {
                  weight: 800
                }
              }
            }
          }
        };
                                               
  return ;
                                       
         }
  
  //::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::://


  SitesDataGraph = (): any => {
    var myStatusString = ["Concept Evaluation", "Concept Re-evaluation", "Rejected (Concept)", "Protocol Submission", "Protocol Evaluation",
      "Protocol Re-evaluation", "Rejected (Protocol)", "Contract Negotiation", "Documents Check"]

    let index = Number(this.statusNumber);
    this.statusString = myStatusString[index];

    this.graphValues = [];

    for (index = 0; index < 9; index++) {
      this.graphValue = new GraphValue();

      this.statusString = myStatusString[index];
      this.graphValue.Index = index + 1;
      this.graphValue.graphValueX = this.statusString;

      this.siteSetupsFilteredByStatus = this.siteSetups.filter((aSite, i) =>
        this.EvaluationStatus(aSite, i) == this.statusString); // Think of adding another condition here
      this.siteSetupsFilteredByStatusCountforGraph = this.siteSetupsFilteredByStatus.length; // Counting filtering result   

      this.graphValue.graphValueY = this.siteSetupsFilteredByStatusCountforGraph;

      this.graphValues.push(this.graphValue);
    }
    // console.log(this.graphValues);

    return [this.graphValues]

  }


  //........................................................................................................................//

  // For Patients status data filtering

  patientStatusDataGraph = (): any => {
    var myStatusString = ["Needs Screening", "Waiting For Screening Results",
      "Selected After Screening - Provide Consent Form", " Not selected for Study - Failed Screening",
      "Awaiting consent signature", "No consent", "Enrolled"]



    let index = Number(this.statusNumber);
    this.statusString = myStatusString[index];

    this.patientStatusGraphValues = [];

    for (index = 0; index < 7; index++) {
      this.patientStatusGraphValue = new GraphValue();
//console.log(index, this.statusString);
      this.statusString = myStatusString[index];
      this.patientStatusGraphValue.Index = index;
      this.patientStatusGraphValue.graphValueX = this.statusString;

      this.patientListFiltered = this.patientList.filter(aPatient =>
        this.patientStatus(aPatient) == this.statusString);
      this.patientListFilteredCountforGraph = this.patientListFiltered.length; //To count the result of filtering  

      this.patientStatusGraphValue.graphValueY = this.patientListFilteredCountforGraph;

      this.patientStatusGraphValues.push(this.patientStatusGraphValue);
    }
   // console.log(this.patientStatusGraphValues);

    return [this.patientStatusGraphValues]

  }

  patientStatus = (aPatient: Patient): string => {
    if (aPatient.consentSignedDate != undefined && aPatient.consentSignedDate != null) {
      if (aPatient.consent != undefined && aPatient.consent != null && aPatient.consent) {
        return "Enrolled";
      }
      else {
        return "No consent";
      }
    }
    if (aPatient.consentGivenDate != undefined && aPatient.consentGivenDate != null) {
      return "Awaiting consent signature";
    }
    if (aPatient.screeningResultsDate != undefined && aPatient.screeningResultsDate != null) {
      if (aPatient.selected != undefined && aPatient.selected != null) {
        return aPatient.selected ? "Selected After Screening - Provide Consent Form" : " Not selected for Study - Failed Screening";
      }
    }
    if (aPatient.screeningDate != undefined && aPatient.screeningDate != null) {
      return "Waiting For Screening Results"
    }
    return "Needs Screening";
  }


  //................................Patient Enrollment Graph....................................................................//

patientEnrollmentDataGraph = ():any=>{
  var myStatusString=["Enrolled", "Awaiting consent signature"]

  //STEP #1 - Filtering out the patient in treatment
                     let i:number = 0;
                     let index=Number(this.statusNumber);
                     //this.statusString=myStatusString[index]; 
                     this.patientListFiltered = [];
                     this.patientEnrollmentGraphValues = [];
                     this.patientNumberAwaitingConsentSignature = [];
                     this.orderedDates = [];
                     this.enrolledPatientIdArray = [];
                     this.knobEnrollmentMax = 0;
                     this.value1 = 0;
                     this.value2 = 0; 
                     this.FPI = null;
                     this.summaryEnrollmentData = [];
                     
                     for (index = 0; index < 2; index++) 
         { 
                     this.statusString = myStatusString[index];

                     this.patientListFiltered = this.patientList.filter(aPatient => this.patientStatus(aPatient) == this.statusString);
                   
            // console.log(this.statusString, this.patientListFiltered);
                    
            // -----------------------------End STEP #1  --------------------------------------//

            //STEP #2 - Enrollment date (date of signing the consent form) extraction
                      if (this.statusString == "Enrolled" && this.patientListFiltered.length !=0 )
            {                            
                     for (i = 0; i< this.patientListFiltered.length; i++)
                    {
                      
                      if(this.patientListFiltered[i].consent != null && this.patientListFiltered[i].consent == true)    
                      
                      {
                        let date = new Date(this.patientListFiltered[i].consentSignedDate);
                        let truncatedDate = date.toISOString().split('T')[0];

                        this.orderedDates.push(truncatedDate);  //Array of consent form returned dates for enrolled patients (e.g consent form signature date)
                        this.enrolledPatientIdArray.push(this.patientListFiltered[i].id);  // Array of enrolled patients' Ids

                        // console.log(truncatedDate, this.patientListFiltered[i].consentSignedDate);
                      } 
                    } 
                     
                     this.value1= this.enrolledPatientIdArray.length; //Number of consented/enrolled patients
                      

                      //console.log("Patients IDs and Related Enrollment Dates", this.enrolledPatientIdArray, this.orderedDates);
          
                      this.orderedDates.sort((a, b) => {return a.localeCompare(b);});
                    
                      this.FPI = this.orderedDates.reduce((prev, curr) => { return prev < curr ? prev : curr; });
                    
                      // console.log('FPI =', this.FPI);

                      for (i = 0; i< this.patientListFiltered.length; i++)

                  {
                     this.patientEnrollmentGraphValue = new GraphValue();
        
                     this.patientEnrollmentGraphValue.graphValueX = this.orderedDates[i];
                     this.patientEnrollmentGraphValue.graphValueY = i+1; 
                     this.patientEnrollmentGraphValue.Index = i;

                     this.patientEnrollmentGraphValues.push(this.patientEnrollmentGraphValue);

                     //console.log(this.patientEnrollmentGraphValues)
                  
                  } 
            }

                     
                     
            //for later decisison tool to provide recommentations to PI Still part of STEP #2
                    
                    if (this.statusString == "Awaiting consent signature" && this.patientListFiltered.length !=0)
                    {
                           
                      for (i=0; i< this.patientListFiltered.length; i++)
                      
                      {
                         this.patientNumberAwaitingConsentSignature.push(this.patientListFiltered[i].patientNumber);
                      }
                        
                    }
                  
          }
                  {
                      let rowIndex:number = 0;

                      rowIndex = this.irbs.findIndex(row => row.id === this.currentIrbId);  //to find the row's index associated with "this.currenIrbId"

                      this.knobEnrollmentMax = this.irbs[rowIndex].expectedNumberOfPatients;
                     // console.log("Expected Patients at site#",this.currentIrbId, "=", this.knobEnrollmentMax);
                  }

                     this.value2 = this.patientNumberAwaitingConsentSignature.length;
                     
                     this.summaryEnrollmentData = [this.value1];  // For the summary table

        
       // console.log("Array of Patient Numbers to follow-up (Not Yet Enrolled)",this.patientNumberAwaitingConsentSignature);
       // console.log("Number of Enrolled Patients",this.summaryEnrollmentData);
        //console.log("Plot Coordonnees",this.patientEnrollmentGraphValues);

                     return this.patientEnrollmentGraphValues, this.patientNumberAwaitingConsentSignature, 
                            this.enrolledPatientIdArray, this.summaryEnrollmentData, this.value2, this.enrollmentRate
                                      
                  }

//----------------------------------------------------------//

       patientTreatmentData =():void =>{
                   
          this.firstTreatmentDatesArray = [];
          this.patientsInTreatmentID = [];
          this.patientsAwaitingFirstTreatmentID = [];
          this.value3 = 0;
          this.value4 = 0; 
          this.AECount = 0;
          this.AECounts = 0;
          this.patientsWithAECounts = 0;
          this.allTreatmentsForAE = [];
                                                           
          // first treatment dates'Extraction             
 
          let i:number = 0;                
                    
          for (i = 0; i < this.enrolledPatientIdArray.length; i++)
                                                            
                {         
                
                this.getTreatmentListForTable(this.enrolledPatientIdArray[i]);   
                this.listingAllTreatmentsForAE(this.enrolledPatientIdArray[i])
                                                
                }
            console.log('All EA Treatments', this.allTreatmentsForAE);
                                                                                     
        }

         AESelection= (treatment: ITreatment[]): number =>{
               
                this.AECount = 0;
             
                {
                  this.treatmentListFiltered = this.treatmentList.filter(aTreatment=>
                    aTreatment.treatmentType == 1);
                }
                console.log("AE Filtered list",this.treatmentListFiltered);
                this.AECount = this.treatmentListFiltered.length; //Counting fitering results

               return this.AECount
          }

       /* batchSelection = (treatment: ITreatment[], selectedbatch:string): number =>{
               
                this.patientsOnSelectedBatchCount = 0;
                
                {
                  this.treatmentListFiltered = this.treatmentList.filter(aTreatment=>
                    aTreatment.vialId == 'selectedbatch');
                }
                console.log("Number of Patients Using BATCH:'",this.availableBatchNumbersAtSite,"'",this.treatmentListFiltered);
                this.patientsOnSelectedBatchCount = this.treatmentListFiltered.length; //Counting filtering results

               return this.patientsOnSelectedBatchCount
               
              }*/

//----------------------------------------------------------------------------------------------------//
  //....................................................................................................//

irbDataGraph= ():any=>{
  var myIrbStatusString=["Requested", "Submitted", "Pending Revision", "Approved"]



                  let index=Number(this.IRBstatusNumber);
                  this.IRBstatusString=myIrbStatusString[index];
          
                  this.irbGraphValues = [];
          
          
                  for (index=0; index < 4; index++) 
                 
                  {
                  this.irbGraphvalue  = new GraphValue();
                     
                  this.IRBstatusString=myIrbStatusString[index];
                  this.irbGraphvalue.Index = index;
                  this.irbGraphvalue.graphValueX = this.statusString;
                  
                  this.irbsFilteredByStatus=this.irbs.filter((aIrb) =>
                    this.irbApprovalStatus(aIrb) == this.IRBstatusString);
                    this.irbFilteredByStatusCountforGraph=this.irbsFilteredByStatus.length; //To count the result of filtering  
                    
                  this.irbGraphvalue.graphValueY=this.irbFilteredByStatusCountforGraph;
                      
                  this.irbGraphValues.push(this.irbGraphvalue);
            
                  }
                  // console.log(this.irbGraphValues);
                        
                  return [this.irbGraphValues]
                  
                }

irbApprovalStatus = (aIrb: Irb): string => {
              if (aIrb.reviewDate != undefined && aIrb.reviewDate != null) {


                  if (aIrb.outcome == true) {
                    return "Approved";
                  }
                  if (aIrb.outcome == false) {
                    return "Pending Revision";
                  }
                }
                if (aIrb.submissionDate != undefined && aIrb.submissionDate != null) {
                  return "Submitted";
                }
                return "Requested"
            }


  //------------------------------------------------------------------------------------------------------------//


  getInfoview = (siteId: number, j: number): void => {
    this.currentSiteId = siteId;
    this.administrationService.getAnalyticsDataSummary(this.authService.authorizationHeaderValue, siteId)
      .subscribe(results => {
        var analyticsDataSummary = results;
        var theString = '<div><b>Study Name</b> = ' + analyticsDataSummary.studyName + '</div>'
          + '<div><b>Product Under Investigation</b> = ' + analyticsDataSummary.requestedProduct + '</div>'
          + '<div><b>Coordinating Site</b> = <b>' + analyticsDataSummary.siteName + '</b></div>'
          + '<div><b>PI Name (Coordinator)</b> = <b>' + analyticsDataSummary.piName + '</b></div>'
          + '<div><b>Study Type</b> = ' + analyticsDataSummary.studyTypeDescription + '</div>'
          + '<div><b>Disease Area</b> = ' + analyticsDataSummary.diseaseArea + '</div>'
          + '<div><b>Protocol Version</b> = ' + analyticsDataSummary.protocolVersion + '</div>'
          + '<div><b> MSL (Associated with the Site) </b>= ' + analyticsDataSummary.msl + '</div>'
          + '<div><b>Number of Patients in the Study</b> = ' + analyticsDataSummary.numberOfPatients.toString() + '/ <b>' + analyticsDataSummary.expectedNumberOfPatients.toString() + '</b></div>'
          + '<div><b>FPI </b> = ' + analyticsDataSummary.firstPatientIn + '</div>'
          +  '<div><b>List of Adverse Events for all sites in the study</b> = ' + this.getAdverseEventList(analyticsDataSummary.adverseEventsList) + '</div>'   ;
        this.summaryData[j] = theString;
      })
  }
  findIrbById =(irbId :number):IIrb =>{

      return this.irbs.filter(thisIrb => thisIrb.id==irbId)[0];
      }
  
  getIrbInfoview = (irbId: number, j: number): void => {
    this.currentIrbId = irbId;
    var thisIrb = this.findIrbById(irbId);
    console.log('find Irb by Id ' + thisIrb);
    var thisSiteId= thisIrb.site.id;
    //get site analytics
    this.administrationService.getAnalyticsDataSummary(this.authService.authorizationHeaderValue, thisSiteId)
    .subscribe(results => {
      var siteAnalyticsDataSummary=results;
    this.administrationService.getIrbAnalyticsDataSummary(this.authService.authorizationHeaderValue, irbId)
      .subscribe(results => {
        var analyticsDataSummary = results;
        // console.log (results);

        var theString = '<div><b>Study Name</b> = ' + siteAnalyticsDataSummary.studyName + '</div>'
          + '<div><b>Product Under Investigation</b> = ' + siteAnalyticsDataSummary.requestedProduct + '</div>'
          + '<div><b>Coordinating Site</b> = ' + siteAnalyticsDataSummary.siteName + '</div>'
          + '<div><b>PI Name (Coordinator)</b> = ' + siteAnalyticsDataSummary.piName + '</div>'
          + '<div><b>Study Type </b>= ' + siteAnalyticsDataSummary.studyTypeDescription + '</div>'
          + '<div><b>Disease Area </b>= ' + siteAnalyticsDataSummary.diseaseArea + '</div>'
          + '<div><b>Protocol Version </b>= ' + analyticsDataSummary.protocolVersion + '</div>'
          + '<div><b>Number of Patients in the Study</b> = ' + siteAnalyticsDataSummary.numberOfPatients.toString() + '/ <b>' + analyticsDataSummary.expectedNumberOfPatientsForTheStudy.toString() + '</b></div>'   // to be corrected the numerator should be the number of patients in treatment (started or not started)
          + '<div><b>Study Total Budget</b> = $' + siteAnalyticsDataSummary.siteTotalBudget + '</div>'

          +  '<div><b>List of Adverse Events for all sites in the study</b> = ' + this.getAdverseEventList(siteAnalyticsDataSummary.adverseEventsList) + '</div>'   
          + '<div><b>  ---------------IRB Site Info---------------' + '</div>'
          + '<div><b>Irb Site </b>= <b>' + analyticsDataSummary.irbSiteName + '</b></div>'
          + '<div><b>Irb (Local PI) </b>= ' + analyticsDataSummary.irbPiName + '</div>'
          + '<div><b>Irb Local MSL </b>= ' + analyticsDataSummary.irbMsl + '</div>'
          + '<div><b>Number of Patients  @ this Site</b> = ' + analyticsDataSummary.numberOfPatients.toString() + '/ <b>' + analyticsDataSummary.expectedNumberOfPatients.toString() + '</b></div>'
          + '<div><b>Inventory </b>= ' + analyticsDataSummary.inventory + '</div>'
          + '<div><b>IRB Protocol Version </b>= ' + analyticsDataSummary.irbProtocolVersion + '</div>'
          + '<div><b>IRB Outcome </b>= ' + analyticsDataSummary.irbOutcome + '</div>'
          + '<div><b>FPI</b> = ' + analyticsDataSummary.firstPatientIn + '</div>'
          ///copy the 2 following lines
          + '<div><b>  ---------------Treatments Adverse Events---------------' + '</b></div>'
          +  '<div><b>List of Adverse Events @ This Site</b> : ' + this.getAdverseEventList(analyticsDataSummary.adverseEventsList) + '</div>'   ;

        this.summaryData[j] = theString;

      })} );
  }
  /// copy this function
  getAdverseEventList (adverseEventArray:string[]): string  {
    var list="";
    adverseEventArray.forEach(element => {
      if(element!=null){
        if(list=="")
       {list+= element;}
       else{
        list+=", "+element
       }
      
      }
    });
    console.log(list);
    if(list=="")
    {
      list+="None reported";
    }
    return list
  }
  
  getAllIrbs = (): void => {
    this.administrationService.getAllIrbs(this.authService.authorizationHeaderValue)
      .pipe(finalize(() => {
        this.busy = false;
        this.hideSpinner();
      }))
      .subscribe(
        results => {
          this.irbs = results;
          this.irbCount = this.irbs.length;

          
          this.distinctIrbVariables();
        
          for (let i = 0; i < this.irbCount; i++) {
            if (this.irbs[i].longitude != null && this.irbs[i].latitude != null && this.irbs[i].country != null) {
              this.pushIrbMarker(i);
            }
            else {
              console.log(this.irbs[i]);
              this.getLongLat(i);
            }
          }
          this.irbsOperationalAnalytics(this.irbs, this.irbCount );
          this.IRBVectorGenerator(this.irbs);
          // console.log(this.irbGraphValues);
         
        }

      )

  }


  //push the marker for irbs[i]
  pushIrbMarker = (i: number): void => {
    this.marker = new Marker();
    this.marker.position = new PositionMarker();
    this.marker.position.lat = Number(this.irbs[i].latitude);
    this.marker.position.lng = Number(this.irbs[i].longitude);
    this.marker.title = this.irbs[i].geoLocation;// i.toString();  //
    
    this.marker.options = this.IrbMarkerColor(this.irbs[i], this.IRBstatusString);  //introduced for the IRB Marker color selection
    
    this.markerOptions = {
                           icon: this.IrbMarker,
                           zIndex: Number(google.maps.Marker.MAX_ZINDEX) + 100*i
    };

    this.marker.label = { text:this.irbs[i].id.toString(),
                          color:'#FFFFFF',
                        };

    this.marker.id = this.irbs[i].id;
    this.marker.markerIndex = this.markerIndex;
     
    this.marker.markerType = "irb";

    this.markers.push(this.marker);

      
    this.getIrbInfoview(this.irbs[i].id,this.markerIndex)
    this.markerIndex++;

  }
  // get longitude and latitude // update database//push marker
  getLongLat = (i: number): void => {
    let a = this.irbs[i];
    this.administrationService.trialomsGeocode(a.geoLocation)
      .pipe(finalize(() => {

      })).subscribe(
        results => {
          try {
            var formattedAddress = results.results[0].formatted_address;
            this.irbs[i].longitude = results.results[0].geometry.location.lng.toString();
            this.irbs[i].latitude = results.results[0].geometry.location.lat.toString();
            this.irbs[i].country = this.getCountry(formattedAddress).trim();
            console.log(results);
            //update irb in database
            this.updateLongitudeLatitude(this.irbs[i]);
            //push irbmarker
            this.pushIrbMarker(i);
          }
          catch (e) {
            console.log(results);
            console.log('i=' + i.toString());
            console.log(e.message);
          }
          // console.log('id=' + a.id.toString());
          // console.log ('latitude='+results.results[0].geometry.location.lat.toString());
          // console.log ('longitude='+ results.results[0].geometry.location.lng.toString());

        });

  }

  getCountry(formattedAddress: string): string {
    var positionOfCountry = formattedAddress.lastIndexOf(",");
    var country = formattedAddress.substring(positionOfCountry);
    country = country.substring(1, country.length)
    return country;
  }

  //....................................................................................................//
  
  showSpinner = (): void => {
    this.spinner.show();
  }

  hideSpinner = (): void => {
    this.spinner.hide();
  }
  move(event: google.maps.MapMouseEvent) {
    if (event.latLng != null) this.display = event.latLng.toJSON();
  }




  /*  openInfoWindow(marker: MapMarker, windowIndex: number) {
     /// stores the current index in forEach
     this.description= marker.title;
     let curIdx = 0;
     if (this.infoWindowsView[windowIndex] != undefined) this.infoWindowsView[windowIndex].open(marker);//  .open(marker);
     return;
     this.infoWindowsView.forEach((window: MapInfoWindow) => {
       if (windowIndex === curIdx) {
         window.open(marker);
         curIdx++;
       } else {
         window.open(marker);
         curIdx++;
       }
     });
   } */
  updateLongitudeLatitude = (a: IIrb): void => {
    this.piAdministrationService.updateIrb(this.authService.authorizationHeaderValue, a)  //updateIrb//updateLatitudeLongitudeIrb
      .pipe(finalize(() => {

      })).subscribe(
        results => {
          console.log(a);
        });

  }
  openInfoWindow(marker: MapMarker, i: number) {
    // debugger;
    if (this.markers[i].title.substring(0, 3) == "Irb") {
      this.currentIrbId = this.markers[i].id;
      this.getPatientList(this.currentIrbId);
      this.getMaterialInventoryList(this.currentIrbId);
      
      
    }
    else {
      this.currentSiteId = this.markers[i].id;
      this.getPatientList(this.currentIrbId); // CRUCIAL for the display of the KNOB
      //this.getMaterialInventoryList(this.currentIrbId);

    }
    let identifier = this.markers[i].markerIndex;

    let description = this.markers[i].title;
    if (this.summaryData[identifier] != null) {
      this.thisistheinfo = this.summaryData[identifier];
    }
    else {
      this.thisistheinfo = description;
    }
    if (this.infoWindow != undefined) this.infoWindow.open(marker);

  }


  //.......................................................................................................................

//::::::::::::::::::::::: Data for Material Summary Table:::::::::::::::::::::

materialSummaryTable = (irbId:number): any =>{
this.batchInfo = [];

  let i:number = 0;

  for (i = 0; i<this.materialInventoryListCount; i++) {

        this.ExpirationDateEvaluation(this.materialInventoryList[i].expiryDate);

        this.batchInfo.push([this.materialInventoryList[i].batchNumber, 
                             this.materialInventoryList[i].manufacturingReleaseDate,
                             this.materialInventoryList[i].expiryDate, 
                             this.materialInventoryList[i].numberOfItems, 
                             this.batchStatus]);
  }

  console.log("Used for filtering the associated treatment (partially displayed in the table)....",this.batchInfo);

  return this.batchInfo
  
}

UpdatedMaterialSummaryTable = (irbId:number, numberOfTreatmentForThisBatch: number): any =>{
  this.updatedBatchInfo = [];
  
  
  let j:number = 0;

  for (j = 0; j < this.materialInventoryListCount; j++) {

    this.ExpirationDateEvaluation(this.materialInventoryList[j].expiryDate);

                  let batchInfo ={
                                   batchNumber:this.materialInventoryList[j].batchNumber, 
                                   manufacturingReleaseDate: this.materialInventoryList[j].manufacturingReleaseDate,
                                   expiryDate: this.materialInventoryList[j].expiryDate,
                                   numberOfItems: this.materialInventoryList[j].numberOfItems, 
                                   currentInventory: (this.materialInventoryList[j].numberOfItems - this.allTreatmentWithBatchAtSite[j]),
                                   inventoryStatus: this.batchStatus, 
                                   numberTreatedWithBatch: this.allTreatmentWithBatchAtSite[j]
                                  };

      this.updatedBatchInfo.push(batchInfo)
      }
         
    console.log("To be used for Material Summary Table....",this.updatedBatchInfo);

    return this.updatedBatchInfo
    
  }

  //::::::::::::::::::::::::::::::::::::::::::::Evaluation of Expiration Date::::::::::::::
  
  ExpirationDateEvaluation = (expiryDate:Date): any => {
    
    let localDate = new Date();
    let currentDate = new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000);  //convert local time to UTC 
    let lastDate = new Date(expiryDate);
  
       
    // Calculate the difference in milliseconds and convert it into days
    
    this.remainingDays = Math.round((lastDate.getTime() - currentDate.getTime())/(1000 * 60 * 60 * 24));

    if(this.remainingDays <= 0 )
    {
      this.batchStatus = [this.remainingDays, "Expired Batch"]
    }
    else
    {
      this.batchStatus = [this.remainingDays, "   Days To Expiration" ]
    }
    
    
    //console.log(currentDate, expiryDate);
    //console.log("# days before expiration", (this.remainingDays));
    //console.log(this.batchStatus);
   
    return [this.remainingDays, this.batchStatus]
  }

  getButtonColor(inventoryStatus: number): string {
    if (inventoryStatus > 30) {
        return 'green';
    } else if (inventoryStatus > 0 && inventoryStatus <= 30) {
        return 'orange';
    } else {
        return 'red';
    }
}

  //::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

  listingTreatmentsWithProtocol = (): any =>{
       
    //console.log("Step #2 - Original List of TREATMENTS",  this.treatmentList);
    this.treatmentsWithProtocolAndBatchList = [];
   
        if (this.treatmentsWithProtocol.length !=0){
      
          this.associationOfTreatmentAndMaterial(this.treatmentsWithProtocol, this.currentIrbId);
          //this.treatmentsWithProtocolAndBatchList.push(this.treatmentsWithProtocol);
          this.treatmentsWithProtocolAndBatchList.push(this.batchAndTreatmentNumberArrayForSite);
     
       }
        
  console.log("Step #4 - NUMBER of Treatments('Conducting According to Protocol') With a Selected BATCH at IRB Site #", this.currentIrbId, this.treatmentsWithProtocolAndBatchList);
   

       return this.treatmentsWithProtocolAndBatchList
  }


  associationOfTreatmentAndMaterial = (treatmentsWithProtocol:ITreatment[], currentIrbId:number): any =>{

    this.batchAndTreatmentNumberArrayForSite = [];
    this.updatedBatchInfo = [];
    this.batchInfo = [];
    this.allTreatmentWithBatchAtSite = [];
    
    this.numberOfTreatmentForThisBatch = 0;
    this.batchInfo = this.materialSummaryTable(this.currentIrbId)
    
    console.log("Number of Batches at Site #", this.currentIrbId, "--", this.batchInfo.length, "Number of treatments for this patientId at Site---", treatmentsWithProtocol.length);
    console.log("Step #5 - Available Batches & List of Treatment With PROTOCOL", this.batchInfo, treatmentsWithProtocol);

 let i:number = 0;

 for(i=0; i< this.batchInfo.length; i++){

  this.updatedBatchInfo[i] = {};
  this.allTreatmentWithBatchAtSite[i] = 0;
  this.numberOfTreatmentForThisBatch = 0,

    this.treatmentsWithBatch = treatmentsWithProtocol.filter(aTreatment=>
      aTreatment.vialId == this.batchInfo[i][0]);   //Filter treatment by batch

      this.allTreatmentWithBatchAtSite[i] = this.treatmentsWithBatch.length;  // Number of treatment on the selected batch
      this.numberOfTreatmentForThisBatch = this.allTreatmentWithBatchAtSite[i];

      this.updatedBatchInfo[i] = this.UpdatedMaterialSummaryTable(this.currentIrbId, this.allTreatmentWithBatchAtSite[i]);
      
      console.log("Step #6A - Selected Batch and Associated Treatments",this.updatedBatchInfo[i], this.treatmentsWithBatch);
     
      this.batchAndTreatmentNumberArrayForSite.push(this.updatedBatchInfo[i]);
      
      
     }
     
     
     console.log("Step #6B - Selected Batch and Number of Associated Treatments At This Site",this.batchAndTreatmentNumberArrayForSite);

    return  [this.batchAndTreatmentNumberArrayForSite, this.updatedBatchInfo]
  }


  listingAllTreatmentsForAE = (enrolledPatientId: number): any =>{
       
   
    

        if (this.treatmentsForAE.length !=0){
      
          
          this.allTreatmentsForAE.push(this.treatmentsForAE);
     
       }
        
  console.log("All Treatments for AE", this.currentIrbId, this.allTreatmentsForAE);
   

       return this.allTreatmentsForAE
  }


  //::::::::::::::::::::::::::::::::::::::Number of enrolled Patients at slected IRB site::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

  patientEnrollmentAtIrbSite = (irbId:number):any[] => {
    
    // 1- 1st step for finding the number of treatments with a specific batch
    this.enrolledPatientListAtSite = [];
    this.enrolledPatientIdArray = [];
    this.treatmentsWithProtocol = [];
    //this.treatmentsWithProtocolAndBatchList = [];
    this.updatedBatchInfo = [];
    this.batchAndTreatmentNumberArrayForSite = [];

  

              { //Selection of All CONSENTED Patients
              this.enrolledPatientListAtSite = this.patientList.filter(aPatient => this.patientStatus(aPatient) == "Enrolled");
                     
              }

              let i:number = 0;

              for (i=0; i<this.enrolledPatientListAtSite.length; i++)
              {

                this.enrolledPatientIdArray.push(this.enrolledPatientListAtSite[i].id)
               
              }
             // console.log("Array Enrolled PatientId",this.enrolledPatientIdArray);

   //2- 2nd Step for finding the treatments with PROTOCOL for a specific patient Id
              
              
              // this.treatmentsWithProtocolAndBatchList = [];

              for (i=0; i< this.enrolledPatientIdArray.length; i++)
              {
              
             this.treatmentsWithProtocol =  this.getTreatmentListForTable(this.enrolledPatientIdArray[i]);
             
              }
             
              console.log("Step #1- All Consented/Enrolled PATIENTS @ IRB", this.currentIrbId,this.enrolledPatientListAtSite, "Array Enrolled PatientId", this.enrolledPatientIdArray);

               return [this.enrolledPatientListAtSite, this.enrolledPatientIdArray]
                                        
              }         
  //::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::


 
}
