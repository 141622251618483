import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { BaseService } from "../shared/base.service";
import { ConfigService } from '../shared/config.service';
import { Observable, throwError, observable } from 'rxjs';
import { IStudyType } from 'src/app/shared/interfaces/studyTypes';
import { IConceptEvaluation} from 'src/app/shared/interfaces/conceptEvaluation';
import { SiteSetup} from 'src/app/shared/interfaces/siteSetup';
import { ISiteSetup } from 'src/app/shared/interfaces/siteSetup';
import { IIrb } from 'src/app/shared/interfaces/irb';
import { IProtocolEvaluation } from '../shared/interfaces/protocolEvaluation';
import { IContractNegociation } from '../shared/interfaces/contractNegociation';
import { IPrincipalInvestigator, PrincipalInvestigator } from '../shared/interfaces/principalInvestigator';
import { IAnalyticsDataSummary } from '../shared/interfaces/analyticsSummaryData';
import { RegisterUser } from '../shared/interfaces/registerUser';
import { Material, IMaterial } from '../shared/interfaces/material';
import { IPatient } from 'src/app/shared/interfaces/patient';
import { ITreatment } from '../shared/interfaces/treatment';
import { IMaterialReturn, MaterialReturn } from '../shared/interfaces/materialReturn';
import { ITrialomsLogin, TrialomsLogin } from '../shared/interfaces/trialomsLogin';
import { IParameter, IParameterAudit, Parameter } from '../shared/interfaces/parameter';
@Injectable({
  providedIn: 'root'
})
export class IssAdministrationService extends BaseService  {
  constructor(private http: HttpClient, private configService: ConfigService) {    
    super();      
  }

  getStudyTypes=(token: string):Observable<IStudyType[]> => {   
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })
    };
    return this.http.get<IStudyType[]>(this.configService.resourceApiURI + '/studytypes', httpOptions).pipe(catchError(this.handleError));
  }
  siteSetup(token: string,siteData:SiteSetup ){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })
    };
    debugger;
    return this.http.post(this.configService.resourceApiURI + '/issSiteSetups',siteData, httpOptions).pipe(catchError(this.handleError));
  }
  getIssSites=(token: string):Observable<ISiteSetup[]> => {   
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })
    };
    return this.http.get<ISiteSetup[]>(this.configService.resourceApiURI + '/issSiteSetups', httpOptions).pipe(catchError(this.handleError));
 
  }
  getAllIrbs = (token: string): Observable<IIrb[]> => {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<IIrb[]>(this.configService.resourceApiURI + '/irbs/all', httpOptions).pipe(catchError(this.handleError));

  }

  getIssSite=(token: string, conceptId:number) :Observable<ISiteSetup> =>{   
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })
    };
    return this.http.get<ISiteSetup>(this.configService.resourceApiURI + `/issSiteSetups/${conceptId.toString()}`, httpOptions).pipe(catchError(this.handleError));
  }

  getConceptEvaluations=(token: string, conceptId:number) :Observable<IConceptEvaluation[]> =>{   
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })
    };
    return this.http.get<IConceptEvaluation[]>(this.configService.resourceApiURI + `/conceptEvaluations/${conceptId.toString()}`, httpOptions).pipe(catchError(this.handleError));
  }
  saveConceptEvaluation(token: string,siteData:ISiteSetup ){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })
    };
    return this.http.put(this.configService.resourceApiURI + `/conceptEvaluations/${siteData.id.toString()}`,siteData, httpOptions).pipe(catchError(this.handleError));
  }
  
  submitProtocol(token: string,siteData:ISiteSetup ){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })
    };
    return this.http.put(this.configService.resourceApiURI + `/protocolSubmissions/${siteData.id.toString()}`,siteData, httpOptions).pipe(catchError(this.handleError));
  }
  
  evaluateProtocol(token: string,siteData:ISiteSetup ){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })
    };
    debugger;
    return this.http.put(this.configService.resourceApiURI + `/protocolEvaluations/${siteData.id.toString()}`,siteData, httpOptions).pipe(catchError(this.handleError));
  }
  contractNegotiation(token: string,siteData:ISiteSetup ){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })
    };
    debugger;
    return this.http.put(this.configService.resourceApiURI + `/contractNegotiation/${siteData.id.toString()}`,siteData, httpOptions).pipe(catchError(this.handleError));
  }
  getProtocolEvaluations=(token: string, conceptId:number) :Observable<IProtocolEvaluation[]> =>{   
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })
    };
    return this.http.get<IProtocolEvaluation[]>(this.configService.resourceApiURI + `/protocolEvaluations/${conceptId.toString()}`, httpOptions).pipe(catchError(this.handleError));
  }
  //ContractNegotiation
  getContractNegotiations=(token: string, conceptId:number) :Observable<IContractNegociation[]> =>{   
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })
    };
    return this.http.get<IContractNegociation[]>(this.configService.resourceApiURI + `/contractNegotiation/${conceptId.toString()}`, httpOptions).pipe(catchError(this.handleError));
  }
  
  addPI(token: string,principalInvestigator:PrincipalInvestigator ){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })
    };
    debugger;
    return this.http.post(this.configService.resourceApiURI + '/principalInvestigators',principalInvestigator, httpOptions).pipe(catchError(this.handleError));
  }  
  registerPI(token: string,principalInvestigator:RegisterUser ){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })
    };
    debugger;
    return this.http.post(this.configService.authApiURI + '/accounts',principalInvestigator, httpOptions).pipe(catchError(this.handleError));
  }

  listPI=(token: string):Observable<IPrincipalInvestigator[]> => {   
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })
    };
    return this.http.get<IPrincipalInvestigator[]>(this.configService.resourceApiURI + '/principalInvestigators', httpOptions).pipe(catchError(this.handleError));
 
  }
  trialomsGeocode=(address:string):Observable<any>=>{
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    return this.http.get<any>(this.configService.googleMapApi+address+'&key='+this.configService.googleMapKey).pipe(catchError(this.handleError));
  }
  updateLatitudeLongitude(token: string,siteData:ISiteSetup ){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })
    };
    return this.http.put(this.configService.resourceApiURI + `/longitudeLatitude/${siteData.id.toString()}`,siteData, httpOptions).pipe(catchError(this.handleError));
  }
  updateLatitudeLongitudeIrb(token: string, irb: IIrb) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.put(this.configService.resourceApiURI + `/IrbLongitudeLatitude/${irb.id.toString()}`, irb, httpOptions).pipe(catchError(this.handleError));
  }
  //IAnalyticsDataSummary
  getAnalyticsDataSummary = (token: string, siteId: number): Observable<IAnalyticsDataSummary> => {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };

    return this.http.get<IAnalyticsDataSummary>(this.configService.resourceApiURI + `/issSiteSetups/analyticsDataSummary/${siteId.toString()}`, httpOptions).pipe(catchError(this.handleError));
  }
  getIrbAnalyticsDataSummary = (token: string, irbId: number): Observable<IAnalyticsDataSummary> => {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };

    return this.http.get<IAnalyticsDataSummary>(this.configService.resourceApiURI + `/irbs/analyticsDataSummary/${irbId.toString()}`, httpOptions).pipe(catchError(this.handleError));
  }
  addMaterial = (token:string,material:Material) : Observable<any> =>{
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })
    };
    debugger;
    return this.http.post(this.configService.resourceApiURI + '/materials',material, httpOptions).pipe(catchError(this.handleError));
  }  
  //get Material deliveries
  getDeliveries =(token:string,irbId:number) : Observable<IMaterial[]> =>{
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })
    };
    debugger;
    return this.http.get<IMaterial[]>(this.configService.resourceApiURI + `/materials/irb/${irbId.toString()}`, httpOptions).pipe(catchError(this.handleError));
  }
  getAllMaterialInventory = (token: string, irbId: number): Observable<IMaterial[]> => {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<IMaterial[]>(this.configService.resourceApiURI + `/patients/irb/${irbId.toString()}`, httpOptions).pipe(catchError(this.handleError));
  }
  getMaterialById=(token: string, id: number): Observable<IMaterial> => {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    
    return this.http.get<IMaterial>(this.configService.resourceApiURI + `/materials/${id.toString()}`, httpOptions).pipe(catchError(this.handleError));
  }
  returnMaterial=(token:string,materialReturn:MaterialReturn) : Observable<any> =>{
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })
    };
    return this.http.post(this.configService.resourceApiURI + '/materialReturns',materialReturn, httpOptions).pipe(catchError(this.handleError));
  }  
  getPatientTreatments = (token: string, patientId: number): Observable<ITreatment[]> => {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<ITreatment[]>(this.configService.resourceApiURI + `/treatments/patient/${patientId.toString()}`, httpOptions).pipe(catchError(this.handleError));
  }

  getAllPatient = (token: string, irbId: number): Observable<IPatient[]> => {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<IPatient[]>(this.configService.resourceApiURI + `/patients/irb/${irbId.toString()}`, httpOptions).pipe(catchError(this.handleError));
  }
  getAllReturns = (token: string): Observable<IMaterialReturn[]> => {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.get<IMaterialReturn[]>(this.configService.resourceApiURI + `/materialReturns/`, httpOptions).pipe(catchError(this.handleError));
  }
  //authApiURI
  
  registerPrincipalInvestigator=(token:string,piDetails:TrialomsLogin) : Observable<ITrialomsLogin> =>{
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })
    };
    return this.http.post<ITrialomsLogin>(this.configService.authApiURI + '/principalInvestigator',piDetails, httpOptions).pipe(catchError(this.handleError));
  } 
  registerLocalPI=(token:string,piDetails:TrialomsLogin) : Observable<ITrialomsLogin> =>{
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })
    };
    return this.http.post<ITrialomsLogin>(this.configService.authApiURI + '/localPI',piDetails, httpOptions).pipe(catchError(this.handleError));
  } 
  uploadDocuments=(token:string,formData:FormData) : Observable<any> =>{
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': token
      })
    };
    return this.http.post<any>(this.configService.resourceApiURI + '/documents',formData, httpOptions).pipe(catchError(this.handleError));
  } 
  getAllSetupParameters = (token:string):Observable<IParameter[]> =>{
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': token
      })
    };
    return this.http.get<IParameter[]> (this.configService.resourceApiURI +`/setupParameters/`,httpOptions).pipe(catchError(this.handleError));
  } 
  getSingleSetupParameter = (token:string, i :number):Observable<IParameter[]> =>{
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': token
      })
    };
    return this.http.get<IParameter[]> (this.configService.resourceApiURI +`/setupParameters/single/${i.toString()}`,httpOptions).pipe(catchError(this.handleError));
  }
  createParameter=  (token:string, newParameter:Parameter):Observable<any> =>{
        const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })
    };
    return this.http.post<IParameter>(this.configService.resourceApiURI +`/setupParameters/`,newParameter,httpOptions).pipe(catchError(this.handleError)); 
  }
  updateParameter= (token:string, parameter:IParameter):Observable<any> =>{
    const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Authorization': token
  })
};
return this.http.put<any>(this.configService.resourceApiURI +`/setupParameters/${parameter.id.toString()}`,parameter,httpOptions).pipe(catchError(this.handleError)); 

  }
  getSetupParameterAudit = (token:string, i :number):Observable<IParameterAudit[]> =>{
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': token
      })
    };
    return this.http.get<IParameterAudit[]> (this.configService.resourceApiURI +`/setupParameters/audit/${i.toString()}`,httpOptions).pipe(catchError(this.handleError));
  } 

}
