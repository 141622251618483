import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Shell } from './../shell/shell.service';
import { CompanyComponent } from './company/company.component';
import { TrialSiteComponent } from './trial-site/trial-site.component';
import { UpdateTrialSiteComponent } from './update-trial-site/update-trial-site.component';
import { TrialProtocolComponent } from './trial-protocol/trial-protocol.component';
import { TrialInclusionCriteriaComponent } from './trial-inclusion-criteria/trial-inclusion-criteria.component';
import { NewTrialSiteComponent } from './new-trial-site/new-trial-site.component';
import { SiteComponent } from './site/site.component';
import { NewSiteComponent } from './new-site/new-site.component';
import { UpdateSiteComponent } from './update-site/update-site.component'
import {AreaComponent} from './area/area.component';
import {AreaProtocolComponent} from './area-protocol/area-protocol.component';
import {AreaProtocolInclusionCriteriaComponent} from './area-protocol-inclusion-criteria/area-protocol-inclusion-criteria.component';
import { AdminHomeComponent} from './admin-home/admin-home.component';
import {OmnipotentCompanyTrialsComponent} from './omnipotent-company-trials/omnipotent-company-trials.component';
import {UpdateCompanyComponent} from './update-company/update-company.component';
import {NewCompanyComponent} from './new-company/new-company.component';
import {NewTrialComponent} from './new-trial/new-trial.component';
import {NewAreaComponent} from './new-area/new-area.component';
import {UpdateTrialComponent} from './update-trial/update-trial.component';
import {UpdateAreaComponent} from './update-area/update-area.component';
import { AuthGuard } from '../core/authentication/auth.guard';
import { from } from 'rxjs';

const routes: Routes = [
Shell.childRoutes([
    { path: 'company', component: CompanyComponent, canActivate: [AuthGuard] } ,
    { path: 'company', component: CompanyComponent, canActivate: [AuthGuard] } ,
    { path: 'site', component: SiteComponent, canActivate: [AuthGuard] } ,
    { path: 'adminHome', component:AdminHomeComponent,canActivate: [AuthGuard] },
    { path: 'companyTrials/:companyId', component: OmnipotentCompanyTrialsComponent, canActivate: [AuthGuard] },
    { path: 'updateCompany/:companyId', component: UpdateCompanyComponent, canActivate: [AuthGuard] },
    { path: 'updateSite/:siteId', component: UpdateSiteComponent, canActivate: [AuthGuard] },
    { path: 'newCompany', component:NewCompanyComponent,canActivate: [AuthGuard] },
    { path: 'newSite', component:NewSiteComponent,canActivate: [AuthGuard] },
    { path: 'newTrial/:companyId', component:NewTrialComponent,canActivate: [AuthGuard] },
    { path: 'updateTrial/:trialId/:companyId', component:UpdateTrialComponent,canActivate: [AuthGuard] },
    { path: 'areas/:companyId', component: AreaComponent, canActivate: [AuthGuard] },
    { path: 'areaProtocols/:trialId', 
      component: AreaProtocolComponent,
      canActivate: [AuthGuard] } ,
    { path: 'newArea/:companyId', component:NewAreaComponent,canActivate: [AuthGuard] },
    { path: 'updateArea/:areaId/:companyId', component:UpdateAreaComponent,canActivate: [AuthGuard] },
    { path: 'trialSites/:trialId', component: TrialSiteComponent, canActivate: [AuthGuard] } ,
    { path: 'newTrialSite/:trialId', component: NewTrialSiteComponent, canActivate: [AuthGuard] } ,
    { path: 'updateTrialSite/:trialSiteId', component: UpdateTrialSiteComponent, canActivate: [AuthGuard] },
    { path: 'trialProtocol/:companyId/:trialId', component: TrialProtocolComponent, canActivate: [AuthGuard] },
    { path: 'inclusionCriteria/:protocolId/:trialId', component: TrialInclusionCriteriaComponent, canActivate: [AuthGuard] },
    { path: 'areaProtocolInclusionCriteria/:protocolId', component: AreaProtocolInclusionCriteriaComponent, canActivate: [AuthGuard] }//

  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class OmnipotentRoutingModule { }