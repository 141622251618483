<div class="row justify-content-center"
    *ngIf="contractEvaluations!=undefined && contractEvaluations!=null && contractEvaluations.length>0">
    <div class="col-lg-12 text-center">
        <div class="col-md-12">
            <h3>Contract Negotiation History</h3>
            <table class="table small">
                <thead>

                   <!-- <tr>-->
                        <th scope="col">Audit Trail</th>
                        <th scope="col">Contract Evaluation Date</th>
                        <th scope="col">Total Budget ($)</th> 
                        <th scope="col">Review Status</th>
                       <!-- <th scope="col">Comments</th> -->
                        <th scope="col">Created By</th>
                        <th scope="col">Comments</th> 
                  <!---  </tr> -->
                </thead>
                <tbody>
                    <tr *ngFor="let contractEvaluation of contractEvaluations; let i = index" [attr.data-index]="i">
                       <!-- <th scope="row">{{i+1}}</th> -->
                        <div class="col-lg-1">
                            <th for>{{contractAuditCount-i}}</th>
        
                        </div>
                        <td>{{contractEvaluation.contractEvaluationDate|date:'d MMM y H:mm:ss'}}</td>
                       <td>${{contractEvaluation.totalBudget}}</td>  
                        <td>{{contractEvaluation.contractEvaluationStatus}}</td> 
                        <td>{{contractEvaluation.createdBy}}</td>
                    <!--<td>{{contractEvaluation.contractEvaluationComment}}</td>  -->
                        <td  colspan="3" *ngIf="contractEvaluation.contractEvaluationStatus!=null">
                <div class="form-group">
                    <textarea type="text" id="contractEvaluation.contractEvaluationComment" name="contractEvaluation.contractEvaluationComment" [(ngModel)]="contractEvaluation.contractEvaluationComment" 
                    class="form-control COMMENTBOX " readonly></textarea>

                </div>
                       </td>
          
                    </tr>

                </tbody>

            </table>


        </div>
    </div>
</div>

<!--End contract negotiation-->

<div class="row justify-content-center"
    *ngIf="protocolEvaluations!=undefined && protocolEvaluations!=null && protocolEvaluations.length>0">
    <div class="col-lg-12 text-center">
        <div class="col-md-12">
            <h3>Protocol Evaluation History</h3>
            <table class="table small">
                <thead>

                    <tr>
                        <th scope="col">Audit Trail</th>
                        <th scope="col">Protocol Submission Date</th>
                        <th scope="col">Protocol Evaluation Date</th>
                        <th scope="col">Review Status</th>
                        <th scope="col">Created By</th>
                        <th scope="col">Comments</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let protocolEvaluation of protocolEvaluations; let i = index" [attr.data-index]="i">
                       <!-- <th scope="row">{{i+1}}</th> -->
                        <div class="col-lg-1">
                            <th for>{{protocolAuditCount-i}}</th>
        
                        </div>
                        <td>{{protocolEvaluation.protocolSubmissionDate|date:'d MMM y H:mm:ss'}}</td>
                        <td>{{protocolEvaluation.protocolEvaluationDate|date:'d MMM y H:mm:ss'}}</td>
                        <td>{{protocolEvaluation.protocolEvaluationStatus}}</td> 
                        <td>{{protocolEvaluation.createdBy}}</td>
                   <!-- <td>{{protocolEvaluation.protocolEvaluationComment}}</td> -->

                        <td  colspan="3" *ngIf="protocolEvaluation.protocolEvaluationComment!=null">
                            <div class="form-group">
                                <textarea type="text" id="protocolEvaluation.protocolEvaluationComment" name="protocolEvaluation.protocolEvaluationComment" [(ngModel)]="protocolEvaluation.protocolEvaluationComment" 
                                class="form-control COMMENTBOX " readonly></textarea>
            
                            </div>
                                   </td>
                    </tr>
                </tbody>

            </table>
        </div>
    </div>
</div>

<!--End protocolEvaluations-->
<div *ngIf="!busy && conceptEvaluations!=undefined && conceptEvaluations!=null && conceptEvaluations.length>0">
    <div class="row justify-content-center">
        <div class="col-lg-12 text-center">
            <div class="col-md-12">
                <h3>Concept Evaluation History</h3>
                <table class="table small">
                    <thead>

                        <tr>
                            <th scope="col">Audit Trail</th>
                            <th scope="col">Concept Submission Date</th>
                            <th scope="col">Concept Evaluation Date</th>
                            <th scope="col">Review Status</th>
                            <th scope="col">Created By</th>
                            <th scope="col">Comments</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let conceptEvaluation of conceptEvaluations; let i = index" [attr.data-index]="i">
                           
                          <!-- <th scope="row">{{i+1}}</th> -->
                       <div class="col-lg-1">
                            <th for>{{conceptAuditCount-i}}</th>
        
                        </div>
                            <td>{{site.conceptSubmissionDate|date:'d MMM y H:mm:ss'}}</td>   
                            <td>{{conceptEvaluation.conceptEvaluationDate|date:'d MMM y H:mm:ss'}}</td>
                            <td>{{conceptEvaluation.conceptEvaluationStatus}}</td>
                            <td>{{conceptEvaluation.createdBy}}</td>  
                       <!-- <td>{{conceptEvaluation.conceptEvaluationComment}}</td> -->
                            <td  colspan="3" *ngIf="conceptEvaluation.conceptEvaluationComment!=null">
                                <div class="form-group">
                                    <textarea type="text" id="conceptEvaluation.conceptEvaluationComment" name="conceptEvaluation.conceptEvaluationComment" [(ngModel)]="conceptEvaluation.conceptEvaluationComment" 
                                    class="form-control COMMENTBOX " readonly></textarea>
                
                                </div>
                                       </td>
                        </tr>
                    </tbody>
                        
                </table>
            </div>
            <ngx-spinner type="pacman">
    <p>Loading...</p></ngx-spinner>
        </div>
    </div>

    <!--End conceptEvaluations-->
    
    <div class="row justify-content-center">
        <div class="col-lg-4 offset-lg-8 col-xs-12">
            <a class="btn btn-lg btn-primary form-control" ngbTooltip="Return to site set up"
                [routerLink]="['/siteSetup']" role="button">Back
            </a>
        </div>
    </div>
</div>