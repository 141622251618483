import { Component } from '@angular/core';

@Component({
  selector: 'app-pi-patients',
  templateUrl: './pi-patients.component.html',
  styleUrls: ['./pi-patients.component.sass']
})
export class PiPatientsComponent {

}
