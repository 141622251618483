import { Component, OnInit } from '@angular/core';
import { SiteSetup, ISiteSetup } from 'src/app/shared/interfaces/siteSetup';
import { AdverseEvent, IAdverseEvents, IAdverseEventAudits } from 'src/app/shared/interfaces/adverseEvent';

import { Irb, IIrb, IIrbAudits } from 'src/app/shared/interfaces/irb';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators'
import { AuthService } from '../../core/authentication/auth.service';
import { PiAdministrationService } from '../pi-administration.service';
import { IssAdministrationService } from '../../investigator-initiated-study/iss-administration.service';
import { Router, CanActivate, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { DatePipe } from '@angular/common';
//import { ThrowStmt } from '@angular/compiler';
@Component({
  selector: 'app-irb-submission',
  templateUrl: './irb-submission.component.html',
  styleUrls: ['../styles.sass', './irb-submission.component.sass']
})
export class IrbSubmissionComponent implements OnInit {

  conceptId: number;
  localPIiIrb:boolean=false;
  irbId: number;  //for PI
  site: ISiteSetup;
  submitText: string = "Create";
  irbTitle: string = "Request For IRB Review";// Submission To IRB // IRB Decision
  requestIrb: boolean = true;
  submitIrb: boolean = false;
  approveIrb: boolean = false;
  submitNew: boolean = true;//submit will create a new adverse eevent
  outcomeString: string;
  commentVisible: boolean = false;
  auditVisible: boolean = false;
  irb: Irb = new Irb();
  irbList: IIrb[];
  irbListFiltered: IIrb[];
  irbListFilteredCount: number;
  statusNumber:string = "0";
  statusString:string;
  

  irbAuditList: IIrbAudits[];
  buttonBaseClass: string = "btn btn-lg form-control ";
  error: boolean = false;
  busy: boolean = true;
  auditBusy: boolean = false;
  isCollapsed: boolean = false;
  isPending: boolean = false;
  isResubmit: boolean = false;
  isApproved: boolean = false;
  lastestReviewDate: string;
  lastComment: string;
  principalInvestigator: boolean = false;
  irbTooltip: string = "Click to hide or to create an IRB submission";
  irbRequestCount:number;  //To count the number of IRB reequest
  constructor(private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private pIAdministrationService: PiAdministrationService,
    private administrationService: IssAdministrationService,
    private spinner: NgxSpinnerService) { }


  ngOnInit(): void {
    var aWhere = this.route.snapshot.url[0].path;
    if(aWhere=='displayirb')
    {
      this.localPIiIrb=true;
      this.irbId = +this.route.snapshot.paramMap.get('irbId')
    }
    debugger;
    console.log(`is busy ${this.busy} collapsed ${this.isCollapsed}`);
    this.busy = true;
    this.showSpinner();
    this.submitNew = true;
    this.submitText = "Request IRB Review";
    this.irb.protocolVersion = "Version #1";
    if (sessionStorage.getItem('principalInvestigator') === 'true') {
      this.principalInvestigator = true;
    }
    else {
      this.principalInvestigator = false;
    }
    if (this.localPIiIrb){
      this.irbId = +this.route.snapshot.paramMap.get('irbId');
      this.select(this.irbId);
      this.hideSpinner();
      return;
    }
    this.conceptId = +this.route.snapshot.paramMap.get('conceptId');
    this.irb.issSiteId = this.conceptId;
    this.administrationService.getIssSite(this.authService.authorizationHeaderValue, this.conceptId)
      .pipe(finalize(() => {
        this.busy = false;
        this.hideSpinner();
      })).subscribe(
        result => {
          this.site = result;
          debugger;
          if (this.site.protocolVersion != null && this.site.protocolVersion.length > 0) {
            this.irb.protocolVersion = this.site.protocolVersion;
          }
          console.log(this.site);
        });
    this.getIrbList();
  }

  select = (eventId: number): void => {
    this.isCollapsed = false;
    if(!this.localPIiIrb){
      this.getIrbList();
    }
    this.submitNew = false; //will update an adverse event
    this.submitIrb = true; //by default on selection - will be modified to approve if necessary
    this.approveIrb = false; // this is approval stage
    this.requestIrb = false; // going to submit
    this.isResubmit = false;
    this.irbTitle = "Submit To IRB";
    this.submitText = "Submit";
    this.busy = true;
    this.showSpinner();
    this.outcomeString = "";
    this.commentVisible = false;

    this.pIAdministrationService.getSelectedIrb(this.authService.authorizationHeaderValue, eventId)
      .pipe(finalize(() => {
        this.busy = false;
        this.hideSpinner();
      })).subscribe(
        result => {
          debugger;
          this.irb = result;
          if(this.irb.longitude==null || this.irb.latitude==null )
          {
            this.getLongLat();
          }
          if (this.irb.submissionDate != null) {
            this.submitNew = false;//not a request
            this.submitIrb = false; // not irb submission
            this.approveIrb = true; // this is approval stage
            this.irbTitle = "IRB Decision";
            this.submitText = "Confirm Decision";
          }
          if(this.localPIiIrb){
            this.conceptId=this.irb.issSiteId;
            //getSite
            this.getSite(this.conceptId);
          }
          this.updateApproval();
          if (this.isPending) {
            //put it back in submit irb mode
            this.irbTitle = "Submit To IRB";
            this.submitText = "Submit";
            this.isResubmit = true;
            this.submitIrb=true;
            this.approveIrb = false;
            this.irb.submissionDate = null;
            this.irb.reviewDate = null;
          }
        });
  }
  updateApproval = (): void => {
    this.isApproved = false;
    this.isPending = false;
    if (this.approvalStatus(this.irb) == 'Approved') {
      this.isApproved = true;
      return;
    }
    if (this.approvalStatus(this.irb) == 'Pending Revision') {
      this.isPending = true;
      this.lastestReviewDate = this.irb.reviewDate.toString();
      this.lastComment = this.irb.comment;
      return;
    }
  }
  updateOutcome = (): void => {
    debugger;
    if (this.outcomeString == "true") {
      this.irb.outcome = true;
      this.commentVisible = false;
    }
    else {
      this.irb.outcome = false;
      this.commentVisible = true;
    }
  }
  onSubmit = (): void => {
    this.auditVisible = false;
    this.isCollapsed = true;
    if (this.submitNew) {
      this.createIrb();

    }
    else {
      this.updateIrb();
    }
  }
  // get longitude & latitude for the current irb
  
  getLongLat = (): void => {
    let a = this.irb;
    this.busy=true;
    this.administrationService.trialomsGeocode(a.geoLocation)
      .pipe(finalize(() => {
        this.busy=false;
      })).subscribe(
        results => {
          try {
            debugger;
            var formattedAddress = results.results[0].formatted_address;
            this.irb.longitude = results.results[0].geometry.location.lng.toString();
            this.irb.latitude = results.results[0].geometry.location.lat.toString();
            this.irb.country = this.getCountry(formattedAddress).trim();
            console.log(results);

          }
          catch (e) {
            console.log(results);
            console.log(e.message);
          }

        });
  }

  getCountry(formattedAddress: string): string {
    var positionOfCountry = formattedAddress.lastIndexOf(",");
    var country = formattedAddress.substring(positionOfCountry);
    country = country.substring(1, country.length)
    return country;
  }
  getIrbList = (): void => {

    console.log(this.conceptId);
    this.spinner.show();
    this.busy = true;
    this.pIAdministrationService.getIrbs(this.authService.authorizationHeaderValue, this.conceptId)
      .pipe(finalize(() => {
        this.busy = false;
        this.hideSpinner();
      })).subscribe(
        result => {
          this.irbList = result;
          this.irbRequestCount = this.irbList.length; // counting the number of IRB requests
          this.irbListFiltered = result;
          this.irbListFilteredCount = this.irbListFiltered.length; //To count the result of filtering
          console.log(this.irbList);
        });
  }
  mouseOverSetUp = (): void => {

    console.log(`is busy ${this.busy} collapsed ${this.isCollapsed}`);
  }
  showSpinner = (): void => {
    this.spinner.show();

  }
  hideSpinner = (): void => {
    if (this.busy || this.auditBusy) {
      return;
    }
    this.spinner.hide();
  }
  createIrb = (): void => {
    this.showSpinner();
    this.pIAdministrationService.addIrb(this.authService.authorizationHeaderValue, this.irb)
      .pipe(finalize(() => {
        this.busy = false;
        this.hideSpinner();
      })).subscribe(
        result => {
          console.log(result);
          this.getIrbList();
          //this.router.navigate(['/siteSetup']);
        });
  }
  updateIrb = (): void => {
    this.showSpinner();
    this.pIAdministrationService.updateIrb(this.authService.authorizationHeaderValue, this.irb)
      .pipe(finalize(() => {
        this.busy = false;
        this.hideSpinner();
      })).subscribe(
        result => {
          console.log(result);
          this.getIrbList();
          //this.router.navigate(['/siteSetup']);
        });
  }

  approvalStatusClass = (aIrb: Irb): string => {
    if (this.approvalStatus(aIrb) == "Approved") {
      return "clsApproved";
    }
    if (this.approvalStatus(aIrb) == "Pending Revision") {
      return "clsPending";
    }
    if (this.approvalStatus(aIrb) == "Submitted") {
      return "clsSubmitted";
    }
    if (this.approvalStatus(aIrb) == "Requested") {
      return "clsRequested";
    }
    return "clsRequested";
  }

  approvalStatus = (aIrb: Irb): string => {
    if (aIrb.reviewDate != undefined && aIrb.reviewDate != null) {


      if (aIrb.outcome == true) {
        return "Approved";
      }
      if (aIrb.outcome == false) {
        return "Pending Revision";
      }
    }
    if (aIrb.submissionDate != undefined && aIrb.submissionDate != null) {
      return "Submitted";
    }
    return "Requested"
  }

  irbApprovalStatus= ():void=>{
    var myStatusString = ["All IRBs", "Requested",
                       "Submitted", "Pending Revision", "Approved"]

  let index = Number(this.statusNumber);
  this.statusString = myStatusString[index];
  console.log(`${this.statusString} number = ${index}`);

  if(index==0){
    this.irbListFiltered=this.irbList
    this.irbListFilteredCount=this.irbListFiltered.length; //To count the result of filtering
  }
  else{

    this.irbListFiltered=this.irbList.filter( aIrb=>
    this.approvalStatus(aIrb) == this.statusString);
    this.irbListFilteredCount=this.irbListFiltered.length; //To count the result of filtering
  }
  }
  getSite=(conceptId:number): void =>{

    this.administrationService.getIssSite(this.authService.authorizationHeaderValue, this.conceptId)
      .pipe(finalize(() => {
        this.busy = false;
        this.hideSpinner();
      })).subscribe(
        result => {
          this.site = result;
          debugger;
          if (this.site.protocolVersion != null && this.site.protocolVersion.length > 0) {
            this.irb.protocolVersion = this.site.protocolVersion;
          }
          console.log(this.site);
        });

  }
  getIrbById = (irbId: number): void => {
    this.isCollapsed = false;
    this.submitNew = false; //will update an adverse event
    this.submitIrb = true; //by default on selection - will be modified to approve if necessary
    this.approveIrb = false; // this is approval stage
    this.requestIrb = false; // going to submit
    this.isResubmit = false;
    this.irbTitle = "Submit To IRB";
    this.submitText = "Submit";
    this.busy = true;
    this.showSpinner();
    this.outcomeString = "";
    this.commentVisible = false;

    this.pIAdministrationService.getSelectedIrb(this.authService.authorizationHeaderValue, irbId)
      .pipe(finalize(() => {
        this.busy = false;
        this.hideSpinner();
      })).subscribe(
        result => {
          debugger;
          this.irb = result;
          if (this.irb.submissionDate != null) {
            this.submitNew = false;//not a request
            this.submitIrb = false; // not irb submission
            this.approveIrb = true; // this is approval stage
            this.irbTitle = "IRB Decision";
            this.submitText = "Confirm Decision";
          }
          if(this.localPIiIrb){
            this.conceptId=this.irb.issSiteId;
            //getSite
            this.getSite(this.conceptId);
          }
          this.updateApproval();
          if (this.isPending) {
            //put it back in submit irb mode
            this.irbTitle = "Submit To IRB";
            this.submitText = "Submit";
            this.isResubmit = true;
            this.submitIrb=true;
            this.approveIrb = false;
            this.irb.submissionDate = null;
            this.irb.reviewDate = null;
          }
        });
  }
 
}
