import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators'
import { AuthService } from '../../core/authentication/auth.service';
import { PiAdministrationService } from '../pi-administration.service';
import { IssAdministrationService } from '../../investigator-initiated-study/iss-administration.service';
import { Router, CanActivate, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Patient, IPatient } from 'src/app/shared/interfaces/patient';

@Component({
  selector: 'app-consent-form-signed',
  templateUrl: './consent-form-signed.component.html',
  styleUrls: ['./consent-form-signed.component.sass']
})
export class ConsentFormSignedComponent implements OnInit {
  irbId:number;
  patientId:number=0;
  busy: boolean = true;
  biologicalGender:string;
  patient:Patient;
  patientList:IPatient[];
  patientListBusy:boolean=true;//buusy retrieving patient List
  patientTooltip:string="Click to toggle between Add and Search"
  patientNumber:string="";
  dateOfBirth:Date; //=new  Date('Jan 1, 2021');

constructor(private route: ActivatedRoute,
  private authService: AuthService,
  private router: Router,
  private pIAdministrationService: PiAdministrationService,
  private administrationService: IssAdministrationService,
  private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    
    this.patientId = +this.route.snapshot.paramMap.get('patientId');
    this.patient=new Patient();
    this.busy=false;
    this.patient.irbId=this.patientId;
      this.getPatient(this.patientId);
  }
  getPatient=(irbId:number):void => {
    this.busy=true;
    this.showSpinner();
    this.pIAdministrationService.getSelectedPatient(this.authService.authorizationHeaderValue, this.patientId)
      .pipe(finalize(() => {
        this.busy=false;
        this.hideSpinner();
      })).subscribe(
        result => {
          this.patient=result;
          this.irbId=this.patient.irbId;
          console.log(" patients");
          console.log(result);
        });

  }
  selectForTrial = (): void =>{
    console.log (this.patient.selected);
  }
  showSpinner = (): void => {
    this.spinner.show();

  }
  hideSpinner = (): void => {
    if (this.busy) {
      return;
    }
    this.spinner.hide();
  }

  onSubmit = (): void =>{ 
    this.patientListBusy=true;
    this.showSpinner();
    this.pIAdministrationService.updatePatient(this.authService.authorizationHeaderValue, this.patient)
      .pipe(finalize(() => {
        this.busy = false;
        this.hideSpinner();
      })).subscribe(
        result => { 
          console.log(result);
          this.router.navigate(['/patient', this.irbId]);
        });
  }


}
