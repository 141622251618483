<div class = "row mb-1">
    <div class="col-lg-8"></div>
    <div class="col-lg-4"><a  class="btn btn-lg btn-primary ml-4" routerLink="/irbSubmission/{{conceptId}}" role="button">Request an IRB instead?
        &raquo;</a></div>
</div>
<div>
    <div class="row mb-1 justify-content-center">

        <div class="col-lg-12 document" align="center">
            <h3>Required Documents Check</h3>


        </div>
        <div class="col-lg-12 text-center" *ngIf="!historyOnly">

            <form *ngIf="!busy" #f="ngForm" (ngSubmit)="onSubmit()">

                <div class="row mb-1">
                    <div class="col-lg-2 col-xs-12 mb-1">
                        <div class="form-group">
                            <label for="siteName">Institution Name</label>
                        </div>
                    </div>

                    <div class="col-lg-4 col-xs-12">
                        <div class="form-group">
                            <input type="text" id="siteName" name="siteName" [(ngModel)]="site.siteName"
                                class="form-control" readonly>
                        </div>
                    </div>

                    <div class="col-lg-2 col-xs-12 mb-1">
                        <div class="form-group">
                            <label for="studyName">Study Name</label>
                        </div>
                    </div>

                    <div class="col-lg-4 col-xs-12">
                        <div class="form-group">

                            <input type="text" id="studyName" name="studyName" [(ngModel)]="site.studyName"
                                class="form-control" readonly>
                        </div>
                    </div>
                </div>
                <div class="row mb-1">
                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <label for="geoLocation">Address</label>
                        </div>
                    </div>

                    <div class="col-lg-10 col-xs-12">
                        <div class="form-group">
                            <input type="text" id="geoLocation" name="geoLocation" [(ngModel)]="site.geoLocation"
                                class="form-control" readonly>
                        </div>
                    </div>
                </div>
                <!-- </div> -->
                <!-- -->
                <div class="alert alert-primary" *ngIf="isCollapsed" (click)="isCollapsed = !isCollapsed"
                    aria-controls="siteInfo">Click here for more info on this site

                </div>
                <div class="alert alert-secondary" *ngIf="!isCollapsed" (click)="isCollapsed = !isCollapsed"
                    aria-controls="siteInfo">Click here for less info on this site

                </div>
                <div class="col-lg-12 text-center collapse" id='siteInfo' [ngbCollapse]="isCollapsed">

                    <div class="card">
                        <div class="card-body">
                            <!---->
                            <div class="row mb-1">
                                <div class="col-lg-2 col-xs-12">
                                    <div class="form-group">
                                        <label for="piName">PI </label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xs-12">
                                    <div class="form-group">
                                        <input type="text" id="piName" name="piName" [(ngModel)]="site.piName"
                                            class="form-control" placeholder="Name" readonly>
                                    </div>
                                </div>

                                <div class="col-lg-2 col-xs-12">
                                    <div class="form-group">
                                        <label for="piEmail"> </label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xs-12">
                                    <div class="form-group">
                                        <input type="email" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                            id="piEmail" name="piEmail" [(ngModel)]="site.piEmail" class="form-control"
                                            placeholder="Email" readonly>
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-1">
                                <div class="col-lg-2 col-xs-12">
                                    <div class="form-group">
                                        <label for="siteResearchCoordinatorName">Site Ressearch Coordinator</label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xs-12">
                                    <div class="form-group">
                                        <input type="text" id="siteResearchCoordinatorName"
                                            name="siteResearchCoordinatorName"
                                            [(ngModel)]="site.siteResearchCoordinatorName" class="form-control"
                                            placeholder="Name" readonly>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-xs-12">
                                    <div class="form-group">
                                        <label for="piEmail"></label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xs-12">
                                    <div class="form-group">
                                        <input type="email" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                            id="siteResearchCoordinatorEmail" name="siteResearchCoordinatorEmail"
                                            [(ngModel)]="site.siteResearchCoordinatorEmail" class="form-control"
                                            placeholder="Email" readonly>
                                    </div>
                                </div>

                            </div>


                            <div class="row mb-1">
                                <div class="col-lg-2 col-xs-12">
                                    <div class="form-group">
                                        <label for="msl">MSL</label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xs-12">
                                    <div class="form-group">
                                        <input type="text" id="msl" name="msl" [(ngModel)]="site.msl"
                                            class="form-control" placeholder="Name" readonly>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-xs-12">
                                    <div class="form-group">
                                        <label for="mslEmail"></label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xs-12">
                                    <div class="form-group">
                                        <input type="email" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                            id="mslEmail" name="mslEmail" [(ngModel)]="site.mslEmail"
                                            class="form-control" placeholder="Email" readonly>
                                    </div>
                                </div>

                            </div>

                            <!---->
                            <div class="row mb-1">

                                <div class="col-lg-2 col-xs-12">

                                    <div class="form-group">
                                        <label for="diseaseArea">Disease Area</label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xs-12">

                                    <div class="form-group">
                                        <input type="text" id="diseaseArea" name="diseaseArea"
                                            [(ngModel)]="site.diseaseArea" class="form-control" readonly>
                                    </div>
                                </div>

                            </div>

                            <div class="row mb-1">


                                <div class="col-lg-2 col-xs-12">
                                    <div class="form-group">
                                        <label for="protocol">Concept Submited on</label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xs-12">
                                    <div class="form-group">
                                        {{site.conceptSubmissionDate|date:'d MMMM y'}}
                                    </div>
                                </div>

                            </div>

                            <div class="row mb-1">

                                <div class="col-lg-2 col-xs-12">
                                    <div class="form-group">
                                        <label for="concept">Concept Evaluated on</label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xs-12">
                                    <div class="form-group">
                                        {{site.conceptEvaluationDate|date:'d MMMM y'}}
                                    </div>
                                </div>
                                <div class="col-lg-2 col-xs-12">

                                    <div class="form-group">
                                        <label for="conceptEvaluationStatus">Status</label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xs-12">

                                    <div class="form-group">
                                        <!-- -->
                                        <input type="text" class="form-control" id="conceptEvaluationStatus"
                                            name="conceptEvaluationStatus" [(ngModel)]="site.conceptEvaluationStatus"
                                            readonly>
                                    </div>
                                </div>


                            </div>
                            <div class="row mb-1">

                                <div class="col-lg-2 col-xs-12">

                                    <div class="form-group">
                                        <label for="conceptEvaluationComment">Evaluation Comment</label>
                                    </div>
                                </div>
                                <div class="col-lg-10 col-xs-12">

                                    <div class="form-group">
                                        <input type="text" id="conceptEvaluationComment" name="conceptEvaluationComment"
                                            [(ngModel)]="site.conceptEvaluationComment" class="form-control"
                                            placeholder="Evaluation Comment" readonly>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-1">
                                <div class="col-lg-2 col-xs-12">
                                    <div class="form-group">
                                        <label for="concept">Protocol Submited on</label>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-xs-12">
                                    <div class="form-group">
                                        {{site.protocolSubmissionDate|date:'d MMMM y'}}
                                    </div>
                                </div>
                                <div class="col-lg-2 col-xs-12">
                                    <div class="form-group">
                                        <label for="concept">Protocol file link</label>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-xs-12">
                                    <div class="form-group alert alert-primary">
                                        link to approved protocol here
                                    </div>
                                </div>
                            </div>



                            <div class="row mb-1">
                                <div class="col-lg-2 col-xs-12">
                                    <div class="form-group">
                                        <label for="concept">Protocol Evaluated on </label>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-xs-12">
                                    {{site.protocolEvaluationDate|date:'d MMMM y'}}

                                </div>

                                <div class="col-lg-2 col-xs-12">

                                    <div class="form-group">
                                        <label for="protocolEvaluationStatus">Status</label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xs-12">

                                    <div class="form-group">
                                        <input type="text" class="form-control" id="protocolEvaluationStatus"
                                            name="protocolEvaluationStatus" [(ngModel)]="site.protocolEvaluationStatus"
                                            readonly>

                                    </div>
                                </div>

                                <div *ngIf="!validEvaluationDate && evaluationDateErrorMessage.length>5"
                                    class="col-lg-12 alert alert-danger">
                                    {{evaluationDateErrorMessage}}
                                </div>

                            </div>
                            <div class="row mb-1">

                                <div class="col-lg-2 col-xs-12">

                                    <div class="form-group">
                                        <label for="protocolEvaluationComment">Evaluation Comment</label>
                                    </div>
                                </div>
                                <div class="col-lg-10 col-xs-12">

                                    <div class="form-group">
                                        <input type="text" id="protocolEvaluationComment"
                                            name="protocolEvaluationComment"
                                            [(ngModel)]="site.protocolEvaluationComment" class="form-control"
                                            placeholder="Protocol Evaluation Comment" readonly>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-1">
                                <div class="col-lg-2 col-xs-12">
                                    <div class="form-group">
                                        <label for="setUpCost">Set Up Costs</label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xs-12">
                                    <div class="form-group">
                                        <input type="number" id="setUpCost" name="setUpCost"
                                            [(ngModel)]="site.setUpCost" placeholder="Set Up Costs" class="form-control"
                                            readonly>
                                    </div>
                                </div>
                                <!--  </div>
              
                        <div class="row mb-1"> -->
                                <div class="col-lg-2 col-xs-12">

                                    <div class="form-group">
                                        <label for="totalBudget">Total Budget</label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xs-12">

                                    <div class="form-group">
                                        <input type="number" id="totalBudget" name="totalBudget"
                                            [(ngModel)]="site.totalBudget" placeholder="Total Budget"
                                            class="form-control" readonly>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-1">
                                <div class="col-lg-2 col-xs-12">
                                    <div class="form-group">
                                        <label for="costPerPatient">Cost per Patient</label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xs-12">
                                    <div class="form-group">
                                        <input type="number" id="costPerPatient" name="costPerPatient"
                                            placeholder="Cost Per Patient" [(ngModel)]="site.costPerPatient"
                                            class="form-control" readonly>
                                    </div>
                                </div>
                                <!--  </div>
              
                        <div class="row mb-1"> -->
                                <div class="col-lg-2 col-xs-12">

                                    <div class="form-group">
                                        <label for="materialCost">Material Costs</label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xs-12">

                                    <div class="form-group">
                                        <input type="number" id="materialCost" name="materialCost"
                                            [(ngModel)]="site.materialCost" class="form-control"
                                            placeholder="Material Costs" readonly>
                                    </div>
                                </div>
                            </div>
                            <!--    <div class="row mb-1" *ngIf="LastEvaluationDate!=null">
                                <div class="col-lg-12 col-xs-12">
                                    <p>This is a reevaluation, the concept has been evaluated on &nbsp;
                                        <b> {{LastEvaluationDate|date:'d MMMM y'}}</b>&nbsp; and review team decision
                                        was
                                        &nbsp;<b>{{LastStatus}}</b></p>
                                </div>
                            </div> -->
                            <div class="row mb-1">
                                <div class="col-lg-2 col-xs-12">
                                    <div class="form-group">
                                        <label for="contractEvaluationDate">Contract Evaluation Date</label>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-xs-12">
                                    <div class="form-group">
                                        <b> {{site.contractEvaluationDate|date:'d MMMM y'}}</b>
                                        <!--   <input type="date" id="contractEvaluationDate" name="contractEvaluationDate"
                                            [(ngModel)]="site.contractEvaluationDate|date:'d MMMM y'" class="form-control"
                                            readonly>  -->
                                    </div>
                                </div>

                                <div class="col-lg-2 col-xs-12">

                                    <div class="form-group">
                                        <label for="contractEvaluationStatus">Status</label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xs-12">

                                    <div class="form-group">

                                        <input type="text" class="form-control" id="contractEvaluationStatus"
                                            name="contractEvaluationStatus" [(ngModel)]="site.contractEvaluationStatus"
                                            readonly>
                                        <!--         <select class="form-control" id="contractEvaluationStatus"
                                            name="contractEvaluationStatus" placeholder="Status"
                                            [(ngModel)]="site.contractEvaluationStatus" readonly>

                                            <option value="Approved">Approved</option>
                                            <option value="Conditional approval pending revisions">Pending Revision
                                            </option>
                                        </select> -->
                                    </div>
                                </div>

                            </div>
                            <div class="row mb-1">

                                <div class="col-lg-2 col-xs-12">

                                    <div class="form-group">
                                        <label for="contractEvaluationComment">Evaluation Comment</label>
                                    </div>
                                </div>
                                <div class="col-lg-10 col-xs-12">

                                    <div class="form-group">
                                        <input type="text" id="contractEvaluationComment"
                                            name="contractEvaluationComment"
                                            [(ngModel)]="site.contractEvaluationComment" class="form-control"
                                            placeholder="Contract Evaluation Comment" readonly>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>




                </div>
                <!--PI's Documents-->
                <div class="row mb-1">
                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <label for="piCV" class="checkbox"><input type="checkbox" id="piCV" name="piCV"
                                    [(ngModel)]="site.piCV">PI’s curriculum vitae</label>
                        </div>
                    </div>

                    <div class="col-lg-4 col-xs-12">
                        <div class="form-group">
                            <input type="file" id="piCVLink" name="piCVLink" 
                            (change)="addFile($event, 'piCV')"
                                class="form-control" [(ngModel)]="site.piCVLink">
                        </div>
                    </div>

                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <label for="formW9" class="checkbox"><input type="checkbox" id="formW9" name="formW9"
                                    [(ngModel)]="site.formW9" value="">Form W-9 (optional)</label>
                        </div>
                    </div>

                    <div class="col-lg-4 col-xs-12">
                        <div class="form-group">
                            <input type="file" id="formW9Link" name="formW9Link" placeholder="Form W-9 Link"
                            (change)="addFile($event, 'formW9')"
                                class="form-control" [(ngModel)]="site.formW9Link">
                        </div>
                    </div>
                </div>

                <div class="row mb-1">
                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <label class="checkbox"><input type="checkbox" id="studyPILicences" name="studyPILicences"
                                    [(ngModel)]="site.studyPILicences">PI's Licences or DEA Licence (if applicable)</label>
                        </div>
                    </div>
                
                    <div class="col-lg-4 col-xs-12">
                        <div class="form-group">
                            <input type="file" id="studyPILicencesLink" name="studyPILicencesLink" placeholder="Copy of PI's Medical Licenses or DEA License Link"
                            (change)="addFile($event, 'studyPILicences')"
                                class="form-control" [(ngModel)]="site.studyPILicencesLink">
                        </div>
                    </div>
                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <label class="checkbox"><input type="checkbox" id="pharmacyLicense" name="pharmacyLicense"
                                    [(ngModel)]="site.pharmacyLicense" value="">Pharmacy License if Study Drugs are requested</label>
                        </div>
                    </div>
                
                    <div class="col-lg-4 col-xs-12">
                        <div class="form-group">
                            <input type="file" id="pharmacyLicenseLink" name="pharmacyLicenseLink" placeholder="Pharmacy License Link"
                            (change)="addFile($event, 'pharmacyLicense')"
                                class="form-control" [(ngModel)]="site.pharmacyLicenseLink">
                        </div>
                    </div>
                </div>
                <!-- Final approved Study Protocol and Budget 
                Final informed consent form-->
                <div class="row mb-1">
                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <label class="checkbox"><input type="checkbox" id="studyProtocolAndBudget" name="studyProtocolAndBudget"
                                    [(ngModel)]="site.studyProtocolAndBudget">Study Protocol and Budget</label>
                        </div>
                    </div>

                    <div class="col-lg-4 col-xs-12">
                        <div class="form-group">
                            <input type="file" id="studyProtocolAndBudgetLink" name="studyProtocolAndBudgetLink" placeholder="Final approved Study Protocol and Budget Link"
                            (change)="addFile($event, 'studyProtocolAndBudget')"
                                class="form-control" [(ngModel)]="site.studyProtocolAndBudgetLink">
                        </div>
                    </div>
                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <label class="checkbox"><input type="checkbox" id="informedConsent" name="informedConsent"
                                    [(ngModel)]="site.informedConsent" value="">Final informed consent form</label>
                        </div>
                    </div>

                    <div class="col-lg-4 col-xs-12">
                        <div class="form-group">
                            <input type="file" id="informedConsentLink" name="informedConsentLink" placeholder="Final informed consent form Link"
                            (change)="addFile($event, 'informedConsent')"
                                class="form-control" [(ngModel)]="site.informedConsentLink">
                        </div>
                    </div>
                </div>

   <!--............................ New Additions..................................................-->             
   <div class="row mb-1">
    <div class="col-lg-2 col-xs-12">
        <div class="form-group">
            <label class="checkbox"><input type="checkbox" id="studySignedContract" name="studySignedContract"
                    [(ngModel)]="site.studySignedContract">Fully Executed IIS (or IST) Agreement</label>
        </div>
    </div>

    <div class="col-lg-4 col-xs-12">
        <div class="form-group">
            <input type="file" id="studySignedContractLink" name="studySignedContractLink" placeholder="Fully Executed IIS (or IST) Agreement Link"
            (change)="addFile($event, 'studySignedContract')"
                class="form-control" [(ngModel)]="site.studySignedContractLink">
        </div>
    </div>
    <div class="col-lg-2 col-xs-12">
        <div class="form-group">
            <label class="checkbox"><input type="checkbox" id="signedIrbApproval" name="signedIrbApproval"
                    [(ngModel)]="site.signedIrbApproval" value="">Documentation of IRB/IACUC Approval</label>
        </div>
    </div>

    <div class="col-lg-4 col-xs-12">
        <div class="form-group">
            <input type="file" id="signedIrbApprovalLink" name="signedIrbApprovalLink" placeholder="Documentation of IRB/IACUC Approval Link"
            (change)="addFile($event, 'signedIrbApproval')"
                class="form-control" [(ngModel)]="site.signedIrbApprovalLink">
        </div>
    </div>
</div>
  
  
<!--!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!-->

<div class="row mb-1">
    <div class="col-lg-2 col-xs-12">
        <div class="form-group">
            <label class="checkbox"><input type="checkbox" id="studyRegulatoryResponseDoc" name="studyRegulatoryResponseDoc"
                    [(ngModel)]="site.studyRegulatoryResponseDoc">Regulatory Response Document         </label>
        </div>
    </div>

    <div class="col-lg-4 col-xs-12">
        <div class="form-group">
            <input type="file" id="studyRegulatoryResponseDocLink" name="studyRegulatoryResponseDocLink" placeholder="Regulatory Response Doc (IND Number) Link"
            (change)="addFile($event, 'signedIrbApproval')"
                class="form-control" [(ngModel)]="site.studyRegulatoryResponseDocLink">
        </div>
    </div>
    <div class="col-lg-2 col-xs-12">
        <div class="form-group">
            <label class="checkbox"><input type="checkbox" id="ClincalTrialGovNumber" name="ClincalTrialGovNumber"
                    [(ngModel)]="site.ClincalTrialGovNumber" value="">NCT Number from ClinicalTrials.gov</label>
        </div>
    </div>

    <div class="col-lg-4 col-xs-12">
        <div class="form-group">
            <input type="url" id="ClincalTrialGovLink" name="ClincalTrialGovLink" placeholder="National Clinical Trial (NCT) Number Link"
                class="form-control" [(ngModel)]="site.ClincalTrialGovLink">
        </div>
    </div>
</div>

<!--..................................................................................................-->
                <div class="row mb-1">
                    <div class="col-lg-2 col-xs-12">
                    </div>
                    <div class="col-lg-4 col-xs-12">
                        <div class="form-group">
                            <a class="btn btn-lg btn-primary btn-block" [routerLink]="['/siteSetup']"
                                role="button">Cancel</a>
                        </div>
                    </div>
                    <div class="col-lg-2 col-xs-12">
                    </div>
                    <div class="col-lg-4 col-xs-12">
                        <div class="form-group">
                            <button class="btn btn-lg btn-primary btn-block" type="submit"
                                [disabled]="!f.form.valid  || !atLeastOneFileToUpload">
                                Update
                            </button>
                        </div>
                    </div>
                </div>



                
                <div *ngIf="error" class="alert alert-danger mt-3" role="alert">
                    <strong>Oops!</strong> {{error}}
                </div>
            </form>

        </div>
    </div>

    <ngx-spinner type="pacman">
    <p>Loading...</p></ngx-spinner>
</div>