export class AreaProtocol{
    public id:number;
    public trialId:number;
    public areaId:number;
    public protocolName: string;
    public protocolFile:string;
    public protocolDate: Date;
    public createdBy:string;
    public dateCreated:Date;
    public modifiedBy: string;
    public dateModified: Date;
    public deleted:boolean;
}
export interface IAreaProtocol{
    id:number;
    trialId:number;
    areaId:number;
    protocolName: string;
    protocolFile:string;
    protocolDate: Date;
    createdBy:string;
    dateCreated:Date;
    modifiedBy: string;
    dateModified: Date;
    deleted:boolean;
}