<div class="row mb-1 justify-content-center">

    <div class="col-lg-12 concept" align="center">
      <div>
        <h3>Material Management View Deliveries!</h3>
      </div>
    </div>
</div>
<div class="row mb-1">

    <div class="col-lg-4 col-xs-12">
        <div class="form-group">
            <a class="btn btn-lg btn-primary btn-block" routerLink="/drugs/" 
                role="button">Back</a>
        </div>
    </div>
</div>
<div *ngIf="busy">
    <h1>Loading...................</h1>
</div>
<div class="row mb-1" *ngIf="!busy" >
    <div class="col-lg-2 col-xs-12" style="display: flex; justify-content: center; align-items: center;" (click)="sort('batchNumber')">
       <b>Batch Number</b>
    </div>

    <div class="col-lg-2 col-xs-12" style="display: flex; justify-content: center; align-items: center;" (click)="sort('manufacturingReleaseDate')">
        <b>Manuf. Release Date</b>
     </div>

     <div class="col-lg-2 col-xs-12" style="display: flex; justify-content: center; align-items: center;" (click)="sort('dateCreated')">
        <b>Dispatch Date</b>
     </div>
    
    <div class="col-lg-2 col-xs-12" style="display: flex; justify-content: center; align-items: center;" (click)="sort('numberOfItems')">
       <b>Dispatched Inventory</b>
    </div>

    <div class="col-lg-2 col-xs-12" style="display: flex; justify-content: center; align-items: center;" (click)="sort('expiryDate')">
        <b>Expiry Date</b>
     </div>
   
     <div class="col-lg-2 col-xs-12" style="display: flex; justify-content: center; align-items: center;" (click)="sort('materialsStatus')">
        <b>Batch Status</b>
     </div>
  
</div>  

<div class="row mb-1" *ngFor="let aMaterial of allMaterials let i = index" >
    <div class="col-lg-2 col-xs-12" style="display: flex; justify-content: center; align-items: center;">
        {{aMaterial.batchNumber}}
    </div>
    <div class="col-lg-2 col-xs-12" style="display: flex; justify-content: center; align-items: center;">
        {{aMaterial.manufacturingReleaseDate|date:'d MMMM y'}}
    </div>

    <div class="col-lg-2 col-xs-12" style="display: flex; justify-content: center; align-items: center;">
        {{aMaterial.dateCreated|date:'d MMMM y'}}
    </div>

    
    
    <div class="col-lg-2 col-xs-12" style="display: flex; justify-content: center; align-items: center;">
        {{aMaterial.numberOfItems}}
    </div>
    
    <div class="col-lg-2 col-xs-12" style="display: flex; justify-content: center; align-items: center;">
        {{aMaterial.expiryDate|date:'d MMMM y'}}
    </div>

    <div 
        class="col-lg-2 col-xs-12" style="display: flex; justify-content: center; align-items: center;"
        *ngIf="materialsStatus[i][0] > 0" >
        <button  class="btn width-button"
        [ngStyle]="{'background-color': getButtonColor(materialsStatus[i][0]), 'color': 'black'}">
            Active Batch, <b>{{ materialsStatus[i][0] }}</b> Day(s) to Expiration
        </button>
    </div>
    <div 
        class="col-lg-2 col-xs-12" style="display: flex; justify-content: center; align-items: center;"
        *ngIf="materialsStatus[i][0] <= 0" >
        <button class="btn width-button" 
        [ngStyle]="{'background-color': getButtonColor(materialsStatus[i][0]), 'color': 'black'}">
            <b>{{ materialsStatus[i][1]}}</b>
        </button>
    </div>

</div>  