import {PositionMarker, IPositionMarker} from 'src/app/shared/interfaces/positionMarker';
//import {PinElement} from 'google.maps.marker';

export class Marker{
    public position:PositionMarker;
    public title:string;
    public label?:any;
    public options:string;  //AAA
    public icon:any;   //AAA
    public id:number;
    public siteName:string;
    public geoLocation:string;
    public zIndex:number;
    public markerIndex:number;
    public markerType:string;

}
export interface IMarker{
    position:IPositionMarker;
    title:string;
    label:any;
    options:string;  //AAA
    icon:any;  //AAA
    id:number;   //AAA
    siteName:string;  //AAA
    geoLocation:string;
    zIndex:number;
    markerIndex:number;
    markerType:string;

}
