import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideAnimations } from '@angular/platform-browser/animations';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';  //AAA  for Material
import {CdkTableModule} from '@angular/cdk/table';
//import { AnalyticsComponent } from './investigator-initiated-study/analytics/analytics.component'; //AAA
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
//import { AgmCoreModule } from '@agm/core';
import { GoogleMapsModule } from '@angular/google-maps'
// used to create fake backend
import { FakeBackendProvider } from './shared/mocks/fake-backend-interceptor';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ConfigService } from './shared/config.service';

import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
// Toastr

 
import { ToastrModule } from 'ngx-toastr';
/* Module Imports */
import { CoreModule } from './core/core.module';
import { HomeModule }  from './home/home.module';
import { AccountModule }  from './account/account.module';
import { ShellModule } from './shell/shell.module';
import { TopSecretModule } from './top-secret/top-secret.module';
import { OmnipotentModule } from './omnipotent/omnipotent.module';
import { SharedModule }   from './shared/shared.module';
import { InvestigatorInitiatedStudyModule} from './investigator-initiated-study/investigator-initiated-study.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PrincipalInvestigatorModule } from './principal-investigator/principal-investigator.module';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatSliderModule} from '@angular/material/slider';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { TableModule } from 'primeng/table';
import { KnobModule } from 'primeng/knob';
import { SliderModule } from 'primeng/slider';
import { InputTextModule} from 'primeng/inputtext';
import { ChartModule } from 'primeng/chart';
import { PrimeIcons } from 'primeng/api';
import { DragDropModule } from '@angular/cdk/drag-drop';





@NgModule({
  declarations: [
    AppComponent,
    AuthCallbackComponent,
    
   
    
  ],
  imports: [
    
    CommonModule,
    BrowserAnimationsModule, // required animations module
    /* AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCkviDi0WgxiYHc5I3UH8lHiLRg9vZxV6o'
    }), */
    GoogleMapsModule,
    ToastrModule.forRoot({
      timeOut: 100000,
      positionClass: 'inline',
      preventDuplicates: true,
    }),
    BrowserModule, 
    MatTabsModule,  // AAA
    FormsModule,  //for material
    BrowserAnimationsModule,
    HttpClientModule, 
    CoreModule,
    HomeModule,
    AccountModule,
    TopSecretModule,   
    OmnipotentModule,
    InvestigatorInitiatedStudyModule,
    PrincipalInvestigatorModule,
    AppRoutingModule,
    ShellModule,   
    SharedModule, NgbModule,
    CdkTableModule,
    MatSelectModule,
    MatCardModule,
    MatSliderModule,
    ButtonModule, CardModule, TableModule, KnobModule, SliderModule, InputTextModule,
    ChartModule,
    DragDropModule
    

  ],

  providers: [
    ConfigService,DatePipe,
    // provider used to create fake backend
    FakeBackendProvider,
    provideAnimations(),
    PrimeIcons
  ],
  
  exports:[
    BrowserModule,
    BrowserAnimationsModule,
    MatTabsModule,
    FormsModule,
    CoreModule,
    CdkTableModule,
    MatSelectModule,
    MatCardModule,
    MatSelectModule,
    ButtonModule, CardModule, TableModule, KnobModule,

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
