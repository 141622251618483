import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-omnipotent-ompany-trials',
  templateUrl: './omnipotent-ompany-trials.component.html',
  styleUrls: ['./omnipotent-ompany-trials.component.sass']
})
export class OmnipotentOmpanyTrialsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
