import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators'
import { AuthService } from '../../core/authentication/auth.service';
import { AdministrationService } from '../administration.service';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
@Component({
  selector: 'app-update-site',
  templateUrl: './update-site.component.html',
  styleUrls: ['./update-site.component.sass']
})
export class UpdateSiteComponent implements OnInit {
  site:any;
  success:boolean=false;
  busy:boolean=false;
  siteId:number;
  error:string;
  constructor(private authService: AuthService,private route: ActivatedRoute, private router:Router, private administrationService: AdministrationService, private spinner: NgxSpinnerService) { 

  }
  
  ngOnInit() {
    this.siteId = +this.route.snapshot.paramMap.get('siteId');
    //retrieve the site/getsite
    
    this.busy = true;
    this.spinner.show();
    this.administrationService.getSite(this.authService.authorizationHeaderValue,this.siteId)
    .pipe(finalize(() => {
      this.spinner.hide();
    })).subscribe(
    result => {   
      this.busy = false;      
      this.site = result;
   });
    console.log(`site Id=${this.siteId}`)
  }
  onSubmit() {
    console.log('submit');
    this.busy=true;
    this.spinner.show();
    this.administrationService.updateSite(this.authService.authorizationHeaderValue,
                this.siteId,
                this.site)
    .pipe(finalize(() => {
      this.spinner.hide();
      this.busy = false;
    })).subscribe(
    result => {         
      //this.companies = result;
      this.router.navigate(['/site']);//view list
   },
   error => {
     this.error = error; 
   });
   
   }
}
