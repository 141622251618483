import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators'
import { AuthService } from '../../core/authentication/auth.service';
import { AdministrationService } from '../administration.service';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Component({
  selector: 'app-omnipotent-company-trials',
  templateUrl: './omnipotent-company-trials.component.html',
  styleUrls: ['./omnipotent-company-trials.component.sass']
})
export class OmnipotentCompanyTrialsComponent implements OnInit {

busy: boolean;
companyId:number;
trials:any;
company:any;

  constructor(private route: ActivatedRoute,private authService: AuthService,private router:Router, private administrationService: AdministrationService, private spinner: NgxSpinnerService ) {
     
  }

  ngOnInit() {
    this.companyId = +this.route.snapshot.paramMap.get('companyId');
    console.log(`Company Id=${this.companyId}`)
    console.log("starting .... listing trials"); 

    this.busy = true;
// Get Company
this.administrationService.getCompany(this.authService.authorizationHeaderValue,this.companyId)
.pipe(finalize(() => {
 // this.spinner.hide();
})).subscribe(
result => {   
 // this.busy = false;      
  this.company = result;
});
//

    this.spinner.show();
    this.administrationService.fetchTrialsData(this.authService.authorizationHeaderValue, this.companyId)
    .pipe(finalize(() => {
      this.spinner.hide();
      this.busy = false;
    })).subscribe(
    result => {         
      this.trials = result;
   });
  }

}
