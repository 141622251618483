import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pi-dashboard',
  templateUrl: './pi-dashboard.component.html',
  styleUrls: ['./pi-dashboard.component.sass']
})
export class PiDashboardComponent implements OnInit {
  userName:string= sessionStorage.getItem("userName");
  constructor() { }

  ngOnInit(): void {

  }

}
