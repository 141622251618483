import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ICompany, Company } from 'src/app/shared/interfaces/company';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators'
import { AuthService } from '../../core/authentication/auth.service';
import { AdministrationService } from '../administration.service';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Component({
  selector: 'app-update-company',
  templateUrl: './update-company.component.html',
  styleUrls: ['./update-company.component.sass']
})
export class UpdateCompanyComponent implements OnInit {
  company:any;
  success:boolean=false;
  busy:boolean=false;
  companyId:number;
  error:string;
  constructor(private authService: AuthService,private route: ActivatedRoute, private router:Router, private administrationService: AdministrationService, private spinner: NgxSpinnerService) { 
    /* this.company.companyName='';
    this.company.companyAddress=''; */
  }
  
  ngOnInit() {
    this.companyId = +this.route.snapshot.paramMap.get('companyId');
    //retrieve the company/getCompany
    
    this.busy = true;
    this.spinner.show();
    this.administrationService.getCompany(this.authService.authorizationHeaderValue,this.companyId)
    .pipe(finalize(() => {
      this.spinner.hide();
    })).subscribe(
    result => {   
      this.busy = false;      
      this.company = result;
   });
    console.log(`Company Id=${this.companyId}`)
  }
  onSubmit() {
    console.log('submit');
    this.busy=true;
    this.spinner.show();
    this.administrationService.updateCompany(this.authService.authorizationHeaderValue,
                this.companyId,
                this.company)
    .pipe(finalize(() => {
      this.spinner.hide();
      this.busy = false;
    })).subscribe(
    result => {         
      //this.companies = result;
      this.router.navigate(['/company']);//view list
   },
   error => {
     this.error = error; 
   });
   
   }
}
