import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators'
import { AuthService } from '../../core/authentication/auth.service';
import { AdministrationService } from '../administration.service';
import { Router, CanActivate, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { InclusionCriteria } from 'src/app/shared/interfaces/inclusionCriteria';

@Component({
  selector: 'app-trial-inclusion-criteria',
  templateUrl: './trial-inclusion-criteria.component.html',
  styleUrls: ['./trial-inclusion-criteria.component.sass']
})
export class TrialInclusionCriteriaComponent implements OnInit {
inclusionCriteria:InclusionCriteria;
trialId:any;
error:boolean;
criteriaSet:any;
protocolId:any;
busy:boolean=true;
protocolBusy:boolean=true;
currentProtocol:any;
companyId:number;
inExclusion:string;
  constructor(private route: ActivatedRoute,
    private authService: AuthService,
    private router:Router, 
    private administrationService: AdministrationService, 
    private spinner: NgxSpinnerService) { 
    this.inclusionCriteria=new InclusionCriteria();
    
  }

  ngOnInit(): void {
    this.inclusionCriteria=new InclusionCriteria();
    this.inclusionCriteria.inExclusion=true;
    this.protocolId= +this.route.snapshot.paramMap.get('protocolId');
    this.inclusionCriteria.protocolId=this.protocolId;
    this.trialId= +this.route.snapshot.paramMap.get('trialId');
    this.busy=true;
    this.spinner.show();
    this.administrationService.fetchInclusionCriteriaData(this.authService.authorizationHeaderValue,this.protocolId)
    .pipe(finalize(() => {
      this.busy=false;
      this.spinner.hide();
    })).subscribe(
    result => {        
      this.criteriaSet = result;
      console.log(this.criteriaSet);
    });
    this.getCurrentProtocol();
  }
  getAllInclusionCriteria()
  {
    this.busy=true;
    this.spinner.show();
    this.administrationService.fetchInclusionCriteriaData(this.authService.authorizationHeaderValue,this.protocolId)
    .pipe(finalize(() => {
      this.busy=false;
      this.spinner.hide();
    })).subscribe(
    result => {        
      this.criteriaSet = result;
      console.log(this.criteriaSet);
    });
  }
  getCurrentProtocol()
  {
    this.protocolBusy=true;
    this.administrationService.getProtocolbyId(this.authService.authorizationHeaderValue,this.protocolId)
    .pipe(finalize(() => {
      this.protocolBusy=false;
    })).subscribe(
    result => {        
      this.currentProtocol = result;
      this.companyId=this.currentProtocol.trial.company.companyId;

      console.log(this.currentProtocol);
    });
  }
  inExclusionChanged()
  {
    if (this.inExclusion=='inclusion')
    {
      this.inclusionCriteria.inExclusion=true;
    }
    
    if (this.inExclusion=='exclusion')
    {
      this.inclusionCriteria.inExclusion=false;
    }
    console.log (this.inclusionCriteria.inExclusion);
  }
  deleteCriteria(id:number)
  {
    console.log(id);
  }
  onSubmit() {
    this.busy=true;
    this.spinner.show();
    this.administrationService.addInclusionCriteria(this.authService.authorizationHeaderValue
      ,this.inclusionCriteria)
      .pipe(finalize(() => {
        this.busy=false;
       this.spinner.hide();
      })).subscribe(
      result => {    
    console.log('submit');  
    this.getAllInclusionCriteria();  
    });
      
  }
}
