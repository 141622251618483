import { Component, OnInit } from '@angular/core';
import { SiteSetup, ISiteSetup } from 'src/app/shared/interfaces/siteSetup';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators'
import { AuthService } from '../../core/authentication/auth.service';
import { IssAdministrationService } from '../iss-administration.service';
import { Router, CanActivate, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['../styles.sass','./document.component.sass']
})
export class DocumentComponent implements OnInit {
  conceptId: number;
  site: ISiteSetup;
  pipe: DatePipe;
  isCollapsed: boolean = true;
  atLeastOneFileToUpload:boolean=false;
  validEvaluationDate: boolean = false;
  evaluationDateErrorMessage: string = '';
  lastContractEvaluation: Date;
  
  LastEvaluationDate:Date;
  LastStatus:string;
  error: boolean = false;
  busy: boolean = false;
  auditBusy: boolean = false;
  historyOnly: boolean = false;
  formData = new FormData();

  constructor(private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private administrationService: IssAdministrationService,
    private spinner: NgxSpinnerService) { }


  ngOnInit(): void {

    this.conceptId = +this.route.snapshot.paramMap.get('conceptId');
    console.log(this.conceptId);
    if (this.route.snapshot.url[0].path == 'conceptEvaluationAudit') {
      this.historyOnly = true;
    }
    console.log(`url= ${this.route.snapshot.url}`);
    // get concept
    //get site for this concept
    // this.getConceptEvaluationAudit();
    this.spinner.show();
    this.busy = true;
    this.administrationService.getIssSite(this.authService.authorizationHeaderValue, this.conceptId)
      .pipe(finalize(() => {
        this.busy = false;
        this.hideSpinner();
      })).subscribe(
        result => {
          this.site = result;
          this.lastContractEvaluation = this.site.contractEvaluationDate;
          this.LastEvaluationDate=this.site.contractEvaluationDate;
          this.LastStatus=this.site.contractEvaluationStatus;
          console.log(this.site);
        });

  }
  hideSpinner = (): void => {
    if (this.busy || this.auditBusy) {
      return;
    }
    this.spinner.hide();
  }
  addFile(event,document:string )
  {
  //  console.log(event.target.files);
    console.log(document);       
    const file:File = event.target.files[0];
    this.atLeastOneFileToUpload=true;
    if (file) {
        debugger;
        var fileName = file.name;
        try{
          if (this.formData.has(document)){
          this.formData.delete(document);}
        }
        catch(err)
        {
          console.log (err);
        }

        this.formData.append(document, file,fileName);

   
    }
  
  }
  onSubmit= ():void=>{     
    //const upload$ = this.http.post("/api/thumbnail-upload", this.formData);
    //upload$.subscribe();
    this.formData.append("ConceptId",this.conceptId.toString());
    const myFormData=this.formData;
    const result = Object.fromEntries([...myFormData].map(([key, value]) => [ 
      key, value
    ]))
    
    console.log(result);
    this.spinner.show();
    this.busy=true;
    this.administrationService.uploadDocuments(this.authService.authorizationHeaderValue, myFormData)
    .pipe(finalize(() => {
      this.busy = false;
      this.spinner.hide();
    })).subscribe(
      result => {
      });
  } 
}
