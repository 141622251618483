import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';;
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators'
import { AuthService } from '../../core/authentication/auth.service';
import { AdministrationService } from '../administration.service';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
@Component({
  selector: 'app-update-area',
  templateUrl: './update-area.component.html',
  styleUrls: ['./update-area.component.sass']
})
export class UpdateAreaComponent implements OnInit {
  area:any;
  success:boolean=false;
  busy:boolean=false;
  areaId:number;
  companyId:number;
  error:string;
  constructor(private authService: AuthService,
    private route: ActivatedRoute, 
    private router:Router, 
    private administrationService: AdministrationService, 
    private spinner: NgxSpinnerService) { }

  ngOnInit() {    this.areaId = +this.route.snapshot.paramMap.get('areaId');
  this.companyId=+this.route.snapshot.paramMap.get('companyId');
  //retrieve the area/getarea
  
  this.busy = true;
  this.spinner.show();
  this.administrationService.getArea(this.authService.authorizationHeaderValue,this.areaId)
  .pipe(finalize(() => {
    this.spinner.hide();
  })).subscribe(
  result => {   
    this.busy = false;      
    this.area = result;

 });
  console.log(`area Id=${this.areaId}`)
}
onSubmit() {
  console.log('submit');
  this.busy=true;
  this.spinner.show();
  this.administrationService.updateArea(this.authService.authorizationHeaderValue,
              this.areaId,
              this.area)
  .pipe(finalize(() => {
    this.spinner.hide();
    this.busy = false;
  })).subscribe(
  result => {         
    //this.companies = result;
    this.router.navigate([`/areas/${this.companyId}`]);//view list
 },
 error => {
   this.error = error; 
 });
}

}
