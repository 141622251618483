import { Component } from '@angular/core';
import { IIrb } from 'src/app/shared/interfaces/irb';
import { IMaterial} from 'src/app/shared/interfaces/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators'
import { AuthService } from '../../core/authentication/auth.service';
import { IssAdministrationService } from '../iss-administration.service';
import { Router, CanActivate, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { tap, map } from 'rxjs/operators';

@Component({
  selector: 'app-material-management',
  templateUrl: './material-management.component.html',
  styleUrls: ['./material-management.component.sass']
})
export class MaterialManagementComponent {
allIrbs:IIrb [];
allMaterials: IMaterial[];
numberOfBatchesAtSite: number;
presenceOfMaterialAtSite: any[] = [];
presenceOfMaterialAtAllSites: any[] = [];
busy:boolean=false;

constructor(private route: ActivatedRoute,
  private authService: AuthService,
  private router: Router,
  private administrationService: IssAdministrationService,
  private spinner: NgxSpinnerService) { }
  ngOnInit(): void {

    this.spinner.show();
    this.busy=true;
    this.administrationService.getAllIrbs(this.authService.authorizationHeaderValue)
      .pipe(finalize(() => {
        this.busy = false;
        //this.hideSpinner();
        this.spinner.hide();
      })).subscribe(
        result => {
          this.allIrbs = result;
          this.checkMaterialsPresenceAtAllSites(this.allIrbs)
        });

  }

  showSpinner = (): void => {
    this.spinner.show();    
     }

    hideSpinner=():void =>{
     this.spinner.hide();
     }

  getDeliveries = (irbId:number): Observable<IMaterial[]> =>{
    this.busy=true;
    this.showSpinner();

    return this.administrationService.getDeliveries(this.authService.authorizationHeaderValue, irbId)
    .pipe(
      finalize(() => {
      this.busy=false;
      this.hideSpinner();
    }),
    tap((result: IMaterial[]) => {
     
        this.allMaterials=result;

      })
    );
  }

 

  checkMaterialsPresenceAtAllSites = async(allIrbs:IIrb[]): Promise<any> => {
    console.log(allIrbs);
    this.presenceOfMaterialAtAllSites = [];
    
    const tasks = this.allIrbs.filter(irb => irb.outcome != null && irb.outcome != false)
      .map(async irb => {
        await this.checkNumberofBatchesAddedToSite(this.getDeliveries(irb.id));
        this.presenceOfMaterialAtSite = [irb.id, irb.site.studyName ,irb.siteName ,irb.outcome, this.numberOfBatchesAtSite];
        return this.presenceOfMaterialAtSite;
      });
  
    this.presenceOfMaterialAtAllSites = await Promise.all(tasks);
    
    console.log('irbID, IrbApproval Status, #of Batches at Site',this.presenceOfMaterialAtAllSites);
    return this.presenceOfMaterialAtAllSites;
  }

checkNumberofBatchesAddedToSite = async(allMaterialsObservable:Observable<IMaterial[]>) =>{
  await new Promise ((resolve, reject) =>{
    allMaterialsObservable.subscribe({
      next: (allMaterials) =>{ 
        console.log(allMaterials);
        this.numberOfBatchesAtSite = allMaterials.length;
       // console.log(this.numberOfBatchesAtSite);
      },
      error: (err) => {
        console.error(err);
        reject(err);
      },
      complete: () => {
        resolve(this.numberOfBatchesAtSite);
      }
    });
  });
};

}
