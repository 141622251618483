import { Component, OnInit } from '@angular/core';
import { SiteSetup, ISiteSetup } from 'src/app/shared/interfaces/siteSetup';
import { AdverseEvent, IAdverseEvents, IAdverseEventAudits } from 'src/app/shared/interfaces/adverseEvent';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators'
import { AuthService } from '../../core/authentication/auth.service';
import { PiAdministrationService } from '../pi-administration.service';
import { IssAdministrationService } from '../../investigator-initiated-study/iss-administration.service';
import { Router, CanActivate, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
@Component({
  selector: 'app-adverse-effects',
  templateUrl: './adverse-effects.component.html',
  styleUrls: ['../styles.sass','./adverse-effects.component.sass']
})
export class AdverseEffectsComponent implements OnInit {
  conceptId: number;
  site: ISiteSetup;
  submitText:string="Create";
  submitNew:boolean=true;//submit will create a new adverse eevent
  auditVisible:boolean=false;
  adverseEvent: AdverseEvent = new AdverseEvent();
  adverseEventList:IAdverseEventAudits[];
  adverseEventAuditList:IAdverseEventAudits[];
  buttonBaseClass: string = "btn btn-lg form-control ";
  error: boolean = false;
  busy: boolean = false;
  auditBusy: boolean = false;
  isCollapsed: boolean = false;
  principalInvestigator: boolean = false;
  adverseEffectTooltip: string = "Click to set up a new adverse event";
  constructor(private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private pIAdministrationService: PiAdministrationService,
    private administrationService: IssAdministrationService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    console.log(`is busy ${this.busy} collapsed ${this.isCollapsed}`);
    this.submitNew=true; //will create a new adverse event
    this.submitText="Create";
    if (sessionStorage.getItem('principalInvestigator') === 'true') {
      this.principalInvestigator = true;
    }
    else {
      this.principalInvestigator = false;
    }
    this.conceptId = +this.route.snapshot.paramMap.get('conceptId');
   this.adverseEvent.issSiteId=this.conceptId;
   this. getAdverseEventList();
  }
  getAdverseEventList=(): void=>{
    console.log(this.conceptId);
    this.spinner.show();
    this.busy = true;
    this.pIAdministrationService.getAdverseEvents(this.authService.authorizationHeaderValue, this.conceptId)
      .pipe(finalize(() => {
        this.busy = false;
        this.hideSpinner();
      })).subscribe(
        result => {
          this.adverseEventList = result;
          console.log(this.adverseEventList);
        });
  }
  getAdverseEventAuditList=(): void=>{
    this.spinner.show();
    this.busy = true;
    this.pIAdministrationService.getAdverseEventAudit(this.authService.authorizationHeaderValue, this.adverseEvent.id)
      .pipe(finalize(() => {
        this.busy = false;
        this.hideSpinner();
      })).subscribe(
        result => {
          this.adverseEventAuditList = result;
          this.auditVisible=true;
          console.log(this.adverseEventAuditList);
        });
  }
  onSubmit = (): void => {
    this.auditVisible=false;
    if (this.submitNew)
    {
      this.createAdverseEvent();
      
    }
    else{
      this.updateAdverseEvent();
    }
  }
  select = (eventId : number):void=>{
    debugger;
    this.submitNew=false; //will update an adverse event
    this.submitText="Update";
    //get the event for eventId
    let eventIndex:number=0;
    for(let n=0;n<this.adverseEventList.length; n++)
    {
      if (this.adverseEventList[n].id==eventId)
      {
        eventIndex=n;
        //populate adverse event
        this.adverseEvent.adverseEventName=this.adverseEventList[n].adverseEventName;
        this.adverseEvent.seriousAdverseEvent=this.adverseEventList[n].seriousAdverseEvent;
        this.adverseEvent.adverseEventPriority=this.adverseEventList[n].adverseEventPriority;
        this.adverseEvent.id=this.adverseEventList[n].id;
        this.getAdverseEventAuditList();
      }
    }


  }
  createAdverseEvent =():void => {
    this.pIAdministrationService.addAdverseEvent(this.authService.authorizationHeaderValue, this.adverseEvent)
    .pipe(finalize(() => {
        this.busy = false;
        this.hideSpinner();
    })).subscribe(
      result => {
        console.log(result);
        this. getAdverseEventList();
        this.adverseEvent= new AdverseEvent();
        //this.router.navigate(['/siteSetup']);
        this.submitNew=true; //will create a new adverse event
        this.submitText="Create";
      });
  }
  updateAdverseEvent  =():void =>{
    this.pIAdministrationService.updateAdverseEvent(this.authService.authorizationHeaderValue, this.adverseEvent)
    .pipe(finalize(() => {
        this.busy = false;
        this.hideSpinner();
    })).subscribe(
      result => {
        console.log(result);
        this. getAdverseEventList();
        this.adverseEvent= new AdverseEvent();
     // this.submitNew=true; //will create a new adverse event  //moved under createAdverseEvent by Armel
     // this.submitText="Create";  //moved under createAdverseEvent by Armel
        //this.router.navigate(['/siteSetup']);
      });

  }
  mouseOverSetUp = (): void => {

    console.log(`is busy ${this.busy} collapsed ${this.isCollapsed}`);
  }
  hideSpinner = (): void => {
    if (this.busy || this.auditBusy) {
      return;
    }
    this.spinner.hide();
  }
}
