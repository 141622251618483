<h1  *ngIf="!busy && !protocolBusy">Inclusion Criteria</h1>
<div class="row justify-content-center" >
    <div class="col-lg-12 text-center">

        <form #f="ngForm" *ngIf="!busy && !protocolBusy"
        (ngSubmit)="onSubmit()">
            <!--Site -->
            <div class="row mb-1">
                <div class="col-lg-3 col-xs-12">
                    <div class="form-group">
                        <label for="criteria">Criteria</label>
                    </div>
                </div>

                <div class="col-lg-6 col-xs-12">
                    <div class="form-group">
                        <input type="text" id="criteria" name="criteria"
                            [(ngModel)]="inclusionCriteria.criteriaDescription" class="form-control"
                            placeholder="Inclusion or Exclusion Criteria" required>
                    </div>
                </div>
            </div>
            
            <div class="row mb-1">
                <div  class="col-lg-3 col-xs-12"></div>
                <div  class="col-lg-3 col-xs-12">
                    <div>
                        <input id="inclusion" type="radio" class="custom-control-input" 
                        value="inclusion" name="inExclusion"
                        (change)="inExclusionChanged()"
                         [(ngModel)]="inExclusion" required >
                        <label class="custom-control-label" for="inclusion">Inclusion</label>
                     </div>
                  
                     <div>
                        <input id="exclusion" type="radio" 
                        class="custom-control-input" value="exclusion" 
                        name="inExclusion"
                        (change)="inExclusionChanged()"
                        [(ngModel)]="inExclusion" required>
                        <label class="custom-control-label" for="exclusion">Exclusion</label>
                     </div>
                </div>

        </div>
            <div class="row mb-1">

                <div class="col-lg-3 col-xs-12">
                </div>
                <div class="col-lg-6 col-xs-12">
                    <div class="form-group">
                        <a class="btn btn-lg btn-primary btn-block" [routerLink]="['/areaProtocol', trialId]"
                            role="button">Cancel</a>
                    </div>
                </div>
            </div>
            <div class="row mb-1">
                <div class="col-lg-3 col-xs-12">
                </div>
                <div class="col-lg-6 col-xs-12">
                    <div class="form-group">
                        <button class="btn btn-lg btn-primary btn-block" type="submit" [disabled]="!f.form.valid">
                            Add
                            <span *ngIf="inclusionCriteria.inExclusion && inExclusion=='inclusion'">Inclusion</span> 
                            <span *ngIf="!inclusionCriteria.inExclusion">Exclusion</span> 
                            Criteria</button>
                    </div>
                </div>
            </div>

     
            <div *ngIf="error" class="alert alert-danger mt-3" role="alert">
                <strong>Oops!</strong> {{error}}
            </div>
        </form>
    </div>

    <div class="row justify-content-center">
        <div class="col-lg-12 text-center">
            <div *ngIf="!busy && !protocolBusy" class="col-md-12">
                <h3>List of criteria sent from the server for Protocol:
                      {{currentProtocol.protocolName}}
                    
                    </h3>
                <table class="table small">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Criteria</th>
                            <th scope="col">Created By</th>
                            <th scope="col">Date Created</th>
                            <th scope="col">Active</th>
                            <th scope="col">Type of Criteri</th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let criteria of criteriaSet; let i = index" [attr.data-index]="i">
                            <th scope="row">{{i+1}}</th>
                            <td style=" text-align: left;">{{criteria.criteriaDescription}}</td>
                            <td>{{criteria.createdBy}}</td>
                            <td>{{criteria.dateCreated}}</td>
                            <td>{{!criteria.deleted}} </td>
                            <td *ngIf="criteria.inExclusion"><!-- 
                                <a class="btn btn-lg btn-primary"
                                    [routerLink]="['/inclusionCriteria' , aProtocol.protocolId, trial.trialId]"
                                    role="button">Inclusion&nbsp;Criteria</a> 
                                 -->
                                 Inclusion
                                </td>
                                <td *ngIf="!criteria.inExclusion"><!-- 
                                    <a class="btn btn-lg btn-primary"
                                        [routerLink]="['/inclusionCriteria' , aProtocol.protocolId, trial.trialId]"
                                        role="button">Inclusion&nbsp;Criteria</a> 
                                     -->
                                     Exclusion
                                    </td>
                                
                            <td>
                                <button class="btn btn-lg btn-primary" 
                            role="button" (click)="deleteCriteria(criteria.id)">Delete</button>
                            </td>
                            <td>

                            </td>

                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <ngx-spinner type="pacman">
    <p>Loading...</p></ngx-spinner> 
</div>