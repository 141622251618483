<h2>Sponsor Dashboard</h2>
<!--<div>
    <a  class="btn btn-lg btn-primary me-4" routerLink="/sponsor/diseasearea" role="button">Disease Area
    &raquo;</a>
    <a  class="btn btn-lg btn-primary me-4" routerLink="/sponsor/analytics/" role="button">Analytics
        &raquo;</a>

        <a  class="btn btn-lg btn-primary me-4" routerLink="/sponsor/aggregate/" role="button">Aggregate Analytics
            &raquo;</a>
        
        <a  class="btn btn-lg btn-primary me-4" routerLink="/PISetup/" role="button">Principal PI&raquo;</a>
    <p></p>
        <a  class="btn btn-lg btn-primary me-4" routerLink="/registerPi/" role="button">Register PI
            &raquo;</a>
        <a  class="btn btn-lg btn-primary me-4" routerLink="/siteSetup/" role="button">Site Setup
            &raquo;</a>
            <a  class="btn btn-lg btn-primary me-4" routerLink="/drugs/" role="button">Material Management
                &raquo;</a>
                <a  class="btn btn-lg btn-primary me-4" routerLink="/sponsor/allirbs" role="button">IRBs
                    &raquo;</a>
                    <a  class="btn btn-lg btn-primary me-4" routerLink="/sponsor/sponsorparameters" role="button">Sponsor Parameters
                    &raquo;</a>
     </div>-->
     
    <div></div>
                
   
      
      <div class="circle-container">
        <a class="btn btn-lg btn-primary me-4 circle-item" routerLink="/sponsor/diseasearea" role="button">Disease Area »</a>
        <a class="btn btn-lg btn-primary me-4 circle-item" routerLink="/sponsor/analytics/" role="button">Analytics »</a>
        <a class="btn btn-lg btn-primary me-4 circle-item" routerLink="/sponsor/aggregate/" role="button">Aggregate Analytics »</a>
        <a class="btn btn-lg btn-primary me-4 circle-item" routerLink="/PISetup/" role="button">Principal PI »</a>
        <a class="btn btn-lg btn-primary me-4 circle-item" routerLink="/registerPi/" role="button">Register PI »</a>
        <a class="btn btn-lg btn-primary me-4 circle-item" routerLink="/siteSetup/" role="button">Site Setup »</a>
        <a class="btn btn-lg btn-primary me-4 circle-item" routerLink="/drugs/" role="button">Material Management »</a>
        <a class="btn btn-lg btn-primary me-4 circle-item" routerLink="/sponsor/allirbs" role="button">IRBs »</a>
        <a class="btn btn-lg btn-primary me-4 circle-item" routerLink="/parameters/view/" role="button">Sponsor Parameters »</a>
        <!-- <a class="btn btn-lg btn-primary me-4 circle-item" routerLink="/sponsor/parameterssetup" role="button">Sponsor Parameters »</a> -->

      </div>
      
      
  
