<div class="row mb-1 justify-content-center">
    <div>
        <button type="button" class="btn btn-info" (click)="isCollapsed = !isCollapsed" 
            (mouseover)="mouseOverSetUp()" [attr.aria-expanded]="!isCollapsed" aria-controls="addSite">
            <h3>PI information</h3>
        </button>

    </div>
    <div class="col-lg-12 text-center collapse" id='addSite' [ngbCollapse]="isCollapsed">

        <div class="card">
            <div class="card-body">
                <form *ngIf="!busy" #f="ngForm" (ngSubmit)="onSubmit()">

                    <div class="row mb-1">
                        <div class="col-lg-2 col-xs-12">
                            <div class="form-group">
                                <label for="siteName">Institution Name</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-xs-12">
                            <div class="form-group">
                                <input type="text" id="siteName" name="siteName" 
                                [(ngModel)]="pi.siteName"
                                    class="form-control" placeholder="Institution Name" 
                                    required autoFocus>
                            </div>
                        </div>
                    </div>


                    <div class="row mb-1">
                        <div class="col-lg-2 col-xs-12">
                            <div class="form-group">
                                <label for="geoLocation">Address</label>
                            </div>
                        </div>

                        <div class="col-lg-10 col-xs-12">
                            <div class="form-group">
                                <input type="text" id="address" name="address" 
                                    class="form-control" placeholder="Geo Location, address of the institution"
                                [(ngModel)]="pi.address"
                                    required>
                            </div>
                        </div>
                    </div>

                    <div class="row mb-1">
                        <div class="col-lg-2 col-xs-12">
                            <div class="form-group">
                                <label for="piName">PI </label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-xs-12">
                            <div class="form-group">
                                <input type="text" id="piName" name="piName"
                                [(ngModel)]="pi.piName"
                                    class="form-control" placeholder="Name" required>
                            </div>
                        </div>

                        <div class="col-lg-2 col-xs-12">
                            <div class="form-group">
                                <label for="piEmail"> </label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-xs-12">
                            <div class="form-group">
                                <input type="email" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" id="piEmail"
                                    name="piEmail"  class="form-control" placeholder="Email"
                                    [(ngModel)]="pi.piEmail" (change)="checkEmail()"
                                    required>
                            </div>
                        </div>
                    </div>

                    <div class="row mb-1">
                        <div class="col-lg-2 col-xs-12">
                            <div class="form-group">
                                <label for="msl">MSL</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-xs-12">
                            <div class="form-group">
                                <input type="text" id="msl" name="msl" class="form-control"
                                [(ngModel)]="pi.msl"
                                    placeholder="Name" required>
                            </div>
                        </div>
                        <div class="col-lg-2 col-xs-12">
                            <div class="form-group">
                                <label for="mslEmail"></label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-xs-12">
                            <div class="form-group">
                                <input type="email" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" id="mslEmail"
                                    name="mslEmail" class="form-control" placeholder="Email"
                                    [(ngModel)]="pi.mslEmail"
                                    required>
                            </div>
                        </div>

                    </div>
                    <div *ngIf="!emailChecked" class="red">
                        {{errorMessage}} : {{pi.piEmail}}
                    </div>
                    
                    <div class="row mb-1">

                        <div class="col-lg-2 col-xs-12">
                        </div>
                        <div class="col-lg-4 col-xs-12">
                            <div class="form-group">
                                <a class="btn btn-lg btn-primary btn-block" [routerLink]="['/']"
                                    role="button">Cancel</a>
                            </div>
                        </div>

                        <div class="col-lg-2 col-xs-12">
                        </div>
                        <div class="col-lg-4 col-xs-12">
                            <div class="form-group">
                                <button class="btn btn-lg btn-primary btn-block" type="submit"
                                    [disabled]="!f.form.valid || !emailChecked">
                                    Set Up PI
                                </button>
                            </div>
                        </div>
                    </div>



                    <div *ngIf="error" class="alert alert-danger mt-3" role="alert">
                        <strong>Oops!</strong> {{error}}
                    </div>
                </form>
            </div>
        </div>
<div *ngIf="piList!=undefined && piList!=null">

    <div class="row mb-1 justify-content-center">
        <div class="col-lg-12 text-center">
            <div class="col-md-12">
                <h3>Principal PI List</h3>
                <table class="table small">
                    <thead>

                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">PI Name</th>
                            <th scope="col">PI email</th>
                            <th scope="col">Institution Name</th>
                            <th scope="col">Address</th>
                            <th scope="col">Modified by</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let aPi of piList; let i = index" [attr.data-index]="i">
                           
                          <!-- <th scope="row">{{i+1}}</th> -->
                       <div class="col-lg-1">
                            <th for>{{i+1}}</th>
        
                        </div>
                            <td>{{aPi.piName}}</td>   
                            <td>{{aPi.piEmail}}</td>
                            <td>{{aPi.siteName}}</td>
                            <td>{{aPi.address}}</td>  
                            <td>{{aPi.modifiedBy}}</td> 
                       
                        </tr>
                    </tbody>
                        
                </table>
    
</div>
    </div>
    <ngx-spinner type="pacman">
    <p>Loading...</p></ngx-spinner>
</div>