export class Parameter 
{ 
    public count: number;
    public componentInternal: string; 
    public durationInternal: number; 
    public unitInternal: string; 
    public componentExternal: string; 
    public durationExternal: number; 
    public unitExternal: string; 
    public afterSiteActivation:boolean=false;
  } 
  export interface IParameter 
{ 
    id:number;
    count: number;
    componentInternal: string; 
    durationInternal: number; 
    unitInternal: string; 
    componentExternal: string; 
    durationExternal: number; 
    unitExternal: string; 
    afterSiteActivation:boolean;
  }
  export interface IParameterAudit 
{ 
    id:number;
    parameterId:number;
    count: number;
    componentInternal: string; 
    durationInternal: number; 
    unitInternal: string; 
    componentExternal: string; 
    durationExternal: number; 
    unitExternal: string; 
    modifiedBy:string;
    dateModified:Date;
    afterSiteActivation:boolean;
  }