import { Component, OnInit } from '@angular/core';

import {SiteSetup, ISiteSetup} from 'src/app/shared/interfaces/siteSetup';
import { IStudyType } from 'src/app/shared/interfaces/studyTypes';
import {IConceptEvaluation} from 'src/app/shared/interfaces/conceptEvaluation';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators'
import { AuthService } from '../../core/authentication/auth.service';
import { IssAdministrationService } from '../iss-administration.service';
import { Router, CanActivate, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-concept-approval',
  templateUrl: './concept-approval.component.html',
  styleUrls: ['../styles.sass','./concept-approval.component.sass']
})
export class ConceptApprovalComponent implements OnInit {
  conceptId:number;
  site:ISiteSetup ;
  iconMarkerURL:ISiteSetup;
  pipe:DatePipe;
  conceptEvaluations:IConceptEvaluation[];
  
  validEvaluationDate:boolean=false;
  evaluationDateErrorMessage:string='';
  error:boolean=false;
  busy:boolean=false;
  auditBusy:boolean=false;
  historyOnly:boolean=false;
  LastEvaluationDate:Date;
  LastStatus:string;
  

  constructor(private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private administrationService: IssAdministrationService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    
    this.conceptId = +this.route.snapshot.paramMap.get('conceptId');
    console.log(this.conceptId);
   if(this.route.snapshot.url[0].path=='conceptEvaluationAudit')
   {
     this.historyOnly=true;
   }
    console.log(`url= ${this.route.snapshot.url}`);
    // get concept
    //get site for this concept
    this.getConceptEvaluationAudit();
    this.spinner.show();
    this.busy = true;
    this.administrationService.getIssSite(this.authService.authorizationHeaderValue, this.conceptId)
      .pipe(finalize(() => {
        this.busy = false;
        this.hideSpinner();
      })).subscribe(
        result => {
          this.site = result;
          this.LastEvaluationDate=this.site.conceptEvaluationDate;
          this.LastStatus=this.site.conceptEvaluationStatus;
          
          console.log(this.site);
        });
  }

  hideSpinner=():void =>{
    if(this.busy || this.auditBusy)
    {
      return; 
    }
    this.spinner.hide();
  }
  getConceptEvaluationAudit=():void=>{
    //conceptId initialized at the beginning
    this.spinner.show();
    this.auditBusy=true;
    this.administrationService.getConceptEvaluations(this.authService.authorizationHeaderValue, this.conceptId)
    .pipe(finalize(() => {
      this.auditBusy = false;
      this.hideSpinner();
    })).subscribe(
      result => {
        this.conceptEvaluations = result;
        console.log(this.conceptEvaluations);
      });
  }
  onSubmit= ():void=>{
    debugger;
    this.administrationService.saveConceptEvaluation(this.authService.authorizationHeaderValue, this.site)
    .pipe(finalize(() => {
        this.busy = false;
        this.hideSpinner();
    })).subscribe(
      result => {
        console.log(result);
        //this.getConceptEvaluationAudit();
        
        this.router.navigate(['/siteSetup']);
      });
} 

evaluationDate= ():void =>{
  this.pipe = new DatePipe('en-US');
  console.log(`Evaluation Date ${this.site.conceptEvaluationDate} Submission Date:${this.site.conceptSubmissionDate}`);
  if (this.site.conceptEvaluationDate< this.site.conceptSubmissionDate)
  {
    this.validEvaluationDate=false;
    this.evaluationDateErrorMessage=`Invalid Evaluation Date. Must be after Submission Date which is ${ this.pipe.transform(this.site.conceptSubmissionDate, 'd MMMM y')}`;
  }
  else{
    this.validEvaluationDate=true;
    this.evaluationDateErrorMessage='';
  }
}
}
