import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule }   from '../shared/shared.module';
import { CompanyComponent } from './company/company.component';
import {AdministrationService} from './administration.service'
import { OmnipotentRoutingModule } from './omnipotent.routing-modules';
import { AdminHomeComponent } from './admin-home/admin-home.component';
import { OmnipotentDashboardComponent } from './omnipotent-dashboard/omnipotent-dashboard.component';
import { OmnipotentOmpanyTrialsComponent } from './omnipotent-ompany-trials/omnipotent-ompany-trials.component';
import { OmnipotentCompanyTrialsComponent } from './omnipotent-company-trials/omnipotent-company-trials.component';
import { NewCompanyComponent } from './new-company/new-company.component';
import { UpdateCompanyComponent } from './update-company/update-company.component';
import { FormsModule }   from '@angular/forms';
import { NewTrialComponent } from './new-trial/new-trial.component';
import { UpdateTrialComponent } from './update-trial/update-trial.component';
import { AreaComponent } from './area/area.component';
import { NewAreaComponent } from './new-area/new-area.component';
import { UpdateAreaComponent } from './update-area/update-area.component';
import { SiteComponent } from './site/site.component';
import { TrialSiteComponent } from './trial-site/trial-site.component';
import { NewSiteComponent } from './new-site/new-site.component';
import { UpdateSiteComponent } from './update-site/update-site.component';
import { NewTrialSiteComponent } from './new-trial-site/new-trial-site.component';
import { UpdateTrialSiteComponent } from './update-trial-site/update-trial-site.component';
import { TrialInclusionCriteriaComponent } from './trial-inclusion-criteria/trial-inclusion-criteria.component';
import { TrialProtocolComponent } from './trial-protocol/trial-protocol.component';
import { AreaProtocolComponent } from './area-protocol/area-protocol.component';
import { AreaProtocolInclusionCriteriaComponent } from './area-protocol-inclusion-criteria/area-protocol-inclusion-criteria.component';

@NgModule({
  declarations: [CompanyComponent, AdminHomeComponent, OmnipotentDashboardComponent, OmnipotentOmpanyTrialsComponent, OmnipotentCompanyTrialsComponent, NewCompanyComponent, UpdateCompanyComponent, NewTrialComponent, UpdateTrialComponent, AreaComponent, NewAreaComponent, UpdateAreaComponent, SiteComponent, TrialSiteComponent, NewSiteComponent, UpdateSiteComponent, NewTrialSiteComponent, UpdateTrialSiteComponent, TrialInclusionCriteriaComponent, TrialProtocolComponent, AreaProtocolComponent, AreaProtocolInclusionCriteriaComponent],
  providers: [ AdministrationService],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    OmnipotentRoutingModule
  ]
})
export class OmnipotentModule { }
