export class AdverseEvent
{
    /// on report send email to PI
    public id:number;
    public issSiteId:number;
    public adverseEventName:string;
    public seriousAdverseEvent:boolean;
    public adverseEventPriority: number;
    public reason:string;
    public createdBy:string;
    public dateCreated:Date;
    public modifiedBy: string;
    public dateModified: Date;
    public deleted:boolean;
}
export interface IAdverseEvents
{
    id:number;
    issSiteId:number;
    adverseEventName:string;
    seriousAdverseEvent:boolean;
    adverseEventPriority: number;
    createdBy:string;
    dateCreated:Date;
    modifiedBy: string;
    dateModified: Date;
    deleted:boolean;
}
export interface IAdverseEventAudits
{
    id:number;
    adverseEventName:string;
    seriousAdverseEvent:boolean;
    adverseEventPriority: number;
    createdBy:string;
    dateCreated:Date;
}
