<h3>Material Management</h3>
<div class="col-lg-12" style="background-color: #045975; margin-bottom: 20px;"> <!-- Color band -->
    &nbsp; 
</div>
<div>
<div class="row">
    <div class="col-lg-3 m-3 text-center" style="font-weight: bold">Study Name</div>
    <div class="col-lg-3 m-3 text-center" style="font-weight: bold">Irb Site</div>
    <div class="col-lg-3 m-3 text-center" style="font-weight: bold">View</div>
    <div class="col-lg-1 m-3 text-center" style="font-weight: bold">Add</div>
</div>
<!--<div class="row" *ngFor="let aIrb of allIrbs">
        
    <div class="col-lg-4 m-3"  *ngIf="aIrb.outcome">{{aIrb.site.studyName}}</div>
    <div class="col-lg-4 m-3"  style="color:#041d99" *ngIf="aIrb.outcome"><b>{{aIrb.siteName}}</b></div>
    <div class="col-lg-1 m-3"  *ngIf="aIrb.outcome"><a  class="btn btn-lg btn-primary me-4" routerLink="/viewStocks/{{aIrb.id}}" role="button">View&raquo;</a></div>
    <div class="col-lg-1 m-3"  *ngIf="aIrb.outcome"><a  class="btn btn-lg btn-primary me-4" routerLink="/addtoStocks/{{aIrb.id}}" role="button">Add&raquo;</a></div>
 
</div>-->

<div class="row" *ngFor="let aIrb of presenceOfMaterialAtAllSites">
    <div class="col-lg-3 m-3 text-center"  *ngIf="aIrb[1]">{{aIrb[1]}}</div>
    <div class="col-lg-3 m-3 text-center"  style="color:#041d99" *ngIf="aIrb[3]"><b>{{aIrb[2]}}</b></div>
    <div class="col-lg-3 m-3 text-center">
        <a *ngIf="aIrb[3] && aIrb[4] !=0" class="btn btn-lg btn-primary me-4" routerLink="/viewStocks/{{aIrb[0]}}" role="button" >View - {{aIrb[4]}} Batche(s) &raquo;</a>
    </div>
    <div class="col-lg-1 m-3 text-center">
        <a *ngIf="aIrb[3]" class="btn btn-lg btn-primary me-4" routerLink="/addtoStocks/{{aIrb[0]}}" role="button">Add&raquo;</a>
    </div>
</div>


<ngx-spinner type="pacman">
    <p>Loading...</p>
</ngx-spinner>
</div>