<div *ngIf="!busy">
<h2>View Parameters</h2>

<div  class="col-lg-12 text-center" style="background-color: #034289; color: white; height: 50px;"></div>
<div class = "row mb-20"></div>

<!--.................................................-->

<div fxLayout="row" fxLayoutAlign="start center">

  <p-card 
  
   [style] = "{ width: '100%' }"
   class = "card-style">

   <ng-template pTemplate="header">
      
       <p-table       
                  [value] = "parametersForDisplay"
                  styleClass = "p-datatable-striped" 
                  [tableStyle]="{ 'min-width': '20rem' }" 
                  [scrollable]="true"
                  scrollHeight="350px" 
                  >

                  <ng-template pTemplate="caption">
                      <div class="flex" style="display: flex;">
                        <div style="flex: 1; border-right: 2px solid whitesmoke; display: flex; justify-content: center; align-items: center;">
                          For Internal Operation Use
                        </div>
                        <div style="flex: 1; border-right: 2px solid whitesmoke; display: flex; justify-content: flex-end; align-items: center;">
                          For External Guidance
                          <span class="p-input-icon-right ml-auto"></span>
                        </div>
                        <div style="flex: 1; display: flex; justify-content: center; align-items: center;">
                          For Updates
                          <span class="p-input-icon-right ml-auto"></span>
                        </div>
                        
                      </div>
                  </ng-template>
                    
                                   
                  <ng-template pTemplate="header">

                          <tr  >

                                 <th>#</th>
                                 <th>Component / Stage</th>
                                 <th style="border-right: 2px solid #000000;">Duration</th>
                                 <th></th>
                                 <th>Component / Stage</th>
                                 <th>Duration</th>
                                 <th>----</th>
                                 <th>Links to Update</th>
                                 
                                              
                          </tr>
                     
                  </ng-template>
                                
                                  
                                    
                  <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">

                          <tr [ngClass]="{'color-banner': rowIndex > 4}">
                              <td class="tbody"> <a routerLink="/parameters/audit/{{rowData.id}}" title="Click to View Audit">{{rowData.count}}</a></td>
                              <td class="tbody"> {{rowData.componentInternal}}</td>
                              <td class="tbody" style="border-right: 2px solid black;"> {{rowData.durationInternal}} &nbsp;{{rowData.unitInternal}}</td>
                              <td class="tbody"> </td>
                              <td class="tbody"> {{rowData.componentExternal}}</td>
                              <td class="tbody"> {{rowData.durationExternal}} &nbsp;{{rowData.unitInternal}}</td>
                              <td class="tbody"> ----</td>
                              <td class="tbody"><a class="btn btn-lg btn-primary" routerLink="/parameters/update/{{rowData.id}}">Modify Parameter</a></td>




                          </tr>            
                                       
                        

                  </ng-template>  

                  

                                
       </p-table>                            
   


</ng-template> 
  </p-card>  

 

  
</div> 

<!--...................................................-->
<!--<div class="row mb-20">

    <div class="col-lg-1">
    <b>Count<br>Click for Audit</b>
    <b>Count</b>
    </div>
    
    <div class="col-lg-2">
      <b>Component Internal</b>
    </div>
    <div class="col-lg-1">
      <b>Duration</b>
    </div>
    <div class="col-lg-2">
      <b>Component External</b>
    </div>
    <div class="col-lg-1">
      <b>Duration</b>
    </div>

    <div class="col-lg-1">
      <b>----------------</b>
    </div>
    
</div>

<div class="row mb-20" *ngFor="let aParameter of allParameters; let i = index">
<div class="col-lg-1">

<a routerLink="/parameters/audit/{{aParameter.id}}" title="Click to View Audit">{{aParameter.count}}</a>
</div>

<div class="col-lg-2">
  {{aParameter.componentInternal}}
</div>
<div class="col-lg-1" >
  {{aParameter.durationInternal}}&nbsp;{{aParameter.unitInternal}}
</div>

<div class="col-lg-2 m-1">
  {{aParameter.componentExternal}}
</div>
<div class="col-lg-1 mb-1">
  {{aParameter.durationExternal}}&nbsp;{{aParameter.unitInternal}}
</div>

<div class="col-lg-4">
  <a class="btn btn-lg btn-primary" routerLink="/parameters/update/{{aParameter.id}}">Modify Parameter</a>
</div>
</div>-->

<div class="row mb-50">

</div>
<div class="row ">
<div class="col-lg-2">
  <a class="btn btn-lg btn-primary" routerLink="/sponsor/dashboard/" role="button">Dashboard&raquo;</a></div>
<div class="col-lg-7 m-1"></div>
<div class="col-lg-2">
  <a class="btn btn-lg btn-primary" routerLink="/parameters/create" role="button">Create Parameter&raquo;</a></div>
</div>
<ngx-spinner type="pacman">
  <h2>Loading...</h2></ngx-spinner>
</div>