<div class="row mb-1 justify-content-center">
  <div>
    <button type="button" class="btn btn-info" (click)="isCollapsed = !isCollapsed"
      ngbTooltip="{{adverseEffectTooltip}}" (mouseover)="mouseOverSetUp()" [attr.aria-expanded]="!isCollapsed"
      aria-controls="addAdverseEffect">
      <h3>Adverse Events</h3>
    </button>

  </div>
  <div class="col-lg-12 text-center collapse" id='addAdverseEffect' [ngbCollapse]="isCollapsed">

    <div class="card">
      <div class="card-body">
        <form *ngIf="!busy" #f="ngForm" (ngSubmit)="onSubmit()">

          <div class="row mb-1">
            <div class="col-lg-1 col-xs-12">
              <div class="form-group">
                <label for="adverseEventName">Adverse Event</label>
              </div>
            </div>

            <div class="col-lg-3 col-xs-12">
              <div class="form-group">
                <input type="text" id="adverseEventName" name="adverseEventName"
                  [(ngModel)]="adverseEvent.adverseEventName" class="form-control" placeholder="Adverse Event Name"
                  required autoFocus>
              </div>
            </div>


            <div class="col-lg-1 col-xs-12">
              <div class="form-group">
                <label for="adverseEventPriority">Priority</label>
              </div>
            </div>

            <div class="col-lg-3 col-xs-12">
              <div class="form-group">
                <input type="number" id="adverseEventPriority" name="adverseEventPriority"
                  [(ngModel)]="adverseEvent.adverseEventPriority" class="form-control"
                  placeholder="Adverse Event Priority (Order by PI According to Protocol)" required>
              </div>
            </div>

            <div class="col-lg-2 col-xs-12">
              <div class="form-group">
                <label for="seriousAdverseEvent">Serious (Check)</label>
              </div>
            </div>

            <div class="col-lg-2 col-xs-12">
              <div class="form-group">
                <input type="checkbox" id="seriousAdverseEvent" name="seriousAdverseEvent"
                  [(ngModel)]="adverseEvent.seriousAdverseEvent" class="form-control"
                  placeholder="Adverse Event Seriousness">
              </div>
            </div>
          </div>
          <div class="row mb-1" *ngIf="!submitNew">
            <div class="col-lg-1 col-xs-12">
              <label for="reason">Reason</label>
            </div>
            <div class="col-lg-11 col-xs-12">
              <div class="form-group">
                <input type="text" id="reason" name="reason" [(ngModel)]="adverseEvent.reason" class="form-control"
                  placeholder="Please provide rationale for editing this adverse event" required>

              </div>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-lg-1 col-xs-12">
            </div>
            <div class="col-lg-3 col-xs-12">
              <div class="form-group">
                <a class="btn btn-lg btn-primary btn-block" [routerLink]="['/']" role="button">Cancel</a>
              </div>
            </div>
            <div class="col-lg-6 col-xs-12">
            </div>
            <div class="col-lg-2 col-xs-12">
              <div class="form-group">
                <button class="btn btn-lg btn-primary btn-block" type="submit" [disabled]="!f.form.valid">
                  {{submitText}}
                </button>
              </div>
            </div>
          </div>
          <div *ngIf="error" class="alert alert-danger mt-3" role="alert">
            <strong>Oops!</strong> {{error}}
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div class="row mb-1 justify-content-center">
  <div class="col-lg-12 text-center">
    <div *ngIf="!busy && !auditVisible" class="col-md-12">
      <h3>List of Adverse Events</h3>
      <table class="table small">
        <tr>

          <p>Click an event below (in the Severity column) to modify its properties and provide justifications, if needed<br>
             Your id will be recorded against the modification</p>
        </tr>
      </table>

      <table class="table">
        <thead>

          <tr>
            <th>num</th>
            <th scope="col"> Name</th>
            <th scope="col" >Priority</th>
            <th scope="col" title="Click here to access the FDA refrence document"><a href="https://www.fda.gov/safety/reporting-serious-problems-fda/what-serious-adverse-event"
              target="blank" rel="noopener noreferrer"> Seriousness</a> </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let aEvent of adverseEventList; let i = index" [attr.data-index]="i" (click)="select(aEvent.id)">
            <th scope="row" style="cursor: pointer;" title="Click here to modify">
              {{i+1}}

            </th>
            <td>{{aEvent.adverseEventName}}</td>
            <td>{{aEvent.adverseEventPriority}}</td>
            <td *ngIf="aEvent.seriousAdverseEvent" style="color:white;font-weight:bold" bgcolor="red">Serious</td>
            <td *ngIf="!aEvent.seriousAdverseEvent">Not Considered As Serious</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div *ngIf="auditVisible" class="col-md-12">

        <h3>Audit</h3>
        <table class="table small">
          <thead>
  
            <tr>
              <th>num</th>
              <th scope="col"  > Name</th>
              <th scope="col">Priority</th>
              <th scope="col">Seriousness</th>
              <th scope="col">Amended By</th>
              <th scope="col">Date Modified</th>
              <th>Reason</th>
              </tr>
          </thead>
          <tbody>
            <tr *ngFor="let aAudit of adverseEventAuditList; let j = index" [attr.data-index]="j"  > 
              <th scope="row" >
                 
                {{j+1}}
              </th>
              <td >{{aAudit.adverseEventName}}</td>
              <td>{{aAudit.adverseEventPriority}}</td>
              <td *ngIf="aAudit.seriousAdverseEvent" style="color:white;font-weight:bold" bgcolor="red">Serious</td>
              <td *ngIf="!aAudit.seriousAdverseEvent">Not Considered As Serious</td>
              <td>{{aAudit.createdBy}}</td>
              <td>{{aAudit.dateCreated|date:"d MMM yyyy H:mm:ss"}}</td>
              <td>{{aAudit.reason}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    <ngx-spinner type="pacman">
    <p>Loading...</p></ngx-spinner>
  </div>
</div>