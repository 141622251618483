import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { IParameter, Parameter } from 'src/app/shared/interfaces/parameter';
import { IssAdministrationService } from '../iss-administration.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-update-parameter',
  templateUrl: './update-parameter.component.html',
  styleUrls: ['./update-parameter.component.sass']
})
export class UpdateParameterComponent {
  submitText:string="Update";
  busy:boolean=false;
  setupParameter:IParameter;
  parameterId:number;
constructor(private route: ActivatedRoute,
  private authService: AuthService,
  private router: Router,
  private administrationService: IssAdministrationService,
  private spinner: NgxSpinnerService) { }


  ngOnInit(): void {
this.busy=true;
this.parameterId = +this.route.snapshot.paramMap.get('parameterid');

this.spinner.show();
this.administrationService.getSingleSetupParameter(this.authService.authorizationHeaderValue, this.parameterId)
.pipe(finalize(() => {
  this.busy = false;
  this.spinner.hide();
})).subscribe(
result => {
  console.log(result);
  this.setupParameter=result[0];
});

  }

  
  onSubmit= ():void=>{
    this.busy=true;
    this.spinner.show();
    this.administrationService.updateParameter(this.authService.authorizationHeaderValue,this.setupParameter)
    .pipe(finalize(() => {
      this.busy = false;
      this.spinner.hide();
  })).subscribe(
    result => {
      console.log(result);
      //this.getConceptEvaluationAudit();
      
      this.router.navigate(['/parameters/view']);
    });
  }
}
