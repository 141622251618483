
import { Component, OnInit } from '@angular/core';
import { SiteSetup, ISiteSetup} from 'src/app/shared/interfaces/siteSetup';
import { IStudyType } from 'src/app/shared/interfaces/studyTypes';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators'
import { AuthService } from '../../core/authentication/auth.service';
import { PiAdministrationService } from '../pi-administration.service';
import { IssAdministrationService } from '../../investigator-initiated-study/iss-administration.service';
import { Router, CanActivate, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { IConceptEvaluation } from 'src/app/shared/interfaces/conceptEvaluation';
import { IContractNegociation } from 'src/app/shared/interfaces/contractNegociation';
import { IProtocolEvaluation } from 'src/app/shared/interfaces/protocolEvaluation';

@Component({
  selector: 'app-hospitals',
  templateUrl: './hospitals.component.html',
  styleUrls: ['../styles.sass','./hospitals.component.sass']
})

export class HospitalsComponent implements OnInit {

  site:SiteSetup=new SiteSetup();
  buttonBaseClass:string="btn btn-lg form-control ";
  studyTypeId:number;
  error:boolean=false;
  busy:boolean=true;
  isCollapsed:boolean=true;
  setUpTooltip:string="Click to set up a new site";
  studyTypes:IStudyType[];
  siteSetups:ISiteSetup[];
  siteSetupsFiltered:ISiteSetup[];
  siteSetupsFilteredByStatus:ISiteSetup[];
  studySetupsCount:number;
  siteSetupsFilteredCount:number;
  siteSetupsFilteredByStatusCount:number;
  statusString:string;
  typeString:string;
  statusNumber:string="0";
  typeNumber:string="0";
  conceptEvaluationStatus:IConceptEvaluation[];
  protocolEvaluationStatus:IProtocolEvaluation[];
  contractEvaluationStatus:IContractNegociation[];
  conceptEvaluationDate:Date;
  protocolEvaluationDate:Date;
  contratcEvaluationDate:Date;
   constructor(private route: ActivatedRoute,
     private authService: AuthService,
     private router: Router,
     private administrationService: IssAdministrationService,
     private piAdministrationService: PiAdministrationService,
     private spinner: NgxSpinnerService) {
     this.busy = true;
     this.spinner.show();
     this.administrationService.getStudyTypes(this.authService.authorizationHeaderValue)
        .pipe(finalize(() => {
            this.busy = false;
            this.hideSpinner();
        })).subscribe(
          result => {
            this.studyTypes = result;
            console.log(this.studyTypes);
          }); 
        
        }

    ngOnInit(): void { 
      this.getSiteSetups();
    }
    getSiteSetups():void{
      this.spinner.show();
      this.piAdministrationService.getPiSites(this.authService.authorizationHeaderValue)
      .pipe(finalize(() => {
          this.busy = false;
          this.hideSpinner();
      })).subscribe(
        result => {
          this.siteSetups=result;
          this.studySetupsCount=this.siteSetups.length; // counting the number of studies in the list 
          this.siteSetupsFiltered=result;
          this.siteSetupsFilteredCount=this.siteSetupsFiltered.length; // Counting filtering result
          console.log("All Studies");
          console.log(result);
          console.log(this.siteSetups);
        });
    }
    showSpinner=():void=>{
      this.spinner.show();
    }
    hideSpinner=():void =>{
      this.spinner.hide();
    }

//Filter Implementatiuon (place holder)
filterSites =(): void =>{
  this.filterByStatus();
  this.filterByType();
  
  };

filterByStatus= ():void=>{
  var myStatusString=[" ", "Concept Evaluation", "Protocol Submission", "Protocol Evaluation", "Re-evaluation Decision","Contract Negotiation", "Documents Check", "Rejected (Concept)", "Rejected (Protocol)"]

  let index=Number(this.statusNumber);
  this.statusString=myStatusString[index];
  console.log(`${this.statusString} number= ${index}`);
  
  if(index==0){
    this.siteSetupsFilteredByStatus=this.siteSetups
    this.siteSetupsFilteredByStatusCount=this.siteSetupsFilteredByStatus.length; // Counting filtering result
  }
  else{
  
    this.siteSetupsFilteredByStatus=this.siteSetups.filter(aSite=>
    this.EvaluationStatus(aSite) == this.statusString ); // Think of adding another condition here
    
    this.siteSetupsFilteredByStatusCount=this.siteSetupsFilteredByStatus.length; // Counting filtering result
  }
  
}

EvaluationStatus = (aSite:ISiteSetup): string => {
  if (aSite.contractEvaluationStatus== 'Approved'  ){
      
    return 'Documents Check';
  }
 
  if (aSite.contractEvaluationStatus== 'Conditional approval pending revisions'  ){
  
    return 'Contract Negotiation';
 }

 if (aSite.protocolEvaluationStatus=='Approved' &&  aSite.contractEvaluationStatus!= 'NULL'){
 return 'Contract Negotiation';
 }

 if (aSite.conceptEvaluationStatus== 'Approved' && aSite.protocolSubmissionDate==null){
  return 'Protocol Submission';
}

 if (aSite.protocolEvaluationStatus!=null &&aSite.protocolEvaluationDate==null){
    return 'Protocol Submission' //Should ths be re-submission
  }
 
  if (aSite.conceptEvaluationStatus=='Approved' && aSite.protocolEvaluationStatus=='Pending Revision'){
    return 'Protocol Evaluation';
  }
  if (aSite.protocolSubmissionDate!=null && aSite.protocolEvaluationStatus==null){
    return 'Protocol Evaluation';
  }  

  if (aSite.protocolEvaluationStatus=='Conditional approval pending revisions'){
    return 'Re-evaluation Decision';
 }

 if (aSite.conceptEvaluationStatus== 'Pending additional information'){
    return 'Re-evaluation Decision';
}
 if (aSite.conceptEvaluationStatus==null){
 return 'Concept Evaluation';
}

if (aSite.protocolEvaluationStatus=='Rejected'){
  return 'Rejected (Protocol)'; 
}
  if (aSite.conceptEvaluationStatus=='Rejected'){
    return 'Rejected (Concept)';
}

    return '@#!';
}
  

//86 to 156 were inserted for filetring


    nextStepTitle=(aSite:SiteSetup):string=>{
      if (aSite.conceptEvaluationStatus==null)
      {
       return 'Concept Evaluation';  // AAA - Removed "Decision at the end of this text"
      }
      
      if (aSite.conceptEvaluationStatus=='Rejected')
      {
        return 'Rejected';
      }
      
      if (aSite.conceptEvaluationStatus=='Pending additional information')
      {
        return 'Re-evaluation Decision';
      }

      if (aSite.conceptEvaluationStatus=='Approved' && aSite.protocolSubmissionDate==null)
      {
        return 'Protocol Submission';
      }
   
      if (aSite.protocolSubmissionDate!=null && aSite.protocolEvaluationStatus==null){
        return 'Protocol Evaluation';
      }
     // if (aSite.protocolSubmissionDate!=null && aSite.protocolEvaluationStatus=='Conditional approval pending revisions'){
     //  return 'Protocol Evaluation';
    //}

      if ( aSite.protocolEvaluationStatus!='Approved' && aSite.protocolEvaluationStatus!='Rejected'){
        // pending => need to submit modifications
        return 'Protocol Submission'; 
      }

      if ( aSite.protocolEvaluationStatus=='Rejected'){
        return 'Protocol Rejected'; 
      }

      if ( aSite.protocolEvaluationStatus=='Approved' && aSite.contractEvaluationStatus!='Approved'){
        return 'Contract Negotiation';
      } 

      if ( aSite.contractEvaluationStatus!='Approved'){
        return 'Contract Negotiation';
      } 

      if ( aSite.contractEvaluationStatus=='Approved'){
        return 'Documents Check';
      }

      return '@#!';
    }
    
    //[string,number]
    
    nextStepLink=(aSite:SiteSetup):any=>{
      if (aSite.conceptEvaluationStatus==null)
      {
        return '.';
      }
      
      if (aSite.conceptEvaluationStatus=='Pending additional information')
      {
        return '.';
        //return ['/conceptApproval' , aSite.id];
      }
  
      if (aSite.conceptEvaluationStatus=='Rejected')
      {
        return '.';
      }
      
      if (aSite.conceptEvaluationStatus=='Approved' && aSite.protocolSubmissionDate==null)
      {
        return ['/protocolSubmission' , aSite.id];
      }
      
      if (aSite.protocolSubmissionDate!=null && aSite.protocolEvaluationStatus==null){
        //return ['/protocolApproval' , aSite.id];
        return '.';
      }

     // if (aSite.protocolSubmissionDate!=null && aSite.protocolEvaluationStatus=='Conditional approval pending revisions'){
        //return ['/protocolApproval' , aSite.id];
      //  return '.';
     // }
      
      if ( aSite.protocolEvaluationStatus!='Rejected' && aSite.protocolEvaluationStatus!='Approved')
      {
      return ['/protocolSubmission' , aSite.id];  
      }

      if ( aSite.protocolEvaluationStatus=='Rejected')
      {
        return ['/',0];
      }
      if ( aSite.protocolEvaluationStatus=='Approved' && aSite.contractEvaluationStatus!='Approved')
      {
        //return ['/negotiation' , aSite.id];
        return '.';
      }
      
      //if ( aSite.contractEvaluationStatus!='Approved')
      //{
        //return ['/negotiation' , aSite.id];
       // return '.';
      //}    
      if ( aSite.contractEvaluationStatus=='Approved'){
        return ['/document' , aSite.id];
      }
      //return ['/conceptApproval' , aSite.id];
      return '.';
    }
    //btn btn-lg btn-primary form-control

    nextStepClass=(aSite:SiteSetup):string=>{
      if (aSite.conceptEvaluationStatus==null)
      {
        return this.buttonBaseClass+'concept';
      }
      
      if (aSite.conceptEvaluationStatus=='Rejected')
      {
        return this.buttonBaseClass+'rejected';
      }
      
      if (aSite.conceptEvaluationStatus=='Pending additional information')
      {
        return this.buttonBaseClass+'concept';
      }
      if (aSite.conceptEvaluationStatus=='Approved' && aSite.protocolSubmissionDate==null)
      {
        return this.buttonBaseClass+'protocol';
      }
   
      //if (aSite.protocolSubmissionDate!=null && aSite.protocolEvaluationStatus=='Conditional approval pending revisions'){
        //return this.buttonBaseClass+'protocol';
      //}

      if (aSite.protocolSubmissionDate!=null && aSite.protocolEvaluationStatus==null){
        return this.buttonBaseClass+'protocol';
      }

      if ( aSite.protocolEvaluationStatus!='Approved' && aSite.protocolEvaluationStatus!='Rejected'){
        // pending => need to submit modifications
        return this.buttonBaseClass+'protocol';
      }
      if ( aSite.protocolEvaluationStatus=='Rejected'){
        return this.buttonBaseClass+'rejected';
      }
      if ( aSite.protocolEvaluationStatus=='Approved' && aSite.contractEvaluationStatus!='Approved'){
        return this.buttonBaseClass+'negotiation';
      }    
      if ( aSite.contractEvaluationStatus!='Approved'){
        return this.buttonBaseClass+'negotiation';
      }    
      if ( aSite.contractEvaluationStatus=='Approved'){
        return this.buttonBaseClass+'document';
      }
      return '@#!';
    }
    nextStepWait=(aSite:SiteSetup):boolean=>{
      if (this.nextStepLink(aSite)[0]=='/' && this.nextStepLink(aSite)[1]=='0')//  ==['/',0])
      {return true}
      else
      {
        return false;
      }

    }

 // Filter by Type of Study

 filterByType= ():void=>{
  var myTypeString=["", "Clinical", "Pre-Clinical", "Observational" ]
  
  let index=Number(this.typeNumber);
  this.typeString=myTypeString[index];

  console.log(`${this.typeString} number= ${this.typeNumber}`);

if(index == 0){
  this.siteSetupsFiltered=this.siteSetupsFilteredByStatus;
  this.siteSetupsFilteredCount=this.siteSetupsFilteredByStatus.length; // Counting filtering result
}
else{

  this.siteSetupsFiltered=this.siteSetupsFilteredByStatus.filter(aSite=>
    aSite.studyType.id == Number(this.typeNumber)); 

 /* this.siteSetupsFiltered=this.siteSetupsFilteredByStatus.filter(aSite=>
    this.studyTypeSelection(aSite) == this.typeNumber); */



console.log(this.siteSetupsFiltered);
    this.siteSetupsFilteredCount=this.siteSetupsFiltered.length; // Counting filtering result
}
console.log(this.siteSetupsFiltered);
}

studyTypeSelection = (aSite:SiteSetup): string =>{
  if (aSite.studyTypeId == 1){
    return '1'
  }

  if (aSite.studyTypeId == 2){
    return '2'
  }

  if (aSite.studyTypeId == 3){
    return '3'
  }

}  


//End Filter by Type of Study

    mouseOverSetUp=():void=>{
      if (this.isCollapsed){
        this.setUpTooltip="Click to set up a new study";
      }
      else{
        this.setUpTooltip="Click to hide fields and only view the list of studies";
      }
    }
    onSubmit= ():void=>{
      this.busy = true;
      
      this.piAdministrationService.siteSetup(this.authService.authorizationHeaderValue, this.site)
      .pipe(finalize(() => {
          this.busy = false;
          this.hideSpinner();
      })).subscribe(
        result => {
        this.getSiteSetups();
        console.log(result);
        this.site=new SiteSetup();
        //this.getSiteSetups();
        });
  } 
   
}
