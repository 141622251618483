import { Component, OnInit } from '@angular/core';

import {SiteSetup, ISiteSetup} from 'src/app/shared/interfaces/siteSetup';
import { IStudyType } from 'src/app/shared/interfaces/studyTypes';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators'
import { AuthService } from '../../core/authentication/auth.service';
import { IssAdministrationService } from '../iss-administration.service';
import { Router, CanActivate, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { IConceptEvaluation} from 'src/app/shared/interfaces/conceptEvaluation';
import { IProtocolEvaluation } from 'src/app/shared/interfaces/protocolEvaluation';
import { IContractNegociation } from 'src/app/shared/interfaces/contractNegociation';
@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.sass']
})
export class AuditComponent implements OnInit {
  conceptId:number;
  site:ISiteSetup ;
  conceptEvaluations:IConceptEvaluation[];
  protocolEvaluations:IProtocolEvaluation[];
  contractEvaluations:IContractNegociation[];
  error:boolean=false;
  busy:boolean=false;
  auditBusy:boolean=false;
  historyOnly:boolean=false;
  LastEvaluationDate:Date;
  lastContractEvaluation:Date;
  LastStatus:string;
  documentAudit: boolean=false;
  contractAudit: boolean=false;
  protocolAudit:  boolean=false;
  conceptAudit:  boolean=false;
  conceptAuditCount:number;
  protocolAuditCount:number;
  contractAuditCount:number;
  constructor(private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private administrationService: IssAdministrationService,
    private spinner: NgxSpinnerService) { }

    ngOnInit(): void {
    
      this.conceptId = +this.route.snapshot.paramMap.get('conceptId');
      console.log(this.conceptId);
     if(this.route.snapshot.url[0].path=='conceptEvaluationAudit')
     {
       this.historyOnly=true;
     }
      console.log(`url= ${this.route.snapshot.url}`);
      // get concept
      //get site for this concept
      this.getAudit();
      this.spinner.show();
      this.busy = true;
      this.administrationService.getIssSite(this.authService.authorizationHeaderValue, this.conceptId)
        .pipe(finalize(() => {
          this.busy = false;
          this.hideSpinner();
        })).subscribe(
          result => {
            this.site = result;
            this.LastEvaluationDate=this.site.conceptEvaluationDate;
            this.getAudit();
            this.LastStatus=this.site.conceptEvaluationStatus;
            console.log(this.site);
          });
    }
  
  

    hideSpinner=():void =>{
      if(this.busy)
      {
        return; 
      }
      this.spinner.hide();
    }
    getAudit=():void=>{
      //conceptId initialized at the beginning
      this.getConceptEvaluationAudit();
      this.getProtocolEvaluationAudit();
      this.getContractNegociationAudit();
      this.getDocumentationAudit();
    }
    getConceptEvaluationAudit=():void=>{
      //conceptId initialized at the beginning
  
      this.administrationService.getConceptEvaluations(this.authService.authorizationHeaderValue, this.conceptId)
      .pipe(finalize(() => {
      })).subscribe(
        result => {
          this.conceptEvaluations = result;
          this.conceptAuditCount=this.conceptEvaluations.length;
          console.log(this.conceptEvaluations);
        });
    }
    getProtocolEvaluationAudit=():void=>{
            //conceptId initialized at the beginning
        
            this.administrationService.getProtocolEvaluations(this.authService.authorizationHeaderValue, this.conceptId)
            .pipe(finalize(() => {
            })).subscribe(
              result => {
                this.protocolEvaluations = result;
                this.protocolAuditCount=this.protocolEvaluations.length;
                console.log(this.protocolEvaluations);
              });
    }
    getContractNegociationAudit=():void=>{
      //contractNegociations
      this.administrationService.getContractNegotiations(this.authService.authorizationHeaderValue, this.conceptId)
      .pipe(finalize(() => {
      })).subscribe(
        result => {
          this.contractEvaluations = result;
          this.contractAuditCount=this.contractEvaluations.length;
          console.log(this.contractEvaluations);
        });
    }
    getDocumentationAudit=():void=>{
    }
}
