import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators'
import { AuthService } from '../../core/authentication/auth.service';
import { AdministrationService } from '../administration.service';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Component({
  selector: 'app-area',
  templateUrl: './area.component.html',
  styleUrls: ['./area.component.sass']
})
export class AreaComponent implements OnInit {
  busy: boolean;
  companyId:number;
  areas:any;
  company:any;
  constructor(private route: ActivatedRoute,
   // private toastr: ToastrService,
    private authService: AuthService,private router:Router, private administrationService: AdministrationService, private spinner: NgxSpinnerService ) {
     
  }
  ngOnInit() {
    
    this.companyId = +this.route.snapshot.paramMap.get('companyId');
    console.log(`Company Id=${this.companyId}`)
    console.log("starting .... listing areas"); 

    this.busy = true;
// Get Company
this.administrationService.getCompany(this.authService.authorizationHeaderValue,this.companyId)
.pipe(finalize(() => {
 // this.spinner.hide();
})).subscribe(
result => {   
 // this.busy = false;      
  this.company = result;
  //this.toastr.info("company");
  //this.toastr.info(this.company.companyAddress,this.company.companyName);
});
//
    this.spinner.show();
    this.administrationService.fetchAreasData(this.authService.authorizationHeaderValue, this.companyId)
    .pipe(finalize(() => {
      this.spinner.hide();
      this.busy = false;
    })).subscribe(
    result => {         
      this.areas = result;
   });
  }

}
