<div class="row justify-content-center">
  <div class="col-lg-12 text-center" *ngIf="viewFormAddProtocol">

    <form #f="ngForm" (ngSubmit)="onSubmit()">
      <!--Site -->
      <div class="row mb-1">
        <div class="col-lg-3 col-xs-12">
          <div class="form-group">
            <label for="protocol">Protocol</label>
          </div>
        </div>

        <div class="col-lg-6 col-xs-12">
          <div class="form-group">
            <input type="text" id="protocolName" name="protocolName" [(ngModel)]="protocol.protocolName"
              class="form-control" placeholder="Protocol Name" required autoFocus>
          </div>
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-lg-3 col-xs-12">
          <div class="form-group">
            <label for="protocol">Protocol Date</label>
          </div>
        </div>

        <div class="col-lg-6 col-xs-12">
          <div class="form-group">
            <input type="date" id="protocolDate" name="protocolDate" [(ngModel)]="protocol.protocolDate"
              class="form-control" placeholder="Protocol Date" required>
          </div>
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-lg-3 col-xs-12">
          <div class="form-group">
            <label for="protocol">Protocol File</label>
          </div>
        </div>

        <div class="col-lg-6 col-xs-12">
          <div class="form-group">
            <input type="file" id="fileLocation" name="fileLocation" [(ngModel)]="protocol.protocolFile"
              class="form-control" placeholder="File Location" required>
          </div>
        </div>
      </div>
      <div class="row mb-1">

        <div class="col-lg-3 col-xs-12">
        </div>
        <div class="col-lg-6 col-xs-12">
          <div class="form-group">
            <a class="btn btn-lg btn-primary btn-block" [routerLink]="['/companyTrials' , companyId]"
              role="button">Cancel</a>
          </div>
        </div>
      </div>

      <div class="row mb-1">
        <div class="col-lg-3 col-xs-12">
        </div>
        <div class="col-lg-6 col-xs-12">
          <div class="form-group">
            <button class="btn btn-lg btn-primary btn-block" type="submit" [disabled]="!f.form.valid">
              {{createProtocolButtonText}}
            </button>
          </div>
        </div>
      </div>

      <ngx-spinner type="pacman">
    <p>Loading...</p></ngx-spinner>
      <div *ngIf="error" class="alert alert-danger mt-3" role="alert">
        <strong>Oops!</strong> {{error}}
      </div>


    </form>
  </div>
</div>
<div class="row " *ngIf="!viewFormAddProtocol && !busy && !protocolBusy">

  <div class="col-lg-3 col-xs-12">
  </div>
  <div class="col-lg-6 col-xs-12">
    <div class="form-group">
      <button class="btn btn-warning btn-lg btn-block" (click)="showFormAddProtocol()">
        Revise/Amend Protocol</button>
    </div>
  </div>
</div>
<div class="row " *ngIf="!viewFormAddProtocol && !busy && !protocolBusy">

  <div class="col-lg-3 col-xs-12">
  </div>
  <div class="col-lg-6 col-xs-12">
    <div class="form-group">
      <a class="btn btn-primary btn-lg btn-block" 
      [routerLink]="['/areaProtocols' ,  trial.trialId]"
                role="button">
        Area Specific Protocols</a>
    </div>
  </div>
</div>
<div class="row justify-content-center">
  <div class="col-lg-12 text-center">
    <div *ngIf="!busy && !protocolBusy" class="col-md-12">
      <h3>List of Protocols sent from the server for Trial: {{trial.trialName}}!</h3>
      <table class="table small">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Protocol Name</th>
            <th scope="col">Protocol Date</th>
            <th scope="col">Protocol File</th>
            <th scope="col">Created By</th>
            <th scope="col">Date Created</th>
            <th scope="col">Modified By</th>
            <th scope="col">Date Modified</th>
            <th scope="col">Active</th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let aProtocol of protocols; let i = index" [attr.data-index]="i">
            <th scope="row">{{i+1}}</th>
            <td>{{aProtocol.protocolName}}</td>
            <td>{{aProtocol.protocolDate}}</td>
            <td>{{aProtocol.fileLocation}}</td>
            <td>{{aProtocol.createdBy}}</td>
            <td>{{aProtocol.dateCreated}}</td>
            <td>{{aProtocol.modifiedBy}}</td>
            <td>{{aProtocol.dateModified}}</td>
            <td>{{!aProtocol.deleted}} </td>
            <td *ngIf="!aProtocol.deleted"><a class="btn btn-lg btn-primary"
                [routerLink]="['/inclusionCriteria' , aProtocol.protocolId, trial.trialId]"
                role="button">Inclusion&nbsp;Criteria</a>
            </td>

            <td>
              <!-- <a class="btn btn-lg btn-primary" [routerLink]="['/trialSites' , trial.trialId]"
                role="button">Trial&nbsp;Sites
              </a> -->
            </td>
            <td>
              <a class="btn btn-lg btn-primary" [routerLink]="['/companyTrials' , companyId]" role="button"> Trials
              </a>
            </td>

          </tr>
        </tbody>
      </table>
      <h3 *ngIf="noProtocolExists">There is no protocol yet for this trial</h3>
      <h3 *ngIf="noProtocolExists">Please Request one</h3>
    </div>
  </div>
  <ngx-spinner type="pacman">
    <p>Loading...</p></ngx-spinner>
</div>