import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { ITrialomsLogin, TrialomsLogin } from 'src/app/shared/interfaces/trialomsLogin';
import { IssAdministrationService } from '../iss-administration.service';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-register-local-pi',
  templateUrl: './register-local-pi.component.html',
  styleUrls: ['./register-local-pi.component.sass']
})
export class RegisterLocalPiComponent {
  busy:boolean=false;
  trialomsUser:TrialomsLogin;
  disabledSubmit:boolean=false;
  showError:boolean=false;
  response:ITrialomsLogin;
  feedback:string="";
  passwordMatch:boolean=false;
  strongPassword:boolean=false;
  constructor(private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private administrationService: IssAdministrationService,
    private spinner: NgxSpinnerService) { }
  ngOnInit(): void {
    this.trialomsUser=new TrialomsLogin();
  }
  changeEmail=():void =>{
    this.disabledSubmit=false;
    this.feedback="";
  }
  onSubmit = (): void => {
    if(!this.checkPasswords()){
      return;
    }
    this.trialomsUser.userName=this.trialomsUser.email;
    this.disabledSubmit=true;
    this.busy=true; 
    this.spinner.show();
    this.administrationService.registerLocalPI(this.authService.authorizationHeaderValue, this.trialomsUser)
    .pipe(finalize(() => {
      this.busy = false;
      this.spinner.hide();
    }))
    .subscribe(
        result=>{
          this.response=result;
          console.log(result);
          if (this.response.errorMessage!=null)
          {
            //display error message
              this.feedback=this.response.errorMessage;
              this.showError=true;
          }
          else{
            this.router.navigate(['/']);
          }
        }
  
    );
  }
  
  onInputChange(inputName: string) {
    console.log(inputName + ' input has changed.');
  }
  
  
  
  checkPasswords() {
    this.passwordMatch=false;
  
    if(!this.checkLowercase())
    {
      this.showError=true;
      this.feedback='You need at least one lowercase character in the password';
      this.strongPassword=false;
      return false;
    }
    if(!this.checkUpercase())
    {
      this.showError=true;
      this.feedback='You need at least one uppercase character in the password';
      this.strongPassword=false;
      return false;
    }
    if(!this.checkNumber())
    {
      this.showError=true;
      this.feedback='You need at least one number in the password';
      this.strongPassword=false;
      return false;
    }
    if(!this.checkSpecialCharacter())
    {
      this.showError=true;
      this.feedback='You need at least one special character in the password';
      this.strongPassword=false;
      return false;
    }
    this.strongPassword=true;
    if (this.trialomsUser.password === this.trialomsUser.confirmPassword) {
      this.showError = false;
      this.feedback = 'Passwords match.';
      this.passwordMatch=true;
      return true;
    } else {
      this.showError = true;
      this.feedback = 'Passwords do not match.';
      this.passwordMatch=false;
      return false;
    }
  }
  checkUpercase = ():boolean => { 
    var format = /[A-Z]/;
    return format.test(this.trialomsUser.password);
  }
  checkLowercase =(): boolean => {
    var format = /[a-z]/;
    return format.test(this.trialomsUser.password);
  }
  checkNumber =(): boolean => {
    var format = /\d/;
    return format.test(this.trialomsUser.password);
  }
  checkSpecialCharacter=(): boolean => {
    var format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return format.test(this.trialomsUser.password);
  }
}
