
<div  >
    <h1>Sponsor Parameters Set-Up </h1>
</div>
<div>
  <a class="btn btn-lg btn-primary m-4" routerLink="/parameters/view" role="button">View Parameters&raquo;</a>

</div>
<div  class="col-lg-12 text-center" style="background-color: #034289; color: white; height: 30px;"></div>

<!--............................................................................................................-->
<div fxLayout="row" fxLayoutAlign="start center">

    <p-card 
    
     [style] = "{ width: '100%' }"
     class = "card-style">

     <ng-template pTemplate="header">
        
         <p-table       
                    [value] = "operationalParameters"
                    styleClass = "p-datatable-striped" 
                    [tableStyle]="{ 'min-width': '20rem' }" 
                    [scrollable]="true"
                    scrollHeight="400px" 
                    >

                    <ng-template pTemplate="caption">
                        <div class="flex" style="display: flex;">
                          <div style="flex: 1; border-right: 2px solid whitesmoke; display: flex; justify-content: center; align-items: center;">
                            For Internal Operation Use
                          </div>
                          <div style="flex: 1; display: flex; justify-content: center; align-items: center;">
                            For External Guidance
                            <span class="p-input-icon-right ml-auto"></span>
                          </div>
                        </div>
                    </ng-template>
                      
                                     
                    <ng-template pTemplate="header">

                            <tr >

                                   <th>#</th>
                                   <th>Component</th>
                                   <th>Duration</th>
                                   <th style="border-right: 2px solid #000000;">Units</th>
                                   <th>Component</th>
                                   <th>Duration</th>
                                   <th>Units</th>
                                                
                            </tr>
                       
                    </ng-template>
                                  
                                    
                                      
                    <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                                        
                                         
                           <tr [ngClass]="{'color-banner': rowIndex > 4}">
                                    <td><b>{{rowData.count}}</b></td>  
                                    <td><b>{{rowData.componentInternal}}</b></td>
                                    <td><p-inputNumber 
                                      [(ngModel)]="rowData.durationInternal" 
                                      [showButtons]="true" 
                                      placeholder="Enter a number" 
                                      inputId="minmax-buttons" [min]="0" [max]="100" 
                                      [inputStyle]="{'width': '25%'}"
                                      pTooltip="Enter a number between 0 and 100"
                                      tooltipPosition="top">
                                      </p-inputNumber>
                                    </td> 
                                    <td style="border-right: 2px solid black;">
                                        <p-dropdown [options]="durationUnit" 
                                        [(ngModel)]="rowData.unitInternal" 
                                        optionLabel="label" [showClear]="true" 
                                        placeholder="Select Duration unit" 
                                        styleClass="w-60"
                                        pTooltip="Select Days or Months"
                                        tooltipPosition="top">
                                        </p-dropdown>
                                      </td>
                                    <td>{{rowData.componentExternal}}</td>
                                    <td><p-inputNumber 
                                      [(ngModel)]="rowData.durationExternal" 
                                      [showButtons]="true" 
                                      placeholder="Enter a number" 
                                      inputId="minmax-buttons" [min]="0" [max]="100" 
                                      [inputStyle]="{'width': '25%'}" 
                                      pTooltip="Enter a number between 0 and 100"
                                      tooltipPosition="top">
                                      
                                      </p-inputNumber>
                                   </td>
                                    <td>{{rowData.unitInternal.label}}</td>
                               <!-- <td><p-dropdown [options]="durationUnit" [(ngModel)]="rowData.unitExternal" optionLabel="label" [showClear]="true" placeholder="Select Duration unit" styleClass="w-60"></p-dropdown></td> -->
                           </tr>

                    </ng-template>  

                    

                                    <p-inputNumber [(ngModel)]="conceptReviewDuration" ></p-inputNumber>
                                    <p-inputNumber [(ngModel)]="conceptSubmissionDuration" ></p-inputNumber>

                                    <p-inputNumber [(ngModel)]="protocolReviewDuration" ></p-inputNumber>
                                    <p-inputNumber [(ngModel)]="protocolSubmissionDuration" ></p-inputNumber>

                                    <p-inputNumber [(ngModel)]="contractReviewDuration" ></p-inputNumber>
                                    <p-inputNumber [(ngModel)]="contractSubmissionDuration" ></p-inputNumber>

                                    <p-inputNumber [(ngModel)]="documentReviewDuration" ></p-inputNumber>
                                    <p-inputNumber [(ngModel)]="documentSubmissionDuration" ></p-inputNumber>

                                    <p-inputNumber [(ngModel)]="timeToSiteActivation" ></p-inputNumber>
                                    <p-inputNumber [(ngModel)]="timeToDataAnalysisSinceLPO" ></p-inputNumber>
                                    <p-inputNumber [(ngModel)]="timeToPublicationSinceLPO" ></p-inputNumber>                                 
                                    <p-inputNumber [(ngModel)]="timeToLastBudgetReconciliation" ></p-inputNumber>
                                    <p-inputNumber [(ngModel)]="timeToSiteClosureSinceDataAnalysis" ></p-inputNumber>
         </p-table>                            
     


 </ng-template> 
    </p-card>  

   

    
 </div> 

  <div class="button-container">

  <p-button label="Cancel" icon="pi pi-times" iconPos="right" [routerLink]="['/']"></p-button>

  <p-button label="Submit" icon="pi pi-check" iconPos="right"  severity="success"  routerLink="['/']"></p-button>

</div>



 