<!-- Site Name  -->
<!-- Date of site selection  -->
<!-- draft protocol to pharma company-->
<!--  Date of IRB submission -->
<!--Date IRB approval-->
<!--Date of Supplies and Equipment -->
<!-- Date of Site Activation  -->

<div class="row mb-1 justify-content-center">
  <div>
    <button type="button" class="btn btn-info" (click)="isCollapsed = !isCollapsed" 
    ngbTooltip="{{setUpTooltip}}" (mouseover)="mouseOverSetUp()"
    [attr.aria-expanded]="!isCollapsed"
      aria-controls="addSite">
      <h3  >PI information</h3> 
    </button>

  </div>
  <div class="col-lg-12 text-center collapse" id='addSite' [ngbCollapse]="isCollapsed">

    <div class="card">
      <div class="card-body">
        <form *ngIf="!busy" #f="ngForm" (ngSubmit)="onSubmit()">

          <div class="row mb-1">
            <div class="col-lg-2 col-xs-12">
              <div class="form-group">
                <label for="siteName">Institution Name</label>
              </div>
            </div>

            <div class="col-lg-4 col-xs-12">
              <div class="form-group">
                <input type="text" id="siteName" name="siteName" [(ngModel)]="site.siteName" class="form-control"
                  placeholder="Institution Name" required autoFocus>
              </div>
            </div>
<!--           </div>
          <div class="row mb-1"> -->
            <div class="col-lg-2 col-xs-12">
              <div class="form-group">
                <label for="studyName">Study Name</label>
              </div>
            </div>

            <div class="col-lg-4 col-xs-12">
              <div class="form-group">
                <input type="text" id="studyName" name="studyName" [(ngModel)]="site.studyName" class="form-control"
                  placeholder="Study Name" required>
              </div>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-lg-2 col-xs-12">
              <div class="form-group">
                <label for="diseaseArea">Disease Area</label>
              </div>
            </div>

            <div class="col-lg-4 col-xs-12">
              <div class="form-group">
                <input type="text" id="diseaseArea" name="diseaseArea" [(ngModel)]="site.diseaseArea"
                  class="form-control" placeholder="Disease Area" required>
              </div>
            </div>

            <div class="col-lg-2 col-xs-12">

              <div class="form-group">
                <label for="studyType">Type of Study</label>
              </div>
            </div>

            <div class="col-lg-4 col-xs-12">

              <div class="form-group">
                <!-- -->
                <select class="form-control" id="studyTypeId" name="studyTypeId" [(ngModel)]="site.studyTypeId"
                  required>
                  <option *ngFor="let studyType of studyTypes" [value]="studyType.id">{{studyType.description}}</option>
                </select>
              </div>
            </div>

          </div>
          <div class="row mb-1">
            <div class="col-lg-2 col-xs-12">
              <div class="form-group">
                <label for="siteName">Number of patients</label>
              </div>
            </div>

            <div class="col-lg-4 col-xs-12">
              <div class="form-group">
                <input type="number" id="numberOfPatients" name="numberOfPatients" [(ngModel)]="site.numberOfPatients"
                  class="form-control" placeholder="Proposed Number of Patients">
              </div>
            </div>
       <!--    </div>
          <div class="row mb-1"> -->
            <div class="col-lg-2 col-xs-12">
              <div class="form-group">
                <label for="protocol">Concept Submission Date</label>
              </div>
            </div>

            <div class="col-lg-4 col-xs-12">
              <div class="form-group">
                <input type="date" id="conceptSubmissionDate" name="conceptSubmissionDate"
                  [(ngModel)]="site.conceptSubmissionDate" class="form-control" placeholder="Concept Submission Date"
                  required>
              </div>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-lg-2 col-xs-12">
              <div class="form-group">
                <label for="geoLocation">Address</label>
              </div>
            </div>

            <div class="col-lg-10 col-xs-12">
              <div class="form-group">
                <input type="text" id="geoLocation" name="geoLocation" [(ngModel)]="site.geoLocation"
                  class="form-control" placeholder="Geo Location, address of the institution" required>
              </div>
            </div>
          </div>

          <div class="row mb-1">
            <div class="col-lg-2 col-xs-12">
              <div class="form-group">
                <label for="piName">PI </label>
              </div>
            </div>

            <div class="col-lg-4 col-xs-12">
              <div class="form-group">
                <input type="text" id="piName" name="piName" [(ngModel)]="site.piName" class="form-control"
                  placeholder="Name" required>
              </div>
            </div>
            
            <div class="col-lg-2 col-xs-12">
              <div class="form-group">
                <label for="piEmail"> </label>
              </div>
            </div>

            <div class="col-lg-4 col-xs-12">
              <div class="form-group">
                <input type="email" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                 id="piEmail" name="piEmail" [(ngModel)]="site.piEmail" class="form-control"
                  placeholder="Email" required>
              </div>
            </div>
         </div>

          <div class="row mb-1"> 
            <div class="col-lg-2 col-xs-12">
              <div class="form-group">
                <label for="siteResearchCoordinatorName">Site Ressearch Coordinator</label>
              </div>
            </div>

            <div class="col-lg-4 col-xs-12">
              <div class="form-group">
                <input type="text" id="siteResearchCoordinatorName" name="siteResearchCoordinatorName" [(ngModel)]="site.siteResearchCoordinatorName" class="form-control"
                  placeholder="Name" required>
              </div>
            </div>
            <div class="col-lg-2 col-xs-12">
              <div class="form-group">
                <label for="piEmail"></label>
              </div>
            </div>

            <div class="col-lg-4 col-xs-12">
              <div class="form-group">
                <input type="email" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" 
                id="siteResearchCoordinatorEmail" name="siteResearchCoordinatorEmail" [(ngModel)]="site.siteResearchCoordinatorEmail" class="form-control"
                  placeholder="Email" required>
              </div>
            </div>

          </div>
<!--

    piEmail:string;
    siteResearchCoordinatorEmail:string;

-->

          <div class="row mb-1">
            <div class="col-lg-2 col-xs-12">
              <div class="form-group">
                <label for="msl">MSL</label>
              </div>
            </div>

            <div class="col-lg-4 col-xs-12">
              <div class="form-group">
                <input type="text" id="msl" name="msl" [(ngModel)]="site.msl" class="form-control" placeholder="Name"
                  required>
              </div>
            </div>
            <div class="col-lg-2 col-xs-12">
              <div class="form-group">
                <label for="mslEmail"></label>
              </div>
            </div>

            <div class="col-lg-4 col-xs-12">
              <div class="form-group">
                <input type="email" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                 id="mslEmail" name="mslEmail" [(ngModel)]="site.mslEmail" class="form-control" placeholder="Email"
                  required>
              </div>
            </div>

          </div>

          <!-- MSL & Date concept submitted-->
          <!--div class="row mb-1"> conceptSubmissionDate
              <div class="col-lg-2 col-xs-12">
                <div class="form-group">
                  <label for="protocol">Protocol Date</label>
                </div>
              </div>
      
              <div class="col-lg-10 col-xs-12">
                <div class="form-group">
                  <input type="date" id="protocolDate" name="protocolName" [(ngModel)]="protocol.protocolDate"
                    class="form-control" placeholder="Protocol Date" required>
                </div>
              </div>
            </div-->
          <!--div class="row mb-1">
              <div class="col-lg-2 col-xs-12">
                <div class="form-group">
                  <label for="protocol">Protocol File</label>
                </div>
              </div>
      
              <div class="col-lg-10 col-xs-12">
                <div class="form-group">
                  <input type="file" id="fileLocation" name="fileLocation" [(ngModel)]="protocol.protocolFile"
                    class="form-control" placeholder="File Location" required>
                </div>
              </div>
            </div-->
          <div class="row mb-1">

            <div class="col-lg-2 col-xs-12">
            </div>
            <div class="col-lg-4 col-xs-12">
              <div class="form-group">
                <a class="btn btn-lg btn-primary btn-block" [routerLink]="['/']" role="button">Cancel</a>
              </div>
            </div>
         <!--  </div>
          <div class="row mb-1"> -->
            <div class="col-lg-2 col-xs-12">
            </div>
            <div class="col-lg-4 col-xs-12">
              <div class="form-group">
                <button class="btn btn-lg btn-primary btn-block" type="submit" [disabled]="!f.form.valid">
                  Set Up
                </button>
              </div>
            </div>
          </div>

          <!--  <div class="row mb-1">
              <div class="col-lg-2 col-xs-12">
              </div>
              <div class="col-lg-10 col-xs-12">
                <div class="form-group">
                  <button class="btn btn-lg btn-primary btn-block" type="submit" [disabled]="!f.form.valid">
                    {{createProtocolButtonText}}
                  </button>
                </div>
              </div>
            </div> -->

          <div *ngIf="error" class="alert alert-danger mt-3" role="alert">
            <strong>Oops!</strong> {{error}}
          </div>
        </form>
      </div>
    </div>

  </div>
</div>

<div class="row mb-10 justify-content-center">
  <div class="col-lg-12 text-center">
    <div *ngIf="!busy" class="col-md-12">
      <h3>List of Investigator Initiated Studies</h3>
      <div class="col-lg-12" style="background-color: #045975; margin-bottom: 20px;"> <!-- Color band -->
        &nbsp; 
    </div>

                  <!--Filters Start Here-->

            <!--Filter by Evaluation Status-->  
    
 <div class="row mb-10">
        <div class="col-lg-3 col-sx-12">
          
            <div class="form-group">
                <label for="EvaluationStatus"><b>Studies By Evaluation Status</b></label>
                
            </div>
        </div>
        
                  <div class="col-lg-3 col-xs-12">

                      <div class="form-group">
                          
                          <select class="form-control" id="EvaluationStatus" name="EvaluationStatus"
                          [(ngModel)]="statusNumber"  (change)= "filterSites()">
                              <option value="0">All Studies</option>
                              <option value="1">Concept Evaluation</option>
                              <option value="2">Concept Re-evaluation</option>
                              <option value="3">Rejected (Concept)</option>
                              <option value="4">Protocol Submission</option>
                              <option value="5">Protocol Evaluation</option>
                              <option value="6">Protocol Re-evaluation</option>
                              <option value="7">Rejected (Protocol)</option>
                              <option value="8">Contract Negotiation</option>
                              <option value="9">Documents Check</option>
                         
                          </select>
                      </div>
                  </div>
                
 
 <!-- /////Filter by Study Type -->

 

 <!--<div class="row mb-1">-->
  <div class="col-lg-3 col-sx-12">
    
      <div class="form-group">
          <label for="studyTypeSelection"><b>Filter by Study Type</b></label>
          
      </div>
      </div>


 <div class="col-lg-3 col-sx-12">

    <div class="form-group">
        
        <select class="form-control" id="studyTypeSelection" name="studyTypeSelection"
        
        [(ngModel)]="typeNumber"  (change)= "filterSites()">
            <option value="0">All Studies</option>
            <option value="1">Clinical</option>
            <option value="2">Pre-Clinical</option>
            <option value="3">Observational</option>
           
        </select>
    </div>
 </div>
 </div>

 <!--////End of 2nd filter setup-->

      
 <!--/// Begining of # of Sites Display-->
 <div class="row mb-10"> 
  <div class="col-lg-3 col-xs-12">
    <div class="form-group">
        <label for="siteSetupsCount"><b># of Sites (Filtered/Total)</b></label>
    </div>
  </div>

 <div class="col-lg-3 col-xs-12">
    <div class="form-group">
      
           <a  class="btn btn-primary form-control SITESCOUNT"
               role="button" title={{siteSetupsCount}} aria-disabled="true" >
               {{siteSetupsFilteredCount}} / {{siteSetupsCount}}
           </a>
   
    </div>
 </div>
 </div> 

<!--</div>-->

<!--////End # of Sites Display-->

      <table class="table small">
        
        <thead>

          <tr>
            <th scope="col">Audit Trail</th>
            <th scope="col">Site Name</th>
            <th scope="col">Address</th>
            <th scope="col">PI Name</th>
            <th scope="col">Study Name</th>
            <th scope="col">Study Type</th> 
            <th scope="col">Evaluation Status</th>

            
          </tr>
        </thead>
        <tbody>
  <tr *ngFor="let aSite of siteSetupsFiltered; let i = index" [attr.data-index]="i">
        <th scope="row">
          
              <a *ngIf="aSite.conceptEvaluationStatus!=null" ngbTooltip="View audit"
              [routerLink]= "['/audit' , aSite.id]" >{{i+1}}</a>
              <span *ngIf="aSite.conceptEvaluationStatus==null" ngbTooltip="Not yet evaluated" >{{i+1}}</span>

        </th>
            <td>{{aSite.siteName}}</td>
            <td>{{aSite.geoLocation}}</td>
            <td>{{aSite.piName}}</td>
            <td>{{aSite.studyName}}</td>
            <td>{{aSite.studyType.description}}</td>
           

            <td colspan="2" *ngIf="aSite.conceptEvaluationStatus=='Rejected'">
             <a class= {{nextStepClass(aSite)}}
             [routerLink]=nextStepLink(aSite)
             role="button"> Concept Rejected on {{aSite.conceptEvaluationDate|date:'d MMMM y'}} </a>
            </td>
             
             <td *ngIf="aSite.protocolEvaluationStatus=='Rejected' ">
                <a class= {{nextStepClass(aSite)}}
                [routerLink]=nextStepLink(aSite)
                role="button">  Protocol Rejected on {{aSite.protocolEvaluationDate|date:'d MMMM y'}} </a>
              </td>

          <td colspan="2" *ngIf="aSite.conceptEvaluationStatus!='Rejected' && aSite.protocolEvaluationStatus!='Rejected' "> 
            
             <!--  <td class="form-group">       "['/conceptApproval' , aStudy.id]"-->
              <a class= {{nextStepClass(aSite)}}
              [routerLink]=nextStepLink(aSite)     
              role="button"> {{nextStepTitle(aSite)}}
                    
              </a> 
           </td> 

  </tr>
  
      </tbody>  

      </table>  

     

      </div> 

      <ngx-spinner type="pacman">
        <p>Loading...</p>
    </ngx-spinner>
  </div>  

</div>  

