import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { IParameter, IParameterAudit } from 'src/app/shared/interfaces/parameter';
import { IssAdministrationService } from '../iss-administration.service';

@Component({
  selector: 'app-parameter-audit',
  templateUrl: './parameter-audit.component.html',
  styleUrls: ['./parameter-audit.component.sass']
})
export class ParameterAuditComponent {
  busy:boolean=false;
  setupParameterAudit:IParameterAudit[];
  parameterId:number;
constructor(private route: ActivatedRoute,
  private authService: AuthService,
  private router: Router,
  private administrationService: IssAdministrationService,
  private spinner: NgxSpinnerService) { }


ngOnInit(): void {
this.busy=true;
this.parameterId = +this.route.snapshot.paramMap.get('parameterid');
console.log(`Parameter id ${this.parameterId}`);
this.spinner.show();
this.administrationService.getSetupParameterAudit(this.authService.authorizationHeaderValue, this.parameterId)
.pipe(finalize(() => {
  this.busy = false;
  this.spinner.hide();
})).subscribe(
result => {
  console.log(result);
  this.setupParameterAudit=result;
  
  for (let i=0; i<this.setupParameterAudit.length; i++){
    if (this.setupParameterAudit[i].unitInternal=="d"){
      this.setupParameterAudit[i].unitInternal="Days";
    }
    if (this.setupParameterAudit[i].unitInternal=="m"){
      this.setupParameterAudit[i].unitInternal="Months";
    }
   }
});

  }


}
