import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { IssAdministrationService } from '../iss-administration.service';

@Component({
  selector: 'app-sponsor-irb-detail',
  templateUrl: './sponsor-irb-detail.component.html',
  styleUrls: ['./sponsor-irb-detail.component.sass']
})
export class SponsorIrbDetailComponent {
  irbId: number;
  conceptId: number;
  busy:boolean;

  constructor(private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private administrationService: IssAdministrationService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    
    this.irbId = +this.route.snapshot.paramMap.get('irbId');
    this.conceptId = +this.route.snapshot.paramMap.get('conceptID');
    console.log('irb Id = '+ this.irbId.toString());
    console.log('concept Id = '+ this.conceptId.toString());
  }
}
