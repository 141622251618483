<div>
    <div>
        <form *ngIf="!busy" #f="ngForm" (ngSubmit)="onSubmit()">



            <div>
                <div class="row mb-1 justify-content-center">
                    <h3>Patient Screening Results</h3>
                </div>
                <div class="row mb-1">
                    <div class="col-lg-12" id='addPatient'>
                    </div>
                </div>


                <div class="row mb-1">
                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <label for="patientNumber"><b>Patient Number</b></label>
                        </div>
                    </div>

                    <div class="col-lg-3 col-xs-12">
                        <div class="form-group">
                            <span>{{patient.patientNumber}}</span>
                        </div>
                    </div>

                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <label for="dateOfBirth"><b>Date of Birth</b></label>
                        </div>
                    </div>

                    <div class="col-lg-3 col-xs-12">
                        <div class="form-group">
                            <span>{{patient.dateOfBirth|date:'d MMMM y'}}</span>
                        </div>
                    </div>
                </div>
                <div class="row mb-1">
                    <div class="col-lg-2 col-sx-12">

                        <div class="form-group">
                            <label for="biologicalGender"><b>Biological Gender</b></label>
                        </div>
                    </div>
                    <div class="col-lg-3 col-xs-12">

                        <div class="form-group">
                            <!-- -->
                            <span>{{patient.biologicalGender}}</span>
                            <!-- <select class="form-control" id="biologicalGender" name="biologicalGender"
                            [(ngModel)]="patient.biologicalGender" readonly>
                            <option value="M">Male</option>
                            <option value="F">Female</option>
                        </select> -->
                        </div>
                    </div>
                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <label for="screeningDate"><b>Screening Date</b></label>
                        </div>
                    </div>

                    <div class="col-lg-3 col-xs-12">
                        <div class="form-group">
                            <span>{{patient.screeningDate|date:'d MMMM y'}}</span>
                        </div>
                    </div>
                </div>
                <div class="row mb-1">
                    
                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <label for="screeningResultsDate" title="Sreeneng Results Date"><b>Result Date</b></label>
                        </div>
                    </div>

                    <div class="col-lg-3 col-xs-12">
                        <div class="form-group">
                            <input type="date" id="screeningResultsDate" name="screeningResultsDate" (click)="selectForTrial()"
                                [(ngModel)]="patient.screeningResultsDate" class="form-control" required>
                        </div>
                    </div>
                    <div class="col-lg-2 col-xs-12">
                        <div class="form-group">
                            <label for="selected" title="Patient Selected?"><b>Selected ?</b></label>
                        </div>
                    </div>
                    
                    <div class="col-lg-3 col-xs-12">
                        
                            <input type="checkbox"   id="selected" name="selected"
                                [(ngModel)]="patient.selected"  >
                        
                    </div>
                </div>
                <div class="row mb-1" *ngIf="!patient.selected">

                    
                    <div class="col-lg-2 col-xs-12">

                        <div class="form-group">
                            <label for="comment"><b>Comment</b></label>
                        </div>
                    </div>

                    <div class="col-lg-10 col-xs-12">
                        <div class="form-group" >
                            
                            <textarea id="comment" name="comment" [(ngModel)]="patient.screeningComment"
                            class="form-control"  >
                        </textarea>
                        </div>
                    </div>
                </div>
                <!--add a comment box here optional-->
                <div class="row mb-1">
                    <div class="col-lg-6 col-xs-12">

                    </div>
                    <div class="col-lg-3 col-xs-12">
                        <div class="form-group">
                            <a class="btn btn-lg form-control btn-primary btn-block" [routerLink]="['/patient', irbId]"
                                role="button" title="View Patients">Cancel</a>
                        </div>
                    </div>
                    <div class="col-lg-3 col-xs-12">
                        <div class="form-group">
                            <button type="submit" class="btn btn-lg  btn btn-primary form-control"
                                [disabled]="!f.form.valid || patient.selected==undefined || patient.selected==null ">
                                Update
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- </div> -->
        </form>
    </div>

    <ngx-spinner type="pacman">
        <p>Loading...</p>
    </ngx-spinner>
</div>
