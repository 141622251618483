import { SiteSetup,ISiteSetup } from "./siteSetup";

export class Irb {
    /// think about audit
    public id: number;
    public issSiteId: number;
    public protocolVersion:string;
    public siteName: string;
    public geoLocation: string;
    public longitude: string;
    public latitude: string;
    public country:string;
    public piName: string;
    public piEmail: string;
    public siteResearchCoordinatorName: string;
    public siteResearchCoordinatorEmail: string;
    public msl: string;
    public mslEmail: string;
    public submissionDate: Date;
    public expectedReviewDate: Date;
    public reviewDate: Date;
    public outcome: boolean;
    public firstApprovalDate:Date;
    // comment for the outcome when negative
    public comment: string;
    public expectedNumberOfPatients:number;
    public irbCost:number;
    public recruitmentCosts:number;
    public hospitalCosts: number;
    public paymentToPatient:number;
    public documentUrl: string;
    public studyDuration:number;
    public studyDurationUnit:string;
    public createdBy: string;
    public dateCreated: Date;
    public modifiedBy: string;
    public dateModified: Date;
    public deleted: boolean;
}
export interface IIrb {
    id: number;
    issSiteId: number;
    protocolVersion:string;
    siteName: string;
    site:ISiteSetup;
    geoLocation: string;
    longitude: string;
    latitude: string;
    country:string;
    piName: string;
    piEmail: string;
    siteResearchCoordinatorName: string;
    siteResearchCoordinatorEmail: string
    msl: string;
    mslEmail: string;
    submissionDate: Date;
    expectedReviewDate: Date;
    reviewDate: Date;
    outcome: boolean;
    firstApprovalDate:Date;
    comment: string;
    expectedNumberOfPatients:number;
    irbCost:number;
    recruitmentCosts:number;
    hospitalCosts: number;
    paymentToPatient:number;
    documentUrl: string;
    studyDuration:number;
    studyDurationUnit:string;
    createdBy: string;
    dateCreated: Date;
    modifiedBy: string;
    dateModified: Date;
    deleted: boolean;
}
export interface IIrbAudits
{
    id:number;
    irbId:string;
    protocolVersion:string;
    siteName:string;
    geoLocation: string;
    piName:string;
    piEmail:string
    siteResearchCoordinatorName:string;
    siteResearchCoordinatorEmail:string;
    msl:string;
    mslEmail:string;
    submissionDate:Date;
    expectedReviewDate:Date;
    reviewDate:Date;
    
    // comment for the outcome when negative
    comment:string;
    expectedNumberOfPatients:number;
    documentUrl:string;
    modifiedBy:string;
    dateModified:Date;
    outcome:boolean;
}
