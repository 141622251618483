export class Protocol{
    public id:number;
    public trialId:number;
    public protocolName: string;
    public protocolFile:string;
    public protocolDate: Date;
    public trialInProgress:boolean;
    //if trial is in progress then these are visible
    //summary of changes to the protocol
    public changeSummary:string;
    public stopTrial:boolean;//trial needs to be stopped until irb is approved
    //notification to authorities required - FDA EMA etc
    public notificationRequired:boolean;
    public trialGoalChanged:boolean;
    public trialDesignChanged:boolean;
    public statisticalAnalysisImpacted:boolean;
    public irbApprovalRequired:boolean;
    public createdBy:string;
    public dateCreated:Date;
    public modifiedBy: string;
    public dateModified: Date;
    public deleted:boolean;
}
export interface IProtocol{
    id:number;
    trialId:number;
    protocolName: string;
    protocolFile:string;
    protocolDate: Date;
    trialInProgress:boolean;
    changeSummary:string;
    stopTrial:boolean;//trial needs to be stopped until irb is approved
    //notification to authorities required - FDA EMA etc
    notificationRequired:boolean;
    trialGoalChanged:boolean;
    trialDesignChanged:boolean;
    statisticalAnalysisImpacted:boolean;
    irbApprovalRequired:boolean;
    createdBy:string;
    dateCreated:Date;
    modifiedBy: string;
    dateModified: Date;
    deleted:boolean;
}