import { Component, OnInit } from '@angular/core';
import { SiteSetup, ISiteSetup } from 'src/app/shared/interfaces/siteSetup';
import { IStudyType } from 'src/app/shared/interfaces/studyTypes';
import { IConceptEvaluation } from 'src/app/shared/interfaces/conceptEvaluation';
import { IProtocolSubmission } from 'src/app/shared/interfaces/protocolSubmission';
import { IProtocolEvaluation} from 'src/app/shared/interfaces/protocolEvaluation';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators'
import { AuthService } from '../../core/authentication/auth.service';
import { IssAdministrationService } from '../iss-administration.service';
import { Router, CanActivate, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-protocol-submission',
  templateUrl: './protocol-submission.component.html',
  styleUrls: ['../styles.sass', './protocol-submission.component.sass']
})
export class ProtocolSubmissionComponent implements OnInit {

  conceptId: number;
  site: ISiteSetup;
  pipe:DatePipe;
  isCollapsed: boolean = true;
  validSubmissionDate:boolean=false;
  submissionDateErrorMessage:string='';
  
  protocolSubmissions: IProtocolSubmission[];
  protocolEvaluations:IProtocolEvaluation[];
  conceptEvaluations:IConceptEvaluation[];
  error: boolean = false;
  busy: boolean = false;
  trialStarted:boolean=false;
  auditBusy: boolean = false;
  historyOnly: boolean = false;
  principalInvestigator: boolean = false;
  LastEvaluationDate:Date;  //Latest protocol evaluation date
  LastStatus:string;
  

  constructor(private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private administrationService: IssAdministrationService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    if (sessionStorage.getItem('principalInvestigator') === 'true') {
      this.principalInvestigator = true;
    }
    else {
      this.principalInvestigator = false;
    }
    this.conceptId = +this.route.snapshot.paramMap.get('conceptId');
    console.log(this.conceptId);
    this.spinner.show();
    this.busy = true;
    this.administrationService.getIssSite(this.authService.authorizationHeaderValue, this.conceptId)
      .pipe(finalize(() => {
        this.busy = false;
        this.hideSpinner();
      })).subscribe(
        result => {
          this.site = result;
          debugger;
          this.LastEvaluationDate=this.site.protocolEvaluationDate;
          this.LastStatus=this.site.protocolEvaluationStatus;
          this.trialStarted=this.site.trialInProgress;  // AAA addition
          console.log(this.site);
        });
  }
 // Inserted from here by Armel

  SubmissionDate= ():void =>{
  this.pipe = new DatePipe('en-US');
  console.log(`Concept Evaluation Date ${this.site.conceptEvaluationDate} Submission Date:${this.site.protocolSubmissionDate}`);
  
 
    
 if (this.LastStatus==null && this.site.protocolSubmissionDate < this.site.conceptEvaluationDate)  //First Submission
   {
     this.validSubmissionDate=false;
     this.submissionDateErrorMessage='Invalid Submission Date. Must be after the Concept Approval Date, above in the expanded window ';
   }
  else{
    
    if (this.LastStatus!=null && this.site.protocolSubmissionDate < this.LastEvaluationDate)  //Subsequent Submissions
    {
    this.validSubmissionDate=false;
      this.submissionDateErrorMessage=`Invalid Submission Date. Must be after the last Protocol Evaluation Date of ${ this.pipe.transform(this.site.protocolEvaluationDate, 'd MMMM y')}`;
    }
   else{
      this.validSubmissionDate=true;
      this.submissionDateErrorMessage='';
    }
  }
    
  }
 //End of submission date script

  onSubmit = (): void => {
    debugger;
    this.site.trialInProgress=this.trialStarted;
    this.administrationService.submitProtocol(this.authService.authorizationHeaderValue, this.site)
      .pipe(finalize(() => {
        this.busy = false;
        this.hideSpinner();
      })).subscribe(
        result => {
          
          console.log(result);
          if (this.principalInvestigator) {
            this.router.navigate(['/hospitals']);
          }
          else {
            this.router.navigate(['/siteSetup']);
          }
         
        });
  }

  hideSpinner = (): void => {
    if (this.busy || this.auditBusy) {
      return;
    }
    this.spinner.hide();
  }
}
