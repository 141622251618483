<div>
    <h1>Current IRBs </h1>
</div>

<div  class="col-lg-12 text-center" style="background-color: #031789; color: white; height: 30px;"> </div>
<div>
    <!--:::::::::::::::::::::::::::::::::::::::::::PRIMENG CODES::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::-->
  
  <div class="row mb-20"></div>
  
  
    <div fxLayout="row" fxLayoutAlign="start center">

       <p-card 
       
        [style] = "{ width: '100%' }"
        class = "card-style">

        <ng-template pTemplate="header">
           
            <p-table  #dt2
                       [value]="allIrbListing"
                       styleClass="p-datatable-gridlines"
                       styleClass = "p-datatable-striped" 
                       [tableStyle]="{ 'min-width': '20rem' }" 
                       [scrollable]="true"
                       scrollHeight="500px" 
                       [globalFilterFields]="['studyName', 'siteName', 'diseaseArea', 'piName', 'country']"
                       pResizableColumn>

                       <ng-template pTemplate="caption">
                        
                        <div class="flex ">
                            <span class="p-input-icon-right ml-auto">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt2.filterGlobal($event.target.value, 'contains')" 
                                        placeholder="Search Keyword (including countries)"
                                        class="rounded-button" />
                            </span>
                        </div>
                        
                    </ng-template>
                                        
                                        <ng-template pTemplate="header">

                                            <tr style="background-color: #04526c;"  >
                                                  <th pSortableColumn = "count">#<p-sortIcon field="count"></p-sortIcon></th>
                                                  <th pSortableColumn = "studyName">Study Name<p-sortIcon field="studyName"></p-sortIcon></th>
                                                  <th pSortableColumn = "siteName">Site Name<p-sortIcon field="siteName"></p-sortIcon></th>
                                                  <th pSortableColumn = "diseaseArea" >Disease Area<p-sortIcon field="diseaseArea"></p-sortIcon></th>
                                                  <th pSortableColumn = "piName" >PI Name<p-sortIcon field="PiName"></p-sortIcon></th>
                                                  <th pSortableColumn = "irbStatus">IRB Status<p-sortIcon field="irbStatus"></p-sortIcon></th>
                                                   
                                            </tr>
                                            <tr>
                                                <th>
                                                   
                                                </th>

                                                <th>
                                                    <p-columnFilter type="text" field="studyName" matchMode="contains" [showMenu]="false">
                                                      <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                         <p-multiSelect class="multi-select" [ngModel]="selectedStudyNames" [options]="distinctStudyNameListObject" placeholder="Select Study" (onChange)="dt.filter1($event.value, 'studyName', 'in')" optionLabel="studyName">
                                                             <ng-template let-option pTemplate = "item">
                                                                 <div class="inline-block vertical-align-middle">
                                                                    <span class="ml-1 mt-1">{{ option.studyName }}</span>
                                                                </div>
                                                            </ng-template>
                                                        </p-multiSelect>
                                                      </ng-template>
                                                    </p-columnFilter>
                                                </th>  
                                                

                                                <th>
                                                    <p-columnFilter type="text" field="siteName" matchMode="contains" [showMenu]="false">
                                                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                            <p-multiSelect class="multi-select" [ngModel]="selectedSiteNames" [options]="irbDistinctSiteNameListObject" placeholder="Select Site" (onChange)="dt.filter($event.value, 'siteName', 'in')" optionLabel="siteName">
                                                                <ng-template let-option pTemplate="item">
                                                                    <div class="inline-block vertical-align-middle">
                                                                       
                                                                        <span class="ml-1 mt-1">{{ option.siteName }}</span>
                                                                    </div>
                                                                </ng-template>
                                                            </p-multiSelect>
                                                        </ng-template>   
                                                    </p-columnFilter>
                                                </th>

                                                <th>
                                                    <p-columnFilter type="text" field="diseaseArea" matchMode="contains" [showMenu]="false">

                                                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                            <p-multiSelect class="multi-select" [ngModel]="selectedDiseaseAreas" [options]="distinctDiseaseAreaListObject" placeholder="Select Disease Area" (onChange)="dt.filter($event.value, 'diseaseArea', 'in')" optionLabel="diseaseArea">
                                                                <ng-template let-option pTemplate="item">
                                                                    <div class="inline-block vertical-align-middle">
                                                                       
                                                                        <span class="ml-1 mt-1">{{ option.diseaseArea }}</span>
                                                                    </div>
                                                                </ng-template>
                                                            </p-multiSelect>
                                                        </ng-template>   
                                                    </p-columnFilter>
                                                </th>

                                                <th>
                                                    <p-columnFilter type="text" field="piName" matchMode="contains" [showMenu]="false">
                                                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                        <p-multiSelect class="multi-select" [ngModel]="selectedPiNames" [options]="irbDistinctPiNameListObject" placeholder="Select PI" (onChange)="dt.filter($event.value, 'piName' , 'in')" optionLabel="piName">
                                                            <ng-template let-option pTemplate="item">
                                                                <div class="inline-block vertical-align-middle">
                                                                   
                                                                    <span class="ml-1 mt-1">{{ option.piName }}</span>
                                                                </div>
                                                            </ng-template>
                                                        </p-multiSelect>
                                                        </ng-template>
                                                    </p-columnFilter>
                                                </th>

                                                <th>
                                                    <p-columnFilter type="text" field="Country" matchMode="contains" [showMenu]="false">
                                                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                        <p-multiSelect class="multi-select" [ngModel]="selectedCountries" [options]="irbDistinctCountryListObject" placeholder="Select countries" (onChange)="dt.filter($event.value, 'country', 'in')" optionLabel="country">
                                                            <ng-template let-option pTemplate="item">
                                                                <div class="inline-block vertical-align-middle">
                                                                    <img src="{{ getLocalFlagUrl(getCountryCodeFromLibrary(option.country)) }}" alt="{{ option.country }} flag" class="country-flag"> 
                                                                    <span class="ml-1 mt-1">{{ option.country }}</span>
                                                                </div>
                                                            </ng-template>
                                                        </p-multiSelect>
                                                        </ng-template>
                                                    </p-columnFilter>
                                                </th>
    
                                            </tr>
                                      </ng-template>
  
                                      <ng-template pTemplate="body"  let-rowData>
                                            <tr>
                                                <td style="color: #3398FF" class="tbody"><b>{{ rowData.count }}</b></td>
                                                  <td class="tbody">{{ rowData.studyName }}</td>
                                                  <td class="tbody">{{ rowData.siteName  }}</td>
                                                  <td class="tbody">{{ rowData.diseaseArea }}</td>
                                                  <td class="tbody">{{ rowData.piName }}</td>
                                                  <td class="tbody">
                                                                <a [routerLink]="['/irbAudit',rowData.conceptId, rowData.irbId ]" role="button">
                                                                    <button class="full-width-button rounded-button" 
                                                                    [ngClass]="{
                                                                        'clsApproved': rowData.irbStatus === 'Approved',
                                                                        'clsPending': rowData.irbStatus === 'Pending',
                                                                        'clsSubmitted': rowData.irbStatus === 'Submitted',
                                                                        'clsRequested': rowData.irbStatus === 'Requested'}" 
                                                                        (click)="clickHandler(rowData.irbId)">{{ rowData.irbStatus }}
                                                                    </button>
                                                                </a>
                                                   </td>
                                                 
                                            </tr>
                                      </ng-template>

            </p-table>                            
        


    </ng-template> 

    <div style="text-align: center;">
        <button pButton type="button" 
            label= "Click to Visualize IRB's Geolocation" 
            class= "p-mt-2 rounded-button"
            (click) = "displayIrbMap = !displayIrbMap">
        </button>
    </div>

    </p-card>  

    
</div> 

</div>

 <!--::::::::::::::::::::::::::::::::::::::::::::IRB Map Card Below::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::-->
 
<p-card *ngIf="displayIrbMap"
    class="floating-IrbMapCard"
    cdkDrag  
    [style] = "{ width: '700px' , height: '500px'}"
    pTooltip="Drag & Position The IRB Map Around The Screen - Best To Enlarge The Map"
    tooltipPosition="top">
 
    <p-header class="centered-bold">IRB Geolocation For - {{irbCount}} - IRB Sites</p-header>
        
    <div>
    <div>
     
    <div class = "map">
    <div style = "width: 100%; height: 400px;"> 
    <google-map 

      width = "100%" 
      height = "100%"
  
      [zoom] = "1.5"
      [center] = "center"
  
      (mapMousemove) = "move($event)">
                     
    <map-marker #marker = "mapMarker"
     
    *ngFor = "let markerP of markers;  let i = index" 
    [position] = "markerP.position"
    [options]  = "markerOptions"    
    (mapClick) = "openInfoWindow(marker, i)"
    [title]    = "markerP.title"
    [label]    = "markerP.label"
    [icon]     = "markerP.options" >
   
             <map-info-window>
                <div [innerHtml] = "thisistheinfo"></div>
             </map-info-window>
   
    </map-marker> 
    
    
    
  </google-map>
      </div>
  </div> 
  
  </div>
  
  
  
  </div>
 
 </p-card>
